import { forwardRef, memo, useCallback, type ReactNode } from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { useIntl, FormattedMessage} from 'react-intl';
// Material UI imports
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
// SCSS imports
import { group, button, buttonSmall } from './YesNoButtonGroup.module.scss';

type YesNoButtonGroupProps = {
  small?: boolean | null;
  disabled?: boolean | null;
  pendingValue?: boolean | null;
  value: boolean;
  onChange: (value: boolean) => void;
  ariaLabel?: string;
  noRipple?: boolean | null;
  yesLabel?: string | ReactNode | ReactNode[];
  noLabel?: string | ReactNode | ReactNode[];
}

const YesNoButtonGroupPropTypes = {
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  pendingValue: PropTypes.bool,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string,
  noRipple: PropTypes.bool,
  yesLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  noLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

// eslint-disable-next-line complexity
const YesNoButtonGroup = forwardRef<HTMLDivElement, YesNoButtonGroupProps>(({
  small = false,
  disabled = false,
  pendingValue,
  value,
  onChange,
  ariaLabel,
  noRipple = false,
  yesLabel,
  noLabel
}, ref) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();

  const handleYes = useCallback(() => {
    onChange(true);
  }, [onChange]);

  const handleNo = useCallback(() => {
    onChange(false);
  }, [onChange]);

  return (
    <ButtonGroup
        ref={ref}
        color="primary"
        disableElevation
        disableRipple={noRipple ? true : undefined}
        disabled={disabled ? true : undefined}
        aria-label={ariaLabel ? formatMessage({ id: ariaLabel, defaultMessage: ariaLabel }) : undefined}
        className={small ? undefined : group}
        size={small ? 'small' : undefined}
    >
      <Button
          fullWidth
          aria-pressed={value}
          aria-label={formatMessage({
            id: (isString(yesLabel) && yesLabel as string) || 'common.yes',
            defaultMessage: (isString(yesLabel) && yesLabel as string) || undefined
          })}
          variant={value ? 'contained' : 'outlined'}
          color={value ? 'primary' : 'secondary'}
          onClick={handleYes}
          className={small ? buttonSmall : button}
      >
        {(pendingValue === true && <CircularProgress color="inherit" size="1rem"/>) || (!yesLabel || isString(yesLabel) ? (
          <FormattedMessage
              id={(yesLabel as string) || (small ? 'common.yes' : 'common.big_yes')}
              defaultMessage={yesLabel as string}
          />
        ) : yesLabel)}
      </Button>
      <Button
          fullWidth
          aria-pressed={!value}
          aria-label={formatMessage({
            id: (isString(noLabel) && noLabel as string) || 'common.no',
            defaultMessage: (isString(noLabel) && noLabel as string) || undefined
          })}
          variant={value ? 'outlined' : 'contained'}
          color={value ? 'secondary' : 'primary'}
          onClick={handleNo}
          className={small ? buttonSmall : button}
      >
        {(pendingValue === false && <CircularProgress color="inherit" size="1rem"/>) || (!noLabel || isString(noLabel) ? (
          <FormattedMessage
              id={(noLabel as string) || (small ? 'common.no' : 'common.big_no')}
              defaultMessage={noLabel as string}
          />
        ) : noLabel)}
      </Button>
    </ButtonGroup>
  );
});

YesNoButtonGroup.displayName = 'YesNoButtonGroup';

YesNoButtonGroup.propTypes = YesNoButtonGroupPropTypes;

export default memo(YesNoButtonGroup);
