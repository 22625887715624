import { gql } from '@apollo/client';
// local imports
import { API_DA_UPSKILLING_VELOCITY } from '../config/api';

export const DA_UPSKILLING_VELOCITY_QUERY = gql`
  query DAUpskillingVelocity(
    $input: DAUpskillingVelocityInput!
    $pathBuilder: String!
  ) {
    daUpskillingVelocity(
      input: $input
    )
    @rest(
      type: "DAUpskillingVelocityPayload",
      path: "${API_DA_UPSKILLING_VELOCITY}",
      pathBuilder: $pathBuilder
    ) {
      total_employees
      results {
        id
        title
        abbr
        total
        data {
          date
          total
        }
      }
    }
  }
`;
