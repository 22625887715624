import { gql } from '@apollo/client';
// local imports
import { API_DA_EFFECTIVE_COURSES } from '../config/api';

export const DA_EFFECTIVE_COURSES_QUERY = gql`
  query DAMostEffectiveCourses(
    $input: DAMostEffectiveCoursesInput!
    $pathBuilder: String!
  ) {
    daMostEffectiveCourses(
      input: $input
    )
    @rest(
      type: "DAMostEffectiveCoursesPayload",
      path: "${API_DA_EFFECTIVE_COURSES}",
      pathBuilder: $pathBuilder
    ) {
      total_employees
      results {
        id
        title
        code
        abbr
        external_url
        actual_level_up_count
        potential_level_up_count
        percentage
        skills {
          id
          title
          abbr
        }
      }
    }
  }
`;
