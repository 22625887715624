import { gql } from '@apollo/client';
// local imports
import { API_EMPLOYEE_ADVISOR } from '../config/api';

export const DELETE_EMPLOYEE_ADVISOR = gql`
  mutation DeleteEmployeeAdvisor(
    $advisor_id: Int!
  ) {
    deleteEmployeeAdvisor(
      advisor_id: $advisor_id
    )
    @rest(
      type: "MutationResponse",
      path: "${API_EMPLOYEE_ADVISOR}",
      method: "DELETE"
    ) {
      success # just a placeholder; this request has empty response (status 204 only)
    }
  }
`;
