import { gql } from '@apollo/client';
// local imports
import { API_DEV_PLAN_OPPORTUNITY } from '../config/api';

export const DEV_PLAN_OPPORTUNITY_QUERY = gql`
  query DevPlanOpportunity(
    $devplan_id: Int!
    $opportunity_id: Int!
  ) {
    devplanOpportunity(
      devplan_id: $devplan_id,
      opportunity_id: $opportunity_id
    )
    @rest(
      type: "Opportunity",
      path: "${API_DEV_PLAN_OPPORTUNITY}"
    ) {
      id
      title
      duration_unit
      duration_value
      description
      start_date
      status
      scope_manager_id
      onsite
      sidegig
      timezone_minutes
      published_at
      updated_at
      started_at
      archived_at
      timestamp @client
      is_selected # local field used in DevPlanEditor
      location {
        id
        city
        state
        country
        title @client
      }
      owner {
        id
        code
        first_name
        last_name
        email
      }
      skills {
        id
        title
        abbr
        skill_proficiency_level
        status
      }
    }
  }
`;
