import { gql } from '@apollo/client';
// local imports
import { API_DEV_PLANS } from '../config/api';

export const NEW_DEV_PLAN = gql`
  mutation NewDevPlan(
    $input: NewDevPlanInput!
  ) {
    newDevPlan(
      input: $input
    )
    @rest(
      type: "DevPlan",
      path: "${API_DEV_PLANS}",
      method: "POST"
    )
    # status 201
    {
      id
      # title
      # created_at
      # updated_at
    }
  }
`;
