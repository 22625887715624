import { gql } from '@apollo/client';
// local imports
import { API_EMPLOYEE_DEV_PLAN_ACTIVE } from '../config/api';

export const ACTIVE_EMPLOYEE_DEV_PLAN = gql`
  mutation ActiveEmployeeDevPlan(
    $devplan_id: Int!
    $input: ActiveEmployeeDevPlanInput!
  ) {
    activeEmployeeDevPlan(
      devplan_id: $devplan_id,
      input: $input
    )
    @rest(
      type: "MutationResponse",
      path: "${API_EMPLOYEE_DEV_PLAN_ACTIVE}",
      method: "POST"
    )
    {
      success
      optimistic
    }
  }
`;
