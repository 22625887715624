// Scopes

export const JOB_MOVEMENT_SCOPE_UP = 'upward' as const;
export const JOB_MOVEMENT_SCOPE_ADJ = 'adjacent' as const;
export const JOB_MOVEMENT_SKILL_SCOPES = [JOB_MOVEMENT_SCOPE_UP, JOB_MOVEMENT_SCOPE_ADJ];

// TODO: migrate RoleScope to jobCategories

export const ROLE_SCOPE_TECH = 'tech' as const;
export const ROLE_SCOPE_BUSINESS = 'business' as const;
export const ROLE_SCOPE_CORPORATE = 'corporate' as const;

export const ROLE_SCOPES = [
  ROLE_SCOPE_TECH,
  ROLE_SCOPE_BUSINESS,
  ROLE_SCOPE_CORPORATE
] as const;

export type RoleScope = typeof ROLE_SCOPES[number];

const VALID_ROLE_SCOPES: Record<string, boolean> = {
  [ROLE_SCOPE_TECH]: true,
  [ROLE_SCOPE_BUSINESS]: true,
  [ROLE_SCOPE_CORPORATE]: true
};

export const isValidRoleScope = (scope?: string) => Boolean(scope && VALID_ROLE_SCOPES[scope]);
