import { gql } from '@apollo/client';
// local imports
import { API_HR_REDEPLOYMENTS } from '../config/api';

export const REDEPLOYMENT_PLANS_QUERY = gql`
  query RedeploymentPlans(
    $limit: Int
    $offset: Int
  ) {
    redeploymentPlans(
      limit: $limit,
      offset: $offset
    )
    @rest(
      type: "RedeploymentPlansPayload",
      path: "${API_HR_REDEPLOYMENTS}?{args}"
    ) {
      count
      results {
        id
        title
        created_at
        updated_at
        employees_count
      }
    }
  }
`;
