import { gql } from '@apollo/client';
// local imports
import { API_DA_SKILL_SUPPLY } from '../config/api';

export const DA_SKILL_SUPPLY_QUERY = gql`
  query DASkillSupply(
    $input: DASkillSupplyInput!
    $pathBuilder: String!
  ) {
    daSkillSupply(
      input: $input
    )
    @rest(
      type: "DASkillSupplyPayload",
      path: "${API_DA_SKILL_SUPPLY}",
      pathBuilder: $pathBuilder
    ) {
      total_employees
      skills {
        id
        title
        abbr
        level1
        level2
        level3
        level4
        avg
        total_employees
        job_open_req_count
        days_to_fill
      }
    }
  }
`;
