import {
  forwardRef, memo, useState, useCallback, useMemo, useEffect,
  type ChangeEvent, type ReactNode, type ReactElement, type Component
} from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import head from 'lodash/head';
import last from 'lodash/last';
import range from 'lodash/range';
import floor from 'lodash/floor';
import indexOf from 'lodash/indexOf';
import isNull from 'lodash/isNull';
import isSafeInteger from 'lodash/isSafeInteger';
import toSafeInteger from 'lodash/toSafeInteger';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import MuiPagination, { type PaginationProps as MuiPaginationProps } from '@mui/material/Pagination';
import Select, { type SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// local imports
import { MAX_JUMP_PAGE_COUNT, MAX_PAGE_SIZE, MIN_PAGE_SIZE, PAGE_SIZES } from '../config/params';
import { DEFAULT_MENU_LEFT, DEFAULT_MENU_RIGHT } from '../helpers/menus';
import { ReactComponent } from '../helpers/react';

const PAGINATION_DISPLAY_NAME = 'Pagination' as const;

export type PaginationComponent = ReactComponent<PaginationProps>;

export const hasPaginationElements = (
  loaded?: boolean,
  total?: number | null,
  pending?: boolean | null,
  minPageSize: number = MIN_PAGE_SIZE
) => Boolean((loaded && total && total > minPageSize) || (pending && (isNull(total) || (total && total > minPageSize))));

export const hasPagination = (
  pagination?: PaginationComponent | null,
  minPageSize: number = MIN_PAGE_SIZE
) => {
  if (!pagination) return false;
  if (pagination.type?.displayName !== PAGINATION_DISPLAY_NAME) return true;
  const { loaded, total, pending, totalOverride } = pagination.props || {};
  return hasPaginationElements(loaded, total, pending, minPageSize) || Boolean(totalOverride);
};

export const calcPageCount = (total: number, pageSize: number) => floor(total / pageSize) + (total % pageSize >= 1 ? 1 : 0);

type PaginationProps = {
  loaded: boolean;
  pending?: boolean | null;
  loading?: boolean | null;
  total?: number | null;
  totalMessage?: string;
  totalOverride?: ReactNode | ReactNode[];
  currentPage: number;
  pageSize?: number;
  pageSizes?: readonly number[];
  onPageSelected: (page: number) => void;
  onPageSize: (pageSize: number) => void;
  disabled?: boolean | null;
}

const PaginationPropTypes = {
  loaded: PropTypes.bool.isRequired,
  pending: PropTypes.bool,
  loading: PropTypes.bool,
  total: PropTypes.number,
  totalMessage: PropTypes.string,
  totalOverride: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number,
  pageSizes: PropTypes.arrayOf(PropTypes.number.isRequired),
  onPageSelected: PropTypes.func.isRequired,
  onPageSize: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

const Pagination = forwardRef<Component<MuiPaginationProps>, PaginationProps>(({
  loaded,
  pending = false,
  loading = false,
  total,
  totalMessage,
  totalOverride,
  currentPage,
  pageSize,
  pageSizes = PAGE_SIZES,
  onPageSelected,
  onPageSize,
  disabled = false
}, ref) => {
  const minPageSize = head(pageSizes) || MIN_PAGE_SIZE;
  const maxPageSize = last(pageSizes) || MAX_PAGE_SIZE;

  const [totalCount, setTotalCount] = useState<number>((total || 0) >= 0 ? toSafeInteger(total) : 0);
  const [pgSize, setPgSize] = useState<number>(
    pageSize && pageSize >= minPageSize && pageSize <= maxPageSize ? pageSize : minPageSize
  );
  const [curPage, setCurPage] = useState<number>(currentPage > 1 ? currentPage : 1);

  const pagesCount = useMemo(
    () => calcPageCount(totalCount, pgSize),
    [totalCount, pgSize]
  );

  useEffect(() => {
    if (!loading && isSafeInteger(total) && (total || 0) >= 0) setTotalCount(total || 0);
  }, [loading, total]);

  useEffect(() => {
    if (!loading && isSafeInteger(currentPage) && currentPage >= 1) setCurPage(currentPage);
  }, [loading, currentPage]);

  useEffect(() => {
    if (pageSize && pageSize >= minPageSize && pageSize <= maxPageSize) {
      setPgSize(pageSize);
      if (onPageSize) onPageSize(pageSize);
    }
  }, [minPageSize, maxPageSize, pageSize, onPageSize]);

  useEffect(() => {
    if (indexOf(pageSizes, pgSize) < 0) {
      const pSize = head(pageSizes) || MIN_PAGE_SIZE;
      setPgSize(pSize);
      if (onPageSize) onPageSize(pSize);
    }
  }, [pageSizes, pgSize, onPageSize]);

  const handlePageSizeChange = useCallback((event: SelectChangeEvent<number>) => {
    const pSize = toSafeInteger(event.target.value);
    setPgSize(pSize);
    if (onPageSize) onPageSize(pSize);
  }, [onPageSize]);

  const handleCurPageChange = useCallback((event: SelectChangeEvent<number>) => {
    const newPage = toSafeInteger(event.target.value);
    setCurPage(newPage);
    if (onPageSelected) onPageSelected(newPage);
  }, [onPageSelected]);

  const handleOnChange = useCallback(
    (_event: ChangeEvent<unknown>, page: number) => {
      setCurPage(page);
      if (onPageSelected) onPageSelected(page);
    }, [onPageSelected]
  );

  // pagination must be invisible in this case:
  if (!hasPaginationElements(loaded, total, pending, minPageSize)) return (totalOverride as ReactElement) || null;

  const textColor = disabled ? 'text.disabled' : 'text.label';

  return (
    <>
      <Box py={0.25} flexGrow={1} display="flex" alignItems="center" color={textColor}>
        <Box pr={2}>
          {totalOverride || <FormattedMessage id={totalMessage || 'pagination.results'} values={{ total: totalCount }}/>}
        </Box>
        <Box pr={0.5}>
          <FormattedMessage id="pagination.show"/>
        </Box>
        <FormControl variant="outlined" size="small">
          <Select
              value={pgSize}
              onChange={handlePageSizeChange}
              disabled={disabled ? true : undefined}
              MenuProps={DEFAULT_MENU_LEFT}
          >
            {map(pageSizes, (page_size) => (
              <MenuItem
                  key={page_size}
                  value={page_size}
                  disabled={disabled ? true : undefined}
              >
                {page_size}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box py={0.25} display="flex" alignItems="center" justifyContent="center" flexGrow={1}>
        <MuiPagination
            ref={ref}
            onChange={handleOnChange}
            count={pagesCount}
            page={curPage}
            color="primary"
            disabled={disabled ? true : undefined}
        />
      </Box>
      {pagesCount <= MAX_JUMP_PAGE_COUNT && (
        <Box py={0.25} flexGrow={1} justifySelf="flex-end" display="flex" alignItems="center" justifyContent="flex-end">
          <Box pr={0.5} color={textColor}>
            <FormattedMessage id="pagination.jump_to_page"/>
          </Box>
          <FormControl variant="outlined" size="small">
            <Select
                value={pagesCount >= 1 ? curPage : ''}
                onChange={handleCurPageChange}
                disabled={disabled ? true : undefined}
                MenuProps={DEFAULT_MENU_RIGHT}
            >
              {map(range(1, pagesCount + 1), (page) => (
                <MenuItem key={page} value={page}>
                  {page}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
    </>
  );
});

Pagination.displayName = PAGINATION_DISPLAY_NAME;

Pagination.propTypes = PaginationPropTypes;

const PaginationMemo = memo(Pagination);

PaginationMemo.displayName = PAGINATION_DISPLAY_NAME;

export default PaginationMemo;
