import { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import split from 'lodash/split';
import trim from 'lodash/trim';
import size from 'lodash/size';
import replace from 'lodash/replace';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Typography from '@mui/material/Typography';
// SCSS imports
import { list, item } from './LevelDescription.module.scss';

type LevelDescriptionProps = {
  description?: string;
  level?: number | null;
}

const LevelDescriptionPropTypes = {
  // attributes
  description: PropTypes.string,
  level: PropTypes.number
};

const LevelDescription = forwardRef<HTMLSpanElement, LevelDescriptionProps>(({
  description = '',
  level
}, ref) => (
  <>
    <Typography ref={ref} paragraph variant={level ? 'h5' : undefined}>
      {level
        ? <FormattedMessage id="common.skill_level.title" values={{ level, inferred: null }}/>
        : <FormattedMessage id="skill.level_description_info"/>}
    </Typography>
    <ul className={list}>
      {map(split(description, '\n'), (line, index) => {
        const text = replace(trim(line), /^\s*[-–—]+\s*/u, '');
        return size(text) >= 1 && (
          <Typography key={index} variant="body1" component="li" className={item}>
            {text}
          </Typography>
        );
      })}
    </ul>
  </>
));

LevelDescription.displayName = 'LevelDescription';

LevelDescription.propTypes = LevelDescriptionPropTypes;

export default memo(LevelDescription);
