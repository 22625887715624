import { gql } from '@apollo/client';
// local imports
import { API_DA_JOB_RELATED_SKILLS } from '../config/api';

export const DA_JOB_RELATED_SKILLS_QUERY = gql`
  query DAJobRelatedSkills(
    $input: DAJobRelatedSkillsInput!
    $pathBuilder: String!
  ) {
    daJobRelatedSkills(
      input: $input
    )
    @rest(
      type: "DAJobRelatedSkillsPayload",
      path: "${API_DA_JOB_RELATED_SKILLS}",
      pathBuilder: $pathBuilder
    ) {
      total_employees
      results {
        id
        title
        code
        average_total_skill
        average_confirmed_skill
        average_inferred_skill
        average_total_percentage
        average_confirmed_percentage
        average_inferred_percentage
        data {
          date
          total_confirmed
          total_confirmed_percentage
        }
      }
    }
  }
`;
