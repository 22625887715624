import { gql } from '@apollo/client';
// local imports
import { API_DA_COURSE_COMPLETION } from '../config/api';

export const DA_COURSE_COMPLETION_QUERY = gql`
  query DACourseCompletion(
    $input: DACourseCompletionInput!
    $pathBuilder: String!
  ) {
    daCourseCompletion(
      input: $input
    )
    @rest(
      type: "DACourseCompletionPayload",
      path: "${API_DA_COURSE_COMPLETION}",
      pathBuilder: $pathBuilder
    ) {
      total_employees
      results {
        id
        title
        code
        abbr
        external_url
        total
        # percentage # TODO: enable when backend includes this field into the response
        skills {
          id
          title
          abbr
        }
      }
    }
  }
`;
