import { gql } from '@apollo/client';
// local imports
import { API_DA_JOBS_MOVEMENT } from '../config/api';

export const DA_JOBS_MOVEMENT_QUERY = gql`
  query DAJobsMovement(
    $input: DAJobsMovementInput!
    $pathBuilder: String!
  ) {
    daJobsMovement(
      input: $input
    )
    @rest(
      type: "DAJobsMovementPayload",
      path: "${API_DA_JOBS_MOVEMENT}",
      pathBuilder: $pathBuilder
    ) {
      total_employees
      jobs {
        id
        code
        title
        movement_count
        target_count
      }
    }
  }
`;
