import { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import { type Variant } from '@mui/material/styles/createTypography';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
// SCSS imports
import { header } from './BuilderHeader.module.scss';

const titleTypographyProps = {
  variant: 'subtitle1' as Variant,
  color: 'inherit'
};

type BuilderHeaderProps = {
  progress: number;
};

const BuilderHeaderPropTypes = {
  progress: PropTypes.number.isRequired
};

const BuilderHeader = forwardRef<HTMLElement, BuilderHeaderProps>(({
  progress
}, ref) => (
  <CardHeader
      ref={ref}
      titleTypographyProps={titleTypographyProps}
      title={<FormattedMessage id="builder.title"/>}
      action={(
        <Typography variant="subtitle1">
          <FormattedMessage id="builder.progress" values={{ progress: progress / 100 }}/>
        </Typography>
      )}
      className={header}
  />
));

BuilderHeader.displayName = 'BuilderHeader';

BuilderHeader.propTypes = BuilderHeaderPropTypes;

export default memo(BuilderHeader);
