import { memo, useContext, useEffect, useMemo, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import size from 'lodash/size';
// EmPath UI Components
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
import CardTabbar from '@empathco/ui-components/src/elements/CardTabbar';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
// local imports
import { Job } from '../models/job';
import useNonReducedUI from '../constants/managementLevel';
import { PATH_JOB, PATH_JOB_TCP } from '../config/paths';
import { DataContext } from '../context';
import MostCommonJobMoves from './MostCommonJobMoves';
import TechnicalCareerPath from './TechnicalCareerPath';

type RolePathProps = {
  isEmployee?: boolean;
}

const RolePathPropTypes = {
  isEmployee: PropTypes.bool
};

// eslint-disable-next-line complexity
const RolePath: FunctionComponent<RolePathProps> = ({
  isEmployee = false
}) => {
  const { showNonReducedUI } = useNonReducedUI();
  const {
    role: { data: role, pending, failed },
    jobMoves: { data: jobMoves, pending: pendingJobMoves, failed: failedJobMoves }, requireJobMoves
  } = useContext(DataContext);
  const { id, code, is_index_display, discipline } = !role || pending || failed ? {} as Job : role;
  const { id: disciplineId } = discipline || {};

  useEffect(() => {
    if (id) requireJobMoves?.({ job_id: id });
  }, [id, requireJobMoves]);

  const noMCJM = !pendingJobMoves && !failedJobMoves && jobMoves &&
    size(jobMoves.prev_jobs) < 1 && size(jobMoves.next_jobs) < 1;

  const items = useMemo(() => disciplineId && !noMCJM ? [
    {
      title: 'role.most_common_job_moves',
      link: code && is_index_display
        ? injectParams(PATH_JOB, { role_id: code }) : null
    },
    {
      title: 'role.technical_career_path',
      link: code && is_index_display
        ? injectParams(PATH_JOB_TCP, { role_id: code }) : null
    }
  ] : undefined, [code, is_index_display, disciplineId, noMCJM]);

  if (failed || (!disciplineId && noMCJM)) return null;

  const isContractorRole = !showNonReducedUI(role);

  return (
    <ContentCard pending={pending || pendingJobMoves}>
      {(disciplineId && (
        items ? (
          <CardTabbar withoutScroll items={items}>
            <MostCommonJobMoves jobId={id} isContractorRole={isContractorRole} isEmployee={isEmployee}/>
            <TechnicalCareerPath jobId={id} disciplineId={disciplineId} isEmployee={isEmployee}/>
          </CardTabbar>
        ) : (
          <>
            <CardTitle title="role.technical_career_path" withDivider/>
            <TechnicalCareerPath jobId={id} disciplineId={disciplineId} isEmployee={isEmployee}/>
          </>
        )
      )) || (
        <>
          <CardTitle title="role.most_common_job_moves" withDivider/>
          <MostCommonJobMoves jobId={id} isContractorRole={isContractorRole} isEmployee={isEmployee}/>
        </>
      )}
    </ContentCard>
  );
};

RolePath.propTypes = RolePathPropTypes;

export default memo(RolePath);
