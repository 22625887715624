import { forwardRef, memo, useState, useCallback, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import { type RouteObject } from 'react-router-dom';
// local imports
import type { BreadcrumbItem, DrawerItem } from '../types';
import useCurrentPath from '../hooks/useCurrentPath';
import AppBar, { type AppBarVariant, AppBarVariantProp, APPBAR_EMPTY } from './AppBar';
import AppDrawer from './AppDrawer';

type AppHeaderProps = {
  logo: ReactNode;
  appbar: typeof AppBar;
  drawer: typeof AppDrawer;
  items: DrawerItem[];
  routes: RouteObject[];
  breadcrumbs?: BreadcrumbItem[] | null;
  variant?: AppBarVariant;
  // for Storybook only:
  testOpen?: boolean;
};

const AppHeaderPropTypes = {
  // attributes
  logo: PropTypes.node.isRequired,
  appbar: PropTypes.object.isRequired as Validator<typeof AppBar>,
  drawer: PropTypes.object.isRequired as Validator<typeof AppDrawer>,
  items: PropTypes.array.isRequired,
  routes: PropTypes.array.isRequired,
  breadcrumbs: PropTypes.array,
  variant: AppBarVariantProp,
  // for Storybook only:
  testOpen: PropTypes.bool
};

const AppHeader = forwardRef<HTMLDivElement, AppHeaderProps>(({
  logo,
  appbar: CustomizedAppBar,
  drawer: CustomizedAppDrawer,
  items,
  routes,
  breadcrumbs,
  variant = 'full',
  testOpen = false,
  ...props
}, ref) => {
  const path = useCurrentPath(routes);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(testOpen);

  const handleDrawerOpen = useCallback(() => setDrawerOpen(true), []);
  const handleDrawerClose = useCallback(() => setDrawerOpen(false), []);

  const withDrawer = variant !== APPBAR_EMPTY;

  return (
    <>
      <CustomizedAppBar
          ref={ref}
          logo={logo}
          path={path}
          breadcrumbs={breadcrumbs || undefined}
          toggleDrawer={handleDrawerOpen}
          drawerOpen={withDrawer && drawerOpen ? true : undefined}
          variant={variant}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
      />
      {withDrawer ? (
        <CustomizedAppDrawer
            items={items}
            path={path}
            open={drawerOpen}
            onOpen={handleDrawerOpen}
            onClose={handleDrawerClose}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
        />
      ) : undefined}
    </>
  );
});

AppHeader.displayName = 'AppHeader';

AppHeader.propTypes = AppHeaderPropTypes;

export default memo(AppHeader);
