import { forwardRef, memo } from 'react';
import PropTypes, { type Validator } from 'prop-types';
// Material UI imports
import { type ThemeOptions } from '@mui/material/styles';
import Box from '@mui/material/Box';
// Material Icon imports
import Summarize from '@mui/icons-material/Summarize';
// EmPath UI Components
import { themeOptions } from '@empathco/ui-components/src/styles/themeOptions';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import TruncatedTextLink from '@empathco/ui-components/src/elements/TruncatedTextLink';
import ItemCard from '@empathco/ui-components/src/elements/ItemCard';
// local imports
import { AdminReport } from '../graphql/types';
import { ADMIN_REPORT_TYPES } from '../graphql/customTypes';
import { PATH_ADMIN_REPORT } from '../config/paths';

const disabledIconSx = { opacity: (themeOptions as unknown as ThemeOptions).palette?.action?.disabledOpacity || 0.38 };

type ReportCardProps = {
  item: AdminReport;
  disabled?: boolean;
}

const ReportCardPropTypes = {
  // attributes
  item: PropTypes.object.isRequired as Validator<AdminReport>,
  disabled: PropTypes.bool
};

const ReportCard = forwardRef<HTMLDivElement, ReportCardProps>(({
  item,
  disabled = false
}, ref) => {
  const { id, title, report_type, description } = item || {};
  return (
    <ItemCard
        ref={ref}
        light
        disabled={disabled}
        link={!disabled && ADMIN_REPORT_TYPES.includes(report_type)
          ? injectParams(PATH_ADMIN_REPORT, { report_id: id, report_type })
          : undefined}
    >
      <Box display="flex">
        <Box pr={1} pt={1.25} sx={disabled ? disabledIconSx : undefined}>
          <Summarize fontSize="large" color="primary"/>
        </Box>
        <Box flexGrow={1}>
          <TruncatedTextLink plain text={title} disabled={disabled}/>
          <TruncatedTextLink plain text={description} regular variant="body1" color="text.secondary" disabled={disabled}/>
        </Box>
      </Box>
    </ItemCard>
  );
});

ReportCard.displayName = 'ReportCard';

ReportCard.propTypes = ReportCardPropTypes;

export default memo(ReportCard);
