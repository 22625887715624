import { gql } from '@apollo/client';
// local imports
import { API_COHORT } from '../config/api';

export const COHORT_QUERY = gql`
  query Cohort(
    $cohort_id: Int!
  ) {
    cohort(
      cohort_id: $cohort_id
    )
    @rest(
      type: "CohortExpanded",
      path: "${API_COHORT}"
    ) {
      id
      title
      created_at
      updated_at
      owner {
        id
        code
        first_name
        last_name
        email
      }
      dev_plan {
        id
        title
      }
      cohort_skills {
        id
        status
        skill_proficiency_level
        skill {
          id
          title
          abbr
          type
        }
      }
      employees {
        id
        code
        first_name
        last_name
        email
        is_profile_accessible
        performance_rating
        years_on_job
        current_job {
          id
          title
          code
        }
        location {
          id
          title
          city
          state
          country
        }
        manager {
          id
          code
          first_name
          last_name
          email
        }
        skills {
          id
          title
          abbr
          current_level
          inferred_level
          is_inference_newer
        }
      }
    }
  }
`;
