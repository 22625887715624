import { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
// local imports
import BoxTypography from '../mixins/BoxTypography';
// SCSS imports
import {
  tagLabel, smallLabel, course, advisor, active, inactive,
  outlined, published, draft, started, archived
} from './TagLabel.module.scss';

const TAG_LABEL_VARIANTS = [
  'course', 'advisor', 'active', 'inactive',
  'outlined', 'published', 'draft', 'started', 'archived'
] as const;
export type TagLabelVariant = typeof TAG_LABEL_VARIANTS[number];

type TagLabelProps = {
  variant: TagLabelVariant;
  small?: boolean;
  title?: string;
}

const TagLabelPropTypes = {
  variant: PropTypes.oneOf(TAG_LABEL_VARIANTS).isRequired,
  small: PropTypes.bool,
  title: PropTypes.string
};

const TagLabel = forwardRef<HTMLElement, TagLabelProps>(({
  variant,
  small = false,
  title
}, ref) => (
  <BoxTypography
      ref={ref}
      variant="caption"
      textAlign="center"
      className={clsx(tagLabel, {
        [course]: variant === 'course',
        [advisor]: variant === 'advisor',
        [active]: variant === 'active',
        [inactive]: variant === 'inactive',
        [outlined]: variant === 'outlined',
        [published]: variant === 'published',
        [draft]: variant === 'draft',
        [started]: variant === 'started',
        [archived]: variant === 'archived',
        [smallLabel]: small
      })}
  >
    <FormattedMessage id={title || `common.tag.${variant}`} defaultMessage={title}/>
  </BoxTypography>
));

TagLabel.displayName = 'TagLabel';

TagLabel.propTypes = TagLabelPropTypes;

export default memo(TagLabel);
