import { gql } from '@apollo/client';
// local imports
import { API_SEND_MENTORSHIP_REQUEST } from '../config/api';

export const SEND_MENTORSHIP_REQUEST = gql`
  mutation SendMentorshipRequest(
    $input: SendMentorshipRequestInput!
  ) {
    sendMentorshipRequest(
      input: $input
    )
    @rest(
      type: "MutationResponse",
      path: "${API_SEND_MENTORSHIP_REQUEST}",
      method: "POST"
    ) {
      success
    }
  }
`;
