import { memo, useState, useCallback, useEffect, useContext, useMemo, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import size from 'lodash/size';
import reject from 'lodash/reject';
import indexOf from 'lodash/indexOf';
import { useLazyQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
// EmPath UI Components
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import GridBox from '@empathco/ui-components/src/mixins/GridBox';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import FilterSelector from '@empathco/ui-components/src/elements/FilterSelector';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import CloseIconButton from '@empathco/ui-components/src/elements/CloseIconButton';
// local imports
import { ORGS_QUERY } from '../graphql/Orgs';
import { SKILLS_IN_DEMAND_QUERY } from '../graphql/SkillsInDemand';
import { Org, OrgsDocument, SimpleSkill, SkillsInDemandDocument } from '../graphql/types';
import { JobLookupItem } from '../graphql/customTypes';
import { Skill } from '../models/skill';
import { Job } from '../models/job';
import { ILookupSkill, LookupItem } from '../models/lookupItem';
import { MAX_LOOKUP_OPTIONS } from '../config/params';
import useCustomerSettings from '../config/customer';
import { SkillGroup } from '../context/persistent';
import { DataContext } from '../context';
import JobSearch from '../v3/JobSearch';
import SkillSearch from './SkillSearch';

type AddSkillsDialogProps = {
  exclude?: number[] | null;
  excludeJobs?: number[] | null;
  excludeOrgs?: number[] | null;
  anchorEl?: Element | null;
  onAdd: (skill: Skill | ILookupSkill) => void;
  onAddGroup: (skillGroup?: SkillGroup | null) => void;
  onCancel: () => void;
  disabled?: boolean | null;
  // for Storybook only
  testInDemandPending?: boolean;
};

const AddSkillsDialogPropTypes = {
  // attributes
  exclude: PropTypes.arrayOf(PropTypes.number.isRequired),
  excludeJobs: PropTypes.arrayOf(PropTypes.number.isRequired),
  excludeOrgs: PropTypes.arrayOf(PropTypes.number.isRequired),
  anchorEl: PropTypes.instanceOf(Element),
  onAdd: PropTypes.func.isRequired,
  onAddGroup: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  // for Storybook only
  testInDemandPending: PropTypes.bool
};

// eslint-disable-next-line complexity
const AddSkillsDialog: FunctionComponent<AddSkillsDialogProps> = ({
  exclude,
  excludeJobs,
  excludeOrgs,
  anchorEl,
  onAdd,
  onAddGroup,
  onCancel,
  disabled: parentDisabled = false,
  testInDemandPending = false
}) => {
  const { HAS_INDEMAND_SKILLS } = useCustomerSettings();
  const {
    role: { data: cachedRole, pending: rolePending, failed: roleFailed }, requireEmployeeRole
  } = useContext(DataContext);

  // lazy load orgs
  const { query: getOrgs, pending: orgsPending, failed: orgsFailed, results: orgsData } = useQueryCounted({
    data: undefined as unknown as Org,
    key: 'orgs',
    lazyQuery: useLazyQuery(ORGS_QUERY as typeof OrgsDocument)
  });

  // lazy load in-demand skills
  const {
    query: getInDemand, pending: pendingInDemandSkills, failed: failedInDemand, results: inDemand, variables: inDemandParams
  } = useQueryCounted({
    data: undefined as unknown as SimpleSkill,
    key: 'skillsInDemand',
    lazyQuery: useLazyQuery(SKILLS_IN_DEMAND_QUERY as typeof SkillsInDemandDocument)
  });
  const pendingInDemand = (pendingInDemandSkills && size(inDemandParams) >= 1) || testInDemandPending;
  const cachedOrg = !pendingInDemand && !failedInDemand ? inDemand : undefined;

  const [skill, setSkill] = useState<Skill | ILookupSkill | null>(null);
  const [job, setJob] = useState<JobLookupItem | null>(null);
  const [org, setOrg] = useState<LookupItem | null>(null);

  const isOpen = Boolean(anchorEl);
  const [visible, setVisible] = useState(isOpen);

  const orgs = useMemo(() => HAS_INDEMAND_SKILLS && !orgsFailed && !orgsPending && orgsData
    ? (size(excludeOrgs) >= 1 && reject(orgsData, ({ id }) => indexOf(excludeOrgs, id) >= 0)) || orgsData
    : null,
    [excludeOrgs, orgsData, orgsFailed, orgsPending, HAS_INDEMAND_SKILLS]
  );

  useEffect(() => {
    if (isOpen) {
      setVisible(true);
      setSkill(null);
      setJob(null);
      setOrg(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (roleFailed === true) setJob(null);
  }, [roleFailed]);

  useEffect(() => {
    if (failedInDemand === true) setOrg(null);
  }, [failedInDemand]);

  useEffect(() => {
    if (HAS_INDEMAND_SKILLS) getOrgs?.({ variables: { limit: MAX_LOOKUP_OPTIONS } });
  }, [getOrgs, HAS_INDEMAND_SKILLS]);

  const handleSkill = useCallback((selectedSkill: Skill | ILookupSkill | null) => {
    setSkill(selectedSkill);
    if (selectedSkill) {
      setJob(null);
      setOrg(null);
    }
  }, []);

  const handleJob = useCallback((selectedJob: JobLookupItem | null) => {
    setJob(selectedJob);
    if (selectedJob) {
      setSkill(null);
      setOrg(null);
    }
  }, []);

  const handleOrg = useCallback((id: number) => {
    const selectedOrg = find(orgs, ['id', id]) || null;
    setOrg(selectedOrg);
    if (selectedOrg) {
      setSkill(null);
      setJob(null);
    }
  }, [orgs]);

  const addJobGroup = useCallback((role?: Job | null) => onAddGroup(
    role?.id && (size(role.skills) >= 1 || size(role.skills_with_gap) >= 1) ? {
      id: role.id,
      type: 'job',
      title: role.title,
      skills: [...role.skills || [], ...role.skills_with_gap || []]
    } as SkillGroup : null
  ), [onAddGroup]);

  const addOrgGroup = useCallback((skills?: Skill[] | null) => onAddGroup(org?.id && size(skills) >= 1 ? {
    id: org.id,
    type: 'org',
    title: org.title,
    skills
  } as SkillGroup : null), [org, onAddGroup]);

  const handleConfirm = useCallback(() => {
    if (skill) onAdd(skill);
    else if (job?.code) {
      if (cachedRole?.code === job.code) addJobGroup(cachedRole);
      else requireEmployeeRole?.({
        role_id: job.code,
        hrbp: true,
        onSuccess: addJobGroup
      });
    } else if (org) {
      if (cachedOrg && inDemandParams?.org_id === org.id) addOrgGroup(cachedOrg);
      else getInDemand?.({
        variables: { org_id: org.id, limit: MAX_LOOKUP_OPTIONS },
        onCompleted(data) {
          addOrgGroup(data.skillsInDemand?.results || null);
        }
      });
    }
  }, [
    skill, job, org, cachedRole, cachedOrg, inDemandParams,
    onAdd, addJobGroup, addOrgGroup, requireEmployeeRole, getInDemand
  ]);

  const transitionProps = useMemo(() => ({ onExited: () => {
    setVisible(false);
  } }), []);

  const disabled = parentDisabled || rolePending || pendingInDemand;

  return (
    <>
      <Dialog
          disableEnforceFocus
          maxWidth="lg"
          fullWidth
          scroll="body"
          open={isOpen}
          onClose={onCancel}
          TransitionProps={transitionProps}
      >
        <CloseIconButton small onClick={onCancel}/>
        <CardTitle
            title="hr.talentfinder.add_skill"
            withDivider
        />
        <CardSection>
          <GridBox py={1} container alignItems="center" justifyContent="center">
            {visible ? (
              <>
                <Grid item xs={12} md={HAS_INDEMAND_SKILLS ? 3 : 5} container alignItems="center" justifyContent="center">
                  <SkillSearch
                      library
                      exclude={exclude || undefined}
                      onChange={handleSkill}
                      disabled={disabled || Boolean(job || org)}
                  />
                </Grid>
                <GridBox py={1} item xs={12} md={1} container alignItems="center" justifyContent="center">
                  <FormattedMessage id="hr.talentfinder.or"/>
                </GridBox>
                <Grid item xs={12} md={HAS_INDEMAND_SKILLS ? 3 : 5} container alignItems="center" justifyContent="center">
                  <JobSearch
                      variant="skills"
                      withSkills
                      value={job}
                      onChange={handleJob}
                      exclude={excludeJobs}
                      disabled={disabled || Boolean(skill || org)}
                  />
                </Grid>
                {HAS_INDEMAND_SKILLS ? (
                  <>
                    <GridBox py={1} item xs={12} md={1} container alignItems="center" justifyContent="center">
                      <FormattedMessage id="hr.talentfinder.or"/>
                    </GridBox>
                    <Grid item xs={12} md={3} container alignItems="center" justifyContent="center">
                      <FilterSelector
                          type="in_demand_skills"
                          choices={orgs}
                          value={org?.id || 0}
                          onChange={handleOrg}
                          disabled={disabled || Boolean(skill || job)}
                      />
                    </Grid>
                  </>
                ) : undefined}
              </>
            ) : undefined}
          </GridBox>
        </CardSection>
        <Divider/>
        <Box p={2} display="flex" alignItems="center" justifyContent="center">
          <Button
              onClick={handleConfirm}
              color="primary"
              variant="contained"
              size="large"
              disableElevation
              disabled={disabled || (!skill && !job && !org)}
              startIcon={rolePending || pendingInDemand ? <CircularProgress size={18} color="inherit"/> : undefined}
          >
            <FormattedMessage id="common.button.add"/>
          </Button>
        </Box>
      </Dialog>
      <ActionFailedAlert
          message="hr.talentfinder.job_error"
          open={roleFailed}
      />
      <ActionFailedAlert
          message="hr.talentfinder.org_error"
          open={failedInDemand}
      />
    </>
  );
};

AddSkillsDialog.propTypes = AddSkillsDialogPropTypes;

export default memo(AddSkillsDialog);
