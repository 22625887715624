import map from 'lodash/map';
import take from 'lodash/take';
import { type AxiosResponse } from 'axios';
import type { RowInput } from 'jspdf-autotable';
// local imports
import { OrgSkill } from '../graphql/types';
import { APIResponse } from '../models/apiResponse';
import {
  IAutoTable, IJsPDF, Rows,
  getTitleFilename,
  downloadAndSave, saveToCSV, saveToPDF
} from '../helpers/export';

// Org In-Demand Skills export

const IN_DEMAND_SKILLS_CSV_COLUMNS = [
  'supervisor.export.skill.id',
  'supervisor.export.skill.title',
  'supervisor.export.skill.role_type'
];

function saveInDemandSkills(
  title: string,
  headers: string[],
  skills: OrgSkill[],
  draft: boolean,
  jsPDF?: IJsPDF,
  autoTable?: IAutoTable
) {
  const rows = map(skills, (skill) => [
    skill.abbr,
    skill.title,
    skill.role_type
  ] as RowInput) as Rows;
  const filename = `in-demand_skills_${getTitleFilename(title)}${draft ? '-draft' : ''}`;
  if (jsPDF && autoTable) {
    saveToPDF(jsPDF, autoTable, filename, [...take(headers, 3), headers[4]], rows, title, '' /* , {} */);
  } else {
    saveToCSV(filename, headers, rows);
  }
}

function exportInDemandSkills(
  format: string,
  title: string,
  headers: string[],
  skills: OrgSkill[],
  draft: boolean
): Promise<boolean | number> {
  return downloadAndSave<OrgSkill>(
    format,
    { status: 200, data: { results: skills } as APIResponse<OrgSkill> } as AxiosResponse<APIResponse<OrgSkill>>,
    (data, jsPDF, autoTable) => saveInDemandSkills(title, headers, data, draft, jsPDF, autoTable)
  );
}

const exportInDemandSkillsHook = {
  IN_DEMAND_SKILLS_CSV_COLUMNS,
  exportInDemandSkills
};

function useExportInDemandSkills() {
  return exportInDemandSkillsHook;
}

export default useExportInDemandSkills;
