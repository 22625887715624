import { useCallback, useMemo } from 'react';
import toSafeInteger from 'lodash/toSafeInteger';
import axios, { type AxiosResponse } from 'axios';
import { makeVar, useReactiveVar } from '@apollo/client';
// local imports
import { MIN_MATCH_RATE, MAX_MATCH_RATE } from './params';
import defaultCustomerSettings from '../customers/settings.json';
import {
  customerSettingsFactory,
  DashboardAnalyticsTabs, RuntimeConfig,
  CustomerTicker as CustomerTickerType, OtherResource as OtherResourceType, OtherResources as OtherResourcesType,
  SkillDevelopmentRes as SkillDevelopmentResType, ISkillDevelopmentResource as ISkillDevelopmentResourceType
} from '../helpers/customer';

const customerSettingsUrl = window.CUSTOMER ? `/customers/${window.CUSTOMER}/settings.json` : undefined;
// eslint-disable-next-line @typescript-eslint/init-declarations
let customerSettingsResult: AxiosResponse<typeof defaultCustomerSettings> | undefined;
try {
  if (customerSettingsUrl) customerSettingsResult = await axios.get<typeof defaultCustomerSettings>(customerSettingsUrl);
} catch (_error) {
  // nothing to do
}

const customerSettings: typeof defaultCustomerSettings =
  (customerSettingsResult?.status === 200 && customerSettingsResult.data) || defaultCustomerSettings;


let RUNTIME_CONFIG: RuntimeConfig = {};

const defaultMatchRate = toSafeInteger(window.MATCH_RATE_DEFAULT);

try {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = window.RUNTIME_CONFIG || '{}';
  RUNTIME_CONFIG = JSON.parse(textArea.value) || {};
} catch (_error) {
  // nothing to do
}

// Customer specific configuration parameters

export type ISkillDevelopmentResource = ISkillDevelopmentResourceType;
export type SkillDevelopmentRes = SkillDevelopmentResType;
export type OtherResource = OtherResourceType;
export type OtherResources = OtherResourcesType;
export type CustomerTicker = CustomerTickerType;

const HAS_COURSES = (customerSettings.has_courses ||
  Boolean(RUNTIME_CONFIG?.enable_courses) || RUNTIME_CONFIG?.courses === true
) && !RUNTIME_CONFIG?.disable_courses && RUNTIME_CONFIG?.courses !== false;
const HAS_OPPORTUNITIES = (customerSettings.has_opportunities || RUNTIME_CONFIG?.opportunities === true) &&
  !RUNTIME_CONFIG?.disable_opportunities && RUNTIME_CONFIG?.opportunities !== false;

const DEFAULT_MATCH_RATE = (MIN_MATCH_RATE <= defaultMatchRate && defaultMatchRate < MAX_MATCH_RATE
  ? defaultMatchRate : undefined) || MIN_MATCH_RATE;

const {
  LOGO_WIDTH, LOGO_HEIGHT, MANAGEMENT_LEVEL_FIRST, MANAGEMENT_LEVEL_LAST, DOMESTIC_COUNTRIES, CUSTOM_MESSAGES,
  HAS_INTERNAL_COURSE_LOGO,
  FIND_YOUR_GEO_ZONE_URL, INTERNATIONAL_SALARY_URL, PRIVACY_NOTICE_URL, HELP_URL, PROFILE_BUILDER_VIDEO_URL,
  SKILL_DEVELOPMENT_RESOURCES_DISABLED, SKILL_DEVELOPMENT_RESOURCES, CUSTOMER_OTHER_RESOURCES,
  isFeatureReleased, getOrgUnitSplash, getCourseUrl, getSkillCoursesUrl, getOpenReqUrl, getEmployeeContactUrl,
  CUSTOMER_TICKERS, DA_TABS
} = customerSettingsFactory(RUNTIME_CONFIG, customerSettings);

const customerSettingsVar = makeVar({
  DEFAULT_MATCH_RATE,

  LOGO_WIDTH,
  LOGO_HEIGHT,

  MANAGEMENT_LEVEL_FIRST,
  MANAGEMENT_LEVEL_LAST,

  // Global settings:
  HAS_MULTIPLE_SSO: (customerSettings.has_multiple_sso || RUNTIME_CONFIG?.multiple_sso === true) &&
    RUNTIME_CONFIG?.multiple_sso !== false,
  HAS_LOGIN: customerSettings.has_login as boolean,
  HAS_LOGOUT: customerSettings.has_logout as boolean,
  HAS_INTERNAL_COURSE_LOGO,

  // Employee UI: as boolean
  HAS_JOBS_CHART: customerSettings.has_jobs_chart as boolean,
  HAS_JOBS_SCOPE: customerSettings.has_jobs_scope as boolean,
  HAS_INDEMAND_SKILLS: customerSettings.has_indemand_skills as boolean,
  HAS_PROFILE_BUILDER: customerSettings.has_profile_builder as boolean,
  HAS_RESUME_UPLOADER: customerSettings.has_resume_uploader as boolean,
  HAS_QUICK_TOUR: customerSettings.has_quick_tour as boolean,
  HAS_REDUCED_UI: customerSettings.has_reduced_ui as boolean,
  HAS_EXPORT_MY_PLAN: RUNTIME_CONFIG?.export_my_plan !== false,
  HAS_COMPENSATION: customerSettings.has_compensation as boolean,
  HAS_MENTORING: customerSettings.has_mentoring as boolean,
  HAS_COURSES,
  HAS_COURSES_READONLY: HAS_COURSES || customerSettings.has_courses_readonly,
  HAS_DEV_PLAN: (customerSettings.has_dev_plan || RUNTIME_CONFIG?.dev_plan === true) &&
    !RUNTIME_CONFIG?.disable_dev_plan && RUNTIME_CONFIG?.dev_plan !== false,
  HAS_OPPORTUNITIES,
  HAS_DEV_PLAN_OPPORTUNITIES: HAS_OPPORTUNITIES &&
    (customerSettings.has_devplan_opportunities || RUNTIME_CONFIG?.devplan_opportunities === true) &&
    RUNTIME_CONFIG?.devplan_opportunities !== false,
  HAS_REDEPLOYMENT: (customerSettings.has_redeployment || RUNTIME_CONFIG?.redeployment === true) &&
    !RUNTIME_CONFIG?.disable_redeployment && RUNTIME_CONFIG?.redeployment !== false,

  // HRBP UI:
  HAS_HRP_JOBS: customerSettings.has_hrp_jobs as boolean,
  HAS_TALENT_FINDER: customerSettings.has_talent_finder as boolean,
  HAS_TEAM_BUILDER: (customerSettings.has_team_builder || RUNTIME_CONFIG?.team_builder === true) &&
    !RUNTIME_CONFIG?.disable_team_builder && RUNTIME_CONFIG?.team_builder !== false,
  HAS_CANDIDATES: customerSettings.has_candidates as boolean,
  HAS_JOBS_SKILLS_GAP_CHART: customerSettings.has_jobs_skills_gap_chart as boolean,
  HAS_INDEMAND_SKILLS_EDITOR: customerSettings.has_indemand_skills_editor as boolean,

  // Admin UI:
  HAS_ADMIN_UI: RUNTIME_CONFIG?.admin_ui === true, // TODO: when ready change to `!== false,`

  // Other UI tweaks
  SHOW_WELCOME_DATA_PRIVACY: customerSettings.show_welcome_data_privacy as boolean,

  DOMESTIC_COUNTRIES,
  CUSTOM_MESSAGES,

  isFeatureReleased,
  getOrgUnitSplash,
  getCourseUrl,
  getSkillCoursesUrl,
  getOpenReqUrl,
  getEmployeeContactUrl,

  FIND_YOUR_GEO_ZONE_URL,
  INTERNATIONAL_SALARY_URL,

  PRIVACY_NOTICE_URL,
  HELP_URL,
  PROFILE_BUILDER_VIDEO_URL,

  SKILL_DEVELOPMENT_RESOURCES_DISABLED,
  SKILL_DEVELOPMENT_RESOURCES,

  CUSTOMER_OTHER_RESOURCES,

  CUSTOMER_TICKERS,
  DA_TABS
});

// eslint-disable-next-line max-statements
function useCustomerSettings() {

  const settings = useReactiveVar(customerSettingsVar);

  // Global
  const setHasMultipleSSO = useCallback((hasMultipleSSO: boolean) =>
    customerSettingsVar({ ...settings, HAS_MULTIPLE_SSO: hasMultipleSSO }), [settings]);
  const setHasLogin = useCallback((hasLogin: boolean) =>
    customerSettingsVar({ ...settings, HAS_LOGIN: hasLogin }), [settings]);
  const setHasLogout = useCallback((hasLogout: boolean) =>
    customerSettingsVar({ ...settings, HAS_LOGOUT: hasLogout }), [settings]);
  const setHasInternalCourseLogo = useCallback((hasInternalCourseLogo: boolean) =>
    customerSettingsVar({ ...settings, HAS_INTERNAL_COURSE_LOGO: hasInternalCourseLogo }), [settings]);
  // Employee
  const setHasJobsChart = useCallback((hasJobsChart: boolean) =>
    customerSettingsVar({ ...settings, HAS_JOBS_CHART: hasJobsChart }), [settings]);
  const setHasJobsScope = useCallback((hasJobsScope: boolean) =>
    customerSettingsVar({ ...settings, HAS_JOBS_SCOPE: hasJobsScope }), [settings]);
  const setHasInDemandSkills = useCallback((hasInDemandSkills: boolean) =>
    customerSettingsVar({ ...settings, HAS_INDEMAND_SKILLS: hasInDemandSkills }), [settings]);
  const setHasProfileBuilder = useCallback((hasProfileBuilder: boolean) =>
    customerSettingsVar({ ...settings, HAS_PROFILE_BUILDER: hasProfileBuilder }), [settings]);
  const setHasResumeUploader = useCallback((hasResumeUploader: boolean) =>
    customerSettingsVar({ ...settings, HAS_RESUME_UPLOADER: hasResumeUploader }), [settings]);
  const setHasQuickTour = useCallback((hasQuickTour: boolean) =>
    customerSettingsVar({ ...settings, HAS_QUICK_TOUR: hasQuickTour }), [settings]);
  const setHasReducedUI = useCallback((hasReducedUI: boolean) =>
    customerSettingsVar({ ...settings, HAS_REDUCED_UI: hasReducedUI }), [settings]);
  const setHasCompensation = useCallback((hasCompensation: boolean) =>
    customerSettingsVar({ ...settings, HAS_COMPENSATION: hasCompensation }), [settings]);
  const setHasMentoring = useCallback((hasMentoring: boolean) =>
    customerSettingsVar({ ...settings, HAS_MENTORING: hasMentoring }), [settings]);
  const setHasCourses = useCallback((hasCourses: boolean) =>
    customerSettingsVar({ ...settings, HAS_COURSES: hasCourses }), [settings]);
  const setHasCoursesReadOnly = useCallback((hasCoursesReadOnly: boolean) =>
    customerSettingsVar({ ...settings, HAS_COURSES_READONLY: hasCoursesReadOnly }), [settings]);
  const setHasDevPlan = useCallback((hasDevPlan: boolean) =>
    customerSettingsVar({ ...settings, HAS_DEV_PLAN: hasDevPlan }), [settings]);
  const setHasOpportunities = useCallback((hasOpportunities: boolean) =>
    customerSettingsVar({ ...settings, HAS_OPPORTUNITIES: hasOpportunities }), [settings]);
  const setHasDevPlanOpportunities = useCallback((hasDevPlanOpportunities: boolean) =>
    customerSettingsVar({ ...settings, HAS_DEV_PLAN_OPPORTUNITIES: hasDevPlanOpportunities }), [settings]);
  const setHasRedeployment = useCallback((hasRedeployment: boolean) =>
    customerSettingsVar({ ...settings, HAS_REDEPLOYMENT: hasRedeployment }), [settings]);
  // HRBP
  const setHasHRPJobs = useCallback((hasHRPJobs: boolean) =>
    customerSettingsVar({ ...settings, HAS_HRP_JOBS: hasHRPJobs }), [settings]);
  const setHasTalentFinder = useCallback((hasTalentFinder: boolean) =>
    customerSettingsVar({ ...settings, HAS_TALENT_FINDER: hasTalentFinder }), [settings]);
  const setHasTeamBuilder = useCallback((hasTeamBuilder: boolean) =>
    customerSettingsVar({ ...settings, HAS_TEAM_BUILDER: hasTeamBuilder }), [settings]);
  const setHasCandidates = useCallback((hasCandidates: boolean) =>
    customerSettingsVar({ ...settings, HAS_CANDIDATES: hasCandidates }), [settings]);
  const setHasJobsSkillsGapChart = useCallback((hasJobsSkillsGapChart: boolean) =>
    customerSettingsVar({ ...settings, HAS_JOBS_SKILLS_GAP_CHART: hasJobsSkillsGapChart }), [settings]);
  const setHasInDemandSkillsEditor = useCallback((hasInDemandSkillsEditor: boolean) =>
    customerSettingsVar({ ...settings, HAS_INDEMAND_SKILLS_EDITOR: hasInDemandSkillsEditor }), [settings]);
  // Other UI tweaks
  const setShowWelcomeDataPrivacy = useCallback((showWelcomeDataPrivacy: boolean) =>
    customerSettingsVar({ ...settings, SHOW_WELCOME_DATA_PRIVACY: showWelcomeDataPrivacy }), [settings]);

  const setFindYourGeoZoneUrl = useCallback((findYourGeoZoneUrl?: string | null) =>
    customerSettingsVar({ ...settings, FIND_YOUR_GEO_ZONE_URL: findYourGeoZoneUrl as string }), [settings]);
  const setInternationalSalaryUrl = useCallback((internationalSalaryUrl?: string | null) =>
    customerSettingsVar({ ...settings, INTERNATIONAL_SALARY_URL: internationalSalaryUrl as string }), [settings]);
  const setPrivacyNoticeUrl = useCallback((privacyNoticeUrl?: string | null) =>
    customerSettingsVar({ ...settings, PRIVACY_NOTICE_URL: privacyNoticeUrl as string }), [settings]);
  const setHelpUrl = useCallback((helpUrl?: string | null) =>
    customerSettingsVar({ ...settings, HELP_URL: helpUrl as string }), [settings]);

  // TODO: ExportMyPlan setter
  const setHasExportMyPlan = useCallback((_hasExportMyPlan: boolean) => null, []);
  // TODO: Admin UI setter
  const setHasAdminUI = useCallback((_hasAdminUI: boolean) => null, []);
  // TODO: Dashboard Analytics Tabs setters
  const setDATabs = useCallback((_daTabs?: Partial<DashboardAnalyticsTabs> | null | undefined) => null, []);

  const customerSettingsMemo = useMemo(() => ({
    ...settings,

    HAS_COURSES_READONLY: settings.HAS_COURSES || settings.HAS_COURSES_READONLY,
    HAS_DEV_PLAN_OPPORTUNITIES: settings.HAS_OPPORTUNITIES && settings.HAS_DEV_PLAN_OPPORTUNITIES,

    // Global
    setHasMultipleSSO,
    setHasLogin,
    setHasLogout,
    setHasInternalCourseLogo,
    // Employee
    setHasJobsChart,
    setHasJobsScope,
    setHasInDemandSkills,
    setHasProfileBuilder,
    setHasResumeUploader,
    setHasQuickTour,
    setHasReducedUI,
    setHasExportMyPlan,
    setHasCompensation,
    setHasMentoring,
    setHasCourses,
    setHasCoursesReadOnly,
    setHasDevPlan,
    setHasOpportunities,
    setHasDevPlanOpportunities,
    setHasRedeployment,
    // HRBP
    setHasHRPJobs,
    setHasTalentFinder,
    setHasTeamBuilder,
    setHasCandidates,
    setHasJobsSkillsGapChart,
    setHasInDemandSkillsEditor,
    // Admin
    setHasAdminUI,
    // Other UI tweaks
    setShowWelcomeDataPrivacy,
    // Dashboard Analytics Tabs
    setDATabs,

    // string value setters
    setFindYourGeoZoneUrl,
    setInternationalSalaryUrl,
    setPrivacyNoticeUrl,
    setHelpUrl

  }), [
    settings,
    setDATabs, setFindYourGeoZoneUrl, setHasAdminUI, setHasCandidates, setHasCompensation, setHasCourses,
    setHasCoursesReadOnly, setHasDevPlan, setHasDevPlanOpportunities, setHasExportMyPlan, setHasHRPJobs,
    setHasInDemandSkills, setHasInDemandSkillsEditor, setHasInternalCourseLogo,
    setHasJobsChart, setHasJobsScope, setHasJobsSkillsGapChart, setHasMultipleSSO, setHasLogin, setHasLogout, setHasMentoring,
    setHasOpportunities, setHasProfileBuilder, setHasQuickTour, setHasRedeployment, setHasReducedUI,
    setHasResumeUploader, setHasTalentFinder, setHasTeamBuilder, setHelpUrl, setInternationalSalaryUrl,
    setPrivacyNoticeUrl, setShowWelcomeDataPrivacy
  ]);

  return customerSettingsMemo;
}

export default useCustomerSettings;
