import { memo, useState, useCallback, useLayoutEffect, useMemo, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
// Material UI imports
import Dialog from '@mui/material/Dialog';
// EmPath UI Components
import CloseIconButton from '@empathco/ui-components/src/elements/CloseIconButton';
import AddSkillPopover from '@empathco/ui-components/src/widgets/AddSkillPopover';
// local imports
import { LookupItem } from '../models/lookupItem';
import { Skill, SkillLevel, SKILL_LEVEL_FIRST, SKILL_LEVEL_MIN } from '../models/skill';
import useCustomerSettings from '../config/customer';
import EditSkillLevel from '../v3/EditSkillLevel';
import SkillSearch, { SkillSearchProps } from './SkillSearch';

type AddSkillDialogProps = {
  supervisor?: boolean;
  depersonalized?: boolean;
  limit?: number;
  exclude?: number[];
  plain?: boolean;
  reducedUI?: boolean;
  anchorEl?: Element | null;
  onAdd: (skill: Skill, level: SkillLevel, mentoring: boolean, isTarget?: boolean) => void;
  onCancel: () => void;
  disabled?: boolean;
  // for Storybook only
  testSkill?: Skill;
};

const AddSkillDialogPropTypes = {
  // attributes
  supervisor: PropTypes.bool,
  depersonalized: PropTypes.bool,
  limit: PropTypes.number,
  exclude: PropTypes.arrayOf(PropTypes.number.isRequired),
  plain: PropTypes.bool,
  reducedUI: PropTypes.bool,
  anchorEl: PropTypes.instanceOf(Element),
  onAdd: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  // for Storybook only
  testSkill: PropTypes.object as Validator<Skill>
};

const AddSkillDialog: FunctionComponent<AddSkillDialogProps> = ({
  supervisor = false,
  depersonalized = false,
  limit,
  exclude,
  plain = false,
  reducedUI = false,
  anchorEl,
  onAdd,
  onCancel,
  disabled = false,
  testSkill = null
}) => {
  const { HAS_MENTORING } = useCustomerSettings();

  const [skill, setSkill] = useState<Skill | null>(testSkill); // selected skill
  const [skl, setSkl] = useState<Skill | null>(testSkill); // detailed skill object fetched by `EditSkillLevel`
  const [level, setLevel] = useState<SkillLevel>(depersonalized ? SKILL_LEVEL_FIRST : SKILL_LEVEL_MIN);
  const [mentoring, setMentoring] = useState(false);
  const [isTarget, setIsTarget] = useState<boolean | undefined>(false);

  const isOpen = Boolean(anchorEl);

  const skillSearchProps = useMemo(() => limit ? { limit } as SkillSearchProps : undefined, [limit]);

  const handleBack = useCallback(() => {
    setSkill(null);
    setSkl(null);
    onCancel();
  }, [onCancel]);

  const handleConfirm = useCallback(
    () => skl && onAdd(skl, level, mentoring, isTarget),
    [skl, level, mentoring, isTarget, onAdd]
  );

  const handleUpdate = useCallback((sk: Skill, lvl: SkillLevel, mentor: boolean, targeting?: boolean) => {
    setSkl(sk);
    setLevel(lvl);
    if (HAS_MENTORING) setMentoring(mentor);
    setIsTarget(targeting);
  }, [HAS_MENTORING]);

  // reset on open
  useLayoutEffect(() => {
    setSkill(testSkill);
    setSkl(testSkill);
    setLevel(depersonalized ? SKILL_LEVEL_FIRST : SKILL_LEVEL_MIN);
    if (HAS_MENTORING) setMentoring(false);
    setIsTarget(false);
  }, [testSkill, isOpen, depersonalized, HAS_MENTORING]);

  return (
    <>
      <Dialog
          disableEnforceFocus
          maxWidth="lg"
          fullWidth
          scroll="body"
          open={Boolean(skill)}
          onClose={onCancel}
      >
        <CloseIconButton onClick={onCancel}/>
        {skill ? (
          <EditSkillLevel
              plain={plain}
              reducedUI={reducedUI}
              noTargeting={depersonalized || reducedUI}
              defaultLevel={depersonalized ? SKILL_LEVEL_FIRST : undefined}
              depersonalized={depersonalized}
              skill={skill}
              onUpdate={handleUpdate}
              onBack={handleBack}
              onConfirm={handleConfirm}
              disabled={disabled}
              confirmDisabled={level < SKILL_LEVEL_FIRST}
          />
        ) : undefined}
      </Dialog>
      <AddSkillPopover
          supervisor={supervisor}
          anchorEl={skill ? null : anchorEl}
          exclude={exclude}
          onAdd={setSkill as (skill: LookupItem) => void}
          onCancel={onCancel}
          disabled={disabled}
          SkillSearchComponentProps={skillSearchProps}
          SkillSearchComponent={SkillSearch}
      />
    </>
  );
};

AddSkillDialog.propTypes = AddSkillDialogPropTypes;

export default memo(AddSkillDialog);
