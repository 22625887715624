import { gql } from '@apollo/client';
// local imports
import { API_HR_REDEPLOYMENTS } from '../config/api';

export const NEW_REDEPLOYMENT_PLAN = gql`
  mutation NewRedeploymentPlan(
    $input: NewRedeploymentPlanInput!
  ) {
    newRedeploymentPlan(
      input: $input
    )
    @rest(
      type: "MutationResponseWithId",
      path: "${API_HR_REDEPLOYMENTS}",
      method: "POST"
    )
    {
      id
      success
    }
  }
`;
