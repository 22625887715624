// My Skills Sorting

export const SKILL_SORT_NAME = 'title' as const;
export const SKILL_SORT_LEVEL = 'level' as const;
export const SKILL_SORT_UNDEFINED = 'undefined' as const;

export const SKILL_SORTS = [SKILL_SORT_UNDEFINED, SKILL_SORT_LEVEL, SKILL_SORT_NAME] as const;

export type SkillSort = typeof SKILL_SORTS[number];

export const SKILL_SORT_ASC = 'asc' as const;
export const SKILL_SORT_DESC = 'desc' as const;
export const SKILL_SORT_ASCENDING = 'ascending' as const;
export const SKILL_SORT_DESCENDING = 'descending' as const;

const SKILL_SORT_DIRS = [SKILL_SORT_ASC, SKILL_SORT_DESC] as const;
const SKILL_SORT_DIRECTIONS = [SKILL_SORT_ASCENDING, SKILL_SORT_DESCENDING] as const;

export type SkillSortDir = typeof SKILL_SORT_DIRS[number];
export type SkillSortDirections = typeof SKILL_SORT_DIRECTIONS[number];

export const DEFAULT_SORT_DIRECTION: Record<string, boolean> = {
  [SKILL_SORT_NAME]: true,
  [SKILL_SORT_LEVEL]: false,
  [SKILL_SORT_UNDEFINED]: false
};

const VALID_SORT: Record<string, boolean> = {
  [SKILL_SORT_NAME]: true,
  [SKILL_SORT_LEVEL]: true,
  [SKILL_SORT_UNDEFINED]: true
};

const VALID_SORT_PARAMS: Record<string, boolean> = {
  [SKILL_SORT_NAME]: true,
  [SKILL_SORT_LEVEL]: true
};

const VALID_DIRECTIONS: Record<string, boolean> = {
  [SKILL_SORT_ASCENDING]: true,
  [SKILL_SORT_DESCENDING]: true
};

export const isValidSortBy = (sort?: string) => Boolean(sort && VALID_SORT[sort]);
export const isValidSortParam = (sort?: string) => Boolean(sort && VALID_SORT_PARAMS[sort]);
export const isValidSortDirection = (direction: string) => Boolean(VALID_DIRECTIONS[direction]);
