import { forwardRef, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// SCSS imports
import { tabItem, clickable, current, currentFirst, first, other } from './QuickTourTab.module.scss';

type QuickTourTabProps = {
  tab: string;
  selected?: boolean;
  isFirst?: boolean;
  disabled?: boolean;
  onClick: (tab: string) => void;
};

const QuickTourTabPropTypes = {
  tab: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  isFirst: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

const QuickTourTab = forwardRef<HTMLDivElement, QuickTourTabProps>(({
  tab,
  selected = false,
  isFirst = false,
  disabled = false,
  onClick
}, ref) => {
  const handleClick = useCallback(() => onClick(tab), [onClick, tab]);
  return (
    <Grid
        ref={ref}
        item
        container
        xs={12}
        sm={4}
        alignItems="center"
        justifyContent="center"
        onClick={disabled ? undefined : handleClick}
        className={clsx(tabItem, {
          [clickable]: !disabled,
          [current]: !isFirst && selected,
          [currentFirst]: isFirst && selected,
          [first]: isFirst && !selected,
          [other]: !isFirst && !selected
        })}
    >
      <Typography variant={selected ? 'body1' : 'h5'}>
        <FormattedMessage id={`tour.tab.${tab}`}/>
      </Typography>
    </Grid>
  );
});

QuickTourTab.displayName = 'QuickTourTab';

QuickTourTab.propTypes = QuickTourTabPropTypes;

export default memo(QuickTourTab);
