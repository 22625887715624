import { memo, useContext, useEffect, useState, useCallback, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import omit from 'lodash/omit';
import isNil from 'lodash/isNil';
import toLower from 'lodash/toLower';
import { useQuery } from '@apollo/client';
// Material UI imports
import Box from '@mui/material/Box';
// EmPath UI Components
import { paramsDiffer } from '@empathco/ui-components/src/helpers/pagination';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import FilterSelector from '@empathco/ui-components/src/elements/FilterSelector';
// local imports
import { ORGS_QUERY } from '../graphql/Orgs';
import { Org, OrgsDocument } from '../graphql/types';
import { LOOKUP_OPTIONS } from '../helpers/graphql';
import { InDemandSkillsParams } from '../context/dataContext';
import { DataContext } from '../context';
import { sanitizeLookup } from '../helpers/models';
import SkillsGrid from '../v3/SkillsGrid';
import PaginationControls from '../v3/PaginationControls';

type InDemandSkillsListProps = {
  supervisor?: boolean;
  uid?: string;
  userOrgId?: number;
};

const InDemandSkillsListPropTypes = {
  // attributes
  supervisor: PropTypes.bool,
  uid: PropTypes.string,
  userOrgId: PropTypes.number
};

const InDemandSkillsList: FunctionComponent<InDemandSkillsListProps> = ({
  supervisor,
  uid,
  userOrgId
}) => {
  // orgs
  const { pending: orgsPending, failed: orgsFailed, results: orgs } = useQueryCounted({
    data: undefined as unknown as Org,
    key: 'orgs',
    query: useQuery(ORGS_QUERY as typeof OrgsDocument, LOOKUP_OPTIONS)
  });

  const {
    skillsInDemand: { data: skills, pending, failed, count, params }, requireInDemandSkills
  } = useContext(DataContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>();

  const [org, setOrg] = useState<number>(0);

  const handleOrg = useCallback((value: number) => setOrg(sanitizeLookup(value, orgs)), [orgs]);

  useEffect(() => {
    setOrg(isNil(orgs) ? 0 : sanitizeLookup(userOrgId, orgs));
  }, [userOrgId, orgs]);

  useEffect(() => {
    if (requireInDemandSkills && !isNil(pageSize)) {
      const newParams: InDemandSkillsParams = {
        org_id: org,
        limit: pageSize
      };
      let curPage = currentPage;
      if (paramsDiffer(supervisor && uid ? omit(params, 'selected_employee_id') : params, newParams) ||
        (supervisor && uid && toLower(uid) !== toLower(get(params, 'selected_employee_id')))
      ) {
        curPage = 1;
        setCurrentPage(1);
      }
      requireInDemandSkills({
        ...newParams,
        offset: pageSize * (curPage - 1)
       });
     }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supervisor, uid, org, currentPage, pageSize, requireInDemandSkills]); // ignoring `params` changes

  const loading = pending || !skills;
  const disabled = loading || failed;

  return (
    <SkillsGrid
        source="in_demand"
        isEmployee={!supervisor}
        readOnly={supervisor}
        supervisor={supervisor}
        skills={skills}
        failed={failed}
        pending={loading}
        disabled={disabled}
        pagination={(
          <PaginationControls
              settingsId="in_demand_skills"
              loaded={Boolean(skills)}
              pending={pending}
              loading={pending}
              total={count}
              totalMessage="skills.in_demand.pagination"
              currentPage={currentPage}
              onPageSelected={setCurrentPage}
              onPageSize={setPageSize}
              disabled={disabled}
          />
        )}
        filters={(
          <Box display="flex" justifyContent="flex-start">
            <FilterSelector
                type="in_demand_org"
                required
                choices={orgs}
                value={org}
                onChange={handleOrg}
                disabled={disabled || orgsPending || orgsFailed}
                myId={supervisor ? undefined : userOrgId}
            />
          </Box>
        )}
    />
  );
};

InDemandSkillsList.propTypes = InDemandSkillsListPropTypes;

export default memo(InDemandSkillsList);
