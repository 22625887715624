import { memo, type FunctionComponent } from 'react';
// Material UI imports
import { useTheme } from '@mui/material/styles';

const SvgGradients: FunctionComponent = () => {
  const theme = useTheme();
  return (
    <svg width="0" height="0">
      <defs>
        <linearGradient id="jobMoveArrowGradient">
          <stop offset="0%" stopColor={theme.palette.success.light}/>
          <stop offset="100%" stopColor={theme.palette.success.dark}/>
        </linearGradient>
        <linearGradient id="matchRateGradient">
          <stop offset="0%" stopColor={theme.palette.secondary.dark0}/>
          <stop offset="100%" stopColor={theme.palette.success.light1}/>
        </linearGradient>
        <linearGradient id="matchRateReserved" gradientTransform="rotate(45) translate(0.5,0.5)">
          <stop offset="0%" stopColor={theme.palette.success.light1}/>
          <stop offset="100%" stopColor={theme.palette.secondary.dark0}/>
        </linearGradient>
        <linearGradient id="matchRatePlanned" gradientTransform="rotate(-45) translate(-0.5,-0.5)">
          <stop offset="0%" stopColor={theme.palette.misc.matchRateStart}/>
          <stop offset="100%" stopColor={theme.palette.misc.matchRateEnd}/>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default memo(SvgGradients);
