import { forwardRef, type RefAttributes } from 'react';
import { Link as RouterLink, type LinkProps as RouterLinkProps } from 'react-router-dom';
import Link, { type LinkProps } from '@mui/material/Link';

const LinkWithRoute = forwardRef<HTMLAnchorElement,
  Omit<LinkProps, 'component'> &
  RouterLinkProps &
  RefAttributes<HTMLAnchorElement>
>((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Link ref={ref} component={RouterLink} {...props} underline="hover"/>
));

LinkWithRoute.displayName = 'LinkWithRoute';

export default LinkWithRoute;
