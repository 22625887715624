import { gql } from '@apollo/client';
// local imports
import { API_SUPV_OPPORTUNITIES } from '../config/api';

export const OPPORTUNITIES_QUERY = gql`
  query Opportunities(
    $search: String
    $published_only: Boolean
    $hide_archived: Boolean
    $sort_by: OpportunitiesSortOrder
    $direction: SortDirection
    $offset: Int
    $limit: Int
  ) {
    opportunities(
      search: $search,
      published_only: $published_only,
      hide_archived: $hide_archived,
      sort_by: $sort_by,
      direction: $direction,
      offset: $offset,
      limit: $limit
    )
    @rest(
      type: "OpportunitiesPayload",
      path: "${API_SUPV_OPPORTUNITIES}?{args}"
    ) {
      count
      results {
        id
        title
        duration_unit
        duration_value
        description
        start_date
        status
        scope_manager_id
        onsite
        sidegig
        timezone_minutes
        published_at
        updated_at
        started_at
        archived_at
        timestamp @client
        total_matches
        shortlisted
        bookings {
          status
          count
        }
        location {
          id
          city
          state
          country
          state_id
          country_id
          title @client
        }
      }
    }
  }
`;
