import round from 'lodash/round';
import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';
import isSafeInteger from 'lodash/isSafeInteger';
// EmPath UI Components
import { isEmptyString } from '@empathco/ui-components/src/helpers/strings';
import { getCurrentSeconds } from '@empathco/ui-components/src/helpers/datetime';
// local imports
import { isValidOnboardingStep } from '../constants/builder';
import { NOTIFICATIONS_POLLING_INTERVAL } from '../config/params';
import {
  axiosInstance as axios,
  API_MY_PREFERENCES, API_MY_SET_PREFERENCES,
  API_MY_SETTINGS, API_MY_SETTINGS_UPDATE,
  API_MY_NOTIFICATIONS, API_MY_NOTIFICATION_COUNTS, API_MY_COC, getApiTalentData
} from '../config/api';
import {
  PREFERENCES_FETCH, SETTINGS_FETCH, NOTIFICATIONS_FETCH,
  PREFERENCES_UPDATED, PREFERENCES_UPDATING,
  SETTINGS_UPDATED, SETTINGS_UPDATING, COC_FETCH, UNREAD_COUNT_FETCH, TALENT_DATA_FETCH
} from './dataContext';
import { getRequestHeaders, optimizeParams } from '../helpers/context';
import { fetchFactory } from '../helpers/actions';
import { transformCoC } from './dataTranformations';

export const requirePreferences = (token, online, unauthenticate, dispatch, preferences) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  type: PREFERENCES_FETCH,
  entity: preferences,
  api: API_MY_PREFERENCES,
  results: ''
});

export const requireSettings = (token, online, unauthenticate, dispatch, settings) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  type: SETTINGS_FETCH,
  entity: settings,
  api: API_MY_SETTINGS
});

export const requireNotifications = (token, online, unauthenticate, dispatch, notifications) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  withCount: true,
  params: ({ timestamp, limit, offset }) => ({
    timestamp: timestamp && isSafeInteger(timestamp) && timestamp >= 1 ? timestamp : null,
    limit: limit && limit >= 1 && isSafeInteger(limit) ? limit : null,
    offset: offset && offset >= 1 && isSafeInteger(offset) ? offset : null
  }),
  type: NOTIFICATIONS_FETCH,
  entity: notifications,
  api: API_MY_NOTIFICATIONS,
  dropParams: ['timestamp']
});

export const requireCoC = (token, online, unauthenticate, dispatch, coc) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  params: ({ org_id }) => org_id && isSafeInteger(org_id) && org_id >= 1 ? { org_id } : {},
  type: COC_FETCH,
  entity: coc,
  api: API_MY_COC,
  transformation: transformCoC
});

export const requireUnreadCount = (token, online, unauthenticate, dispatch, unreadCount) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  params: () => ({ timestamp: round(getCurrentSeconds() * (3000 / NOTIFICATIONS_POLLING_INTERVAL)) }),
  type: UNREAD_COUNT_FETCH,
  entity: unreadCount,
  api: API_MY_NOTIFICATION_COUNTS,
  dropParams: ['timestamp']
});

export const requireTalentData = (token, online, unauthenticate, dispatch, talentData) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  type: TALENT_DATA_FETCH,
  entity: talentData,
  params: ['role_id'],
  validator: ({ role_id }) => !isEmptyString(role_id),
  api: ({ role_id }) => getApiTalentData(role_id),
  dropParams: ['role_id']
});

export const updatePreferences = (
  token, online, dispatch, preferencesUpdatePending, HAS_MENTORING
) => async (callParams = {}) => {
  if (!token || preferencesUpdatePending) return;
  const {
    skills_profile_is_public, open_to_opportunities, my_manager_can_view_my_target_job,
    mentor_others, skills_i_mentor, email_notifications, onboarding_step, onSuccess
  } = callParams || {};
  const params = optimizeParams({
    skills_profile_is_public: isNil(skills_profile_is_public) ? null : Boolean(skills_profile_is_public),
    open_to_opportunities: isNil(open_to_opportunities) ? null : Boolean(open_to_opportunities),
    my_manager_can_view_my_target_job: isNil(my_manager_can_view_my_target_job)
      ? null : Boolean(my_manager_can_view_my_target_job),
    email_notifications: isArray(email_notifications) ? email_notifications : null,
    ...HAS_MENTORING ? {
      mentor_others: isNil(mentor_others) ? null : Boolean(mentor_others),
      skills_i_mentor: isArray(skills_i_mentor) ? skills_i_mentor : null
    } : {},
    onboarding_step: isValidOnboardingStep(onboarding_step) ? onboarding_step : null
  }, online);
  try {
    if (!online) throw new Error();
    dispatch({
      type: PREFERENCES_UPDATING,
      params
    });
    const { status, data } = await axios.request({
      method: 'POST',
      url: API_MY_SET_PREFERENCES,
      data: params,
      headers: getRequestHeaders(token)
    }) || {};
    if (status < 200 || status > 201 || !data || !data.success) throw new Error();
    if (onSuccess) onSuccess();
    dispatch({
      type: PREFERENCES_UPDATED,
      payload: true,
      params
    });
  } catch (error) {
    dispatch({
      type: PREFERENCES_UPDATED,
      payload: false,
      params
    });
  }
};

export const updateSettings = (token, online, dispatch, settingsUpdatePending) => async (callParams = {}) => {
  if (!token || settingsUpdatePending) return;
  const params = optimizeParams(callParams || {}, online);
  try {
    if (!online) throw new Error();
    dispatch({
      type: SETTINGS_UPDATING,
      params
    });
    const { status, data } = await axios.request({
      method: 'POST',
      url: API_MY_SETTINGS_UPDATE,
      data: params,
      headers: getRequestHeaders(token)
    }) || {};
    if (status < 200 || status > 201 || !data || !data.success) throw new Error();
    dispatch({
      type: SETTINGS_UPDATED,
      payload: true,
      params
    });
  } catch (error) {
    dispatch({
      type: SETTINGS_UPDATED,
      payload: false,
      params
    });
  }
};
