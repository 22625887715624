import { gql } from '@apollo/client';
// local imports
import { API_DEV_PLAN_ADVISOR } from '../config/api';

export const DEV_PLAN_ADVISOR_QUERY = gql`
  query DevPlanAdvisorQuery(
    $devplan_id: Int!
    $advisor_id: Int!
  ) {
    devplanAdvisor(
      devplan_id: $devplan_id,
      advisor_id: $advisor_id
    )
    @rest(
      type: "SkillAdvisor",
      path: "${API_DEV_PLAN_ADVISOR}"
    ) {
      id
      code
      first_name
      last_name
      email
      active_mentor_count
      is_selected # local field used in DevPlanEditor
      current_job {
        id
        title
        code
      }
      location {
        id
        title
        city
        state
        country
      }
      advisory_skills {
        id
        title
        abbr
        current_level
      }
    }
  }
`;
