import { gql } from '@apollo/client';
// local imports
import { API_EMPLOYEE_SKILL_ADVISORS } from '../config/api';

export const EMPLOYEE_SKILL_ADVISORS_QUERY = gql`
  query EmployeeSkillAdvisors(
    $skill_id: Int!
    $state_id: Int
    $country_id: Int
    $my_org_only: Boolean
    $limit: Int
    $offset: Int
  ) {
    employeeSkillAdvisors(
      skill_id: $skill_id,
      state_id: $state_id,
      country_id: $country_id,
      my_org_only: $my_org_only,
      limit: $limit,
      offset: $offset
    )
    @rest(
      type: "SkillAdvisorsPayload",
      path: "${API_EMPLOYEE_SKILL_ADVISORS}?{args}"
    ) {
      count
      results {
        id
        first_name
        last_name
        code
        email
        current_job {
          title
          management_level
        }
        location {
          country
          city
          state
        }
        skills {
          id
          title
          abbr
          current_level
          # inferred_level - not provided by backend
          # is_inference_newer - not provided by backend
          # is_new - not used in UI
          status
          status_updated_at
        }
      }
    }
  }
`;
