import { memo, type FunctionComponent } from 'react';
// local imports
import useVerifyUser from '../hooks/useVerifyUser';
import Screen from '../v3/Screen';
import Preferences from '../components/Preferences';

const PreferencesScreen: FunctionComponent = () => {
  const { content } = useVerifyUser({ employee: true });
  return content || (
    <Screen>
      <Preferences/>
    </Screen>
  );
};

export default memo(PreferencesScreen);
