import { memo, type ForwardedRef } from 'react';
import includes from 'lodash/includes';
// import PropTypes from 'prop-types';
import map from 'lodash/map';
import { useIntl } from 'react-intl';
// Material UI imports
import ButtonGroup from '@mui/material/ButtonGroup';
// local imports
import { simpleForwardRef } from '../helpers/react';
import ScopeSelectorButton from './ScopeSelectorButton';
// SCSS imports
import { button } from './ScopeSelector.module.scss';

type ScopeSelectorProps<T> = {
  readonly scope: readonly T[];
  readonly unavailable?: readonly T[];
  readonly value: T;
  readonly onChange: (item: T) => void;
  readonly disabled?: boolean
  readonly simple?: boolean;
};

// const ScopeSelectorPropTypes = {
//   // attributes
//   scope: PropTypes.arrayOf(
//     PropTypes.string.isRequired
//   ).isRequired,
//   unavailable: PropTypes.arrayOf(
//     PropTypes.string.isRequired
//   ),
//   value: PropTypes.string.isRequired,
//   onChange: PropTypes.func.isRequired,
//   disabled: PropTypes.bool,
//   simple: PropTypes.bool
// };

const ScopeSelectorRender = <T extends string>({
  scope,
  unavailable,
  value,
  onChange,
  disabled = false,
  simple = false
}: ScopeSelectorProps<T>, ref: ForwardedRef<HTMLDivElement>) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();
  return (
    <ButtonGroup
        ref={ref}
        color="primary"
        size="small"
        disableElevation
        disabled={disabled}
        aria-label={formatMessage({ id: 'common.scope.label' })}
    >
      {map(scope, (item) => (
        <ScopeSelectorButton
            key={item}
            item={item}
            selected={item === value}
            simple={simple}
            disabled={unavailable ? includes(unavailable, item) : undefined}
            onChange={onChange as ((item: string) => void)}
            className={button}
        />
      ))}
    </ButtonGroup>
  );
};

const ScopeSelector = simpleForwardRef(ScopeSelectorRender);

// ScopeSelector.displayName = 'ScopeSelector';

// ScopeSelector.propTypes = ScopeSelectorPropTypes;

export default memo(ScopeSelector);
