/* eslint-disable max-lines */
import { forwardRef, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import join from 'lodash/join';
import every from 'lodash/every';
import range from 'lodash/range';
import maxBy from 'lodash/maxBy';
import sortBy from 'lodash/sortBy';
import round from 'lodash/round';
import flatten from 'lodash/flatten';
import transform from 'lodash/transform';
import toSafeInteger from 'lodash/toSafeInteger';
import { type CallbackDataParams } from 'echarts/types/src/util/types';
import type EChartsReactCore from 'echarts-for-react/lib/core';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
// Material UI imports
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/system/colorManipulator';
// EmPath UI Components
import { type EChartsMouseEvent, getSvgShadingImage } from '@empathco/ui-components/src/helpers/echarts';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import { getTruncatedTitle } from '@empathco/ui-components/src/helpers/strings';
import { spacing } from '@empathco/ui-components/src/helpers/styles';
import useCombinedRefs from '@empathco/ui-components/src/hooks/useCombinedRefs';
// local imports
import { DAHiringTrends, DAJobRelatedSkills, DAJobsMovement } from '../graphql/types';
import { CONST_YEARS } from '../constants/constValues';
import { GlobalEChartsStyles } from '../config/params';
import {
  NormalizedTimelineData, generateTimelineChartData, getPreparedTimelineData, pushTimelineData
} from '../helpers/charts';
import Chart from '../elements/Chart';
// SCSS imports
import { s2L4, s3L4, s6L2, series5 } from '../styles/modules/Chart.module.scss';
import {
  chart, chartPreview, marker, cardBackground, barGradient, inferred,
  tooltip, tooltipTitle, tooltipLine, tooltipValue, tooltipHistory, tooltipColumn, tooltipCaption, tooltipBar, tooltipAxis
} from './TopChartVBars.module.scss';

const JOBS_MOVEMENT = 'jobs_movement' as const;
const HIRING_TRENDS = 'hiring_trends' as const;
const JOB_SKILLS = 'job_skills' as const;
const VBARS_VARIANTS = [JOBS_MOVEMENT, HIRING_TRENDS, JOB_SKILLS] as const;
export type VBarsVariant = typeof VBARS_VARIANTS[number];

type TopChartVBarsProps = {
  preview?: boolean;
  variant: VBarsVariant;
  data?: (DAJobsMovement | DAHiringTrends | DAJobRelatedSkills)[];
  years?: number | null;
  path?: string;
  internalHiresOnly?: boolean | null;
  pending?: boolean | null;
}

const TopChartVBarsPropTypes = {
  // attributes
  preview: PropTypes.bool,
  variant: PropTypes.oneOf(VBARS_VARIANTS).isRequired as Validator<VBarsVariant>,
  data: PropTypes.array,
  years: PropTypes.number,
  path: PropTypes.string,
  internalHiresOnly: PropTypes.bool,
  pending: PropTypes.bool
};

// eslint-disable-next-line max-lines-per-function
const TopChartVBars = forwardRef<EChartsReactCore, TopChartVBarsProps>(({
  preview = false,
  variant,
  data,
  years,
  path,
  internalHiresOnly: parentInternalHiresOnly = false,
  pending = false
}, ref) => {
  const navigate = useNavigate();
  const theme = useTheme();
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage, formatNumber } = useIntl();

  const isMovements = variant === JOBS_MOVEMENT;
  const isJobSkills = variant === JOB_SKILLS;
  const internalHiresOnly = parentInternalHiresOnly || (!preview && variant === HIRING_TRENDS &&
    every(data as DAHiringTrends[], ({ external_hires }) => !external_hires || external_hires < 1)
  );

  const innerRef = useRef<EChartsReactCore>(null);
  const chartRef = useCombinedRefs<EChartsReactCore>(ref, innerRef);

  const [activeId, setActiveId] = useState<number>();

  const tooltipMarkerColors = useMemo(() =>
    (isMovements && [
      s2L4,
      barGradient
    ]) ||
    (isJobSkills && [
      barGradient,
      inferred
    ]) ||
    [
      cardBackground,
      ...internalHiresOnly ? [] : [s3L4],
      s6L2,
      series5
    ],
    [isMovements, isJobSkills, internalHiresOnly]);

  const [series1Label, series2Label, series3Label, series4Label, noData] = useMemo(() => preview ? [] : [
    formatMessage({ id: `hr.dashboard.${variant}.series1` }),
    formatMessage({ id: `hr.dashboard.${variant}.series2` }),
    isMovements || isJobSkills ? null : formatMessage({ id: `hr.dashboard.${variant}.series3` }),
    isMovements || isJobSkills ? null : formatMessage({ id: `hr.dashboard.${variant}.series4` }),
    formatMessage({ id: `supervisor.dashboard.${variant}.empty` })
  ], [variant, isMovements, isJobSkills, preview, formatMessage]);

  const [xLabel, yLabel] = useMemo(() => preview ? [] : [
    formatMessage({ id: `hr.dashboard.${variant}.x_label` }),
    formatMessage({ id: `hr.dashboard.${variant}.y_label` })
  ], [variant, preview, formatMessage]);

  const categories = useMemo(() => preview ? map(range(6), (id) => ({ value: `{id:${id + 1}}` })) : [
    {
      value: JSON.stringify({
        id: -1,
        code: '0',
        label: ''
      })
    },
    ...map(data, ({ id, code, title }) => ({
      value: JSON.stringify({
        id,
        code,
        label: getTruncatedTitle(title)
      }),
      ...activeId && id === activeId ? {
        textStyle: {
          backgroundColor: alpha(theme.palette.secondary.main, theme.palette.action.hoverOpacity)
        }
      } : {}
    }))
  ], [activeId, data, preview, theme]);

  const { s1data, s2data, s3data, s4data, s4Markers, tooltipValues, historicalData } = useMemo(() => preview ? {
    s1data: isJobSkills ? [0.1, 0.25, 0.4, 0.55, 0.7, 0.85] : [60, 35, 30, 70, 50, 60],
    s2data: isJobSkills ? [0.9, 0.75, 0.6, 0.45, 0.3, 0.15] : [55, 25, 20, 60, 35, 45],
    s3data: isMovements || isJobSkills ? null : [10, 20, 30, 35, 25, 15],
    s4data: isMovements || isJobSkills ? null : [20, 30, 10, 50, 40, 35],
    s4Markers: isMovements || isJobSkills ? null : flatten(map([20, 30, 10, 50, 40, 35], (days, idx) => [
      { symbol: 'circle', symbolSize: spacing(0.75), coord: [idx, days] },
      { symbol: 'rect', symbolSize: [spacing(0.75), theme.shape.borderWidth], coord: [idx, 0] }
    ])),
    tooltipValues: [],
    historicalData: []
  } : transform(data as (DAJobsMovement | DAHiringTrends | DAJobRelatedSkills)[],
    ({
      s1data: s1d, s2data: s2d, s3data: s3d, s4data: s4d, s4Markers: s4mrk, tooltipValues: tltp, historicalData: hist
    }, record, dataIndex) => {
      if (isMovements) {
        const { movement_count, target_count } = record as DAJobsMovement;
        s1d.push(toSafeInteger(movement_count));
        s2d.push(toSafeInteger(target_count));
      } else if (isJobSkills) {
        const {
          average_confirmed_percentage, average_inferred_percentage,
          average_confirmed_skill, average_inferred_skill, data: histData
        } = record as DAJobRelatedSkills;
        s1d.push(toSafeInteger(average_confirmed_percentage) / 100);
        s2d.push(toSafeInteger(average_inferred_percentage) / 100);
        tltp?.push([toSafeInteger(average_confirmed_skill), toSafeInteger(average_inferred_skill)]);

        // Historical Data for Tooltip:
        const normalized = transform(sortBy(histData, 'date'), (result, { date, total_confirmed }) => {
          pushTimelineData(result, date, [total_confirmed || 0]);
        }, [] as NormalizedTimelineData<never>[]);
        if (hist && size(normalized) >= 1) {
          hist[dataIndex] = [];
          const { takeMonths, stepMonths, lastDate, prepared } =
            getPreparedTimelineData({ normalized, wide: false, years: years || CONST_YEARS[CONST_YEARS.length - 1] });
          generateTimelineChartData({
            seriesCount: 1, takeMonths, stepMonths, lastDate, prepared,
            callback: (_index, category, values) => {
              hist[dataIndex].push({ date: category, value: values[0] || 0 });
            }
          });
        }
      } else {
        const { total_open_reqs, external_hires, internal_hires, days_to_fill } = record as DAHiringTrends;
        s1d.push(toSafeInteger(total_open_reqs));
        s2d.push(toSafeInteger(external_hires));
        s3d.push(toSafeInteger(internal_hires));
        const day = toSafeInteger(days_to_fill);
        s4d.push(day);
        s4mrk.push({
          symbol: 'circle',
          symbolSize: spacing(1),
          coord: [s4d.length - 1, day]
        });
        s4mrk.push({
          symbol: 'rect',
          symbolSize: [spacing(1), theme.shape.borderWidth],
          coord: [s4d.length - 1, 0]
        });
      }
    }, {
      s1data: [null] as (number | null)[],
      s2data: [null] as (number | null)[],
      s3data: [null] as (number | null)[],
      s4data: [null] as (number | null)[],
      s4Markers: [] as object[],
      tooltipValues: isJobSkills ? [null] as (number[] | null)[] : null,
      historicalData: isJobSkills ? [] as {
        date: string;
        value: number;
      }[][] : null
    }),
    [data, years, isMovements, isJobSkills, preview, theme]
  );

  const onEvents = useMemo(() => path ? {
    click: ({ componentType, value }: EChartsMouseEvent) => {
      if (componentType === 'xAxis') try {
        const { code } = JSON.parse(value);
        if (code) navigate(injectParams(path, { role_id: code }));
      } catch (_err) {
        // nothing to do
      }
    },
    mouseover: ({ componentType, value }: EChartsMouseEvent) => {
      if (componentType === 'xAxis') try {
        setActiveId(JSON.parse(value).id);
      } catch (_err) {
        setActiveId(undefined);
      }
    },
    mouseout: ({ componentType }: EChartsMouseEvent) => {
      if (componentType === 'xAxis') setActiveId(undefined);
    }
  } : undefined, [navigate, path]);

  const xAxisLabelFormatter = useCallback((value: string) => {
    try {
      return JSON.parse(value).label;
    } catch (_err) {
      return value;
    }
  }, []);

  const yAxisLabelFormatter = useCallback((value?: number | null) => formatMessage(
    { id: `hr.dashboard.${variant}.y_value` }, { value }
  ), [variant, formatMessage]);

  const tooltipFormatter = useCallback((params: CallbackDataParams[]) => {
    const { name, dataIndex } = params[0] || {};
    try {
      const { label } = JSON.parse(name);
      const timeline = historicalData?.[dataIndex - 1];
      const timelineMax = timeline ? maxBy(timeline, 'value')?.value || 1 : 1;
      return `<div class="${tooltip}"><div class="${tooltipTitle}">${label}</div>${join(
        map(params, ({ seriesName, seriesIndex, value }) => `<div class="${tooltipLine}"><span class="${
          marker} ${tooltipMarkerColors[seriesIndex || 0]}">\u00A0</span><span>${
          seriesName}</span><span class="${tooltipValue}">${
          tooltipValues ? tooltipValues[dataIndex]?.[seriesIndex || 0] : value
        }</span></div>`), ''
      )}${timeline ? `<div class="${tooltipHistory}">${join(map(timeline, ({ date, value }) =>
        `<div class="${tooltipColumn}"><div class="${tooltipCaption}">${value}</div><div class="${barGradient} ${tooltipBar
        } bar-height-${round(75 * value / timelineMax) + 1}"></div><div class="${tooltipAxis}">${
          date}</div></div>`
      ), '')}</div>` : ''}</div>`;
    } catch (_err) {
      return null;
    }
  }, [tooltipValues, historicalData, tooltipMarkerColors]);

  // eslint-disable-next-line complexity, max-lines-per-function
  useEffect(() => {
    if (!innerRef.current) return;

    const echartInstance = innerRef.current.getEchartsInstance();

    const bar = {
      type: 'bar',
      barWidth: isMovements || isJobSkills ? '31%' : '13%',
      barGap: isMovements || isJobSkills ? '17%' : '58%',
      barCategoryGap: '21%'
    };

    const hasData = size(categories) > 1;

    const gradientColor = {
      type: 'linear',
      // eslint-disable-next-line id-length
      x: 0,
      // eslint-disable-next-line id-length
      y: 1,
      x2: 0,
      y2: 0,
      colorStops: [
        {
          offset: 0,
          color: theme.palette.chart.barGradientBottom // color at 0% position
        }, {
          offset: 1,
          color: theme.palette.chart.barGradientTop // color at 100% position
        }
      ]
    };

    echartInstance.setOption({
      ...GlobalEChartsStyles,
      silent: Boolean(preview || pending),
      ...hasData ? {} : {
        title: {
          text: noData,
          left: isMovements || isJobSkills ? '45%' : '41%',
          top: '45%',
          textStyle: {
            color: theme.palette.text.secondary,
            fontWeight: theme.typography.fontWeightRegular,
            fontSize: theme.typography.subtitle1.fontSize,
            lineHeight: theme.typography.subtitle1.lineHeight
          }
        }
      },
      grid: preview ? {
        left: 0, top: spacing(0.5), right: 0, bottom: spacing(0.25)
      } : {
        left: spacing(4),
        top: spacing(8),
        right: spacing(4),
        bottom: spacing(4),
        containLabel: true
      },
      ...!preview && hasData ? {
        legend: {
          type: 'scroll',
          bottom: 0,
          padding: spacing(2.5),
          icon: 'roundRect',
          itemWidth: spacing(1.75),
          itemHeight: spacing(1.75),
          itemGap: spacing(4.5),
          itemStyle: {
            borderWidth: theme.shape.thinBorderWidth
          },
          textStyle: {
            fontSize: 15,
            fontStyle: 'italic',
            color: theme.palette.info.caption,
            padding: spacing(1)
          },
          pageButtonItemGap: spacing(1.25),
          pageFormatter: ' ',
          pageIconSize: 18,
          pageIconColor: theme.palette.action.active,
          pageIconInactiveColor: theme.palette.action.disabled,
          pageTextStyle: {
            color: 'transparent',
            fontSize: 1,
            width: 0,
            height: 0
          }
        }
      } : {},
      ...!preview && !pending && hasData ? {
        tooltip: {
          show: true,
          confine: true,
          trigger: 'axis',
          axisPointer: { type: 'none' },
          formatter: tooltipFormatter,
          borderColor: theme.palette.misc.selectedBorder,
          borderWidth: theme.shape.borderWidth,
          backgroundColor: theme.palette.background.tooltip,
          extraCssText: `box-shadow: ${theme.shadows[5]}`
        }
      } : {},
      xAxis: {
        type: 'category',
        name: !preview && hasData ? xLabel : null,
        triggerEvent: Boolean(!preview && path),
        data: categories,
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: preview ? null : {
          interval: 0,
          rotate: 33,
          overflow: 'break',
          lineHeight: 17,
          fontSize: theme.typography.caption.fontSize,
          fontWeight: theme.typography.fontWeightMedium,
          color: theme.palette.secondary.text,
          padding: [spacing(0.375), spacing(1.5), spacing(0.375), spacing(1.5)],
          borderRadius: theme.shape.borderRadius,
          formatter: xAxisLabelFormatter
        },
        nameGap: preview ? null : -spacing(5),
        nameTextStyle: preview ? null : {
          fontSize: 13.5,
          fontWeight: theme.typography.fontWeightMedium,
          color: theme.palette.info.caption,
          verticalAlign: 'top',
          padding: [spacing(2.5), 0, 0, 0]
        }
      },
      yAxis: [
        {
          type: 'value',
          name: !preview && hasData ? yLabel : null,
          minInterval: isJobSkills ? 0.05 : 1,
          ...isJobSkills && hasData ? { min: 0, max: 1 } : {},
          splitLine: preview ? null : {
            lineStyle: {
              color: isMovements || isJobSkills ? theme.palette.greys.border : theme.palette.chart.gridBars
            }
          },
          axisLabel: preview ? null : {
            fontSize: 14,
            color: theme.palette.text.label,
            ...isJobSkills ? { formatter: yAxisLabelFormatter } : {}
          },
          nameGap: preview ? null : spacing(3.5),
          nameTextStyle: preview ? null : {
            fontSize: 13.5,
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.info.caption,
            verticalAlign: 'bottom',
            padding: [0, 0, 0, spacing((isJobSkills && 16) || (isMovements && 8) || 1)]
          }
        },
        ...isMovements || isJobSkills ? [] : [
          {
            type: 'value',
            position: 'right',
            minInterval: 1,
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: preview ? null : {
              fontSize: 14,
              color: theme.palette.chart.series5,
              formatter: yAxisLabelFormatter
            }
          }
        ]
      ],
      ...hasData ? {
        series: isMovements || isJobSkills ? [
          {
            ...bar,
            name: series1Label,
            data: s1data,
            ...isJobSkills ? { stack: 'skills' } : {},
            itemStyle: isJobSkills ? {
              borderRadius: [0, 0, theme.shape.smallBorderRadius, theme.shape.smallBorderRadius],
              color: gradientColor
            } : {
              borderRadius: theme.shape.smallBorderRadius,
              color: theme.palette.chart.series2level4
            }
          },
          {
            ...bar,
            name: series2Label,
            data: s2data,
            ...isJobSkills ? { stack: 'skills' } : {},
            itemStyle: isJobSkills ? {
              borderColor: theme.palette.primary.main,
              borderWidth: theme.shape.thinBorderWidth,
              borderRadius: [theme.shape.smallBorderRadius, theme.shape.smallBorderRadius, 0, 0],
              color: {
                type: 'pattern',
                repeat: 'repeat',
                // eslint-disable-next-line id-length
                x: 0,
                // eslint-disable-next-line id-length
                y: 0,
                rotation: 0,
                scaleX: 0.5,
                scaleY: 0.5,
                image: getSvgShadingImage(theme.palette.primary.main, theme.palette.background.paper)
              }
            } : {
              borderRadius: theme.shape.smallBorderRadius,
              color: gradientColor
            }
          }
        ] : [
          {
            ...bar,
            name: series1Label,
            data: s1data,
            itemStyle: {
              borderRadius: theme.shape.smallBorderRadius,
              color: theme.palette.background.card,
              borderColor: theme.palette.misc.selectedBorder,
              borderWidth: theme.shape.thinBorderWidth
            }
          },
          ...internalHiresOnly ? [] : [
            {
              ...bar,
              name: series2Label,
              data: s2data,
              stack: 'hires',
              itemStyle: {
                borderRadius: theme.shape.smallBorderRadius,
                color: theme.palette.chart.series3level4
              }
            }
          ],
          {
            ...bar,
            name: series3Label,
            data: s3data,
            stack: 'hires',
            itemStyle: {
              borderRadius: theme.shape.smallBorderRadius,
              color: theme.palette.chart.series6level2 // === series4
            }
          },
          {
            ...bar,
            barWidth: theme.shape.borderWidth,
            yAxisIndex: 1,
            name: series4Label,
            data: s4data,
            markPoint: {
              data: s4Markers
            },
            itemStyle: {
              color: theme.palette.chart.series5
            }
          }
        ]
      } : {}
    }, true);
    echartInstance.resize();
  }, [
    categories, s1data, s2data, s3data, s4data, s4Markers, pending,
    series1Label, series2Label, series3Label, series4Label, noData, xLabel, yLabel,
    path, isMovements, isJobSkills, internalHiresOnly, preview, theme,
    xAxisLabelFormatter, yAxisLabelFormatter, tooltipFormatter, formatNumber
  ]);

  return (
    <Chart
        ref={chartRef}
        option={GlobalEChartsStyles}
        onEvents={pending ? undefined : onEvents}
        className={preview ? chartPreview : chart}
    />
  );
});

TopChartVBars.displayName = 'TopChartVBars';

TopChartVBars.propTypes = TopChartVBarsPropTypes;

export default memo(TopChartVBars);
