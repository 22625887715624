import { gql } from '@apollo/client';
// local imports
import { API_ADMIN_REPORTS } from '../config/api';

export const ADMIN_REPORTS_QUERY = gql`
  query AdminReports {
    adminReports
    @rest(
      type: "AdminReportsPayload",
      path: "${API_ADMIN_REPORTS}?limit=100"
    ) {
      count
      results {
        id
        title
        report_type
        description
      }
    }
  }
`;
