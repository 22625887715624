import { forwardRef, memo, useContext, useMemo } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import { useMutation } from '@apollo/client';
// EmPath UI Components
import { GetComponentProps } from '@empathco/ui-components/src/helpers/types';
import useMutationMethod from '@empathco/ui-components/src/hooks/useMutationMethod';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
// local imports
import { UPDATE_OPPORTUNITY_MATCH } from '../graphql/UpdateOpportunityMatch';
import {
  BookingStatus, MyOpportunityStatus, Opportunity, OpportunityMatch, UpdateOpportunityMatchDocument
} from '../graphql/types';
import { OpportunityEmployee } from '../graphql/customTypes';
import { GlobalContext } from '../context/global';
import PaginationControls from '../v3/PaginationControls';
import CardsGrid from '../v3/CardsGrid';
import EmployeeCard from '../v3/EmployeeCard';

type OpportunityMatchesPanelProps = {
  opportunity?: Opportunity | null;
  disabled?: boolean | null;
  matches?: OpportunityMatch[] | null;
  count?: number | null;
  pending?: boolean | null;
  failed?: boolean | null;
  currentPage: number;
  onPageSelected: (page: number) => void;
  onPageSize: (pageSize: number) => void;
}

const OpportunityMatchesPanelPropTypes = {
  opportunity: PropTypes.object as Validator<Opportunity>,
  disabled: PropTypes.bool,
  matches: PropTypes.array as Validator<OpportunityMatch[]>,
  count: PropTypes.number,
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  currentPage: PropTypes.number.isRequired,
  onPageSelected: PropTypes.func.isRequired,
  onPageSize: PropTypes.func.isRequired
};

const OpportunityMatchesPanel = forwardRef<HTMLDivElement, OpportunityMatchesPanelProps>(({
  opportunity,
  disabled = false,
  matches,
  count,
  pending = false,
  failed = false,
  currentPage,
  onPageSelected,
  onPageSize
}, ref) => {
  const { paths: { supvEmplPath } } = useContext(GlobalContext);

  const { id: opportunity_id } = opportunity || {};

  const { mutate: updateMatch, loading: updatePending, failed: updateFailed } = useMutationMethod({
    // TODO: key: 'updateOpportunityMatch', // -- restore when backend responds with `success: true`
    mutation: useMutation(UPDATE_OPPORTUNITY_MATCH as typeof UpdateOpportunityMatchDocument)
  });

  const componentProps: Partial<GetComponentProps<typeof EmployeeCard>> = useMemo(() => ({
    route: supvEmplPath,

    matchRateVariant: 'default',
    growthRateVariant: 'planned',
    avatarVariant: 'opportunity',

    // 'X' button (card top right)
    onRemove: opportunity_id ? ({ id }) => {
      updateMatch?.({
        variables: { opportunity_id, match_id: id, input: { owner_status: MyOpportunityStatus.hidden } },
        // TODO: optimistic response
        update: (cache) => {
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunityMatches' });
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunities' }); // need to update counts
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunity' }); // need to update counts
        }
      });
    } : undefined,

    // Short-list
    actionLabel: 'opportunities.button.shortlist',
    actionFinishedLabel: 'opportunities.button.shortlisted',
    onAction: opportunity_id ? (id) => {
      updateMatch?.({
        variables: { opportunity_id, match_id: id, input: { owner_status: MyOpportunityStatus.shortlist } },
        // TODO: optimistic response
        update: (cache) => {
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunityMatches' });
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunityBookings' });
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunities' }); // need to update counts
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunity' }); // need to update counts
        }
      });
    } : undefined
  }), [opportunity_id, supvEmplPath, updateMatch]);

  const employees = useMemo(() => matches ? map(matches, ({
    id, is_new, employee, match_rate, growth_rate, employee_skills, booking, owner_status
  }) => ({
    ...employee,
    id, // substitute match id for employee id (for calling updateMatch with it)
    is_applicant: Boolean(booking?.status === BookingStatus.employee_requested || booking?.employee_requested_at),
    is_shortlisted: Boolean(owner_status === MyOpportunityStatus.shortlist),
    is_new,
    match_rate,
    growth_rate,
    skills: employee_skills
  } as OpportunityEmployee)) : undefined, [matches]);

  return (
    <>
      <CardsGrid
          ref={ref}
          items={employees}
          variant="white"
          withReloading
          pending={pending}
          failed={failed}
          disabled={disabled || updatePending}
          component={EmployeeCard}
          ComponentProps={componentProps}
          notFoundMessage="opportunities.matches.empty"
          filters={null}
          pagination={(
            <PaginationControls
                settingsId="opp_matches"
                loaded={Boolean(matches)}
                pending={pending}
                loading={pending}
                total={count}
                currentPage={currentPage}
                onPageSelected={onPageSelected}
                onPageSize={onPageSize}
                disabled={disabled || failed}
                totalMessage="opportunities.matches.pagination"
            />
          )}
      />
      <ActionFailedAlert
          message="opportunities.matches.update.error"
          open={updateFailed}
      />
    </>
  );
});

OpportunityMatchesPanel.displayName = 'OpportunityMatchesPanel';

OpportunityMatchesPanel.propTypes = OpportunityMatchesPanelPropTypes;

export default memo(OpportunityMatchesPanel);
