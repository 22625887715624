import { gql } from '@apollo/client';
// local imports
import { API_DA_SKILLS_DEVELOPMENT } from '../config/api';

export const DA_SKILLS_DEVELOPMENT_QUERY = gql`
  query DASkillsDevelopment(
    $input: DASkillsGrowthDevInput!
    $pathBuilder: String!
  ) {
    daSkillsDevelopment(
      input: $input
    )
    @rest(
      type: "DASkillsDevelopmentPayload",
      path: "${API_DA_SKILLS_DEVELOPMENT}",
      pathBuilder: $pathBuilder
    ) {
      total_employees
      skills {
        id
        title
        abbr
        target_skill_count
        employee_skill_count
        advisor_count
        employee_advisor_count
        skill_course_hours
        top_courses {
          id
          title
          code
          abbr
          external_url
        }
      }
    }
  }
`;
