import { forwardRef, memo, useCallback, useMemo, type ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
// Material Icon imports
import CheckBold from 'mdi-material-ui/CheckBold';
// SCSS imports
import { icon, smallIcon, inactive } from './CheckboxButton.module.scss';

type CheckboxButtonProps = {
  id?: number;
  label?: string;
  checked?: boolean;
  onChange?: (checked: boolean, id?: number) => void;
  disabled?: boolean;
  small?: boolean;
  className?: string;
}

const CheckboxButtonPropTypes = {
  // attributes
  id: PropTypes.number,
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  className: PropTypes.string
};

const CheckboxButton = forwardRef<HTMLButtonElement, CheckboxButtonProps>(({
  id,
  label,
  checked = false,
  onChange,
  disabled = false,
  small = false,
  className
}, ref) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();

  const clsName = `${small ? smallIcon : icon} ${disabled ? inactive : ''}`;

  const handleChange = useCallback((_event: ChangeEvent<HTMLInputElement>, chk: boolean) => {
    onChange?.(chk, id);
  }, [onChange, id]);

  const inputProps = useMemo(() => ({
    'aria-label': formatMessage({ id: label || 'common.checkbox.label', defaultMessage: label })
  }), [label, formatMessage]);

  return (
    <Checkbox
        ref={ref}
        checked={checked}
        color="secondary"
        onChange={handleChange}
        inputProps={inputProps}
        disabled={disabled}
        icon={<Box className={clsName}/>}
        checkedIcon={
          <Box className={clsName}>
            <CheckBold color="primary"/>
          </Box>
        }
        className={className}
    />
  );
});

CheckboxButton.displayName = 'CheckboxButton';

CheckboxButton.propTypes = CheckboxButtonPropTypes;

export default memo(CheckboxButton);
