import { forwardRef, memo } from 'react';
// Material UI imports
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
// Material Icon imports
import AddRounded from '@mui/icons-material/AddRounded';
// SCSS imports
import { plusChip } from './PlusChip.module.scss';

const PlusChip = forwardRef<HTMLDivElement>((_props, ref) => (
  <Box m={1}>
    <Chip
        ref={ref}
        icon={(
          <Box
              pl={4.5}
              pr={2.5}
              display="flex"
              alignItems="center"
              className={plusChip}
          >
            <AddRounded fontSize="inherit"/>
          </Box>
        )}
    />
  </Box>
));

PlusChip.displayName = 'PlusChip';

export default memo(PlusChip);
