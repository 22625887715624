import filter from 'lodash/filter';
import isArray from 'lodash/isArray';
import isSafeInteger from 'lodash/isSafeInteger';
// EmPath UI Components
import { isEmptyString } from '@empathco/ui-components/src/helpers/strings';
// local imports
import {
  MY_SKILLS_FETCH, MY_SKILLS_UPDATED, MY_SKILLS_UPDATING,
  MY_TARGET_SKILLS_UPDATED, MY_TARGET_SKILLS_UPDATING, SKL_FETCH
} from './dataContext';
import { isValidBuilderStep, isValidInferredScope } from '../constants/builder';
import { isValidSortParam, isValidSortDirection } from '../constants/skillsSort';
import {
  axiosInstance as axios,
  getApiEmployeeSkills, getApiSkill,
  API_MY_INFERRED_SKILLS, API_MY_SKILLS, API_MY_SKILLS_SET, API_MY_TARGET_SKILLS_SET
} from '../config/api';
import { MAX_INFERRED_SKILLS } from '../config/params';
import { fetchFactory } from '../helpers/actions';
import { invalidateApolloCacheSkills } from '../graphql/helpers';
import { getRequestHeaders, optimizeParams } from '../helpers/context';
import { transformEditableSks } from './dataTranformations';

export const requireSkl = (token, online, unauthenticate, dispatch, skl) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  type: SKL_FETCH,
  entity: skl,
  params: ['skill_id'],
  validator: ({ skill_id }) => !isEmptyString(skill_id),
  api: ({ skill_id }) => getApiSkill(skill_id),
  dropParams: ['skill_id'],
  results: ''
});

export const requireEditableSkills = (token, online, unauthenticate, dispatch, editableSks, routeParams) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  type: MY_SKILLS_FETCH,
  routeParams,
  entity: editableSks,
  withCount: true,
  params: ({ inferred, limit, offset, scope, sort_by, direction, inferences_only, forced }) => inferred === true ? {
    inferred,
    scope: isValidInferredScope(scope) ? scope : null,
    forced,
    limit: MAX_INFERRED_SKILLS
  } : {
    sort_by: isValidSortParam(sort_by) ? sort_by : null,
    direction: isValidSortParam(sort_by) && isValidSortDirection(direction) ? direction : null,
    inferences_only: inferences_only ? inferences_only : null,
    limit: limit && limit >= 1 && isSafeInteger(limit) ? limit : null,
    offset: offset && offset >= 1 && isSafeInteger(offset) ? offset : null
  },
  api: ({ inferred, selected_employee_id }) => (
    selected_employee_id && getApiEmployeeSkills(selected_employee_id)) || (
    inferred ? API_MY_INFERRED_SKILLS : API_MY_SKILLS
  ),
  dropParams: ['inferred', 'forced', 'selected_employee_id'],
  transformation: transformEditableSks
});

export const updateSkills = (token, online, dispatch, skillsUpdatePending, updateOnboardingSteps) => async ({
  levels, is_onboarding, onboarding_step, onSuccess, apolloCache
} = {}) => {
  if (!token || skillsUpdatePending || !levels || !isArray(levels)) return;
  const params = optimizeParams({
    levels, // TODO: improve `levels` verification
    is_onboarding: is_onboarding === true ? true : null,
    onboarding_step: isValidBuilderStep(onboarding_step) ? onboarding_step : null
  }, online);
  try {
    if (!online) throw new Error();
    dispatch({
      type: MY_SKILLS_UPDATING,
      params
    });
    const { status, data } = await axios.request({
      method: 'POST',
      url: API_MY_SKILLS_SET,
      data: params,
      headers: getRequestHeaders(token)
    }) || {};
    if (status < 200 || status > 201 || !data || !data.success) throw new Error();
    onSuccess?.();
    if (apolloCache) invalidateApolloCacheSkills(apolloCache);
    dispatch({
      type: MY_SKILLS_UPDATED,
      payload: true,
      params
    });
    if (updateOnboardingSteps) updateOnboardingSteps(params.onboarding_step);
  } catch (error) {
    dispatch({
      type: MY_SKILLS_UPDATED,
      payload: false,
      params
    });
  }
};

export const updateTargetSkills = (
  token, online, dispatch, targetSkillsUpdatePending, routeParams, updateOnboardingSteps
) => async ({
  targets, onboarding_step, onSuccess
} = {}) => {
  if (!token || targetSkillsUpdatePending || !targets || !isArray(targets)) return;
  const params = optimizeParams({
    ...routeParams,
    targets: filter(targets, (skillId) => skillId && skillId >= 1 && isSafeInteger(skillId)),
    onboarding_step: isValidBuilderStep(onboarding_step) ? onboarding_step : null
  }, online);
  try {
    if (!online) throw new Error();
    dispatch({
      type: MY_TARGET_SKILLS_UPDATING,
      params
    });
    const { status, data } = await axios.request({
      method: 'POST',
      url: API_MY_TARGET_SKILLS_SET,
      data: params,
      headers: getRequestHeaders(token)
    }) || {};
    if (status < 200 || status > 201 || !data || !data.success) throw new Error();
    if (onSuccess) onSuccess();
    dispatch({
      type: MY_TARGET_SKILLS_UPDATED,
      payload: true,
      params
    });
    if (updateOnboardingSteps) updateOnboardingSteps(params.onboarding_step);
  } catch (error) {
    dispatch({
      type: MY_TARGET_SKILLS_UPDATED,
      payload: false,
      params
    });
  }
};
