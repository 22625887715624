import { memo, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import toString from 'lodash/toString';
// Material UI imports
import Box from '@mui/material/Box';
// EmPath UI Components
import { shape } from '@empathco/ui-components/src/styles/themeOptions';
// local imports
import { Skill } from '../models/skill';
import SkillChip from '../elements/SkillChip';
import JobSkillsResetButton from '../elements/JobSkillsResetButton';

type SkillsSelectionVariant = 'default' | 'analytics'; // TODO: `default` is unused

type EditJobAdvancedSkillsProps = {
  variant?: SkillsSelectionVariant;
  skills: Skill[];
  advancedIds: Record<string, boolean>;
  onActivate: (id: number) => void;
  onApply?: () => void;
  onReset?: () => void;
  disabled?: boolean | null;
  disableUnselected?: boolean;
}

const EditJobAdvancedSkillsPropTypes = {
  variant: PropTypes.string as Validator<SkillsSelectionVariant>,
  skills: PropTypes.array.isRequired,
  advancedIds: PropTypes.object.isRequired as Validator<Record<string, boolean>>,
  onActivate: PropTypes.func.isRequired,
  onApply: PropTypes.func,
  onReset: PropTypes.func,
  disabled: PropTypes.bool,
  disableUnselected: PropTypes.bool
};

const EditJobAdvancedSkills: FunctionComponent<EditJobAdvancedSkillsProps> = ({
  variant = 'default',
  skills,
  advancedIds,
  onActivate,
  onApply,
  onReset,
  disabled = false,
  disableUnselected = false
}) => {
  const isAnalytics = variant === 'analytics';
  return (
    <Box
        bgcolor={isAnalytics ? 'background.dashboard' : 'background.darker'}
        display="flex"
        flexWrap="wrap"
        justifyContent={isAnalytics ? undefined : 'center'}
        p={1}
        borderRadius={shape.tinyBorderRadius}
    >
      {map(skills, (skill) => {
        const active = advancedIds[toString(skill.id)] || undefined;
        return (
          <SkillChip
              key={skill.id}
              skill={skill}
              active={active}
              onActivate={onActivate}
              disabled={disabled || (disableUnselected && !active) ? true : undefined}
          />
        );
      })}
      {isAnalytics ? (
        <JobSkillsResetButton
            title="hr.dashboard.button.apply"
            onReset={onApply}
            disabled={disabled}
        />
      ) : undefined}
      <JobSkillsResetButton
          onReset={onReset}
          disabled={disabled}
      />
    </Box>
  );
};

EditJobAdvancedSkills.propTypes = EditJobAdvancedSkillsPropTypes;

export default memo(EditJobAdvancedSkills);
