import { useCallback, useEffect, useLayoutEffect, useMemo, useState, type Dispatch, type SetStateAction } from 'react';
import map from 'lodash/map';
import size from 'lodash/size';
import keyBy from 'lodash/keyBy';
import union from 'lodash/union';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import lt from 'lodash/lt';
import gte from 'lodash/gte';
import indexOf from 'lodash/indexOf';
import without from 'lodash/without';
import unionBy from 'lodash/unionBy';
import getValues from 'lodash/values';
import forEach from 'lodash/forEach';
import reduce from 'lodash/reduce';
import transform from 'lodash/transform';
import intersection from 'lodash/intersection';
import toString from 'lodash/toString';
import update, { type CustomCommands } from 'immutability-helper';
import { useDrop, type DropTargetHookSpec, type FactoryOrInstance } from 'react-dnd';
// local imports
import { Identified } from '../models/item';

export type DnDExtraIds = number[]; // selected item ids
export type DnDExtraSetIds = Dispatch<SetStateAction<number[]>>;

function useDnD<T extends Identified, D extends string>({
  accept,
  allItems,
  ids,
  setIds,
  userAddedIds,
  onAdd,
  onDelete,
  onAddedItemsChange,
  onSelectionChange,
  onReset,
  disabled,
  manageAddedItems = false,
  updateIdsOnDelete = false,
  extraIds: parentExtraIds,
  extraSetIds: parentExtraSetIds
}: {
  accept: D;
  allItems: T[]; // union of fetched items + added items
  ids: number[]; // selected item ids
  setIds: Dispatch<SetStateAction<number[]>>;
  userAddedIds?: number[];
  onAdd?: (item: T) => void;
  onDelete?: (id: number) => void;
  onAddedItemsChange?: (addedIds: number[], addedItems?: T[]) => void;
  onSelectionChange: (ids: number[], extraIndex?: number) => void;
  onReset?: () => void;
  disabled?: boolean | null;
  manageAddedItems?: boolean;
  updateIdsOnDelete?: boolean;
  extraIds?: DnDExtraIds[];
  extraSetIds?: DnDExtraSetIds[];
}) {
  const hasExtra = Boolean(parentExtraIds && parentExtraSetIds) && size(parentExtraIds) === size(parentExtraSetIds);
  const extraIds = hasExtra ? parentExtraIds : undefined;
  const extraSetIds = hasExtra ? parentExtraSetIds : undefined;

  // fetched items + added items
  const [items, setItems] = useState<Record<number, T> | null>(null); // hash by id
  const [excludeIds, setExcludeIds] = useState<number[] | null>(null); // ids array

  // ids of added items
  const [addedIds, setAddedIds] = useState<number[]>(userAddedIds || []);
  useEffect(() => {
    if (userAddedIds) setAddedIds(userAddedIds);
  }, [userAddedIds]);

  useLayoutEffect(() => {
    if (allItems) {
      setItems(keyBy(allItems, 'id'));
      setExcludeIds(map(allItems, 'id'));
    }
  }, [allItems]);

  const availableIds = useMemo(() => without(excludeIds, ...ids, ...extraIds
    ? reduce(extraIds, (result, eids) => union(result, eids), [] as number[])
    : []
  ), [excludeIds, ids, extraIds]);

  const getIds = useCallback((extraIndex?: number) =>
    (!isNil(extraIndex) && extraIds?.[extraIndex]) || ids, [ids, extraIds]);
  const getSetIds = useCallback((extraIndex?: number) =>
    (!isNil(extraIndex) && extraSetIds?.[extraIndex]) || setIds, [extraSetIds, setIds]);

  const deleteItem = useCallback((id: number) => {
    if (onDelete) onDelete(id);
    else {
      const newAddedItemIds = without(addedIds, id);
      setAddedIds(newAddedItemIds);
      if (manageAddedItems) setItems(omit(items, toString(id)));
      if (updateIdsOnDelete) forEach([ids, ...extraIds || []], (currentIds, idx) => {
        if (indexOf(currentIds, id) < 0) return;
        const currentSetIds = idx === 0 ? setIds : extraSetIds?.[idx - 1];
        if (!currentSetIds) return;
        const updatedExtraIds = without(currentIds, id);
        currentSetIds(updatedExtraIds);
        onSelectionChange(updatedExtraIds, idx === 0 ? undefined : idx - 1);
      });
      onAddedItemsChange?.(newAddedItemIds, (manageAddedItems && (
        items ? transform(newAddedItemIds, (result, sid) => {
          const itm = items[sid];
          if (itm) result.push(itm);
        }, [] as T[]) : []
      )) || undefined);
    }
  }, [
    items, ids, extraIds, addedIds, setIds, extraSetIds,
    onAddedItemsChange, onDelete, onSelectionChange, manageAddedItems, updateIdsOnDelete
  ]);

  const addItem = useCallback((item?: T | null) => {
    if (onAdd) {
      if (item) onAdd(item);
    } else {
      const newIds = item ? [item.id] : [];
      const newItems = item ? keyBy(unionBy(getValues(items), [item], 'id'), 'id') : items;
      if (item) setItems(newItems);
      setExcludeIds((prevIds) => union(prevIds, newIds));
      const newAddedItemIds = union(addedIds, newIds);
      setAddedIds(newAddedItemIds);
      onAddedItemsChange?.(newAddedItemIds, (manageAddedItems && (
        newItems ? transform(newAddedItemIds, (result, id) => {
          const itm = newItems[id];
          if (itm) result.push(itm);
        }, [] as T[]) : []
      )) || undefined);
    }
  }, [items, addedIds, onAdd, onAddedItemsChange, manageAddedItems]);

  const findItemIndex = useCallback((id: number, extraIndex?: number): number =>
    indexOf(getIds(extraIndex), id), [getIds]);

  const reorderItems = useCallback((updatedIds?: number[] | null, extraIndex?: number): void => {
    const newIds = updatedIds || getIds(extraIndex);
    onSelectionChange(newIds, extraIndex);
    if (extraIds) forEach([ids, ...extraIds], (currentIds, idx) => {
      if ((idx === 0 && isNil(extraIndex)) || (idx - 1) === extraIndex || size(intersection(currentIds, newIds)) < 1) return;
      const updatedExtraIds = without(currentIds, ...newIds);
      onSelectionChange(updatedExtraIds, idx === 0 ? undefined : idx - 1);
    });
  }, [ids, extraIds, getIds, onSelectionChange]);

  const moveItem = useCallback(
    (id: number, atIndex: number | null, extraIndex?: number): number[] => {
      const index = findItemIndex(id, extraIndex);
      const currentIds = getIds(extraIndex);
      const updatedIds = update<number[], CustomCommands<{}>>(currentIds, {
        $splice: [
          ...index >= 0 ? [[index, 1]] : [],
          ...isNil(atIndex) || atIndex >= 0 ? [[isNil(atIndex) ? size(currentIds) : atIndex, 0, id]] : []
        ]
      });
      getSetIds(extraIndex)?.(updatedIds);
      return updatedIds;
    },
    [getIds, getSetIds, findItemIndex]
  );

  const unselectItem = useCallback((id: number) => {
    forEach([ids, ...extraIds || []], (currentIds, idx) => {
      if (indexOf(currentIds, id) < 0) return;
      const currentSetIds = idx === 0 ? setIds : extraSetIds?.[idx - 1];
      if (!currentSetIds) return;
      const updatedIds = without(currentIds, id);
      currentSetIds(updatedIds);
      reorderItems(updatedIds, idx === 0 ? undefined : idx - 1);
    });
  }, [ids, extraIds, setIds, extraSetIds, reorderItems]);

  const resetItems = useCallback(() => {
    if (onReset) onReset();
    else {
      setIds?.([]);
      forEach(extraSetIds, (currentSetIds) => currentSetIds?.([]));
    }
  }, [extraSetIds, setIds, onReset]);

  const getDropArgs = useCallback(
    (isMain: boolean, extraIndex?: number) => ({
      accept,
      ...isMain ? {} : {
        canDrop: () => !disabled
      },
      collect: (monitor) => ({
        isOver: monitor.canDrop() && monitor.isOver() &&
          (isMain ? gte : lt)(indexOf(getIds(extraIndex), monitor.getItem<T>().id), 0)
      }),
      drop({ id }, monitor) {
        // eslint-disable-next-line no-console
        // console.log(`${extraIndex || '[parent]'}.drop: id=${id} [index=${findItemIndex(id, extraIndex)}] didDrop=${
        //   monitor.didDrop()}`);
        if (!monitor.didDrop()) {
          if ((isMain ? gte : lt)(findItemIndex(id, extraIndex), 0)) {
            reorderItems(moveItem(id, isMain ? -1 : null, extraIndex), extraIndex);
          } else {
            reorderItems(undefined, extraIndex);
          }
        }
      }
    }) as FactoryOrInstance<DropTargetHookSpec<T, void, { isOver: boolean; }>>,
    [getIds, disabled, moveItem, findItemIndex, reorderItems, accept]
  );

  const mainDropArg = useMemo(() => getDropArgs(!hasExtra), [hasExtra, getDropArgs]);
  const selectedDropArg = useMemo(() => getDropArgs(false), [getDropArgs]);

  const [{ isOver: isDragging }, drop] = useDrop(mainDropArg);
  const [{ isOver: isActive }, selectedDrop] = useDrop(selectedDropArg);

  return useMemo(() => ({
    items,
    addedIds,
    availableIds,
    excludeIds,
    addItem,
    deleteItem,
    moveItem,
    unselectItem,
    resetItems,
    // Drag and Drop state
    isDragging,
    drop,
    isActive,
    selectedDrop,
    getDropArgs
  }), [
    addItem, addedIds, availableIds, deleteItem, drop, excludeIds, getDropArgs, isActive, isDragging, items,
    moveItem, resetItems, selectedDrop, unselectItem
  ]);
}

export default useDnD;
