import { gql } from '@apollo/client';
// local imports
import { API_SUPV_TARGETED_SKLS } from '../config/api';

export const MD_TARGETED_SKILLS_QUERY = gql`
  query MDTargetedSkills(
    $input: MDInput!
    $pathBuilder: String!
  ) {
    mdTargetedSkills(
      input: $input
    )
    @rest(
      type: "MDTargetedSkillsPayload",
      path: "${API_SUPV_TARGETED_SKLS}",
      pathBuilder: $pathBuilder
    ) {
      job_id
      count
      employees_count
      results {
        id
        title
        abbr
        type
        employees_count
      }
    }
  }
`;
