import { gql } from '@apollo/client';
// local imports
import { API_SUPV_OPPORTUNITY } from '../config/api';

export const UPDATE_OPPORTUNITY_BOOKING = gql`
  mutation UpdateOpportunityBooking(
    $opportunity_id: Int!
    $booking_id: Int!
    $input: UpdateOpportunityBookingInput!
  ) {
    updateOpportunityBooking(
      opportunity_id: $opportunity_id,
      booking_id: $booking_id
      input: $input
    )
    @rest(
      type: "MutationResponse",
      path: "${API_SUPV_OPPORTUNITY}bookings/{args.booking_id}/",
      method: "PATCH"
    ) {
      success
      optimistic
    }
  }
`;
