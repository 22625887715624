import { gql } from '@apollo/client';
// local imports
import { API_EMPLOYEE_ADVISOR } from '../config/api';

export const UPDATE_EMPLOYEE_ADVISOR = gql`
  mutation UpdateEmployeeAdvisor(
    $advisor_id: Int!
    $input: UpdateEmployeeAdvisorInput!
  ) {
    updateEmployeeAdvisor(
      advisor_id: $advisor_id,
      input: $input
    )
    @rest(
      type: "MutationResponse",
      path: "${API_EMPLOYEE_ADVISOR}",
      method: "PATCH"
    ) {
      success
      # TODO: optimistic
    }
  }
`;
