import { gql } from '@apollo/client';
// local imports
import { API_CAREER_LADDERS } from '../config/api';

export const CAREER_LADDERS_QUERY = gql`
  query CareerLadders(
    $limit: Int
  ) {
    careerLadders(
      limit: $limit
    )
    @rest(
      type: "LookupPayload",
      path: "${API_CAREER_LADDERS}?{args}"
    ) {
      count
      results {
        id
        title
      }
    }
  }
`;
