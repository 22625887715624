import { gql } from '@apollo/client';
// local imports
import { API_ADMIN_EMPLOYEE } from '../config/api';

export const UPDATE_ADMIN_EMPLOYEE = gql`
  mutation UpdateAdminEmployee(
    $employee_id: Int!
    $input: AdminEmployeeInput!
  ) {
    updateAdminEmployee(
      employee_id: $employee_id,
      input: $input
    )
    @rest(
      type: "MutationResponse",
      path: "${API_ADMIN_EMPLOYEE}",
      method: "PATCH"
    ) {
      success
    }
  }
`;
