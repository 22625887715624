import { gql } from '@apollo/client';
// local imports
import { API_MY_OPPORTUNITIES_BROWSE } from '../config/api';

export const MY_OPPORTUNITIES_BROWSE_QUERY = gql`
  query MyOpportunitiesBrowse(
    $search: String
    $hide_shortlist: Boolean
    $hide_booking: Boolean
    $opportunity_owner_id: Int
    $country_id: Int
    $state_id: Int
    $location_id: Int
    $sort_by: MyOpportunitiesSortOrder
    $direction: SortDirection
    $offset: Int
    $limit: Int
  ) {
    myOpportunitiesBrowse(
      search: $search,
      hide_shortlist: $hide_shortlist,
      hide_booking: $hide_booking,
      opportunity_owner_id: $opportunity_owner_id,
      country_id: $country_id,
      state_id: $state_id,
      location_id: $location_id,
      sort_by: $sort_by,
      direction: $direction,
      offset: $offset,
      limit: $limit
    )
    @rest(
      type: "MyOpportunitiesPayload",
      path: "${API_MY_OPPORTUNITIES_BROWSE}?{args}",
    ) {
      count
      results {
        id
        is_new
        match_rate
        growth_rate
        employee_status
        opportunity {
          id
          title
          duration_unit
          duration_value
          description
          start_date
          status
          scope_manager_id
          onsite
          sidegig
          timezone_minutes
          # created_at
          published_at
          updated_at
          started_at
          archived_at
          timestamp @client
          location {
            id
            city
            state
            country
            title @client
          }
          owner {
            id
            code
            first_name
            last_name
            email
          }
          skills {
            id
            title
            abbr
            skill_proficiency_level
            status
          }
        }
        booking {
          id
          employee_id
          status
          employee_requested_at
          manager_requested_at
          confirmed_at
        }
        employee_skills {
          id
          title
          abbr
          current_level
          inferred_level
          is_inference_newer
        }
      }
    }
  }
`;
