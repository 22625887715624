import { gql } from '@apollo/client';
// local imports
import { API_LOOKUP_LEADERS } from '../config/api';

export const LEADERS_SEARCH_QUERY = gql`
  query LeadersSearch(
    $search: String
    $limit: Int
  ) {
    leadersSearch(
      search: $search,
      limit: $limit
    )
    @rest(
      type: "LeadersPayload",
      path: "${API_LOOKUP_LEADERS}?{args}"
    ) {
      count
      results {
        id
        first_name
        last_name
        code
        email
        management_level
        title @client
      }
    }
  }
`;
