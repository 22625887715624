import { forwardRef, memo, type Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box, { type BoxProps } from '@mui/material/Box';
// SCSS imports
import { newTag, normal, invisible } from './SkillNewTag.module.scss';

type SkillNewTagProps = {
  active?: boolean | null;
  className?: string;
}

const SkillNewTagPropTypes = {
  active: PropTypes.bool,
  className: PropTypes.string
};

const SkillNewTag = forwardRef<Component<BoxProps>, SkillNewTagProps>(({
  active = false,
  className
}, ref) => (
  <Box
      ref={ref}
      aria-hidden={active ? undefined : 'true'}
      className={clsx(newTag, { [normal]: active, [invisible]: !active }, className)}
  >
    <FormattedMessage id="common.new_skill"/>
  </Box>
));

SkillNewTag.displayName = 'SkillNewTag';

SkillNewTag.propTypes = SkillNewTagPropTypes;

export default memo(SkillNewTag);
