import { forwardRef, memo, useContext, useEffect, useMemo, useState, useRef } from 'react';
import isNil from 'lodash/isNil';
import toSafeInteger from 'lodash/toSafeInteger';
import { Link as RouterLink } from 'react-router-dom';
import { useIntl } from 'react-intl';
// Material UI imports
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
// Material Icon imports
import Notifications from '@mui/icons-material/Notifications';
// local imports
import { MAX_UNREAD_NOTIFICATIONS, NOTIFICATIONS_POLLING_INTERVAL } from '../config/params';
import { PATH_MY_NOTIFICATIONS } from '../config/paths';
import { DataContext } from '../context';
// SCSS imports
import { iconButton, icon } from './NotificationsIcon.module.scss';

const NotificationsIcon = forwardRef<HTMLAnchorElement, {}>((_props, ref) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();
  const interval = useRef<ReturnType<typeof setInterval> | null>(null);
  const {
    unreadCount: { data, pending, failed }, requireUnreadCount
  } = useContext(DataContext);
  const { unread } = (!pending && !failed && data) || {};

  const [unreadCount, setUnreadCount] = useState(unread || 0);
  useEffect(() => {
    if (!isNil(unread)) setUnreadCount(toSafeInteger(unread));
  }, [unread]);

  useEffect(() => {
    requireUnreadCount?.();
  }, [requireUnreadCount]);

  // eslint-disable-next-line consistent-return
  useEffect((): (() => void) | void => {
    if (requireUnreadCount && !interval.current) {
      interval.current = setInterval(() => requireUnreadCount?.(), NOTIFICATIONS_POLLING_INTERVAL);
      return () => {
        if (interval.current) {
          clearInterval(interval.current);
          interval.current = null;
        }
      };
    }
  }, [requireUnreadCount]);

  const [label, maxUnread] = useMemo(() => [
    formatMessage({ id: 'header.notifications' }),
    formatMessage({ id: 'header.max_unread' })
  ], [formatMessage]);

  return (
    <IconButton
        ref={ref}
        color="primary"
        size="large"
        aria-haspopup="true"
        className={iconButton}
        aria-label={label}
        component={RouterLink}
        to={PATH_MY_NOTIFICATIONS}
    >
      {unreadCount >= 1 ? (
        <Badge
            badgeContent={unreadCount > MAX_UNREAD_NOTIFICATIONS ? maxUnread : unreadCount}
            color="secondary"
            overlap="rectangular"
        >
          <Notifications className={icon}/>
        </Badge>
      ) : <Notifications className={icon}/>}
    </IconButton>
  );
});

NotificationsIcon.displayName = 'NotificationsIcon';

export default memo(NotificationsIcon);
