import { memo, type FunctionComponent, type ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// Material UI imports
import Button from '@mui/material/Button';
// Media and SCSS imports
import { button } from './AppBarBrand.module.scss';

type AppBarBrandProps = {
  link?: string;
  logo: ReactNode;
};

const AppBarBrandPropTypes = {
  // attributes
  link: PropTypes.string,
  logo: PropTypes.node.isRequired
};

const AppBarBrand: FunctionComponent<AppBarBrandProps> = ({
  link,
  logo
}) => link ? (
  <Button
      color="primary"
      variant="text"
      component={RouterLink}
      to={link}
      className={button}
  >
    {logo}
  </Button>
) : logo;

AppBarBrand.propTypes = AppBarBrandPropTypes;

export default memo(AppBarBrand);
