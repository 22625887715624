import { gql } from '@apollo/client';
// local imports
import { API_DEV_PLANS } from '../config/api';

export const DEV_PLANS_QUERY = gql`
  query DevPlans(
    $limit: Int
    $offset: Int
  ) {
    devplans(
      limit: $limit,
      offset: $offset
    )
    @rest(
      type: "DevPlansPayload",
      path: "${API_DEV_PLANS}?{args}"
    ) {
      count
      results {
        id
        title
        created_at
        updated_at
        owner {
          id
          code
          first_name
          last_name
          email
        }
        job {
          id
          title
          code
        }
        opportunity {
          id
          title
        }
        employee {
          id
          code
          first_name
          last_name
          email
        }
        cohort {
          id
          title
          employees_count
          # Also available in backend response, but not used in UI:
          # created_at
          # updated_at
        }
      }
    }
  }
`;
