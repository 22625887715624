// OpportunityCard
import { forwardRef, memo, useCallback, useMemo } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import isSafeInteger from 'lodash/isSafeInteger';
import toSafeInteger from 'lodash/toSafeInteger';
import clsx from 'clsx';
import { FormattedDate, FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
// Material Icon imports
import SwitchAccount from '@mui/icons-material/SwitchAccount';
import PlaceIcon from '@mui/icons-material/Place';
import HourglassTop from '@mui/icons-material/HourglassTop';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
// EmPath UI Components
import { shape } from '@empathco/ui-components/src/styles/themeOptions';
import { shortDateOptions, longDateOptions } from '@empathco/ui-components/src/common/intl';
import { getJsDateFromISO } from '@empathco/ui-components/src/helpers/datetime';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import { italic } from '@empathco/ui-components/src/helpers/intl';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import TargetIcon from '@empathco/ui-components/src/elements/TargetIcon';
import SkillNewTag from '@empathco/ui-components/src/elements/SkillNewTag';
import TagLabel from '@empathco/ui-components/src/elements/TagLabel';
import ItemCard from '@empathco/ui-components/src/elements/ItemCard';
import MatchIndicator from '@empathco/ui-components/src/elements/MatchIndicator';
import TruncatedTextLink from '@empathco/ui-components/src/elements/TruncatedTextLink';
import SkillName from '@empathco/ui-components/src/elements/SkillName';
// local imports
import { BookingStatus, MyOpportunityStatus, Opportunity, OpportunitySkillStatus, OpportunityStatus } from '../graphql/types';
import {
  OpportunityPost, MyOpportunity, DevPlanOpportunity, EmployeeDevPlanOpportunity, DevPlanCompletedOpportunity
} from '../graphql/customTypes';
import { SkillLevel } from '../models/skill';
import { PATH_MY_OPPORTUNITY, PATH_SUPERVISOR_OPPORTUNITY, PATH_SKILL } from '../config/paths';
import useModels from '../helpers/models';
import EmployeeName from '../elements/EmployeeName';
// SCSS imports
import { card, cardBorder, cardBg, divider, saveBtn, tag } from './OpportunityCard.module.scss';

type OpportunityCardProps = {
  supervisor?: boolean;
  devplan?: boolean;
  item: OpportunityPost | MyOpportunity | DevPlanOpportunity | EmployeeDevPlanOpportunity | DevPlanCompletedOpportunity;
  disabled?: boolean | null;
  onSave?: (opp: MyOpportunity) => void;
  savingId?: number | null;
  isSelected?: boolean;
  withCompletedState?: boolean;
  isMyPlan?: boolean;
  onSelect?: (opp: DevPlanOpportunity) => void;
  onRemove?: (opp: DevPlanOpportunity) => void;
}

const OpportunityCardPropTypes = {
  // attributes
  supervisor: PropTypes.bool,
  devplan: PropTypes.bool,
  item: PropTypes.object.isRequired as Validator<OpportunityPost | MyOpportunity>,
  disabled: PropTypes.bool,
  onSave: PropTypes.func,
  savingId: PropTypes.number,
  isSelected: PropTypes.bool,
  withCompletedState: PropTypes.bool,
  isMyPlan: PropTypes.bool,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func
};

// eslint-disable-next-line complexity, max-lines-per-function
const OpportunityCard = forwardRef<HTMLDivElement, OpportunityCardProps>(({
  supervisor = false,
  devplan = false,
  item,
  disabled = false,
  onSave,
  savingId,
  isSelected,
  withCompletedState = false,
  isMyPlan = false,
  onSelect,
  onRemove
}, ref) => {
  const { getLocationStr } = useModels();
  const id = supervisor || devplan ? (item as OpportunityPost)?.id : (item as MyOpportunity)?.id;
  const oppLinkId = isMyPlan ? (item as Opportunity).opportunity_match_id : id;
  const {
    title, status, location, duration_value, duration_unit, sidegig, onsite, timezone_minutes, start_date,
    published_at, updated_at, started_at, archived_at, // , created_at
    timestamp
  } = (supervisor || devplan ? item as OpportunityPost : (item as MyOpportunity)?.opportunity) || {};
  const { owner } = (!supervisor && (
    (devplan && item as DevPlanOpportunity | EmployeeDevPlanOpportunity | DevPlanCompletedOpportunity) ||
    (item as MyOpportunity)?.opportunity)
  ) || {};
  const { total_matches, shortlisted, bookings } = ((supervisor && item) || {}) as OpportunityPost;
  const { is_new, match_rate, growth_rate, employee_status, booking } =
    ((!supervisor && !devplan && item) || {}) as MyOpportunity;
  const { skills } = ((devplan && item) || {}) as DevPlanOpportunity | EmployeeDevPlanOpportunity | DevPlanCompletedOpportunity;
  const { is_selected } = ((devplan && item) || {}) as DevPlanOpportunity;
  const { status: bookingStatus, employee_requested_at } = booking || {};
  const is_applied = Boolean(bookingStatus === BookingStatus.employee_requested ||
    (employee_requested_at && bookingStatus && bookingStatus !== BookingStatus.employee_rejected));
  const is_saved = employee_status === MyOpportunityStatus.shortlist;

  const growthSkills = useMemo(() => devplan ? filter(skills, ['status', OpportunitySkillStatus.growth])
    : undefined, [skills, devplan]);

  const [requested_count, booked_count/* , applicants_count */] = useMemo(() => {
    let requested = 0;
    let booked = 0;
    forEach(bookings, ({ status: bookStatus, count }) => {
      if (bookStatus === BookingStatus.manager_requested) requested = toSafeInteger(count);
      else if (bookStatus === BookingStatus.confirmed) booked = toSafeInteger(count);
    });
    return [requested, booked];
  }, [bookings]);

  const handleSave = useCallback(() => onSave?.(item as MyOpportunity), [item, onSave]);
  const handleChange = useCallback(() => onSelect?.(item as DevPlanOpportunity), [item, onSelect]);
  const handleRemove = useCallback(() => onRemove?.(item as DevPlanOpportunity), [item, onRemove]);

  const link = useMemo(() => (devplan && !isMyPlan) || !oppLinkId ? undefined : injectParams(
    supervisor ? PATH_SUPERVISOR_OPPORTUNITY : PATH_MY_OPPORTUNITY, { opp_id: oppLinkId }
  ), [oppLinkId, supervisor, devplan, isMyPlan]);

  const statusValues = useMemo(() => supervisor && !devplan ? {
    italic,
    posted: published_at ? (
      <FormattedDate
          value={getJsDateFromISO(published_at)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...shortDateOptions}
      />
    ) : null,
    update: (archived_at && 'archived') || (started_at && 'started') || 'updated',
    updated: archived_at || started_at || updated_at ? (
      <FormattedDate
          value={getJsDateFromISO((archived_at || started_at || updated_at) as string)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...shortDateOptions}
      />
    ) : '—'
  } : undefined, [published_at, updated_at, started_at, archived_at, supervisor, devplan]);

  const isBooked = status === OpportunityStatus.published && (booked_count || 0) >= 1;

  const ownerDetails = !supervisor || devplan ? (
    <FormattedMessage
        id="opportunities.timestamp"
        values={{
          status,
          // eslint-disable-next-line react/jsx-props-no-spreading
          date: timestamp ? <FormattedDate value={getJsDateFromISO(timestamp)} {...shortDateOptions}/> : null,
          update: null,
          owner: owner ? (
            <EmployeeName
                variant="inherit"
                employee={owner}
                manager
                disabled={disabled ? true : undefined}
            />
          ) : '—'
        }}
    />
  ) : undefined;

  return (
    <ItemCard
        ref={ref}
        light
        link={supervisor ? link : undefined}
        onSelect={onSelect ? handleChange : undefined}
        onRemoveClick={onRemove && !is_selected ? handleRemove : undefined}
        selected={isSelected || (onSelect ? Boolean(is_selected) : undefined)}
        disabled={disabled}
        smallItem={supervisor}
        header={(devplan && <TagLabel variant="active" title="hr.dev_plan.opportunity"/>) || (supervisor && (
          <TagLabel
              small
              variant={status === OpportunityStatus.deleted ? 'inactive' : (isBooked && 'active') || status}
              title={isBooked ? 'opportunities.status.booked' : undefined}
          />
        )) || undefined}
        footer={supervisor ? (
          <>
            <Box display="flex" alignItems="center">
              <SwitchAccount color="primary"/>
              <BoxTypography variant="h6" pl={1}>
                <FormattedMessage id="opportunities.candidates"/>
              </BoxTypography>
            </Box>
            <Grid
                container
                display="flex"
                flexWrap="wrap"
                pl="1.5rem"
                pt={0.75}
                pb={1.25}
                component={Typography}
                variant="body2"
            >
              <Grid item xs={6} pl={1} component="span">
                <FormattedMessage id="opportunities.total_matches" values={{ count: toSafeInteger(total_matches) }}/>
              </Grid>
              <Grid item xs={6} pl={1} component="span">
                <FormattedMessage id="opportunities.shortlisted" values={{ count: toSafeInteger(shortlisted) }}/>
              </Grid>
              <Grid item xs={6} pl={1} component="span">
                <FormattedMessage id="opportunities.requested_count" values={{ count: toSafeInteger(requested_count) }}/>
              </Grid>
              <Grid item xs={6} pl={1} component="span">
                <FormattedMessage id="opportunities.booked_count" values={{ count: toSafeInteger(booked_count) }}/>
              </Grid>
            </Grid>
          </>
        ) : undefined}
        status={withCompletedState && status === OpportunityStatus.archived && archived_at ? (
          <BoxTypography pt={1} pl={0.25} variant="body2" fontStyle="italic">
            <FormattedMessage
                id="hr.dev_plan.opportunity_completed"
                // eslint-disable-next-line react/jsx-props-no-spreading
                values={{ date: <FormattedDate value={getJsDateFromISO(archived_at)} {...longDateOptions}/> }}
            />
          </BoxTypography>
        ) : undefined}
        className={clsx(card, {
          [cardBg]: !supervisor && !devplan
        })}
        cardClassName={devplan ? undefined : cardBorder}
    >
      {supervisor ? (
        <>
          <Divider className={divider}/>
          <Box py={1}>
            <TruncatedTextLink text={title} plain/>
          </Box>
          <BoxTypography variant="caption" color="info.caption" display="block" pb={1}>
            <FormattedMessage id="opportunities.timestamps" values={statusValues}/>
          </BoxTypography>
          <Divider className={divider}/>
          <Box pt={1.25} display="flex" flexDirection="column">
            <BoxTypography variant="h6" display="flex" alignItems="center" py={0.5}>
              <PlaceIcon color="primary"/>
              <Box pl={1}>
                {getLocationStr(location) || <FormattedMessage id="opportunities.not_specified"/>}
                <FormattedMessage
                    id="opportunities.location_options"
                    values={{ onsite, timezone: isSafeInteger(timezone_minutes) ? timezone_minutes : null, style: 'dash' }}
                />
              </Box>
            </BoxTypography>
            <BoxTypography variant="h6" display="flex" alignItems="center" py={0.5}>
              <HourglassTop color="primary"/>
              <Box pl={1}>
                <FormattedMessage
                    id="opportunities.duration"
                    values={{ duration: duration_value, unit: duration_unit, sidegig, withTitle: true }}
                />
              </Box>
            </BoxTypography>
            <BoxTypography variant="h6" display="flex" alignItems="center" py={0.5}>
              <CalendarMonth color="primary"/>
              <Box pl={1}>
                <FormattedMessage
                    id="opportunities.start_date"
                    values={{ date: start_date ? getJsDateFromISO(start_date) : null, withTitle: false }}
                />
              </Box>
            </BoxTypography>
          </Box>
        </>
      ) : (
        <>
          {devplan ? undefined : (
            <>
              <Box display="flex">
                <Box display="flex" flex="1 1 0" flexDirection="column" alignItems="flex-start">
                  <Box display="flex" alignItems="center" className={tag}>
                    <TargetIcon
                        Icon={BookmarkBorderIcon}
                        ActiveIcon={BookmarkIcon}
                        label="opportunities.status.not_saved"
                        activeLabel="opportunities.status.saved"
                        active={is_saved}
                        onClick={handleSave}
                        disabled={disabled}
                        pending={savingId && savingId === id ? true : undefined}
                        className={saveBtn}
                    />
                    {is_applied ? (
                      <TagLabel small variant="outlined" title="opportunities.applied"/>
                    ) : undefined}
                  </Box>
                  <SkillNewTag active={is_new}/>
                  <BoxTypography
                      variant="caption"
                      color="info.caption"
                      fontStyle="italic"
                      display="block"
                      pb={1}
                  >
                    {ownerDetails}
                  </BoxTypography>
                </Box>
                <Box display="flex" flexDirection="column" pl={1.5} py={0.5}>
                  <MatchIndicator value={toSafeInteger(match_rate)}/>
                </Box>
              </Box>
              <Divider className={divider}/>
            </>
          )}
          <Box
              pt={devplan ? 0.5 : undefined}
              pb={devplan ? 1 : undefined}
              py={devplan ? undefined : 1}
          >
            <TruncatedTextLink text={title} to={link} plain={!link} disabled={disabled ? true : undefined}/>
          </Box>
          <Divider className={divider}/>
          <Box display="flex" pt={devplan ? 1.5 : 2.5} pb={devplan ? 0.75 : undefined}>
            <Box flex="1 0 0">
              {devplan ? (
                <BoxTypography variant="body2" pb={0.25}>
                  {ownerDetails}
                </BoxTypography>
              ) : undefined}
              <BoxTypography variant="body2" pb={0.25}>
                {getLocationStr(location) || <FormattedMessage id="opportunities.location_not_specified"/>}
                <FormattedMessage
                    id="opportunities.location_options"
                    values={{ onsite, timezone: isSafeInteger(timezone_minutes) ? timezone_minutes : null, style: 'dash' }}
                />
              </BoxTypography>
              <BoxTypography variant="body2" py={0.25}>
                <FormattedMessage
                    id="opportunities.duration"
                    values={{ duration: duration_value, unit: duration_unit, sidegig, withTitle: true }}
                />
              </BoxTypography>
              <BoxTypography variant="body2" py={0.25}>
                <FormattedMessage
                    id="opportunities.start_date"
                    values={{ date: start_date ? getJsDateFromISO(start_date) : null, withTitle: true }}
                />
              </BoxTypography>
            </Box>
            {devplan ? undefined : (
              <Box display="flex" flexDirection="column" justifyContent="flex-end" pl={1.5}>
                <MatchIndicator value={toSafeInteger(growth_rate)} variant="planned" label="opportunities.growth_rate_value"/>
              </Box>
            )}
          </Box>
        </>
      )}
      {devplan && size(growthSkills) >= 1 ? (
        <>
          <Divider className={divider}/>
          <Box pt={1.125} display="flex" alignItems="center" flexWrap="wrap">
            {map(growthSkills, (skill) => (
              <Box
                  key={skill.id}
                  my={0.375}
                  mr={0.75}
                  px={1}
                  py={0.25}
                  bgcolor="background.card"
                  borderRadius={shape.tinyBorderRadius}
              >
                <SkillName
                    skill={skill}
                    level={skill.skill_proficiency_level as SkillLevel}
                    skillPath={PATH_SKILL}
                    variant="body1"
                    maxLines={1}
                    disabled={disabled}
                />
              </Box>
            ))}
          </Box>
        </>
      ) : undefined}
    </ItemCard>
  );
});

OpportunityCard.displayName = 'OpportunityCard';

OpportunityCard.propTypes = OpportunityCardPropTypes;

export default memo(OpportunityCard);
