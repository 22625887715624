import { gql } from '@apollo/client';
// local imports
import { API_HR_CUSTOM_ACTIVITIES } from '../config/api';

export const CUSTOM_ACTIVITIES_QUERY = gql`
  query CustomActivities(
    $offset: Int
    $limit: Int
  ) {
    customActivities(
      offset: $offset,
      limit: $limit
    )
    @rest(
      type: "SkillActivitiesPayload",
      path: "${API_HR_CUSTOM_ACTIVITIES}"
    ) {
      count
      results {
        id
        code
        name
        activity_type
        description
        activity_link
        development_area
        field_support
        store
        education_style
        owner {
          id
          code
          first_name
          last_name
          email
        }
        skills {
          id
          abbr
          title
          skill_proficiency_level
        }
      }
    }
  }
`;
