import { gql } from '@apollo/client';
// local imports
import { API_EMPLOYEE_COURSE_COMPLETE } from '../config/api';

export const COMPLETE_EMPLOYEE_COURSE = gql`
  mutation CompleteEmployeeCourse(
    $course_id: Int!
    $input: CompleteEmployeeCourseInput!
  ) {
    completeEmployeeCourse(
      course_id: $course_id,
      input: $input
    )
    @rest(
      type: "MutationResponse",
      path: "${API_EMPLOYEE_COURSE_COMPLETE}",
      method: "POST"
    )
    {
      success
      optimistic
    }
  }
`;
