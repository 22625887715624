import { gql } from '@apollo/client';
// local imports
import { API_ADMIN_USER_INVITE } from '../config/api';

export const NEW_ADMIN_USER_INVITE = gql`
  mutation NewAdminUserInvite(
    $input: AdminUserInviteInput!
  ) {
    newAdminUserInvite(
      input: $input
    )
    @rest(
      type: "MutationResponse",
      path: "${API_ADMIN_USER_INVITE}",
      method: "POST"
    )
    # status 201
    {
      success
    }
  }
`;
