import { forwardRef, memo, useCallback, useMemo, useState } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import find from 'lodash/find';
import round from 'lodash/round';
import isNil from 'lodash/isNil';
import toFinite from 'lodash/toFinite';
import toSafeInteger from 'lodash/toSafeInteger';
import { useIntl, FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
// Material Icon imports
import StarRounded from '@mui/icons-material/StarRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// EmPath UI Components
import { fontWeightRegular } from '@empathco/ui-components/src/styles/themeOptions';
import { getFullName } from '@empathco/ui-components/src/helpers/strings';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import GridBox from '@empathco/ui-components/src/mixins/GridBox';
import CheckboxButton from '@empathco/ui-components/src/elements/CheckboxButton';
import StandardLink from '@empathco/ui-components/src/elements/StandardLink';
// local imports
import { Opportunity, SkillActivity, DevPlanTargetSkill } from '../graphql/types';
import { DevPlanAdvisor, DevPlanCourse, DevPlanOpportunity } from '../graphql/customTypes';
import { SkillLevel } from '../models/skill';
import useCustomerSettings from '../config/customer';
import { PATH_MY_OPPORTUNITY } from '../config/paths';
import useModels from '../helpers/models';
import SkillActivityDialog from '../v3/SkillActivityDialog';
import SkillChip from './SkillChip';
import ActivityCheckbox from './ActivityCheckbox';
// SCSS imports
import { accordion, summary, details } from './DevPlanAccordionSkill.module.scss';

type DevPlanAccordionSkillProps = {
  skill: DevPlanTargetSkill;
  isMyPlan?: boolean;
  courses: DevPlanCourse[];
  advisors: DevPlanAdvisor[];
  opportunities: DevPlanOpportunity[];
  activities: SkillActivity[];
  disabled?: boolean;
  defaultSelected?: boolean;
  satisfied?: boolean;
  onCourse: (course: DevPlanCourse) => void;
  onAdvisor: (advisor: DevPlanAdvisor) => void;
  onOpportunity: (opportunity: DevPlanOpportunity) => void;
  onActivity: (activity: SkillActivity) => void;
  courseHighlighRating?: number;
  advisorHighlightCount?: number;
}

const DevPlanAccordionSkillPropTypes = {
  skill: PropTypes.object.isRequired as Validator<DevPlanTargetSkill>,
  isMyPlan: PropTypes.bool,
  courses: PropTypes.array.isRequired,
  advisors: PropTypes.array.isRequired,
  opportunities: PropTypes.array.isRequired,
  activities: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  defaultSelected: PropTypes.bool,
  satisfied: PropTypes.bool,
  onCourse: PropTypes.func.isRequired,
  onAdvisor: PropTypes.func.isRequired,
  onOpportunity: PropTypes.func.isRequired,
  onActivity: PropTypes.func.isRequired,
  courseHighlighRating: PropTypes.number,
  advisorHighlightCount: PropTypes.number
};

// eslint-disable-next-line complexity, max-lines-per-function
const DevPlanAccordionSkill = forwardRef<HTMLDivElement, DevPlanAccordionSkillProps>(({
  skill,
  isMyPlan = false,
  courses,
  advisors,
  opportunities,
  activities,
  disabled = false,
  defaultSelected = false,
  satisfied = false,
  onCourse,
  onAdvisor,
  onOpportunity,
  onActivity,
  courseHighlighRating,
  advisorHighlightCount
}, ref) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();
  const {
    HAS_MENTORING, HAS_COURSES, HAS_DEV_PLAN_OPPORTUNITIES, getEmployeeContactUrl, getCourseUrl
  } = useCustomerSettings();
  const { getLocationStr } = useModels();

  const [openActivity, setOpenActivity] = useState<SkillActivity>();
  const handleOpenActivity = useCallback((activity?: SkillActivity) => setOpenActivity(activity), []);
  const handleCloseActivity = useCallback(() => setOpenActivity(undefined), []);

  const ariaLabelPreferred = useMemo(() => formatMessage({ id: 'hr.dev_plan.preferred' }), [formatMessage]);

  const handleCourse = useCallback((checked: boolean, id?: number) => {
    if (id) {
      const course = find(courses, ['id', id]);
      if (course && Boolean(course.is_selected) !== checked) onCourse(course);
    }
  }, [courses, onCourse]);

  const handleAdvisor = useCallback((checked: boolean, id?: number) => {
    if (id) {
      const advisor = find(advisors, ['id', id]);
      if (advisor && Boolean(advisor.is_selected) !== checked) onAdvisor(advisor);
    }
  }, [advisors, onAdvisor]);

  const handleOpportunity = useCallback((checked: boolean, id?: number) => {
    if (id) {
      const opportunity = find(opportunities, ['id', id]);
      if (opportunity && Boolean(opportunity.is_selected) !== checked) onOpportunity(opportunity);
    }
  }, [opportunities, onOpportunity]);

  const handleActivity = useCallback((checked: boolean, id?: number) => {
    if (id) {
      const activity = find(activities, ['id', id]);
      if (activity && Boolean(activity.is_selected) !== checked) onActivity(activity);
    }
  }, [activities, onActivity]);

  const hasActivities = size(activities) >= 1;
  const columns = (HAS_COURSES ? 1 : 0) + (HAS_MENTORING ? 1 : 0) + (HAS_DEV_PLAN_OPPORTUNITIES ? 1 : 0) +
    (hasActivities ? 1 : 0);

  return (
    <>
      <Accordion
          ref={ref}
          elevation={0}
          square
          defaultExpanded={defaultSelected}
          disabled={disabled ? true : undefined}
          className={accordion}
      >
        <AccordionSummary
            expandIcon={<ExpandMoreIcon fontSize="large"/>}
            className={summary}
        >
          <SkillChip
              plain
              satisfied={satisfied}
              skill={skill}
              level={skill.skill_proficiency_level as SkillLevel}
          />
        </AccordionSummary>
        <AccordionDetails className={details}>
          <Grid container>
            {HAS_COURSES ? (
              <GridBox
                  item
                  xs={12}
                  sm={columns > 1 ? 6 : undefined}
                  md={columns > 2 ? 4 : undefined}
                  lg={columns > 3 ? 3 : undefined}
                  pt={1}
                  px={1.25}
              >
                {HAS_MENTORING || HAS_DEV_PLAN_OPPORTUNITIES || hasActivities ? (
                  <BoxTypography variant="h6">
                    <FormattedMessage id="hr.dev_plan.courses"/>
                  </BoxTypography>
                ) : undefined}
                {size(courses) < 1 ? '—' : map(courses, ({
                  id, code, title, is_selected, link, external_url, duration_value, duration_unit, preferred_rating
                }) => {
                  const href = link || external_url || getCourseUrl(code);
                  return (
                    <Box
                        key={id}
                        display="flex"
                        alignItems="center"
                    >
                      <CheckboxButton
                          id={id}
                          label={title}
                          checked={Boolean(is_selected)}
                          onChange={handleCourse}
                          disabled={disabled ? true : undefined}
                      />
                      <Box
                          pl={1}
                          pt={0.5}
                      >
                        {href ? (
                          <StandardLink variant="body2" bold href={disabled ? undefined : href}>
                            {title}
                          </StandardLink>
                        ) : (
                          <Typography variant="body2">
                            {title}
                          </Typography>
                        )}
                        {!is_selected && !isNil(courseHighlighRating) &&
                        courseHighlighRating === toSafeInteger(preferred_rating) ? (
                          <Box fontSize="1rem" color="misc.starRating" component="span">
                            <StarRounded color="inherit" fontSize="inherit" aria-label={ariaLabelPreferred}/>
                          </Box>
                        ) : undefined}
                        <Typography variant="h6">
                          <FormattedMessage
                              id="skill.courses.duration_value"
                              values={{
                                duration: round(toFinite(duration_value)),
                                unit: duration_unit
                              }}
                          />
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </GridBox>
            ) : undefined}
            {HAS_MENTORING ? (
              <GridBox
                  item
                  xs={12}
                  sm={columns > 1 ? 6 : undefined}
                  md={columns > 2 ? 4 : undefined}
                  lg={columns > 3 ? 3 : undefined}
                  pt={1}
                  px={1.25}
              >
                {HAS_COURSES || HAS_DEV_PLAN_OPPORTUNITIES || hasActivities ? (
                  <BoxTypography variant="h6">
                    <FormattedMessage id="hr.dev_plan.advisors"/>
                  </BoxTypography>
                ) : undefined}
                {size(advisors) < 1 ? '—' : map(advisors, ({
                  id, code, email, first_name, last_name, is_selected, location, current_job, active_mentor_count
                }) => {
                  const locationStr = getLocationStr(location);
                  const fullName = getFullName(first_name, last_name);
                  const href = getEmployeeContactUrl(code, email);
                  return (
                    <Box
                        key={id}
                        display="flex"
                        alignItems="center"
                    >
                      <CheckboxButton
                          id={id}
                          label={fullName}
                          checked={Boolean(is_selected)}
                          onChange={handleAdvisor}
                          disabled={disabled ? true : undefined}
                      />
                      <Box
                          pl={1}
                          pt={0.5}
                      >
                        {href ? (
                          <StandardLink variant="h6" bold href={disabled ? undefined : href}>
                            {fullName}
                          </StandardLink>
                        ) : (
                          <Typography variant="h6">
                            {fullName}
                          </Typography>
                        )}
                        {!is_selected && !isNil(advisorHighlightCount) &&
                        advisorHighlightCount === toSafeInteger(active_mentor_count) ? (
                          <Box fontSize="1rem" color="misc.starRating" component="span">
                            <StarRounded color="inherit" fontSize="inherit" aria-label={ariaLabelPreferred}/>
                          </Box>
                        ) : undefined}
                        <Typography variant="body2">
                          {current_job?.title || ''}
                          {current_job?.title && locationStr ? ' | ' : ''}
                          {locationStr}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </GridBox>
            ) : undefined}
            {HAS_DEV_PLAN_OPPORTUNITIES ? (
              <GridBox
                  item
                  xs={12}
                  sm={columns > 1 ? 6 : undefined}
                  md={columns > 2 ? 4 : undefined}
                  lg={columns > 3 ? 3 : undefined}
                  pt={1}
                  px={1.25}
              >
                {HAS_MENTORING || HAS_COURSES || hasActivities ? (
                  <BoxTypography variant="h6">
                    <FormattedMessage id="hr.dev_plan.opportunities"/>
                  </BoxTypography>
                ) : undefined}
                {size(opportunities) < 1 ? '—' : map(opportunities, (opportunity) => {
                  const {
                    id, title, is_selected, duration_value, duration_unit, sidegig, location
                  } = opportunity;
                  const oppLinkId = isMyPlan ? (opportunity as Opportunity).opportunity_match_id : undefined;
                  const loc = getLocationStr(location);
                  return (
                    <Box
                        key={id}
                        display="flex"
                        alignItems="center"
                    >
                      <CheckboxButton
                          id={id}
                          label={title}
                          checked={Boolean(is_selected)}
                          onChange={handleOpportunity}
                          disabled={disabled ? true : undefined}
                      />
                      <Box
                          pl={1}
                          pt={0.5}
                      >
                        {oppLinkId ? (
                          <StandardLink
                              variant="body2" bold
                              to={disabled ? undefined : injectParams(PATH_MY_OPPORTUNITY, { opp_id: oppLinkId })}
                          >
                            {title}
                          </StandardLink>
                        ) : (
                          <Typography variant="body2">
                            {title}
                          </Typography>
                        )}
                        <Typography variant="h6">
                          <FormattedMessage
                              id="opportunities.duration"
                              values={{ duration: duration_value, unit: duration_unit, sidegig, withTitle: false }}
                          />
                          {loc ? (
                            <Box component="span" fontWeight={fontWeightRegular}>
                              {' | '}
                              {loc}
                            </Box>
                          ) : undefined}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              </GridBox>
            ) : undefined}
            {hasActivities ? (
              <GridBox
                  item
                  xs={12}
                  sm={columns > 1 ? 6 : undefined}
                  md={columns > 2 ? 4 : undefined}
                  lg={columns > 3 ? 3 : undefined}
                  pt={1}
                  px={1.25}
              >
                {HAS_MENTORING || HAS_COURSES || HAS_DEV_PLAN_OPPORTUNITIES ? (
                  <BoxTypography variant="h6">
                    <FormattedMessage id="skill.development.activities"/>
                  </BoxTypography>
                ) : undefined}
                {map(activities, (activity) => (
                  <ActivityCheckbox
                      key={activity.id}
                      activity={activity}
                      onChange={handleActivity}
                      onOpen={handleOpenActivity}
                      disabled={disabled ? true : undefined}
                  />
                ))}
              </GridBox>
            ) : undefined}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <SkillActivityDialog
          skillActivity={openActivity}
          open={Boolean(openActivity)}
          onClose={handleCloseActivity}
          selected={openActivity?.is_selected ? true : undefined}
          onSelect={onActivity}
      />
    </>
  );
});

DevPlanAccordionSkill.displayName = 'DevPlanAccordionSkill';

DevPlanAccordionSkill.propTypes = DevPlanAccordionSkillPropTypes;

export default memo(DevPlanAccordionSkill);
