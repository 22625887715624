import { forwardRef, memo, type Component, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import { FormattedDate, FormattedMessage } from 'react-intl';
// Material UI imports
import Box, { type BoxProps } from '@mui/material/Box';
// EmPath UI Components
import { shortDateOptions } from '@empathco/ui-components/src/common/intl';
import { getJsDateFromISO } from '@empathco/ui-components/src/helpers/datetime';
import { getFullName } from '@empathco/ui-components/src/helpers/strings';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
// local imports
import { DevPlanOwner } from '../graphql/customTypes';

type DevPlanTimestampStatusProps = {
  createdAt?: string | null;
  updatedAt?: string | null;
  owner?: DevPlanOwner | null;
  myOwn?: boolean;
  compact?: boolean;
  tags?: ReactNode | ReactNode[];
};

const DevPlanTimestampStatusPropTypes = {
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  owner: PropTypes.object as Validator<DevPlanOwner>,
  myOwn: PropTypes.bool,
  compact: PropTypes.bool,
  tags: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

const DevPlanTimestampStatus = forwardRef<Component<BoxProps>, DevPlanTimestampStatusProps>(({
  createdAt,
  updatedAt,
  owner,
  myOwn = false,
  compact = false,
  tags
}, ref) => createdAt || updatedAt ? (
  <Box
      ref={ref}
      display="flex"
      flexWrap="wrap"
      alignSelf="flex-start"
      pb={0.5}
  >
    {tags ? (
      <Box pl={compact ? '2rem' : '3.5rem'}>
        {tags}
      </Box>
    ) : undefined}
    {createdAt ? (
      <BoxTypography
          pl={compact ? '3rem' : '4.5rem'}
          pt={tags ? 0.25 : undefined}
          flexGrow={1}
          variant="caption"
          fontStyle="italic"
      >
        <FormattedMessage
            id={owner ? 'hr.dev_plan.created_by' : 'hr.dev_plan.created'}
            values={{
              // eslint-disable-next-line react/jsx-props-no-spreading
              date: <FormattedDate value={getJsDateFromISO(createdAt)} {...shortDateOptions}/>,
              ...(myOwn && { owner: null }) || (owner && { owner: getFullName(owner.first_name, owner.last_name) }) || {}
            }}
        />
      </BoxTypography>
    ) : undefined}
    {updatedAt ? (
      <BoxTypography
          pl={compact ? '4rem' : '4.5rem'}
          pt={tags ? 0.25 : undefined}
          flexGrow={1}
          variant="caption"
          fontStyle="italic"
      >
        <FormattedMessage
            id="hr.dev_plan.updated"
            values={{
              // eslint-disable-next-line react/jsx-props-no-spreading
              date: <FormattedDate value={getJsDateFromISO(updatedAt)} {...shortDateOptions}/>
            }}
        />
      </BoxTypography>
    ) : undefined}
  </Box>
) : null);

DevPlanTimestampStatus.displayName = 'DevPlanTimestampStatus';

DevPlanTimestampStatus.propTypes = DevPlanTimestampStatusPropTypes;

export default memo(DevPlanTimestampStatus);
