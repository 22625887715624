import { gql } from '@apollo/client';
// local imports
import { API_EMPLOYEE_ADVISEE } from '../config/api';

export const DELETE_EMPLOYEE_ADVISEE = gql`
  mutation DeleteEmployeeAdvisee(
    $advisee_id: Int!
  ) {
    deleteEmployeeAdvisee(
      advisee_id: $advisee_id
    )
    @rest(
      type: "MutationResponse",
      path: "${API_EMPLOYEE_ADVISEE}",
      method: "DELETE"
    ) {
      success # just a placeholder; this request has empty response (status 204 only)
    }
  }
`;
