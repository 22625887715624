// based on https://github.com/mpgon/apollo-type-patcher

/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import size from 'lodash/size';
import find from 'lodash/find';
import head from 'lodash/head';
import keys from 'lodash/keys';
import slice from 'lodash/slice';
import split from 'lodash/split';
import filter from 'lodash/filter';
import values from 'lodash/values';
import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import includes from 'lodash/includes';
import { type RestLink } from 'apollo-link-rest';

export interface TypeDefinitions {
  [typename: string]: {
    [field: string]: any;
  };
}

type FieldTypeMap = Array<{ field: string; type: string }>;

type TypePatcherFunction = (
  data: Parameters<RestLink.FunctionalTypePatcher>[0],
  outerType?: Parameters<RestLink.FunctionalTypePatcher>[1] | null,
  patchDeeper?: Parameters<RestLink.FunctionalTypePatcher>[2] | null,
  context?: Parameters<RestLink.FunctionalTypePatcher>[3] | null,
  pathAccumulator?: string
) => void;

export interface TypePatcher extends RestLink.TypePatcherTable {
  self: () => TypePatcher;
  [typename: string]: TypePatcherFunction;
}

/**
 * Adds a __typename property to the target object
 * If the target is an array, it adds the __typename
 * to all of its elements
 *
 * @param typename The __typename value
 *
 * @param target The target object or array
 *
 * @returns the target with the added __typename property
 */
function addTypenameInobject(typename: string, target: any) {
  if (target === null) return null;
  if (isArray(target)) {
    return target.map((nestedobject) => ({
      __typename: typename,
      ...nestedobject
    }));
  }
  return {
    __typename: typename,
    ...target
  };
}

/**
 * Recursive function that does a deep search from the
 * base data until the nested target object to which the
 * __typename property should be added, and adds it
 *
 * @param elem The object being updated every iteration
 * initial it equals data
 *
 * @param typename The __typename value
 *
 * @param data The base data response object that nests the target
 * It menains immutable through all iterations
 *
 * @param path The path to be recursed from the base data
 * until the target. In every iteration but the n-1, the path array
 * is shifted
 *
 * @returns the updated data
 */
function recursiveTypename(
  elem: { [key: string]: any },
  typename: string,
  data: object,
  path: Array<string>,
  result: { status: boolean }
) {
  if (!path || !elem) return false;

  // extract next element in the path
  const nextElem = elem && elem[path[0]];
  // inject the tyename prop if it is the last element (target) if
  // the element exists
  if (size(path) === 1) {
    if (
      elem !== undefined &&
      elem !== null &&
      elem[path[0]] !== undefined &&
      elem[path[0]] !== null
    ) {
      elem[path[0]] = addTypenameInobject(typename, elem[path[0]]);
      result.status = true;
      return true;
    }
    return false;
  }

  // advance the path to the next element
  // using slice instead of shift to clone the array
  const nextPath = slice(path, 1);

  if (isArray(nextElem)) {
    // recurse each next element if in the presence of a nested array
    forEach(nextElem, (el) =>
      recursiveTypename(el, typename, data, nextPath, result)
    );
  } else {
    // recurse next element
    recursiveTypename(nextElem, typename, data, nextPath, result);
  }
  return true;
}

/**
 * Adds a __typename to an object/array with possible nested objects/arrays
 *
 * @param data The base data response object
 *
 * @param path The path from the base data until the target
 *
 * @param typename The __typename value
 *
 * @returns True if the typename property was added
 */
export function addTypename(data: any, path: string, typename: string) {
  const pathArr = split(path, '.');
  const result = { status: false };
  recursiveTypename(data, typename, data, pathArr, result);
  return result.status;
}

/**
 * Calls the addTypename for the types under the
 * root value supplied
 *
 * @param data The base data response object
 *
 * @param root The field in the response
 *
 * @param rootValue The type of the root field
 *
 * @param types The fields and corresponding types
 * nested under the root
 *
 * @returns True if the typename property was added
 */
function patch(data: object, root: string, rootValue: string, types: FieldTypeMap) {
  let patchSuccess = false;
  let addResult = false;
  if (root && rootValue && root !== '' && rootValue !== '') addResult = addTypename(data, root, rootValue);
  patchSuccess ||= addResult;
  const base = !root || root === '' ? '' : `${root}.`;
  forEach(types, ({ field, type }) => {
    addResult = addTypename(data, `${base}${field}`, type);
    patchSuccess ||= addResult;
  });
  return patchSuccess;
}

/**
 * Utility function to mark the field types that have
 * definitions of their own under a 'nested' prop
 *
 * @param typeDefinitions The type definitions object
 * that map each object in a response's field with it's type
 *
 */
function artificiallyAddNestedFields(typeDefinitions: TypeDefinitions) {
  const rootTypes = keys(typeDefinitions);
  forEach(rootTypes, (rootType) => {
    const interiorTypes: Array<string> = values(typeDefinitions[rootType]);
    const nestedTypes = filter(interiorTypes, (type) => includes(rootTypes, type));

    if (size(nestedTypes) >= 1) {
      // move nested types inside __nested: {...} prop
      // e.g. { Type1: { Field1: Type2 }, Type2: {...} }
      // becomes { Type1: __nested: { Field1: Type2 }, Type2: {...} }
      typeDefinitions[rootType].__nested = {};
      forEach(nestedTypes, (type) => {
        const typeKey =
          find(keys(typeDefinitions[rootType]), (key) => typeDefinitions[rootType][key] === type) || '';
        typeDefinitions[rootType].__nested[typeKey] = type;
        delete typeDefinitions[rootType][typeKey];
      });
    }
  });
}

/**
 * Generates the type patcher functions
 *
 * @param typeDefinitions The type definitions object
 * that map each object in a response's field with it's type
 *
 * @returns the type patcher functions
 */
export function typePatcherFactory(typeDefinitions: TypeDefinitions) {
  artificiallyAddNestedFields(typeDefinitions);

  const out: TypePatcher = {
    // 'self' is necessary to allow the typePatcher
    // to call other type functions from itself
    self() {
      return this;
    }
  };
  const typePatchers: { [key: string]: FieldTypeMap } = {};

  const types = keys(typeDefinitions);

  // create type patchers
  forEach(types, (type) => {
    const fields = filter(keys(typeDefinitions[type]), (field) => field !== '__nested');
    const fieldTypeMap: FieldTypeMap = [];
    forEach(fields, (field) => fieldTypeMap.push({ field, type: typeDefinitions[type][field] }));
    typePatchers[type] = fieldTypeMap;
  });

  // create patch functions
  forEach(types, (type) => {
    out[type] = (...args: Array<any>) => {
      const data = head(args);
      const pathAccumulator = size(args) === 5 ? args[4] || '' : '';

      const typePatcher = typePatchers[type];
      const patchSuccess = patch(data, pathAccumulator, type, typePatcher);
      if (patchSuccess || pathAccumulator === '') {
        // nested
        const nestedFields = typeDefinitions[type].__nested
          ? keys(typeDefinitions[type].__nested)
          : [];
        forEach(nestedFields, (nestedField) => {
          const nestedType = typeDefinitions[type].__nested[nestedField];
          const nextPath = pathAccumulator === '' ? nestedField : `${pathAccumulator}.${nestedField}`;

          const thisRef = out.self();
          thisRef[nestedType](data, null, null, args[3], nextPath);
        });
      }
      return data;
    };
    return out[type];
  });
  return out;
}
