import { gql } from '@apollo/client';
// local imports
import { API_SUPV_OPPORTUNITY } from '../config/api';

export const UPDATE_OPPORTUNITY_STATUS = gql`
  mutation UpdateOpportunityStatus(
    $opportunity_id: Int!
    $input: OpportunityStatusInput!
  ) {
    updateOpportunityStatus(
      opportunity_id: $opportunity_id,
      input: $input
    )
    @rest(
      type: "MutationResponse",
      path: "${API_SUPV_OPPORTUNITY}status/",
      method: "PATCH"
    ) {
      success
      optimistic
    }
  }
`;
