import { gql } from '@apollo/client';
// local imports
import { API_HR_REDEPLOYMENT_EMPLOYEE_JOBS } from '../config/api';

export const REDEPLOYMENT_EMPLOYEE_JOBS_QUERY = gql`
  query RedeploymentEmployeeJobs(
    $plan_id: Int!
    $employee_id: Int!
    $input: RedeploymentEmployeeJobsInput!
    $pathBuilder: String!
  ) {
    redeploymentEmployeeJobs(
      plan_id: $plan_id,
      employee_id: $employee_id,
      input: $input
    )
    @rest(
      type: "RedeploymentEmployeeJobsPayload",
      path: "${API_HR_REDEPLOYMENT_EMPLOYEE_JOBS}",
      pathBuilder: $pathBuilder
    ) {
      count
      results {
        id
        title
        code
        is_target
        match_rate
        open_reqs_count
        new_open_reqs_count
        display_level
        management_level
        is_selected
        location {
          id
          city
          state
          country
        }
      }
    }
  }
`;
