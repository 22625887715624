import { forwardRef, memo, type ElementType, type Component } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import { type SystemProps } from '@mui/system/Box';
import Box, { type BoxProps } from '@mui/material/Box';
// local imports
import { Values, ValuesPropType } from '../helpers/intl';
// SCSS imports
import { subheader } from './CardSubheader.module.scss';

type CardSubheaderProps = SystemProps & {
  component?: ElementType;
  className?: string;
  text: string;
  values?: Values;
};

const CardSubheaderPropTypes = {
  component: PropTypes.elementType as Validator<ElementType>,
  text: PropTypes.string.isRequired,
  values: ValuesPropType,
  className: PropTypes.string
};

const CardSubheader = forwardRef<Component<BoxProps>, CardSubheaderProps>(({
  component: UIComponent = Box,
  text,
  values,
  className,
  ...props
}, ref) => (
  <UIComponent
      ref={ref}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={clsx(subheader, className)}
  >
    <FormattedMessage id={text} values={values} defaultMessage={text}/>
  </UIComponent>
));

CardSubheader.displayName = 'CardSubheader';

CardSubheader.propTypes = CardSubheaderPropTypes;

export default memo(CardSubheader);
