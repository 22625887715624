import { useCallback, useMemo } from 'react';
import sum from 'lodash/sum';
import values from 'lodash/values';
import toSafeInteger from 'lodash/toSafeInteger';
import { type ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import { type FieldPolicy, type FieldReadFunction, InMemoryCache } from '@apollo/client';
import { useIntl } from 'react-intl';
// EmPath UI Components
import { getFullName, getName } from '@empathco/ui-components/src/helpers/strings';
// local imports
import { CountsPerLevel, OpportunityStatus, SimpleEmployee, StrictTypedTypePolicies } from './types';
import { transformHierarchy } from '../helpers/graphql';
import useModels from '../helpers/models';

const employeeTitle = (codeInBrackets: boolean) => ({
  read: (_title: string, { readField }: { readField: ReadFieldFunction }) => {
    const code = readField('code');
    const first_name = readField('first_name') as string;
    const last_name = readField('last_name') as string;
    return first_name || last_name
      ? `${getFullName(first_name, last_name)} ${codeInBrackets ? '(' : '- '}${code}${codeInBrackets ? ')' : ''}`
      : (codeInBrackets && `(${code})`) || code;
  }
} as FieldPolicy | FieldReadFunction);

const pureName = (_pure_name: string, { readField }: { readField: ReadFieldFunction }) => {
  const code = readField('code') as string;
  const first_name = readField('first_name') as string;
  const last_name = readField('last_name') as string;
  return first_name || last_name ? `${getFullName(first_name, last_name)} - ${code}` : code;
};

function useCustomizedApolloCache() {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();
  const { getLocationStr } = useModels();

  const typePolicies: StrictTypedTypePolicies = useMemo(() => ({

    Query: {
      fields: {
        readHierarchy: {
          read: (_hierarchy, { readField }) => transformHierarchy(readField('hierarchyInternal'))
        }
      }
    },

    MySearchItem: {
      fields: {
        id: {
          read: (_id: string, { readField }) => `${readField('item_type')}-${readField('item_id')}`
        }
      }
    },

    Leader: {
      fields: {
        title: {
          read: pureName
        }
      }
    },

    Delegate: {
      fields: {
        is_delegated: {
          read: (_is_delegated: boolean, { readField }: { readField: ReadFieldFunction }) =>
            Boolean(readField('delegated_for'))
        },
        pure_name: {
          read: pureName
        },
        title: {
          read: (_title: string, { readField }: { readField: ReadFieldFunction }) => {
            const pure_name = readField('pure_name') as string;
            const delegated_for = readField('delegated_for') as SimpleEmployee;
            const is_delegated = Boolean(delegated_for);
            const { code: d_code, first_name: d_first_name, last_name: d_last_name } = delegated_for || {};
            const delegated_for_name = is_delegated
              ? ((d_first_name || d_last_name) && `${getFullName(d_first_name, d_last_name)}`) || d_code
              : undefined;
            return formatMessage({ id: 'delegates.delegate_name' }, { pure_name, is_delegated, delegated_for_name });
          }
        }
      }
    },
  
    Location: {
      fields: {
        title: {
          read: (_title: string, { readField }) => getLocationStr({
            city: readField('city'),
            state: readField('state'),
            country: readField('country')
          })
        }
      }
    },

    CourseAdvisorItem: {
      fields: {
        id: {
          read: (_id: number, { readField }) => {
            const itemType = readField('item_type');
            return 10 * toSafeInteger(readField('item_id')) + (
              (itemType === 'advisor' && 1) ||
              (itemType === 'opportunity' && 2) || 0
            );
          }
        },
        title: {
          read: (title: string, { readField }) => readField('item_type') === 'advisor' ? getName(title) : title
        }
      }
    },

    MDInDemandSkill: {
      fields: {
        employees_count: {
          read: (_employees_count: number, { readField }) => {
            const counts = readField<CountsPerLevel>('counts_per_level');
            return sum(values(counts));
          }
        }
      }
    },

    EmployeeSearchItem: {
      fields: {
        title: employeeTitle(false)
      }
    },

    OpportunityOwner: {
      fields: {
        title: employeeTitle(true)
      }
    },

    Opportunity: {
      fields: {
        timestamp: {
          read: (_timestamp: string, { readField }) => {
            const status = readField('status');
            return (status === OpportunityStatus.draft && readField('updated_at')) ||
              (status === OpportunityStatus.published && readField('published_at')) ||
              (status === OpportunityStatus.started && readField('started_at')) ||
              (status === OpportunityStatus.archived && readField('archived_at')) ||
              (status === OpportunityStatus.deleted && readField('updated_at')) ||
              null;
          }
        }
      }
    }
  }), [getLocationStr, formatMessage]);

  const createCache = useCallback(() => new InMemoryCache({
    typePolicies
  }), [typePolicies]);

  return useMemo(() => ({ createCache }), [createCache]);
}

export default useCustomizedApolloCache;
