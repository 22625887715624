import isString from 'lodash/isString';
import trim from 'lodash/trim';
import split from 'lodash/split';
import join from 'lodash/join';
import map from 'lodash/map';
import size from 'lodash/size';
import transform from 'lodash/transform';
import toSafeInteger from 'lodash/toSafeInteger';
import toString from 'lodash/toString';
import toUpper from 'lodash/toUpper';
import capitalize from 'lodash/capitalize';
import truncate from 'lodash/truncate';
import replace from 'lodash/replace';
import sortBy from 'lodash/sortBy';
import zod from 'zod';
// local imports
import { TITLE_TRUNCATE_OPTIONS } from '../config/params';

export function isEmptyString(str: unknown) {
  return !isString(str) || str.length === 0 || trim(str).length === 0;
}

export function getName(name?: string | null): string {
  return join(map(split(name, ' '), capitalize), ' ');
}

export function getFullName(firstName?: string | null, lastName?: string | null, code?: string | null): string {
  return firstName && lastName
    ? `${getName(firstName)} ${getName(lastName)}`
    : (firstName && getName(firstName)) || (lastName && getName(lastName)) || code || '';
}

export function getInitials(firstName?: string | null, lastName?: string | null): string {
  return toUpper(`${toString(firstName).charAt(0)}${toString(lastName).charAt(0)}`);
}

const emailSchema = zod.coerce.string().email();
export const isValidEmail = (str?: string | null) => {
  try {
    return size(trim(emailSchema.parse(str))) >= 1;
  } catch {
    return false;
  }
};

const dashRegexp = /([-–—‒])/gu;

export function getTruncatedTitle(title: string): string {
  return replace(truncate(title, TITLE_TRUNCATE_OPTIONS), dashRegexp, (_match, p1) => `${p1}\u200B`);
}

export function getIds<T extends number = number>(
  ids?: string,
  minValue = 1,
  maxValue: number | undefined = undefined
): T[] {
  const str = trim(ids || '');
  return str === '' ? [] : transform(split(str, ','), (result, idString) => {
    const id = toSafeInteger(idString);
    if (id >= minValue && (!maxValue || id <= maxValue)) result.push(id as T);
  }, [] as T[]);
}

export function getStringifiedIds(ids?: number[] | null): string {
  return size(ids) >= 1 ? join(map(sortBy(ids), toSafeInteger)) : '';
}
