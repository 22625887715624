import { forwardRef, memo, useCallback, useMemo } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import { useLazyQuery } from '@apollo/client';
// Material Icon imports
import SearchIcon from '@mui/icons-material/Search';
// EmPath UI Components
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import Lookup from '@empathco/ui-components/src/elements/Lookup';
// local imports
import { EMPLOYEES_SEARCH_QUERY } from '../graphql/EmployeesSearch';
import { EmployeesSearchDocument, EmployeesSearchQueryVariables } from '../graphql/types';
import { EmployeeSearchItem } from '../graphql/customTypes';
import { MAX_ITEMS } from '../config/params';
// SCSS imports
import { root } from './EmployeeSearch.module.scss';

type EmployeeSearchProps = {
  value?: EmployeeSearchItem | null;
  onChange: (skill: EmployeeSearchItem | null) => void;
  disabled?: boolean | null;
  fullWidth?: boolean;
}

const EmployeeSearchPropTypes = {
  // attributes
  value: PropTypes.object as Validator<EmployeeSearchItem>,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool
};

const EmployeeSearch = forwardRef<HTMLDivElement, EmployeeSearchProps>(({
  value,
  onChange,
  disabled = false,
  fullWidth = false
}, ref) => {
  const { query: getEmployees, loading, failed, results, variables } = useQueryCounted({
    data: undefined as unknown as EmployeeSearchItem,
    key: 'employeesSearch',
    lazyQuery: useLazyQuery(EMPLOYEES_SEARCH_QUERY as typeof EmployeesSearchDocument)
  });

  const fetchParams: Partial<EmployeesSearchQueryVariables> = useMemo(() => ({
    limit: MAX_ITEMS
  }), []);

  const fetchEmployees = useCallback(
    (params: EmployeesSearchQueryVariables) => getEmployees?.({ variables: params }),
    [getEmployees]);

  return (
    <Lookup
        ref={ref}
        fullWidth={fullWidth}
        type="employees"
        className={root}
        fetched={results}
        pending={loading}
        failed={failed}
        params={variables}
        fetch={fetchEmployees}
        fetchParams={fetchParams}
        onChange={onChange}
        value={value}
        disabled={disabled}
        popupIcon={<SearchIcon/>}
    />
  );
});

EmployeeSearch.displayName = 'EmployeeSearch';

EmployeeSearch.propTypes = EmployeeSearchPropTypes;

export default memo(EmployeeSearch);
