import { gql } from '@apollo/client';
// local imports
import { API_MY_ACTIVITY } from '../config/api';

export const UPDATE_ACTIVITY = gql`
  mutation UpdateActivity(
    $activity_id: Int!
    $input: UpdateActivityInput!
  ) {
    updateActivity(
      activity_id: $activity_id,
      input: $input
    )
    @rest(
      type: "EmployeeActivity",
      path: "${API_MY_ACTIVITY}",
      method: "PATCH"
    ) {
      id
    }
  }
`;
