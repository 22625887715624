import { gql } from '@apollo/client';
// local imports
import { API_EMPLOYEE_ROLE } from '../config/api';

export const EMPLOYEE_ROLE_QUERY = gql`
  query EmployeeRole(
    $role_id: String!
    $selected_employee_id: String
  ) {
    employeeRole(
      role_id: $role_id,
      selected_employee_id: $selected_employee_id
    )
    @rest(
      type: "EmployeeRole",
      path: "${API_EMPLOYEE_ROLE}?{args}"
    ) {
      id
      code
      title
      match_rate
      is_target
      open_reqs_count
      new_open_reqs_count
      display_level
      management_level
      is_contractor
      effort_level
      is_current
      is_index_display
      is_supervisory
      supervisory
      movement_rate
      description
      open_reqs_url
      payrange
      bonus
      location {
        id
        title
        country
        country_id
        state
        state_id
        city
      }
      discipline {
        name
        sub_family {
          id
          title
        }
      }
      regions {
        id
        region
        salary_currency
        salary_maximum
        salary_midpoint
        salary_minimum
      }
      skills {
        id
        title
        abbr
        current_level
        inferred_level
        is_inference_newer
        target_level
        expected_level
        is_in_demand
        is_inference
        is_mentoring
        is_my
        is_target
        trending
        group
        type
        ple_link
        level_description
      }
      skills_with_gap {
        id
        title
        abbr
        current_level
        inferred_level
        is_inference_newer
        target_level
        expected_level
        is_in_demand
        is_inference
        is_mentoring
        is_my
        is_target
        trending
        group
        type
        ple_link
        level_description
      }
    }
  }
`;
