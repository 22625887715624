import { forwardRef, memo } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import range from 'lodash/range';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
// Material UI imports
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
// Material Icon imports
import CheckBold from 'mdi-material-ui/CheckBold';
// EmPath UI Components
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import GridBox from '@empathco/ui-components/src/mixins/GridBox';
// local imports
import { OnboardingSteps, User } from '../models/user';
import useBuilderSteps, { BUILDER_STEPS } from '../constants/builder';
import { PATHS_MY_PROFILE_BUILDER } from '../config/paths';
// SCSS imports
import { checkIcon, pastStep, futureStep, linkBtn, linkActive } from './BuilderStepper.module.scss';

type BuilderStepperProps = {
  user?: User | null;
  activeStep?: number;
  steps?: OnboardingSteps | null;
  disabled?: boolean | null;
  handleClicks: (() => void)[];
};

const BuilderStepperPropTypes = {
  user: PropTypes.object as Validator<User>,
  activeStep: PropTypes.number,
  steps: PropTypes.object as Validator<OnboardingSteps>,
  disabled: PropTypes.bool,
  handleClicks: PropTypes.arrayOf(PropTypes.func.isRequired).isRequired
};

const BuilderStepper = forwardRef<HTMLDivElement, BuilderStepperProps>(({
  user,
  activeStep = 0,
  steps,
  disabled = false,
  handleClicks
}, ref) => {
  const { getStepNumber, getStepsCount, isValidStep } = useBuilderSteps();
  const stepsCount = getStepsCount(user);
  const currentStepCompleted = activeStep < 1 || Boolean(steps?.[BUILDER_STEPS[activeStep - 1]]);
  return (
    <Grid ref={ref} container>
      {map(range(1, BUILDER_STEPS.length + 1), (step) => {
        if (!isValidStep(user?.has_current_job_skills, user?.has_in_demand_skills, step)) return null;
        const completed = steps?.[BUILDER_STEPS[step - 1]];
        const content = (
          <>
            <Avatar
                variant="circular"
                className={step === activeStep || completed ? pastStep : futureStep}
            >
              {completed ? (
                <CheckBold color="inherit" className={checkIcon}/>
              ) : (
                <Typography variant="h5">
                  {getStepNumber(user, step)}
                </Typography>
              )}
            </Avatar>
            <BoxTypography
                pl={1.5}
                variant={step === activeStep ? 'body1' : 'h5'}
                align="center"
            >
              <FormattedMessage
                  id={`builder.step${step}.title`}
                  values={{ has_skills: Boolean(user?.has_current_job_skills || user?.has_in_demand_skills) }}
              />
            </BoxTypography>
          </>
        );
        return (
          <GridBox
              key={step}
              item
              container
              xs={12}
              sm={stepsCount % 2 === 0 ? 6 : 4}
              md={stepsCount > 2 && stepsCount % 2 === 0 ? 3 : undefined}
              pr={step === BUILDER_STEPS.length ? 0 : 0.375}
              pb={0.375}
          >
            {(step === activeStep && (
              <Button
                  fullWidth
                  color="primary"
                  disabled
                  className={linkActive}
              >
                {content}
              </Button>
            )) || (currentStepCompleted && (
              <Button
                  fullWidth
                  color="primary"
                  component={RouterLink}
                  to={PATHS_MY_PROFILE_BUILDER[step - 1]}
                  disabled={disabled ? true : undefined}
                  className={linkBtn}
              >
                {content}
              </Button>
            )) || (
              <Button
                  fullWidth
                  color="primary"
                  onClick={handleClicks[step - 1]}
                  disabled={disabled ? true : undefined}
                  className={linkBtn}
              >
                {content}
              </Button>
            )}
          </GridBox>
        );
      })}
    </Grid>
  );
});

BuilderStepper.displayName = 'BuilderStepper';

BuilderStepper.propTypes = BuilderStepperPropTypes;

export default memo(BuilderStepper);
