/* eslint-disable max-lines */
import {
  memo, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useReducer, useState, type FunctionComponent
} from 'react';
import PropTypes, { type Validator } from 'prop-types';
import size from 'lodash/size';
import keys from 'lodash/keys';
import map from 'lodash/map';
import sum from 'lodash/sum';
import xor from 'lodash/xor';
import find from 'lodash/find';
import take from 'lodash/take';
import omit from 'lodash/omit';
import reject from 'lodash/reject';
import filter from 'lodash/filter';
import indexOf from 'lodash/indexOf';
import unionBy from 'lodash/unionBy';
import sortBy from 'lodash/sortBy';
import without from 'lodash/without';
import flatten from 'lodash/flatten';
import transform from 'lodash/transform';
import intersection from 'lodash/intersection';
import toSafeInteger from 'lodash/toSafeInteger';
import toString from 'lodash/toString';
import toLower from 'lodash/toLower';
import trim from 'lodash/trim';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLazyQuery, useMutation } from '@apollo/client';
// Material UI imports
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
// EmPath UI Components
import { isEmptyString } from '@empathco/ui-components/src/helpers/strings';
import { pathBuilder } from '@empathco/ui-components/src/helpers/graphql';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import { bold } from '@empathco/ui-components/src/helpers/intl';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import useQueryObject from '@empathco/ui-components/src/hooks/useQueryObject';
import useMutationMethod from '@empathco/ui-components/src/hooks/useMutationMethod';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import ExportButton, { ExportParams } from '@empathco/ui-components/src/elements/ExportButton';
import ViewSwitch, { CHART_VIEW, TABLE_VIEW } from '@empathco/ui-components/src/elements/ViewSwitch';
import OnOffSwitch from '@empathco/ui-components/src/elements/OnOffSwitch';
import StandardLink from '@empathco/ui-components/src/elements/StandardLink';
// local imports
import { getDefaultLeaderId, getSelectedLeaderId, getRootLeaderId } from '../models/user';
import { SKILL_LEVEL_MIN, SKILL_LEVEL_REGULAR, Skill, SkillLevel } from '../models/skill';
import { TALENT_COUNTS_QUERY } from '../graphql/TalentCounts';
import { TALENT_EMPLOYEES_QUERY } from '../graphql/TalentEmployees';
import { NEW_COHORT } from '../graphql/NewCohort';
import { UPDATE_COHORT } from '../graphql/UpdateCohort';
import {
  TalentCountsDocument, TalentEmployeesDocument, NewCohortDocument, UpdateCohortDocument,
  TalentFinderInput, TalentEmployeesInput, TalentFinderSort, SortDirection,
  NewCohortMutation, NewCohortMutationVariables, UpdateCohortMutationVariables,
  TalentCounts, AdminEmployee, CohortSkillStatus
} from '../graphql/types';
import { CohortDetails, TalentEmployeeObject } from '../graphql/customTypes';
import { DEFAULT_TF_SORT_DIRECTION, VALID_SORT_DIRECTION } from '../constants/talentFinder';
import { API_TALENT_FINDER_EXPORT } from '../config/api';
import { TALENT_FOLDED_EMPLOYEES } from '../config/params';
import useCustomerSettings from '../config/customer';
import { PATH_HR_COHORT } from '../config/paths';
import { locationParams, getSettingsStrValue } from '../helpers/context';
import { toggleReducer } from '../helpers/reducers';
import { PersistentContext, SkillGroup, SkillGroupType } from '../context/persistent';
import { GlobalContext } from '../context/global';
import { TalentFinderFilterValues } from '../context/supervisor';
import { DataContext } from '../context';
import CategoryChip from '../elements/CategoryChip';
import DashboardFilters from '../v3/DashboardFilters';
import EmployeesTable, { EmployeeSelection } from '../v3/EmployeesTable';
import EmployeeDetailsPopup from '../v3/EmployeeDetailsPopup';
import SkillsDnDSelector from '../widgets/SkillsDnDSelector';
import TalentFinderChart from '../widgets/TalentFinderChart';
import SkillLevelDialog from '../widgets/SkillLevelDialog';
// SCSS imports
import { header } from './TalentFinder.module.scss';

const prepareSkills = (allSkills: Skill[], desiredSkillIds: number[]) => {
  const res = transform(allSkills, (result, skill) => {
    const { id, skill_proficiency_level } = skill;
    const isDesired = indexOf(desiredSkillIds, id) >= 0;
    result[isDesired ? 'desiredIds' : 'requiredIds'].push(id);
    result[isDesired ? 'desiredWithLevels' : 'requiredWithLevels'].push(skill);
    if (!result.hasLevels) result.hasLevels =
      Boolean(skill_proficiency_level) && skill_proficiency_level !== SKILL_LEVEL_REGULAR;
  }, {
    requiredIds: [] as number[],
    desiredIds: [] as number[],
    requiredWithLevels: [] as Skill[],
    desiredWithLevels: [] as Skill[],
    hasLevels: false
  });
  return {
    requiredIds: sortBy(res.requiredIds),
    desiredIds: sortBy(res.desiredIds),
    skillLevels: res.hasLevels
      ? map([...sortBy(res.requiredWithLevels, 'id'), ...sortBy(res.desiredWithLevels, 'id')],
        ({ skill_proficiency_level }) => skill_proficiency_level || SKILL_LEVEL_REGULAR)
      : undefined
  };
}

type TalentExportParams = TalentFinderInput & {
  token: string;
};

const talentCountsParams = ({
  skill_ids, desired_skill_ids, levels, job_levels,
  manager_id, country_id, state_id, location_id,
  selected_leader_id
}: TalentFinderInput) => ({
  ...locationParams(country_id, state_id, location_id),
  ...skill_ids && isArray(skill_ids) ? { skill_ids } : {},
  ...desired_skill_ids && isArray(desired_skill_ids) && size(desired_skill_ids) >= 1 ? { desired_skill_ids } : {},
  ...size(levels) >= 1 ? { levels } : {},
  ...job_levels && isArray(job_levels) && size(job_levels) >= 1 ? { job_levels } : {},
  ...isEmptyString(manager_id) || trim(toString(manager_id)) === '0' ? {} : { manager_id },
  ...selected_leader_id ? { selected_leader_id } : {}
} as TalentFinderInput);

type TalentSortingInput = Pick<TalentEmployeesInput, 'sort_by' | 'direction'>;

const talentSortingParams = (
  sort_by?: TalentFinderSort,
  direction?: SortDirection
): TalentSortingInput => ({
  ...sort_by ? { sort_by } : {},
  ...direction ? { direction } : {}
});

type TalentFinderProps = {
  supervisor?: boolean;
  cohort?: CohortDetails | null;
  pending?: boolean | null;
  failed?: boolean | null;
  // for Storybook only
  asChart?: boolean;
  employeeSelection?: EmployeeSelection;
  testAction?: boolean | null;
}

const TalentFinderPropTypes = {
  supervisor: PropTypes.bool,
  cohort: PropTypes.object as Validator<CohortDetails>,
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  asChart: PropTypes.bool,
  employeeSelection: PropTypes.object as Validator<EmployeeSelection>,
  testAction: PropTypes.bool
};

// eslint-disable-next-line complexity, max-statements, max-lines-per-function
const TalentFinder: FunctionComponent<TalentFinderProps> = ({
  supervisor = false,
  cohort,
  pending: cohortPending = false,
  failed: cohortFailed = false,
  asChart = false,
  employeeSelection,
  testAction
}) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { HAS_DEV_PLAN } = useCustomerSettings();

  const isEditing = !isUndefined(cohort);
  const { id: cohortId, title: cohortTitle, cohort_skills, employees: cohortEmployees } = cohort || {};

  const {
    cohortSkills, requiredIds: cohortRequiredIds, desiredIds: cohortDesiredIds, skillLevels: cohortSkillLevels
  } = useMemo(() => {
    if (!cohort_skills) return {
      cohortSkills: undefined,
      requiredIds: undefined,
      desiredIds: undefined,
      skillLevels: undefined
    };
    const res = transform(cohort_skills, (result, { skill, skill_proficiency_level, status }) => {
      result.skills.push({
        ...skill,
        skill_proficiency_level: skill_proficiency_level as SkillLevel || SKILL_LEVEL_REGULAR
      });
      if (status === CohortSkillStatus.desired) result.desiredSkillIds.push(skill.id);
    }, {
      skills: [] as Skill[],
      desiredSkillIds: [] as number[]
    });
    return {
      ...prepareSkills(res.skills, res.desiredSkillIds),
      cohortSkills: res.skills
    };
  }, [cohort_skills]);
  const cohortHasLevels = size(cohortSkillLevels) >= 1;

  const { fontsLoaded, token, paths: { supvEmplPath }, user: { data: user } } = useContext(GlobalContext);
  const {
    settings: { data: settingsData, pending: pendingSettings, failed: failedSettings },
    settingsUpdate: { pending: pendingSettingsUpdate }, updateSettings
  } = useContext(DataContext);
  const settingsLoaded = pendingSettings === false && failedSettings === false && Boolean(settingsData);
  const settings = settingsLoaded ? settingsData : null;
  const settingsId = 'hr_talent' as const;

  const settingsView = getSettingsStrValue(settings, `${settingsId}__view`);
  const settingsSort = getSettingsStrValue(settings, `${settingsId}__sort`);
  const settingsDirection = getSettingsStrValue(settings, `${settingsId}__direction`);

  const [withLevels, setWithLevels] = useState(cohortHasLevels);
  useLayoutEffect(() => {
    setWithLevels(cohortHasLevels);
  }, [cohortHasLevels]);
  const [open, setOpen] = useState(false);
  const [skl, setSkl] = useState<Skill | null>(null);

  const [showChart, setShowChart] = useState(settingsView ? !isEditing && settingsView === CHART_VIEW : asChart);
  const [sortOrder, setSortOrder] = useState<TalentFinderSort>(
    DEFAULT_TF_SORT_DIRECTION[settingsSort as TalentFinderSort]
      ? settingsSort as TalentFinderSort : TalentFinderSort.employee
  );
  const [direction, setDirection] = useState<SortDirection>(
    VALID_SORT_DIRECTION[settingsDirection as SortDirection]
      ? settingsDirection as SortDirection : DEFAULT_TF_SORT_DIRECTION[TalentFinderSort.employee]
  );
  const [unfolded, toggleFolded] = useReducer(toggleReducer, false);

  const [employee, setEmployee] = useState<TalentEmployeeObject>();
  const [popupOpen, setPopupOpen] = useState(false);
  const handlePopupClose = useCallback(() => setPopupOpen(false), []);
  const handleEmployeeClick = useCallback((empl: TalentEmployeeObject | AdminEmployee) => {
    setEmployee(empl as TalentEmployeeObject);
    setPopupOpen(true);
  }, []);

  // new cohort
  const { mutate: newCohort, loading: newPending, failed: newFailed } = useMutationMethod({
    key: 'newCohort',
    mutation: useMutation(NEW_COHORT as typeof NewCohortDocument)
  });
  // save cohort
  const { mutate: saveCohort, loading: savePending, failed: saveFailed } = useMutationMethod({
    key: 'updateCohort',
    mutation: useMutation(UPDATE_COHORT as typeof UpdateCohortDocument)
  });

  // talent counts
  const { query: getCounts, pending: countsPending, failed: countsFailed, results: counts } = useQueryObject({
    data: undefined as unknown as TalentCounts,
    key: 'talentCounts',
    lazyQuery: useLazyQuery(TALENT_COUNTS_QUERY as typeof TalentCountsDocument)
  });

  // talent employees
  const {
    query: getEmployees, pending: employeesPending, failed: employeesFailed, count: employeesCount, results: foundEmployees
  } = useQueryCounted({
    data: undefined as unknown as TalentEmployeeObject,
    key: 'talentEmployees',
    lazyQuery: useLazyQuery(TALENT_EMPLOYEES_QUERY as typeof TalentEmployeesDocument)
  });

  const defaultSelection: EmployeeSelection = useMemo(() => employeeSelection || (cohortEmployees
    ? transform(cohortEmployees, (result, { id }) => {
      result[id] = true;
    }, {} as EmployeeSelection) : {} as EmployeeSelection), [cohortEmployees, employeeSelection]);

  const [allEmployees, highlightedIds] = useMemo(() => {
    if (!foundEmployees || !cohortEmployees) return [foundEmployees, undefined];
    const cohortIds = transform(cohortEmployees, (result, { id }) => {
      result[id] = true;
    }, {} as EmployeeSelection);
    const foundIds = transform(foundEmployees, (result, { id }) => {
      result[id] = true;
    }, {} as EmployeeSelection);
    const notFound = filter(cohortEmployees, ({ id }) => !foundIds[id]) as TalentEmployeeObject[];
    return [
      [
        ...filter(foundEmployees, ({ id }) => cohortIds[id]),
        ...notFound,
        ...filter(foundEmployees, ({ id }) => !cohortIds[id])
      ],
      map(notFound, 'id')
    ];
  }, [foundEmployees, cohortEmployees]);

  const canFold = size(allEmployees) > TALENT_FOLDED_EMPLOYEES;
  const employeesFolded = useMemo(
    () => canFold && allEmployees ? take(allEmployees, TALENT_FOLDED_EMPLOYEES) : allEmployees,
    [canFold, allEmployees]
  );
  const employees = canFold && !unfolded ? employeesFolded : allEmployees;

  const [selection, setSelection] = useState<EmployeeSelection>(defaultSelection);
  useLayoutEffect(() => {
    setSelection(defaultSelection);
  }, [defaultSelection]);

  const { by_desired_skills_count } = counts || {};
  const count = showChart ? sum(by_desired_skills_count) : employeesCount || 0;
  const pending = showChart ? countsPending : employeesPending;
  const failed = showChart ? countsFailed : employeesFailed;

  const {
    skills, setSkills,
    skillGroups, setSkillGroups,
    desiredSkillIds, setDesiredSkillIds
  } = useContext(PersistentContext);

  const [excludeJobIds, excludeOrgIds] = useMemo(() => [
    map(filter(skillGroups, ['type', 'job']), 'id'),
    map(filter(skillGroups, ['type', 'org']), 'id')
  ], [skillGroups]);

  const [rootUid, uid, leaderUid] = useMemo(() => supervisor ? [undefined, user?.code, undefined] : [
    getRootLeaderId(user),
    getDefaultLeaderId(user),
    getSelectedLeaderId(user)
  ], [supervisor, user]);

  const allSkills = useMemo(() => unionBy(
    skills,
    flatten(map(skillGroups, 'skills')),
    'id'
  ), [skillGroups, skills]);

  const [filters, setFilters] = useState<TalentFinderFilterValues>();
  const { job_id } = filters || {};

  const { requiredIds, desiredIds, skillLevels } = useMemo(() =>
    prepareSkills(allSkills, desiredSkillIds), [allSkills, desiredSkillIds]);

  const countsParams: TalentFinderInput | null = useMemo(() => filters ? {
    skill_ids: requiredIds,
    desired_skill_ids: desiredIds,
    ...withLevels && skillLevels ? { levels: skillLevels as number[] } : {},
    manager_id: toLower(filters.manager_id || ''),
    country_id: filters.country_id,
    state_id: filters.state_id,
    job_levels: filters.job_levels,
    selected_leader_id: leaderUid
  } : null, [requiredIds, desiredIds, withLevels, skillLevels, filters, leaderUid]);

  const defaultCohortTitle = useMemo(() => formatMessage({ id: 'hr.talentfinder.default_cohort_title' }), [formatMessage]);

  const canSave = useMemo(() => size(selection) >= 2 && (!isEditing ||
    !isEqual(cohortRequiredIds || [], requiredIds) ||
    !isEqual(cohortDesiredIds || [], desiredIds) ||
    !isEqual(cohortSkillLevels, withLevels ? skillLevels : undefined) ||
    !isEqual(sortBy(map(cohortEmployees, 'id')), sortBy(map(keys(selection), toSafeInteger)))
  ), [
    requiredIds, desiredIds, withLevels, skillLevels, selection,
    cohortEmployees, cohortRequiredIds, cohortDesiredIds, cohortSkillLevels, isEditing
  ]);

  const employeesChangeValues = useMemo(() => {
    if (!isEditing) return null;
    const newIds = map(keys(selection), toSafeInteger);
    const oldIds = map(cohortEmployees, 'id');
    const kept = size(intersection(newIds, oldIds));
    const added = size(newIds) - kept;
    const deleted = size(oldIds) - kept;
    const criteria = size(xor(cohortRequiredIds, requiredIds)) + size(xor(cohortDesiredIds, desiredIds)) +
      (isEqual(cohortSkillLevels, withLevels ? skillLevels : undefined) ? 0 : 1);
    return added >= 1 || deleted >= 1 ? { added, deleted, criteria, bold } : null;
  }, [
    selection, cohortEmployees, cohortRequiredIds, cohortDesiredIds, cohortSkillLevels,
    requiredIds, desiredIds, withLevels, skillLevels, isEditing
  ]);

  const hasSelection = size(allSkills) >= 1;
  const hasRequired = size(requiredIds) >= 1;

  useLayoutEffect(() => {
    if (settingsLoaded && hasRequired && countsParams) getCounts?.({ variables: {
      input: talentCountsParams(countsParams),
      pathBuilder: pathBuilder as unknown as string
    } });
  }, [settingsLoaded, hasRequired, countsParams, getCounts]);

  useLayoutEffect(() => {
    if (settingsLoaded && hasRequired && countsParams) getEmployees?.({ variables: {
      input: {
        ...talentCountsParams(countsParams),
        ...talentSortingParams(sortOrder, direction),
        ...!supervisor && job_id ? { job_id } : {}
      },
      pathBuilder: pathBuilder as unknown as string
    } });
  }, [settingsLoaded, hasRequired, countsParams, job_id, sortOrder, direction, supervisor, getEmployees]);

  useLayoutEffect(() => {
    if (cohortSkills) {
      setSkills(cohortSkills as Skill[]);
      setWithLevels(cohortHasLevels);
      setSkillGroups([]);
      setDesiredSkillIds(cohortDesiredIds || []);
    }
  }, [cohortSkills, cohortDesiredIds, cohortHasLevels, setSkills, setSkillGroups, setDesiredSkillIds]);

  useEffect(() => {
    if (settingsLoaded && !pendingSettingsUpdate) {
      if (isNil(asChart) && !isEditing) setShowChart(getSettingsStrValue(settings, `${settingsId}__view`) === CHART_VIEW);
      const sortFromSettings = getSettingsStrValue(settings, `${settingsId}__sort`) as TalentFinderSort;
      const directionFromSettings = getSettingsStrValue(settings, `${settingsId}__direction`) as SortDirection;
      setSortOrder(DEFAULT_TF_SORT_DIRECTION[sortFromSettings] ? sortFromSettings : TalentFinderSort.employee);
      setDirection(VALID_SORT_DIRECTION[directionFromSettings] ? directionFromSettings
        : DEFAULT_TF_SORT_DIRECTION[sortFromSettings] || DEFAULT_TF_SORT_DIRECTION[TalentFinderSort.employee]
      );
    }
  }, [settingsLoaded, pendingSettingsUpdate, settings, asChart, isEditing, settingsId]);

  // for Storybook & Jest-snapshots testing only
  useEffect(() => {
    if (testAction) newCohort?.({ variables: { input: { title: defaultCohortTitle, skill_ids: [], employee_ids: [] } } });
    if (testAction === false) saveCohort?.({ variables: { cohort_id: 1, input: { skill_ids: [], employee_ids: [] } } });
  }, [testAction, defaultCohortTitle, newCohort, saveCohort]);

  const handleAddSkill = useCallback((skill?: Skill | null) => {
    if (skill) setSkills((prevSkills) => [...prevSkills, skill]);
  }, [setSkills]);

  const handleAddSkillGroup = useCallback((skillGroup?: SkillGroup | null) => {
    if (skillGroup) setSkillGroups((prevSkillGroups) => [
      ...reject(prevSkillGroups, { id: skillGroup.id, type: skillGroup.type }),
      skillGroup.type === 'job' ? {
        ...skillGroup,
        skills: map(skillGroup.skills, (skill) => ({
          ...skill,
          skill_proficiency_level: skill.expected_level || SKILL_LEVEL_REGULAR
        }))
      } : skillGroup
    ]);
  }, [setSkillGroups]);

  const handleDeleteSkill = useCallback((id: number) => {
    setSkills((prevSkills) => reject(prevSkills, ['id', id]));
    setSkillGroups((prevSkillGroups) => map(prevSkillGroups, (skillGroup) => ({
      ...skillGroup,
      skills: reject(skillGroup.skills, ['id', id])
    })));
    setDesiredSkillIds((prevDesiredSkillIds) => without(prevDesiredSkillIds, id));
  }, [setSkills, setSkillGroups, setDesiredSkillIds]);

  const handleCategoryDelete = useCallback((id: number, type: SkillGroupType) => {
    setSkillGroups((prevSkillGroups) => reject(prevSkillGroups, { id, type }));
    // TODO: remove skill ids unique to this group from `desiredSkillIds`
  }, [setSkillGroups]);

  const handleSelectionChange = useCallback((newDesiredSkillIds: number[]) => {
    setDesiredSkillIds(newDesiredSkillIds);
  }, [setDesiredSkillIds]);

  const handleUpdateOpen = useCallback((id: number) => {
    const sk = find(allSkills, { id });
    if (sk) {
      setOpen(true);
      setSkl({ ...sk, current_level: sk.skill_proficiency_level || SKILL_LEVEL_REGULAR });
    }
  }, [allSkills]);
  const handleUpdateClose = useCallback(() => setOpen(false), []);
  const handleUpdateExited = useCallback(() => setSkl(null), []);

  const handleUpdateSkill = useCallback((level: SkillLevel | null) => {
    if (skl?.id && skl.current_level && level) {
      setSkills((prevSkills) => find(prevSkills, ['id', skl?.id])
        ? map(prevSkills, (skill) => skill.id === skl?.id ? {
            ...skill,
            skill_proficiency_level: level
          } : skill)
        : [...prevSkills, { ...skl, skill_proficiency_level: level }]
      );
      setOpen(false);
    }
  }, [skl, setSkills]);

  const handleReset = useCallback(() => {
    setSkills((cohortSkills || []) as Skill[]);
    setWithLevels(cohortHasLevels);
    setSkillGroups([]);
    setDesiredSkillIds(cohortDesiredIds || []);
    setSelection(defaultSelection);
  }, [cohortSkills, cohortDesiredIds, cohortHasLevels, defaultSelection, setDesiredSkillIds, setSkillGroups, setSkills]);

  const handleView = useCallback((value: string) => {
    const val = value === CHART_VIEW;
    setShowChart(val);
    if (settingsView !== value) updateSettings?.({ [`${settingsId}__view`]: value });
  }, [settingsView, updateSettings, settingsId]);

  const handleSort = useCallback((sortValue: string, newDir: SortDirection) => {
    const dir = DEFAULT_TF_SORT_DIRECTION[sortValue as TalentFinderSort];
    if (dir) {
      const dirValue = VALID_SORT_DIRECTION[newDir] ? newDir : dir;
      setSortOrder(sortValue as TalentFinderSort);
      setDirection(dirValue);
      if (settingsSort !== sortValue || settingsDirection !== dirValue) updateSettings?.({
        [`${settingsId}__sort`]: sortValue,
        [`${settingsId}__direction`]: dirValue
      });
    }
  }, [settingsSort, settingsDirection, updateSettings, settingsId]);

  const handleFiltersReset = useCallback(() => {
    const sortValue = TalentFinderSort.employee;
    const dirValue = DEFAULT_TF_SORT_DIRECTION[TalentFinderSort.employee];
    setSortOrder(sortValue as TalentFinderSort);
    setDirection(dirValue);
    if (settingsSort !== sortValue || settingsDirection !== dirValue) updateSettings?.({
      [`${settingsId}__sort`]: sortValue,
      [`${settingsId}__direction`]: dirValue
    });
  }, [settingsSort, settingsDirection, updateSettings, settingsId]);

  const handleSelect = useCallback((id: number, selected: boolean) => setSelection((prevSelection) =>
    selected ? { ...prevSelection, [id]: true } : omit(prevSelection, toString(id))
  ), []);

  const handleSaveCohort = useCallback(() => {
    const title = cohortId ? null : skillGroups?.[0]?.title || skills?.[0]?.title;
    const statuses = map(requiredIds, () => CohortSkillStatus.required);
    const hasDesired = size(desiredIds) >= 1;
    const skill_ids = hasDesired ? [...requiredIds, ...desiredIds] : requiredIds;
    (cohortId ? saveCohort : newCohort)?.({
      variables: {
        ...cohortId ? { cohort_id: cohortId } : {},
        input: {
          ...cohortId ? {} : {
            title: title ? formatMessage({ id: 'hr.talentfinder.cohort_title' }, { title }) : defaultCohortTitle
          },
          skill_ids,
          status: hasDesired ? [...statuses, ...map(desiredIds, () => CohortSkillStatus.desired)] : statuses,
          levels: withLevels && skillLevels ? skillLevels : map(skill_ids, () => SKILL_LEVEL_MIN),
          employee_ids: map(keys(selection), toSafeInteger)
        }
      } as NewCohortMutationVariables & UpdateCohortMutationVariables,
      update: (cache) => {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'cohorts' });
        if (cohortId) cache.evict({ id: 'ROOT_QUERY', fieldName: 'cohort' });
      },
      onCompleted: (data) => {
        const cohort_id = cohortId || (data as NewCohortMutation)?.newCohort?.id;
        if (cohort_id) navigate(injectParams(PATH_HR_COHORT, { cohort_id }));
      }
    });
  }, [
    requiredIds, desiredIds, withLevels, skillLevels, selection, defaultCohortTitle, skillGroups, skills, cohortId,
    newCohort, saveCohort, navigate, formatMessage
  ]);

  const exportParams: TalentExportParams | null = useMemo(() => !countsParams || !token || !isString(token) ? null : {
    ...talentCountsParams(countsParams),
    token
  }, [countsParams, token]);

  const disabled = (hasRequired && countsParams && pending) || !settingsLoaded || pendingSettingsUpdate ||
    newPending || savePending ? true : undefined;

  const exportButton = (
    <ExportButton
        endpoint={API_TALENT_FINDER_EXPORT}
        disabled={disabled || (showChart ? !counts : !allEmployees) || failed || count < 1 || !exportParams}
        params={exportParams as (ExportParams | null)}
    />
  );

  return (cohortFailed && <FetchFailedAlert flat/>) ||
  ((cohortPending || (isEditing && !cohort)) && <LoadingPlaceholder flat/>) || (
    <>
      {cohortTitle ? <CardTitle title={cohortTitle} withDivider/> : undefined}
      {hasSelection ? (
        <CardSection flex className={header}>
          <BoxTypography pb={0.25} pr={1} variant="subtitle1">
            <FormattedMessage id="hr.talentfinder.skills"/>
          </BoxTypography>
          <Button
              color="primary"
              variant="text"
              disabled={disabled}
              onClick={handleReset}
          >
            <FormattedMessage id="hr.talentfinder.reset"/>
          </Button>
          <Box pl={2.5}>
            <OnOffSwitch
                italic
                label="hr.talentfinder.with_levels"
                value={withLevels}
                onChange={setWithLevels}
                disabled={disabled}
            />
          </Box>
          {size(skillGroups) >= 1 && (
            <Box flex="1 0 0" display="flex" alignItems="center" justifyContent="flex-end" flexWrap="wrap">
              <BoxTypography
                  pl={3}
                  pr={1.25}
                  py={0.5}
                  alignItems="center"
                  variant="subtitle2"
                  textAlign="right"
                  fontStyle="italic"
              >
                <FormattedMessage id="hr.talentfinder.categories"/>
              </BoxTypography>
              {map(skillGroups, ({ id, type, title }) => (
                <CategoryChip
                    key={`${type}-${id}`}
                    groupId={id}
                    groupType={type}
                    label={title}
                    onDelete={handleCategoryDelete}
                    disabled={disabled}
                />
              ))}
            </Box>
          )}
        </CardSection>
      ) : undefined}
      <SkillsDnDSelector
          variant="talent"
          withLevels={withLevels}
          onClick={withLevels ? handleUpdateOpen : undefined}
          title="hr.talentfinder.required"
          subtitle="hr.talentfinder.desired"
          emptyMessage="hr.talentfinder.add_skills"
          allSkills={allSkills}
          skillIds={desiredSkillIds}
          excludeJobIds={excludeJobIds}
          excludeOrgIds={excludeOrgIds}
          setSkillIds={setDesiredSkillIds}
          onAddSkill={handleAddSkill}
          onAddSkillGroup={handleAddSkillGroup}
          onDeleteSkill={handleDeleteSkill}
          onSelectionChange={handleSelectionChange}
          disabledSkills={disabled}
          disabledSelected={disabled}
      />
      {hasRequired ? (
        <CardSection>
          <Box pb={1.5} display="flex" alignItems="flex-end" justifyContent="space-between">
            <BoxTypography variant="subtitle1">
              <FormattedMessage id="hr.talentfinder.results"/>
            </BoxTypography>
            {!HAS_DEV_PLAN || supervisor ? undefined : exportButton}
          </Box>
          <Divider light/>
          <DashboardFilters
              settingsId={settingsId}
              withLevels
              withReset
              compact
              onChange={setFilters}
              onReset={sortOrder !== TalentFinderSort.employee ||
                  direction !== DEFAULT_TF_SORT_DIRECTION[TalentFinderSort.employee]
                ? handleFiltersReset : undefined}
              uid={uid}
              rootUid={rootUid}
              withoutHierarchy={supervisor}
              withJobVariant={supervisor || showChart ? undefined : 'target'}
              disabled={disabled}
              action={settingsLoaded ? (
                <ViewSwitch
                    chart
                    chartStyle="pie"
                    value={showChart ? CHART_VIEW : TABLE_VIEW}
                    onChange={handleView}
                    disabled={pending || pendingSettingsUpdate ? true : undefined}
                />
              ) : undefined}
          />
          {(failed && <FetchFailedAlert/>) || (
            <>
              {showChart ? ((countsPending || !fontsLoaded || !by_desired_skills_count) && <LoadingPlaceholder/>) || (
                <>
                  <Divider light/>
                  <TalentFinderChart counts={by_desired_skills_count as number[]}/>
                  {!HAS_DEV_PLAN || supervisor ? <Divider light/> : undefined}
                </>
              ) : (
                <EmployeesTable
                    supervisor={/* TODO: remove HAS_DEV_PLAN */ !HAS_DEV_PLAN || supervisor}
                    route={/* TODO: remove HAS_DEV_PLAN */ !HAS_DEV_PLAN || supervisor ? supvEmplPath : undefined}
                    data={employees}
                    selectedIds={highlightedIds}
                    pending={employeesPending}
                    // failed={...} // handled on upper level
                    sortBy={sortOrder}
                    direction={direction}
                    changeSort={handleSort}
                    onClick={/* TODO: remove HAS_DEV_PLAN */ !HAS_DEV_PLAN || supervisor ? undefined : handleEmployeeClick}
                    selection={selection}
                    onSelect={handleSelect}
                    disabled={disabled}
                    disableMatchRateSort={!job_id}
                />
              )}
              <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  pt={2.5}
              >
                {!showChart && (
                  <Box
                      color={employeesPending || !employees ? 'action.disabled' : 'info.caption'}
                      alignSelf="flex-end"
                      pb={1.25}
                  >
                    {((employeesPending || !employees) && <CircularProgress size={16} color="inherit"/>) ||
                    (Boolean(employeesCount) && (
                      <>
                        <FormattedMessage
                            id="hr.talentfinder.employees.showing"
                            values={{
                              count: size(employees),
                              total: employeesCount,
                              sorted: sortOrder
                            }}
                        />
                        {canFold ? (
                          <>
                            &nbsp;
                            <StandardLink onClick={disabled ? undefined : toggleFolded} variant="inherit">
                              <FormattedMessage id={unfolded ? 'common.show_less' : 'common.show_more'}/>
                            </StandardLink>
                          </>
                        ) : undefined}
                      </>
                    ))}
                  </Box>
                )}
                {!HAS_DEV_PLAN || supervisor ? exportButton : !showChart && (
                  <>
                    <Button
                        color="primary"
                        variant="contained"
                        disableElevation
                        disabled={disabled || !canSave || employeesPending || !employees}
                        onClick={handleSaveCohort}
                        startIcon={newPending || savePending ? <CircularProgress size={16} color="inherit"/> : undefined}
                    >
                      <FormattedMessage id="hr.talentfinder.save_cohort"/>
                    </Button>
                    {employeesChangeValues && canSave && !employeesPending && employees ? (
                      <Box
                          color="info.caption"
                          alignSelf="center"
                          pt={1.25}
                      >
                        <FormattedMessage
                            id="hr.talentfinder.employees.change"
                            values={employeesChangeValues}
                        />
                      </Box>
                    ) : undefined}
                  </>
                )}
              </Box>
            </>
          )}
        </CardSection>
      ) : undefined}
      {HAS_DEV_PLAN && !supervisor && popupOpen && employee ? (
        <EmployeeDetailsPopup
            employee={employee}
            isOpen={popupOpen}
            onClose={handlePopupClose}
            route={supvEmplPath}
        />
      ) : undefined}
      {withLevels && skl ? (
        <SkillLevelDialog
            isOpen={open}
            skill={skl}
            plain
            depersonalized
            onUpdate={handleUpdateSkill}
            onCancel={handleUpdateClose}
            onExited={handleUpdateExited}
            disabled={disabled}
        />
      ) : undefined}
      {HAS_DEV_PLAN && !supervisor ? (
        <ActionFailedAlert
            message="hr.talentfinder.cohort_error"
            open={newFailed || saveFailed}
        />
      ) : undefined}
    </>
  );
};

TalentFinder.propTypes = TalentFinderPropTypes;

export default memo(TalentFinder);
