import { forwardRef, memo, useCallback, useMemo, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import { useLazyQuery } from '@apollo/client';
// Material Icon imports
import SearchIcon from '@mui/icons-material/Search';
// EmPath UI Components
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import Lookup from '@empathco/ui-components/src/elements/Lookup';
// local imports
import { LOCATIONS_QUERY } from '../graphql/Locations';
import { Location, LocationsDocument, LocationsQueryVariables } from '../graphql/types';
import { MAX_ITEMS } from '../config/params';
// SCSS imports
import { root } from './LocationSearch.module.scss';

type LocationSearchProps = {
  countryId?: number;
  value?: Location | null;
  onChange: (location: Location | null) => void;
  disabled?: boolean | null;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: ReactNode;
}

const LocationSearchPropTypes = {
  // attributes
  countryId: PropTypes.number,
  value: PropTypes.object as Validator<Location>,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.node
};

const LocationSearch = forwardRef<HTMLDivElement, LocationSearchProps>(({
  countryId,
  value,
  onChange,
  disabled = false,
  fullWidth = false,
  error,
  helperText
}, ref) => {
  const { query: getLocations, loading, failed, results, variables } = useQueryCounted({
    data: undefined as unknown as Location,
    key: 'locations',
    lazyQuery: useLazyQuery(LOCATIONS_QUERY as typeof LocationsDocument)
  });

  const fetchParams: Partial<LocationsQueryVariables> = useMemo(() => ({
    country_id: countryId,
    limit: MAX_ITEMS
  }), [countryId]);

  const fetchLocations = useCallback(
    (params: LocationsQueryVariables) => getLocations?.({ variables: params }),
    [getLocations]);

  return (
    <Lookup
        ref={ref}
        fullWidth={fullWidth}
        type="locations"
        className={root}
        fetched={results}
        pending={loading}
        failed={failed}
        params={variables}
        fetch={fetchLocations}
        fetchParams={fetchParams}
        onChange={onChange}
        value={value}
        disabled={disabled}
        error={error}
        helperText={helperText}
        popupIcon={<SearchIcon/>}
    />
  );
});

LocationSearch.displayName = 'LocationSearch';

LocationSearch.propTypes = LocationSearchPropTypes;

export default memo(LocationSearch);
