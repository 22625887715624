import { Fragment, memo, useCallback, useMemo, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import isNil from 'lodash/isNil';
import toSafeInteger from 'lodash/toSafeInteger';
import { FormattedMessage, FormattedNumber } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TableSortLabel from '@mui/material/TableSortLabel';
import LinearProgress from '@mui/material/LinearProgress';
// Material Icon imports
import CheckIcon from '@mui/icons-material/Check';
// EmPath UI Components
import SortArrow from '@empathco/ui-components/src/icons/SortArrow';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import TargetIcon from '@empathco/ui-components/src/elements/TargetIcon';
import SkillNewTag from '@empathco/ui-components/src/elements/SkillNewTag';
// import InfoButton from '@empathco/ui-components/src/elements/InfoButton';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import DataTable from '@empathco/ui-components/src/elements/DataTable';
// local imports
import { AdminJob, AdminJobsSort, SortDirection } from '../graphql/types';
import {
  JobSortExt, DEFAULT_SORT_DIRECTION, JOB_SORT_ASC, JOB_SORT_DESC,
  JOB_SORT_LEVEL, JOB_SORT_MATCH_RATE, JOB_SORT_OPEN_REQS, JOB_SORT_TITLE, JOB_SORT_LOCATION
} from '../constants/jobSort';
import useNonReducedUI from '../constants/managementLevel';
import { Job } from '../models/job';
import { DEFAULT_ADMIN_JOBS_DIRECTION, RedeploymentEmployeeJob, JobLookupItem } from '../graphql/customTypes';
import useModels from '../helpers/models';
import JobLevel from '../elements/JobLevel';
import OpenReqsLink from '../v3/OpenReqsLink';
import RoleName from '../v3/RoleName';
// SCSS imports
import { overlayDefault } from '@empathco/ui-components/src/styles/modules/Overlay.module.scss';
import { rowHeight, newTag } from './JobsTable.module.scss';

type JobsTableProps = {
  redeployment?: boolean;
  admin?: boolean;
  hrbp?: boolean;
  supervisor?: boolean;
  // uid?: string | null;
  title?: string | null;
  data?: (Job | JobLookupItem | AdminJob)[] | null;
  pending?: boolean | null;
  failed?: boolean | null;
  sortBy?: JobSortExt | null;
  direction?: boolean | null;
  changeSort: (sort: JobSortExt, direction: SortDirection) => void;
  disabled?: boolean | null;
  onClick?: ((code: string, job?: Job | JobLookupItem | AdminJob) => void) | null;
  withOpenReqsPopup?: boolean;
  withReloading?: boolean;
}

const JobsTablePropTypes = {
  // attributes
  redeployment: PropTypes.bool,
  admin: PropTypes.bool,
  hrbp: PropTypes.bool,
  supervisor: PropTypes.bool,
  // uid: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object.isRequired as Validator<Job | AdminJob>),
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  sortBy: PropTypes.string as Validator<JobSortExt>,
  direction: PropTypes.bool,
  changeSort: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  withOpenReqsPopup: PropTypes.bool,
  withReloading: PropTypes.bool
};

const JobsTable: FunctionComponent<JobsTableProps> = ({
  redeployment = false,
  admin = false,
  hrbp = false,
  supervisor = false,
  // uid,
  title: tableTitle,
  data,
  pending = false,
  failed = false,
  sortBy,
  direction = false,
  changeSort,
  disabled = false,
  onClick,
  withOpenReqsPopup = false,
  withReloading = false
}) => {
  const { showNonReducedUI } = useNonReducedUI();
  const { getLocationStr, isInternational } = useModels();

  const loading = withReloading ? pending && !data : pending || !data;
  const reloading = Boolean(withReloading && pending && data);

  const sortByTitle = useCallback(() => changeSort(
    admin ? AdminJobsSort.title : JOB_SORT_TITLE,
    sortBy === (admin ? AdminJobsSort.title : JOB_SORT_TITLE) && !isNil(direction)
      ? (direction && SortDirection.descending) || SortDirection.ascending
      : (admin && DEFAULT_ADMIN_JOBS_DIRECTION[AdminJobsSort.title]) ||
        (DEFAULT_SORT_DIRECTION[JOB_SORT_TITLE] && SortDirection.ascending) || SortDirection.descending
  ), [direction, changeSort, sortBy, admin]);

  const sortByMatchRate = useCallback(() => changeSort(
    JOB_SORT_MATCH_RATE,
    sortBy === JOB_SORT_MATCH_RATE && !isNil(direction)
      ? (direction && SortDirection.descending) || SortDirection.ascending
      : (DEFAULT_SORT_DIRECTION[JOB_SORT_MATCH_RATE] && SortDirection.ascending) || SortDirection.descending
  ), [direction, changeSort, sortBy]);

  const sortBySkills = useCallback(() => changeSort(
    AdminJobsSort.skill_count,
    sortBy === AdminJobsSort.skill_count && !isNil(direction)
      ? (direction && SortDirection.descending) || SortDirection.ascending
      : DEFAULT_ADMIN_JOBS_DIRECTION[AdminJobsSort.skill_count]
  ), [direction, changeSort, sortBy]);

  const sortByEmployees = useCallback(() => changeSort(
    AdminJobsSort.employee_count,
    sortBy === AdminJobsSort.employee_count && !isNil(direction)
      ? (direction && SortDirection.descending) || SortDirection.ascending
      : DEFAULT_ADMIN_JOBS_DIRECTION[AdminJobsSort.employee_count]
  ), [direction, changeSort, sortBy]);

  const sortByOpenReqs = useCallback(() => changeSort(
    admin ? AdminJobsSort.open_req_count : JOB_SORT_OPEN_REQS,
    sortBy === (admin ? AdminJobsSort.open_req_count : JOB_SORT_OPEN_REQS) && !isNil(direction)
      ? (direction && SortDirection.descending) || SortDirection.ascending
      : (admin && DEFAULT_ADMIN_JOBS_DIRECTION[AdminJobsSort.open_req_count]) ||
        (DEFAULT_SORT_DIRECTION[JOB_SORT_OPEN_REQS] && SortDirection.ascending) || SortDirection.descending
  ), [direction, changeSort, sortBy, admin]);

  const sortByLevel = useCallback(() => changeSort(
    admin ? AdminJobsSort.management_level : JOB_SORT_LEVEL,
    sortBy === (admin ? AdminJobsSort.management_level : JOB_SORT_LEVEL) && !isNil(direction)
      ? (direction && SortDirection.descending) || SortDirection.ascending
      : (admin && DEFAULT_ADMIN_JOBS_DIRECTION[AdminJobsSort.management_level]) ||
        (DEFAULT_SORT_DIRECTION[JOB_SORT_LEVEL] && SortDirection.ascending) || SortDirection.descending
  ), [direction, changeSort, sortBy, admin]);

  const sortByLocation = useCallback(() => changeSort(
    admin ? AdminJobsSort.location : JOB_SORT_LOCATION,
    sortBy === (admin ? AdminJobsSort.location : JOB_SORT_LOCATION) && !isNil(direction)
      ? (direction && SortDirection.descending) || SortDirection.ascending
      : (admin && DEFAULT_ADMIN_JOBS_DIRECTION[AdminJobsSort.location]) ||
        (DEFAULT_SORT_DIRECTION[JOB_SORT_LOCATION] && SortDirection.ascending) || SortDirection.descending
  ), [direction, changeSort, sortBy, admin]);

  const sortDisabled = disabled || loading || failed || !data || size(data) < 1;

  const titles = useMemo(() => map([
    ...redeployment ? [{}] : [],
    { label: 'jobs_index.job_title', id: JOB_SORT_TITLE, handler: sortByTitle },
    ...redeployment || admin
      ? [{ label: 'hr.redeployment.column.location', id: JOB_SORT_LOCATION, handler: sortByLocation }] : [],
    ...hrbp || admin ? [] : [{ label: 'jobs_index.match_rate', id: JOB_SORT_MATCH_RATE, handler: sortByMatchRate }],
    ...admin ? [
      { label: 'admin.jobs.skill_count', id: AdminJobsSort.skill_count, handler: sortBySkills },
      { label: 'admin.jobs.employee_count', id: AdminJobsSort.employee_count, handler: sortByEmployees }
    ] : [],
    { label: 'jobs_index.open_reqs', id: JOB_SORT_OPEN_REQS, handler: sortByOpenReqs },
    { label: 'jobs_index.level', id: JOB_SORT_LEVEL, handler: sortByLevel }
  ], ({ id, label, handler }) => id && label && handler ? (
    <TableSortLabel key={id}
        direction={(sortBy === id ? direction : DEFAULT_SORT_DIRECTION[id]) ? JOB_SORT_ASC : JOB_SORT_DESC}
        active={sortBy === id}
        onClick={handler}
        disabled={sortDisabled}
        IconComponent={SortArrow}
    >
      <FormattedMessage id={label}/>
    </TableSortLabel>
  ) : (
    <Fragment key={id}>
      {' '}
    </Fragment>
  )), [
    hrbp, admin, redeployment, sortDisabled, sortBy, direction,
    sortByLevel, sortByMatchRate, sortBySkills, sortByEmployees, sortByOpenReqs, sortByLocation, sortByTitle
  ]);

  // eslint-disable-next-line complexity
  const rows = useMemo(() => map(data, (role) => {
    const { code, is_index_display, title } = role;
    const { is_current, is_target, is_new, match_rate } = role as Job;
    const { is_selected } = role as RedeploymentEmployeeJob;
    const { location } = role as RedeploymentEmployeeJob | AdminJob;
    const { skill_count, employee_count } = role as AdminJob;
    const showMatchRate = !hrbp && !admin && showNonReducedUI(role);
    const clickable = (!supervisor || showMatchRate) && (!redeployment || !disabled);
    const roleName = (
      <RoleName
          variant={redeployment ? 'h5' : 'h4'}
          isEmployee={!supervisor && !hrbp && !admin && !redeployment}
          code={is_index_display || (clickable && onClick) ? code : undefined}
          title={title}
          isCurrent={!hrbp && !admin && is_current}
          isTarget={supervisor && is_target ? true : undefined}
          role={role}
          onClick={clickable ? onClick : undefined}
      />
    );
    return {
      selected: redeployment ? Boolean(is_selected) : !hrbp && !admin && Boolean(is_current || is_target),
      values: [
        /* eslint-disable react/jsx-key */
        ...redeployment ? [is_selected ? <CheckIcon color="primary"/> : ''] : [],
        !hrbp && !admin && (is_target || is_new) ? (
          <Box display="flex" alignItems="center">
            {roleName}
            {is_target ? (
              <Box pl={1.5}>
                <TargetIcon active tooltip={supervisor ? undefined : 'common.target_role'} tooltipPlacement="right"/>
              </Box>
            ) : undefined}
            {is_new && !is_target ? <SkillNewTag active className={newTag}/> : undefined}
          </Box>
        ) : roleName,
        ...redeployment || admin ? [
          (
            <Typography variant="body2">
              {getLocationStr(location) ||
              (isInternational(location?.country) && <FormattedMessage id="role_details.intl_location"/>) ||
              '—'}
            </Typography>
          )
        ] : [],
        ...admin ? [
          skill_count || 0,
          employee_count || 0
        ] : [],
        ...hrbp || admin ? [] : [
          <Box display="flex" alignItems="center" justifyContent="center" className={rowHeight}>
            {showMatchRate ? (
              // eslint-disable-next-line react/style-prop-object
              <FormattedNumber value={toSafeInteger(match_rate) / 100} style="percent" minimumFractionDigits={0}/>
            ) : '—'}
          </Box>
        ],
        <OpenReqsLink
            admin={admin}
            plain
            table
            role={role}
            withOpenReqsPopup={withOpenReqsPopup}
        />,
        <Box display="flex" alignItems="center" justifyContent="center" className={rowHeight}>
          <JobLevel role={role}/>
        </Box>
        /* eslint-enable react/jsx-key */
      ]
    };
  }), [
    data, supervisor, redeployment, disabled, hrbp, admin, onClick, withOpenReqsPopup,
    showNonReducedUI, getLocationStr, isInternational
  ]);

  const table = (
    <DataTable
        titles={titles}
        empty="jobs_index.empty"
        data={rows}
        pending={loading}
        failed={failed}
        lastLeftAlignedTitle={redeployment ? 2 : undefined}
    />
  );

  const content = redeployment ? table : (
    <CardSection>
      {table}
    </CardSection>
  );

  return (
    <>
      {tableTitle ? (
        <CardSection dark compact>
          <BoxTypography
              py={2}
              variant="body1"
              fontStyle="italic"
              color="text.label"
          >
            <FormattedMessage id={tableTitle} defaultMessage={tableTitle}/>
          </BoxTypography>
        </CardSection>
      ) : undefined}
      {reloading ? (
        <Box
            flexGrow={1}
            display="flex"
            flexDirection="column"
            position="relative"
        >
          {content}
          <Box className={overlayDefault}>
            <LinearProgress/>
          </Box>
        </Box>
      ) : content}
    </>
  );
};

JobsTable.propTypes = JobsTablePropTypes;

export default memo(JobsTable);
