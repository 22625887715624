import { useState, useCallback, useEffect, useMemo, type FunctionComponent, type ForwardedRef } from 'react';
// import PropTypes, { Validator } from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
// local imports
import { ILookupItem } from '../models/lookupItem';
import { DEFAULT_MENU_PROPS, DEFAULT_MENU_RIGHT } from '../helpers/menus';
import { simpleForwardRef } from '../helpers/react';
import CardTitle from '../elements/CardTitle';
import CardSection from '../elements/CardSection';
import CloseIconButton from '../elements/CloseIconButton';
// SCSS imports
import { content, searchInput, confirmButton } from './AddSkillPopover.module.scss';

export type SkillSearchComponentProps = {
  supervisor?: boolean;
  exclude?: number[];
  value?: ILookupItem | null;
  onChange: (skill: ILookupItem | null) => void;
  disabled?: boolean | null;
  fullWidth?: boolean;
}

type AddSkillPopoverProps<P, C> = {
  readonly supervisor?: boolean;
  readonly title?: string | null;
  readonly centered?: boolean | null;
  readonly exclude?: number[] | null;
  readonly anchorEl?: Element | null;
  readonly onAdd: (skill: ILookupItem) => void;
  readonly onCancel: () => void;
  readonly disabled?: boolean | null;
  readonly SkillSearchComponentProps?: Partial<Omit<P, 'supervisor' | 'exclude' | 'value' | 'onChange' | 'disabled'>>;
  readonly SkillSearchComponent: C;
};

// const AddSkillPopoverPropTypes = {
//   // attributes
//   supervisor: PropTypes.bool,
//   title: PropTypes.string,
//   centered: PropTypes.bool,
//   exclude: PropTypes.arrayOf(PropTypes.number.isRequired),
//   anchorEl: PropTypes.instanceOf(Element),
//   onAdd: PropTypes.func.isRequired,
//   onCancel: PropTypes.func.isRequired,
//   disabled: PropTypes.bool,
//   SkillSearchComponent: PropTypes.oneOfType([
//     PropTypes.object.isRequired,
//     PropTypes.func.isRequired
//   ]).isRequired as Validator<FunctionComponent<SkillSearchComponentProps>>
// };

const AddSkillPopoverRender = <
  P extends SkillSearchComponentProps,
  C extends FunctionComponent<P>
>({
  supervisor = false,
  title,
  centered = false,
  exclude,
  anchorEl,
  onAdd,
  onCancel,
  disabled = false,
  SkillSearchComponent,
  SkillSearchComponentProps
}: AddSkillPopoverProps<P, C>, ref: ForwardedRef<HTMLDivElement>) => {
  const [skill, setSkill] = useState<ILookupItem | null>(null);

  const isOpen = Boolean(anchorEl);

  const [visible, setVisible] = useState(isOpen);

  useEffect(() => {
    if (isOpen) {
      setVisible(true);
      setSkill(null);
    }
  }, [isOpen]);

  const handleSearch = useCallback((skl: ILookupItem | null) => setSkill(skl), []);

  const handleConfirm = useCallback(() => {
    if (skill) onAdd(skill);
  }, [onAdd, skill]);

  const transitionProps = useMemo(() => ({ onExited: () => {
    setVisible(false);
  } }), []);

  return (
    <Popover
        ref={ref}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...centered ? DEFAULT_MENU_PROPS : DEFAULT_MENU_RIGHT}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={onCancel}
        TransitionProps={transitionProps}
    >
      <CloseIconButton small onClick={onCancel}/>
      <CardTitle
          compact
          title={title || 'common.add_skill.title'}
      />
      <CardSection
          className={content}
      >
        <Box flexGrow={1} py={1} display="flex" alignItems="center" className={searchInput}>
          {visible ? (
            <SkillSearchComponent
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...{
                  fullWidth: true,
                  ...SkillSearchComponentProps || {},
                  supervisor,
                  disabled,
                  exclude: exclude || undefined,
                  value: skill,
                  onChange: handleSearch
                } as JSX.IntrinsicAttributes & JSX.LibraryManagedAttributes<C, P>}
            />
          ) : undefined}
        </Box>
        <Button
            onClick={handleConfirm}
            color="primary"
            variant="contained"
            disableElevation
            disabled={disabled || !skill}
            className={confirmButton}
        >
          <FormattedMessage id="common.button.add"/>
        </Button>
      </CardSection>
    </Popover>
  );
};

const AddSkillPopover = simpleForwardRef(AddSkillPopoverRender);

// AddSkillPopover.displayName = 'AddSkillPopover';

// AddSkillPopover.propTypes = AddSkillPopoverPropTypes;

export default AddSkillPopover;
