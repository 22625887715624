import { forwardRef } from 'react';
import { Link as RouterLink, type LinkProps } from 'react-router-dom';
import Tab, { type TabProps } from '@mui/material/Tab';

const TabLink = forwardRef<HTMLAnchorElement,
  Omit<TabProps, 'ref' | 'component'> &
  LinkProps
>((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tab ref={ref} component={RouterLink} {...props}/>
));

TabLink.displayName = 'TabLink';

export default TabLink;
