import { gql } from '@apollo/client';
// local imports
import { API_DOMAIN_VERIFY } from '../config/api';

export const VERIFY_EMAIL_QUERY = gql`
  mutation VerifyEmail(
    $email: String!
  ) {
    verifyEmail(
      email: $email
    )
    @rest(
      type: "VerificationResponse",
      path: "${API_DOMAIN_VERIFY}?{args}",
      method: "GET"
    ) {
      success
      error_message
    }
  }
`;
