/* eslint-disable global-require */
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillCanonical } from '@formatjs/intl-getcanonicallocales/should-polyfill';
if (shouldPolyfillLocale()) {
  require('@formatjs/intl-locale/polyfill');
}
if (shouldPolyfillCanonical()) {
  require('@formatjs/intl-getcanonicallocales/polyfill');
}
if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/locale-data/en');
}
if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/locale-data/en');
}
if (!Intl.DisplayNames) {
  require('@formatjs/intl-displaynames/polyfill');
  require('@formatjs/intl-displaynames/locale-data/en');
}
/* eslint-enable global-require */

/* eslint-disable import/first */
import { createIntl, createIntlCache, type FormatNumberOptions, type IntlCache } from 'react-intl';
import en from '../locales/en.json';
/* eslint-enable import/first */

// prevents memory leak
const cache = createIntlCache();

export const defaultDateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  // eslint-disable-next-line no-process-env
  ...process.env.NODE_ENV === 'test' ? { timeZone: 'utc' } : {}
};

export const dateOnlyOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  // eslint-disable-next-line no-process-env
  ...process.env.NODE_ENV === 'test' ? { timeZone: 'utc' } : {}
};

export const shortDateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  // eslint-disable-next-line no-process-env
  ...process.env.NODE_ENV === 'test' ? { timeZone: 'utc' } : {}
};

export const uniformDateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  // eslint-disable-next-line no-process-env
  ...process.env.NODE_ENV === 'test' ? { timeZone: 'utc' } : {}
};

export const longDateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  // eslint-disable-next-line no-process-env
  ...process.env.NODE_ENV === 'test' ? { timeZone: 'utc' } : {}
};

export const defaultIntegerOptions: FormatNumberOptions = {
  style: 'decimal',
  maximumFractionDigits: 0
};

export const percentageOptions: FormatNumberOptions = {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
};

export const newIntl = (
  messages?: Record<string, string>,
  customMessages?: Record<string, string>,
  customCache?: IntlCache
) => createIntl({
  locale: 'en',
  messages: {
    ...en,
    ...messages || {},
    ...customMessages || {}
  },
  formats: {
    number: {
      integer: defaultIntegerOptions
    },
    date: {
      numeric: defaultDateOptions
    }
  },
  wrapRichTextChunksInFragment: true
}, customCache || cache);

export const intl = newIntl();
