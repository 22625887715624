import { useCallback, useMemo, useState } from 'react';

function useConfirmationDialog(
  onConfirm: () => void
) {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmMounted, setConfirmMounted] = useState(false);

  const handleConfirm = useCallback(() => {
    setConfirmOpen(false);
    onConfirm();
  }, [onConfirm]);

  const handleCancel = useCallback(() => setConfirmOpen(false), []);
  const handleExited = useCallback(() => setConfirmMounted(false), []);

  const handleAction = useCallback(() => {
    setConfirmOpen(true);
    setConfirmMounted(true);
  }, []);

  return useMemo(() => ({
    confirmOpen,
    confirmMounted,
    handleAction,
    handleCancel,
    handleExited,
    handleConfirm
  }), [confirmMounted, confirmOpen, handleAction, handleCancel, handleConfirm, handleExited]);
}

export default useConfirmationDialog;
