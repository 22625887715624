import PropTypes from 'prop-types';
import toSafeInteger from 'lodash/toSafeInteger';
import indexOf from 'lodash/indexOf';

const CONST_VARIANT =
  ['months', 'opportunityMonths', 'opportunityHours', 'years', 'skills', 'match_rate', 'jobs', 'timezones'] as const;
export type ConstVariant = typeof CONST_VARIANT[number];
export const ConstVariantProp = PropTypes.oneOf(CONST_VARIANT);

export const CONST_MONTHS = [6, 12, 24] as const; // Learning Progress Durations
export const CONST_MONTHS_OPPORTINUTY = [1, 2, 3, 6] as const; // Opportunity Durations (fulltime => months)
export const CONST_HOURS_OPPORTINUTY = [10, 20, 30] as const; // Opportunity Durations (sidegig => hours)
export const CONST_YEARS = [1, 2, 3] as const; // Dashboard Analytics - Employees' Skills - Period
export const CONST_MIN_SKILLS = [5, 10, 15, 25] as const; // Dashboard Analytics - Employees' Skills - Min Skills
// Dashboard Analytics - Close Match Jobs - Match Rate:
export const CONST_MATCH_RATE = [50, 60, 70, 80, 90] as const;
// Dashboard Analytics - Jobs Movement - Job Total:
export const CONST_JOBS = [5, 10, 15, 20] as const;
// Opportunity Marketplace - Timezones:
export const CONST_TIMEZONES = [300, 360, 420, 480] as const; // in minutes: <any>, EST, CST, MST, PST

export type ConstValue = number;
export const ConstValueProp = PropTypes.number;

export const CONST_VALUES: Record<ConstVariant, readonly ConstValue[]> = {
  months: CONST_MONTHS,
  opportunityMonths: CONST_MONTHS_OPPORTINUTY,
  opportunityHours: CONST_HOURS_OPPORTINUTY,
  years: CONST_YEARS,
  skills: CONST_MIN_SKILLS,
  match_rate: CONST_MATCH_RATE,
  jobs: CONST_JOBS,
  timezones: CONST_TIMEZONES
} as const;

export const toValidConst = (
  value: string | number | unknown | null | undefined,
  defaultValue: ConstValue,
  values: readonly number[]
): ConstValue => {
  const val = toSafeInteger(value);
  return indexOf(values, val) >= 0
    ? val as ConstValue
    : defaultValue;
};
