import { forwardRef, useCallback } from 'react';
import PropTypes, { type Validator } from 'prop-types';
// Material UI imports
import { withStyles } from 'tss-react/mui';
import { type Theme } from '@mui/material/styles';
import Chip, { chipClasses, type ChipProps } from '@mui/material/Chip';
// Material Icon imports
import CloseIcon from '@mui/icons-material/Close';
// EmPath UI Components
import { spacing } from '@empathco/ui-components/src/helpers/styles';
// local imports
import { SkillGroupType, SkillGroupTypePropType } from '../context/persistent';

const styles = (left = false, transparent = false) => (theme: Theme) => ({
  root: {
    minHeight: 'auto',
    ...transparent ? {
      backgroundColor: 'transparent'
    } : {}
  },
  label: {
    paddingRight: spacing(0.5)
  },
  outlined: {
    paddingLeft: left ? 0 : spacing(3),
    paddingRight: left ? spacing(3) : 0
  },
  outlinedSecondary: {
    boxShadow: 'none',
    borderRadius: 0
  },
  deletable: {
    [`&.${chipClasses.outlinedSecondary}`]: {
      '&:hover, &:focus': {
        backgroundColor: transparent ? 'transparent' : theme.palette.background.paper
      }
    }
  }
});

type CategoryChipProps = Omit<ChipProps, 'color' | 'variant' | 'deleteIcon' | 'onDelete'> & {
  groupId: number;
  groupType: SkillGroupType;
  onDelete: (id: number, type: SkillGroupType) => void;
};

const CategoryChipPropTypes = {
  // attributes
  groupId: PropTypes.number.isRequired,
  groupType: SkillGroupTypePropType.isRequired as Validator<SkillGroupType>,
  onDelete: PropTypes.func.isRequired
};

const CategoryChip = forwardRef<HTMLDivElement, CategoryChipProps>(({
  groupId,
  groupType,
  onDelete,
  ...props
}, ref) => {
  const handleDelete = useCallback(() => onDelete(groupId, groupType), [groupId, groupType, onDelete]);
  return (
    <Chip
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        ref={ref}
        color="secondary"
        variant="outlined"
        deleteIcon={<CloseIcon fontSize="inherit"/>}
        onDelete={handleDelete}
    />
  );
});

CategoryChip.displayName = 'CategoryChip';

CategoryChip.propTypes = CategoryChipPropTypes;

export default withStyles(CategoryChip, styles());

export const CategoryChipLeftTransparent = withStyles(CategoryChip, styles(true, true));
