import { gql } from '@apollo/client';
// local imports
import { API_COHORT } from '../config/api';

export const DELETE_COHORT = gql`
  mutation DeleteCohort(
    $cohort_id: Int!
  ) {
    deleteCohort(
      cohort_id: $cohort_id
    )
    @rest(
      type: "MutationResponse",
      path: "${API_COHORT}",
      method: "DELETE"
    ) {
      success # just a placeholder; this request has empty response (status 204 only)
    }
  }
`;
