import { memo, useEffect, useMemo, type FunctionComponent } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
// EmPath UI Components
import useCurrentPath from '@empathco/ui-components/src/hooks/useCurrentPath';
import useQueryObject from '@empathco/ui-components/src/hooks/useQueryObject';
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
import CardTabbar from '@empathco/ui-components/src/elements/CardTabbar';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
// local imports
import { getDelegator } from '../models/user';
import { COHORT_QUERY } from '../graphql/Cohort';
import { CohortDocument } from '../graphql/types';
import { CohortDetails } from '../graphql/customTypes';
import useCustomerSettings from '../config/customer';
import usePathConfig, { PATH_HR_TALENT_FINDER, PATH_HR_COHORTS, PATH_HR_COHORT_EDIT } from '../config/paths';
import { getCohortId } from '../helpers/routerParams';
import useVerifyUser from '../hooks/useVerifyUser';
import LeaderHeader from '../elements/LeaderHeader';
import Screen from '../v3/Screen';
import TalentFinder from '../panels/TalentFinder';
import SavedCohorts from '../panels/SavedCohorts';
import SavedCohort from '../panels/SavedCohort';

const HRTalentFinderScreen: FunctionComponent = () => {
  const { HAS_DEV_PLAN } = useCustomerSettings();
  const { ROUTES } = usePathConfig();
  const path = useCurrentPath(ROUTES);
  const cohort_id = getCohortId(useParams());
  const { content, user } = useVerifyUser({ manager: true, hr: true });
  const leader = getDelegator(user);

  // lazy load cohort
  const { query: getCohort, pending, failed, results: cohort } = useQueryObject({
    data: undefined as unknown as CohortDetails,
    key: 'cohort',
    flatResults: true,
    lazyQuery: useLazyQuery(COHORT_QUERY as typeof CohortDocument)
  });

  useEffect(() => {
    if (!content && cohort_id) getCohort?.({ variables: { cohort_id } });
  }, [content, cohort_id, getCohort]);

  const loadedCohort = Boolean(cohort_id) && cohort_id === cohort?.id;

  const breadcrumbs = useMemo(() => loadedCohort ? [{ title: cohort.title }] : undefined, [cohort, loadedCohort]);

  const items = useMemo(() => HAS_DEV_PLAN ? [
    {
      title: 'hr.talentfinder',
      link: PATH_HR_TALENT_FINDER
    },
    {
      title: 'hr.cohorts',
      active: Boolean(cohort_id),
      link: PATH_HR_COHORTS
    }
  ] : undefined, [cohort_id, HAS_DEV_PLAN]);

  return content || (
    <Screen withSettings breadcrumbs={breadcrumbs}>
      {leader ? <LeaderHeader leader={leader}/> : undefined}
      <ContentCard withoutTopMargin={Boolean(leader)}>
        {items ? (
          <CardTabbar withoutScroll items={items}>
            <DndProvider backend={HTML5Backend} context={window}>
              <TalentFinder/>
            </DndProvider>
            {cohort_id ? (path === PATH_HR_COHORT_EDIT && (
              <DndProvider backend={HTML5Backend} context={window}>
                <TalentFinder
                    cohort={loadedCohort ? cohort : null}
                    pending={pending || cohort_id !== cohort?.id}
                    failed={failed}
                />
              </DndProvider>
            )) || (
              <SavedCohort
                  cohort={loadedCohort ? cohort : null}
                  pending={pending || cohort_id !== cohort?.id}
                  failed={failed}
              />
             ) : <SavedCohorts/>}
          </CardTabbar>
        ) : (
          <>
            <CardTitle title="hr.talentfinder" withDivider/>
            <DndProvider backend={HTML5Backend} context={window}>
              <TalentFinder/>
            </DndProvider>
          </>
        )}
      </ContentCard>
    </Screen>
  );
};

export default memo(HRTalentFinderScreen);
