import { memo, useState, useCallback, useEffect, useMemo, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
// EmPath UI Components
import CloseIconButton from '@empathco/ui-components/src/elements/CloseIconButton';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import CardFooter from '@empathco/ui-components/src/elements/CardFooter';
// local imports
import { EmployeeSearchItem } from '../graphql/customTypes';
import EmployeeSearch from '../v3/EmployeeSearch';
// SCSS imports
import { button } from './AddEmployeeDialog.module.scss';

type AddEmployeeDialogProps = {
  isOpen: boolean;
  onAdd: (employee: EmployeeSearchItem) => void;
  onCancel: () => void;
  disabled?: boolean | null;
};

const AddEmployeeDialogPropTypes = {
  // attributes
  isOpen: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

const AddEmployeeDialog: FunctionComponent<AddEmployeeDialogProps> = ({
  isOpen,
  onAdd,
  onCancel,
  disabled = false
}) => {
  const [employee, setEmployee] = useState<EmployeeSearchItem | null>(null);
  const [visible, setVisible] = useState(isOpen);

  useEffect(() => {
    if (isOpen) {
      setVisible(true);
      setEmployee(null);
    }
  }, [isOpen]);

  const handleConfirm = useCallback(() => employee && onAdd(employee), [employee, onAdd]);
  const transitionProps = useMemo(() => ({ onExited: () => {
    setVisible(false);
  } }), []);

  return (
    <Dialog
        disableEnforceFocus
        maxWidth="sm"
        fullWidth
        scroll="body"
        open={isOpen}
        onClose={onCancel}
        TransitionProps={transitionProps}
    >
      <CloseIconButton small onClick={onCancel}/>
      <CardTitle
          title="hr.dev_plans.select_employee.title"
          withDivider
      />
      <CardSection flex>
        {visible ? (
          <EmployeeSearch
              fullWidth
              value={employee}
              onChange={setEmployee}
              disabled={disabled}
          />
        ) : undefined}
      </CardSection>
      <CardFooter withDivider>
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
          <Button
              onClick={handleConfirm}
              color="primary"
              variant="contained"
              disableElevation
              disabled={disabled || !employee}
              className={button}
          >
            <FormattedMessage id="common.button.add"/>
          </Button>
        </Box>

      </CardFooter>
    </Dialog>
  );
};

AddEmployeeDialog.propTypes = AddEmployeeDialogPropTypes;

export default memo(AddEmployeeDialog);
