import map from 'lodash/map';
import find from 'lodash/find';
import { type AxiosResponse } from 'axios';
import type { RowInput } from 'jspdf-autotable';
// local imports
import { Skill } from '../models/skill';
import { Employee } from '../models/employee';
import { APIResponse } from '../models/apiResponse';
import { getSkillCurrentLevel } from '../helpers/models';
import {
  IAutoTable, IJsPDF, Rows, Headers,
  getTitleFilename,
  downloadAndSave, saveToCSV, saveToPDF
} from '../helpers/export';

// Team Builder - Team Members export

const ROSTER_CSV_COLUMNS = [
  'supervisor.export.code',
  'supervisor.export.first_name',
  'supervisor.export.last_name',
  'supervisor.export.email',
  'supervisor.export.role_code',
  'supervisor.export.role_title',
  'supervisor.export.city',
  'supervisor.export.state',
  'supervisor.export.country'
];

function saveRoster (
  title: string,
  headers: Headers,
  teamSkills: Skill[],
  employees: Employee[],
  jsPDF?: IJsPDF,
  autoTable?: IAutoTable
) {
  const titles = [
    ...headers,
    ...map(teamSkills, 'title')
  ];
  const rows = map(employees, ({ code, first_name, last_name, email, current_job, location, skills }) => [
    code,
    first_name,
    last_name,
    email,
    current_job?.code,
    current_job?.title,
    location?.city,
    location?.state,
    location?.country,
    ...map(teamSkills, ({ id }) => getSkillCurrentLevel(find(skills, ['id', id])))
  ] as RowInput) as Rows;
  const filename = `team_builder_${getTitleFilename(title)}`;
  if (jsPDF && autoTable) {
    saveToPDF(jsPDF, autoTable, filename, titles, rows, title, '' /* , {} */);
  } else {
    saveToCSV(filename, titles, rows);
  }
}

function exportRoster(
  format: string,
  title: string,
  headers: Headers,
  skills: Skill[],
  employees: Employee[]
): Promise<boolean | number> {
  return downloadAndSave<Employee>(
    format,
    { status: 200, data: { results: employees } as APIResponse<Employee> } as AxiosResponse<APIResponse<Employee>>,
    (data, jsPDF, autoTable) => saveRoster(title, headers, skills, data, jsPDF, autoTable)
  );
}

const exportRosterHook = {
  ROSTER_CSV_COLUMNS,
  exportRoster
};

function useExportRoster() {
  return exportRosterHook;
}

export default useExportRoster;
