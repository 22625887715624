import { gql } from '@apollo/client';
// local imports
import { API_JOB } from '../config/api';

export const JOB_DETAILS_QUERY = gql`
  query JobDetails(
    $job_code: String!
  ) {
    jobDetails(
      job_code: $job_code
    )
    @rest(
      type: "JobWithSkills",
      path: "${API_JOB}"
    ) {
      id
      title
      code
      skills {
        id
        title
        abbr
        expected_level
      }
    }
  }
`;
