import { forwardRef, memo, useState, useCallback, type SyntheticEvent } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import { visuallyHidden } from '@mui/utils';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Snackbar, { type SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
// Material Icon imports
import Share from 'mdi-material-ui/Share';
// local imports
import { SNACKBAR_AUTOHIDE_DURATION, SNACKBAR_ELEVATION, anchorOrigin } from '../config/params';

type CopyToClipboardButtonProps = {
  text: string;
  message?: string;
  srLabel?: string;
  disabled?: boolean | null;
  className?: string;
  // for Storybook only
  testCopied?: boolean;
};

const CopyToClipboardButtonPropTypes = {
  text: PropTypes.string.isRequired,
  message: PropTypes.string,
  srLabel: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  // for Storybook only
  testCopied: PropTypes.bool
};

const CopyToClipboardButton = forwardRef<HTMLButtonElement, CopyToClipboardButtonProps>(({
  text,
  message,
  srLabel,
  disabled,
  className,
  testCopied = false
}, ref) => {
  const [snackbarOpen, setSnackbarOpen] = useState(testCopied);

  const handleSnackbarOpen = useCallback((_text: string, success: boolean) => {
    if (success) setSnackbarOpen(true);
  }, []);

  const handleSnackbarClose = useCallback((_event: Event | SyntheticEvent, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  }, []);

  const alert = (
    <Alert onClose={handleSnackbarClose} severity="success" variant="standard" elevation={SNACKBAR_ELEVATION}>
      <Typography variant="body1">
        <FormattedMessage id={message} defaultMessage={message}/>
      </Typography>
    </Alert>
  );

  return (
    <>
      <CopyToClipboard text={text} onCopy={handleSnackbarOpen}>
        <IconButton ref={ref} color="primary" disabled={disabled ? true : undefined} className={className}>
          <Share color="inherit"/>
          {srLabel ? (
            <Box sx={visuallyHidden}>
              <FormattedMessage id={srLabel} defaultMessage={srLabel}/>
            </Box>
          ) : undefined}
        </IconButton>
      </CopyToClipboard>
      {(typeof jest === 'undefined' && (
        <Snackbar
            open={snackbarOpen}
            onClose={handleSnackbarClose}
            autoHideDuration={0.5 * SNACKBAR_AUTOHIDE_DURATION}
            anchorOrigin={anchorOrigin}
        >
          {alert}
        </Snackbar>
      )) || (snackbarOpen ? alert : undefined)}
    </>
  );
});

CopyToClipboardButton.displayName = 'CopyToClipboardButton';

CopyToClipboardButton.propTypes = CopyToClipboardButtonPropTypes;

export default memo(CopyToClipboardButton);
