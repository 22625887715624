import { gql } from '@apollo/client';
// local imports
import { API_DA_CURRENT_ROLE_MATCH } from '../config/api';

export const DA_CURRENT_ROLE_MATCH_QUERY = gql`
  query DACurrentRoleMatch(
    $input: DACurrentRoleMatchInput!
    $pathBuilder: String!
  ) {
    daCurrentRoleMatch(
      input: $input
    )
    @rest(
      type: "DACurrentRoleMatchPayload",
      path: "${API_DA_CURRENT_ROLE_MATCH}",
      pathBuilder: $pathBuilder
    ) {
      total_employees
      results {
        month
        rate_bucket
        total
      }
    }
  }
`;
