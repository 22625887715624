import { memo, useState, useMemo, useEffect, type FunctionComponent, type MouseEventHandler } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import isNull from 'lodash/isNull';
import countBy from 'lodash/countBy';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import LinearProgress from '@mui/material/LinearProgress';
// EmPath UI Components
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import CloseIconButton from '@empathco/ui-components/src/elements/CloseIconButton';
// local imports
import { Skill } from '../models/skill';
import TbItemChip from '../elements/TbItemChip';
// SCSS imports
import * as classes from './EmployeeSkillsPopup.module.scss';

const contentSx = { py: 1 };

type EmployeeSkillsPopupProps = {
  title?: string;
  skills?: Skill[] | null;
  isOpen?: boolean;
  onClose: MouseEventHandler<HTMLButtonElement>;
}

const EmployeeSkillsPopupPropTypes = {
  // attributes
  title: PropTypes.string,
  skills: PropTypes.array as Validator<Skill[]>,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

const EmployeeSkillsPopup: FunctionComponent<EmployeeSkillsPopupProps> = ({
  title,
  skills,
  isOpen = false,
  onClose
}) => {
  const [mounted, setMounted] = useState(isOpen);

  useEffect(() => {
    if (isOpen) setMounted(true);
  }, [isOpen]);

  const transitionProps = useMemo(() => ({ onExited: () => {
    setMounted(false);
  } }), []);

  const all = size(skills);
  const satisfied = countBy(skills, 'satisfied').true || 0;
  const normalized = all > 0 ? satisfied * 100 / all : 0;

  return mounted ? (
    <Dialog
        disableEnforceFocus
        maxWidth="xs"
        fullWidth
        scroll="body"
        open={isOpen}
        onClose={onClose}
        TransitionProps={transitionProps}
    >
      <CloseIconButton onClick={onClose}/>
      <DialogContent sx={contentSx}>
        <BoxTypography pt={2} pb={1} pr={4} variant="subtitle1">
          <FormattedMessage
              id="hr.teambuilder.skills_popup.title"
              values={{ title }}
          />
        </BoxTypography>
        <Box flexGrow={1} display="flex" flexDirection="column">
          <Box pb={1}>
            <LinearProgress classes={classes} variant="determinate" value={normalized}/>
          </Box>
          <BoxTypography variant="body2" pb={2} fontStyle="italic" color="text.secondary">
            <FormattedMessage
                id="hr.teambuilder.skills_popup.num_satisfied"
                values={{ all, satisfied }}
            />
          </BoxTypography>
          {map(skills, (skill, index) => (
            <Box key={index} pb={1}>
              <TbItemChip item={skill} satisfied={isNull(skill.satisfied) ? false : skill.satisfied}/>
            </Box>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  ) : null;
};

EmployeeSkillsPopup.propTypes = EmployeeSkillsPopupPropTypes;

export default memo(EmployeeSkillsPopup);
