import { type FunctionComponent, memo, useEffect } from 'react';

const ScrollToTopOnMount: FunctionComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
};

export default memo(ScrollToTopOnMount);
