import { gql } from '@apollo/client';
// local imports
import { API_HR_ORG } from '../config/api';

export const UPDATE_IN_DEMAND_SKILLS = gql`
  mutation UpdateInDemandSkills(
    $org_id: Int!
    $input: UpdateInDemandSkillsInput!
  ) {
    updateInDemandSkills(
      org_id: $org_id,
      input: $input
    )
    @rest(
      type: "MutationResponse",
      path: "${API_HR_ORG}{args.org_id}/skills/reset/",
      method: "POST"
    ) {
      success
    }
  }
`;
