import { gql } from '@apollo/client';
// local imports
import { API_EMPLOYEE_SKILLS } from '../config/api';

export const EMPLOYEE_SKILLS_SEARCH_QUERY = gql`
  query EmployeeSkillsSearch(
    $input: SkillsSearchInput!
    $pathBuilder: String!
  ) {
    employeeSkillsSearch(
      input: $input
    )
    @rest(
      type: "EmployeeSkillsPayload",
      path: "${API_EMPLOYEE_SKILLS}",
      pathBuilder: $pathBuilder
    ) {
      count
      results {
        id
        title
        abbr
        type
        is_in_demand
        is_my
        is_target
        is_mentoring
        is_inference
        is_inference_newer
        inferred_level
        current_level
        # target_level
        # ple_link
        # trending
      }
    }
  }
`;
