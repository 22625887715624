import PropTypes from 'prop-types';

export type ContextParams = Record<string, unknown>;

export type StateFlag = boolean | null;

export interface IActionState<P = ContextParams> {
  pending: StateFlag;
  failed: StateFlag;
  params: P | null;
}
export type ActionState<P = ContextParams> = IActionState<P>;

export interface IContextObject<T, P = ContextParams> extends IActionState<P> {
  data: T | null;
}
export type ContextObject<T, P = ContextParams> = IContextObject<T, P>;

export interface ICountedData<T> {
  data: T[];
  count: number;
}

export interface IContextEntity<T, P = ContextParams> extends IActionState<P> {
  data: T[] | null;
}
export interface IContextEntityWithCount<T, P = ContextParams> extends IContextEntity<T, P> {
  count: number | null;
  jobId?: number;
}

export type ContextEntity<T, P = ContextParams> = IContextEntity<T, P>;
export type ContextEntityWithCount<T, P = ContextParams> = IContextEntityWithCount<T, P>;

export type ContextState<K extends string = string> = Record<
  K,
  IContextEntity<unknown> | IContextObject<unknown> | ActionState | Function | boolean | string | null | undefined
>;

export const ContextObjectProp = PropTypes.shape({
  data: PropTypes.object,
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  params: PropTypes.object,
  count: PropTypes.number
});

export const ContextEntityProp = PropTypes.shape({
  data: PropTypes.array,
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  params: PropTypes.object
});

export const ContextEntityWithCountProp = PropTypes.shape({
  data: PropTypes.array,
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  params: PropTypes.object,
  count: PropTypes.number
});

export const ActionEntityProp = PropTypes.shape({
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  params: PropTypes.object
});
