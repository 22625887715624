import { gql } from '@apollo/client';
// local imports
import { API_COHORTS } from '../config/api';

export const NEW_COHORT = gql`
  mutation NewCohort(
    $input: NewCohortInput!
  ) {
    newCohort(
      input: $input
    )
    @rest(
      type: "Cohort",
      path: "${API_COHORTS}",
      method: "POST"
    )
    # status 201
    {
      id
      success
    }
  }
`;
