import { forwardRef, memo, type MouseEventHandler, type Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import CardActions, { type CardActionsProps } from '@mui/material/CardActions';
import DialogActions, { type DialogActionsProps } from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
// SCSS imports
import { footer, builder, builderPending, quickTour, prevButton, nextButton } from './BuilderFooter.module.scss';

type BuilderFooterProps = {
  tour?: boolean;
  onPrev: MouseEventHandler<HTMLButtonElement>;
  onNext: MouseEventHandler<HTMLButtonElement>;
  isFirst?: boolean;
  isLast?: boolean;
  disabledPrev?: boolean;
  disabledNext?: boolean;
  topPadding?: boolean;
  pendingPrev?: boolean;
  pendingNext?: boolean;
  prevCaption?: string;
  nextCaption?: string;
};

const BuilderFooterPropTypes = {
  tour: PropTypes.bool,
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  disabledPrev: PropTypes.bool,
  disabledNext: PropTypes.bool,
  topPadding: PropTypes.bool,
  pendingPrev: PropTypes.bool,
  pendingNext: PropTypes.bool,
  prevCaption: PropTypes.string,
  nextCaption: PropTypes.string
};

const BuilderFooter = forwardRef<Component<DialogActionsProps | CardActionsProps>, BuilderFooterProps>(({
  tour = false,
  onPrev,
  onNext,
  isFirst = false,
  isLast = false,
  disabledPrev = false,
  disabledNext = false,
  topPadding = false,
  pendingPrev = false,
  pendingNext = false,
  prevCaption,
  nextCaption
}, ref) => {
  const Actions = tour ? DialogActions : CardActions;
  return (
    <Actions
        ref={ref}
        disableSpacing={tour ? true : undefined}
        className={clsx(footer, {
          [builder]: !tour && !topPadding,
          [builderPending]: !tour && topPadding,
          [quickTour]: tour
        })}
    >
      <Button
          onClick={onPrev}
          color="primary"
          variant="outlined"
          size="large"
          disabled={disabledPrev || pendingPrev}
          startIcon={pendingPrev ? <CircularProgress size={18} color="inherit"/> : undefined}
          className={prevButton}
      >
        <FormattedMessage
            id={prevCaption || (tour
              ? (isFirst && 'common.button.close') || 'common.button.back'
              : 'common.button.skip'
            )}
            defaultMessage={prevCaption}
        />
      </Button>
      <Button
          onClick={onNext}
          color="primary"
          variant="contained"
          disableElevation
          size="large"
          disabled={disabledNext || pendingNext}
          startIcon={pendingNext ? <CircularProgress size={18} color="inherit"/> : undefined}
          className={nextButton}
      >
        <FormattedMessage
            id={nextCaption || (tour
              ? (isLast && 'common.button.finish') || 'common.button.next'
              : 'common.button.confirm'
            )}
            defaultMessage={nextCaption}
        />
      </Button>
    </Actions>
  );
});

BuilderFooter.displayName = 'BuilderFooter';

BuilderFooter.propTypes = BuilderFooterPropTypes;

export default memo(BuilderFooter);
