import { memo, type FunctionComponent } from 'react';
// local imports
import useVerifyUser from '../hooks/useVerifyUser';
import Screen from '../v3/Screen';
import Delegation from '../components/Delegation';

const DelegationScreen: FunctionComponent = () => {
  const { content } = useVerifyUser({ hr: true });
  return content || (
    <Screen>
      <Delegation/>
    </Screen>
  );
};

export default memo(DelegationScreen);
