import { JobStatusObject } from '../models/jobStatusObject';

export const JOB_QUEUED = 'queued' as const;
export const JOB_PENDING = 'in_progress' as const;
export const JOB_COMPLETED = 'complete' as const;
export const JOB_ERROR = 'error' as const;

const JOB_STATUSES = [JOB_QUEUED, JOB_PENDING, JOB_COMPLETED, JOB_ERROR] as const;

export type JobStatus = typeof JOB_STATUSES[number];

export const getJobStatus = (jobStatus?: JobStatusObject | null): string => jobStatus?.run_status || '';

export const isInProgress = (status?: string) => status !== JOB_COMPLETED && status !== JOB_ERROR;
  // ^^^ this more reliable than: `status === JOB_QUEUED || status === JOB_PENDING;`
export const isCompleted = (status?: string) => status === JOB_COMPLETED;
export const isFailed = (status?: string) => status === JOB_ERROR;
