import { forwardRef, memo, useCallback, useMemo } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import toSafeInteger from 'lodash/toSafeInteger';
// Material UI imports
import Box from '@mui/material/Box';
// EmPath UI Components
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import TargetIcon from '@empathco/ui-components/src/elements/TargetIcon';
import ItemCard from '@empathco/ui-components/src/elements/ItemCard';
import SkillLevelGauge from '@empathco/ui-components/src/elements/SkillLevelGauge';
import SkillTrendIcon from '@empathco/ui-components/src/elements/SkillTrendIcon';
import SkillNewTag from '@empathco/ui-components/src/elements/SkillNewTag';
// local imports
import { Skill, SKILL_LEVEL_MIN } from '../models/skill';
import { PATH_SKILL } from '../config/paths';
import { getSkillCurrentLevelOnly, isInferred } from '../helpers/models';
import CourseHours from '../elements/CourseHours';
import SuggestedSkillIcon from '../v3/SuggestedSkillIcon';
// SCSS imports
import { titleRow, nextIcon } from './SkillCard.module.scss';

type SkillCardProps = {
  skill: Skill;
  onLevelClick?: (skill: Skill) => void;
  onRemoveClick?: (skill: Skill) => void;
  // onTargetClick?: (skill: Skill) => void;
  withRemoveIcon?: boolean;
  disabled?: boolean | null;
  pendingTarget?: boolean;
  pendingLevel?: boolean;
  isEmployee?: boolean;
  plain?: boolean;
  depersonalised?: boolean;
  withExpectedLevel?: boolean;
  withCourses?: boolean;
  withRemoveSuggested?: boolean;
  fullWidth?: boolean;
  narrowGrid?: boolean;
  small?: boolean;
};

const SkillCardPropTypes = {
  // attributes
  skill: PropTypes.object.isRequired as Validator<Skill>,
  onLevelClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  // onTargetClick: PropTypes.func,
  withRemoveIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  pendingTarget: PropTypes.bool,
  pendingLevel: PropTypes.bool,
  isEmployee: PropTypes.bool,
  plain: PropTypes.bool,
  depersonalised: PropTypes.bool,
  withExpectedLevel: PropTypes.bool,
  withCourses: PropTypes.bool,
  withRemoveSuggested: PropTypes.bool,
  fullWidth: PropTypes.bool,
  narrowGrid: PropTypes.bool,
  small: PropTypes.bool
};

// eslint-disable-next-line complexity
const SkillCard = forwardRef<HTMLDivElement, SkillCardProps>(({
  skill,
  onLevelClick,
  onRemoveClick,
  withRemoveIcon = false,
  disabled = false,
  pendingLevel = false,
  pendingTarget = false,
  isEmployee = false,
  plain = false,
  depersonalised = false,
  withExpectedLevel = false,
  withCourses = false,
  withRemoveSuggested = false,
  fullWidth = false,
  narrowGrid = false,
  small = false
  // onTargetClick
}, ref) => {
  const {
    abbr, title, expected_level, inferred_level, is_in_demand, is_new, is_target, is_inference_newer,
    course_hours, course_titles, is_suggested_by_manager
  } = skill || {};
  const currentLevel = depersonalised ? undefined : getSkillCurrentLevelOnly(skill);

  const handleLevelClick = useCallback(() => {
    if (onLevelClick && skill && skill.id) onLevelClick(skill);
  }, [onLevelClick, skill]);

  const handleRemoveClick = useCallback(() => {
    if (onRemoveClick && skill && skill.id) onRemoveClick(skill);
  }, [onRemoveClick, skill]);

  // const handleTargetClick = useCallback(() => {
  //   if (onTargetClick && skill && skill.id) onTargetClick(skill);
  // }, [onTargetClick, skill]);

  const link = useMemo(() => injectParams(PATH_SKILL, { skill_id: abbr }), [abbr]);

  const icons = plain ? null : (
    <>
      {isEmployee && !depersonalised && (is_target || pendingTarget) ? (
        <TargetIcon
            small
            active
            tooltip={pendingTarget ? undefined : 'skill.target_this'}
            tooltipPlacement="top"
            // button
            // onClick={handleTargetClick}
            pending={pendingTarget}
            // disabled={disabled}
            // className={is_in_demand ? targetIcon : undefined}
            className={nextIcon}
        />
      ) : undefined}
      {is_suggested_by_manager && !depersonalised ? <SuggestedSkillIcon active className={nextIcon}/> : undefined}
      {/* {(!is_target || is_in_demand) && ( */}
      <SkillTrendIcon small active={is_in_demand}/>
      {/* )} */}
    </>
  );

  return (
    <ItemCard
        ref={ref}
        md={narrowGrid ? 6 : undefined}
        lg={narrowGrid ? 4 : undefined}
        xl={narrowGrid ? 3 : undefined}
        onClick={disabled || !onLevelClick ? undefined : handleLevelClick}
        onRemoveClick={!disabled && onRemoveClick && (
          (withRemoveIcon && currentLevel !== SKILL_LEVEL_MIN) ||
          (withRemoveSuggested ? is_suggested_by_manager : isInferred(skill))
        ) ? handleRemoveClick : undefined}
        link={disabled || onLevelClick ? undefined : link}
        badge
        fullWidth={fullWidth}
        footer={(
          <SkillLevelGauge
              level={currentLevel}
              depersonalised={depersonalised}
              requiredLevel={withExpectedLevel ? toSafeInteger(expected_level) : undefined}
              inferredLevel={inferred_level}
              isInferenceNewer={is_inference_newer}
              pending={pendingLevel}
          />
        )}
    >
      <Box
          display="flex"
          pr={onRemoveClick ? 1 : undefined}
          className={small ? undefined : titleRow}
      >
        {plain ? title : (
          <>
            <Box pr={1.5} flexGrow={1} flexBasis={0} className={small ? undefined : titleRow}>
              {isEmployee && !depersonalised && !small ? <SkillNewTag active={is_new}/> : undefined}
              <div>
                {title}
              </div>
            </Box>
            <Box
                display="flex"
                px={1}
                pt={!isEmployee || depersonalised || small ? 0.25 : '1.25rem'}
                flexDirection={withCourses ? 'column' : undefined}
            >
              {withCourses ? (
                <>
                  <Box display="flex" flexGrow={1} justifyContent="flex-end">
                    {icons}
                  </Box>
                  <CourseHours
                      hours={course_hours}
                      courses={course_titles}
                  />
                </>
              ) : icons}
            </Box>
          </>
        )}
      </Box>
    </ItemCard>
  );
});

SkillCard.displayName = 'SkillCard';

SkillCard.propTypes = SkillCardPropTypes;

export default memo(SkillCard);
