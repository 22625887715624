import { forwardRef, memo, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import last from 'lodash/last';
import { useLazyQuery } from '@apollo/client';
// Material UI imports
import Box from '@mui/material/Box';
// EmPath UI Components
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import { bold } from '@empathco/ui-components/src/helpers/intl';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import useConfirmationDialog from '@empathco/ui-components/src/hooks/useConfirmationDialog';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import ConfirmDialog from '@empathco/ui-components/src/elements/ConfirmDialog';
// local imports
import { EMPLOYEE_DEV_PLANS_QUERY } from '../graphql/EmployeeDevPlans';
import { EmployeeDevPlansDocument } from '../graphql/types';
import { EmployeeDevPlan } from '../graphql/customTypes';
import { CONST_MONTHS } from '../constants/constValues';
import { PATH_MY_DEV_PLAN } from '../config/paths';
import LargeButton from '../elements/LargeButton';

type EmployeeNewDevPlanButtonProps = {
  jobId: number;
  title: string;
  userId: number;
  pending?: boolean;
  failed?: boolean;
  onClick: () => void;
}

const EmployeeNewDevPlanButtonPropTypes = {
  // attributes
  jobId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

const EmployeeNewDevPlanButtons = forwardRef<HTMLDivElement, EmployeeNewDevPlanButtonProps>(({
  jobId,
  title,
  userId,
  pending = false,
  failed = false,
  onClick
}, ref) => {
  // lazy load dev plans
  const { query: getDevPlans, pending: pendingDevPlans, failed: failedDevPlans, results: devplans } = useQueryCounted({
    data: undefined as unknown as EmployeeDevPlan,
    key: 'employeeDevplans',
    lazyQuery: useLazyQuery(EMPLOYEE_DEV_PLANS_QUERY as typeof EmployeeDevPlansDocument)
  });

  const {
    confirmOpen,
    confirmMounted,
    handleAction: handleNewDevPlan,
    handleCancel,
    handleExited,
    handleConfirm
  } = useConfirmationDialog(onClick);

  useEffect(() => {
    getDevPlans?.({ variables: { duration: last(CONST_MONTHS) } });
  }, [getDevPlans]);

  const devPlansLoaded = !pendingDevPlans && !failedDevPlans && Boolean(devplans);

  const [devplan, myDevplan] = useMemo(() => {
    if (!devPlansLoaded || !devplans) return [undefined, undefined];
    const activeDevplan =
      find(devplans, ({ is_active, job }: EmployeeDevPlan) => is_active && job?.id === jobId) as EmployeeDevPlan;
    return [
      activeDevplan || find(devplans, ({ job }) => job?.id === jobId),
      activeDevplan && activeDevplan?.owner?.id === userId
        ? activeDevplan
        : find(devplans, ({ owner, job }) => owner?.id === userId && job?.id === jobId)
    ];
  }, [devPlansLoaded, devplans, jobId, userId]);

  const notOwnDevPlan = !myDevplan && Boolean(devplan);

  const buttonValues = useMemo(() => ({ title, br: <br/>, notOwnDevPlan }), [title, notOwnDevPlan]);
  const dialogValues = useMemo(() => ({ title, bold }), [title]);

  return (
    <>
      {pendingDevPlans ? undefined : (
        <Box ref={ref} flex="1 0 0" display="flex" justifyContent="center" pt={1} pb={0.75} data-html2canvas-ignore>
          {myDevplan ? undefined : (
            <LargeButton
                title="dev_plans.button.job_new"
                values={buttonValues}
                onClick={handleNewDevPlan}
                disabled={pending}
                pending={pending}
            />
          )}
          {notOwnDevPlan ? <Box width="11.5%"/> : undefined}
          {myDevplan || devplan ? (
            <LargeButton
                title="dev_plans.button.job_view"
                values={buttonValues}
                link={injectParams(PATH_MY_DEV_PLAN, { plan_id: myDevplan?.id || devplan?.id })}
                disabled={pending}
                pending={pending}
            />
          ) : undefined}
        </Box>
      )}
      {confirmMounted ? (
        <ConfirmDialog
            open={confirmOpen}
            title="dev_plans.new"
            text="dev_plans.new.confirm"
            withCancelButton
            values={dialogValues}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            onExited={handleExited}
        />
      ) : undefined}
      <ActionFailedAlert
          message="hr.dev_plan.new_devplan_error"
          open={failed}
      />
    </>
  );
});

EmployeeNewDevPlanButtons.displayName = 'EmployeeNewDevPlanButtons';

EmployeeNewDevPlanButtons.propTypes = EmployeeNewDevPlanButtonPropTypes;

export default memo(EmployeeNewDevPlanButtons);
