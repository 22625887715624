import { memo, useCallback, useEffect, useMemo, useRef, useState, type FunctionComponent, type ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import trim from 'lodash/trim';
import toString from 'lodash/toString';
import { useMutation } from '@apollo/client';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
// EmPath UI Components
import useMutationMethod from '@empathco/ui-components/src/hooks/useMutationMethod';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
// local imports
import { NEW_ADMIN_PASSWORD } from '../graphql/NewAdminPassword';
import { NewAdminPasswordDocument } from '../graphql/types';
import { PATH_ADMIN, PATH_HOME } from '../config/paths';
import { checkPasswordStrength } from '../helpers/password';
import SplashText from '../elements/SplashText';
import Screen from '../v3/Screen';

type AdminSetPasswordScreenProps = {
  // for Storybook only
  pwd?: string;
  repeatPwd?: string;
  testAction?: boolean;
};

const AdminSetPasswordScreenPropTypes = {
  pwd: PropTypes.string,
  repeatPwd: PropTypes.string,
  testAction: PropTypes.bool
};

const AdminSetPasswordScreen: FunctionComponent<AdminSetPasswordScreenProps> = ({
  pwd = '',
  repeatPwd = '',
  testAction
}) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();
  const { search } = useLocation();
  const navigate = useNavigate();

  const inputRef = useRef<HTMLInputElement>();
  const { current } = inputRef;

  const [password, setPassword] = useState(pwd);
  const [repeatPassword, setRepeatPassword] = useState(repeatPwd);

  const [passwordLabel, repeatPasswordLabel] = useMemo(() => [
    formatMessage({ id: 'splash.set_password.password' }),
    formatMessage({ id: 'splash.set_password.repeat_password' })
  ], [formatMessage]);

  // set new password
  const { mutate: newPassword, loading: passwordPending, failed: passwordFailed } = useMutationMethod({
    key: 'newAdminPassword',
    mutation: useMutation(NEW_ADMIN_PASSWORD as typeof NewAdminPasswordDocument)
  });

  const token = useMemo(() => {
    if (search) try {
      const tkn = new URLSearchParams(search)?.get?.('token');
      return tkn ? trim(tkn) : '';
    } catch (_error) {
      // nothing to do
    }
    return undefined
  }, [search]);

  useEffect(() => {
    current?.focus?.();
  }, [current]);

  const handlePassword = useCallback((event: ChangeEvent<{ name?: string; value: unknown; }>) => {
    event?.preventDefault();
    setPassword(toString(event?.target?.value));
  }, []);

  const handleRepeatPassword = useCallback((event: ChangeEvent<{ name?: string; value: unknown; }>) => {
    event?.preventDefault();
    setRepeatPassword(toString(event?.target?.value));
  }, []);

  const handleClick = useCallback(() => {
    if (!password || !token) return;
    newPassword?.({
      variables: { input: { password, token } },
      onCompleted: () => navigate(PATH_ADMIN)
    });
  }, [password, token, newPassword, navigate]);

  const passwordUnmatched = Boolean(password && repeatPassword) && password !== repeatPassword;
  const passwordStrength = checkPasswordStrength(password);
  const invalidPassword = passwordStrength.strength < 4 || size(passwordStrength.tips) >= 1;
  const disabled = invalidPassword || passwordUnmatched || passwordPending;

  const passwordError = useMemo(() => invalidPassword ? (
    <>
      {map(passwordStrength.tips, (tip) => (
        <Box key={tip} color="primary.contrastText" component="span">
          <FormattedMessage id={tip}/>
          <br/>
        </Box>
      ))}
    </>
  ) : '\u00A0', [invalidPassword, passwordStrength.tips]);

  const repeatPasswordError = useMemo(() => passwordUnmatched ? (
    <Box color="primary.contrastText" component="span">
      <FormattedMessage id="splash.set_password.match"/>
    </Box>
  ) : '\u00A0', [passwordUnmatched]);

  useEffect(() => {
    if (testAction) handleClick(); 
  }, [testAction, handleClick]);

  if (!token) return <Navigate replace to={PATH_HOME}/>;

  return (
    <>
      <Screen appbar="empty" splash>
        <SplashText
            variant="set_password"
            onClick={handleClick}
            pending={passwordPending}
            disabled={disabled}
        >
          <Box width="28rem" mx="auto" pt={2} px={6}>
            <TextField
                inputRef={inputRef}
                variant="standard"
                color="secondary"
                margin="normal"
                fullWidth
                id="password"
                name="password"
                type="password"
                autoComplete="password"
                autoFocus
                label={passwordLabel}
                onChange={handlePassword}
                value={password}
                disabled={passwordPending ? true : undefined}
                // error={invalidPassword}
                helperText={passwordError}
            />
          </Box>
          <Box width="28rem" mx="auto" pt={2} px={6}>
            <TextField
                variant="standard"
                color="secondary"
                margin="normal"
                fullWidth
                id="repeatPassword"
                name="repeatPassword"
                type="password"
                autoComplete="password"
                label={repeatPasswordLabel}
                onChange={handleRepeatPassword}
                value={repeatPassword}
                disabled={passwordPending ? true : undefined}
                // error={passwordUnmatched}
                helperText={repeatPasswordError}
            />
          </Box>
        </SplashText>
      </Screen>
      <ActionFailedAlert
          long
          message="splash.set_password.error"
          open={passwordFailed}
      />
    </>
  );
};

AdminSetPasswordScreen.propTypes = AdminSetPasswordScreenPropTypes;

export default memo(AdminSetPasswordScreen);
