import { gql } from '@apollo/client';

export const HIERARCHY_QUERY = gql`
  query Hierarchy {
    readHierarchy @client {
      id
      title
      level
      org_id
      # calculated fields
      parent_idx
      direct_reports_idx
      # only first Manager[] array element has these properties
      siblings_idx
      topId
      topOrgId
      topName
      managers {
        code
        full_name
        level
        org_id
      }
    }
  }
`;
