import { forwardRef, memo, useCallback, type ChangeEvent } from 'react';
import PropTypes /* , { type Validator } */ from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch /* , { type SwitchProps } */ from '@mui/material/Switch';

const opacitySx = { opacity: 0.618 };

type OnOffSwitchProps = {
  label?: string;
  italic?: boolean;
  disabled?: boolean;
  value: boolean;
  onChange: (value: boolean) => void;
};

const OnOffSwitchPropTypes = {
  label: PropTypes.string,
  italic: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

const OnOffSwitch = forwardRef<HTMLButtonElement, OnOffSwitchProps>(({
  label,
  italic = false,
  disabled = false,
  value,
  onChange
}, ref) => {

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange(Boolean(event.target.checked));
  }, [onChange]);

  return label ? (
    <FormControlLabel
        ref={ref}
        label={(
          <Box
              pl={1}
              fontStyle={italic ? 'italic' : undefined}
              color={italic ? 'info.caption' : undefined}
              sx={italic && disabled ? opacitySx : undefined}
          >
            <FormattedMessage id={label} defaultMessage={label}/>
          </Box>
        )}
        disabled={disabled}
        control={(
          <Switch
              // color={color}
              color="default"
              checked={value}
              onChange={handleChange}
          />
        )}
    />
  ) : (
    <Switch
        ref={ref}
        // color={color}
        color="secondary"
        checked={value}
        disabled={disabled}
        onChange={handleChange}
    />
  );
});

OnOffSwitch.displayName = 'OnOffSwitch';

OnOffSwitch.propTypes = OnOffSwitchPropTypes;

export default memo(OnOffSwitch);
