import { memo, useCallback, useContext, useLayoutEffect, useMemo, useState, type FunctionComponent } from 'react';
import PropTypes, { Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import { useLazyQuery, useMutation, type ApolloCache } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
// EmPath UI Components
import { ExportFormat } from '@empathco/ui-components/src/models/exportFormat';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import { mapChunks } from '@empathco/ui-components/src/helpers/intl';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import useMutationMethod from '@empathco/ui-components/src/hooks/useMutationMethod';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
import StandardLink from '@empathco/ui-components/src/elements/StandardLink';
import TagLabel from '@empathco/ui-components/src/elements/TagLabel';
// local imports
import { UPDATE_DEV_PLAN } from '../graphql/UpdateDevPlan';
import { DELETE_DEV_PLAN } from '../graphql/DeleteDevPlan';
import { DEV_PLAN_COURSES_QUERY } from '../graphql/DevPlanCourses';
import { DEV_PLAN_ADVISORS_QUERY } from '../graphql/DevPlanAdvisors';
import { DEV_PLAN_OPPORTUNITIES_QUERY } from '../graphql/DevPlanOpportunities';
import { DEV_PLAN_ACTIVITIES_QUERY } from '../graphql/DevPlanActivities';
import { DEV_PLAN_PROGRESSES_QUERY } from '../graphql/DevPlanProgresses';
import {
  DevPlanCourseType, DevPlanAdvisorType, DevPlanOpportunityType, Opportunity, CohortSkillStatus, SkillActivity,
  DevPlanCoursesDocument, DevPlanAdvisorsDocument, DevPlanOpportunitiesDocument, DevPlanActivitiesDocument,
  DevPlanProgressesDocument,
  UpdateDevPlanDocument, DeleteDevPlanDocument
} from '../graphql/types';
import {
  DevPlanAdvisor, DevPlanCourse, DevPlanDetails, DevPlanOpportunity, DevPlanProgress, DevPlanTarget
} from '../graphql/customTypes';
import { SkillLevel, SKILL_LEVEL_REGULAR } from '../models/skill';
import {
  PATH_JOB, PATH_MY_OPPORTUNITY, PATH_MY_SKILLS,
  PATH_HR_COHORT, PATH_HR_DEV_PLANS, PATH_HR_DEV_PLAN_EDITOR, PATH_MY_DEV_PLAN_EDITOR, PATH_SKILL
} from '../config/paths';
import useCustomerSettings from '../config/customer';
import useExportDevPlan from '../hooks/useExportDevPlan';
import useExportDevPlanProgress from '../hooks/useExportDevPlanProgress';
import { getCorortSkillsHasLevels, getTargetSkills } from '../helpers/graphql';
import { GlobalContext } from '../context/global';
import DevPlanAvatar from '../elements/DevPlanAvatar';
import SkillChip from '../elements/SkillChip';
import DevPlanTimestampStatus from '../elements/DevPlanTimestampStatus';
import ReviewBar from '../elements/ReviewBar';
import CoursesAndAdvisorsGrid from '../v3/CoursesAndAdvisorsGrid';
import EmployeeProgressPopup from '../v3/EmployeeProgressPopup';
import DevPlanEmployeesTable from '../v3/DevPlanEmployeesTable';
// SCSS imports
import { details } from './DevPlanReview.module.scss';

const updateCache = (cache: ApolloCache<unknown>, _isMyPlan: boolean, _onDelete: boolean) => {
  // if (isMyPlan) {
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeDevplans' });
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeDevplan' });
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeProgress' });
  // }
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'devplans' });
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'devplan' });
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'cohort' });
  // if we add dev_plan info to the cohort cards:
  // if (onDelete) cache.evict({ id: 'ROOT_QUERY', fieldName: 'cohorts' });
};

type DevPlanReviewProps = {
  devplan?: DevPlanDetails | null;
  pending?: boolean | null;
  failed?: boolean | null;
  isMyPlan?: boolean;
}

const DevPlanReviewPropTypes = {
  devplan: PropTypes.object as Validator<DevPlanDetails>,
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  isMyPlan: PropTypes.bool
};

// eslint-disable-next-line complexity, max-statements, max-lines-per-function
const DevPlanReview: FunctionComponent<DevPlanReviewProps> = ({
  devplan,
  pending: parentPending = false,
  failed: parentFailed = false,
  isMyPlan = false
}) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { HAS_COURSES, HAS_MENTORING, HAS_DEV_PLAN_OPPORTUNITIES } = useCustomerSettings();
  const { exportDevPlan, DEV_PLAN_CSV_COLUMNS } = useExportDevPlan();
  const { exportDevPlanProgress, DEV_PLAN_PROGRESS_CSV_COLUMNS } = useExportDevPlanProgress();

  const {
    id: devplan_id, title: devplanTitle, created_at, updated_at, job, opportunity, cohort, employee,
    target_skills
  } = devplan || {} as DevPlanDetails;
  const targetCode = job?.code || (isMyPlan ? (opportunity as Opportunity)?.opportunity_match_id : opportunity?.id);
  const targetTitle = job?.title || opportunity?.title;
  const { id: cohort_id, title: cohortTitle, cohort_skills } = cohort || {};

  const hasLevels = useMemo(() => getCorortSkillsHasLevels(cohort_skills), [cohort_skills]);
  const targetSkills = useMemo(() => getTargetSkills(target_skills), [target_skills]);

  const { user: { data: user }, paths: { supvEmplPath } } = useContext(GlobalContext);
  const targetPath = (job && PATH_JOB) ||
    (isMyPlan && opportunity && PATH_MY_OPPORTUNITY) || // TODO: HRBP path for opportunity?
    undefined;

  // lazy load dev plan courses
  const {
    query: getCourses, pending: pendingCourses, failed: failedCourses, results: courses
  } = useQueryCounted({
    data: undefined as unknown as DevPlanCourse,
    key: 'devplanCourses',
    lazyQuery: useLazyQuery(DEV_PLAN_COURSES_QUERY as typeof DevPlanCoursesDocument)
  });
  // lazy load dev plan advisors
  const {
    query: getAdvisors, pending: pendingAdvisors, failed: failedAdvisors, results: advisors
  } = useQueryCounted({
    data: undefined as unknown as DevPlanAdvisor,
    key: 'devplanAdvisors',
    lazyQuery: useLazyQuery(DEV_PLAN_ADVISORS_QUERY as typeof DevPlanAdvisorsDocument)
  });
  // lazy load dev plan opportunities
  const {
    query: getOpportunities, pending: pendingOpportunities, failed: failedOpportunities, results: opportunities
  } = useQueryCounted({
    data: undefined as unknown as DevPlanOpportunity,
    key: 'devplanOpportunities',
    lazyQuery: useLazyQuery(DEV_PLAN_OPPORTUNITIES_QUERY as typeof DevPlanOpportunitiesDocument)
  });
  // lazy load dev plan activities
  const {
    query: getActivities, pending: pendingActivities, failed: failedActivities, results: activities
  } = useQueryCounted({
    data: undefined as unknown as SkillActivity,
    key: 'devplanActivities',
    lazyQuery: useLazyQuery(DEV_PLAN_ACTIVITIES_QUERY as typeof DevPlanActivitiesDocument)
  });
  // lazy load dev plan employees progress
  const { query: getEmployees, pending: pendingEmployees, failed: failedEmployees, results: employees } = useQueryCounted({
    data: undefined as unknown as DevPlanProgress,
    key: 'devplanProgresses',
    lazyQuery: useLazyQuery(DEV_PLAN_PROGRESSES_QUERY as typeof DevPlanProgressesDocument)
  });

  const { mutate: updateDevPlan, loading: updatePending, failed: updateFailed } = useMutationMethod({
    mutation: useMutation(UPDATE_DEV_PLAN as typeof UpdateDevPlanDocument)
  });

  const { mutate: deleteDevPlan, loading: deletePending, failed: deleteFailed } = useMutationMethod({
    mutation: useMutation(DELETE_DEV_PLAN as typeof DeleteDevPlanDocument)
  });

  useLayoutEffect(() => {
    if (devplan_id) {
      getActivities?.({ variables: { devplan_id } });
      getEmployees?.({ variables: { devplan_id } });
      if (HAS_COURSES) getCourses?.({ variables: {
        devplan_id, type: DevPlanCourseType.courses, exclude_ids: '', target_skill_ids: ''
      } });
      if (HAS_MENTORING) getAdvisors?.({ variables: {
        devplan_id, type: DevPlanAdvisorType.advisors, exclude_ids: '', target_skill_ids: ''
      } });
      if (HAS_DEV_PLAN_OPPORTUNITIES) getOpportunities?.({ variables: {
        devplan_id, type: DevPlanOpportunityType.opportunities, exclude_ids: '', target_skill_ids: ''
      } });
    }
  }, [
    devplan_id, getActivities, getEmployees, getCourses, getAdvisors, getOpportunities,
    HAS_COURSES, HAS_MENTORING, HAS_DEV_PLAN_OPPORTUNITIES
  ]);

  const updateTitle = useCallback(
    (newTitle?: string) => updateDevPlan({
      variables: { devplan_id, input: { title: newTitle ? newTitle : '' } },
      update: (cache) => updateCache(cache, isMyPlan, false)
    }), [devplan_id, isMyPlan, updateDevPlan]
  );

  const deleteThisDevPlan = useCallback(
    () => deleteDevPlan?.({
      variables: { devplan_id },
      update: (cache) => updateCache(cache, isMyPlan, true),
      onCompleted: () => navigate(isMyPlan ? PATH_MY_SKILLS : PATH_HR_DEV_PLANS)
    }),
    [devplan_id, isMyPlan, deleteDevPlan, navigate]
  );

  const link = useMemo(() => injectParams(isMyPlan ? PATH_MY_DEV_PLAN_EDITOR : PATH_HR_DEV_PLAN_EDITOR, {
    plan_id: devplan_id
  }), [devplan_id, isMyPlan]);

  const getExport = useCallback((format: ExportFormat, title: string) => {
    if (
      !devplan || !title || !targetSkills ||
      (HAS_COURSES && !courses) || (HAS_MENTORING && !advisors) ||
      ((!HAS_COURSES || size(courses) < 1) && (!HAS_MENTORING || size(advisors) < 1))
    ) return null;
    return exportDevPlan(
      format,
      title,
      map(DEV_PLAN_CSV_COLUMNS, (id) => formatMessage({ id })),
      targetSkills,
      (HAS_COURSES && courses) || [],
      (HAS_MENTORING && advisors) || [],
      (HAS_DEV_PLAN_OPPORTUNITIES && opportunities) || [],
      size(activities) >= 1 ? activities : null
    );
  }, [
    devplan, targetSkills, courses, advisors, opportunities, activities,
    HAS_COURSES, HAS_MENTORING, HAS_DEV_PLAN_OPPORTUNITIES, formatMessage,
    DEV_PLAN_CSV_COLUMNS, exportDevPlan
  ]);

  const getEmployeesExport = useCallback((format: ExportFormat, _title: string) => {
    if ((HAS_COURSES && !courses) || (HAS_MENTORING && !advisors) || !targetSkills || !employees || !devplanTitle) return null;
    return exportDevPlanProgress(
      format,
      devplanTitle,
      map(DEV_PLAN_PROGRESS_CSV_COLUMNS, (id) => formatMessage({ id })),
      targetSkills,
      (HAS_COURSES && courses) || [],
      (HAS_MENTORING && advisors) || [],
      (HAS_DEV_PLAN_OPPORTUNITIES && opportunities) || [],
      size(activities) >= 1 ? activities : null,
      employees
    );
  }, [
    employees, courses, advisors, opportunities, activities, targetSkills, devplanTitle,
    HAS_COURSES, HAS_MENTORING, HAS_DEV_PLAN_OPPORTUNITIES, formatMessage,
    DEV_PLAN_PROGRESS_CSV_COLUMNS, exportDevPlanProgress
  ]);

  const handleSkillClick = useCallback((id: number, abbr: string) => {
    if (id && abbr) navigate(injectParams(PATH_SKILL, { skill_id: abbr }));
  }, [navigate]);

  const [empl, setEmpl] = useState<DevPlanProgress>();
  const [popupOpen, setPopupOpen] = useState(false);
  const handlePopupClose = useCallback(() => setPopupOpen(false), []);
  const handleEmployeeClick = useCallback((emp: DevPlanProgress) => {
    setEmpl(emp);
    setPopupOpen(true);
  }, []);

  const target: DevPlanTarget | null = (job && 'job') || (opportunity && 'opportunity') || null;
  const pending = parentPending || pendingActivities;
  const failed = parentFailed || failedActivities;
  const disabled = pending || updatePending || deletePending ? true : undefined;

  return (
    <>
      {failed || pending ? (
        <CardTitle title={failed ? 'hr.dev_plan.title' : <Skeleton variant="text" width="12rem"/>}/>
      ) : (
        <ReviewBar
            titleLabel="hr.dev_plan.title.label"
            title={devplanTitle}
            link={link}
            avatar={isMyPlan ? undefined : <DevPlanAvatar/>}
            status={(
              <DevPlanTimestampStatus
                  compact={isMyPlan}
                  tags={isMyPlan && devplan && employee && !pendingEmployees && employees ? (
                    <TagLabel variant={employees?.[0]?.is_devplan_active ? 'active' : 'inactive'} small/>
                  ) : undefined}
                  createdAt={created_at}
                  updatedAt={updated_at}
                  myOwn={isMyPlan ? user?.id === devplan?.owner?.id : undefined}
              />
            )}
            // pending // handled above
            // disabled // handled above
            editLabel="hr.dev_plan.edit"
            onRename={updateTitle}
            renameLabel="hr.dev_plan.rename"
            renamePending={updatePending}
            renameFailed={updateFailed}
            renameError="hr.dev_plan.rename_error"
            onDelete={deleteThisDevPlan}
            deleteLabel="hr.dev_plan.delete"
            deleteTitle="hr.dev_plan.delete_title"
            deleteQuestion="hr.dev_plan.delete_question"
            deletePending={deletePending}
            deleteFailed={deleteFailed}
            deleteError="hr.dev_plan.delete_error"
            onExport={getExport}
            exportDisabled={!devplan || !devplanTitle || !targetSkills ||
              (HAS_COURSES && !courses) || (HAS_MENTORING && !advisors) || (size(courses) < 1 && size(advisors) < 1)}
        />
      )}
      {(failed && <FetchFailedAlert flat/>) || (pending && <LoadingPlaceholder flat/>) || (
        <>
          <Divider light/>
          <CoursesAndAdvisorsGrid
              isMyPlan={isMyPlan}
              courses={HAS_COURSES ? courses : []}
              advisors={HAS_MENTORING ? advisors : []}
              opportunities={HAS_DEV_PLAN_OPPORTUNITIES ? opportunities : []}
              activities={activities}
              pendingCourses={HAS_COURSES ? pendingCourses : false}
              pendingAdvisors={HAS_MENTORING ? pendingAdvisors : false}
              pendingOpportunities={HAS_DEV_PLAN_OPPORTUNITIES ? pendingOpportunities : false}
              failedCourses={HAS_COURSES ? failedCourses : false}
              failedAdvisors={HAS_MENTORING ? failedAdvisors : false}
              failedOpportunities={HAS_DEV_PLAN_OPPORTUNITIES ? failedOpportunities : false}
              disabled={disabled}
              notFoundMessage="hr.dev_plan.empty"
              isSelected
          />
          <CardSection
              bottom={HAS_MENTORING || HAS_DEV_PLAN_OPPORTUNITIES
                ? (!HAS_MENTORING || (!pendingAdvisors && !failedAdvisors)) &&
                  (!HAS_DEV_PLAN_OPPORTUNITIES || (!pendingOpportunities && !failedOpportunities))
                : !pendingCourses && !failedCourses}
          >
            <Box className={details}>
              <FormattedMessage
                  id="hr.dev_plan.target"
                  values={{
                    target,
                    title: targetPath && targetCode ? (
                      <StandardLink
                          text={targetTitle}
                          to={disabled ? undefined : injectParams(targetPath, { role_id: targetCode, opp_id: targetCode })}
                          variant="inherit"
                      />
                    ) : targetTitle
                  }}
              />
            </Box>
            {cohort ? (
              <>
                <Box
                    display="flex"
                    flexWrap="wrap"
                    alignItems="center"
                    justifyContent="flex-start"
                    pt={0.25}
                    pb={1}
                >
                  <Box pr={2} className={details}>
                    <FormattedMessage id="hr.dev_plan.skills_criteria"/>
                  </Box>
                  {map(cohort_skills, ({ skill, skill_proficiency_level, status }) => status === CohortSkillStatus.required ? (
                    <SkillChip
                        key={skill.id}
                        plain
                        skill={skill}
                        level={hasLevels ? skill_proficiency_level as SkillLevel || SKILL_LEVEL_REGULAR : undefined}
                        disabled={disabled ? true : undefined}
                        onActivate={handleSkillClick}
                    />
                  ) : undefined)}
                </Box>
                <Box className={details}>
                  <FormattedMessage
                      id="hr.dev_plan.cohort"
                      values={{
                        bold: mapChunks,
                        title: cohort_id ? (
                          <StandardLink
                              text={cohortTitle}
                              to={disabled ? undefined : injectParams(PATH_HR_COHORT, { cohort_id })}
                              variant="inherit"
                          />
                        ) : cohortTitle
                      }}
                  />
                </Box>
              </>
            ) : undefined}
          </CardSection>
          <Divider light/>
          {cohort ? (
            <DevPlanEmployeesTable
                target={target}
                hasActivities={size(activities) >= 1}
                cohortTitle={cohortTitle}
                // route={supvEmplPath}
                employees={employees}
                pending={pendingEmployees}
                failed={failedEmployees}
                onClick={handleEmployeeClick}
                disabled={disabled}
                onExport={getEmployeesExport}
                exportDisabled={(HAS_COURSES && !courses) || (HAS_MENTORING && !advisors) ||
                  !targetSkills || !employees || !devplanTitle}
            />
          ) : undefined}
          {devplan && (employee || empl) ? (
            <EmployeeProgressPopup
                isMyPlan={isMyPlan}
                route={supvEmplPath}
                devplan={devplan}
                activities={activities}
                employee={employee ? employees?.[0] : empl}
                pending={pendingEmployees}
                failed={failedEmployees}
                isOpen={popupOpen}
                onClose={employee ? undefined : handlePopupClose}
                onExport={getEmployeesExport}
                exportDisabled={(HAS_COURSES && !courses) || (HAS_MENTORING && !advisors) ||
                  !targetSkills || !employees || !devplanTitle}
            />
          ) : undefined}
        </>
      )}
    </>
  );
};

DevPlanReview.propTypes = DevPlanReviewPropTypes;

export default memo(DevPlanReview);
