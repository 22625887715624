import { forwardRef } from 'react';
import { type BoxProps } from '@mui/system/Box';
import Box from '@mui/material/Box';
import Grid, { type GridProps } from '@mui/material/Grid';

const GridBox = forwardRef<HTMLElement,
  Omit<BoxProps, 'classes' | 'className' | 'component' | 'alignContent' | 'alignItems' | 'justifyContent'> &
  Omit<GridProps, 'component'>
>((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Grid ref={ref} component={Box} {...props}/>
));

GridBox.displayName = 'GridBox';

export default GridBox;
