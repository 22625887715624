import { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// Material UI imports
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
// SCSS imports
import { skeletonPaper, skeleton, heightNormal, height100 } from './LoadingPlaceholder.module.scss';

type LoadingPlaceholderProps = {
  flat?: boolean;
  fullHeight?: boolean;
  className?: string;
};

const LoadingPlaceholderPropTypes = {
  // attributes
  flat: PropTypes.bool,
  fullHeight: PropTypes.bool,
  className: PropTypes.string
};

const LoadingPlaceholder = forwardRef<HTMLDivElement, LoadingPlaceholderProps>(({
  flat = false,
  fullHeight = false,
  className
}, ref) => (
  <Paper
      ref={ref}
      elevation={flat ? 0 : undefined}
      className={clsx(skeletonPaper, {
        [height100]: fullHeight
      })}
  >
    <Skeleton
        variant="rectangular"
        className={clsx(skeleton, className, {
          [heightNormal]: !className && !fullHeight,
          [height100]: fullHeight
        })}
    />
  </Paper>
));

LoadingPlaceholder.displayName = 'LoadingPlaceholder';

LoadingPlaceholder.propTypes = LoadingPlaceholderPropTypes;

export default memo(LoadingPlaceholder);
