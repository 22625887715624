import { memo, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import toSafeInteger from 'lodash/toSafeInteger';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
// local imports
import { SkillLevel, SKILL_LEVEL_FIRST, SKILL_LEVEL_TO_MENTOR } from '../models/skillLevel';
import { bold } from '../helpers/intl';
import BoxTypography from '../mixins/BoxTypography';
import GridBox from '../mixins/GridBox';
import LoadingPlaceholder from './LoadingPlaceholder';
import FetchFailedAlert from './FetchFailedAlert';
import YesNoButtonGroup from './YesNoButtonGroup';
import LevelSelector from './LevelSelector';
import LevelDescription from './LevelDescription';

type SkillLevelDialogContentProps = {
  currentLevel?: SkillLevel | null;
  inferredLevel?: SkillLevel | null;
  description?: string | null;
  levelDescription?: string[] | null;
  disabled?: boolean | null;
  failed?: boolean | null;
  pending?: boolean | null;
  loading?: boolean | null;
  depersonalized?: boolean;
  canMentor?: boolean | null;
  noTargeting?: boolean | null;
  readOnly?: boolean;
  level: SkillLevel;
  mentoring?: boolean;
  isTarget?: boolean;
  setLevel: (level: SkillLevel) => void;
  setMentoring?: (value: boolean) => void;
  setIsTarget?: (value: boolean) => void;
}

const SkillLevelDialogContentPropTypes = {
  // attributes
  currentLevel: PropTypes.number as Validator<SkillLevel>,
  inferredLevel: PropTypes.number as Validator<SkillLevel>,
  description: PropTypes.string,
  levelDescription: PropTypes.arrayOf(PropTypes.string.isRequired),
  disabled: PropTypes.bool,
  failed: PropTypes.bool,
  pending: PropTypes.bool,
  loading: PropTypes.bool,
  depersonalized: PropTypes.bool,
  canMentor: PropTypes.bool,
  noTargeting: PropTypes.bool,
  readOnly: PropTypes.bool,
  level: PropTypes.number.isRequired as Validator<SkillLevel>,
  mentoring: PropTypes.bool,
  isTarget: PropTypes.bool,
  setLevel: PropTypes.func.isRequired,
  setMentoring: PropTypes.func,
  setIsTarget: PropTypes.func
};

// eslint-disable-next-line complexity
const SkillLevelDialogContent: FunctionComponent<SkillLevelDialogContentProps> = ({
  currentLevel,
  inferredLevel,
  description,
  levelDescription,
  disabled = false,
  failed = false,
  pending = false,
  loading = false,
  depersonalized = false,
  canMentor = false,
  noTargeting = false,
  readOnly = false,
  level,
  setLevel,
  mentoring = false,
  setMentoring,
  isTarget = false,
  setIsTarget
}) => {
  const hasInference = !isNil(inferredLevel) && inferredLevel >= SKILL_LEVEL_FIRST;
  const hasMentoring = !depersonalized && !readOnly && setMentoring && (loading || canMentor) && level >= SKILL_LEVEL_TO_MENTOR;
  const hasTargeting = !depersonalized && !readOnly && setIsTarget && !noTargeting;

  return (failed && <FetchFailedAlert/>) || (pending && <LoadingPlaceholder/>) || (
    <>
      <Typography variant="h5" paragraph>
        <FormattedMessage id="skill.description"/>
      </Typography>
      <Typography variant="body1" paragraph>
        {description}
      </Typography>
      {hasInference ? (
        <Typography variant="body1" paragraph>
          <FormattedMessage
              id="skill.inferred_level"
              values={{
                bold,
                level: <FormattedMessage id="common.skill_level.description" values={{ level: toSafeInteger(inferredLevel) }}/>
              }}
          />
        </Typography>
      ) : undefined}
      {!isNil(currentLevel) && (
        <Typography variant="body1" paragraph>
          <FormattedMessage
              id="skill.current_level"
              values={{
                bold,
                level: <FormattedMessage id="common.skill_level.description" values={{ level: toSafeInteger(currentLevel) }}/>
              }}
          />
        </Typography>
      )}
      {readOnly ? undefined : (
        <BoxTypography py={1} variant="body1">
          <FormattedMessage
              id="skill.level_info"
              values={{ inferred: hasInference }}
          />
        </BoxTypography>
      )}
      <GridBox container alignItems="center" pb={2} spacing={1}>
        <Grid item xs={12} md={6} lg={4} container alignItems="center">
          <LevelSelector
              value={level}
              onChange={setLevel as (level: number) => void}
              disabled={disabled ? true : undefined}
              nonZero={depersonalized}
          />
        </Grid>
        {hasMentoring ? (
          <Grid item xs={12} md={6} lg={4} container alignItems="center" justifyContent="flex-end" wrap="wrap">
            {(loading && <CircularProgress size="1.5rem"/>) ||
              <>
                <BoxTypography pr={2} variant="body1">
                  <FormattedMessage id="skill.opt_in_mentor"/>
                </BoxTypography>
                <YesNoButtonGroup
                    small
                    ariaLabel="skill.opt_in_mentor"
                    value={mentoring}
                    onChange={setMentoring}
                    disabled={disabled}
                />
              </>}
          </Grid>
        ) : undefined}
        {hasTargeting ? (
          <Grid item xs={12} lg={4} container alignItems="center" justifyContent="flex-end" wrap="wrap">
            <BoxTypography pr={2} variant="body1">
              <FormattedMessage id="skill.target_this"/>
            </BoxTypography>
            <YesNoButtonGroup
                small
                ariaLabel="skill.target_this"
                value={isTarget}
                onChange={setIsTarget}
                disabled={disabled}
            />
          </Grid>
        ) : undefined}
      </GridBox>
      {isArray(levelDescription) && isString(levelDescription[level]) ? (
        <LevelDescription description={levelDescription[level]}/>
      ) : undefined}
    </>
  );
};

SkillLevelDialogContent.propTypes = SkillLevelDialogContentPropTypes;

export default memo(SkillLevelDialogContent);
