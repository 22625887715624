import { gql } from '@apollo/client';
// local imports
import { API_EMPLOYEE_SKILLS_BROWSING } from '../config/api';

export const EMPLOYEE_SKILLS_QUERY = gql`
  query EmployeeSkills(
    $search: String
    $inferences_only: Boolean
    $skill_category_id: Int
    $selected_employee_id: String
    $sort_by: EmployeeSkillsSort
    $direction: SortDirection
    $offset: Int
    $limit: Int
  ) {
    employeeSkills(
      search: $search,
      inferences_only: $inferences_only,
      skill_category_id: $skill_category_id,
      selected_employee_id: $selected_employee_id,
      sort_by: $sort_by,
      direction: $direction,
      offset: $offset,
      limit: $limit
    )
    @rest(
      type: "EmployeeSkillsPayload",
      path: "${API_EMPLOYEE_SKILLS_BROWSING}?{args}"
    ) {
      count
      results {
        id
        title
        abbr
        current_level
        inferred_level
        is_inference_newer
        type
        # ple_link
        is_inference
        is_target
        is_in_demand
        is_new
        is_my
        is_mentoring
      }
    }
  }
`;
