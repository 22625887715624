export const EMAIL_NOTIFICATIONS = [
  'new_inferred_skills' as const,
  'new_job_matches' as const,
  'new_suggested_skills' as const,
  'new_open_reqs_for_target_job' as const
] as const;
export type EmailNotificationType = typeof EMAIL_NOTIFICATIONS[number];

export const EMAIL_NOTIFICATIONS_REDUCED_UI = [
  'new_job_matches' as const,
  'new_open_reqs_for_target_job' as const
] as const;
export type EmailNotificationReducedType = typeof EMAIL_NOTIFICATIONS_REDUCED_UI[number];
