import { gql } from '@apollo/client';
// local imports
import { API_SUPV_OPPORTUNITY } from '../config/api';

export const UPDATE_OPPORTUNITY_MATCH = gql`
  mutation UpdateOpportunityMatch(
    $opportunity_id: Int!
    $match_id: Int!
    $input: UpdateOpportunityMatchInput!
  ) {
    updateOpportunityMatch(
      opportunity_id: $opportunity_id,
      match_id: $match_id
      input: $input
    )
    @rest(
      type: "MutationResponse",
      path: "${API_SUPV_OPPORTUNITY}matches/{args.match_id}/",
      method: "PATCH"
    ) {
      success
    }
  }
`;
