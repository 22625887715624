import { gql } from '@apollo/client';
// local imports
import { API_DA_HIRING_TRENDS } from '../config/api';

export const DA_HIRING_TRENDS_QUERY = gql`
  query DAHiringTrends(
    $input: DAHiringTrendsInput!
    $pathBuilder: String!
  ) {
    daHiringTrends(
      input: $input
    )
    @rest(
      type: "DAHiringTrendsPayload",
      path: "${API_DA_HIRING_TRENDS}",
      pathBuilder: $pathBuilder
    ) {
      total_employees
      jobs {
        id
        code
        title
        total_open_reqs
        days_to_fill
        external_hires
        internal_hires
      }
    }
  }
`;
