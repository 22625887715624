import PropTypes from 'prop-types';

const SKILL_UPDATE_SOURCE = [
  'profile_builder', // Profile Builder
  'skill', // Skill Details screen
  'job_skills', // Job Related Skills on Job Details screen
  // Skills screen tabs/cards in Employee UI:
  'my_skills', // My Skills card
  'in_demand', // In-Demand Skills tab
  'recommended', // Recommended Skills tab
  'learning', // Learning Progress Skills tab
  'mentoring', // Skill Advisor Skills tab
  'target' // Target Skills tab
] as const;

export type SkillUpdateSource = typeof SKILL_UPDATE_SOURCE[number];

export const SkillUpdateSourceProp = PropTypes.oneOf(SKILL_UPDATE_SOURCE);
