import { forwardRef, memo, useCallback, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
// Material UI imports
import { makeStyles } from 'tss-react/mui';
import { type Theme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
// EmPath UI Components
import { type Values } from '@empathco/ui-components/src/helpers/intl';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    color: theme.palette.secondary.text,
    fontWeight: theme.typography.subtitle1.fontWeight,
    borderRadius: theme.shape.borderRadius
  }
}));

type CategoryMenuItemProps = {
  id: number;
  title?: string;
  values?: Values;
  icon?: ReactNode;
  selected: boolean;
  onClick: (value: number) => void;
};

const CategoryMenuItemPropTypes = {
  // attributes
  id: PropTypes.number.isRequired,
  title: PropTypes.string,
  values: PropTypes.object as Validator<Values>,
  icon: PropTypes.node,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

const CategoryMenuItem = forwardRef<HTMLLIElement, CategoryMenuItemProps>(({
  id,
  title,
  values,
  icon,
  selected,
  onClick
}, ref) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();
  const { classes } = useStyles();
  const handleClick = useCallback(() => onClick(id), [id, onClick]);
  return (
    <MenuItem
        ref={ref}
        // data-value={id}
        // aria-selected={selected} // TODO: does not work, so using hack with `aria-label` and 'browser.selected'
        aria-label={formatMessage({ id: 'browser.selected' }, { title: title || null, selected})}
        aria-live="assertive"
        selected={selected}
        onClick={handleClick}
        classes={classes}
    >
      <FormattedMessage id={title || 'browser.all'} values={values} defaultMessage={title}/>
      {icon ? (
        <>
          {'\u00A0'}
          {icon}
        </>
      ) : undefined}
    </MenuItem>
  );
});

CategoryMenuItem.displayName = 'CategoryMenuItem';

CategoryMenuItem.propTypes = CategoryMenuItemPropTypes;

export default memo(CategoryMenuItem);
