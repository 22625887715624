import { Fragment, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import isArray from 'lodash/isArray';
import type { FormatXMLElementFn } from 'intl-messageformat';
import type { PrimitiveType } from 'react-intl';
// Material UI imports
// import Typography from '@mui/material/Typography';

export type FormatMessageFuncValues =
  Record<string, PrimitiveType | FormatXMLElementFn<string, string>> |
  Record<string, PrimitiveType | FormatXMLElementFn<string>>;
export type Values = Record<string, ReactNode | PrimitiveType | FormatXMLElementFn<ReactNode, ReactNode>>;
export const ValuesPropType = PropTypes.object as Validator<Values>;

export const mapChunks = (chunks?: ReactNode | ReactNode[] | null): ReactNode | ReactNode[] => isArray(chunks)
  ? map(chunks, (chunk, index) => (
    <Fragment key={index}>
      {chunk}
    </Fragment>
    ))
  : chunks;

export const bold = (chunks?: ReactNode | ReactNode[] | null): ReactNode => (
  <strong>
    {mapChunks(chunks)}
  </strong>
);

export const italic = (chunks?: ReactNode | ReactNode[] | null): ReactNode => (
  <em>
    {mapChunks(chunks)}
  </em>
);

// export const subtitle2 = (chunks?: ReactNode | ReactNode[] | null): ReactNode => (
//   <Typography variant="subtitle2" component="span">
//     {mapChunks(chunks)}
//   </Typography>
// );

export const commonFormattingValues = {
  br: <br/>,
  bold,
  italic
  // subtitle2
};
