import { memo, useContext, useMemo, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import size from 'lodash/size';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Typography from '@mui/material/Typography';
// EmPath UI Components
import { type GetComponentProps } from '@empathco/ui-components/src/helpers/types';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
// local imports
import { Leader } from '../graphql/types';
import { SupervisorContext } from '../context/supervisor';
import EmployeeCard from '../v3/EmployeeCard';
import CardsGrid from '../v3/CardsGrid';
// SCSS imports
import { header, subheader } from './DelegatesList.module.scss';

type DelegatesListProps = {
  hrbp?: boolean;
  disabled?: boolean;
  leader?: Leader | null;
}

const DelegatesListPropTypes = {
  // attributes
  hrbp: PropTypes.bool,
  disabled: PropTypes.bool,
  leader: PropTypes.object as Validator<Leader>
};

const DelegatesList: FunctionComponent<DelegatesListProps> = ({
  hrbp = false,
  disabled: parentDisabled = false,
  leader
}) => {
  const {
    delegatesList: { data: delegates, pending, failed },
    delegateAdd: { pending: addDelegatePending },
    delegateRemove: { pending: removeDelegatePending, failed: removeDelegateFailed, params }, removeDelegate
  } = useContext(SupervisorContext);

  const { id: from_id, title } = leader || {};

  const disabled = !from_id || parentDisabled || addDelegatePending || removeDelegatePending ? true : undefined;

  const componentProps: Partial<GetComponentProps<typeof EmployeeCard>> = useMemo(() => ({
    actionLabel: 'delegates.button.remove',
    onAction: (to_id: number) => {
      if (from_id && to_id) removeDelegate?.({ from_id, to_id });
    }
  }), [from_id, removeDelegate]);

  return (
    <>
      {!pending && !failed && size(delegates) >= 1 ? (
        <CardSection top>
          <Typography className={header}>
            <FormattedMessage id="delegation.current_delegates"/>
          </Typography>
          {hrbp && title ? (
            <Typography className={subheader}>
              {title}
            </Typography>
          ) : undefined}
        </CardSection>
      ) : undefined}
      {leader ? (
        <CardsGrid
            variant="white"
            notFoundMessage={hrbp ? 'employees.hrbp.no_delegates' : 'employees.leader.no_delegates'}
            items={delegates}
            pending={pending}
            failed={failed}
            disabled={disabled}
            removingId={params?.to_id}
            component={EmployeeCard}
            ComponentProps={componentProps}
        />
      ) : undefined}
      <ActionFailedAlert
          message="delegation.remove_delegate_error"
          open={removeDelegateFailed}
      />
    </>
  );
};

DelegatesList.propTypes = DelegatesListPropTypes;

export default memo(DelegatesList);
