import { forwardRef, memo, useContext, useCallback } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import isNil from 'lodash/isNil';
import endsWith from 'lodash/endsWith';
import { useIntl } from 'react-intl';
// EmPath UI Components
import { type ExportFormat } from '@empathco/ui-components/src/models/exportFormat';
import { MIN_PAGE_SIZE } from '@empathco/ui-components/src/config/params';
import { getFullName } from '@empathco/ui-components/src/helpers/strings';
import ExportButton from '@empathco/ui-components/src/elements/ExportButton';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
// local imports
import { EmployeeBrief } from '../models/employee';
import useExportEmployeeProfile from '../hooks/useExportEmployeeProfile';
import { DataContext } from '../context';
import useExport from '../hooks/useExport';

type ExportSkillsButtonProps = {
  supervisor?: boolean | null;
  employee?: EmployeeBrief | null;
  disabled?: boolean;
  testPending?: boolean;
  testFailed?: boolean;
};

const ExportSkillsButtonPropTypes = {
  // attributes
  supervisor: PropTypes.bool,
  employee: PropTypes.object as Validator<EmployeeBrief>,
  disabled: PropTypes.bool,
  testPending: PropTypes.bool,
  testFailed: PropTypes.bool
};

const ExportSkillsButton = forwardRef<HTMLDivElement, ExportSkillsButtonProps>(({
  supervisor = false,
  employee,
  disabled = false,
  testPending = false,
  testFailed = false
}, ref) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();
  const { downloadAndSaveEmployeeSkills, SKILLS_CSV_COLUMNS } = useExportEmployeeProfile();
  const { editableSks: { data: skills, count, pending: pendingSkills, failed: failedSkills } } = useContext(DataContext);

  const getExport = useCallback((format: ExportFormat, token: string) => {
    if (!employee) return null;
    const fullName = getFullName(employee.first_name, employee.last_name, employee.code);
    return downloadAndSaveEmployeeSkills(
      supervisor,
      format,
      token,
      formatMessage({ id: 'supervisor.export.skills.title' }, {
        code: employee.code,
        fullName,
        endsWithS: endsWith(fullName, 's')
      }),
      [
        formatMessage({ id: 'supervisor.export.common.footer' }),
        formatMessage({ id: 'supervisor.export.skills.footer' }, { count })
      ],
      employee,
      map(SKILLS_CSV_COLUMNS, (id) => formatMessage({ id })),
      !isNil(count) && count >= 1 && count <= MIN_PAGE_SIZE ? skills : undefined
    );
  }, [supervisor, count, employee, skills, formatMessage, SKILLS_CSV_COLUMNS, downloadAndSaveEmployeeSkills]);

  const exp = useExport(getExport);

  return (
    <>
      <ExportButton
          ref={ref}
          pending={exp.failed === false || testPending}
          disabled={disabled || !employee || pendingSkills || failedSkills || !skills ||
            size(skills) === 0 || exp.failed === false || !exp.enabled}
          onExport={exp.handleExport}
      />
      <ActionFailedAlert
          message="supervisor.export.failed"
          open={exp.failed || testFailed}
          onDismiss={exp.handleDismiss}
      />
    </>
  );
});

ExportSkillsButton.displayName = 'ExportSkillsButton';

ExportSkillsButton.propTypes = ExportSkillsButtonPropTypes;

export default memo(ExportSkillsButton);
