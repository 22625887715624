import { gql } from '@apollo/client';
// local imports
import { API_MY_PROFILE_BUILDER_COMPLETE } from '../config/api';

export const PROFILE_BUILDER_COMPLETE = gql`
  mutation ProfileBuilderComplete(
    $input: ProfileBuilderCompleteInput!
  ) {
    profileBuilderComplete(
      input: $input
    )
    @rest(
      type: "MutationResponse",
      path: "${API_MY_PROFILE_BUILDER_COMPLETE}",
      method: "POST"
    ) {
      success
    }
  }
`;
