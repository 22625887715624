import { forwardRef, memo, useMemo, type Ref, type ReactNode } from 'react';
import PropTypes from 'prop-types';
import startsWith from 'lodash/startsWith';
import { Link as RouterLink } from 'react-router-dom';
import { useIntl } from 'react-intl';
// Material UI imports
import { type Variant } from '@mui/material/styles/createTypography';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// SCSS imports
import { drawerItem } from './AppDrawerItem.module.scss';

const primaryTypographyProps = {
  variant: 'subtitle2' as Variant,
  color: 'secondary',
  className: drawerItem
};

type AppDrawerItemProps = {
  text?: string;
  icon?: ReactNode;
  link?: string;
  href?: string;
  onClick: () => void;
  active: boolean;
}

const AppDrawerItemPropTypes = {
  // attributes
  text: PropTypes.string,
  icon: PropTypes.node,
  link: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired
};

const AppDrawerItem = forwardRef<HTMLAnchorElement | HTMLDivElement, AppDrawerItemProps>(({
  text,
  icon,
  link,
  href,
  onClick,
  active
}, ref) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();
  const item = useMemo(() => text ? formatMessage({ id: text }) : undefined, [formatMessage, text]);
  if (!item && !icon) return null;
  const content = (
    <>
      {icon ? (
        <ListItemIcon>
          {icon}
        </ListItemIcon>
      ) : undefined}
      {item ? (
        <ListItemText
            primary={item}
            primaryTypographyProps={primaryTypographyProps}
        />
      ) : undefined}
    </>
  );
  return (link && (
    <ListItemButton
        ref={ref as Ref<HTMLAnchorElement>}
        onClick={onClick}
        component={RouterLink}
        to={link}
        selected={active}
    >
      {content}
    </ListItemButton>
  )) || (href && (
    <ListItemButton
        ref={ref as Ref<HTMLAnchorElement>}
        onClick={onClick}
        component="a"
        href={href}
        target={startsWith(href, '/') ? undefined : '_blank'}
        rel="noopener noreferrer"
        selected={active}
    >
      {content}
    </ListItemButton>
  )) || (
    <ListItemButton
        ref={ref as Ref<HTMLDivElement>}
        disabled
        selected={active}
    >
      {content}
    </ListItemButton>
  );
});

AppDrawerItem.displayName = 'AppDrawerItem';

AppDrawerItem.propTypes = AppDrawerItemPropTypes;

export default memo(AppDrawerItem);
