import { memo, useEffect, useContext, useMemo, type FunctionComponent, type MouseEventHandler, useState } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import isNil from 'lodash/isNil';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
// EmPath UI Components
import { type GetComponentProps } from '@empathco/ui-components/src/helpers/types';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CloseIconButton from '@empathco/ui-components/src/elements/CloseIconButton';
// local imports
import { AdminJob } from '../graphql/types';
import { JobLookupItem } from '../graphql/customTypes';
import { Job } from '../models/job';
import { PATH_JOB } from '../config/paths';
import { getJobAnalyticsData, useMixpanel } from '../context/analytics';
import { DataContext } from '../context';
import OpenReqCard from '../v3/OpenReqCard';
import CardsGrid from '../v3/CardsGrid';
import PaginationControls from '../v3/PaginationControls';
// SCSS imports
import { dialogContent, detailsButton } from './OpenReqsDialog.module.scss';

type OpenReqsDialogProps = {
  role?: Job | JobLookupItem | AdminJob | null;
  admin?: boolean;
  isOpen?: boolean;
  onClose: MouseEventHandler<HTMLButtonElement>;
  onExited: (node: HTMLElement) => void;
  withoutMoreButton?: boolean;
}

const OpenReqsDialogPropTypes = {
  // attributes
  role: PropTypes.object as Validator<Job | AdminJob>,
  admin: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
  withoutMoreButton: PropTypes.bool
};

const OpenReqsDialog: FunctionComponent<OpenReqsDialogProps> = ({
  role,
  admin = false,
  isOpen = false,
  onClose,
  onExited,
  withoutMoreButton = false
}) => {
  const mixpanel = useMixpanel();
  const {
    openReqs: { data: reqs, count, pending, failed }, requireOpenReqs
  } = useContext(DataContext);

  const { code: roleId, title } = role || {};

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>();

  useEffect(() => {
    if (roleId && !isNil(pageSize)) requireOpenReqs?.({
      role_id: roleId,
      limit: pageSize,
      offset: pageSize * (currentPage - 1)
    });
  }, [roleId, requireOpenReqs, pageSize, currentPage]);

  useEffect(() => {
    if (isOpen && role) mixpanel.track('OpenReqs', getJobAnalyticsData(role));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, mixpanel]); // do not track `role` changes

  const transitionProps = useMemo(() => ({ onExited }), [onExited]);

  const link = useMemo(
    () => !admin && roleId && !withoutMoreButton ? injectParams(PATH_JOB, { role_id: roleId }) : undefined,
    [roleId, admin, withoutMoreButton]
  );

  return (
    <Dialog
        disableEnforceFocus
        maxWidth="lg"
        fullWidth
        scroll="body"
        open={isOpen}
        onClose={onClose}
        TransitionProps={transitionProps}
    >
      <CloseIconButton onClick={onClose}/>
      <CardTitle
          flex
          wrap
          title={<FormattedMessage id="open_reqs.dialog.title" values={{ title }}/>}
          action={link ? (
            <Button
                color="primary"
                variant="outlined"
                component={RouterLink}
                to={link}
                disabled={pending || failed ? true : undefined}
                className={detailsButton}
            >
              <FormattedMessage id="open_reqs.view_details"/>
            </Button>
          ) : undefined}
      />
      <DialogContent className={dialogContent}>
        <CardsGrid
            items={reqs}
            variant="shady"
            pending={pending || !reqs}
            failed={failed}
            notFoundMessage="open_reqs.no_reqs"
            component={OpenReqCard}
            ComponentProps={undefined as unknown as Partial<GetComponentProps<typeof OpenReqCard>>}
            pagination={(
              <PaginationControls
                  settingsId="openreqs"
                  loaded={Boolean(reqs)}
                  pending={pending}
                  loading={pending}
                  total={count}
                  currentPage={currentPage}
                  onPageSelected={setCurrentPage}
                  onPageSize={setPageSize}
                  disabled={pending || failed}
              />
            )}
        />
      </DialogContent>
    </Dialog>
  );
};

OpenReqsDialog.propTypes = OpenReqsDialogPropTypes;

export default memo(OpenReqsDialog);
