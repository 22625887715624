import { memo, useCallback, useLayoutEffect, useState, type FunctionComponent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// local imports
import { isNotContractor, isEmployee, isLeader } from '../models/user';
import useCustomerSettings from '../config/customer';
import { HASH_INFERRED } from '../config/params';
import { PATH_MY_SKILLS, PATH_MY_SKILLS_BROWSER } from '../config/paths';
import { RouterLinkState } from '../helpers/routerParams';
import useVerifyUser from '../hooks/useVerifyUser';
import Screen from '../v3/Screen';
import DataStatusPoller from '../v3/DataStatusPoller';
import EmployeeSkillsRecommended from '../components/EmployeeSkillsRecommended';
import MySkills from '../components/MySkills';
import EmployeeDevPlansPanel from '../components/EmployeeDevPlansPanel';
import SkillsDevelopment from '../components/SkillsDevelopment';

const EmployeeSkillsScreen: FunctionComponent = () => {
  const { HAS_DEV_PLAN } = useCustomerSettings();

  const navigate = useNavigate();
  const { state, hash, pathname } = useLocation();
  const { fromDashboard, viewInferredSkills } = (state as RouterLinkState) || {};

  const { content, user } = useVerifyUser({ employee: true, leader: true });
  const nonReducedUI = isNotContractor(user);
  const leader = isLeader(user) && !isEmployee(user);

  const [showInferred, setShowInferred] = useState(viewInferredSkills);
  useLayoutEffect(() => {
    if (viewInferredSkills || hash === `#${HASH_INFERRED}`) setShowInferred(true);
  }, [viewInferredSkills, hash]);

  const [savedPathname, setSavedPathname] = useState<string>();
  useLayoutEffect(() => {
    if (pathname === PATH_MY_SKILLS_BROWSER) setSavedPathname((prevPathname) => prevPathname || PATH_MY_SKILLS);
  }, [pathname]);

  const handleSkillsBrowserOpen = useCallback(() => {
    setSavedPathname(pathname);
    navigate(PATH_MY_SKILLS_BROWSER);
  }, [pathname, navigate]);

  const handleSkillsBrowserClose = useCallback(() => {
    const savedPath = savedPathname;
    setSavedPathname('');
    if (savedPath) navigate(savedPath);
  }, [savedPathname, navigate]);

  return content || (
    <Screen withSettings>
      <EmployeeSkillsRecommended
          userOrgId={user?.org?.id || undefined}
          reducedUI={!nonReducedUI || leader}
          onOpen={handleSkillsBrowserOpen}
          onClose={handleSkillsBrowserClose}
          savedPathname={savedPathname}
      />
      <MySkills
          scrollToView={Boolean(nonReducedUI && (fromDashboard || showInferred))}
          showInferredSkills={Boolean(nonReducedUI && showInferred)}
          reducedUI={!nonReducedUI || leader}
          manualOnly={leader}
          employee={user}
      />
      {nonReducedUI && !leader && HAS_DEV_PLAN ? (
        <EmployeeDevPlansPanel scrollToView={Boolean((state as RouterLinkState)?.viewDevPlans)}/>
      ) : undefined}
      {nonReducedUI && !leader ? <SkillsDevelopment savedPathname={savedPathname}/> : undefined}
      <DataStatusPoller/>
    </Screen>
  );
};

export default memo(EmployeeSkillsScreen);
