import { gql } from '@apollo/client';
// local imports
import { API_DA_EMPLOYEE_VELOCITY } from '../config/api';

export const DA_EMPLOYEE_VELOCITY_QUERY = gql`
  query DAEmployeeVelocity(
    $input: DAEmployeeVelocityInput!
    $pathBuilder: String!
  ) {
    daEmployeeVelocity(
      input: $input
    )
    @rest(
      type: "DAEmployeeVelocityPayload",
      path: "${API_DA_EMPLOYEE_VELOCITY}",
      pathBuilder: $pathBuilder
    ) {
      total_employees
      results {
        date
        low
        medium
        high
      }
    }
  }
`;
