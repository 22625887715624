import { useCallback, type Ref, type RefCallback, type MutableRefObject } from 'react';
import forEach from 'lodash/forEach';
import isFunction from 'lodash/isFunction';

const useCombinedRefs = <T>(...refs: (Ref<T> | undefined)[]): RefCallback<T> =>
  useCallback((element: T | null) => {
    forEach(refs, (ref) => {
      if (!ref) return;
      if (isFunction(ref)) {
        ref(element);
      } else {
        (ref as MutableRefObject<T | null>).current = element;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refs);

export default useCombinedRefs;
