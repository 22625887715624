import { gql } from '@apollo/client';
// local imports
import { API_HR_CUSTOM_ACTIVITY } from '../config/api';

export const UPDATE_CUSTOM_ACTIVITY = gql`
  mutation UpdateCustomActivity(
    $activity_id: Int!
    $input: CustomActivityInput!
  ) {
    updateCustomActivity(
      activity_id: $activity_id,
      input: $input
    )
    @rest(
      type: "SkillActivity",
      path: "${API_HR_CUSTOM_ACTIVITY}",
      method: "PATCH"
    ) {
      id
    }
  }
`;
