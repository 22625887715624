import { memo, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
// local imports
import useStatusPoller from '../hooks/useStatusPoller';

type DataStatusPollerProps = {
  jobId?: number | null;
}

const DataStatusPollerPropTypes = {
  jobId: PropTypes.number
};

const DataStatusPoller: FunctionComponent<DataStatusPollerProps> = ({
  jobId
}) => {
  useStatusPoller(jobId || null);
  return null;
};

DataStatusPoller.propTypes = DataStatusPollerPropTypes;

export default memo(DataStatusPoller);
