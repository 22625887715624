import { forwardRef, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import max from 'lodash/max';
import size from 'lodash/size';
import join from 'lodash/join';
import ceil from 'lodash/ceil';
import take from 'lodash/take';
import range from 'lodash/range';
import toSafeInteger from 'lodash/toSafeInteger';
import { type CallbackDataParams } from 'echarts/types/src/util/types';
import type EChartsReactCore from 'echarts-for-react/lib/core';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
// Material UI imports
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/system/colorManipulator';
// EmPath UI Components
import { percentageOptions } from '@empathco/ui-components/src/common/intl';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import { getFullName, getTruncatedTitle } from '@empathco/ui-components/src/helpers/strings';
import { spacing } from '@empathco/ui-components/src/helpers/styles';
import { type EChartsMouseEvent } from '@empathco/ui-components/src/helpers/echarts';
import useCombinedRefs from '@empathco/ui-components/src/hooks/useCombinedRefs';
// local imports
import { Skill } from '../models/skill';
import { Job } from '../models/job';
import { MDTargetedJob, DAMostEffectiveCourses, DAMostEffectiveAdvisor, DACourseCompletion } from '../graphql/types';
import { GlobalEChartsStyles, MAX_MANAGER_DASHBOARD_ITEMS } from '../config/params';
import useCustomerSettings from '../config/customer';
import Chart from '../elements/Chart';
// SCSS imports
import { chart, chartPreview, tooltip, tooltipTitle, tooltipSubheader, tooltipLine } from './TopChartSimple.module.scss';

const SIMPLE_CHART_VARIANTS = ['courses', 'completion', 'advisors'] as const;
export type SimpleChartVariant = typeof SIMPLE_CHART_VARIANTS[number];
export const SimpleChartVariantProp = PropTypes.oneOf(SIMPLE_CHART_VARIANTS);

type TopChartSimpleProps = {
  preview?: boolean;
  hrbp?: boolean;
  data?: (Skill | Job | MDTargetedJob | DAMostEffectiveCourses | DAMostEffectiveAdvisor | DACourseCompletion)[];
  totalEmployees?: number | null;
  path?: string;
  variant?: SimpleChartVariant;
  pending?: boolean | null;
}

const TopChartSimplePropTypes = {
  // attributes
  preview: PropTypes.bool,
  hrbp: PropTypes.bool,
  data: PropTypes.array,
  totalEmployees: PropTypes.number,
  path: PropTypes.string,
  variant: SimpleChartVariantProp,
  pending: PropTypes.bool
};

// eslint-disable-next-line max-lines-per-function
const TopChartSimple = forwardRef<EChartsReactCore, TopChartSimpleProps>(({
  preview = false,
  hrbp = false,
  data,
  totalEmployees,
  path,
  variant,
  pending = false
}, ref) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const yLabelWidth = spacing(34);
  const { getCourseUrl } = useCustomerSettings();

  const courses = variant === 'courses';
  const completion = variant === 'completion';
  const advisors = variant === 'advisors';

  const triggerEvent = (!preview && (((courses || completion) && 1) || (path && 2))) || 0;

  // eslint-disable-next-line jest/unbound-method
  const { formatMessage, formatNumber } = useIntl();

  const innerRef = useRef<EChartsReactCore>(null);
  const chartRef = useCombinedRefs<EChartsReactCore>(ref, innerRef);

  const [activeId, setActiveId] = useState<number>();

  const categories = useMemo(() => preview
    ? map(range(MAX_MANAGER_DASHBOARD_ITEMS), (id) => ({ value: `{id:${id + 1}}` }))
    : map(take(
      data as (Skill & Job & MDTargetedJob & DAMostEffectiveCourses & DACourseCompletion & DAMostEffectiveAdvisor)[],
      MAX_MANAGER_DASHBOARD_ITEMS
    ), ({
      id, code, abbr, title, mentor_id, mentor_first_name, mentor_last_name, mentor_uid, skill_abbr
    }) => ({
        value: JSON.stringify({
          id: advisors ? mentor_id : id,
          code: advisors ? skill_abbr : abbr || code,
          label: getTruncatedTitle(advisors
            ? getFullName(mentor_first_name || undefined, mentor_last_name || undefined, mentor_uid || undefined)
            : title
          )
        }),
        ...activeId && (advisors ? mentor_id : id) === activeId ? {
          textStyle: {
            backgroundColor: alpha(theme.palette.secondary.main, theme.palette.action.hoverOpacity)
          }
        } : {}
      })),
    [activeId, data, theme, advisors, preview]);

  const total = useMemo(
    () => (courses && 100) || (advisors
      ? max(map(data as DAMostEffectiveAdvisor[], ({ total_points }) => toSafeInteger(total_points))) || 1
      : toSafeInteger(totalEmployees) || (data?.[0] as Skill & Job & MDTargetedJob)?.employees_count || 1),
    [data, totalEmployees, courses, advisors]);

  const counts = useMemo(() => {
    const styles = (completion && [
      // series: 1-6
      { color: theme.palette.chart.series1level2 },
      { color: theme.palette.chart.series2level2 },
      { color: theme.palette.chart.series3level3 },
      { color: theme.palette.chart.series4level2 },
      { color: theme.palette.chart.series5level2 },
      { color: theme.palette.chart.series6level2 }
    ]) || ((courses || advisors) && [
      // series: 1-6
      { color: theme.palette.chart.series1level3 },
      { color: theme.palette.chart.series2level3 },
      { color: theme.palette.chart.series3level4 },
      { color: theme.palette.chart.series4level3 },
      { color: theme.palette.chart.series5level3 },
      { color: theme.palette.chart.series6level3 }
    ]) || [
      // series: 1-6
      { color: theme.palette.chart.series1 },
      { color: theme.palette.chart.series2 },
      { color: theme.palette.chart.series3 },
      { color: theme.palette.chart.series4 },
      { color: theme.palette.chart.series5 },
      { color: theme.palette.chart.series6 }
    ];
    return preview
      ? map(range(MAX_MANAGER_DASHBOARD_ITEMS), (value, index) => ({ value: 100 - 15 * value, itemStyle: styles[index] }))
      : map(take(data, MAX_MANAGER_DASHBOARD_ITEMS), (item, index) => ({
          value: courses || completion || advisors
            ? toSafeInteger(
                (courses && (item as DAMostEffectiveCourses).percentage) ||
                (completion && (item as DACourseCompletion).total) ||
                (advisors && (item as DAMostEffectiveAdvisor).total_points)
              ) || 1 // do not show 0%, show 1% instead
            : toSafeInteger((item as Skill & Job & MDTargetedJob).employees_count),
          itemStyle: styles[index]
        }));
  }, [
    data, courses, completion, advisors, preview,
    theme.palette.chart.series1level2, theme.palette.chart.series2level2, theme.palette.chart.series3level3,
    theme.palette.chart.series4level2, theme.palette.chart.series5level2, theme.palette.chart.series6level2,
    theme.palette.chart.series1, theme.palette.chart.series2, theme.palette.chart.series3,
    theme.palette.chart.series4, theme.palette.chart.series5, theme.palette.chart.series6,
    theme.palette.chart.series1level3, theme.palette.chart.series2level3, theme.palette.chart.series3level4,
    theme.palette.chart.series4level3, theme.palette.chart.series5level3, theme.palette.chart.series6level3
  ]);

  const onEvents = useMemo(() => triggerEvent ? {
    click: ({ componentType, value }: EChartsMouseEvent) => {
      if (componentType === 'yAxis') try {
        const { code, external_url } = JSON.parse(value);
        const link = triggerEvent === 1
          ? external_url || (code && getCourseUrl(code))
          : path && code && injectParams(path, { role_id: code, skill_id: code });
        if (link) {
          if (triggerEvent === 1) {
            // eslint-disable-next-line no-unused-expressions
            window.open(link, '_blank') || window.location.assign(link);
          }
          else navigate(link);
        }
      } catch (_err) {
        // nothing to do
      }
    },
    mouseover: ({ componentType, value }: EChartsMouseEvent) => {
      if (componentType === 'yAxis') try {
        setActiveId(JSON.parse(value).id);
      } catch (_err) {
        setActiveId(undefined);
      }
    },
    mouseout: ({ componentType }: EChartsMouseEvent) => {
      if (componentType === 'yAxis') setActiveId(undefined);
    }
  } : undefined, [triggerEvent, path, navigate, getCourseUrl]);

  const yAxisLabelFormatter = useCallback((value: string, index: number) => {
    try {
      return `{label|${JSON.parse(value).label}}${courses ? '' : `\n{counts|${advisors
        ? getTruncatedTitle((data?.[index] as DAMostEffectiveAdvisor)?.skill_title || '')
        : formatMessage({ id: 'supervisor.employees.count' }, {
            count: toSafeInteger((data?.[index] as Skill & Job & MDTargetedJob & DACourseCompletion)?.[
              completion ? 'total' : 'employees_count'
            ])
          })
      }}`}`;
    } catch (_err) {
      return value;
    }
  }, [data, courses, advisors, completion, formatMessage]);

  const labelFormatter = useCallback(
    ({ value }: { value?: number | null; }) => formatNumber(ceil((value || 0) / total, 2), percentageOptions),
    [formatNumber, total]
  );

  const tooltipFormatter = useCallback((params: CallbackDataParams) => {
    const { dataIndex } = params;
    const { title, skills } = (data as DAMostEffectiveCourses & DACourseCompletion[])?.[dataIndex] || {};
    const skillsCount = size(skills);
    return `<div class="${tooltip}"><div class="${tooltipTitle}">${title}</div><div class="${tooltipSubheader}">${
      formatMessage({
        id: skillsCount >= 1 ? 'hr.dashboard.effective_courses.skills' : 'hr.dashboard.effective_courses.no_skills'
      }, { skills: skillsCount })
    }</div>${
      join(map(skills, (skill) => `<li class="${tooltipLine}">${skill.title}</li>`), '')
    }</div>`;
  }, [data, formatMessage]);

  useEffect(() => {
    if (!innerRef.current) return;

    const echartInstance = innerRef.current.getEchartsInstance();

    echartInstance.setOption({
      ...GlobalEChartsStyles,
      silent: Boolean(preview || pending) || (!courses && !completion),
      grid: preview ? { left: 0, top: 0, right: 0, bottom: 0 } : {
        left: yLabelWidth + spacing(4.5),
        top: '5%',
        right: hrbp ? spacing(10) : '5%',
        bottom: '5%',
        containLabel: true
      },
      ...!preview && !pending && (courses || completion) ? {
        tooltip: {
          show: true,
          confine: true,
          formatter: tooltipFormatter,
          borderColor: theme.palette.misc.selectedBorder,
          borderWidth: theme.shape.borderWidth,
          backgroundColor: theme.palette.background.tooltip,
          extraCssText: `box-shadow: ${theme.shadows[5]}`
        }
      } : {},
      xAxis: {
        type: 'value',
        ...advisors ? { inverse: true } : {},
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: { show: false },
        splitLine: { show: false }
      },
      yAxis: {
        type: 'category',
        position: 'left',
        inverse: true,
        triggerEvent: Boolean(triggerEvent),
        data: categories,
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: preview ? null : {
          inside: true,
          interval: 0,
          formatter: yAxisLabelFormatter,
          width: yLabelWidth,
          margin: -(yLabelWidth + spacing(2.25)),
          overflow: 'truncate',
          padding: [spacing(0.5), 0, 0, spacing(0.75)],
          borderRadius: theme.shape.borderRadius,
          lineHeight: 18,
          rich: {
            label: {
              fontSize: 15,
              fontWeight: theme.typography.fontWeightMedium,
              color: theme.palette.secondary.text
            },
            counts: {
              fontSize: 14.5,
              fontWeight: theme.typography.fontWeightRegular,
              color: theme.palette.text.primary
            }
          }
        }
      },
      series: {
        type: 'bar',
        ...courses || completion || advisors ? { barWidth: '62%' } : {},
        data: counts,
        label: !preview && hrbp ? {
          show: true,
          position: 'right',
          padding: [0, 0, 0, spacing(1.5)],
          fontSize: 14,
          fontWeight: theme.typography.fontWeightRegular,
          color: theme.palette.text.primary,
          formatter: labelFormatter
        } : undefined,
        itemStyle: {
          borderRadius: theme.shape.smallBorderRadius
        }
      }
    }, true);
    echartInstance.resize();
  }, [
    categories, counts, triggerEvent, labelFormatter, yAxisLabelFormatter, tooltipFormatter,
    courses, completion, advisors, pending, hrbp, path, yLabelWidth, preview, theme
  ]);

  return (
    <Chart
        ref={chartRef}
        option={GlobalEChartsStyles}
        className={preview ? chartPreview : chart}
        onEvents={pending ? undefined : onEvents}
    />
  );
});

TopChartSimple.displayName = 'TopChartSimple';

TopChartSimple.propTypes = TopChartSimplePropTypes;

export default memo(TopChartSimple);
