import { forwardRef, memo } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import isNil from 'lodash/isNil';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// EmPath UI Components
import YesNoButtonGroup from '@empathco/ui-components/src/elements/YesNoButtonGroup';
// SCSS imports
import { targetButton } from './TargetSwitch.module.scss';

export type TargetSwitchVariant = 'role' | 'skill' | 'advisor';

type TargetSwitchProps = {
  variant: TargetSwitchVariant;
  value?: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean | null;
  pendingValue?: boolean | null;
}

const TargetSwitchPropTypes = {
  variant: PropTypes.string.isRequired as Validator<TargetSwitchVariant>,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  pendingValue: PropTypes.bool
};

const TargetSwitch = forwardRef<HTMLDivElement, TargetSwitchProps>(({
  value = false,
  onChange,
  disabled = false,
  pendingValue,
  variant
}, ref) => {
  const label = (variant === 'advisor' && 'skill.opt_in_mentor') ||
    (variant === 'role' && 'role_details.target_this') ||
    'skill.target_this';
  return (
    <Box ref={ref} display="flex" justifyContent="flex-end" flexWrap="wrap" className={targetButton}>
      <Typography variant="body1">
        <FormattedMessage id={label}/>
      </Typography>
      <YesNoButtonGroup
          ariaLabel={label}
          small
          value={isNil(pendingValue) ? value : pendingValue}
          onChange={onChange}
          disabled={disabled}
          pendingValue={pendingValue}
      />
    </Box>
  );
});

TargetSwitch.displayName = 'TargetSwitch';

TargetSwitch.propTypes = TargetSwitchPropTypes;

export default memo(TargetSwitch);
