import { gql } from '@apollo/client';
// local imports
import { API_SKILLS } from '../config/api';

export const SKILLS_SEARCH_QUERY = gql`
  query SkillsSearch(
    $input: SkillsSearchInput!
    $pathBuilder: String!
  ) {
    skillsSearch(
      input: $input
    )
    @rest(
      type: "SimpleSkillsPayload",
      path: "${API_SKILLS}",
      pathBuilder: $pathBuilder
    ) {
      count
      results {
        id
        title
        abbr
        type
        is_in_demand
      }
    }
  }
`;
