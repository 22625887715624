import { useCallback, useMemo } from 'react';
// EmPath UI Components
import * as ML from '@empathco/ui-components/src/models/managementLevel';
// local imports
import type { AdminJob, EmployeeRole } from '../graphql/types';
import { JobLookupItem } from '../graphql/customTypes';
import type { Job } from '../models/job';
import type { Employee } from '../models/employee';
import useCustomerSettings from '../config/customer';

// Employee & Job Management Levels (could be also `0` or `null`)
export type EmployeeManagementLevel = ML.EmployeeManagementLevel;
// User Management Level (including Top Management)
export type ManagementLevel = ML.ManagementLevel;

function useNonReducedUI() {
  const { HAS_REDUCED_UI } = useCustomerSettings();
  const showNonReducedUI = useCallback((entity?: Employee | Job | JobLookupItem | AdminJob | EmployeeRole | null): boolean =>
    !HAS_REDUCED_UI || Boolean(entity && !entity.is_contractor),
    [HAS_REDUCED_UI]);
  return useMemo(() => ({ showNonReducedUI }), [showNonReducedUI]);
}

export default useNonReducedUI;