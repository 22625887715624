import { gql } from '@apollo/client';
// local imports
import { API_DA_SKILLS_INSIGHTS } from '../config/api';

export const DA_SKILLS_INSIGHTS_QUERY = gql`
  query DASkillsInsights(
    $input: DASkillsInsightsInput!
    $pathBuilder: String!
  ) {
    daSkillsInsights(
      input: $input
    )
    @rest(
      type: "DASkillsInsightsPayload",
      path: "${API_DA_SKILLS_INSIGHTS}",
      pathBuilder: $pathBuilder
    ) {
      total_employees
      results {
        id
        title
        abbr
        average_actual_level
        average_expected_level
        level_diff # unused
        level1
        level2
        level3
        level4
        required_level_1
        required_level_2
        required_level_3
        required_level_4
      }
    }
  }
`;
