import { gql } from '@apollo/client';
// local imports
import { API_HR_ORG } from '../config/api';

export const SUGGESTED_IN_DEMAND_QUERY = gql`
  query SuggestedInDemandSkills(
    $org_id: Int!
    $limit: Int
  ) {
    suggestedInDemandSkills(
      org_id: $org_id,
      limit: $limit
    )
    @rest(
      type: "SuggestedInDemandSkillsPayload",
      path: "${API_HR_ORG}{args.org_id}/skills/suggested/?{args}"
    ) {
      count
      results {
        id
        title
        abbr
        role_type
      }
    }
  }
`;
