import { memo, useCallback, useEffect, useState, useMemo, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import omit from 'lodash/omit';
import includes from 'lodash/includes';
import { useLazyQuery, useMutation, type ApolloCache } from '@apollo/client';
// Material UI imports
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
// EmPath UI Components
import { GetComponentProps } from '@empathco/ui-components/src/helpers/types';
import { paramsDiffer } from '@empathco/ui-components/src/helpers/pagination';
import { bold } from '@empathco/ui-components/src/helpers/intl';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import useMutationMethod from '@empathco/ui-components/src/hooks/useMutationMethod';
import useConfirmationDialog from '@empathco/ui-components/src/hooks/useConfirmationDialog';
import CloseIconButton from '@empathco/ui-components/src/elements/CloseIconButton';
import PlusButton from '@empathco/ui-components/src/elements/PlusButton';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import ConfirmDialog from '@empathco/ui-components/src/elements/ConfirmDialog';
// local imports
import { CUSTOM_ACTIVITIES_QUERY } from '../graphql/CustomActivities';
import { NEW_CUSTOM_ACTIVITY } from '../graphql/NewCustomActivity';
import { UPDATE_CUSTOM_ACTIVITY } from '../graphql/UpdateCustomActivity';
import { DELETE_CUSTOM_ACTIVITY } from '../graphql/DeleteCustomActivity';
import {
  SkillActivity, SkillActivityType, ActivitySkill, DevPlanTargetSkill,
  CustomActivitiesDocument, CustomActivitiesQueryVariables,
  NewCustomActivityDocument, UpdateCustomActivityDocument, CustomActivityInput,
  DeleteCustomActivityDocument,
} from '../graphql/types';
import PaginationControls from '../v3/PaginationControls';
import ActivityCard from '../v3/ActivityCard';
import CardsGrid from '../v3/CardsGrid';
import CustomActivityEditor from './CustomActivityEditor';

// TODO: optimistic update
const updateActivities = (cache: ApolloCache<unknown>) => {
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'customActivities' });
};

type CustomActivitiesDialogProps = {
  selectedIds?: number[];
  targetSkills?: DevPlanTargetSkill[];
  open?: boolean;
  onClose: () => void;
  onSelect: (activity: SkillActivity) => void;
  onUpdate: (activity: Partial<SkillActivity>) => void;
  onRemove: (activity: SkillActivity) => void;
  // for Storybook only
  testAction?: number;
}

const CustomActivitiesDialogPropTypes = {
  selectedIds: PropTypes.array,
  targetSkills: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  testAction: PropTypes.number
};

const CustomActivitiesDialog: FunctionComponent<CustomActivitiesDialogProps> = ({
  selectedIds,
  targetSkills,
  open = false,
  onClose,
  onSelect,
  onUpdate,
  onRemove,
  testAction
}) => {
  // lazy load selected activities
  const {
    query: getActivities, pending: pendingActivities, failed, count, results, variables: prevVars
  } = useQueryCounted({
    data: undefined as unknown as SkillActivity,
    key: 'customActivities',
    lazyQuery: useLazyQuery(CUSTOM_ACTIVITIES_QUERY as typeof CustomActivitiesDocument)
  });
  const pending = pendingActivities || testAction === 0;

  const { mutate: newActivity, loading: newPending, failed: newFailed } = useMutationMethod({
    mutation: useMutation(NEW_CUSTOM_ACTIVITY as typeof NewCustomActivityDocument)
  });
  const { mutate: updateActivity, loading: updatePending, failed: updateFailed } = useMutationMethod({
    mutation: useMutation(UPDATE_CUSTOM_ACTIVITY as typeof UpdateCustomActivityDocument)
  });
  const { mutate: deleteActivity, loading: deletePending, failed: deleteFailed } = useMutationMethod({
    mutation: useMutation(DELETE_CUSTOM_ACTIVITY as typeof DeleteCustomActivityDocument)
  });

  const [removeActivity, setRemoveActivity] = useState<SkillActivity>();

  // Custom Activities Editor
  const [editActivity, setEditActivity] = useState<SkillActivity>();

  const handleNewActivity = useCallback(() => setEditActivity({} as SkillActivity), []);

  const handleSaveActivity = useCallback((activity_id?: number, input?: CustomActivityInput, skills?: ActivitySkill[]) => {
    if (!input) {
      setEditActivity(undefined);
      return;
    }
    if (activity_id) updateActivity?.({
      variables: { activity_id, input },
      update: (cache) => {
        updateActivities(cache);
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'devplanEmployees' });
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'devplanProgresses' });
      },
      onCompleted: () => {
        setEditActivity(undefined);
        onUpdate({
          ...omit(input, ['skill_ids', 'levels']),
          ...skills ? { skills } : {},
          id: activity_id
        });
      }
    });
    else newActivity?.({
      variables: { input },
      update: updateActivities,
      onCompleted: ({ newCustomActivity }) => {
        setEditActivity(undefined);
        const { id, code } = newCustomActivity || {};
        if (id && code) onSelect({
          ...omit(input, ['skill_ids', 'levels']),
          ...skills ? { skills } : {},
          code,
          id
        });
      }
    });
  }, [newActivity, updateActivity, onUpdate, onSelect]);

  const doDeleteActivity = useCallback(() => {
    if (removeActivity?.id) deleteActivity?.({
      variables: { activity_id: removeActivity.id },
      update: updateActivities,
      onCompleted: () => {
        setRemoveActivity(undefined);
        onRemove(removeActivity);
      },
      onError: () => setRemoveActivity(undefined)
    });
  }, [removeActivity, deleteActivity, onRemove]);

  const {
    confirmOpen,
    confirmMounted,
    handleAction: handleDelete,
    handleCancel,
    handleExited,
    handleConfirm
  } = useConfirmationDialog(doDeleteActivity);

  const handleDeleteActivity = useCallback((activity?: SkillActivity) => {
    if (activity?.id) {
      setRemoveActivity(activity);
      handleDelete();
    }
  }, [handleDelete]);

  const confirmDeleteValues = useMemo(() => ({
    bold,
    name: removeActivity?.name || '?'
  }), [removeActivity]);

  const activities = useMemo(() => results ? map(results, (activity) => ({
    ...activity,
    is_selected: includes(selectedIds, activity.id)
  })) : results, [selectedIds, results]);

  const [mounted, setMounted] = useState(open);
  useEffect(() => {
    if (open) setMounted(true);
  }, [open]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>();

  const transitionProps = useMemo(() => ({ onExited: () => {
    setMounted(false);
  } }), []);

  const componentProps: Partial<GetComponentProps<typeof ActivityCard>> = useMemo(() => ({
    withEditor: true,
    onClick: setEditActivity,
    onSelect,
    onRemove: handleDeleteActivity,
  }), [onSelect, handleDeleteActivity]);

  useEffect(() => {
    if (open && !editActivity && !removeActivity && pageSize && getActivities) {
      const variables: CustomActivitiesQueryVariables = {
        limit: pageSize
      };
      let curPage = currentPage;
      if (paramsDiffer(prevVars, variables)) {
        curPage = 1;
        setCurrentPage(1);
      }
      variables.offset = pageSize * (curPage - 1);
      getActivities({ variables });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editActivity, removeActivity, open, currentPage, pageSize, getActivities]); // ignoring `prevVars` changes

  useEffect(() => {
    if (testAction === 1) newActivity?.({ variables: { input: { name: 'New Custom Activity', description: '...' } } });
    else if (testAction === 2) updateActivity?.({ variables: {
      activity_id: 1, input: { name: 'New Custom Activity', description: '...', activity_type: SkillActivityType.experience }
    } });
    else if (testAction === 3) deleteActivity?.({ variables: { activity_id: 1 } });
  }, [testAction, newActivity, updateActivity, deleteActivity]);

  if (!mounted) return null;

  const disabled = pending || newPending || updatePending || deletePending || Boolean(editActivity) || (testAction || 0) > 0;

  return (
    <>
      <Dialog
          disableEnforceFocus
          disablePortal
          maxWidth="xl"
          fullWidth
          scroll="body"
          open={open}
          onClose={onClose}
          TransitionProps={transitionProps}
      >
        <CloseIconButton onClick={onClose}/>
        <CardTitle title="hr.custom_activities.title" withDivider/>
        <CardsGrid
            items={activities}
            variant="white"
            withReloading
            pending={pending}
            failed={failed}
            disabled={disabled}
            blendNotFound
            blendFilters
            blendPagination
            component={ActivityCard}
            ComponentProps={componentProps}
            notFoundMessage="hr.custom_activities.empty"
            filters={(
              <Box flex="1 1 0" display="flex" alignItems="center" justifyContent="flex-end">
                <PlusButton
                    label="hr.custom_activities.new"
                    disabled={disabled}
                    pending={newPending || testAction === 1 ? true : undefined}
                    onClick={handleNewActivity}
                />
              </Box>
            )}
            pagination={(
              <PaginationControls
                  settingsId="custom_activities"
                  loaded={Boolean(activities)}
                  pending={pending}
                  loading={pending}
                  total={count}
                  currentPage={currentPage}
                  onPageSelected={setCurrentPage}
                  onPageSize={setPageSize}
                  disabled={pending || failed}
                  totalMessage="hr.custom_activities.pagination"
              />
            )}
        />
      </Dialog>
      <CustomActivityEditor
          activity={editActivity}
          targetSkills={targetSkills}
          onClose={handleSaveActivity}
          pending={newPending || updatePending}
      />
      {confirmMounted ? (
        <ConfirmDialog
            open={confirmOpen}
            title="hr.custom_activities.delete.title"
            text="hr.custom_activities.delete.question"
            values={confirmDeleteValues}
            withCancelButton
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            onExited={handleExited}
        />
      ) : undefined}
      <ActionFailedAlert
          message="hr.custom_activities.new.error"
          open={newFailed}
      />
      <ActionFailedAlert
          message="hr.custom_activities.update.error"
          open={updateFailed}
      />
      <ActionFailedAlert
          message="hr.custom_activities.delete.error"
          open={deleteFailed}
      />
    </>
  );
};

CustomActivitiesDialog.propTypes = CustomActivitiesDialogPropTypes;

export default memo(CustomActivitiesDialog);
