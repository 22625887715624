import { forwardRef, memo, useCallback, useLayoutEffect, useMemo, useState, type ReactNode, type ChangeEvent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import join from 'lodash/join';
import head from 'lodash/head';
import sortBy from 'lodash/sortBy';
import range from 'lodash/range';
import without from 'lodash/without';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import isSafeInteger from 'lodash/isSafeInteger';
import toSafeInteger from 'lodash/toSafeInteger';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import FormControl from '@mui/material/FormControl';
import Select, { type SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
// Material Icon imports
import AddRounded from '@mui/icons-material/AddRounded';
// local imports
import { EmployeeManagementLevel } from '../models/managementLevel';
import { SkillLevel, SKILL_LEVEL_MIN, SKILL_LEVEL_MAX } from '../models/skillLevel';
import { bold } from '../helpers/intl';
import { DEFAULT_MENU_PROPS, DEFAULT_MENU_LEFT } from '../helpers/menus';
// SCSS imports
import { root, rootLevel, placeholder } from '../styles/modules/Lookup.module.scss';

export type LevelSelectorVaraint = 'job' | string;

type LevelSelectorProps = {
  search?: boolean;
  multiple?: boolean;
  variant?: LevelSelectorVaraint;
  nonZero?: boolean;
  minValue?: SkillLevel | EmployeeManagementLevel;
  maxValue?: SkillLevel | EmployeeManagementLevel;
  value?: SkillLevel | EmployeeManagementLevel;
  values?: SkillLevel[] | EmployeeManagementLevel[];
  onChange?: (level: SkillLevel | EmployeeManagementLevel) => void;
  onChangeMulpiple?: (level: SkillLevel[] | EmployeeManagementLevel[]) => void;
  disabled?: boolean;
};

const LevelSelectorPropTypes = {
  // attributes
  search: PropTypes.bool,
  multiple: PropTypes.bool,
  variant: PropTypes.string as Validator<LevelSelectorVaraint>,
  nonZero: PropTypes.bool,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  value: PropTypes.number as Validator<SkillLevel | EmployeeManagementLevel>,
  values: PropTypes.arrayOf(
    PropTypes.number.isRequired as Validator<SkillLevel | EmployeeManagementLevel>
  ),
  onChange: PropTypes.func,
  onChangeMulpiple: PropTypes.func,
  disabled: PropTypes.bool
};

// eslint-disable-next-line complexity
const LevelSelector = forwardRef<HTMLDivElement, LevelSelectorProps>(({
  search = false,
  multiple = false,
  variant,
  nonZero = false,
  minValue: propsMinValue,
  maxValue: propsMaxValue,
  value,
  values,
  onChange,
  onChangeMulpiple,
  disabled = false
}, ref) => {
  const isJob = Boolean(variant);
  const prefix = variant
    ? (variant === 'job' && 'common.job_level') || `levelselector.${variant}`
    : 'common.skill_level';
  const minValue = isNil(propsMinValue) ? SKILL_LEVEL_MIN + (isJob ? 1 : 0) : propsMinValue;
  const maxValue = isNil(propsMaxValue) ? SKILL_LEVEL_MAX : propsMaxValue;

  const [selected, setSelected] = useState<(SkillLevel | EmployeeManagementLevel)[]>([]);

  useLayoutEffect(() => {
    if (multiple && values) setSelected(values);
  }, [multiple, values]);

  const handleChange = useCallback((
    event: SelectChangeEvent<SkillLevel | EmployeeManagementLevel | (SkillLevel | EmployeeManagementLevel)[]>
  ) => {
    if (multiple) {
      setSelected((prevSelected) =>
        includes(event.target.value as SkillLevel[], minValue - 1) && size(prevSelected) >= 1 ? []
        : sortBy(without(map(event.target.value as SkillLevel[], toSafeInteger) as SkillLevel[], minValue - 1))
      );
    } else if (onChange) onChange(toSafeInteger(event.target.value) as SkillLevel);
  }, [multiple, minValue, onChange]);

  const handleClose = useCallback((_event: ChangeEvent<{}>) => {
    if (onChangeMulpiple) onChangeMulpiple(selected);
  }, [onChangeMulpiple, selected]);

  const levels = useMemo(() => map(range(minValue, maxValue + 2), (level) => level > maxValue
    ? <FormattedMessage id={`${prefix}.select`}/>
    : <FormattedMessage id={`${prefix}.item`} values={{ level, bold }}/>
  ), [minValue, maxValue, prefix]);

  const renderValue = useCallback(
    (level: unknown): ReactNode => (search && (isJob ? isSafeInteger(level) && (level as number) < minValue : level === 0)) ||
      (multiple && (
        size(level as SkillLevel[]) <= 0 ||
        (size(level as SkillLevel[]) === 1 && head(level as number[]) === minValue - 1))
      )
      ? levels[maxValue - minValue + 1]
      : (multiple && join(map(level as SkillLevel[], toSafeInteger), ', ')) ||
        levels[toSafeInteger(level) - minValue],
    [levels, minValue, maxValue, isJob, search, multiple]
  );

  return (
    <FormControl ref={ref} variant="outlined" size="small" disabled={disabled} className={isJob ? root : rootLevel}>
      <Select
          id="skill-level-select"
          multiple={multiple}
          value={multiple ? (size(selected) >= 1 && selected) || [minValue - 1] : value}
          displayEmpty
          renderValue={renderValue}
          onChange={handleChange}
          onClose={multiple ? handleClose : undefined}
          IconComponent={multiple ? AddRounded : undefined}
          MenuProps={search ? DEFAULT_MENU_PROPS : DEFAULT_MENU_LEFT}
          className={(search && (isJob ? isSafeInteger(value) && (value as number) < minValue : value === 0)) ||
            (multiple && size(values) < 1) ? placeholder : undefined}
      >
        {(search || multiple) && isJob ? (
          <MenuItem value={minValue - 1}>
            <FormattedMessage id={`${prefix}.all`}/>
          </MenuItem>
        ) : undefined}
        {map(range(minValue + (!isJob && (search || nonZero) ? 1 : 0), maxValue + 1), (level) => (
          <MenuItem key={level} value={level}>
            {levels[level - minValue]}
          </MenuItem>
        ))}
        {search && !isJob ? (
          <MenuItem value={0}>
            <FormattedMessage id={`${prefix}.all`}/>
          </MenuItem>
        ) : undefined}
      </Select>
    </FormControl>
  );
});

LevelSelector.displayName = 'LevelSelector';

LevelSelector.propTypes = LevelSelectorPropTypes;

export default memo(LevelSelector);
