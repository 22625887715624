import { gql } from '@apollo/client';
// local imports
import { API_SUPV_OPPORTUNITY } from '../config/api';

export const DELETE_OPPORTUNITY = gql`
  mutation DeleteOpportunity(
    $opportunity_id: Int!
  ) {
    deleteOpportunity(
      opportunity_id: $opportunity_id
    )
    @rest(
      type: "MutationResponse",
      path: "${API_SUPV_OPPORTUNITY}",
      method: "DELETE"
    ) {
      success # just a placeholder; this request has empty response (status 204 only)
    }
  }
`;
