import { gql } from '@apollo/client';
// local imports
import { API_SKILLS_IN_DEMAND } from '../config/api';

export const SKILLS_IN_DEMAND_QUERY = gql`
  query SkillsInDemand(
    $org_id: Int!
    $limit: Int
  ) {
    skillsInDemand(
      org_id: $org_id,
      limit: $limit
    )
    @rest(
      type: "SimpleSkillsPayload",
      path: "${API_SKILLS_IN_DEMAND}?{args}"
    ) {
      count
      results {
        id
        title
        abbr
        type
        is_in_demand
      }
    }
  }
`;
