import { forwardRef, memo, type Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box, { type BoxProps } from '@mui/material/Box';
// local imports
import BoxTypography from '../mixins/BoxTypography';
// SCSS imports
import { line, mark, unmet, satisfied, required, growth } from './SkillLevelGauge.module.scss';
import { lineWidth } from './SkillsGapLegend.module.scss';

type SkillsGapLegendProps = {
  depersonalized?: boolean;
  withGrowth?: boolean;
  className?: string;
  sx?: typeof Box.propTypes.sx;
};

const SkillsGapLegendPropTypes = {
  depersonalized: PropTypes.bool,
  withGrowth: PropTypes.bool,
  className: PropTypes.string,
  sx: PropTypes.object
};

const SkillsGapLegend = forwardRef<Component<BoxProps>, SkillsGapLegendProps>(({
  depersonalized = false,
  withGrowth = false,
  className,
  sx
}, ref) => (
  <Box
      ref={ref}
      flexGrow={1}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      flexWrap="wrap"
      color="text.label"
      fontStyle="italic"
      sx={sx}
  >
    {(depersonalized ? [{ id: 'required', cls: required }] : [
      { id: 'unmet', cls: unmet },
      { id: 'satisfied', cls: satisfied },
      ...withGrowth ? [{ id: 'growth', cls: growth }] : []
    ])
    .map(({ id, cls }) => (
      <BoxTypography
          key={id}
          display="flex"
          alignItems="baseline"
          justifyContent="flex-end"
          variant="body1"
          className={className}
      >
        <Box pl={5} pr={1.5} display="flex" color="text.label" component="span">
          <Box
              display="flex"
              alignItems="flex-end"
              justifyContent="flex-end"
              className={`${line} ${cls} ${lineWidth}`}
              component="span"
          >
            <Box className={`${mark} ${cls}`} component="span"/>
          </Box>
        </Box>
        <FormattedMessage id={`common.role_skills.legend.${id}`}/>
      </BoxTypography>
    ))}
  </Box>
));

SkillsGapLegend.displayName = 'SkillsGapLegend';

SkillsGapLegend.propTypes = SkillsGapLegendPropTypes;

export default memo(SkillsGapLegend);
