import { gql } from '@apollo/client';
// local imports
import { API_DA_AVERAGE_SKILLS } from '../config/api';

export const DA_AVERAGE_SKILLS_QUERY = gql`
  query DAAverageSkills(
    $input: DAAverageSkillsInput!
    $pathBuilder: String!
  ) {
    daAverageSkills(
      input: $input
    )
    @rest(
      type: "DAAverageSkillsPayload",
      path: "${API_DA_AVERAGE_SKILLS}",
      pathBuilder: $pathBuilder
    ) {
      total_employees
      results {
        month
        job_category
        average_skills
      }
    }
  }
`;
