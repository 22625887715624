import {
  memo, useState, useContext, useEffect, useCallback, useLayoutEffect, useMemo, useRef, type FunctionComponent, type MouseEvent
} from 'react';
import PropTypes, { type Validator } from 'prop-types';
import get from 'lodash/get';
import size from 'lodash/size';
import omit from 'lodash/omit';
import isNil from 'lodash/isNil';
import toLower from 'lodash/toLower';
import endsWith from 'lodash/endsWith';
import isBoolean from 'lodash/isBoolean';
// Material UI imports
import Box from '@mui/material/Box';
// EmPath UI Components
import { paramsDiffer } from '@empathco/ui-components/src/helpers/pagination';
import PlusButton from '@empathco/ui-components/src/elements/PlusButton';
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import ViewSwitch, { TABLE_VIEW, type ViewType } from '@empathco/ui-components/src/elements/ViewSwitch';
import SkillsLegend from '@empathco/ui-components/src/elements/SkillsLegend';
import OnOffSwitch from '@empathco/ui-components/src/elements/OnOffSwitch';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
// local imports
import { Skill, SkillLevel } from '../models/skill';
import { sanitizeSkillsView } from '../helpers/models';
import useCustomerSettings from '../config/customer';
import { getSettingsStrValue, getSettingsBoolValue } from '../helpers/context';
import {
  SkillSort, DEFAULT_SORT_DIRECTION, SKILL_SORT_ASCENDING, SKILL_SORT_DESCENDING, SKILL_SORT_UNDEFINED, SKILL_SORTS,
  isValidSortBy, isValidSortParam, isValidSortDirection
} from '../constants/skillsSort';
import { DataContext } from '../context';
import { EditableSkillsParams } from '../context/dataContext';
import { EmployeeBrief } from '../models/employee';
import SortSelector from '../elements/SortSelector';
import ExportSkillsButton from '../elements/ExportSkillsButton';
import SkillsGrid from '../v3/SkillsGrid';
import PaginationControls from '../v3/PaginationControls';
import AddSkillDialog from '../widgets/AddSkillDialog';

type MySkillsProps = {
  scrollToView?: boolean;
  reducedUI?: boolean | null;
  manualOnly?: boolean;
  supervisor?: boolean;
  employee: EmployeeBrief | null;
  uid?: string | null;
  employeeName?: string | null;
  showInferredSkills?: boolean;
  // for Storybook only
  viewAs?: ViewType;
}

const MySkillsPropTypes = {
  // attributes
  scrollToView: PropTypes.bool,
  reducedUI: PropTypes.bool,
  manualOnly: PropTypes.bool,
  supervisor: PropTypes.bool,
  employee: PropTypes.object.isRequired as Validator<EmployeeBrief>,
  uid: PropTypes.string,
  employeeName: PropTypes.string,
  showInferredSkills: PropTypes.bool,
  // for Storybook only
  viewAs: PropTypes.string as Validator<ViewType>
};

// eslint-disable-next-line complexity, max-statements, max-lines-per-function
const MySkills: FunctionComponent<MySkillsProps> = ({
  supervisor = false,
  uid,
  employeeName,
  employee,
  scrollToView = false,
  reducedUI = false,
  manualOnly = false,
  showInferredSkills: parentShowInferredSkills,
  viewAs
}) => {
  const { HAS_MENTORING } = useCustomerSettings();
  const ref = useRef<HTMLElement>();
  const {
    editableSks: { data: editableSks, count, pending, failed, params }, requireEditableSkills,
    recommendedSkills: { data: recommendedSkills, pending: pendingRecommendedSkills },
    dataStatus: { pending: pendingStatus },
    preferences: { failed: preferencesFailed }, requirePreferences, // required by AddSkillDialog -> EditSkillLevel
    skillUpdate: { pending: updatePending },
    skillAdd: { pending: addPending, failed: addFailed }, addSkill,
    settings: { data: settingsData, pending: pendingSettings, failed: failedSettings },
    settingsUpdate: { pending: pendingSettingsUpdate }, updateSettings
  } = useContext(DataContext);

  const [showInferredSkills, setShowInferredSkills] = useState(parentShowInferredSkills);

  const [anchorAddBtn, setAnchorAddBtn] = useState<HTMLButtonElement | null>(null);
  const [doScroll, setDoScroll] = useState(scrollToView);
  useLayoutEffect(() => {
    if (scrollToView) setDoScroll(true);
  }, [scrollToView]);

  const settingsLoaded = pendingSettings === false && failedSettings === false && Boolean(settingsData);
  const settings = settingsLoaded ? settingsData : null;
  const settingsId = supervisor ? 'employee_skills' : 'my_skills';
  const settingsView = getSettingsStrValue(settings, `${settingsId}__view`);
  const settingsSort = getSettingsStrValue(settings, `${settingsId}__sort`);
  const settingsDirection = getSettingsBoolValue(settings, `${settingsId}__direction`);
  const settingsInferredOnly = getSettingsBoolValue(settings, `${settingsId}__inferences_only`);

  const [view, setView] = useState<ViewType>(isNil(viewAs) ? sanitizeSkillsView(settingsView) : viewAs);
  // eslint-disable-next-line react/hook-use-state
  const [{ sort, dir }, setSort] = useState<{
    sort: SkillSort;
    dir: boolean;
  }>({
    sort: isValidSortBy(settingsSort) ? settingsSort as SkillSort : SKILL_SORT_UNDEFINED,
    dir: isNil(settingsDirection) ? DEFAULT_SORT_DIRECTION[SKILL_SORT_UNDEFINED] : Boolean(settingsDirection)
  });
  const [inferredOnly, setInferredOnly] = useState<boolean | undefined>(
    !reducedUI && (showInferredSkills || settingsInferredOnly));

  useLayoutEffect(() => {
    if (parentShowInferredSkills) {
      setShowInferredSkills(true);
      setInferredOnly(true);
    }
  }, [parentShowInferredSkills]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>();

  const pendingAll = !settingsLoaded || pending;
  const loading = pendingAll || !editableSks;
  const loadingRecommendedSkills = pendingStatus || pendingRecommendedSkills || !recommendedSkills;
  const disabled = loading || failed || updatePending || addPending || !addSkill;

  useEffect(() => {
    if (settingsLoaded && !pendingSettingsUpdate) {
      if (isNil(viewAs)) setView(sanitizeSkillsView(getSettingsStrValue(settings, `${settingsId}__view`)));
      const sortFromSettings = getSettingsStrValue(settings, `${settingsId}__sort`);
      const directionFromSettings = getSettingsBoolValue(settings, `${settingsId}__direction`);
      setSort({
        sort: isValidSortBy(sortFromSettings) ? sortFromSettings as SkillSort : SKILL_SORT_UNDEFINED,
        dir: isNil(directionFromSettings)
          ? DEFAULT_SORT_DIRECTION[SKILL_SORT_UNDEFINED] : Boolean(directionFromSettings)
      });
      if (!reducedUI) setInferredOnly(showInferredSkills || getSettingsBoolValue(settings, `${settingsId}__inferences_only`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // ignore `showInferredSkills`
    supervisor, reducedUI, settingsLoaded, pendingSettingsUpdate, settings, settingsId, viewAs
  ]);

  useEffect(() => {
    if (!settingsLoaded || !requireEditableSkills || isNil(pageSize)) return;
    const direction = (view === TABLE_VIEW ? dir : DEFAULT_SORT_DIRECTION[sort])
      ? SKILL_SORT_ASCENDING : SKILL_SORT_DESCENDING;
    const hasSort = isValidSortParam(sort);
    const newParams: EditableSkillsParams = {
      limit: pageSize,
      ...hasSort ? { sort_by: sort } : {},
      ...hasSort && isValidSortDirection(direction) ? { direction } : {},
      inferences_only: inferredOnly
    };
    let curPage = currentPage;
    if (paramsDiffer(supervisor && uid ? omit(params, 'selected_employee_id') : params, newParams) ||
      (supervisor && uid && toLower(uid) !== toLower(get(params, 'selected_employee_id')))
    ) {
      curPage = 1;
      setCurrentPage(1);
    }
    requireEditableSkills({
      ...newParams,
      offset: pageSize * (curPage - 1)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // only search and pagination params are monitored (i.e. ignoring `params` changes)
    supervisor, uid, currentPage, pageSize, view, sort, dir, inferredOnly,
    settingsLoaded, requireEditableSkills
  ]);

  useEffect(() => {
    if (!supervisor && !manualOnly) requirePreferences?.();
  }, [requirePreferences, supervisor, manualOnly]);

  useEffect(() => {
    if (!ref.current || !doScroll) return;
    ref.current.scrollIntoView({ behavior: 'smooth' });
    if (!loading && !loadingRecommendedSkills) setDoScroll(false);
  }, [ref, doScroll, loading, loadingRecommendedSkills]);

  const handleAddSkill = useCallback((skl: Skill, level: SkillLevel, mentoring: boolean, is_target?: boolean) => {
    addSkill?.({
      skill_id: skl.id,
      level,
      ...HAS_MENTORING ? { is_opt_in_mentor: mentoring } : {},
      is_target,
      source: 'my_skills'
    });
    setAnchorAddBtn(null);
  }, [addSkill, HAS_MENTORING]);

  const handleAddOpen = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => event && setAnchorAddBtn(event.currentTarget), []);
  const handleAddClose = useCallback(() => setAnchorAddBtn(null), []);

  const handleView = useCallback((value: string) => {
    const val = sanitizeSkillsView(value);
    setView(val);
    if (settingsView !== val) updateSettings?.({ [`${settingsId}__view`]: val });
  }, [settingsView, updateSettings, settingsId]);

  const handleSort = useCallback((sortValue: string, newDir?: boolean | null) => {
    if (isValidSortBy(sortValue)) {
      const dirValue = isBoolean(newDir) ? newDir : DEFAULT_SORT_DIRECTION[sortValue];
      setSort({
        sort: sortValue as SkillSort,
        dir: dirValue
      });
      if (settingsSort !== sortValue || Boolean(settingsDirection) !== dirValue) updateSettings?.({
        [`${settingsId}__sort`]: sortValue,
        [`${settingsId}__direction`]: dirValue
      });
    }
  }, [settingsSort, settingsDirection, updateSettings, settingsId]);

  const handleInferredOnly = useCallback((value: boolean) => {
    const val = Boolean(value);
    setInferredOnly(val);
    setShowInferredSkills(false);
    if (Boolean(settingsInferredOnly) !== val) updateSettings?.({ [`${settingsId}__inferences_only`]: val });
  }, [settingsId, settingsInferredOnly, updateSettings]);

  const values = useMemo(() => supervisor ? {
    name: employeeName || '',
    endsWithS: employeeName && endsWith(employeeName, 's')
  } : undefined, [employeeName, supervisor]);

  const showSubheader = !failed && (loading || size(editableSks) >= 1);
  const showLegend = !reducedUI && showSubheader;

  return (
    <>
      <ContentCard withoutTopMargin={reducedUI ? true : undefined}>
        {scrollToView ? (
          <Box position="relative">
            <Box
                ref={ref}
                position="absolute"
                top="-8rem"
            />
          </Box>
        ) : undefined}
        <CardTitle
            title={supervisor ? 'supervisor.employee_skills' : 'edit_skills.title_my_skills'}
            values={values}
            withVerticalDivider={!supervisor && showSubheader}
            subheader={supervisor || !showSubheader ? undefined : 'edit_skills.my_skills.subtitle'}
            action={settingsLoaded || employee ? (
              <Box display="flex">
                {settingsLoaded ? (
                  <Box mr={4}>
                    <ViewSwitch
                        tiles
                        value={view}
                        onChange={handleView}
                        disabled={disabled || pendingSettings || pendingSettingsUpdate ? true : undefined}
                    />
                  </Box>
                ) : undefined}
                {employee ? (
                  <ExportSkillsButton
                      supervisor={supervisor}
                      employee={employee}
                      disabled={loading || addPending || updatePending ? true : undefined}
                  />
                ) : undefined}
              </Box>
            ) : undefined}
            withDivider
        />
        <SkillsGrid
            source="my_skills"
            isEmployee={!supervisor}
            readOnly={supervisor}
            supervisor={supervisor}
            plain={reducedUI && !manualOnly && !supervisor ? true : undefined}
            manualOnly={manualOnly}
            withLink={reducedUI && !manualOnly && !supervisor ? true : undefined}
            removeIcon={!reducedUI && !supervisor ? 'inferred' : undefined}
            skills={editableSks}
            failed={failed}
            pending={loading}
            table={view === TABLE_VIEW}
            sortBy={view === TABLE_VIEW ? sort : undefined}
            direction={view === TABLE_VIEW ? dir : undefined}
            changeSort={handleSort}
            sortDisabled={view !== TABLE_VIEW || disabled}
            disabled={disabled}
            filters={
              <>
                <Box pr={3} display="flex" justifyContent="flex-start">
                  <SortSelector
                      variant="skills"
                      value={sort}
                      onChange={handleSort}
                      disabled={disabled}
                      values={SKILL_SORTS}
                      defaultValue={SKILL_SORT_UNDEFINED}
                  />
                </Box>
                {!reducedUI && (
                  <Box pl={2} py={1.25} display="flex" justifyContent="flex-start">
                    <OnOffSwitch
                        label="edit_skills.inferences_only"
                        value={Boolean(inferredOnly)}
                        onChange={handleInferredOnly}
                        disabled={disabled ? true : undefined}
                    />
                  </Box>
                )}
                {showLegend ? (
                  <Box pr={3} display="flex" flexGrow={3}>
                    <SkillsLegend/>
                  </Box>
                ) : undefined}
                {!supervisor &&
                  <Box
                      py={0.25}
                      display="flex"
                      justifyContent="flex-end"
                      flexGrow={1}
                  >
                    <PlusButton
                        label="edit_skills.add_skill"
                        disabled={disabled}
                        pending={addPending ? true : undefined}
                        onClick={handleAddOpen}
                    />
                  </Box>}
              </>
            }
            // TODO: add onItemRemove set `currentPage--` if removed skill is the only one on the current page
            pagination={(
              <PaginationControls
                  settingsId="skills"
                  loaded={Boolean(editableSks)}
                  pending={pending}
                  loading={pendingAll}
                  total={count}
                  totalMessage={supervisor ? 'edit_skills.manager_pagination' : 'edit_skills.pagination'}
                  currentPage={currentPage}
                  onPageSelected={setCurrentPage}
                  onPageSize={setPageSize}
                  disabled={disabled}
              />
            )}
        />
      </ContentCard>
      <ActionFailedAlert
          message="error.fetch_failed"
          open={preferencesFailed}
      />
      <ActionFailedAlert
          message="edit_skills.add_error"
          open={addFailed}
      />
      {!supervisor && (
        <AddSkillDialog
            plain={manualOnly}
            reducedUI={reducedUI ? true : undefined}
            anchorEl={anchorAddBtn}
            onAdd={handleAddSkill}
            onCancel={handleAddClose}
            disabled={disabled}
        />
      )}
    </>
  );
};

MySkills.propTypes = MySkillsPropTypes;

export default memo(MySkills);
