import { gql } from '@apollo/client';
// local imports
import { API_HR_ORG } from '../config/api';

export const HR_IN_DEMAND_SKILLS_QUERY = gql`
  query HRInDemandSkills(
    $org_id: Int!
    $limit: Int
  ) {
    hrInDemandSkills(
      org_id: $org_id,
      limit: $limit
    )
    @rest(
      type: "InDemandSkillsPayload",
      path: "${API_HR_ORG}{args.org_id}/skills/?{args}"
    ) {
      count
      results {
        id
        title
        abbr
        role_type
        deleted_at
      }
    }
  }
`;
