import { forwardRef, memo } from 'react';
// Material UI imports
import Avatar from '@mui/material/Avatar';
// local imports
import GroupsTrendUp from '../icons/GroupsTrendUp';
// SCSS imports
import { avatar } from './DevPlanAvatar.module.scss';

const DevPlanAvatar = forwardRef<HTMLDivElement, {}>((_props, ref) => (
  <Avatar ref={ref} className={avatar}>
    <GroupsTrendUp fontSize="large"/>
  </Avatar>
));

DevPlanAvatar.displayName = 'DevPlanAvatar';

export default memo(DevPlanAvatar);
