import { gql } from '@apollo/client';
// local imports
import { API_HR_CUSTOM_ACTIVITIES } from '../config/api';

export const NEW_CUSTOM_ACTIVITY = gql`
  mutation NewCustomActivity(
    $input: CustomActivityInput!
  ) {
    newCustomActivity(
      input: $input
    )
    @rest(
      type: "SkillActivity",
      path: "${API_HR_CUSTOM_ACTIVITIES}",
      method: "POST"
    )
    # status 201
    {
      id
      code
    }
  }
`;
