import { forwardRef } from 'react';
import { Link as RouterLink, type LinkProps } from 'react-router-dom';
import Typography, { type TypographyProps } from '@mui/material/Typography';

const TypographyLink = forwardRef<HTMLAnchorElement,
  Omit<TypographyProps, 'ref' | 'component'> &
  LinkProps
>((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Typography ref={ref} component={RouterLink} {...props}/>
));

TypographyLink.displayName = 'TypographyLink';

export default TypographyLink;
