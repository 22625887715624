import { memo, useContext, type FunctionComponent } from 'react';
import { Navigate } from 'react-router-dom';
// Material UI imports
import LinearProgress from '@mui/material/LinearProgress';
// local imports
import useUserModel from '../models/user';
import useRedirects from '../helpers/redirect';
import { GlobalContext } from '../context/global';
import LoginRedirect from '../elements/LoginRedirect';
import SplashText from '../elements/SplashText';
import Screen from '../v3/Screen';

const SplashScreen: FunctionComponent = () => {
  const { getDefaultPath } = useRedirects();
  const { hasNoAccess } = useUserModel();

  const { token, user: { data: user, pending, failed } } = useContext(GlobalContext);
  if (pending) return <LinearProgress/>;
  if (!token || !user || failed) return <LoginRedirect/>;
  if (!hasNoAccess(user)) {
    const path = getDefaultPath(user);
    if (path) return <Navigate replace to={path}/>;
  }

  return (
    <Screen appbar="empty" splash>
      <SplashText variant="access_denied"/>
    </Screen>
  );
};

export default memo(SplashScreen);
