import { gql } from '@apollo/client';
// local imports
import { API_DA_SKILL_COURSE } from '../config/api';

export const DA_SKILL_COURSE_MAPPING_QUERY = gql`
  query DASkillCourseMapping(
    $input: DASkillCourseMappingInput!
    $pathBuilder: String!
  ) {
    daSkillCourseMapping(
      input: $input
    )
    @rest(
      type: "DASkillCourseMappingPayload",
      path: "${API_DA_SKILL_COURSE}",
      pathBuilder: $pathBuilder
    ) {
      results {
        date
        total
        overall_total
        all {
          id
          title
          abbr
          total
          date
        }
      }
    }
  }
`;
