import { gql } from '@apollo/client';
// local imports
import { API_DA_EFFECTIVE_ADVISORS } from '../config/api';

export const DA_EFFECTIVE_ADVISORS_QUERY = gql`
  query DAMostEffectiveAdvisors(
    $input: DAMostEffectiveAdvisorsInput!
    $pathBuilder: String!
  ) {
    daMostEffectiveAdvisors(
      input: $input
    )
    @rest(
      type: "DAMostEffectiveAdvisorsPayload",
      path: "${API_DA_EFFECTIVE_ADVISORS}",
      pathBuilder: $pathBuilder
    ) {
      total_employees
      results {
        mentor_id
        mentor_uid
        mentor_first_name
        mentor_last_name
        total_points
        skill_id
        skill_abbr
        skill_title
      }
    }
  }
`;
