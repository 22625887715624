import { gql } from '@apollo/client';
// local imports
import { API_JOBS } from '../config/api';

export const JOB_SEARCH_QUERY = gql`
  query JobSearch(
    $search: String
    $with_skills_only: Boolean
    $offset: Int
    $limit: Int
  ) {
    jobSearch(
      search: $search,
      with_skills_only: $with_skills_only,
      offset: $offset,
      limit: $limit
    )
    @rest(
      type: "JobSearchPayload",
      path: "${API_JOBS}?{args}"
    ) {
      count
      results {
        id
        code
        title
        management_level
        display_level
        is_index_display
        open_reqs_count
        new_open_reqs_count
        is_contractor
      }
    }
  }
`;
