import { gql } from '@apollo/client';
// local imports
import { API_DA_EMPLOYEE_SKILLS } from '../config/api';

export const DA_EMPLOYEE_SKILLS_QUERY = gql`
  query DAEmployeeSkills(
    $input: DAEmployeeSkillsInput!
    $pathBuilder: String!
  ) {
    daEmployeeSkills(
      input: $input
    )
    @rest(
      type: "DAEmployeeSkillsPayload",
      path: "${API_DA_EMPLOYEE_SKILLS}",
      pathBuilder: $pathBuilder
    ) {
      total_employees
      results {
        date
        employee_count
      }
    }
  }
`;
