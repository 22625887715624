import { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
// Material UI imports
import Avatar from '@mui/material/Avatar';
// local imports
import Groups from '../icons/Groups';
// SCSS imports
import { avatar, avatarGrey } from './CohortAvatar.module.scss';

type CohortAvatarProps = {
  grey?: boolean | null;
};

const CohortAvatarPropTypes = {
  grey: PropTypes.bool
};

const CohortAvatar = forwardRef<HTMLDivElement, CohortAvatarProps>(({
  grey = false
}, ref) => (
  <Avatar ref={ref} className={grey ? avatarGrey : avatar}>
    <Groups fontSize="large"/>
  </Avatar>
));

CohortAvatar.displayName = 'CohortAvatar';

CohortAvatar.propTypes = CohortAvatarPropTypes;

export default memo(CohortAvatar);
