import { forwardRef, memo, useCallback, useMemo } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import { type DateTime } from 'luxon';
import { useIntl } from 'react-intl';
// Material UI imports
import { DatePicker as MuiDatePicker, type DatePickerSlotProps } from '@mui/x-date-pickers/DatePicker';
// SCSS imports
import { root } from '../styles/modules/Lookup.module.scss';
import { openPickerButton } from './DatePicker.module.scss';

const openPickerButtonProps = { className: openPickerButton };

type DatePickerProps = {
  value?: DateTime | null;
  onChange?: (date: DateTime) => void;
  error?: boolean;
  helperText?: string;
  placeholder?: string;
  disabled?: boolean | null;
  disablePast?: boolean;
  disableFuture?: boolean;
  minDate?: DateTime | null;
  maxDate?: DateTime | null;
}

const DatePickerPropTypes = {
  // attributes
  value: PropTypes.object as Validator<DateTime>,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  disablePast: PropTypes.bool,
  disableFuture: PropTypes.bool,
  minDate: PropTypes.object as Validator<DateTime>,
  maxDate: PropTypes.object as Validator<DateTime>
};

const DatePicker = forwardRef<HTMLDivElement, DatePickerProps>(({
  value = null,
  onChange,
  error,
  helperText,
  placeholder,
  disabled = false,
  disablePast,
  disableFuture,
  minDate,
  maxDate
}, ref) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();

  const handleChange = useCallback((date: unknown) => {
    if (onChange) onChange(date as DateTime);
  }, [onChange]);

  const slotProps = useMemo(() => ({
    textField: {
      size: 'small',
      error,
      helperText,
      variant: 'outlined',
      // TODO: remove temporary workaround for screen reader accessibility:
      inputProps: { type: undefined, placeholder: undefined }
    },
    openPickerButton: openPickerButtonProps
  } as DatePickerSlotProps<DateTime, boolean>), [error, helperText]);

  return (
    <MuiDatePicker
        ref={ref}
        label={placeholder}
        slotProps={slotProps}
        value={value}
        format={formatMessage({ id: 'date_picker.date_format' })}
        onChange={handleChange}
        disabled={disabled ? true : undefined}
        disablePast={disablePast}
        disableFuture={disableFuture}
        minDate={minDate || undefined}
        maxDate={maxDate || undefined}
        className={root}
    />
  );
});

DatePicker.displayName = 'DatePicker';

DatePicker.propTypes = DatePickerPropTypes;

export default memo(DatePicker);
