import size from 'lodash/size';
import trim from 'lodash/trim';
import replace from 'lodash/replace';
import toLower from 'lodash/toLower';
import toString from 'lodash/toString';
import { stringify, IStringifyOptions } from 'qs';

export const QUERY_STRING_OPTIONS: IStringifyOptions = {
  arrayFormat: 'comma',
  skipNulls: true
};

// encode URL param for API call
export function encodeURLParam(value?: string | number | null): string {
  return encodeURIComponent(toLower(trim(toString(value))));
}

// inject parameters to path string
const injectRegexp = /:(\w+)/gu;
export const injectParams = (
  path: string,
  params: Record<string, string | number | null | undefined>,
  query?: Record<string, string | number | string[] | number[] | null | undefined>
) => {
  const uri = replace(path, injectRegexp, (_match, p1) => encodeURLParam(params[p1]));
  return query && size(query) >= 1 ? `${uri}?${stringify(query, QUERY_STRING_OPTIONS)}` : uri;
};
