import { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Avatar from '@mui/material/Avatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
// Material Icon imports
import ArrowRightBold from 'mdi-material-ui/ArrowRightBold';
import CheckBold from 'mdi-material-ui/CheckBold';
// local imports
import { PATHS_MY_PROFILE_BUILDER } from '../config/paths';
// SCSS imports
import { listItem, checkedItem, completedStep, pendingStep } from './GettingStartedItem.module.scss';

type GettingStartedItemProps = {
  hasSkills: boolean;
  step: number;
  completed?: boolean;
};

const GettingStartedItemPropTypes = {
  hasSkills: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired,
  completed: PropTypes.bool
};

const GettingStartedItem = forwardRef<HTMLAnchorElement, GettingStartedItemProps>(({
  hasSkills,
  step,
  completed = false
}, ref) => (
  <ListItemButton
      ref={ref}
      component={RouterLink}
      to={PATHS_MY_PROFILE_BUILDER[step - 1]}
      className={completed ? checkedItem : listItem}
  >
    <ListItemAvatar>
      <Avatar
          variant="circular"
          className={completed ? completedStep : pendingStep}
      >
        {completed
        ? <CheckBold color="inherit"/>
        : <ArrowRightBold color="inherit"/>}
      </Avatar>
    </ListItemAvatar>
    <ListItemText
        disableTypography
        primary={<FormattedMessage id={`board.step${step}`} values={{ has_skills: hasSkills }}/>}
    />
  </ListItemButton>
));

GettingStartedItem.displayName = 'GettingStartedItem';

GettingStartedItem.propTypes = GettingStartedItemPropTypes;

export default memo(GettingStartedItem);
