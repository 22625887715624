import { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
// EmPath UI Components
import CardDeck from '@empathco/ui-components/src/elements/CardDeck';
// local imports
import { Skill } from '../models/skill';
import CheckedSkillCard from '../v3/CheckedSkillCard';

type CheckedSkillsGridProps = {
  skills?: Skill[] | null;
  onClick?: (id: number) => void;
  checked?: boolean;
  disabled?: boolean;
}

const CheckedSkillsGridPropTypes = {
  // attributes
  skills: PropTypes.array,
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool
};

const CheckedSkillsGrid = forwardRef<HTMLDivElement, CheckedSkillsGridProps>(({
  skills,
  checked = false,
  onClick,
  disabled = false
}, ref) => (
  <CardDeck ref={ref}>
    {map(skills, (skill) => (
      <CheckedSkillCard
          key={skill.id}
          skill={skill}
          checked={checked}
          onChange={onClick}
          disabled={disabled}
      />
    ))}
  </CardDeck>
));

CheckedSkillsGrid.displayName = 'CheckedSkillsGrid';

CheckedSkillsGrid.propTypes = CheckedSkillsGridPropTypes;

export default memo(CheckedSkillsGrid);
