import { makeVar } from '@apollo/client';
// EmPath UI Components
import { TILES_VIEW, type ViewType } from '@empathco/ui-components/src/elements/ViewSwitch';
// local imports
import { JobSort, DEFAULT_SORT_DIRECTION, JOB_SORT_MATCH_RATE } from '../constants/jobSort';
import { DevPlanEmployeesSort, DEFAULT_DPE_SORT_DIRECTION } from '../constants/talentFinder';
import { EmployeeSkillsQueryVariables, RedeploymentSort, SortDirection } from '../graphql/types';
import { JobsIndexFilterValues } from '../v3/JobsIndexFilters';

export const employeeSkillsViewVar = makeVar<ViewType>(TILES_VIEW);
export const employeeSkillsVar = makeVar<EmployeeSkillsQueryVariables>({});

export const devPlanViewVar = makeVar<ViewType>(TILES_VIEW);

export const devPlanSortVar = makeVar<{
  sortOrder: DevPlanEmployeesSort;
  direction: SortDirection;
}>({
  sortOrder: DevPlanEmployeesSort.match_rate,
  direction: DEFAULT_DPE_SORT_DIRECTION[DevPlanEmployeesSort.match_rate]
});

export const redeploymentSortVar = makeVar<{
  sortOrder: RedeploymentSort;
  direction: SortDirection;
}>({
  sortOrder: RedeploymentSort.employee,
  direction: DEFAULT_DPE_SORT_DIRECTION[RedeploymentSort.employee]
});

export const redeploymentJobsVar = makeVar<JobsIndexFilterValues | undefined>(undefined);
export const redeploymentJobsSortVar = makeVar<{
  sortOrder: JobSort;
  direction: boolean;
}>({
  sortOrder: JOB_SORT_MATCH_RATE,
  direction: DEFAULT_SORT_DIRECTION[JOB_SORT_MATCH_RATE]
});
