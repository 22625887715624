import { gql } from '@apollo/client';
// local imports
import { API_DEV_PLAN } from '../config/api';

export const UPDATE_DEV_PLAN = gql`
  mutation UpdateDevPlan(
    $devplan_id: Int!
    $input: UpdateDevPlanInput!
  ) {
    updateDevPlan(
      devplan_id: $devplan_id,
      input: $input
    )
    @rest(
      type: "DevPlan",
      path: "${API_DEV_PLAN}",
      method: "PATCH"
    ) {
      id
      # title
      # created_at
      # updated_at
    }
  }
`;
