/* eslint-disable max-lines */
import { memo, useEffect, useState, useCallback, useMemo, useContext, type FunctionComponent, useLayoutEffect } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import size from 'lodash/size';
import find from 'lodash/find';
import trim from 'lodash/trim';
import omit from 'lodash/omit';
import isNil from 'lodash/isNil';
import isBoolean from 'lodash/isBoolean';
import { useApolloClient, useLazyQuery, useMutation, type ApolloCache } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
// Material UI imports
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
// EmPath UI Components
import { type GetComponentProps } from '@empathco/ui-components/src/helpers/types';
import { isEmptyString } from '@empathco/ui-components/src/helpers/strings';
import { paramsDiffer } from '@empathco/ui-components/src/helpers/pagination';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import useMutationMethod from '@empathco/ui-components/src/hooks/useMutationMethod';
import PlusButton from '@empathco/ui-components/src/elements/PlusButton';
import OnOffSwitch from '@empathco/ui-components/src/elements/OnOffSwitch';
import ViewSwitch, { TILES_VIEW, TABLE_VIEW, type ViewType } from '@empathco/ui-components/src/elements/ViewSwitch';
import FilterSelector from '@empathco/ui-components/src/elements/FilterSelector';
import FilterSearch from '@empathco/ui-components/src/elements/FilterSearch';
import CardFooter from '@empathco/ui-components/src/elements/CardFooter';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import SkillsGapLegend from '@empathco/ui-components/src/elements/SkillsGapLegend';
// local imports
import { COUNTRIES_QUERY } from '../graphql/Countries';
import { STATES_QUERY } from '../graphql/States';
import { OPPORTUNITIES_QUERY } from '../graphql/Opportunities';
import { MY_OPPORTUNITIES_BROWSE_QUERY } from '../graphql/MyOpportunitiesBrowse';
import { MY_OPPORTUNITIES_QUERY } from '../graphql/MyOpportunities';
import { MY_OPPORTUNITY_QUERY } from '../graphql/MyOpportunity';
import { OPPORTUNITY_OWNERS_QUERY } from '../graphql/OpportunityOwners';
import { UPDATE_MY_OPPORTUNITY } from '../graphql/UpdateMyOpportunity';
import { UPDATE_OPPORTUNITY_BOOKING } from '../graphql/UpdateOpportunityBooking';
import { NEW_OPPORTUNITY_BOOKING } from '../graphql/NewOpportunityBooking';
import { UPDATE_OPPORTUNITY_STATUS } from '../graphql/UpdateOpportunityStatus';
import {
  LookupItem, State,
  OpportunitiesSortOrder, MyOpportunitiesSortOrder, SortDirection, OpportunityStatus, MyOpportunityStatus, BookingStatus,
  CountriesDocument, StatesDocument,
  OpportunitiesDocument, OpportunitiesQueryVariables,
  MyOpportunitiesBrowseDocument, MyOpportunitiesBrowseQuery, MyOpportunitiesBrowseQueryVariables,
  MyOpportunitiesDocument, MyOpportunitiesQuery, MyOpportunitiesQueryVariables,
  OpportunityOwnersDocument,
  UpdateMyOpportunityDocument, NewOpportunityBookingDocument, UpdateOpportunityBookingDocument, UpdateOpportunityStatusDocument
} from '../graphql/types';
import {
  OpportunityPost, MyOpportunity, OpportunityOwner,
  OPPORTUNITIES_ORDER, MY_OPPORTUNITIES_ORDER, DEFAULT_OPPORTUNITIES_DIRECTION
} from '../graphql/customTypes';
import { MAX_COUNTRIES_OPTIONS, MAX_ITEMS } from '../config/params';
import { PATH_MY_OPPORTUNITIES_BROWSE, PATH_SUPERVISOR_OPPORTUNITY_NEW } from '../config/paths';
import { updateCachedResult, updateCachedResults } from '../helpers/graphql';
import useModels, { sanitizeLookup } from '../helpers/models';
import { GlobalContext } from '../context/global';
import { SkillLevelData } from '../context/dataContext';
import { DataContext } from '../context';
import LargeButton from '../elements/LargeButton';
import SortSelector from '../elements/SortSelector';
import OpportunityCard from '../v3/OpportunityCard';
import CardsGrid from '../v3/CardsGrid';
import PaginationControls from '../v3/PaginationControls';
import OpportunitiesTable from '../v3/OpportunitiesTable';
import MyOpportunityDialog from '../widgets/MyOpportunityDialog';
import OpportunityBookingPanel from './OpportunityBookingPanel';
// SCSS imports
import { footer } from './OpportunitiesPanel.module.scss';

const legendSx = { py: 1.25 };

const NO_OWNERS = [] as OpportunityOwner[];
const NO_LOOKUP_ITEMS = [] as LookupItem[];

const updateMyOpportunitiesCache = (cache: ApolloCache<unknown>) => {
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'myOpportunity' });
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'myOpportunities' });
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'myOpportunitiesBrowse' });
};

type OpportunitiesPanelProps = {
  supervisor?: boolean;
  myOpportunities?: boolean;
  opportunityId?: number | null;
  // for Storybook only
  viewAs?: ViewType;
  testAction?: number;
}

const OpportunitiesPanelPropTypes = {
  supervisor: PropTypes.bool,
  myOpportunities: PropTypes.bool,
  opportunityId: PropTypes.number,
  viewAs: PropTypes.string as Validator<ViewType>,
  testAction: PropTypes.number
};

// eslint-disable-next-line max-lines-per-function, max-statements, complexity
const OpportunitiesPanel: FunctionComponent<OpportunitiesPanelProps> = ({
  supervisor,
  myOpportunities,
  opportunityId: parentOppId,
  viewAs,
  testAction
}) => {
  const navigate = useNavigate();
  const { cache: apolloCache } = useApolloClient();
  const { isDomesticCountryId } = useModels();

  const moreFilters = !supervisor && !myOpportunities;

  const { user: { data: user } } = useContext(GlobalContext);
  const { id: user_id } = user || {};
  const { skillsUpdate: { pending: skillsUpdatePending, failed: skillsUpdateFailed }, updateSkills } = useContext(DataContext);

  // lazy load countires
  const { query: getCountries, pending: pendingCountries, failed: failedCountries, results: countriesData } = useQueryCounted({
    data: undefined as unknown as LookupItem,
    key: 'countries',
    lazyQuery: useLazyQuery(COUNTRIES_QUERY as typeof CountriesDocument)
  });
  // lazy load states
  const { query: getStates, pending: pendingStates, results: statesData } = useQueryCounted({
    data: undefined as unknown as State,
    key: 'states',
    lazyQuery: useLazyQuery(STATES_QUERY as typeof StatesDocument)
  });

  const countries = (failedCountries && NO_LOOKUP_ITEMS) || (pendingCountries ? null : countriesData);
  const states = pendingStates ? null : statesData;

  const [opportunityId, setOpportunityId] = useState<number | null | undefined>(parentOppId);
  useLayoutEffect(() => {
    setOpportunityId(parentOppId);
  }, [parentOppId]);

  const [hideArchived, setHideArchived] = useState(false);
  const [hideApplications, setHideApplications] = useState(false);
  const [publishedOnly, setPublishedOnly] = useState(false);

  const [search, setSearch] = useState('');
  useLayoutEffect(() => {
    // reset search filter on tab change
    setSearch('');
    // reload opportunities on first mount or tab change
    updateMyOpportunitiesCache(apolloCache);
    apolloCache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunities' });
    apolloCache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunity' });
    apolloCache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunityMatches' });
    apolloCache.evict({ id: 'ROOT_QUERY', fieldName: 'opportunityBookings' });
}, [supervisor, myOpportunities, apolloCache]);

  const [owner, setOwner] = useState<number>(0);
  const [country, setCountry] = useState<number>(0);
  const [state, setState] = useState<number>(0);
  const [view, setView] = useState<ViewType>(isNil(viewAs) ? TILES_VIEW : viewAs);

  const [defaultSort] = supervisor ? OPPORTUNITIES_ORDER : MY_OPPORTUNITIES_ORDER;

  const isDomestic = useMemo(() => isDomesticCountryId(country, countries), [countries, country, isDomesticCountryId]);

  // eslint-disable-next-line react/hook-use-state
  const [{ sort, dir }, setSort] = useState<{
    sort: OpportunitiesSortOrder | MyOpportunitiesSortOrder;
    dir: boolean;
  }>({
    sort: defaultSort,
    dir: DEFAULT_OPPORTUNITIES_DIRECTION[defaultSort]
  });
  useLayoutEffect(() => {
    setSort({ sort: defaultSort, dir: DEFAULT_OPPORTUNITIES_DIRECTION[defaultSort] });
  }, [defaultSort]);

  const handleSort = useCallback((sortValue?: OpportunitiesSortOrder | MyOpportunitiesSortOrder, newDir?: boolean | null) => {
    if (sortValue) setSort({
      sort: sortValue,
      dir: isBoolean(newDir) ? newDir : DEFAULT_OPPORTUNITIES_DIRECTION[sortValue]
    });
  }, []);

  // lazy load opportunity owners
  const { query: getOwners, pending: ownersPending, failed: ownersFailed, results: ownersResults } = useQueryCounted({
    data: undefined as unknown as OpportunityOwner,
    key: 'opportunityOwners',
    lazyQuery: useLazyQuery(OPPORTUNITY_OWNERS_QUERY as typeof OpportunityOwnersDocument)
  });
  const owners = (ownersFailed && NO_OWNERS) || (ownersPending ? null : ownersResults);

  // lazy load opportunities
  const supvOpportunities = useQueryCounted({
    data: undefined as unknown as OpportunityPost,
    key: 'opportunities',
    lazyQuery: useLazyQuery(OPPORTUNITIES_QUERY as typeof OpportunitiesDocument)
  });
  const myOpportunitiesOnly = useQueryCounted({
    data: undefined as unknown as MyOpportunity,
    key: 'myOpportunities',
    lazyQuery: useLazyQuery(MY_OPPORTUNITIES_QUERY as typeof MyOpportunitiesDocument)
  });
  const myOpportunitiesBrowse = useQueryCounted({
    data: undefined as unknown as MyOpportunity,
    key: 'myOpportunitiesBrowse',
    lazyQuery: useLazyQuery(MY_OPPORTUNITIES_BROWSE_QUERY as typeof MyOpportunitiesBrowseDocument)
  });
  const {
    query: getOpportunities, pending: opportunitiesPending, failed, count, results: opportunities, variables: prevVars
  } = (supervisor && supvOpportunities) || (myOpportunities ? myOpportunitiesOnly : myOpportunitiesBrowse);
  const pending = (moreFilters && (ownersPending || pendingCountries || (isDomestic && pendingStates))) ||
    opportunitiesPending || testAction === 0;

  const [updatingId, setUpdatingId] = useState<number>();
  const [removingId, setRemovingId] = useState<number>();
  const [rejectingId, setRejectingId] = useState<number>();
  const [confirmingId, setConfirmingId] = useState<number>();

  const { mutate: saveOpportunity, loading: savePending, failed: saveFailed } = useMutationMethod({
    // TODO: key: 'updateMyOpportunity', -- restore when backend responds with `success: true`
    mutation: useMutation(UPDATE_MY_OPPORTUNITY as typeof UpdateMyOpportunityDocument)
  });
  const { mutate: updateApplication, loading: updatePending, failed: updateFailed } = useMutationMethod({
    // TODO: key: 'updateOpportunityBooking', -- restore when backend responds with `success: true`
    mutation: useMutation(UPDATE_OPPORTUNITY_BOOKING as typeof UpdateOpportunityBookingDocument)
  });
  const { mutate: newApplication, loading: applicationPending, failed: applicationFailed } = useMutationMethod({
    // TODO: key: 'newOpportunityBooking', -- restore when backend responds with `success: true`
    mutation: useMutation(NEW_OPPORTUNITY_BOOKING as typeof NewOpportunityBookingDocument)
  });
  const { mutate: updateFeedback, loading: feedbackPending, failed: feedbackFailed } = useMutationMethod({
    // TODO: key: 'updateOpportunityBooking', -- restore when backend responds with `success: true`
    mutation: useMutation(UPDATE_OPPORTUNITY_BOOKING as typeof UpdateOpportunityBookingDocument)
  });
  const { mutate: updateOppStatus, loading: oppStatusPending, failed: oppStatusFailed } = useMutationMethod({
    // TODO: key: 'updateOpportunityStatus', // -- restore when backend responds with `success: true`
    mutation: useMutation(UPDATE_OPPORTUNITY_STATUS as typeof UpdateOpportunityStatusDocument)
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>();

  useEffect(() => {
    if (moreFilters) getOwners?.({ variables: { limit: MAX_ITEMS } });
  }, [moreFilters, getOwners]);

  useEffect(() => {
    if (moreFilters) getCountries?.({ variables: { limit: MAX_COUNTRIES_OPTIONS } });
  }, [moreFilters, getCountries]);

  useEffect(() => {
    if (moreFilters && isDomestic && country) getStates?.({ variables: {
      country_id: country,
      limit: MAX_COUNTRIES_OPTIONS
    } });
  }, [country, isDomestic, moreFilters, getStates]);

  useEffect(() => {
    if (pageSize && getOpportunities) {
      const variables: OpportunitiesQueryVariables | MyOpportunitiesQueryVariables | MyOpportunitiesBrowseQueryVariables = {
        ...isEmptyString(search) ? {} : { search: trim(search) },
        ...publishedOnly && !myOpportunities ? { [supervisor ? 'published_only' : 'hide_shortlist']: true } : {},
        ...hideArchived && (supervisor || myOpportunities) ? { hide_archived: true } : {},
        ...moreFilters && hideApplications ? { hide_booking: true } : {},
        ...moreFilters && owner ? { opportunity_owner_id: owner } : {},
        ...moreFilters && country ? { country_id: country } : {},
        ...moreFilters && state ? { state_id: state } : {},
        direction: dir ? SortDirection.ascending : SortDirection.descending,
        sort_by: sort,
        limit: pageSize
      };
      let curPage = currentPage;
      if (paramsDiffer(prevVars, variables)) {
        curPage = 1;
        setCurrentPage(1);
      }
      variables.offset = pageSize * (curPage - 1);
      getOpportunities({ variables: variables as OpportunitiesQueryVariables & MyOpportunitiesBrowseQueryVariables });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // ignoring `prevVars` changes:
    publishedOnly, hideArchived, hideApplications, search, owner, country, state, sort, dir, currentPage, pageSize,
    supervisor, myOpportunities, moreFilters, getOpportunities
  ]);

  const handleOwner = useCallback((value: number) => {
    const val = sanitizeLookup(value, owners as LookupItem[]);
    setOwner(val);
  }, [owners]);

  const handleCountry = useCallback((value: number) => {
    const val = sanitizeLookup(value, countries);
    setCountry(val);
  }, [countries]);

  const handleState = useCallback((value: number) => {
    const val = sanitizeLookup(value, states);
    setState(val);
  }, [states]);

  // eslint-disable-next-line complexity
  const handleSave = useCallback((opp?: MyOpportunity, doApply = false, statusUpdate: BookingStatus | null = null) => {
    const opp_id = opp?.id;
    const opportunity_id = opp?.opportunity?.id;
    if (opp_id && opportunity_id) {
      if (doApply) {
        const { id: owner_id } = opp.opportunity?.owner || {};
        const { id: booking_id, employee_id, status } = opp?.booking || {};
        if (status && (
          status === BookingStatus.deleted ||
          status === BookingStatus.confirmed ||
          status === BookingStatus.manager_rejected
        )) return;
        if (booking_id && (statusUpdate || employee_id === user_id)) {
          const newStatus = statusUpdate || (
            !status || status === BookingStatus.employee_rejected
              ? BookingStatus.employee_requested
              : BookingStatus.employee_rejected
          );
          if (statusUpdate === BookingStatus.confirmed) setConfirmingId(opp_id);
          else if (statusUpdate === BookingStatus.employee_rejected) setRejectingId(opp_id);
          else {
            setUpdatingId(opp_id);
            setRemovingId(opp_id);
          }
          updateApplication?.({
            variables: { opportunity_id, booking_id, input: { status: newStatus } },
            onCompleted: () => {
              setUpdatingId(undefined);
              setRemovingId(undefined);
              setRejectingId(undefined);
              setConfirmingId(undefined);
            },
            onError: () => {
              setUpdatingId(undefined);
              setRemovingId(undefined);
              setRejectingId(undefined);
              setConfirmingId(undefined);
            },
            // TODO: optimistic response
            update: updateMyOpportunitiesCache
          });
        } else {
          if (!user_id || !owner_id) return;
          setUpdatingId(opp_id);
          newApplication?.({
            variables: { opportunity_id, input: {
              manager: owner_id,
              status: BookingStatus.employee_requested,
              employee_id: user_id
            } },
            onCompleted: () => setUpdatingId(undefined),
            onError: () => setUpdatingId(undefined),
            // TODO: optimistic response?
            update: updateMyOpportunitiesCache
          });
        }
      } else {
        const { employee_status } = opp || {};
        if (employee_status === MyOpportunityStatus.hidden) return;
        const newStatus = !employee_status || employee_status !== MyOpportunityStatus.shortlist
          ? MyOpportunityStatus.shortlist : MyOpportunityStatus.active;
        setUpdatingId(opp_id);
        saveOpportunity?.({
          variables: { opportunity_id: opp_id, input: { employee_status: newStatus } },
          onCompleted: () => setUpdatingId(undefined),
          onError: () => setUpdatingId(undefined),
          optimisticResponse: { updateMyOpportunity: { success: true, optimistic: true, __typename: 'MutationResponse' } },
          update: (cache, result) => {
            if (result.data?.updateMyOpportunity?.optimistic) {
              cache.updateQuery(
                { query: MY_OPPORTUNITY_QUERY, variables: { opportunity_id: opp_id } },
                (data: MyOpportunity | null) => updateCachedResult(data, 'employee_status', () => newStatus)
              );
              cache.updateQuery({
                query: MY_OPPORTUNITIES_QUERY, variables: prevVars
              }, (data: MyOpportunitiesQuery | null) => updateCachedResults(
                data, 'myOpportunities',
                (myOpp: MyOpportunity) => opp_id === myOpp.id ? { ...myOpp, employee_status: newStatus } : myOpp
              ));
              cache.updateQuery({
                query: MY_OPPORTUNITIES_BROWSE_QUERY, variables: prevVars
              }, (data: MyOpportunitiesBrowseQuery | null) => updateCachedResults(
                data, 'myOpportunitiesBrowse',
                (myOpp: MyOpportunity) => opp_id === myOpp.id ? { ...myOpp, employee_status: newStatus } : myOpp
              ));
            } else {
              updateMyOpportunitiesCache(cache);
            }
          }
        });
      }
    }
  }, [prevVars, user_id, newApplication, saveOpportunity, updateApplication]);

  const handleBooking = useCallback((opp?: MyOpportunity, reject?: boolean) => {
    handleSave(opp, true, reject ? BookingStatus.employee_rejected : BookingStatus.confirmed);
  }, [handleSave]);

  const handleApply = useCallback((opp?: MyOpportunity) => {
    handleSave(opp, true);
  }, [handleSave]);

  const handleEndOpportunity = useCallback((opp: MyOpportunity) => {
    setOpportunityId(opp.id);
  }, []);

  const handleEndOpportunityConfirm = useCallback((
    opp?: MyOpportunity,
    employee_feedback?: string,
    skills?: SkillLevelData[]
  ) => {
    if (opp) {
      if (employee_feedback && opp.booking?.id) updateFeedback?.({
        variables: {
          opportunity_id: opp.opportunity.id,
          booking_id: opp.booking.id,
          input: { employee_feedback }
        },
        // TODO: optimistic response
        update: updateMyOpportunitiesCache
      });
      if (skills && size(skills) >= 1) updateSkills?.({
        apolloCache,
        levels: skills
      });
      updateOppStatus?.({
        variables: { opportunity_id: opp.opportunity.id, input: { status: OpportunityStatus.archived } },
        // TODO: optimistic response
        update: updateMyOpportunitiesCache
      });
    }
  }, [updateSkills, updateFeedback, updateOppStatus, apolloCache]);

  const handleClose = useCallback(() => {
    if (parentOppId) navigate(PATH_MY_OPPORTUNITIES_BROWSE);
    else setOpportunityId(undefined);
  }, [parentOppId, navigate]);

  const componentProps: Partial<GetComponentProps<typeof OpportunityCard>> = useMemo(() => ({
    supervisor,
    onSave: handleSave,
    savingId: updatingId
  }), [updatingId, handleSave, supervisor]);

  const selectedOpportunity = useMemo(
    () => supervisor ? undefined : find(opportunities, ['id', opportunityId]) as MyOpportunity | undefined,
    [opportunityId, opportunities, supervisor]
  );

  const disabled = pending || (supervisor ? false : savePending || applicationPending || updatePending ||
    feedbackPending || skillsUpdatePending || oppStatusPending);

  // for Storybook & Jest-snapshots testing only
  useEffect(() => {
    if (testAction) {
      if (testAction === 1 || testAction === 6) {
        setUpdatingId(testAction === 1 ? 112 : 223);
        saveOpportunity?.({
          variables: { opportunity_id: 112, input: { employee_status: MyOpportunityStatus.shortlist } },
          onError: () => setUpdatingId(undefined)
        });
      } else if (testAction === 2) {
        setUpdatingId(112);
        newApplication?.({ variables: { opportunity_id: 112, input: {
          status: BookingStatus.employee_requested, manager: 1, employee_id: 1
        } }, onError: () => setUpdatingId(undefined) });
      } else if (testAction === 3) {
        setUpdatingId(112);
        setRemovingId(556);
        updateApplication?.({ variables: { opportunity_id: 112, booking_id: 556, input: {
          status: BookingStatus.employee_requested
        } }, onError: () => {
          setUpdatingId(undefined);
          setRemovingId(undefined);
      } });
      } else if (testAction === 4) {
        setConfirmingId(445);
        updateApplication?.({ variables: { opportunity_id: 4441, booking_id: 445, input: {
          status: BookingStatus.confirmed
        } }, onError: () => setConfirmingId(undefined) });
      } else if (testAction === 5) {
        setRejectingId(445);
        updateApplication?.({ variables: { opportunity_id: 4441, booking_id: 445, input: {
          status: BookingStatus.employee_rejected
        } }, onError: () => setRejectingId(undefined) });
      }
    }
  }, [testAction, saveOpportunity, newApplication, updateApplication]);

  const reloading = Boolean(pending && opportunities);
  const newScreen = supervisor && (!pending || reloading) && !failed && size(opportunities) < 1 &&
    size(omit(prevVars, ['direction', 'limit', 'offset', 'sort_by'])) < 1;

  const filters = newScreen ? undefined : (
    <>
      <Box pr={2} py={0.5} display="flex" justifyContent="flex-start">
        <FilterSearch
            type="opportunity"
            value={search}
            onChange={setSearch}
            disabled={disabled}
        />
      </Box>
      {moreFilters ? (
        <>
          <Box pr={2} py={0.5} display="flex" justifyContent="flex-start">
            <FilterSelector
                type="opportunity_owner"
                choices={owners as LookupItem[]}
                value={owner}
                onChange={handleOwner}
                disabled={disabled}
            />
          </Box>
          <Box pr={2} py={0.5} display="flex" justifyContent="flex-end">
            <FilterSelector
                type="country"
                choices={countries}
                value={country}
                onChange={handleCountry}
                disabled={disabled}
            />
          </Box>
          <Box pr={2} py={0.5} display="flex" justifyContent="flex-end">
            <FilterSelector
                type="state"
                choices={isDomestic ? states : undefined}
                value={isDomestic ? state : 0}
                onChange={handleState}
                disabled={disabled || !isDomestic}
            />
          </Box>
        </>
      ) : undefined}
      <Box pr={3} py={0.5} display="flex" justifyContent="flex-start">
        <SortSelector
            variant="opportunities"
            value={sort}
            onChange={handleSort}
            disabled={disabled}
            values={supervisor ? OPPORTUNITIES_ORDER : MY_OPPORTUNITIES_ORDER}
            defaultValue={defaultSort}
        />
      </Box>
      {moreFilters ? (
        <Box pl={2} py={1.25} display="flex" justifyContent="flex-start">
          <OnOffSwitch
              label="opportunities.hide_applications"
              value={Boolean(hideApplications)}
              onChange={setHideApplications}
              disabled={disabled}
          />
        </Box>
      ) : undefined}
      {myOpportunities ? undefined : (
        <Box pl={2} py={1.25} display="flex" justifyContent="flex-start">
          <OnOffSwitch
              label={supervisor ? 'opportunities.published_only' : 'opportunities.hide_shortlist'}
              value={Boolean(publishedOnly)}
              onChange={setPublishedOnly}
              disabled={disabled}
          />
        </Box>
      )}
      {supervisor || myOpportunities ? (
        <Box pl={2} py={1.25} display="flex" justifyContent="flex-start">
          <OnOffSwitch
              label={supervisor ? 'opportunities.hide_archived' : 'opportunities.hide_ended'}
              value={Boolean(hideArchived)}
              onChange={setHideArchived}
              disabled={disabled}
          />
        </Box>
      ) : undefined}
      {myOpportunities ? <SkillsGapLegend withGrowth sx={legendSx}/> : undefined}
      <Box
          pl={2}
          py={supervisor ? 0.25 : 1.25}
          display="flex"
          justifyContent="flex-end"
          flexGrow={1}
      >
        {supervisor ? (
          <PlusButton
              label="opportunities.add"
              disabled={disabled}
              link={PATH_SUPERVISOR_OPPORTUNITY_NEW}
          />
        ) : !myOpportunities && (
          <ViewSwitch
              tiles
              value={view}
              onChange={setView}
              disabled={disabled}
          />
        )}
      </Box>
    </>
  );

  const pagination = newScreen || myOpportunities ? undefined : (
    <PaginationControls
        settingsId={supervisor ? 'opportunities' : 'my_opp_browse'}
        loaded={Boolean(opportunities)}
        pending={pending}
        loading={pending}
        total={count}
        currentPage={currentPage}
        onPageSelected={setCurrentPage}
        onPageSize={setPageSize}
        disabled={disabled || failed}
        totalMessage="opportunities.pagination"
    />
  );

  return (
    <>
      {(newScreen && (
        <>
          {reloading ? <LinearProgress/> : undefined}
          <CardFooter className={footer}>
            <LargeButton
                title="opportunities.new"
                variant="plus"
                link={PATH_SUPERVISOR_OPPORTUNITY_NEW}
                disabled={disabled}
            />
          </CardFooter>
        </>
      )) || (myOpportunities && (
        <OpportunityBookingPanel
            bookings={opportunities as MyOpportunity[]}
            count={count}
            pending={pending}
            failed={failed}
            disabled={disabled}
            confirmingId={confirmingId}
            rejectingId={rejectingId}
            removingId={removingId}
            onBookingRequest={handleBooking}
            onRemoveApplication={handleApply}
            onEndOpportunity={handleEndOpportunity}
            filters={filters}
            currentPage={currentPage}
            onPageSelected={setCurrentPage}
            onPageSize={setPageSize}
        />
      )) || (!supervisor && view === TABLE_VIEW ? (
        <OpportunitiesTable
            opportunities={opportunities as MyOpportunity[]}
            sortOrder={sort as MyOpportunitiesSortOrder}
            direction={dir}
            onSort={handleSort}
            pending={pending}
            savingId={updatingId}
            failed={failed}
            disabled={disabled}
            notFoundMessage="opportunities.empty"
            filters={filters}
            pagination={pagination}
            onSave={handleSave}
        />
      ) : (
        <CardsGrid
            items={opportunities as OpportunityPost[] & MyOpportunity[]}
            variant="white"
            blendFilters={reloading}
            withReloading
            pending={pending}
            failed={failed}
            disabled={disabled}
            component={OpportunityCard}
            ComponentProps={componentProps}
            notFoundMessage="opportunities.empty"
            filters={filters}
            pagination={pagination}
        />
      ))}
      {supervisor ? undefined : (
        <>
          <MyOpportunityDialog
              isOpen={Boolean(opportunityId)}
              opportunityId={opportunityId}
              opportunity={selectedOpportunity}
              pending={opportunitiesPending}
              disabled={!updateSkills}
              savePending={savePending}
              applyPending={applicationPending || updatePending || feedbackPending || skillsUpdatePending || oppStatusPending}
              onSave={handleSave}
              onApply={handleApply}
              onEnd={handleEndOpportunityConfirm}
              onClose={handleClose}
          />
          <ActionFailedAlert
              message="opportunities.save.error"
              open={saveFailed}
          />
          <ActionFailedAlert
              message="opportunities.apply.error"
              open={applicationFailed || updateFailed}
          />
          <ActionFailedAlert
              message="opportunities.booking.update.error"
              open={feedbackFailed}
          />
          <ActionFailedAlert
              message="edit_skills.submit_error"
              open={skillsUpdateFailed}
          />
          <ActionFailedAlert
              message="opportunities.update.error"
              open={oppStatusFailed}
          />
        </>
      )}
    </>
  );
};

OpportunitiesPanel.propTypes = OpportunitiesPanelPropTypes;

export default memo(OpportunitiesPanel);
