import { RedeploymentSort, SortDirection } from '../graphql/types';

export const REDEPLOYMENT_SORT_DIRECTIONS = [
  RedeploymentSort.employee,
  RedeploymentSort.location,
  RedeploymentSort.manager,
  // These two sort orders cause employees order change while editing redeployment plan:
  // RedeploymentSort.job_title,
  // RedeploymentSort.match_rate,
  RedeploymentSort.years_on_job,
  RedeploymentSort.performance_rating
] as const;

export const DEFAULT_REDEPLOYMENT_SORT_DIRECTION = {
  [RedeploymentSort.employee]: SortDirection.ascending,
  [RedeploymentSort.location]: SortDirection.ascending,
  [RedeploymentSort.manager]: SortDirection.ascending,
  [RedeploymentSort.job_title]: SortDirection.ascending,
  [RedeploymentSort.match_rate]: SortDirection.descending,
  [RedeploymentSort.years_on_job]: SortDirection.descending,
  [RedeploymentSort.performance_rating]: SortDirection.descending
} as const;
