import { gql } from '@apollo/client';
// local imports
import { API_MY_COURSE_ADVISOR_SEARCH } from '../config/api';

export const COURSE_ADVISOR_SEARCH_QUERY = gql`
  query CourseAdvisorSearch(
    $skill_ids: String!
    $search: String!
    $exclude_advisor_ids: String
    $exclude_course_ids: String
    $exclude_opp_ids: String
    $limit: Int
  ) {
    courseAdvisorSearch(
      skill_ids: $skill_ids,
      search: $search,
      exclude_advisor_ids: $exclude_advisor_ids,
      exclude_course_ids: $exclude_course_ids,
      exclude_opp_ids: $exclude_opp_ids,
      limit: $limit
    )
    @rest(
      type: "CourseAdvisorSearchPayload",
      path: "${API_MY_COURSE_ADVISOR_SEARCH}?{args}"
    ) {
      results {
        id @client
        item_type
        item_id
        item_code
        title
        skills {
          id
          title
          abbr
          level
        }
      }
    }
  }
`;
