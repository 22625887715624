import { gql } from '@apollo/client';
// local imports
import { API_SUPV_OPPORTUNITY } from '../config/api';

export const NEW_OPPORTUNITY_BOOKING = gql`
  mutation NewOpportunityBooking(
    $opportunity_id: Int!
    $input: NewOpportunityBookingInput!
  ) {
    newOpportunityBooking(
      opportunity_id: $opportunity_id,
      input: $input
    )
    @rest(
      type: "MutationResponseWithId",
      path: "${API_SUPV_OPPORTUNITY}bookings/",
      method: "POST"
    )
    # status 201
    {
      id
      success
      # TODO: optimistic?
    }
  }
`;
