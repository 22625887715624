import { gql } from '@apollo/client';
// local imports
import { API_SKILL_CATEGORIES } from '../config/api';

export const SKILL_CATEGORIES_QUERY = gql`
  query SkillCategories(
    $limit: Int
  ) {
    skillCategories(
      limit: $limit
    )
    @rest(
      type: "LookupPayload",
      path: "${API_SKILL_CATEGORIES}?{args}"
    ) {
      count
      results {
        id
        title
      }
    }
  }
`;
