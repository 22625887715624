import { gql } from '@apollo/client';
// local imports
import { API_DEV_PLAN } from '../config/api';

export const DEV_PLAN_OPPORTUNITIES_QUERY = gql`
  query DevPlanOpportunities(
    $devplan_id: Int!
    $type: DevPlanOpportunityType!
    $exclude_ids: String!
    $target_skill_ids: String!
  ) {
    devplanOpportunities(
      devplan_id: $devplan_id,
      type: $type,
      exclude_ids: $exclude_ids,
      target_skill_ids: $target_skill_ids
    )
    @rest(
      type: "DevPlanOpportunitiesPayload",
      path: "${API_DEV_PLAN}{args.type}/?target_skill_ids={args.target_skill_ids}&exclude_ids={args.exclude_ids}"
    ) {
      count
      results {
        id
        opportunity_match_id
        title
        duration_unit
        duration_value
        description
        start_date
        status
        scope_manager_id
        onsite
        sidegig
        timezone_minutes
        published_at
        updated_at
        started_at
        archived_at
        timestamp @client
        is_selected # local field used in DevPlanEditor
        location {
          id
          city
          state
          country
          title @client
        }
        owner {
          id
          code
          first_name
          last_name
          email
        }
        skills {
          id
          title
          abbr
          skill_proficiency_level
          status
        }
      }
    }
  }
`;
