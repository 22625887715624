import { type MenuProps } from '@mui/material/Menu';
import { type PopoverProps } from '@mui/material/Popover';

export const DEFAULT_MENU_PROPS: Partial<MenuProps & PopoverProps> = {
  keepMounted: true,
  disableScrollLock: true,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center'
  }
};

export const DEFAULT_MENU_LEFT: Partial<MenuProps & PopoverProps> = {
  ...DEFAULT_MENU_PROPS,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  }
};

export const DEFAULT_MENU_RIGHT: Partial<MenuProps & PopoverProps> = {
  ...DEFAULT_MENU_PROPS,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right'
  }
};
