import { useCallback, useState } from 'react';
import { type Axios, type AxiosRequestConfig, type AxiosError } from 'axios';

type PostResponse = {
  success: boolean | null;
} | undefined;

const usePost = (
  axios: Axios,
  getAuthHeaders: () => AxiosRequestConfig<FormData>
) => {
  const [data, setData] = useState(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<Error | AxiosError | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const post = useCallback((url: string, payload: FormData) => {
    (async () => {
      try {
        setData(null);
        setLoading(true);
        setIsError(false);
        const {status, data: retData} = await axios.post(
          url,
          payload,
          getAuthHeaders()
        );
        if (status !== 200 && status !== 201) throw Error();
        setData(retData);
      } catch (retError) {
        setIsError(true);
        setError(retError as Error | AxiosError);
      } finally {
        setLoading(false);
      }
    })();
  }, [axios, getAuthHeaders]);

  return {
    post,
    data,
    error,
    loading,
    failed: Boolean(isError || (data && !(data as unknown as PostResponse)?.success)),
    succeeded: Boolean(!isError && (data as unknown as PostResponse)?.success)
  };
};

export default usePost;
