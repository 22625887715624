import { forwardRef, memo, type MouseEventHandler, type Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogActions, { type DialogActionsProps } from '@mui/material/DialogActions';
// SCSS imports
import { backButton, confirmButton, actions, border } from './SkillLevelDialogActions.module.scss';

type SkillLevelDialogActionsProps = {
  onConfirm: MouseEventHandler<HTMLButtonElement>;
  onBack?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean | null;
  pending?: boolean | null;
  visible?: boolean | null;
  withBorder?: boolean;
  readOnly?: boolean;
}

const SkillLevelDialogActionsPropTypes = {
  // attributes
  onConfirm: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  disabled: PropTypes.bool,
  pending: PropTypes.bool,
  visible: PropTypes.bool,
  withBorder: PropTypes.bool,
  readOnly: PropTypes.bool
};

const SkillLevelDialogActions = forwardRef<Component<DialogActionsProps>, SkillLevelDialogActionsProps>(({
  onConfirm,
  onBack,
  disabled = false,
  pending = false,
  visible = false,
  withBorder = false,
  readOnly = false
}, ref) => (
  <DialogActions
      ref={ref}
      disableSpacing
      className={clsx({
        [actions]: !withBorder && visible,
        [border]: withBorder && visible
      })}
  >
    {visible ? (
      <>
        {onBack ? (
          <Button
              onClick={onBack}
              color="primary"
              variant="outlined"
              className={backButton}
          >
            <FormattedMessage id="common.button.back"/>
          </Button>
        ) : undefined}
        <Button
            onClick={onConfirm}
            color="primary"
            variant="contained"
            disableElevation
            size="large"
            disabled={disabled && !readOnly ? true : undefined}
            startIcon={pending ? <CircularProgress size={18} color="inherit"/> : undefined}
            className={confirmButton}
        >
          <FormattedMessage id={readOnly ? 'common.button.close' : 'common.button.confirm'}/>
        </Button>
      </>
    ) : undefined}
  </DialogActions>
));

SkillLevelDialogActions.displayName = 'SkillLevelDialogActions';

SkillLevelDialogActions.propTypes = SkillLevelDialogActionsPropTypes;

export default memo(SkillLevelDialogActions);
