import { type Dispatch } from 'react';
import isSafeInteger from 'lodash/isSafeInteger';
import { type AxiosResponse } from 'axios';
// local imports
import { APIActionResponse } from '../models/apiResponse';
import { StateFlag } from '../models/contextEntity';
import {
  IDataState, DataActions,
  UpdateDataStatusParams,
  JOB_STATUS_FETCH, DATA_STATUS_FETCH, DATA_STATUS_UPDATED, DATA_STATUS_UPDATING
} from './dataContext';
import {
  axiosInstance as axios,
  API_MY_DATA_STATUS, API_MY_DATA_UPDATE
} from '../config/api';
import { fetchFactory } from '../helpers/actions';
import { getRequestHeaders, optimizeParams } from '../helpers/context';
import { Token, Online } from './global';

export const requireJobStatus = (
  token: Token,
  online: Online,
  unauthenticate: () => void,
  dispatch: Dispatch<DataActions>,
  jobStatus: IDataState['jobStatus']
) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  type: JOB_STATUS_FETCH,
  entity: jobStatus,
  params: ({ id, timestamp }) => ({
    id,
    ...isSafeInteger(timestamp) ? { timestamp } : {}
  }),
  validator: ({ id }) => Boolean(id) && id >= 1 && isSafeInteger(id),
  api: API_MY_DATA_STATUS,
  dropParams: ['timestamp'],
  results: ''
});

export const requireDataStatus = (
  token: Token,
  online: Online,
  unauthenticate: () => void,
  dispatch: Dispatch<DataActions>,
  dataStatus: IDataState['dataStatus']
) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  type: DATA_STATUS_FETCH,
  entity: dataStatus,
  params: ({ timestamp }) => isSafeInteger(timestamp) ? { timestamp } : {},
  api: API_MY_DATA_STATUS,
  dropParams: ['timestamp'],
  results: ''
});

export const updateDataStatus = (
  token: Token,
  online: Online,
  dispatch: Dispatch<DataActions>,
  dataStatusUpdatePending: StateFlag
) => async ({ force }: UpdateDataStatusParams = {}) => {
  if (!token || dataStatusUpdatePending) return;
  const params = optimizeParams({
    force: force === true ? true : null
  } as UpdateDataStatusParams, online);
  try {
    if (!online) throw new Error();
    dispatch({
      type: DATA_STATUS_UPDATING,
      params
    });
    const { status, data } = await axios.request<UpdateDataStatusParams, AxiosResponse<APIActionResponse>>({
      method: 'POST',
      url: API_MY_DATA_UPDATE,
      data: params,
      headers: getRequestHeaders(token)
    }) || {};
    if (status < 200 || status > 201 || !data || !data.success) throw new Error();
    dispatch({
      type: DATA_STATUS_UPDATED,
      payload: true,
      params
    });
  } catch (error) {
    dispatch({
      type: DATA_STATUS_UPDATED,
      payload: false,
      params
    });
  }
};
