import { forwardRef, memo, type ReactNode, type Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// Material UI imports
import Box, { type BoxProps } from '@mui/material/Box';
// local imports
import ScrollToTopOnMount from '../common/ScrollToTopOnMount';
import { type AppBarVariant, AppBarVariantProp, APPBAR_NONE } from '../elements/AppBar';
import Copyright from '../elements/Copyright';
// Media and SCSS imports
import Diamonds from '../images/welcome.svg?react';
import { main, mainSplash, gradient, gradientOnly, height100V, toolbar, diamonds } from './AppScreen.module.scss';

type AppScreenProps = {
  children?: ReactNode | ReactNode[];
  splash?: boolean;
  fullHeight?: boolean;
  dashboard?: boolean;
  appbar?: AppBarVariant;
  header?: ReactNode | ReactNode[];
  footer?: ReactNode | ReactNode[];
  className?: string;
};

const AppScreenPropTypes = {
  // React built-in
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  // attributes
  splash: PropTypes.bool,
  fullHeight: PropTypes.bool,
  dashboard: PropTypes.bool,
  appbar: AppBarVariantProp,
  header: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  footer: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string
};

const AppScreen = forwardRef<Component<BoxProps>, AppScreenProps>(({
  children,
  splash = false,
  fullHeight = false,
  dashboard = false,
  appbar = 'full',
  header,
  footer,
  className
}, ref) => (
  <Box
      ref={ref}
      display="flex"
      flexGrow={1}
      flexDirection="column"
      bgcolor={dashboard && fullHeight ? undefined : 'background.default'}
      className={dashboard && fullHeight ? gradientOnly : undefined}
  >
    <ScrollToTopOnMount/>
    {appbar !== APPBAR_NONE && header}
    <main
        className={clsx(className, {
          [mainSplash]: splash,
          [main]: !splash,
          [height100V]: fullHeight,
          [gradient]: dashboard && !fullHeight
        })}
    >
      {appbar !== APPBAR_NONE && <div className={toolbar}/>}
      {splash ? <Diamonds className={diamonds}/> : undefined}
      {children}
    </main>
    {footer}
    <Copyright/>
  </Box>
));

AppScreen.displayName = 'AppScreen';

AppScreen.propTypes = AppScreenPropTypes;

export default memo(AppScreen);
