import { gql } from '@apollo/client';
// local imports
import { API_LOOKUP_DELEGATES } from '../config/api';

export const DELEGATES_SEARCH_QUERY = gql`
  query DelegatesSearch(
    $leader_id: Int
    $search: String
    $limit: Int
  ) {
    delegatesSearch(
      leader_id: $leader_id,
      search: $search,
      limit: $limit
    )
    @rest(
      type: "DelegatesPayload",
      path: "${API_LOOKUP_DELEGATES}?{args}"
    ) {
      count
      results {
        id
        first_name
        last_name
        code
        email
        management_level
        title @client
        pure_name @client
        is_delegated @client
        delegated_for {
          id
          code
          first_name
          last_name
        }
      }
    }
  }
`;
