import { gql } from '@apollo/client';
// local imports
import { API_DA_CLOSE_MATCH_JOBS } from '../config/api';

export const DA_CLOSE_MATCH_JOBS_QUERY = gql`
  query DACloseMatchJobs(
    $input: DACloseMatchJobsInput!
    $pathBuilder: String!
  ) {
    daCloseMatchJobs(
      input: $input
    )
    @rest(
      type: "DACloseMatchJobsPayload",
      path: "${API_DA_CLOSE_MATCH_JOBS}",
      pathBuilder: $pathBuilder
    ) {
      total_employees
      results {
        month
        job_count
      }
    }
  }
`;
