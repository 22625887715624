import toLower from 'lodash/toLower';
import join from 'lodash/join';
import map from 'lodash/map';
import take from 'lodash/take';
import { type AxiosResponse } from 'axios';
import type { RowInput } from 'jspdf-autotable';
// local imports
import { Skill } from '../models/skill';
import { Employee } from '../models/employee';
import { APIResponse } from '../models/apiResponse';
import { ExportParams } from '../context/commonParams';
import { MAX_ITEMS_EXPORT } from '../config/params';
import {
  axiosInstance as axios,
  getApiEmployeeSkills, API_MY_SKILLS
} from '../config/api';
import { getRequestHeaders } from '../helpers/context';
import { getSkillCurrentLevel } from '../helpers/models';
import {
  IAutoTable, IJsPDF, Rows,
  CENTERED_BOLD_COLUMN, getInferredString, getSkillString,
  downloadAndSave, saveToCSV, saveToPDF
} from '../helpers/export';

// Employee Profile export

const SKILLS_CSV_COLUMNS = [
  'supervisor.export.skill.id',
  'supervisor.export.skill.title',
  'supervisor.export.skill.level',
  'supervisor.export.inferred',
  'supervisor.export.skill.type'
];

// eslint-disable-next-line max-params
function saveEmployeeSkills(
  title: string,
  footer: string | string[],
  employee: Employee,
  headers: string[],
  skills: Skill[],
  jsPDF?: IJsPDF,
  autoTable?: IAutoTable
) {
  const { code, first_name, last_name } = employee;
  const rows = map(skills, (skill) => [
    skill.abbr,
    skill.title,
    jsPDF ? getSkillString(skill) : getSkillCurrentLevel(skill),
    ...jsPDF ? [] : [getInferredString(skill)],
    skill.type
  ] as RowInput) as Rows;
  const filename = `skills_${join(map([code, first_name, last_name], toLower), '_')}`;
  if (jsPDF && autoTable) {
    saveToPDF(jsPDF, autoTable, filename, [...take(headers, 3), headers[4]], rows, title, footer,
      { 2: CENTERED_BOLD_COLUMN });
  } else {
    saveToCSV(filename, headers, rows);
  }
}

// eslint-disable-next-line max-params
function downloadAndSaveEmployeeSkills(
  supervisor: boolean | null,
  format: string,
  token: string,
  title: string,
  footer: string | string[],
  employee: Employee,
  headers: string[],
  skills?: Skill[] | null
  // eslint-disable-next-line max-params
): Promise<boolean | number> {
  return downloadAndSave<Skill>(
    format,
    skills ? { status: 200, data: { results: skills } as APIResponse<Skill> } as AxiosResponse<APIResponse<Skill>>
    : axios.request<ExportParams, AxiosResponse<APIResponse<Skill>>>({
        method: 'GET',
        url: supervisor ? getApiEmployeeSkills(employee.code) : API_MY_SKILLS,
        params: {
          export_mode: true,
          limit: MAX_ITEMS_EXPORT
        },
        headers: getRequestHeaders(token)
      }),
    (data, jsPDF, autoTable) => saveEmployeeSkills(title, footer, employee, headers, data, jsPDF, autoTable)
  );
}

const exportEmployeeProfileHook = {
  SKILLS_CSV_COLUMNS,
  downloadAndSaveEmployeeSkills
};

function useExportEmployeeProfile() {
  return exportEmployeeProfileHook;
}

export default useExportEmployeeProfile;
