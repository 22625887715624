import { gql } from '@apollo/client';
// local imports
import { API_HR_REDEPLOYMENT_EMPLOYEE_JOB } from '../config/api';

export const UPDATE_REDEPLOYMENT_EMPLOYEE_JOB = gql`
  mutation UpdateRedeploymentEmployeeJob(
    $plan_id: Int!
    $input: RedeploymentEmployeeJobInput!
  ) {
    updateRedeploymentEmployeeJob(
      plan_id: $plan_id,
      input: $input
    )
    @rest(
      type: "MutationResponse",
      path: "${API_HR_REDEPLOYMENT_EMPLOYEE_JOB}",
      method: "POST"
    ) {
      success
      optimistic
    }
  }
`;
