import { memo, useEffect, useMemo, type FunctionComponent } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
// Material UI imports
import Skeleton from '@mui/material/Skeleton';
// EmPath UI Components
import useCurrentPath from '@empathco/ui-components/src/hooks/useCurrentPath';
import useQueryObject from '@empathco/ui-components/src/hooks/useQueryObject';
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardTabbar from '@empathco/ui-components/src/elements/CardTabbar';
// local imports
import { OPPORTUNITY_QUERY } from '../graphql/Opportunity';
import { Opportunity, OpportunityDocument } from '../graphql/types';
import { isEmployee, isManager } from '../models/user';
import usePathConfig, {
  PATH_MY_OPPORTUNITIES_BROWSE, PATH_MY_OPPORTUNITIES, PATH_MY_OPPORTUNITY,
  PATH_SUPERVISOR_OPPORTUNITIES, PATH_SUPERVISOR_OPPORTUNITY_NEW, PATH_SUPERVISOR_OPPORTUNITY_EDIT,
  PATH_SUPERVISOR_OPPORTUNITY, PATH_SUPERVISOR_OPPORTUNITY_MATCHES, PATH_SUPERVISOR_OPPORTUNITY_BOOKINGS
} from '../config/paths';
import { getOpportunityId } from '../helpers/routerParams';
import useVerifyUser from '../hooks/useVerifyUser';
import Screen from '../v3/Screen';
import OpportunitiesPanel from '../panels/OpportunitiesPanel';
import OpportunityReviewPanel from '../panels/OpportunityReviewPanel';
import OpportunityEditorPanel from '../panels/OpportunityEditorPanel';

// eslint-disable-next-line complexity
const OpportunitiesScreen: FunctionComponent = () => {
  const { ROUTES } = usePathConfig();
  const path = useCurrentPath(ROUTES);
  const opportunity_id = getOpportunityId(useParams());
  const { content, user } = useVerifyUser({ employee: true, manager: true });

  // lazy load opportunity
  const { query: getOpportunity, pending, failed, results: opportunity } = useQueryObject({
    data: undefined as unknown as Opportunity,
    key: 'opportunity',
    flatResults: true,
    lazyQuery: useLazyQuery(OPPORTUNITY_QUERY as typeof OpportunityDocument)
  });

  const isNewPath = path === PATH_SUPERVISOR_OPPORTUNITY_NEW;
  const isSupervisorPath = path === PATH_SUPERVISOR_OPPORTUNITIES || isNewPath ||
    path === PATH_SUPERVISOR_OPPORTUNITY || path === PATH_SUPERVISOR_OPPORTUNITY_MATCHES ||
    path === PATH_SUPERVISOR_OPPORTUNITY_BOOKINGS || path === PATH_SUPERVISOR_OPPORTUNITY_EDIT;

  useEffect(() => {
    if (opportunity_id && isSupervisorPath) getOpportunity?.({ variables: { opportunity_id } });
  }, [opportunity_id, isSupervisorPath, getOpportunity]);

  const userIsEmployee = isEmployee(user);

  const loading = opportunity_id && (pending || opportunity_id !== opportunity?.id) ? true : undefined;
  const { title } = (!loading && opportunity_id && opportunity) || {};

  const breadcrumbs = useMemo(() => [
    {
      title: 'breadcrumbs.opportunities',
      link: isSupervisorPath ? PATH_SUPERVISOR_OPPORTUNITIES : PATH_MY_OPPORTUNITIES_BROWSE
    },
    ...path === PATH_MY_OPPORTUNITIES ? [{ title: 'breadcrumbs.opportunities_my' }] : [],
    ...isSupervisorPath && (isNewPath || userIsEmployee) ? [{ title: 'breadcrumbs.opportunities_posts' }] : [],
    ...title ? [{ title }] : []
  ], [title, path, isSupervisorPath, isNewPath, userIsEmployee]);

  const userIsManager = isManager(user);

  const items = useMemo(() => userIsEmployee ? [
    {
      title: 'opportunities.browse',
      active: path === PATH_MY_OPPORTUNITY,
      link: PATH_MY_OPPORTUNITIES_BROWSE
    },
    {
      title: 'opportunities.my',
      link: PATH_MY_OPPORTUNITIES
    },
    ...userIsManager ? [
      {
        title: 'opportunities.posts',
        active: Boolean(opportunity_id || isNewPath),
        link: PATH_SUPERVISOR_OPPORTUNITIES
      }
    ] : []
  ] : undefined, [userIsEmployee, path, userIsManager, opportunity_id, isNewPath]);

  if (content) return content;

  if (!isSupervisorPath && !userIsEmployee) return <Navigate replace to={PATH_SUPERVISOR_OPPORTUNITIES}/>;
  if (isSupervisorPath && !userIsManager) return <Navigate replace to={PATH_MY_OPPORTUNITIES_BROWSE}/>;

  const myPosts = userIsManager ? (((opportunity_id && path === PATH_SUPERVISOR_OPPORTUNITY_EDIT) || isNewPath) && (
    <OpportunityEditorPanel
        opportunityId={opportunity_id}
        opportunity={!opportunity_id || loading ? undefined : opportunity}
        pending={loading}
        failed={failed}
    />
  )) || (opportunity_id && (
    path === PATH_SUPERVISOR_OPPORTUNITY ||
    path === PATH_SUPERVISOR_OPPORTUNITY_MATCHES ||
    path === PATH_SUPERVISOR_OPPORTUNITY_BOOKINGS
  ) && (
    <OpportunityReviewPanel
        opportunityId={opportunity_id}
        opportunity={loading ? undefined : opportunity}
        pending={loading}
        failed={failed}
    />
  )) || <OpportunitiesPanel supervisor/> : undefined;

  return (
    <Screen withSettings breadcrumbs={breadcrumbs}>
      <ContentCard>
        {items ? (
          <CardTabbar withoutScroll items={items}>
            <OpportunitiesPanel opportunityId={opportunity_id}/>
            <OpportunitiesPanel myOpportunities/>
            {myPosts}
          </CardTabbar>
        ) : (
          <>
            <CardTitle
                title={loading ? (
                  <Skeleton variant="text" width="12rem"/>
                ) : title || 'opportunities.posts'}
                withDivider
            />
            {myPosts}
          </>
        )}
      </ContentCard>
    </Screen>
  );
};

export default memo(OpportunitiesScreen);
