import { useMemo } from 'react';
// local imports
import { ChartsAvailabilities } from '../graphql/customTypes';
import { CategoryItem } from '../models/categoriesGroup';
import useCustomerSettings from '../config/customer';

// IMPORTANT! Do not change these numbers (only add new or delete old), they are used in user preferences!
export const DA_GROUP_SKILLS = 1 as const;
// Skills
export const DA_SKILL_SUPPLY = 1 as const;
export const DA_EMPLOYEE_SKILLS = 2 as const;
export const DA_SKILLS_GROWTH = 3 as const;
export const DA_SKILLS_DEVELOPMENT = 4 as const;
export const DA_COURSE_COMPLETION = 6 as const;
export const DA_EFFECTIVE_COURSES = 5 as const;
export const DA_EFFECTIVE_ADVISORS = 19 as const;
export const DA_SKILL_COURSE_MAPPING = 7 as const;

export const DA_GROUP_JOBS = 2 as const;
// Jobs
export const DA_FREQ_INDEMAND = 8 as const;
export const DA_FREQ_SKILL_GAP = 9 as const;
export const DA_HIRING_TRENDS = 10 as const;
export const DA_JOBS_MOVEMENT = 11 as const;
export const DA_JOB_RELATED_SKILLS = 12 as const;

export const DA_GROUP_EMPLOYEES = 3 as const;
// Employees
export const DA_JOB_MOVEMENT_SKILLS = 13 as const;
export const DA_CLOSE_MATCH_JOBS = 14 as const;
export const DA_AVERAGE_SKILLS = 15 as const;
export const DA_EMPLOYEE_VELOCITY = 16 as const;
export const DA_CURRENT_ROLE_MATCH = 17 as const;
export const DA_UPSKILLING_VELOCITY = 18 as const;
export const DA_USAGE_BY_ORG = 20 as const;

export const DA_GROUP_BY_CATEGORY: Record<number, number> = {
  [DA_SKILL_SUPPLY]: DA_GROUP_SKILLS,
  [DA_EMPLOYEE_SKILLS]: DA_GROUP_SKILLS,
  [DA_SKILLS_GROWTH]: DA_GROUP_SKILLS,
  [DA_SKILLS_DEVELOPMENT]: DA_GROUP_SKILLS,
  [DA_COURSE_COMPLETION]: DA_GROUP_SKILLS,
  [DA_EFFECTIVE_COURSES]: DA_GROUP_SKILLS,
  [DA_EFFECTIVE_ADVISORS]: DA_GROUP_SKILLS,
  [DA_SKILL_COURSE_MAPPING]: DA_GROUP_SKILLS,
  [DA_UPSKILLING_VELOCITY]: DA_GROUP_SKILLS,
  [DA_FREQ_INDEMAND]: DA_GROUP_JOBS,
  [DA_FREQ_SKILL_GAP]: DA_GROUP_JOBS,
  [DA_HIRING_TRENDS]: DA_GROUP_JOBS,
  [DA_JOBS_MOVEMENT]: DA_GROUP_JOBS,
  [DA_JOB_RELATED_SKILLS]: DA_GROUP_JOBS,
  [DA_JOB_MOVEMENT_SKILLS]: DA_GROUP_EMPLOYEES,
  [DA_CLOSE_MATCH_JOBS]: DA_GROUP_EMPLOYEES,
  [DA_AVERAGE_SKILLS]: DA_GROUP_EMPLOYEES,
  [DA_EMPLOYEE_VELOCITY]: DA_GROUP_EMPLOYEES,
  [DA_CURRENT_ROLE_MATCH]: DA_GROUP_EMPLOYEES,
  [DA_USAGE_BY_ORG]: DA_GROUP_EMPLOYEES
} as const;

// eslint-disable-next-line complexity
function useAnalyticsCharts(status?: ChartsAvailabilities | null): CategoryItem[] {
  const { DA_TABS, HAS_COURSES_READONLY, HAS_INDEMAND_SKILLS, HAS_MENTORING } = useCustomerSettings();
  // eslint-disable-next-line complexity
  const charts = useMemo(() => DA_TABS && status ? [
    // Skills
    ...DA_TABS.skill_supply_demand && status.skillsupplydemand ? [
      {
        id: DA_SKILL_SUPPLY,
        title: 'supervisor.dashboard.supply_demand',
        values: { indemand: Boolean(HAS_INDEMAND_SKILLS) }
        // info: 'hr.dashboard.supply_demand.info'
      }
    ] : [],
    ...DA_TABS.employees_skills && status.employeeconfirmed ? [
      {
        id: DA_EMPLOYEE_SKILLS,
        title: 'supervisor.dashboard.employee_skills'
        // info: 'hr.dashboard.employees_skills.info'
      }
    ] : [],
    ...HAS_INDEMAND_SKILLS && DA_TABS.skills_growth && status.skillgrowth ? [
      {
        id: DA_SKILLS_GROWTH,
        title: 'supervisor.dashboard.skills_growth'
        // info: 'hr.dashboard.skills_growth.info'
      }
    ] : [],
    ...HAS_INDEMAND_SKILLS && (HAS_MENTORING || HAS_COURSES_READONLY) &&
      DA_TABS.skills_development && status.skilldevelopment ? [
      {
        id: DA_SKILLS_DEVELOPMENT,
        title: 'supervisor.dashboard.skills_development'
        // info: 'hr.dashboard.skills_development.info'
      }
    ] : [],
    ...HAS_COURSES_READONLY && DA_TABS.most_effective_courses && status.mosteffectivecourse ? [
      {
        id: DA_EFFECTIVE_COURSES,
        title: 'supervisor.dashboard.effective_courses',
        name: 'supervisor.dashboard.effective_courses.short'
        // info: 'hr.dashboard.effective_courses.info'
      }
    ] : [],
    ...HAS_COURSES_READONLY && DA_TABS.course_completion && status.coursecompletion ? [
      {
        id: DA_COURSE_COMPLETION,
        title: 'supervisor.dashboard.course_completion',
        name: 'supervisor.dashboard.course_completion.short'
        // info: 'hr.dashboard.course_completion.info'
      }
    ] : [],
    ...HAS_COURSES_READONLY && DA_TABS.skill_course_mapping && status.skillorcourse ? [
      {
        id: DA_SKILL_COURSE_MAPPING,
        title: 'supervisor.dashboard.skill_course_mapping'
        // info: 'hr.dashboard.skill_course_mapping.info'
      }
    ] : [],
    ...HAS_MENTORING && DA_TABS.most_effective_advisors && status.mosteffectivementors ? [
      {
        id: DA_EFFECTIVE_ADVISORS,
        title: 'supervisor.dashboard.effective_advisors',
        name: 'supervisor.dashboard.effective_advisors.short'
        // info: 'hr.dashboard.effective_advisors.info'
      }
    ] : [],

    // Jobs
    ...DA_TABS.frequent_indemand_targeted && status.skillinsight ? [
      {
        id: DA_FREQ_INDEMAND,
        title: 'supervisor.dashboard.frequent_indemand_targeted'
        // info: 'hr.dashboard.frequent_indemand_targeted.info'
      }
    ] : [],
    ...DA_TABS.frequent_skill_gap && status.skillinsight ? [
      {
        id: DA_FREQ_SKILL_GAP,
        title: 'supervisor.dashboard.frequent_skill_gap'
        // info: 'hr.dashboard.frequent_skill_gap.info'
      }
    ] : [],
    ...(DA_TABS.hiring_trends_all || DA_TABS.hiring_trends_internal) && status.jobhire ? [
      {
        id: DA_HIRING_TRENDS,
        title: 'supervisor.dashboard.hiring_trends'
        // info: 'hr.dashboard.hiring_trends.info'
      }
    ] : [],
    ...HAS_INDEMAND_SKILLS && DA_TABS.jobs_movement && status.jobmovement ? [
      {
        id: DA_JOBS_MOVEMENT,
        title: 'supervisor.dashboard.jobs_movement'
        // info: 'hr.dashboard.jobs_movement.info'
      }
    ] : [],
    ...DA_TABS.job_related_skills && status.jobrelatedskill ? [
      {
        id: DA_JOB_RELATED_SKILLS,
        title: 'supervisor.dashboard.job_skills'
        // info: 'hr.dashboard.job_skills.info'
      }
    ] : [],

    // Employees
    ...HAS_INDEMAND_SKILLS && DA_TABS.in_demand_skills && status.jobmovementskill ? [
      {
        id: DA_JOB_MOVEMENT_SKILLS,
        title: 'supervisor.dashboard.job_movement_skills'
        // info: 'hr.dashboard.job_movement_skills.info'
      }
    ] : [],
    ...DA_TABS.close_match_jobs && status.closematch ? [
      {
        id: DA_CLOSE_MATCH_JOBS,
        title: 'supervisor.dashboard.close_match_jobs'
        // info: 'hr.dashboard.close_match_jobs.info'
      }
    ] : [],
    ...DA_TABS.average_skills && status.avgnumberskill ? [
      {
        id: DA_AVERAGE_SKILLS,
        title: 'supervisor.dashboard.job_average_skills'
        // info: 'hr.dashboard.job_average_skills.info'
      }
    ] : [],
    ...DA_TABS.employee_velocity && status.employeevelocity ? [
      {
        id: DA_EMPLOYEE_VELOCITY,
        title: 'supervisor.dashboard.employee_velocity'
        // info: 'hr.dashboard.employee_velocity.info'
      }
    ] : [],
    ...DA_TABS.match_to_current_role && status.currentrolematch ? [
      {
        id: DA_CURRENT_ROLE_MATCH,
        title: 'supervisor.dashboard.current_role_match'
        // info: 'hr.dashboard.current_role_match.info'
      }
    ] : [],

    // Skills
    ...DA_TABS.upskilling_velocity && status.employeevelocityupskill ? [
      {
        id: DA_UPSKILLING_VELOCITY,
        title: 'supervisor.dashboard.upskilling_velocity'
        // info: 'hr.dashboard.upskilling_velocity.info'
      }
    ] : [],

    // Usage by Org
    ...DA_TABS.usage_by_org && status.orgusage ? [
      {
        id: DA_USAGE_BY_ORG,
        title: 'supervisor.dashboard.usage_by_org'
        // info: 'hr.dashboard.usage_by_org.info'
      }
    ] : []

  ] : [], [status, DA_TABS, HAS_COURSES_READONLY, HAS_INDEMAND_SKILLS, HAS_MENTORING]);

  return charts;
}

export default useAnalyticsCharts;
