import { memo, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import omit from 'lodash/omit';
import { type CustomArrowProps } from 'react-slick';
import { useIntl } from 'react-intl';
// Material UI imports
import Fab from '@mui/material/Fab';
// Material Icon imports
import ChevronLeftRounded from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRounded from '@mui/icons-material/ChevronRightRounded';
// SCSS imports
import { fab } from './CarouselArrow.module.scss';

export type ArrowVariant = 'prev' | 'next';

interface CarouselArrowProps extends CustomArrowProps {
  variant: ArrowVariant;
}

const CarouselArrowPropTypes = {
  variant: PropTypes.string.isRequired as Validator<ArrowVariant>
};

const CarouselArrow: FunctionComponent<CarouselArrowProps> = ({
  variant,
  ...props
}) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...omit(props, ['slideCount', 'currentSlide'])}>
      <Fab
          color={variant === 'prev' ? 'secondary' : 'primary'}
          aria-label={formatMessage({ id: `board.stories.button.${variant}` })}
          className={fab}
      >
        {variant === 'prev' ? <ChevronLeftRounded/> : <ChevronRightRounded/>}
      </Fab>
    </div>
  );
};

CarouselArrow.propTypes = CarouselArrowPropTypes;

export default memo(CarouselArrow);
