import { gql } from '@apollo/client';
// local imports
import { API_DEV_PLAN } from '../config/api';

export const DEV_PLAN_ACTIVITIES_QUERY = gql`
  query DevPlanActivities(
    $devplan_id: Int!
  ) {
    devplanActivities(
      devplan_id: $devplan_id
    )
    @rest(
      type: "SkillActivitiesPayload",
      path: "${API_DEV_PLAN}activities/"
    ) {
      count
      results {
        id
        code
        name
        activity_type
        description
        activity_link
        development_area
        field_support
        store
        education_style
        owner {
          id
          code
          first_name
          last_name
          email
        }
        skills {
          id
          abbr
          title
          skill_proficiency_level
        }
      }
    }
  }
`;
