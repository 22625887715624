import { forwardRef, memo, type ReactNode, type Component } from 'react';
import PropTypes from 'prop-types';
// Material UI imports
import Box, { type BoxProps } from '@mui/material/Box';
// SCSS imports
import { scrollable, autofitScrollable } from './Scrollable.module.scss';

type ScrollableProps = {
  autofit?: boolean;
  children: ReactNode | ReactNode[];
}

const ScrollablePropTypes = {
  autofit: PropTypes.bool,
  // React built-in
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
    PropTypes.node.isRequired
  ]).isRequired
};

const Scrollable = forwardRef<Component<BoxProps>, ScrollableProps>(({
  autofit = false,
  children
}, ref) => (
  <Box
      ref={ref}
      color="text.primary"
      pr={2}
      className={autofit ? autofitScrollable : scrollable}
  >
    {children}
  </Box>
));

Scrollable.displayName = 'Scrollable';

Scrollable.propTypes = ScrollablePropTypes;

export default memo(Scrollable);
