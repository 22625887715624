import { gql } from '@apollo/client';
// local imports
import { API_ORGS } from '../config/api';

export const ORGS_QUERY = gql`
  query Orgs(
    $selected_leader_id: String
    $limit: Int
  ) {
    orgs(
      selected_leader_id: $selected_leader_id,
      limit: $limit
    )
    @rest(
      type: "OrgsPayload",
      path: "${API_ORGS}?{args}"
    ) {
      count
      results {
        id
        title
        skills {
          id
          title
          abbr
          role_type
        }
      }
    }
  }
`;
