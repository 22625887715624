import { gql } from '@apollo/client';
// local imports
import { API_HR_CUSTOM_ACTIVITY } from '../config/api';

export const DELETE_CUSTOM_ACTIVITY = gql`
  mutation DeleteCustomActivity(
    $activity_id: Int!
  ) {
    deleteCustomActivity(
      activity_id: $activity_id
    )
    @rest(
      type: "MutationResponse",
      path: "${API_HR_CUSTOM_ACTIVITY}",
      method: "DELETE"
    ) {
      success # just a placeholder; this request has empty response (status 204 only)
    }
  }
`;
