import { memo, useEffect, useState, useMemo, useContext, useCallback, type FunctionComponent, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import get from 'lodash/get';
import size from 'lodash/size';
import isNil from 'lodash/isNil';
import sortBy from 'lodash/sortBy';
import { useLazyQuery, useMutation } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';
// Material Icon imports
import RestartAlt from '@mui/icons-material/RestartAlt';
// EmPath UI Components
import { getFullName } from '@empathco/ui-components/src/helpers/strings';
import { bold } from '@empathco/ui-components/src/helpers/intl';
import useQueryObject from '@empathco/ui-components/src/hooks/useQueryObject';
import useMutationMethod from '@empathco/ui-components/src/hooks/useMutationMethod';
import useConfirmationDialog from '@empathco/ui-components/src/hooks/useConfirmationDialog';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import CloseIconButton from '@empathco/ui-components/src/elements/CloseIconButton';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
import ActionSucceededMessage from '@empathco/ui-components/src/elements/ActionSucceededMessage';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import ConfirmDialog from '@empathco/ui-components/src/elements/ConfirmDialog';
// local imports
import { ADMIN_EMPLOYEE_QUERY } from '../graphql/AdminEmployee';
import { UPDATE_ADMIN_EMPLOYEE } from '../graphql/UpdateAdminEmployee';
import { AdminEmployee, AdminEmployeeDocument, AdminJob, UpdateAdminEmployeeDocument } from '../graphql/types';
import { TalentEmployeeObject } from '../graphql/customTypes';
import { SKILL_LEVEL_MAX, Skill } from '../models/skill';
import { Job } from '../models/job';
import { GlobalContext } from '../context/global';
import ImpersonateButton from '../elements/ImpersonateButton';
import EmployeeTalentBand from '../v3/EmployeeTalentBand';
import JobDetailsItem from '../v3/JobDetailsItem';
import SkillsGrid from '../v3/SkillsGrid';
// SCSS imports
import { lightBg } from './AdminEmployeeDialog.module.scss';

const resetPBBtnSx = { mr: 6 };

type AdminEmployeeDialogProps = {
  employee?: AdminEmployee | null;
  onJobClick?: (code: string, job?: Job | AdminJob, context?: TalentEmployeeObject | AdminEmployee) => void;
  onClose: () => void;
  // for Strorybook only
  testPending?: number;
}

const AdminEmployeeDialogPropTypes = {
  // attributes
  employee: PropTypes.object as Validator<AdminEmployee>,
  onJobClick: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  // for Strorybook only
  testPending: PropTypes.number
};

// eslint-disable-next-line complexity, max-statements
const AdminEmployeeDialog: FunctionComponent<AdminEmployeeDialogProps> = ({
  employee: parentEmployee,
  onJobClick,
  onClose,
  testPending
}) => {
  const { paths: { supvEmplPath } } = useContext(GlobalContext);

  const { query: getEmployee, pending: pendingEmployee, failed, results: employee } = useQueryObject({
    data: undefined as unknown as AdminEmployee,
    key: 'adminEmployee',
    flatResults: true,
    lazyQuery: useLazyQuery(ADMIN_EMPLOYEE_QUERY as typeof AdminEmployeeDocument)
  });
  const {
    mutate: updateEmployee, loading: updatePending, failed: updateFailed, succeeded: updateSucceeded
  } = useMutationMethod({
    // TODO: key: 'updateAdminEmployee', -- restore when backend responds with `success: true`
    mutation: useMutation(UPDATE_ADMIN_EMPLOYEE as typeof UpdateAdminEmployeeDocument)
  });

  const pending = pendingEmployee || !employee || testPending === 1;

  const { id, code: parentCode, first_name: parentFirstName, last_name: parentLastName } = parentEmployee || {};
  const parentTitle = getFullName(parentFirstName, parentLastName, parentCode);
  const {
    code, first_name, last_name, management_level, org, org_unit, email, skills,
    is_contractor, is_countable, is_esau_untouchable, is_inference_target, by_sso, profile
  } = employee || {};
  const emplCode = code || parentCode;
  const title = getFullName(first_name, last_name, code);
  const skillsCount = size(skills);

  const startedProfileBuilder = useMemo(() => {
    try {
      const profileObj = profile ? JSON.parse(profile) : null;
      return Boolean(profileObj) && size(get(profileObj, 'onboarding_steps')) >= 1;
    } catch (_err) {
      return false;
    }
  }, [profile]);

  useEffect(() => {
    if (id) getEmployee?.({ variables: { employee_id: id } });
  }, [id, getEmployee]);

  const isOpen = Boolean(parentEmployee);

  const [mounted, setMounted] = useState(isOpen);
  useEffect(() => {
    if (isOpen) setMounted(true);
  }, [isOpen]);

  const transitionProps = useMemo(() => ({ onExited: () => {
    setMounted(false);
  } }), []);

  const resetProfileBuilder = useCallback(() => {
    if (id) updateEmployee?.({
      variables: { employee_id: id, input: { profile_reset: true } },
      update: (cache) => cache.evict({ id: 'ROOT_QUERY', fieldName: 'adminEmployee' })
    });
  }, [id, updateEmployee]);

  const {
    confirmOpen,
    confirmMounted,
    handleAction: handleProfileBuilderReset,
    handleCancel,
    handleExited,
    handleConfirm
  } = useConfirmationDialog(resetProfileBuilder);

  const msgValues = useMemo(() => ({ bold, name: parentTitle }), [parentTitle]);

  const disabled = pending || updatePending || testPending ? true : undefined;

  const requiresSkills = useMemo(() => sortBy(skills,
    ({ title: skillTitle, expected_level }) => `${SKILL_LEVEL_MAX - (expected_level || 0)}${skillTitle}`), [skills]);

  const details = useMemo(() => [
    ...isNil(management_level) ? [] : [{ label: 'role.admin.management_level', value: management_level }],
    ...isNil(is_contractor) ? [] : [{ label: 'role.admin.is_contractor', value: is_contractor }],
    ...isNil(email) ? [] : [{ label: 'role.admin.email', value: email }],
    ...isNil(org) ? [] : [{ label: 'role.admin.org', value: org.title }],
    ...isNil(org_unit) ? [] : [{ label: 'role.admin.org_unit', value: org_unit.title }],
    ...skillsCount < 1 ? [{ label: 'role.admin.skills', value: 0 }] : []
  ] as { label: string; value: number | string; }[], [skillsCount, management_level, is_contractor, email, org, org_unit]);

  const extra = useMemo(() =>
    [
      ...isNil(is_countable) ? [] : [{ label: 'role.admin.is_countable', value: is_countable }],
      ...isNil(is_esau_untouchable) ? [] : [{ label: 'role.admin.is_esau_untouchable', value: is_esau_untouchable }],
      ...isNil(is_inference_target) ? [] : [{ label: 'role.admin.is_inference_target', value: is_inference_target }],
      ...isNil(by_sso) ? [] : [{ label: 'role.admin.by_sso', value: by_sso }]
    ] as { label: string; value: number | string | ReactNode; }[],
    [is_countable, is_esau_untouchable, is_inference_target, by_sso]
  );

  useEffect(() => {
    if (testPending === 2) updateEmployee?.({ variables: { employee_id: 321, input: { profile_reset: true } } });
  }, [testPending, updateEmployee]);

  const bands = [details, extra].map((band, index) => band.length >= 1 ? (
    <>
      {index === 1 ? <Divider/> : undefined}
      <CardSection
          compact flex
          dark={index === 0 ? true : undefined}
          className={index === 1 ? lightBg : undefined}
      >
        <Box
            py={2.5}
            flexGrow={1}
            display="flex"
            alignItems="center"
            flexWrap="wrap"
        >
          {map(band, ({ label, value }, idx) => (
            <JobDetailsItem
                key={label}
                prompt={<FormattedMessage id={label}/>}
                value={value}
                divider={idx < band.length - 1}
            />
          ))}
        </Box>
      </CardSection>
      {index === 0 ? <Divider/> : undefined}
    </>
  ) : undefined);

  return mounted ? (
    <>
      <Dialog
          disableEnforceFocus
          maxWidth="xl"
          fullWidth
          scroll="body"
          open={isOpen}
          onClose={disabled ? undefined : onClose}
          TransitionProps={transitionProps}
      >
        <CloseIconButton onClick={onClose}/>
        <CardTitle
            title={(failed && (parentTitle || 'admin.employees.default_name')) ||
              (pending && (parentTitle || <Skeleton variant="text" width="12rem"/>)) ||
              title || 'admin.employees.default_name'}
            subheader={emplCode ? <ImpersonateButton large code={emplCode} disabled={pending}/> : undefined}
            withDivider
            action={(
              <Button
                  type="submit"
                  color="error"
                  variant="outlined"
                  disableElevation
                  startIcon={updatePending ? <CircularProgress size={18} color="inherit"/> : <RestartAlt/>}
                  disabled={disabled || !startedProfileBuilder}
                  onClick={handleProfileBuilderReset}
                  sx={resetPBBtnSx}
              >
                <FormattedMessage id="admin.employees.reset_profile_builder"/>
              </Button>
            )}
        />
        {(failed && <FetchFailedAlert flat/>) || (pending && <LoadingPlaceholder flat/>) || (mounted ? (
          <>
            <CardSection compact>
              <EmployeeTalentBand employee={employee} route={supvEmplPath} onJobClick={onJobClick} withoutDivider/>
            </CardSection>
            <Divider/>
            {bands[0]}
            {skillsCount >= 1 ? (
              <>
                <CardSection top>
                  <BoxTypography pt={1} variant="subtitle1">
                    <FormattedMessage id="role.admin.skills"/>
                  </BoxTypography>
                </CardSection>
                <SkillsGrid
                    plain
                    readOnly
                    manualOnly
                    withLink={false}
                    skills={(requiresSkills || []) as Skill[]}
                />
              </>
            ) : undefined}
            {bands[1]}
          </>
        ) : undefined)}
      </Dialog>
      {confirmMounted ? (
        <ConfirmDialog
            open={confirmOpen}
            title="admin.employees.reset_profile_builder"
            text="admin.employees.reset_profile_builder.confirm"
            withCancelButton
            values={msgValues}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            onExited={handleExited}
        />
      ) : undefined}
      <ActionFailedAlert
          message="admin.employees.reset_profile_builder.error"
          open={updateFailed}
      />
      <ActionSucceededMessage
          message="admin.employees.reset_profile_builder.success"
          open={updateSucceeded}
          values={msgValues}
      />
    </>
  ) : null;
};

AdminEmployeeDialog.propTypes = AdminEmployeeDialogPropTypes;

export default memo(AdminEmployeeDialog);
