import { gql } from '@apollo/client';
// local imports
import { API_TALENT_FINDER_EMPLOYEES } from '../config/api';

export const TALENT_EMPLOYEES_QUERY = gql`
  query TalentEmployees(
    $input: TalentEmployeesInput!
    $pathBuilder: String!
  ) {
    talentEmployees(
      input: $input
    )
    @rest(
      type: "TalentEmployeesPayload",
      path: "${API_TALENT_FINDER_EMPLOYEES}",
      pathBuilder: $pathBuilder
    ) {
      count
      results {
        id
        code
        first_name
        last_name
        email
        is_profile_accessible
        performance_rating
        years_on_job
        job_match_rate
        current_job {
          id
          title
          code
        }
        location {
          id
          title
          city
          state
          country
        }
        manager {
          id
          code
          first_name
          last_name
          email
        }
        skills {
          id
          title
          abbr
          current_level
          inferred_level
          is_inference_newer
        }
      }
    }
  }
`;
