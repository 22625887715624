import { gql } from '@apollo/client';
// local imports
import { API_EMPLOYEE_DEV_PLAN } from '../config/api';

export const EMPLOYEE_DEV_PLAN_QUERY = gql`
  query EmployeeDevPlan(
    $devplan_id: Int!
    $selected_employee_id: String
  ) {
    employeeDevplan(
      devplan_id: $devplan_id,
      selected_employee_id: $selected_employee_id
    )
    @rest(
      type: "DevPlanExpanded",
      path: "${API_EMPLOYEE_DEV_PLAN}?{args}"
    ) {
      id
      title
      is_new
      is_active
      created_at
      updated_at
      owner {
        id
        code
        first_name
        last_name
        email
      }
      job {
        id
        title
        code
      }
      opportunity {
        id
        opportunity_match_id
        title
        description
        duration_unit
        duration_value
        status
      }
      target_skills {
        id
        title
        abbr
        skill_proficiency_level
        is_selected
        activities {
          id
          code
          name
          activity_type
          skill_proficiency_level
          description
          activity_link
          development_area
          field_support
          store
          education_style
          employee_activity_id
          is_complete
          is_selected
          owner {
            id
            code
            first_name
            last_name
            email
          }
        }
      }
      cohort {
        id
        title
      }
    }
  }
`;
