import { memo, forwardRef, useCallback } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import isNil from 'lodash/isNil';
import isBoolean from 'lodash/isBoolean';
import clsx from 'clsx';
// Material UI imports
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
// Material Icon imports
import CloseIcon from '@mui/icons-material/Close';
import ErrorRounded from '@mui/icons-material/ErrorRounded';
import CheckCircleRounded from '@mui/icons-material/CheckCircleRounded';
// EmPath UI Components
import TruncatedTextLink from '@empathco/ui-components/src/elements/TruncatedTextLink';
import SkillLevelGauge from '@empathco/ui-components/src/elements/SkillLevelGauge';
// local imports
import { Skill, SkillLevel } from '../models/skill';
import { Job } from '../models/job';
import { SkillWithLevel as SkillWithLevelGenerated } from '../graphql/types';
import { SkillWithLevel } from '../graphql/customTypes';
import { getSkillCurrentLevel, getSkillCurrentLevelOnly } from '../helpers/models';
// SCSS imports
import { chip, chipWithLevel, primary, info, zeroElevation } from './TbItemChip.module.scss';

type TbItemChipProps = {
  item: Skill | Job | SkillWithLevel | SkillWithLevelGenerated;
  withLevel?: boolean | null;
  withLargeLevel?: boolean | null;
  withExpectedLevel?: boolean | null;
  withGrowthLevel?: boolean | null;
  onClick?: (id: number, level?: SkillLevel | null) => void;
  onDelete?: (id: number, level?: SkillLevel | null) => void;
  disabled?: boolean;
  satisfied?: boolean | null;
  flat?: boolean;
  className?: string;
};

const TbItemChipPropTypes = {
  // attributes
  item: PropTypes.object.isRequired as Validator<Skill>,
  withLevel: PropTypes.bool,
  withLargeLevel: PropTypes.bool,
  withExpectedLevel: PropTypes.bool,
  withGrowthLevel: PropTypes.bool,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  disabled: PropTypes.bool,
  satisfied: PropTypes.bool,
  flat: PropTypes.bool,
  className: PropTypes.string
};

// eslint-disable-next-line complexity
const TbItemChip = forwardRef<HTMLDivElement, TbItemChipProps>(({
  item,
  onClick,
  onDelete,
  withLevel = false,
  withLargeLevel = false,
  withExpectedLevel = false,
  withGrowthLevel = false,
  disabled = false,
  satisfied,
  flat = false,
  className
}, ref) => {
  const { id, title } = item || {};
  const { skill_proficiency_level } = (item as Skill) || {};
  const { inferred_level, is_inference_newer } = (item as SkillWithLevel | SkillWithLevelGenerated) || {};
  const { expected_level } = (item as SkillWithLevelGenerated) || {};
  const level = isNil(skill_proficiency_level)
    ? getSkillCurrentLevelOnly(item as SkillWithLevel | SkillWithLevelGenerated)
    : skill_proficiency_level;
  const effectiveLevel = isNil(skill_proficiency_level)
    ? getSkillCurrentLevel(item as SkillWithLevel | SkillWithLevelGenerated)
    : skill_proficiency_level;

  const handleClick = useCallback(() => onClick?.(id, level), [id, level, onClick]);
  const handleDelete = useCallback(() => onDelete?.(id, level), [id, level, onDelete]);

  const hasSatisfied = isBoolean(satisfied);

  return (
    <Chip
        ref={ref}
        icon={hasSatisfied ? (
          ((expected_level ? effectiveLevel >= expected_level : satisfied === true) && (
            <Box className={primary}>
              <CheckCircleRounded color="inherit" fontSize="inherit"/>
            </Box>
          )) || ((expected_level ? effectiveLevel < expected_level : satisfied === false) && (
            <Box className={info}>
              <ErrorRounded color="inherit" fontSize="inherit"/>
            </Box>
          ))
        ) || undefined : undefined}
        deleteIcon={<CloseIcon fontSize="inherit"/>}
        label={withLevel || withLargeLevel || withExpectedLevel || withGrowthLevel ? (
          <Box
              display="flex"
              flexDirection="column"
              pt={withLargeLevel ? 1.25 : 0.75}
              pb={withLargeLevel ? 1.75 : 0.75}
          >
            <Box pb={0.75} pl={0.25}>
              <TruncatedTextLink plain text={title} variant="inherit" maxLines={1} disabled={disabled}/>
            </Box>
            <SkillLevelGauge
                level={level}
                inferredLevel={inferred_level}
                isInferenceNewer={is_inference_newer}
                requiredLevel={(withLargeLevel && hasSatisfied) || withExpectedLevel || withGrowthLevel
                  ? expected_level || undefined : undefined}
                withGrowthLevel={withGrowthLevel}
                small={!withLargeLevel}
                withoutText
            />
          </Box>
        ) : <TruncatedTextLink plain text={title} variant="inherit" maxLines={1} disabled={disabled}/>}
        clickable={Boolean(onClick)}
        onClick={onClick ? handleClick : undefined}
        onDelete={onDelete ? handleDelete : undefined}
        disabled={disabled}
        variant="outlined"
        className={clsx(withLevel || withLargeLevel || withExpectedLevel || withGrowthLevel
          ? chipWithLevel : chip, className, { [zeroElevation]: flat })}
    />
  );
});

TbItemChip.displayName = 'TbItemChip';

TbItemChip.propTypes = TbItemChipPropTypes;

export default memo(TbItemChip);
