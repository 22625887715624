import { gql } from '@apollo/client';
// local imports
import { API_DA_JOB_MOVEMENT_SKILLS } from '../config/api';

export const DA_JOB_MOVEMENT_SKILLS_QUERY = gql`
  query DAJobMovementSkills(
    $input: DAJobMovementSkillsInput!
    $pathBuilder: String!
  ) {
    daJobMovementSkills(
      input: $input
    )
    @rest(
      type: "DAJobMovementSkillsPayload",
      path: "${API_DA_JOB_MOVEMENT_SKILLS}",
      pathBuilder: $pathBuilder
    ) {
      total_employees
      results {
        id
        title
        abbr
        total_adjacent
        total_upward
        adjacent_level_1
        adjacent_level_2
        adjacent_level_3
        adjacent_level_4
        upward_level_1
        upward_level_2
        upward_level_3
        upward_level_4
      }
    }
  }
`;
