import { gql } from '@apollo/client';
// local imports
import { API_ADMIN_PASSWORDS } from '../config/api';

export const NEW_ADMIN_PASSWORD = gql`
  mutation NewAdminPassword(
    $input: AdminPasswordInput!
  ) {
    newAdminPassword(
      input: $input
    )
    @rest(
      type: "MutationResponse",
      path: "${API_ADMIN_PASSWORDS}",
      method: "POST"
    )
    # status 201
    {
      success
    }
  }
`;
