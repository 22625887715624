import { gql } from '@apollo/client';
// local imports
import { API_DEV_PLAN } from '../config/api';

export const DEV_PLAN_SUGGESTED_ACTIVITIES_QUERY = gql`
  query DevPlanSuggestedActivities(
    $devplan_id: Int!
    $exclude_ids: String!
    $target_skill_ids: String!
  ) {
    devplanSuggestedActivities(
      devplan_id: $devplan_id,
      exclude_ids: $exclude_ids,
      target_skill_ids: $target_skill_ids
    )
    @rest(
      type: "SkillActivitiesPayload",
      path: "${API_DEV_PLAN}suggested_activities/?target_skill_ids={args.target_skill_ids}&exclude_ids={args.exclude_ids}"
    ) {
      count
      results {
        id
        code
        name
        activity_type
        description
        activity_link
        development_area
        field_support
        store
        education_style
        owner {
          id
          code
          first_name
          last_name
          email
        }
        skills {
          id
          abbr
          title
          skill_proficiency_level
        }
      }
    }
  }
`;
