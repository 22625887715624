import { gql } from '@apollo/client';
// local imports
import { API_SUPV_IN_DEMAND_SKILLS } from '../config/api';

export const MD_IN_DEMAND_SKILLS_QUERY = gql`
  query MDInDemandSkills(
    $input: MDInput!
    $pathBuilder: String!
  ) {
    mdInDemandSkills(
      input: $input
    )
    @rest(
      type: "MDInDemandSkillsPayload",
      path: "${API_SUPV_IN_DEMAND_SKILLS}",
      pathBuilder: $pathBuilder
    ) {
      job_id
      count
      employees_count
      results {
        id
        title
        abbr
        type
        employees_count @client
        counts_per_level {
          level1
          level2
          level3
          level4
        }
      }
    }
  }
`;
