import { forwardRef, memo, useCallback, useMemo, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import join from 'lodash/join';
import sortBy from 'lodash/sortBy';
import isUndefined from 'lodash/isUndefined';
import toSafeInteger from 'lodash/toSafeInteger';
// local imports
import { SkillLevel } from '../models/skillLevel';
import { ISkill } from '../models/skill';
import { injectParams } from '../helpers/path';
import StandardLink from './StandardLink';
import TruncatedTextLink, { type TruncatedTextVariant } from './TruncatedTextLink';

type SkillNameProps = {
  // React built-in
  children?: ReactNode | ReactNode[];
  // attributes
  skill: ISkill;
  level?: SkillLevel;
  levels?: SkillLevel[];
  onClick?: (skill: ISkill) => void;
  skillPath?: string;
  variant?: TruncatedTextVariant | 'h1' | 'h2' | 'h3' | 'h4' | 'inherit';
  truncated?: boolean;
  maxLines?: number;
  disabled?: boolean | null;
  className?: string;
}

const SkillNamePropTypes = {
  // React built-in
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  // attributes
  skill: PropTypes.object.isRequired as Validator<ISkill>,
  level: PropTypes.number as Validator<SkillLevel>,
  levels: PropTypes.array as Validator<SkillLevel[]>,
  onClick: PropTypes.func,
  skillPath: PropTypes.string,
  variant: PropTypes.string as Validator<TruncatedTextVariant | 'h1' | 'h2' | 'h3' | 'h4' | 'inherit'>,
  truncated: PropTypes.bool,
  maxLines: PropTypes.number,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

const SkillName = forwardRef<HTMLAnchorElement, SkillNameProps>(({
  children,
  skill,
  level,
  levels,
  onClick,
  skillPath,
  variant,
  truncated = false,
  maxLines,
  disabled = false,
  className
}, ref) => {
  const { abbr, title } = skill || {};

  const handleClick = useCallback(() => {
    if (onClick && skill && skill.id) onClick(skill);
  }, [onClick, skill]);

  const link = useMemo(() => skillPath ? injectParams(skillPath, { skill_id: abbr }) : undefined, [abbr, skillPath]);

  const suffix = useMemo(() => !levels && isUndefined(level) ? undefined : (
    <>
      {' - '}
      {levels ? join(sortBy(map(levels, toSafeInteger)), ',') : toSafeInteger(level)}
    </>
  ), [level, levels]);

  return (truncated || maxLines) && !children ? (
    <TruncatedTextLink
        ref={ref}
        variant={variant}
        text={title}
        suffix={suffix}
        maxLines={maxLines}
        to={disabled ? undefined : link}
        onClick={!disabled && onClick ? handleClick : undefined}
        className={className}
    />
  ) : (
    <StandardLink
        ref={ref}
        variant={variant}
        to={disabled ? undefined : link}
        onClick={!disabled && onClick ? handleClick : undefined}
        className={className}
    >
      {children || title}
      {suffix}
    </StandardLink>
  );
});

SkillName.displayName = 'SkillName';

SkillName.propTypes = SkillNamePropTypes;

export default memo(SkillName);
