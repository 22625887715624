// EmPath UI Components
import * as SL from '@empathco/ui-components/src/models/skillLevel';
import { type Item } from '@empathco/ui-components/src/models/item';
import { type ISkill } from '@empathco/ui-components/src/models/skill';
// local imports
import { SkillActivity } from '../graphql/types';
import { Job } from './job';
import { EmployeeCounts } from './employeeCounts';

// Skill Levels
export const {
  SKILL_LEVEL_MIN,
  SKILL_LEVEL_FIRST,
  SKILL_LEVEL_REGULAR,
  SKILL_LEVEL_ADVANCED,
  SKILL_LEVEL_TO_MENTOR,
  SKILL_LEVEL_MAX
} = SL;
export type SkillLevel = SL.SkillLevel;

export interface SimpleSkill extends Item, ISkill {
  abbr: string;
  type?: string | null;
  group?: string | null;
  // ple_link?: string | null; // UI generates links by skill abbr and/or title
}

export interface Skill extends SimpleSkill {
  current_level?: SkillLevel | null;
  actual_level?: SkillLevel | null;
  inferred_level?: SkillLevel | null;
  expected_level?: SkillLevel | null;
  target_level?: SkillLevel | null;
  is_inference?: boolean | null;
  is_inference_newer?: boolean | null;
  is_target?: boolean | null;
  is_in_demand?: boolean | null;
  is_new?: boolean | null;
  is_my?: boolean | null;
  is_mentoring?: boolean | null;
  can_mentor?: boolean | null;
  industry_trend?: string | null;
  prevalence_of_jobs?: number | null;
  frequency_of_employees?: number | null;
  course_hours?: number | null;
  course_titles?: string[] | null;
  description?: string | null;
  level_description?: string[] | null;
  jobs?: Job[] | null;
  mentors_count?: number | null;
  is_suggested_by_manager?: boolean | null;
  employees_count?: number | null;
  counts_per_level?: EmployeeCounts | null;
  total_votes?: number | null;
  skills_org_leader_codes?: string[] | null;
  level?: SkillLevel | null; // used in Talent Finder export only!
  skill_proficiency_level?: SkillLevel | null; // used in Team Builder
  satisfied?: boolean | null;
  activities?: SkillActivity[] | null;
}
