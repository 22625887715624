import { TalentFinderSort, SortDirection } from '../graphql/types';

export const DEFAULT_TF_SORT_DIRECTION = {
  [TalentFinderSort.performance_rating]: SortDirection.descending,
  [TalentFinderSort.years_on_job]: SortDirection.descending,
  [TalentFinderSort.manager]: SortDirection.ascending,
  [TalentFinderSort.location]: SortDirection.ascending,
  [TalentFinderSort.current_job]: SortDirection.ascending,
  [TalentFinderSort.employee]: SortDirection.ascending,
  [TalentFinderSort.job_match_rate]: SortDirection.descending
} as const;

export const VALID_SORT_DIRECTION = {
  [SortDirection.ascending]: true,
  [SortDirection.descending]: true
} as const;

export enum DevPlanEmployeesSort {
  employee = 'employee',
  current_job = 'current_job',
  status = 'status',
  match_rate = 'match_rate',
  courses = 'courses',
  advisors = 'advisors',
  opportunities = 'opportunities',
  activities = 'activities'
}

export const DPE_SORT_ORDERS = [
  DevPlanEmployeesSort.employee,
  DevPlanEmployeesSort.current_job,
  DevPlanEmployeesSort.status,
  DevPlanEmployeesSort.match_rate,
  DevPlanEmployeesSort.courses,
  DevPlanEmployeesSort.advisors,
  DevPlanEmployeesSort.opportunities,
  DevPlanEmployeesSort.activities
] as const;

export const DEFAULT_DPE_SORT_DIRECTION = {
  [DevPlanEmployeesSort.employee]: SortDirection.ascending,
  [DevPlanEmployeesSort.current_job]: SortDirection.ascending,
  [DevPlanEmployeesSort.status]: SortDirection.descending,
  [DevPlanEmployeesSort.match_rate]: SortDirection.descending,
  [DevPlanEmployeesSort.courses]: SortDirection.descending,
  [DevPlanEmployeesSort.advisors]: SortDirection.descending,
  [DevPlanEmployeesSort.opportunities]: SortDirection.descending,
  [DevPlanEmployeesSort.activities]: SortDirection.descending
} as const;
