export const ROLE_CONTRACTOR = 'contractor' as const;
export const ROLE_EMPLOYEE = 'employee' as const;
export const ROLE_MANAGER = 'manager' as const;
export const ROLE_LEADER = 'leader' as const;
export const ROLE_HR = 'hr' as const;
export const ROLE_ADMIN = 'admin' as const;

const USER_ROLES = [ROLE_CONTRACTOR, ROLE_EMPLOYEE, ROLE_MANAGER, ROLE_LEADER, ROLE_HR, ROLE_ADMIN] as const;

export type UserRole = typeof USER_ROLES[number];
