import { memo, useCallback, useContext, useEffect, useState, useMemo, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import get from 'lodash/get';
import omit from 'lodash/omit';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';
import toLower from 'lodash/toLower';
import filter from 'lodash/filter';
import endsWith from 'lodash/endsWith';
// EmPath UI Components
import { paramsDiffer } from '@empathco/ui-components/src/helpers/pagination';
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import ViewSwitch, {
  CHART_VIEW, TABLE_VIEW, TILES_VIEW, type ViewType
} from '@empathco/ui-components/src/elements/ViewSwitch';
// local imports
import {
  JobSort, isValidSortBy, DEFAULT_SORT_DIRECTION, JOB_SORT_ASCENDING, JOB_SORT_DESCENDING, JOB_SORT_MATCH_RATE
} from '../constants/jobSort';
import { getDataStatus, isDirtyData } from '../constants/dataStatuses';
import { Job } from '../models/job';
import useCustomerSettings from '../config/customer';
import { MAX_CLOSE_MATCH_JOBS } from '../config/params';
import useModels from '../helpers/models';
import { getSettingsBoolValue, getSettingsStrValue } from '../helpers/context';
import { DataContext } from '../context';
import { JobsIndexParams } from '../context/dataContext';
import JobsIndexFilters, { JobsIndexFilterValues } from '../v3/JobsIndexFilters';
import PaginationControls from '../v3/PaginationControls';
import RolesGrid from '../v3/RolesGrid';

type JobsIndexProps = {
  supervisor?: boolean;
  uid?: string | null;
  employeeName?: string | null;
  reducedUI?: boolean;
  showOpenReqs?: boolean;
  resetFilters?: boolean;
  onJobClick?: (roleId: string) => void;
  // for Storybook only
  viewAs?: ViewType;
}

const JobsIndexPropTypes = {
  supervisor: PropTypes.bool,
  uid: PropTypes.string,
  employeeName: PropTypes.string,
  reducedUI: PropTypes.bool,
  showOpenReqs: PropTypes.bool,
  resetFilters: PropTypes.bool,
  onJobClick: PropTypes.func,
  // for Storybook only
  viewAs: PropTypes.string as Validator<ViewType>
};

// eslint-disable-next-line complexity, max-statements
const JobsIndex: FunctionComponent<JobsIndexProps> = ({
  supervisor = false,
  uid,
  employeeName = '',
  reducedUI = false,
  showOpenReqs = false,
  resetFilters = false,
  onJobClick,
  viewAs
}) => {
  const { HAS_JOBS_CHART, HAS_JOBS_SCOPE } = useCustomerSettings();
  const { sanitizeJobsView } = useModels();
  const {
    dataStatus: { data: dataStatus, pending: pendingStatus, failed: failedStatus },
    jobsIndex: { data, count, pending, failed, params }, requireJobsIndex,
    skillsGap: { data: skillsGap, pending: pendingGap, failed: failedGap }, requireSkillsGap,
    settings: { data: settingsData, pending: pendingSettings, failed: failedSettings },
    settingsUpdate: { pending: pendingSettingsUpdate }, updateSettings
  } = useContext(DataContext);
  const dirty = !supervisor && isDirtyData(getDataStatus(dataStatus));

  const settingsLoaded = pendingSettings === false && failedSettings === false && Boolean(settingsData);
  const settings = settingsLoaded ? settingsData : null;
  const settingsId = supervisor ? 'employee_profile' : 'jobs_index';

  const settingsView = getSettingsStrValue(settings, `${settingsId}__view`);
  const settingsSort = getSettingsStrValue(settings, `${settingsId}__sort`);
  const settingsDirection = getSettingsBoolValue(settings, `${settingsId}__direction`);

  const [filters, setFilters] = useState<JobsIndexFilterValues>();
  const [view, setView] = useState<ViewType>(isNil(viewAs) ? sanitizeJobsView(settingsView, supervisor) : viewAs);

  // eslint-disable-next-line react/hook-use-state
  const [{ sort, dir }, setSort] = useState<{
    sort: JobSort;
    dir: boolean;
  }>({
    sort: isValidSortBy(settingsSort) ? settingsSort as JobSort : JOB_SORT_MATCH_RATE,
    dir: isNil(settingsDirection) ? DEFAULT_SORT_DIRECTION[JOB_SORT_MATCH_RATE] : Boolean(settingsDirection)
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>();

  const handleView = useCallback((value: string) => {
    const val = sanitizeJobsView(value, supervisor);
    setView(val);
    if (settingsView !== val) updateSettings?.({ [`${settingsId}__view`]: val });
  }, [supervisor, settingsView, updateSettings, sanitizeJobsView, settingsId]);

  const handleSort = useCallback((sortValue: string, newDir: boolean) => {
    if (isValidSortBy(sortValue)) {
      const dirValue = isBoolean(newDir) ? newDir : DEFAULT_SORT_DIRECTION[sortValue];
      setSort({
        sort: sortValue as JobSort,
        dir: dirValue
      });
      if (settingsSort !== sortValue || Boolean(settingsDirection) !== dirValue) updateSettings?.({
        [`${settingsId}__sort`]: sortValue,
        [`${settingsId}__direction`]: dirValue
      });
    }
  }, [settingsSort, settingsDirection, updateSettings, settingsId]);

  useEffect(() => {
    if (settingsLoaded && !pendingSettingsUpdate) {
      if (isNil(viewAs)) setView(sanitizeJobsView(getSettingsStrValue(settings, `${settingsId}__view`), supervisor));
      const sortFromSettings = getSettingsStrValue(settings, `${settingsId}__sort`);
      const directionFromSettings = getSettingsBoolValue(settings, `${settingsId}__direction`);
      setSort({
        sort: isValidSortBy(sortFromSettings) ? sortFromSettings as JobSort : JOB_SORT_MATCH_RATE,
        dir: isNil(directionFromSettings)
          ? DEFAULT_SORT_DIRECTION[JOB_SORT_MATCH_RATE] : Boolean(directionFromSettings)
      });
    }
  }, [supervisor, settingsLoaded, pendingSettingsUpdate, settings, viewAs, sanitizeJobsView, settingsId]);

  useEffect(() => {
    if (HAS_JOBS_CHART && !supervisor) requireSkillsGap?.();
  }, [supervisor, requireSkillsGap, HAS_JOBS_CHART]);

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (settingsLoaded && requireJobsIndex && filters && (view === CHART_VIEW || !isNil(pageSize))) {
      const newParams: JobsIndexParams = {
        ...filters,
        sort_by: view === TABLE_VIEW ? sort : JOB_SORT_MATCH_RATE,
        direction: (view === TABLE_VIEW ? dir : DEFAULT_SORT_DIRECTION[JOB_SORT_MATCH_RATE])
          ? JOB_SORT_ASCENDING : JOB_SORT_DESCENDING,
        limit: view === CHART_VIEW ? MAX_CLOSE_MATCH_JOBS : pageSize
      };
      let curPage = currentPage;
      if (paramsDiffer(supervisor && uid ? omit(params, 'selected_employee_id') : params, newParams) ||
        (supervisor && uid && toLower(uid) !== toLower(get(params, 'selected_employee_id')))
      ) {
        curPage = 1;
        setCurrentPage(1);
      }
      requireJobsIndex({
        ...newParams,
        offset: view === CHART_VIEW ? 0 : (pageSize as number) * (curPage - 1)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // ignoring `params` changes
    supervisor, uid, filters, view, sort, dir, pageSize, currentPage, requireJobsIndex, settingsLoaded
  ]);

  const closeMatchJobs = useMemo(
    () => filter(data, ({ is_index_display, is_current }) => is_index_display && !is_current) as Job[],
    [data]
  );

  const canViewAsChart = HAS_JOBS_CHART && !supervisor && (!isEmpty(skillsGap) || pendingGap || failedGap);
  const viewAsChart = view === CHART_VIEW && canViewAsChart;

  const pendingAll = !settingsLoaded || !filters || (viewAsChart && pendingGap) || pending || pendingStatus;
  const loading = pendingAll || (viewAsChart && !skillsGap);
  const failedAny = failed || failedGap || failedSettings;
  const disabled = loading || !data || failedAny;

  const values = supervisor ? {
    name: employeeName,
    endsWithS: employeeName && endsWith(employeeName, 's')
  } : undefined;

  return (
    <ContentCard>
      <CardTitle
          title={supervisor ? 'jobs_index.employee_title' : 'jobs_index.title'}
          values={values}
          withVerticalDivider={!supervisor}
          subheader={supervisor ? 'jobs_index.subheader_supv' : 'jobs_index.subheader'}
          action={settingsLoaded ? (
            <ViewSwitch
                chart={canViewAsChart ? true : undefined}
                tiles
                value={canViewAsChart || view !== CHART_VIEW ? view : TILES_VIEW}
                onChange={handleView}
                disabled={pendingAll || pendingSettingsUpdate || dirty ? true : undefined}
            />
          ) : undefined}
          withDivider
      />
      <RolesGrid
          supervisor={supervisor}
          title={supervisor ? undefined : 'jobs_index.match_rate.info'}
          withOpenReqsPopup={!supervisor}
          onClick={supervisor ? onJobClick : undefined}
          dark={!viewAsChart}
          table={view === TABLE_VIEW}
          sortBy={view === TABLE_VIEW ? sort : undefined}
          direction={view === TABLE_VIEW ? dir : undefined}
          changeSort={handleSort}
          sortDisabled={view !== TABLE_VIEW || pendingAll || pendingSettingsUpdate}
          roles={viewAsChart ? closeMatchJobs : data}
          currentRole={viewAsChart ? skillsGap : null}
          pending={pendingAll}
          failed={failedAny || failedStatus}
          dirty={dirty}
          filters={(
            <JobsIndexFilters
                settingsId={settingsId}
                visible={settingsLoaded}
                dropdownsFirst={!HAS_JOBS_SCOPE && !supervisor}
                onChange={setFilters}
                disabled={pendingAll || pendingSettingsUpdate}
                withScope={HAS_JOBS_SCOPE && !supervisor ? true : undefined}
                withSupervisory={!reducedUI}
                withMatchRate={!supervisor}
                withCategory={!HAS_JOBS_SCOPE && !supervisor}
                withLadder={!supervisor && !reducedUI}
                withOpenReqs
                withLevel
                showOpenReqs={showOpenReqs}
                resetFilters={resetFilters}
            />
          )}
          pagination={viewAsChart ? undefined : (
            <PaginationControls
                settingsId={settingsId}
                loaded={Boolean(data)}
                pending={pending}
                loading={loading}
                total={count}
                currentPage={currentPage}
                onPageSelected={setCurrentPage}
                onPageSize={setPageSize}
                disabled={disabled || pendingSettingsUpdate}
            />
          )}
      />
    </ContentCard>
  );
};

JobsIndex.propTypes = JobsIndexPropTypes;

export default memo(JobsIndex);
