/* eslint-disable max-lines */
import { createContext, useReducer, useEffect, useContext, type ReactNode, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import trim from 'lodash/trim';
import size from 'lodash/size';
import map from 'lodash/map';
import toString from 'lodash/toString';
import isSafeInteger from 'lodash/isSafeInteger';
import { useParams } from 'react-router-dom';
// EmPath UI Components
import { isEmptyString } from '@empathco/ui-components/src/helpers/strings';
// local imports
import { JobLookupItem } from '../graphql/customTypes';
import {
  SET_ACTIONS, UNAUTHENTICATED, APP_ONLINE, NAVIGATION, FETCHING, FETCHED, PARAMS, DELAYED, CommonActions
} from '../constants/actionTypes';
import {
  ContextEntity, ContextEntityProp, ContextEntityWithCount, ContextObject,
  ICountedData, ContextObjectProp, ContextEntityWithCountProp,
  ActionState, ActionEntityProp
} from '../models/contextEntity';
import { Employee } from '../models/employee';
import { Delegate } from '../models/delegate';
import { Skill, SkillLevel } from '../models/skill';
import { Job } from '../models/job';
import { TeamWithSkill } from '../models/teamWithSkill';
import { JobCandidatesCounts } from '../models/jobCandidatesCounts';
import { TbTeam } from '../models/tbTeam';
import { getSelectedLeaderId } from '../models/user';
import { EmployeeManagementLevel } from '../constants/managementLevel';
import { BestMatchesSort } from '../constants/tbBestMatchesSort';
import {
  getApiEmployee, API_USER_ME,
  API_SUPV_TEAM, API_SKILLS, API_DELEGATES,
  getApiSupvSkillEmployees, getApiSupvSkillTeams, getApiMatchingJobEmpls, getApiMatchingJobEmplsCounts
} from '../config/api';
import { MAX_MANAGER_DASHBOARD_ITEMS, MAX_MATCH_RATE, MIN_MATCH_RATE, MAX_TEAM_EMPLOYEES } from '../config/params';
import { PATH_HOME } from '../config/paths';
import { history } from '../config/history';
import {
  getInitialState, getInitialStateWithCount, getInitialObjectState,
  getPendingState, getPendingStateWithCount, getPendingObjectState,
  getFetchedState, getFetchedStateWithCount, getFetchedObjectState,
  clearActionStates, updateStateParams, updateStateObjectParams, updateStateCountParams,
  getInitialActionState, getActionFinishedState, getActionPendingState, locationParams
} from '../helpers/context';
import { getEmployeeId } from '../helpers/routerParams';
import { fetchFactory } from '../helpers/actions';
import { updateEntity } from '../helpers/reducers';
import { delegationAction } from './delegationAction';
import {
  requireTbTeams, requireTbTeam, requireTbBestMatches,
  tbAddTeam, tbUpdateTeam, tbDeleteTeam,
  tbTeamAddJob, tbTeamDeleteJob,
  tbTeamAddSkill, tbTeamUpdateSkill, tbTeamDeleteSkill, tbTeamResetSkills,
  tbTeamAddEmployee, tbTeamDeleteEmployee, updateCachedTbTeam
  // addEmployeeToCachedTbTeam, deleteEmployeeFromCachedTbTeam
} from './teamBuilder';
import { GlobalContext } from './global';
import { LimitParams, PaginationParams, FeedbackParams, LeaderParams, SelectedEmployeeParams } from './commonParams';

export interface AddedSkillsParams {
  ids?: string | null;
}

export interface ManagerDashboardParams {
  country_id?: number | null;
  state_id?: number | null;
  manager_id?: string | null;
  direct_reports_only?: boolean | null;
  org_id?: number | null;
  job_id?: number | null;
  job_levels?: EmployeeManagementLevel[] | null;
  employee_rated_only?: boolean | null;
  export_mode?: boolean | null;
}

export type DashboardFilterValues = Omit<ManagerDashboardParams, 'export_mode'>;
export type TalentFinderFilterValues = DashboardFilterValues & {
  job_id?: number | null;
};

export const managerDashboardParams = (limit: number = MAX_MANAGER_DASHBOARD_ITEMS) => ({
  country_id,
  state_id,
  manager_id,
  direct_reports_only,
  org_id,
  job_id,
  job_levels,
  employee_rated_only,
  export_mode
}: ManagerDashboardParams): ManagerDashboardParams & LimitParams => {
  const managerId = isEmptyString(manager_id) || trim(toString(manager_id)) === '0' ? null : manager_id;
   return {
    ...locationParams(country_id, state_id),
    ...managerId ? { manager_id: managerId } : {},
    ...managerId && direct_reports_only === true ? { direct_reports_only: true } : {},
    ...job_levels && isArray(job_levels) && size(job_levels) >= 1 ? { job_levels } : {},
    ...employee_rated_only === true ? { employee_rated_only: true } : {},
    ...org_id && org_id >= 1 && isSafeInteger(org_id) ? { org_id } : {},
    ...job_id && job_id >= 1 && isSafeInteger(job_id) ? { job_id } : {},
    ...export_mode === true ? {} : { limit }
  };
};

export type DashboardExportParams = DashboardFilterValues & {
  token: string;
  report_type?: 'dashboard_indemand' | 'dashboard_targetedskills' | 'dashboard_targetedjobs';
  selected_leader_id?: string;
};

export interface ManagerDashboardExtraData {
  employees_count?: number | null;
}

export interface TeamParams {
  employees_only?: boolean | null;
}

export interface EmployeesWithSkillParams {
  skill_id: string;
  state_id?: number | null;
  country_id?: number | null;
  level?: SkillLevel | null;
  offset?: number;
  limit?: number;
}

export interface TeamsWithSkillParams {
  skill_id: string,
  manager_id?: string | null;
}

export interface JobCandidatesParams {
  role_id: string;
  export_mode?: boolean | null;
  min_match_rate?: number | null;
  org_id?: number | null;
  state_id?: number | null;
  country_id?: number | null;
  offset?: number;
  limit?: number;
}

export interface JobCandidatesCountsParams {
  role_id: string;
}

export interface DelegatesListParams {
  from_id: number;
}

export interface DelegatesActionParams {
  from_id: number;
  to_id: number;
  onSuccess?: () => void;
}

type DashboardExtendedParams = ManagerDashboardParams & LeaderParams & LimitParams;

// Team Builder
// ============
// teams
export type TbTeamsParams = PaginationParams;
export interface TbAddTeamFeedback { team_id?: number; }
export interface TbAddTeamParams extends FeedbackParams<TbAddTeamFeedback> {
  title?: string;
  job_id?: number;
}
// team
export interface TbTeamParams {
  team_id: number;
}
export interface TbUpdateTeamParams extends TbTeamParams, FeedbackParams {
  team_id: number;
  title: string;
}
export type TbDeleteTeamParams = TbTeamParams & FeedbackParams;
export interface TbBestMatchesParams extends TbTeamParams {
  team_id: number;
  country_id?: number | null;
  state_id?: number | null;
  manager_id?: string | null;
  job_levels?: EmployeeManagementLevel[] | null;
  sort_by?: BestMatchesSort | null;
  limit?: number | null;
  offset?: number | null;
}
export type TbBestMatchesFilterValues = Omit<TbBestMatchesParams, 'team_id' | 'offset' | 'limit'>;
// team job roles
export interface TbTeamJobParams extends TbTeamParams, FeedbackParams {
  team_id: number;
  job_id: number;
}
export interface TbTeamAddJobParams extends TbTeamJobParams{
  job: Job | JobLookupItem;
}
// team skills
export interface TbTeamSkillParams extends TbTeamParams, FeedbackParams {
  team_id: number;
  skill_id: number;
  skill_proficiency_level: SkillLevel;
}
export interface TbTeamAddSkillParams extends TbTeamSkillParams {
  skill: Skill;
  // onSuccess?: () => void; // already defined in FeedbackParams
}
export type TbTeamUpdateSkillParams = TbTeamSkillParams;
export type TbTeamResetSkillsParams = TbTeamParams & FeedbackParams;
// team employees
export interface TbTeamEmployeeParams extends TbTeamParams, FeedbackParams {
  team_id: number;
  employee_id: number;
}
export interface TbTeamAddEmployeeParams extends TbTeamEmployeeParams {
  employee: Employee;
}

export interface ISupervisorState {
  mgrEmployee: ContextObject<Employee, SelectedEmployeeParams>;
  mgrAddedSkills: ContextEntity<Skill, AddedSkillsParams>;
  mgrTeam: ContextEntity<Employee, TeamParams & LimitParams>;
  mgrEmployeesWithSkill: ContextEntityWithCount<Employee, EmployeesWithSkillParams>;
  mgrTeamsWithSkill: ContextEntity<TeamWithSkill, TeamsWithSkillParams>;
  delegatesList: ContextEntity<Employee, DelegatesListParams>;
  jobCandidates: ContextEntityWithCount<Employee, JobCandidatesParams>;
  jobCandidatesCounts: ContextObject<JobCandidatesCounts, JobCandidatesCountsParams>;
  // Team Builder:
  tbTeams: ContextEntityWithCount<TbTeam, TbTeamsParams>;
  tbTeam: ContextObject<TbTeam, TbTeamParams>;
  tbBestMatches: ContextEntityWithCount<Employee, TbBestMatchesParams>;
  tbTeamAdd: ActionState<TbAddTeamParams>;
  tbTeamUpdate: ActionState<TbUpdateTeamParams>;
  tbTeamDelete: ActionState<TbDeleteTeamParams>;
  tbTeamJobAdd: ActionState<TbTeamAddJobParams>;
  tbTeamJobDelete: ActionState<TbTeamJobParams>;
  tbTeamSkillAdd: ActionState<TbTeamAddSkillParams>;
  tbTeamSkillUpdate: ActionState<TbTeamUpdateSkillParams>;
  tbTeamSkillDelete: ActionState<TbTeamSkillParams>;
  tbTeamSkillsReset: ActionState<TbTeamResetSkillsParams>;
  tbTeamEmployeeAdd: ActionState<TbTeamAddEmployeeParams>;
  tbTeamEmployeeDelete: ActionState<TbTeamEmployeeParams>;
  // fetching:
  requireEmployee?: () => void;
  requireAddedSkills?: (params: AddedSkillsParams) => void;
  requireTeam?: (params: TeamParams) => void;
  requireEmployeesWithSkill?: (params: EmployeesWithSkillParams) => void;
  requireTeamsWithSkill?: (params: TeamsWithSkillParams) => void;
  requireDelegatesList?: (params: DelegatesListParams) => void;
  requireJobCandidates?: (params: JobCandidatesParams) => void;
  requireJobCandidatesCounts?: (params: JobCandidatesCountsParams) => void;
  // Team Builder:
  requireTbTeams?: (params: TbTeamsParams) => void;
  requireTbTeam?: (params: TbTeamParams) => void;
  requireTbBestMatches?: (params: TbBestMatchesParams) => void;
  tbAddTeam?: (params: TbAddTeamParams) => void;
  tbUpdateTeam?: (params: TbUpdateTeamParams) => void;
  tbDeleteTeam?: (params: TbDeleteTeamParams) => void;
  tbTeamAddJob?: (params: TbTeamAddJobParams) => void;
  tbTeamDeleteJob?: (params: TbTeamJobParams) => void;
  tbTeamAddSkill?: (params: TbTeamAddSkillParams) => void;
  tbTeamUpdateSkill?: (params: TbTeamUpdateSkillParams) => void;
  tbTeamDeleteSkill?: (params: TbTeamSkillParams) => void;
  tbTeamResetSkills?: (params: TbTeamResetSkillsParams) => void;
  tbTeamAddEmployee?: (params: TbTeamAddEmployeeParams) => void;
  tbTeamDeleteEmployee?: (params: TbTeamEmployeeParams) => void;
  // HR actions:
  delegateAdd: ActionState<DelegatesActionParams>;
  delegateRemove: ActionState<DelegatesActionParams>;
  addDelegate?: (params: DelegatesActionParams) => void;
  removeDelegate?: (params: DelegatesActionParams) => void;
}

export const SupervisorStatePropTypes = PropTypes.shape({
  mgrEmployee: ContextObjectProp,
  mgrAddedSkills: ContextEntityProp,
  mgrTeam: ContextEntityProp,
  mgrEmployeesWithSkill: ContextEntityWithCountProp,
  mgrTeamsWithSkill: ContextEntityProp,
  delegatesList: ContextEntityProp,
  jobCandidates: ContextEntityWithCountProp,
  jobCandidatesCounts: ContextObjectProp,

  tbTeams: ContextEntityWithCountProp,
  tbTeam: ContextObjectProp,
  tbBestMatches: ContextEntityWithCountProp,
  tbTeamAdd: ActionEntityProp,
  tbTeamUpdate: ActionEntityProp,
  tbTeamDelete: ActionEntityProp,
  tbTeamJobAdd: ActionEntityProp,
  tbTeamJobDelete: ActionEntityProp,
  tbTeamSkillAdd: ActionEntityProp,
  tbTeamSkillUpdate: ActionEntityProp,
  tbTeamSkillDelete: ActionEntityProp,
  tbTeamSkillsReset: ActionEntityProp,
  tbTeamEmployeeAdd: ActionEntityProp,
  tbTeamEmployeeDelete: ActionEntityProp,

  requireEmployee: PropTypes.func,
  requireAddedSkills: PropTypes.func,
  requireTeam: PropTypes.func,
  requireEmployeesWithSkill: PropTypes.func,
  requireTeamsWithSkill: PropTypes.func,
  requireDelegatesList: PropTypes.func,
  requireJobCandidates: PropTypes.func,
  requireJobCandidatesCounts: PropTypes.func,

  requireTbTeams: PropTypes.func,
  requireTbTeam: PropTypes.func,
  requireTbBestMatches: PropTypes.func,
  tbAddTeam: PropTypes.func,
  tbUpdateTeam: PropTypes.func,
  tbDeleteTeam: PropTypes.func,
  tbTeamAddJob: PropTypes.func,
  tbTeamDeleteJob: PropTypes.func,
  tbTeamAddSkill: PropTypes.func,
  tbTeamUpdateSkill: PropTypes.func,
  tbTeamDeleteSkill: PropTypes.func,
  tbTeamResetSkills: PropTypes.func,
  tbTeamAddEmployee: PropTypes.func,
  tbTeamDeleteEmployee: PropTypes.func,

  // HR actions:
  delegateAdd: ActionEntityProp,
  delegateRemove: ActionEntityProp,
  addDelegate: PropTypes.func,
  removeDelegate: PropTypes.func
}) as Validator<ISupervisorState>;

export const initialSupervisorState: ISupervisorState = {
  // fetched data
  mgrEmployee: getInitialObjectState(),
  mgrAddedSkills: getInitialState(),
  mgrTeam: getInitialState(),
  mgrEmployeesWithSkill: getInitialStateWithCount(),
  mgrTeamsWithSkill: getInitialState(),
  delegatesList: getInitialState(),
  jobCandidates: getInitialStateWithCount(),
  jobCandidatesCounts: getInitialObjectState(),

  tbTeams: getInitialStateWithCount(),
  tbTeam: getInitialObjectState(),
  tbBestMatches: getInitialStateWithCount(),
  tbTeamAdd: getInitialActionState(),
  tbTeamUpdate: getInitialActionState(),
  tbTeamDelete: getInitialActionState(),
  tbTeamJobAdd: getInitialActionState(),
  tbTeamJobDelete: getInitialActionState(),
  tbTeamSkillAdd: getInitialActionState(),
  tbTeamSkillUpdate: getInitialActionState(),
  tbTeamSkillDelete: getInitialActionState(),
  tbTeamSkillsReset: getInitialActionState(),
  tbTeamEmployeeAdd: getInitialActionState(),
  tbTeamEmployeeDelete: getInitialActionState(),

  delegateAdd: getInitialActionState(),
  delegateRemove: getInitialActionState()
};

// Transformation Functions - must NOT mutate the objects!

export const transformDelegates = (delegates: Delegate[]) => map(delegates, ({ delegate, delegator }) => ({
  ...delegate,
  delegator
} as Employee));

// Employee
const EMPLOYEE_FETCH = 'EMPLOYEE' as const;
const EMPLOYEE_FETCHING = `${EMPLOYEE_FETCH}${FETCHING}` as const;
const EMPLOYEE_FETCHED = `${EMPLOYEE_FETCH}${FETCHED}` as const;
const EMPLOYEE_PARAMS = `${EMPLOYEE_FETCH}${PARAMS}` as const;
// Supervisor Added Skills fetch
const SUPV_ADDED_SKILLS_FETCH = 'SUPV_ADDED_SKILLS' as const;
const SUPV_ADDED_SKILLS_FETCHING = `${SUPV_ADDED_SKILLS_FETCH}${FETCHING}` as const;
const SUPV_ADDED_SKILLS_FETCHED = `${SUPV_ADDED_SKILLS_FETCH}${FETCHED}` as const;
const SUPV_ADDED_SKILLS_PARAMS = `${SUPV_ADDED_SKILLS_FETCH}${PARAMS}` as const;
// Supervisor Top In-Demand Skills fetch
const SUPV_SKLS_FETCH = 'SUPV_SKLS' as const;
const SUPV_SKLS_FETCHING = `${SUPV_SKLS_FETCH}${FETCHING}` as const;
const SUPV_SKLS_FETCHED = `${SUPV_SKLS_FETCH}${FETCHED}` as const;
const SUPV_SKLS_PARAMS = `${SUPV_SKLS_FETCH}${PARAMS}` as const;
const SUPV_SKLS_DELAYED = `${SUPV_SKLS_FETCH}${DELAYED}` as const;
// Supervisor Top Targeted Skills fetch
const SUPV_TARGETED_SKLS_FETCH = 'SUPV_TARGETED_SKLS' as const;
const SUPV_TARGETED_SKLS_FETCHING = `${SUPV_TARGETED_SKLS_FETCH}${FETCHING}` as const;
const SUPV_TARGETED_SKLS_FETCHED = `${SUPV_TARGETED_SKLS_FETCH}${FETCHED}` as const;
const SUPV_TARGETED_SKLS_PARAMS = `${SUPV_TARGETED_SKLS_FETCH}${PARAMS}` as const;
const SUPV_TARGETED_SKLS_DELAYED = `${SUPV_TARGETED_SKLS_FETCH}${DELAYED}` as const;
// Supervisor Top Targeted Jobs fetch
const SUPV_TARGETED_JOBS_FETCH = 'SUPV_TARGETED_JOBS' as const;
const SUPV_TARGETED_JOBS_FETCHING = `${SUPV_TARGETED_JOBS_FETCH}${FETCHING}` as const;
const SUPV_TARGETED_JOBS_FETCHED = `${SUPV_TARGETED_JOBS_FETCH}${FETCHED}` as const;
const SUPV_TARGETED_JOBS_PARAMS = `${SUPV_TARGETED_JOBS_FETCH}${PARAMS}` as const;
const SUPV_TARGETED_JOBS_DELAYED = `${SUPV_TARGETED_JOBS_FETCH}${DELAYED}` as const;
// Supervisor Top Targeted Skills fetch
const SUPV_SKILLS_GAP_FETCH = 'SUPV_SKILLS_GAP' as const;
const SUPV_SKILLS_GAP_FETCHING = `${SUPV_SKILLS_GAP_FETCH}${FETCHING}` as const;
const SUPV_SKILLS_GAP_FETCHED = `${SUPV_SKILLS_GAP_FETCH}${FETCHED}` as const;
const SUPV_SKILLS_GAP_PARAMS = `${SUPV_SKILLS_GAP_FETCH}${PARAMS}` as const;
const SUPV_SKILLS_GAP_DELAYED = `${SUPV_SKILLS_GAP_FETCH}${DELAYED}` as const;
// Team
const TEAM_FETCH = 'TEAM' as const;
const TEAM_FETCHING = `${TEAM_FETCH}${FETCHING}` as const;
const TEAM_FETCHED = `${TEAM_FETCH}${FETCHED}` as const;
const TEAM_PARAMS = `${TEAM_FETCH}${PARAMS}` as const;
// Emploees with skill through org hierarchy
const EMPLOYEES_WITH_SKILL_FETCH = 'EMPLOYEES_WITH_SKILL' as const;
const EMPLOYEES_WITH_SKILL_FETCHING = `${EMPLOYEES_WITH_SKILL_FETCH}${FETCHING}` as const;
const EMPLOYEES_WITH_SKILL_FETCHED = `${EMPLOYEES_WITH_SKILL_FETCH}${FETCHED}` as const;
const EMPLOYEES_WITH_SKILL_PARAMS = `${EMPLOYEES_WITH_SKILL_FETCH}${PARAMS}` as const;
// Teams with skill through org hierarchy
const TEAMS_WITH_SKILL_FETCH = 'TEAMS_WITH_SKILL' as const;
const TEAMS_WITH_SKILL_FETCHING = `${TEAMS_WITH_SKILL_FETCH}${FETCHING}` as const;
const TEAMS_WITH_SKILL_FETCHED = `${TEAMS_WITH_SKILL_FETCH}${FETCHED}` as const;
const TEAMS_WITH_SKILL_PARAMS = `${TEAMS_WITH_SKILL_FETCH}${PARAMS}` as const;
// Best Job Candidates
const JOB_CANDIDATES_FETCH = 'JOB_CANDIDATES' as const;
const JOB_CANDIDATES_FETCHING = `${JOB_CANDIDATES_FETCH}${FETCHING}` as const;
const JOB_CANDIDATES_FETCHED = `${JOB_CANDIDATES_FETCH}${FETCHED}` as const;
const JOB_CANDIDATES_PARAMS = `${JOB_CANDIDATES_FETCH}${PARAMS}` as const;
// Best Job Candidates Counts
const JOB_CANDIDATES_COUNT_FETCH = 'JOB_CANDIDATES_COUNT' as const;
const JOB_CANDIDATES_COUNT_FETCHING = `${JOB_CANDIDATES_COUNT_FETCH}${FETCHING}` as const;
const JOB_CANDIDATES_COUNT_FETCHED = `${JOB_CANDIDATES_COUNT_FETCH}${FETCHED}` as const;
const JOB_CANDIDATES_COUNT_PARAMS = `${JOB_CANDIDATES_COUNT_FETCH}${PARAMS}` as const;
// Delegates List
const DELEGATES_LIST_FETCH = 'DELEGATES' as const;
const DELEGATES_LIST_FETCHING = `${DELEGATES_LIST_FETCH}${FETCHING}` as const;
const DELEGATES_LIST_FETCHED = `${DELEGATES_LIST_FETCH}${FETCHED}` as const;
const DELEGATES_LIST_PARAMS = `${DELEGATES_LIST_FETCH}${PARAMS}` as const;
// Delegation actions
const DELEGATE_ADDING = 'DELEGATE_ADDING' as const;
const DELEGATE_ADDED = 'DELEGATE_ADDED' as const;
const DELEGATE_REMOVING = 'DELEGATE_REMOVING' as const;
const DELEGATE_REMOVED = 'DELEGATE_REMOVED' as const;

// Team Builder
// ============
// Teams
export const TB_TEAMS_FETCH = 'TB_TEAMS' as const;
const TB_TEAMS_FETCHING = `${TB_TEAMS_FETCH}${FETCHING}` as const;
const TB_TEAMS_FETCHED = `${TB_TEAMS_FETCH}${FETCHED}` as const;
const TB_TEAMS_PARAMS = `${TB_TEAMS_FETCH}${PARAMS}` as const;
// Team
export const TB_TEAM_FETCH = 'TB_TEAM' as const;
const TB_TEAM_FETCHING = `${TB_TEAM_FETCH}${FETCHING}` as const;
const TB_TEAM_FETCHED = `${TB_TEAM_FETCH}${FETCHED}` as const;
const TB_TEAM_PARAMS = `${TB_TEAM_FETCH}${PARAMS}` as const;
// Best Matches
export const TB_MATCHES_FETCH = 'TB_MATCHES' as const;
const TB_MATCHES_FETCHING = `${TB_MATCHES_FETCH}${FETCHING}` as const;
const TB_MATCHES_FETCHED = `${TB_MATCHES_FETCH}${FETCHED}` as const;
const TB_MATCHES_PARAMS = `${TB_MATCHES_FETCH}${PARAMS}` as const;
// Team actions:
export type TeamBuilderAction =
  'TB_TEAM_ADD' | 'TB_TEAM_UPDAT' | 'TB_TEAM_DELET' |
  'TB_TEAM_JOB_ADD' | 'TB_TEAM_JOB_DELET' |
  'TB_TEAM_SKILL_ADD' | 'TB_TEAM_SKILL_UPDAT' | 'TB_TEAM_SKILL_DELET' | 'TB_TEAM_SKILLS_CLEAR' |
  'TB_TEAM_EMPLOYEE_ADD' | 'TB_TEAM_EMPLOYEE_DELET';
const TB_TEAM_ADDING = 'TB_TEAM_ADDING' as const;
const TB_TEAM_ADDED = 'TB_TEAM_ADDED' as const;
const TB_TEAM_UPDATING = 'TB_TEAM_UPDATING' as const;
const TB_TEAM_UPDATED = 'TB_TEAM_UPDATED' as const;
const TB_TEAM_DELETING = 'TB_TEAM_DELETING' as const;
const TB_TEAM_DELETED = 'TB_TEAM_DELETED' as const;
// Team Job Role actions:
const TB_TEAM_JOB_ADDING = 'TB_TEAM_JOB_ADDING' as const;
const TB_TEAM_JOB_ADDED = 'TB_TEAM_JOB_ADDED' as const;
const TB_TEAM_JOB_DELETING = 'TB_TEAM_JOB_DELETING' as const;
const TB_TEAM_JOB_DELETED = 'TB_TEAM_JOB_DELETED' as const;
// Team Skill actions:
const TB_TEAM_SKILL_ADDING = 'TB_TEAM_SKILL_ADDING' as const;
const TB_TEAM_SKILL_ADDED = 'TB_TEAM_SKILL_ADDED' as const;
const TB_TEAM_SKILL_UPDATING = 'TB_TEAM_SKILL_UPDATING' as const;
const TB_TEAM_SKILL_UPDATED = 'TB_TEAM_SKILL_UPDATED' as const;
const TB_TEAM_SKILL_DELETING = 'TB_TEAM_SKILL_DELETING' as const;
const TB_TEAM_SKILL_DELETED = 'TB_TEAM_SKILL_DELETED' as const;
const TB_TEAM_SKILLS_CLEARING = 'TB_TEAM_SKILLS_CLEARING' as const;
const TB_TEAM_SKILLS_CLEARED = 'TB_TEAM_SKILLS_CLEARED' as const;
// Team Employee actions:
const TB_TEAM_EMPLOYEE_ADDING = 'TB_TEAM_EMPLOYEE_ADDING' as const;
const TB_TEAM_EMPLOYEE_ADDED = 'TB_TEAM_EMPLOYEE_ADDED' as const;
const TB_TEAM_EMPLOYEE_DELETING = 'TB_TEAM_EMPLOYEE_DELETING' as const;
const TB_TEAM_EMPLOYEE_DELETED = 'TB_TEAM_EMPLOYEE_DELETED' as const;

export type SupervisorActions =
  | { type: typeof EMPLOYEE_FETCHING; payload?: null; params: {} | null; }
  | { type: typeof EMPLOYEE_FETCHED; payload: Employee | null; params: {}; }
  | { type: typeof EMPLOYEE_PARAMS; params: {}; }
  | { type: typeof SUPV_ADDED_SKILLS_FETCHING; payload?: null; params: AddedSkillsParams | null; }
  | { type: typeof SUPV_ADDED_SKILLS_FETCHED; payload: Skill[] | null; params: AddedSkillsParams; }
  | { type: typeof SUPV_ADDED_SKILLS_PARAMS; params: AddedSkillsParams; }
  | { type: typeof SUPV_SKLS_FETCHING; payload?: null; params: DashboardExtendedParams | null; }
  | { type: typeof SUPV_SKLS_FETCHED; payload: ICountedData<Skill> & ManagerDashboardExtraData | null;
      params: DashboardExtendedParams; }
  | { type: typeof SUPV_SKLS_PARAMS; params: DashboardExtendedParams; }
  | { type: typeof SUPV_SKLS_DELAYED; payload: number; params?: DashboardExtendedParams | null; }
  | { type: typeof SUPV_TARGETED_SKLS_FETCHING; payload?: null; params: DashboardExtendedParams | null; }
  | { type: typeof SUPV_TARGETED_SKLS_FETCHED; payload: ICountedData<Skill> & ManagerDashboardExtraData | null;
      params: DashboardExtendedParams; }
  | { type: typeof SUPV_TARGETED_SKLS_PARAMS; params: DashboardExtendedParams; }
  | { type: typeof SUPV_TARGETED_SKLS_DELAYED; payload: number; params?: DashboardExtendedParams | null; }
  | { type: typeof SUPV_TARGETED_JOBS_FETCHING; payload?: null; params: DashboardExtendedParams | null; }
  | { type: typeof SUPV_TARGETED_JOBS_FETCHED; payload: ICountedData<Job> & ManagerDashboardExtraData | null;
      params: DashboardExtendedParams; }
  | { type: typeof SUPV_TARGETED_JOBS_PARAMS; params: DashboardExtendedParams; }
  | { type: typeof SUPV_TARGETED_JOBS_DELAYED; payload: number; params?: DashboardExtendedParams | null; }
  | { type: typeof SUPV_SKILLS_GAP_FETCHING; payload?: null; params: DashboardExtendedParams | null; }
  | { type: typeof SUPV_SKILLS_GAP_FETCHED; payload: ICountedData<Job> & ManagerDashboardExtraData | null;
      params: DashboardExtendedParams; }
  | { type: typeof SUPV_SKILLS_GAP_PARAMS; params: DashboardExtendedParams; }
  | { type: typeof SUPV_SKILLS_GAP_DELAYED; payload: number; params?: DashboardExtendedParams | null; }
  | { type: typeof TEAM_FETCHING; payload?: null; params: TeamParams & LimitParams | null; }
  | { type: typeof TEAM_FETCHED; payload: Employee[] | null; params: TeamParams & LimitParams; }
  | { type: typeof TEAM_PARAMS; params: TeamParams & LimitParams; }
  | { type: typeof EMPLOYEES_WITH_SKILL_FETCHING; payload?: null; params: EmployeesWithSkillParams | null; }
  | { type: typeof EMPLOYEES_WITH_SKILL_FETCHED; payload: ICountedData<Employee> | null; params: EmployeesWithSkillParams; }
  | { type: typeof EMPLOYEES_WITH_SKILL_PARAMS; params: EmployeesWithSkillParams; }
  | { type: typeof TEAMS_WITH_SKILL_FETCHING; payload?: null; params: TeamsWithSkillParams | null; }
  | { type: typeof TEAMS_WITH_SKILL_FETCHED; payload: TeamWithSkill[] | null; params: TeamsWithSkillParams; }
  | { type: typeof TEAMS_WITH_SKILL_PARAMS; params: TeamsWithSkillParams; }
  | { type: typeof JOB_CANDIDATES_FETCHING; payload?: null; params: JobCandidatesParams | null; }
  | { type: typeof JOB_CANDIDATES_FETCHED; payload: ICountedData<Employee> | null; params: JobCandidatesParams; }
  | { type: typeof JOB_CANDIDATES_PARAMS; params: JobCandidatesParams; }
  | { type: typeof JOB_CANDIDATES_COUNT_FETCHING; payload?: null; params: JobCandidatesCountsParams; }
  | { type: typeof JOB_CANDIDATES_COUNT_FETCHED; payload: JobCandidatesCounts | null; params: JobCandidatesCountsParams; }
  | { type: typeof JOB_CANDIDATES_COUNT_PARAMS; params: JobCandidatesCountsParams; }
  | { type: typeof DELEGATES_LIST_FETCHING; payload?: null; params: DelegatesListParams | null; }
  | { type: typeof DELEGATES_LIST_FETCHED; payload: Employee[] | null; params: DelegatesListParams; }
  | { type: typeof DELEGATES_LIST_PARAMS; params: DelegatesListParams; }
  | { type: typeof DELEGATE_ADDING; payload?: boolean | null; params: DelegatesActionParams; }
  | { type: typeof DELEGATE_ADDED; payload: boolean; params: DelegatesActionParams; }
  | { type: typeof DELEGATE_REMOVING; payload?: boolean | null; params: DelegatesActionParams; }
  | { type: typeof DELEGATE_REMOVED; payload: boolean; params: DelegatesActionParams; }
  // Team Builder
  | { type: typeof TB_TEAMS_FETCHING; payload?: null; params: TbTeamsParams | null; }
  | { type: typeof TB_TEAMS_FETCHED; payload: ICountedData<TbTeam> | null; params: TbTeamsParams; }
  | { type: typeof TB_TEAMS_PARAMS; params: TbTeamsParams; }
  | { type: typeof TB_TEAM_FETCHING; payload?: null; params: TbTeamParams | null; }
  | { type: typeof TB_TEAM_FETCHED; payload: TbTeam | null; params: TbTeamParams; }
  | { type: typeof TB_TEAM_PARAMS; params: TbTeamParams; }
  | { type: typeof TB_MATCHES_FETCHING; payload?: null; params: TbBestMatchesParams | null; }
  | { type: typeof TB_MATCHES_FETCHED; payload: ICountedData<Employee> | null; params: TbBestMatchesParams; }
  | { type: typeof TB_MATCHES_PARAMS; params: TbBestMatchesParams; }
  | { type: typeof TB_TEAM_ADDING; payload?: boolean | null; params: TbAddTeamParams; }
  | { type: typeof TB_TEAM_ADDED; payload: boolean; params: TbAddTeamParams; }
  | { type: typeof TB_TEAM_UPDATING; payload?: boolean | null; params: TbUpdateTeamParams; }
  | { type: typeof TB_TEAM_UPDATED; payload: boolean; params: TbUpdateTeamParams; }
  | { type: typeof TB_TEAM_DELETING; payload?: boolean | null; params: TbTeamParams; }
  | { type: typeof TB_TEAM_DELETED; payload: boolean; params: TbTeamParams; }
  | { type: typeof TB_TEAM_JOB_ADDING; payload?: boolean | null; params: TbTeamAddJobParams; }
  | { type: typeof TB_TEAM_JOB_ADDED; payload: boolean; params: TbTeamAddJobParams; }
  | { type: typeof TB_TEAM_JOB_DELETING; payload?: boolean | null; params: TbTeamJobParams; }
  | { type: typeof TB_TEAM_JOB_DELETED; payload: boolean; params: TbTeamJobParams; }
  | { type: typeof TB_TEAM_SKILL_ADDING; payload?: boolean | null; params: TbTeamAddSkillParams; }
  | { type: typeof TB_TEAM_SKILL_ADDED; payload: boolean; params: TbTeamAddSkillParams; }
  | { type: typeof TB_TEAM_SKILL_UPDATING; payload?: boolean | null; params: TbTeamUpdateSkillParams; }
  | { type: typeof TB_TEAM_SKILL_UPDATED; payload: boolean; params: TbTeamUpdateSkillParams; }
  | { type: typeof TB_TEAM_SKILL_DELETING; payload?: boolean | null; params: TbTeamSkillParams; }
  | { type: typeof TB_TEAM_SKILL_DELETED; payload: boolean; params: TbTeamSkillParams; }
  | { type: typeof TB_TEAM_SKILLS_CLEARING; payload?: boolean | null; params: TbTeamResetSkillsParams; }
  | { type: typeof TB_TEAM_SKILLS_CLEARED; payload: boolean; params: TbTeamResetSkillsParams; }
  | { type: typeof TB_TEAM_EMPLOYEE_ADDING; payload?: boolean | null; params: TbTeamAddEmployeeParams; }
  | { type: typeof TB_TEAM_EMPLOYEE_ADDED; payload: boolean; params: TbTeamAddEmployeeParams; }
  | { type: typeof TB_TEAM_EMPLOYEE_DELETING; payload?: boolean | null; params: TbTeamEmployeeParams; }
  | { type: typeof TB_TEAM_EMPLOYEE_DELETED; payload: boolean; params: TbTeamEmployeeParams; }
  | CommonActions;

// eslint-disable-next-line complexity, max-lines-per-function
const supervisorReducer = (state: ISupervisorState, action: SupervisorActions): ISupervisorState => {
  switch (action.type) {

    case SET_ACTIONS: return { ...state, ...action.payload };

    case EMPLOYEE_FETCHING: return { ...state, mgrEmployee: getPendingObjectState(action.params) };
    case EMPLOYEE_FETCHED: return { ...state, mgrEmployee: getFetchedObjectState(action.payload, action.params) };
    case EMPLOYEE_PARAMS: return { ...state, mgrEmployee: updateStateObjectParams(state.mgrEmployee, action.params) };

    case SUPV_ADDED_SKILLS_FETCHING: return { ...state, mgrAddedSkills: getPendingState(action.params) };
    case SUPV_ADDED_SKILLS_FETCHED: return { ...state, mgrAddedSkills: getFetchedState(action.payload, action.params) };
    case SUPV_ADDED_SKILLS_PARAMS: return { ...state, mgrAddedSkills: updateStateParams(state.mgrAddedSkills, action.params) };

    case TEAM_FETCHING: return { ...state, mgrTeam: getPendingState(action.params) };
    case TEAM_FETCHED: return { ...state, mgrTeam: getFetchedState(action.payload, action.params) };
    case TEAM_PARAMS: return { ...state, mgrTeam: updateStateParams(state.mgrTeam, action.params) };

    // <Team Builder>

    case TB_TEAMS_FETCHING: return { ...state, tbTeams: getPendingStateWithCount(action.params) };
    case TB_TEAMS_FETCHED: return { ...state, tbTeams: getFetchedStateWithCount(action.payload, action.params) };
    case TB_TEAMS_PARAMS: return { ...state, tbTeams: updateStateCountParams(state.tbTeams, action.params) };

    case TB_TEAM_FETCHING: return { ...state, tbTeam: getPendingObjectState(action.params) };
    case TB_TEAM_FETCHED: return { ...state, tbTeam: getFetchedObjectState(action.payload, action.params) };
    case TB_TEAM_PARAMS: return { ...state, tbTeam: updateStateObjectParams(state.tbTeam, action.params) };

    case TB_MATCHES_FETCHING: return { ...state, tbBestMatches: getPendingStateWithCount(action.params) };
    case TB_MATCHES_FETCHED: return { ...state, tbBestMatches: getFetchedStateWithCount(action.payload, action.params) };
    case TB_MATCHES_PARAMS: return { ...state, tbBestMatches: updateStateCountParams(state.tbBestMatches, action.params) };

    case TB_TEAM_ADDING: return { ...state, tbTeamAdd: getActionPendingState(action.params) };
    case TB_TEAM_UPDATING: return { ...state, tbTeamUpdate: getActionPendingState(action.params) };
    case TB_TEAM_DELETING: return { ...state, tbTeamDelete: getActionPendingState(action.params) };

    case TB_TEAM_JOB_ADDING: return { ...state, tbTeamJobAdd: getActionPendingState(action.params) };
    case TB_TEAM_JOB_DELETING: return { ...state, tbTeamJobDelete: getActionPendingState(action.params) };
    case TB_TEAM_SKILL_ADDING: return { ...state, tbTeamSkillAdd: getActionPendingState(action.params) };
    case TB_TEAM_SKILL_UPDATING: return { ...state, tbTeamSkillUpdate: getActionPendingState(action.params) };
    case TB_TEAM_SKILL_DELETING: return { ...state, tbTeamSkillDelete: getActionPendingState(action.params) };
    case TB_TEAM_SKILLS_CLEARING: return { ...state, tbTeamSkillsReset: getActionPendingState(action.params) };
    case TB_TEAM_EMPLOYEE_ADDING: return { ...state, tbTeamEmployeeAdd: getActionPendingState(action.params) };
    case TB_TEAM_EMPLOYEE_DELETING: return { ...state, tbTeamEmployeeDelete: getActionPendingState(action.params) };

    case TB_TEAM_ADDED: return {
      ...state, ...clearActionStates(state),
      ...action.payload ? {
        tbTeams: getInitialStateWithCount(), // reload teams
        tbBestMatches: getInitialStateWithCount() // drop cached best matches
      } : {},
      tbTeamAdd: getActionFinishedState(action.payload, action.params)
    };
    case TB_TEAM_UPDATED: return {
      ...state, ...clearActionStates(state),
      ...action.payload ? {
        tbTeams: getInitialStateWithCount(), // TODO: update team title in `tbTeams`
        ...action.params
          ? updateEntity(state, 'tbTeam', (tbTeam: TbTeam | null) => updateCachedTbTeam(tbTeam, action.params)) : {}
      } : {},
      tbTeamUpdate: getActionFinishedState(action.payload, action.params)
    };
    case TB_TEAM_DELETED: return {
      ...state, ...clearActionStates(state),
      ...action.payload ? {
        tbTeam: getInitialObjectState(), // drop cached team details
        tbTeams: getInitialStateWithCount(), // TODO: just delete the team from cached `tbTeams` (and `--count`)
        tbBestMatches: getInitialStateWithCount() // drop cached best matches
      } : {},
      tbTeamDelete: getActionFinishedState(action.payload, action.params)
    };

    case TB_TEAM_JOB_ADDED: return {
      ...state, ...clearActionStates(state),
      ...action.payload ? {
        // TODO: update team without reload?
        tbTeam: getInitialObjectState(), // reload team
        tbTeams: getInitialStateWithCount(), // TODO: update just updated_at
        tbBestMatches: getInitialStateWithCount() // reload best matches
      } : {},
      tbTeamJobAdd: getActionFinishedState(action.payload, action.params)
    };
    case TB_TEAM_JOB_DELETED: return {
      ...state, ...clearActionStates(state),
      ...action.payload ? {
        // TODO: update team without reload?
        tbTeam: getInitialObjectState(), // reload team
        tbTeams: getInitialStateWithCount(), // TODO: update just updated_at
        tbBestMatches: getInitialStateWithCount() // reload best matches
      } : {},
      tbTeamJobDelete: getActionFinishedState(action.payload, action.params)
    };

    case TB_TEAM_SKILL_ADDED: return {
      ...state, ...clearActionStates(state),
      ...action.payload ? {
        // TODO: update team without reload?
        tbTeam: getInitialObjectState(), // reload team
        tbTeams: getInitialStateWithCount(), // TODO: update just updated_at
        tbBestMatches: getInitialStateWithCount() // reload best matches
      } : {},
      tbTeamSkillAdd: getActionFinishedState(action.payload, action.params)
    };
    case TB_TEAM_SKILL_UPDATED: return {
      ...state, ...clearActionStates(state),
      ...action.payload ? {
        // TODO: update team without reload?
        tbTeam: getInitialObjectState(), // reload team
        tbTeams: getInitialStateWithCount(), // TODO: update just updated_at
        tbBestMatches: getInitialStateWithCount() // reload best matches
      } : {},
      tbTeamSkillUpdate: getActionFinishedState(action.payload, action.params)
    };
    case TB_TEAM_SKILL_DELETED: return {
      ...state, ...clearActionStates(state),
      ...action.payload ? {
        // TODO: update team without reload?
        tbTeam: getInitialObjectState(), // reload team
        tbTeams: getInitialStateWithCount(), // TODO: update just updated_at
        tbBestMatches: getInitialStateWithCount() // reload best matches
      } : {},
      tbTeamSkillDelete: getActionFinishedState(action.payload, action.params)
    };
    case TB_TEAM_SKILLS_CLEARED: return {
      ...state, ...clearActionStates(state),
      ...action.payload ? {
        // TODO: update team without reload?
        tbTeam: getInitialObjectState(), // reload team
        tbTeams: getInitialStateWithCount(), // TODO: update just updated_at
        tbBestMatches: getInitialStateWithCount() // reload best matches
      } : {},
      tbTeamSkillsReset: getActionFinishedState(action.payload, action.params)
    };

    case TB_TEAM_EMPLOYEE_ADDED: return {
      ...state, ...clearActionStates(state),
      ...action.payload ? {
        tbTeams: getInitialStateWithCount(), // TODO: update just updated_at and employees_count
        tbBestMatches: getInitialStateWithCount(), // reload best matches
        tbTeam: getInitialObjectState() // reload team
        // TODO: reload team skills - need updated statuses:
        // ...action.params
        //   ? updateEntity(state, 'tbTeam', (tbTeam: TbTeam | null) => addEmployeeToCachedTbTeam(tbTeam, action.params)) : {}
      } : {},
      tbTeamEmployeeAdd: getActionFinishedState(action.payload, action.params)
    };
    case TB_TEAM_EMPLOYEE_DELETED: return {
      ...state, ...clearActionStates(state),
      ...action.payload ? {
        tbTeams: getInitialStateWithCount(), // TODO: update just updated_at and employees_count
        tbBestMatches: getInitialStateWithCount(), // reload best matches
        tbTeam: getInitialObjectState() // reload team
        // TODO: reload team skills - need updated statuses:
        // ...action.params
        //   ? updateEntity(state, 'tbTeam', (tbTeam: TbTeam | null) =>
        //       deleteEmployeeFromCachedTbTeam(tbTeam, action.params)) : {}
      } : {},
      tbTeamEmployeeDelete: getActionFinishedState(action.payload, action.params)
    };

    // </Team Builder>

    case EMPLOYEES_WITH_SKILL_FETCHING: return { ...state, mgrEmployeesWithSkill: getPendingStateWithCount(action.params) };
    case EMPLOYEES_WITH_SKILL_FETCHED: return {
      ...state, mgrEmployeesWithSkill: getFetchedStateWithCount(action.payload, action.params)
    };
    case EMPLOYEES_WITH_SKILL_PARAMS: return {
      ...state, mgrEmployeesWithSkill: updateStateCountParams(state.mgrEmployeesWithSkill, action.params)
    };

    case TEAMS_WITH_SKILL_FETCHING: return { ...state, mgrTeamsWithSkill: getPendingState(action.params) };
    case TEAMS_WITH_SKILL_FETCHED: return { ...state, mgrTeamsWithSkill: getFetchedState(action.payload, action.params) };
    case TEAMS_WITH_SKILL_PARAMS: return {
      ...state, mgrTeamsWithSkill: updateStateParams(state.mgrTeamsWithSkill, action.params)
    };

    case JOB_CANDIDATES_FETCHING: return { ...state, jobCandidates: getPendingStateWithCount(action.params)};
    case JOB_CANDIDATES_FETCHED: return { ...state, jobCandidates: getFetchedStateWithCount(action.payload, action.params)};
    case JOB_CANDIDATES_PARAMS: return { ...state, jobCandidates: updateStateCountParams(state.jobCandidates, action.params)};

    case JOB_CANDIDATES_COUNT_FETCHING: return { ...state, jobCandidatesCounts: getPendingObjectState(action.params)};
    case JOB_CANDIDATES_COUNT_FETCHED: return {
      ...state, jobCandidatesCounts: getFetchedObjectState(action.payload, action.params)
    };
    case JOB_CANDIDATES_COUNT_PARAMS: return {
      ...state, jobCandidatesCounts: updateStateObjectParams(state.jobCandidatesCounts, action.params)
    };

    case DELEGATES_LIST_FETCHING: return { ...state, delegatesList: getPendingState(action.params)};
    case DELEGATES_LIST_FETCHED: return { ...state, delegatesList: getFetchedState(action.payload, action.params)};
    case DELEGATES_LIST_PARAMS: return { ...state, delegatesList: updateStateParams(state.delegatesList, action.params)};

    case DELEGATE_ADDING: return { ...state, delegateAdd: getActionPendingState(action.params) };
    case DELEGATE_REMOVING: return { ...state, delegateRemove: getActionPendingState(action.params) };
    case DELEGATE_ADDED:
    case DELEGATE_REMOVED:
      return {
        ...state,
        ...clearActionStates(state),
        ...action.payload ? {
          delegatesList: getInitialState()
        } : {},
        [action.type === DELEGATE_ADDED ? 'delegateAdd' : 'delegateRemove']:
          getActionFinishedState(action.payload, action.params)
      };

    case APP_ONLINE:
    case NAVIGATION:
      return clearActionStates(state);

    case UNAUTHENTICATED: return { ...state, ...initialSupervisorState };

    default: return state;
  }
};

export const SupervisorContext = createContext<ISupervisorState>(initialSupervisorState);

const employeeParams: SelectedEmployeeParams = {};
const leaderParams: LeaderParams = {};

type SupervisorProviderProps = {
  children?: ReactNode | ReactNode[];
  // for Storybook only
  state?: ISupervisorState;
  // for Jest specs only
  initState?: ISupervisorState;
};

const SupervisorProviderPropTypes = {
  // React built-in
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  // for Storybook only
  state: SupervisorStatePropTypes,
  // for Jest specs only
  initState: SupervisorStatePropTypes
};

/* eslint-disable max-lines-per-function */
// eslint-disable-next-line max-statements
export const SupervisorProvider: FunctionComponent<SupervisorProviderProps> = ({
  state,
  initState,
  children
}) => {
  const [supervisorState, dispatch] = useReducer(supervisorReducer, initState
    ? { ...initialSupervisorState, ...initState } : initialSupervisorState
  );
  const { online, token, user: { data: user }, unauthenticate } = useContext(GlobalContext);
  employeeParams.selected_employee_id = getEmployeeId(useParams());
  leaderParams.selected_leader_id = getSelectedLeaderId(user);

  useEffect(() => {
    if (!token) dispatch({ type: UNAUTHENTICATED });
  }, [token]);

  useEffect(() => {
    if (online === true) dispatch({ type: APP_ONLINE, payload: true });
  }, [online]);

  useEffect(() => {
    history.listen(({ location: { pathname } }) => {
      if (pathname && pathname !== PATH_HOME) dispatch({ type: NAVIGATION });
    });
  }, []);

  const actualState = state || supervisorState;
  const {
    mgrEmployee, mgrAddedSkills,
    mgrTeam, mgrEmployeesWithSkill, mgrTeamsWithSkill, jobCandidates, jobCandidatesCounts, delegatesList,
    tbTeams, tbTeam, tbBestMatches,
    tbTeamAdd: { pending: tbTeamAddPending },
    tbTeamUpdate: { pending: tbTeamUpdatePending },
    tbTeamDelete: { pending: tbTeamDeletePending },
    tbTeamJobAdd: { pending: tbTeamJobAddPending },
    tbTeamJobDelete: { pending: tbTeamJobDeletePending },
    tbTeamSkillAdd: { pending: tbTeamSkillAddPending },
    tbTeamSkillUpdate: { pending: tbTeamSkillUpdatePending },
    tbTeamSkillDelete: { pending: tbTeamSkillDeletePending },
    tbTeamSkillsReset: { pending: tbTeamSkillResetPending },
    tbTeamEmployeeAdd: { pending: tbTeamEmployeeAddPending },
    tbTeamEmployeeDelete: { pending: tbTeamEmployeeDeletePending },
    delegateAdd: { pending: addDelegatePending },
    delegateRemove: { pending: removeDelegatePending }
  } = actualState;

  useEffect(() => {
    dispatch({
      type: SET_ACTIONS,
      payload: {
        requireEmployee: fetchFactory({
          token,
          online,
          unauthenticate,
          dispatch,
          routeParams: employeeParams,
          type: EMPLOYEE_FETCH,
          entity: mgrEmployee,
          api: ({ selected_employee_id }) => selected_employee_id
            ? getApiEmployee(selected_employee_id) : API_USER_ME,
          dropParams: ['selected_employee_id'],
          results: ''
          // transformation: transformEmployee
        })
      }
    });
  }, [mgrEmployee, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({
      type: SET_ACTIONS,
      payload: {
        requireAddedSkills: fetchFactory({
          token,
          online,
          unauthenticate,
          dispatch,
          params: ['ids'],
          // TODO: improve `ids` param verification
          validator: ({ ids }) => isString(ids) && size(ids) >= 1,
          type: SUPV_ADDED_SKILLS_FETCH,
          entity: mgrAddedSkills,
          api: API_SKILLS
        })
      }
    });
  }, [mgrAddedSkills, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({
      type: SET_ACTIONS,
      payload: {
        requireTeam: fetchFactory({
          token,
          online,
          unauthenticate,
          dispatch,
          params: ({ employees_only }) => ({
            employees_only: employees_only === true ? true : null,
            limit: MAX_TEAM_EMPLOYEES
          }),
          type: TEAM_FETCH,
          entity: mgrTeam,
          api: API_SUPV_TEAM
        })
      }
    });
  }, [mgrTeam, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({
      type: SET_ACTIONS,
      payload: {
        requireEmployeesWithSkill: fetchFactory({
          token,
          online,
          unauthenticate,
          dispatch,
          params: ({ skill_id, state_id, country_id, level, offset, limit }) => ({
            ...locationParams(country_id, state_id),
            skill_id,
            level: level && isSafeInteger(level) && level >= 1 ? level : null,
            offset: offset && offset >= 1 && isSafeInteger(offset) ? offset : null,
            limit: limit && limit >= 1 && isSafeInteger(limit) ? limit : null
          } as EmployeesWithSkillParams),
          type: EMPLOYEES_WITH_SKILL_FETCH,
          entity: mgrEmployeesWithSkill,
          withCount: true,
          validator: ({ skill_id }) => !isEmptyString(skill_id),
          api: ({ skill_id }) => getApiSupvSkillEmployees(skill_id),
          dropParams: ['skill_id']
        })
      }
    });
  }, [mgrEmployeesWithSkill, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({
      type: SET_ACTIONS,
      payload: {
        requireTeamsWithSkill: fetchFactory({
          token,
          online,
          unauthenticate,
          dispatch,
          params: ({ skill_id, manager_id }) => ({
            skill_id,
            ...isEmptyString(manager_id) || trim(toString(manager_id)) === '0' ? {} : { manager_id }
          } as TeamsWithSkillParams),
          type: TEAMS_WITH_SKILL_FETCH,
          entity: mgrTeamsWithSkill,
          validator: ({ skill_id }) => !isEmptyString(skill_id),
          api: ({ skill_id }) => getApiSupvSkillTeams(skill_id),
          dropParams: ['skill_id']
        })
      }
    });
  }, [mgrTeamsWithSkill, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({
      type: SET_ACTIONS,
      payload: {
        requireJobCandidates: fetchFactory({
          token,
          online,
          unauthenticate,
          dispatch,
          params: ({ role_id, min_match_rate, org_id, state_id, country_id, offset, limit }) => ({
            role_id,
            ...min_match_rate && isSafeInteger(min_match_rate) &&
              min_match_rate >= MIN_MATCH_RATE && min_match_rate <= MAX_MATCH_RATE
              ? { min_match_rate } : {},
            ...org_id && isSafeInteger(org_id) && org_id >= 1 ? { org_id } : {},
            ...locationParams(country_id, state_id),
            offset: offset && offset >= 1 && isSafeInteger(offset) ? offset : null,
            limit: limit && limit >= 1 && isSafeInteger(limit) ? limit : null
          } as JobCandidatesParams),
          type: JOB_CANDIDATES_FETCH,
          entity: jobCandidates,
          withCount: true,
          validator: ({ role_id }) => !isEmptyString(role_id),
          api: ({ role_id }) => getApiMatchingJobEmpls(role_id),
          dropParams: ['role_id']
        })
      }
    });
  }, [jobCandidates, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({
      type: SET_ACTIONS,
      payload: {
        requireJobCandidatesCounts: fetchFactory({
          token,
          online,
          unauthenticate,
          dispatch,
          params: ({ role_id }) => ({ role_id } as JobCandidatesCountsParams),
          type: JOB_CANDIDATES_COUNT_FETCH,
          validator: ({ role_id }) => !isEmptyString(role_id),
          entity: jobCandidatesCounts,
          api: ({ role_id }) => getApiMatchingJobEmplsCounts(role_id),
          dropParams: ['role_id']
        })
      }
    });
  }, [jobCandidatesCounts, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({
      type: SET_ACTIONS,
      payload: {
        requireDelegatesList: fetchFactory({
          token,
          online,
          unauthenticate,
          dispatch,
          params: ['from_id'],
          validator: ({ from_id }) => Boolean(from_id) && isSafeInteger(from_id) && from_id >= 1,
          type: DELEGATES_LIST_FETCH,
          entity: delegatesList,
          api: API_DELEGATES,
          transformation: transformDelegates
        })
      }
    });
  }, [delegatesList, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      addDelegate: delegationAction(token, 'ADD', online, dispatch, addDelegatePending)
    }});
  }, [addDelegatePending, online, token]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      removeDelegate: delegationAction(token, 'REMOV', online, dispatch, removeDelegatePending)
    }});
  }, [removeDelegatePending, online, token]);

  // <Team Builder>

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireTbTeams: requireTbTeams(token, online, unauthenticate, dispatch, tbTeams)
    }});
  }, [tbTeams, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireTbTeam: requireTbTeam(token, online, unauthenticate, dispatch, tbTeam)
    }});
  }, [tbTeam, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      requireTbBestMatches: requireTbBestMatches(token, online, unauthenticate, dispatch, tbBestMatches)
    }});
  }, [tbBestMatches, online, token, unauthenticate]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      tbAddTeam: tbAddTeam(token, online, dispatch, tbTeamAddPending)
    }});
  }, [tbTeamAddPending, online, token]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      tbUpdateTeam: tbUpdateTeam(token, online, dispatch, tbTeamUpdatePending)
    }});
  }, [tbTeamUpdatePending, online, token]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      tbDeleteTeam: tbDeleteTeam(token, online, dispatch, tbTeamDeletePending)
    }});
  }, [tbTeamDeletePending, online, token]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      tbTeamAddJob: tbTeamAddJob(token, online, dispatch, tbTeamJobAddPending)
    }});
  }, [tbTeamJobAddPending, online, token]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      tbTeamDeleteJob: tbTeamDeleteJob(token, online, dispatch, tbTeamJobDeletePending)
    }});
  }, [tbTeamJobDeletePending, online, token]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      tbTeamAddSkill: tbTeamAddSkill(token, online, dispatch, tbTeamSkillAddPending)
    }});
  }, [tbTeamSkillAddPending, online, token]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      tbTeamUpdateSkill: tbTeamUpdateSkill(token, online, dispatch, tbTeamSkillUpdatePending)
    }});
  }, [tbTeamSkillUpdatePending, online, token]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      tbTeamDeleteSkill: tbTeamDeleteSkill(token, online, dispatch, tbTeamSkillDeletePending)
    }});
  }, [tbTeamSkillDeletePending, online, token]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      tbTeamResetSkills: tbTeamResetSkills(token, online, dispatch, tbTeamSkillResetPending)
    }});
  }, [tbTeamSkillResetPending, online, token]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      tbTeamAddEmployee: tbTeamAddEmployee(token, online, dispatch, tbTeamEmployeeAddPending)
    }});
  }, [tbTeamEmployeeAddPending, online, token]);

  useEffect(() => {
    dispatch({ type: SET_ACTIONS, payload: {
      tbTeamDeleteEmployee: tbTeamDeleteEmployee(token, online, dispatch, tbTeamEmployeeDeletePending)
    }});
  }, [tbTeamEmployeeDeletePending, online, token]);

  // </Team Builder>

  return (
    <SupervisorContext.Provider value={actualState}>
      {children}
    </SupervisorContext.Provider>
  );
};

SupervisorProvider.propTypes = SupervisorProviderPropTypes;
