import { forwardRef, type ElementType } from 'react';
import { type BoxProps, type SystemProps } from '@mui/system/Box';
import Box from '@mui/material/Box';
import MuiAlert, { type AlertProps } from '@mui/material/Alert';

const Alert = MuiAlert as (props: AlertProps & {
  component?: ElementType;
}) => JSX.Element;

const AlertBox = forwardRef<HTMLDivElement,
  Omit<BoxProps, 'classes' | 'className' | 'component'> &
  Omit<SystemProps, 'component'> &
  Omit<AlertProps, 'component'>
>((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Alert ref={ref} component={Box as ('div' & ElementType)} {...props}/>
));

AlertBox.displayName = 'AlertBox';

export default AlertBox;
