import { gql } from '@apollo/client';
// local imports
import { API_EMPLOYEE_DEV_PLANS } from '../config/api';

export const EMPLOYEE_DEV_PLANS_QUERY = gql`
  query EmployeeDevPlans(
    $duration: Int
    $selected_employee_id: String
    $limit: Int
    $offset: Int
  ) {
    employeeDevplans(
      duration: $duration,
      selected_employee_id: $selected_employee_id,
      limit: $limit,
      offset: $offset
    )
    @rest(
      type: "DevPlansPayload",
      path: "${API_EMPLOYEE_DEV_PLANS}?{args}"
    ) {
      count
      results {
        id
        title
        created_at
        updated_at
        is_active
        is_new
        job {
          id
          code
          title
        }
        opportunity {
          id
          title
        }
        owner {
          id
          code
          first_name
          last_name
          email
        }
      }
    }
  }
`;
