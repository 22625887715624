import { gql } from '@apollo/client';
// local imports
import { API_HR_REDEPLOYMENT } from '../config/api';

export const UPDATE_REDEPLOYMENT_PLAN = gql`
  mutation UpdateRedeploymentPlan(
    $plan_id: Int!
    $input: UpdateRedeploymentPlanInput!
  ) {
    updateRedeploymentPlan(
      plan_id: $plan_id,
      input: $input
    )
    @rest(
      type: "MutationResponse",
      path: "${API_HR_REDEPLOYMENT}",
      method: "PATCH"
    ) {
      success
    }
  }
`;
