import { forwardRef, memo, type Component, type MouseEventHandler } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import size from 'lodash/size';
import map from 'lodash/map';
import indexOf from 'lodash/indexOf';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box, { type BoxProps } from '@mui/material/Box';
// EmPath UI Components
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import PlusChip from '@empathco/ui-components/src/elements/PlusChip';
import PlusButton from '@empathco/ui-components/src/elements/PlusButton';
// local imports
import { SKILL_LEVEL_REGULAR, Skill } from '../models/skill';
import SkillDraggableChip from '../elements/SkillDraggableChip';

export type TeamSkillsVariant = 'normal' | 'simple' | 'uniform' | 'master';

type TeamSkillsProps = {
  variant: TeamSkillsVariant;
  skillIds: number[];
  skills: Record<number, Skill>;
  addedSkillIds?: number[];
  moveSkill?: (id: number, index: number | null) => number[];
  onAddSkill?: MouseEventHandler<HTMLButtonElement>;
  onClick?: (id: number, abbr: string) => void;
  onDelete?: (id: number) => void;
  withLevels?: boolean;
  disabled?: boolean | null;
  pending?: boolean | null;
  dragging?: boolean | null;
  emptyMessage?: string;
}

const TeamSkillsPropTypes = {
  variant: PropTypes.string.isRequired as Validator<TeamSkillsVariant>,
  skillIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  skills: PropTypes.object.isRequired as Validator<Record<number, Skill>>,
  addedSkillIds: PropTypes.arrayOf(PropTypes.number.isRequired),
  moveSkill: PropTypes.func,
  onAddSkill: PropTypes.func,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  withLevels: PropTypes.bool,
  disabled: PropTypes.bool,
  pending: PropTypes.bool,
  dragging: PropTypes.bool,
  emptyMessage: PropTypes.string
};

const TeamSkills = forwardRef<Component<BoxProps>, TeamSkillsProps>(({
  variant,
  skillIds,
  skills,
  addedSkillIds,
  onDelete,
  moveSkill,
  onAddSkill,
  onClick,
  withLevels = false,
  pending = false,
  disabled = false,
  dragging = false,
  emptyMessage
}, ref) => {
  const uniform = variant === 'uniform';
  // const simple = variant === 'simple';
  const master = variant === 'master';
  const empty = (uniform || master) && size(skillIds) < 1;
  return (
    <Box
        ref={ref}
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent={uniform && !empty ? undefined : 'center'}
        // mt={simple || uniform ? undefined : 2.5}
        my={empty ? (master && 3) || 6 : undefined}
        mx={-1}
    >
      {empty ? emptyMessage && (
        <BoxTypography variant="body1" color="info.caption" textAlign="center">
          <FormattedMessage id={emptyMessage} defaultMessage={emptyMessage}/>
        </BoxTypography>
      ) : map(skillIds, (skillId) => {
        const skill = skills[skillId];
        return skill ? (
          <SkillDraggableChip
              key={skillId}
              skill={skill}
              level={withLevels ? skill.skill_proficiency_level || SKILL_LEVEL_REGULAR : undefined}
              onActivate={onClick}
              onDelete={onDelete && (uniform || indexOf(addedSkillIds, skillId) >= 0) ? onDelete : undefined}
              moveSkill={moveSkill}
              disabled={disabled ? true : undefined}
          />
        ) : null;
      })}
      {dragging ? <PlusChip/> : undefined}
      {onAddSkill ? (
        <Box mx={1.5} my={2}>
          <PlusButton
              label="supervisor.add_skill"
              disabled={disabled ? true : undefined}
              pending={pending ? true : undefined}
              onClick={onAddSkill}
          />
        </Box>
      ) : undefined}
    </Box>
  );
});

TeamSkills.displayName = 'TeamSkills';

TeamSkills.propTypes = TeamSkillsPropTypes;

export default memo(TeamSkills);
