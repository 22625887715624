import { gql } from '@apollo/client';
// local imports
import { API_SUPV_TARGETED_JOBS } from '../config/api';

export const MD_TARGETED_JOBS_QUERY = gql`
  query MDTargetedJobs(
    $input: MDInput!
    $pathBuilder: String!
  ) {
    mdTargetedJobs(
      input: $input
    )
    @rest(
      type: "MDTargetedJobsPayload",
      path: "${API_SUPV_TARGETED_JOBS}",
      pathBuilder: $pathBuilder
    ) {
      job_id
      count
      employees_count
      results {
        id
        code
        title
        management_level
        is_index_display
        employees_count
      }
    }
  }
`;
