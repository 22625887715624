// local imports
import { DataStatusObject } from '../models/dataStatusObject';

export const DATA_COMPUTE = 'outdated' as const;
export const DATA_COMPUTING = 'in_progress' as const;
export const DATA_COMPUTED = 'up-to-date' as const;

const DATA_STATUSES = [DATA_COMPUTE, DATA_COMPUTING, DATA_COMPUTED] as const;

export type DataStatus = typeof DATA_STATUSES[number];

export const forceReloadData = (
  prev: { cached_data_status?: string } = {},
  next: { cached_data_status?: string } = {}
) => {
  const { cached_data_status: prevStatus } = prev || {};
  const { cached_data_status: nextStatus } = next || {};
  return prevStatus === DATA_COMPUTING && (
    nextStatus === DATA_COMPUTED ||
    nextStatus === DATA_COMPUTE
  );
};

export const getDataStatus = (dataStatus?: DataStatusObject | null): string => dataStatus?.cached_data_status || '';

export const mustCompute = (status?: string) => status === DATA_COMPUTE;
export const isComputing = (status?: string) => status === DATA_COMPUTING;
export const hasComputed = (status?: string) => status === DATA_COMPUTED;

export const isDirtyData = (status?: string) => status === DATA_COMPUTE || status === DATA_COMPUTING;
