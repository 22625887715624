import toLower from 'lodash/toLower';
// Material Icon imports
import SchoolOutlined from '@mui/icons-material/SchoolOutlined';
import StackedLineChartOutlined from '@mui/icons-material/StackedLineChartOutlined';
import WbSunnyOutlined from '@mui/icons-material/WbSunnyOutlined';
import AutoGraph from '@mui/icons-material/AutoGraph';
// local imports
import { SkillActivityType } from '../graphql/types';

export const getActivityIcon = (type?: SkillActivityType | null, defaultIcon = false) => {
  const activityType = type ? toLower(type) : undefined;
  return activityType
    ? (activityType === SkillActivityType.education && SchoolOutlined) ||
      (activityType === SkillActivityType.experience && StackedLineChartOutlined) ||
      (activityType === SkillActivityType.exposure && WbSunnyOutlined) ||
      AutoGraph
    : (defaultIcon && AutoGraph) || undefined;
};
