import { forwardRef, memo, useCallback } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import toSafeInteger from 'lodash/toSafeInteger';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
// EmPath UI Components
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import TargetIcon from '@empathco/ui-components/src/elements/TargetIcon';
import SkillNewTag from '@empathco/ui-components/src/elements/SkillNewTag';
import ItemCard from '@empathco/ui-components/src/elements/ItemCard';
import MatchIndicator from '@empathco/ui-components/src/elements/MatchIndicator';
// local imports
import { AdminJob } from '../graphql/types';
import { JobLookupItem } from '../graphql/customTypes';
import { Job } from '../models/job';
import useNonReducedUI from '../constants/managementLevel';
import { PATH_JOB } from '../config/paths';
import RoleName from '../v3/RoleName';
import OpenReqsLink from '../v3/OpenReqsLink';
// SCSS imports
import { card, titleRow, info } from './RoleCard.module.scss';

type RoleCardProps = {
  role: Job | JobLookupItem | AdminJob;
  isEmployee?: boolean;
  depersonalized?: boolean;
  onClick?: ((code: string, job?: Job | JobLookupItem | AdminJob) => void) | null;
  withOpenReqsPopup?: boolean;
  matchRate?: number | null;
  matchRatePending?: boolean | null;
}

const RoleCardPropTypes = {
  // attributes
  role: PropTypes.object.isRequired as Validator<Job | AdminJob>,
  isEmployee: PropTypes.bool,
  depersonalized: PropTypes.bool,
  onClick: PropTypes.func,
  withOpenReqsPopup: PropTypes.bool,
  matchRate: PropTypes.number,
  matchRatePending: PropTypes.bool
};

// eslint-disable-next-line complexity
const RoleCard = forwardRef<HTMLDivElement, RoleCardProps>(({
  role,
  isEmployee = false,
  depersonalized = false,
  onClick,
  withOpenReqsPopup,
  matchRate,
  matchRatePending
}, ref) => {
  const { showNonReducedUI } = useNonReducedUI();

  const { code, is_index_display, title, management_level } = role || {};
  const { is_new, display_level, is_current, is_target, match_rate } = (role || {}) as Job;
  const { display_management_level } = (role || {}) as AdminJob;
  const showMatchRate = !depersonalized && showNonReducedUI(role);

  const handleClick = useCallback(() => {
    if (onClick && role?.code) onClick(role.code, role);
  }, [role, onClick]);

  const displayLevel = (isString(display_level) && display_level) ||
    (isString(display_management_level) && display_management_level) ||
    (isNil(management_level) && '—') || undefined;

  return (
    <ItemCard
        ref={ref}
        light
        // Manager does not have dedicated Job Details screen [yet]:
        link={!withOpenReqsPopup && !onClick && is_index_display && code
          ? injectParams(PATH_JOB, { role_id: code })
          : undefined}
        onClick={!withOpenReqsPopup && onClick && code ? handleClick : undefined}
        display="flex"
        className={card}
    >
      <Box pr={1.5} mt={0.75} flexGrow={1} flexBasis={0} display="flex" flexDirection="column">
        {is_new && !is_target ? <SkillNewTag active/> : undefined}
        <RoleName
            isEmployee={isEmployee}
            withoutLink={!withOpenReqsPopup}
            onClick={withOpenReqsPopup && onClick && code ? handleClick : undefined}
            code={withOpenReqsPopup && is_index_display ? code : undefined}
            title={title}
            isCurrent={depersonalized ? undefined : is_current}
            isTarget={depersonalized ? undefined : is_target}
            className={titleRow}
        />
        <Box
            pt={1.5}
            flexGrow={1}
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            className={info}
        >
          {/* {!is_current && isSafeInteger(movement_rate) && movement_rate >= 1 && (
            <Box>
              <FormattedMessage
                  id="common.movement_rate_value"
                  values={{ rate: toSafeInteger(movement_rate) / 100 }}
              />
            </Box>
          )} */}
          <Box>
            <FormattedMessage
                id={displayLevel ? 'common.job_level.display' : 'common.job_level.text'}
                values={{ level: displayLevel || toSafeInteger(management_level) }}
            />
          </Box>
          <OpenReqsLink
              role={role}
              variant="body1"
              withoutLink
              withOpenReqsPopup={withOpenReqsPopup}
          />
        </Box>
      </Box>
      <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          px={showMatchRate ? undefined : 2}
      >
        {showMatchRate ? (
          <MatchIndicator
              value={toSafeInteger(isNil(matchRate) ? match_rate : matchRate)}
              pending={matchRatePending}
          />
        ) : undefined}
        {!depersonalized && !is_current && is_target ? (
          <Box pt={1.5}>
            <TargetIcon active tooltip="common.target_role" tooltipPlacement="bottom"/>
          </Box>
        ) : undefined}
      </Box>
    </ItemCard>
  );
});

RoleCard.displayName = 'RoleCard';

RoleCard.propTypes = RoleCardPropTypes;

export default memo(RoleCard);
