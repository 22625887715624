import { memo, type FunctionComponent } from 'react';
// local imports
import useVerifyUser from '../hooks/useVerifyUser';
import Screen from '../v3/Screen';
import Notifications from '../components/Notifications';

const NotificationsScreen: FunctionComponent = () => {
  const { content } = useVerifyUser({});
  return content || (
    <Screen withSettings>
      <Notifications/>
    </Screen>
  );
};

export default memo(NotificationsScreen);
