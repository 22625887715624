import { gql } from '@apollo/client';
// local imports
import { API_SUPV_JOBS_SKILLS_GAP } from '../config/api';

export const MD_JOBS_SKILLS_GAP_QUERY = gql`
  query MDJobsSkillsGap(
    $input: MDInput!
    $pathBuilder: String!
  ) {
    mdJobsSkillsGap(
      input: $input
    )
    @rest(
      type: "MDJobsSkillsGapPayload",
      path: "${API_SUPV_JOBS_SKILLS_GAP}",
      pathBuilder: $pathBuilder
    ) {
      job_id
      count
      employees_count
      results {
        id
        code
        title
        management_level
        is_index_display
        employees_count
        match_rates {
          by_inferred_skills
          by_confirmed_skills
        }
      }
    }
  }
`;
