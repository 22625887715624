import { memo, useLayoutEffect, type FunctionComponent } from 'react';
import { Navigate } from 'react-router-dom';
// Material UI imports
import LinearProgress from '@mui/material/LinearProgress';
// local imports
import usePathConfig from '../config/paths';
import useCustomerSettings from '../config/customer';

const LoginRedirect: FunctionComponent = () => {
  const { HAS_LOGIN, HAS_MULTIPLE_SSO } = useCustomerSettings();
  const { PATH_LOGIN_LINK } = usePathConfig();
  useLayoutEffect(() => {
    if (!HAS_LOGIN && !HAS_MULTIPLE_SSO) {
      // for SSO-based authentication flow:
      window.location.assign(PATH_LOGIN_LINK);
    }
  }, [HAS_MULTIPLE_SSO, HAS_LOGIN, PATH_LOGIN_LINK]);

  return HAS_LOGIN || HAS_MULTIPLE_SSO
    // for email-based authentication flow:
    ? <Navigate replace to={PATH_LOGIN_LINK}/>
    : <LinearProgress/>;
};

export default memo(LoginRedirect);
