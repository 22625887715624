export const TIMEZONE_BY_STATE: Record<string, number> = {
  ALABAMA: 360,
  AL: 360,
  // ALASKA: 540, // out of scope
  // AK: 540, // out of scope
  ARIZONA: 420,
  AZ: 420,
  ARKANSAS: 360,
  AR: 360,
  CALIFORNIA: 480,
  CA: 480,
  COLORADO: 420,
  CO: 420,
  CONNECTICUT: 300,
  CT: 300,
  DELAWARE: 300,
  DE: 300,
  'DISTRICT OF COLUMBIA': 300,
  DC: 300,
  FLORIDA: 300,
  FL: 300,
  GEORGIA: 300,
  GA: 300,
  // HAWAII: 600, // out of scope
  // HI: 600, // out of scope
  IDAHO: 420,
  ID: 420,
  ILLINOIS: 360,
  IL: 360,
  INDIANA: 300,
  IN: 300,
  IOWA: 360,
  IA: 360,
  KANSAS: 360,
  KS: 360,
  KENTUCKY: 360,
  KY: 360,
  LOUISIANA: 360,
  LA: 360,
  MAINE: 300,
  ME: 300,
  MARYLAND: 300,
  MD: 300,
  MASSACHUSETTS: 300,
  MA: 300,
  MICHIGAN: 300,
  MI: 300,
  MINNESOTA: 360,
  MN: 360,
  MISSISSIPPI: 360,
  MS: 360,
  MISSOURI: 360,
  MO: 360,
  MONTANA: 420,
  MT: 420,
  NEBRASKA: 360,
  NE: 360,
  NEVADA: 480,
  NV: 480,
  'NEW HAMSPHIRE': 300,
  NH: 300,
  'NEW JERSEY': 300,
  NJ: 300,
  'NEW MEXICO': 420,
  NM: 420,
  'NEW YORK': 300,
  NY: 300,
  'NORTH CAROLINA': 300,
  NC: 300,
  'NORTH DAKOTA': 360,
  ND: 360,
  OHIO: 300,
  OH: 300,
  OKLAHOMA: 360,
  OK: 360,
  OREGON: 480,
  OR: 480,
  PENNSYLVANIA: 300,
  PA: 300,
  'RHODE ISLAND': 300,
  RI: 300,
  'SOUTH CAROLINA': 300,
  SC: 300,
  'SOUTH DAKOTA': 360,
  SD: 360,
  TENNESSEE: 360,
  TN: 360,
  TEXAS: 360,
  TX: 360,
  UTAH: 420,
  UT: 420,
  VERMONT: 300,
  VT: 300,
  VIRGINIA: 300,
  VA: 300,
  WASHINGTON: 480,
  WA: 480,
  'WEST VIRGINIA': 300,
  WV: 300,
  WISCONSIN: 360,
  WI: 360,
  WYOMING: 420,
  WY: 420
} as const;
