import { gql } from '@apollo/client';
// local imports
import { API_DA_SKILLS_GROWTH } from '../config/api';

export const DA_SKILLS_GROWTH_QUERY = gql`
  query DASkillsGrowth(
    $input: DASkillsGrowthDevInput!
    $pathBuilder: String!
  ) {
    daSkillsGrowth(
      input: $input
    )
    @rest(
      type: "DASkillsGrowthPayload",
      path: "${API_DA_SKILLS_GROWTH}",
      pathBuilder: $pathBuilder
    ) {
      total_employees
      skills {
        id
        title
        abbr
        # level1
        level2
        level3
        level4
        avg
        total_employees
        total_employee_confirmed
      }
    }
  }
`;
