import { forwardRef } from 'react';

export interface ReactComponent<T> {
  props?: T;
  type?: {
    displayName?: string | null;
  } | null;
}

export type SimpleForwardRef = <T, P = {}>(
  render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
) => (props: P & React.RefAttributes<T>) => React.ReactElement | null;

export const simpleForwardRef = forwardRef as SimpleForwardRef;
