import { forwardRef, memo, type ReactNode } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// Material UI imports
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
// SCSS imports
import { card, lowMargin } from './ContentCard.module.scss';

type ContentCardProps = {
  children: ReactNode | ReactNode[];
  pending?: boolean | null;
  // loading?: boolean | null;
  withoutTopMargin?: boolean;
  className?: string;
};

const ContentCardPropTypes = {
  // React built-in
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  // attributes
  pending: PropTypes.bool,
  // loading: PropTypes.bool,
  withoutTopMargin: PropTypes.bool,
  className: PropTypes.string
};

const ContentCard = forwardRef<HTMLDivElement, ContentCardProps>(({
  pending = false,
  // loading = false,
  children,
  className,
  withoutTopMargin = false
}, ref) => pending ? (
  <Box
      ref={ref}
      flexGrow={1}
      color="action.disabled"
      alignItems="center"
      className={withoutTopMargin ? lowMargin : card}
  >
    <CircularProgress color="inherit" size="2.25rem"/>
  </Box>
) : (
  <Card
      ref={ref}
      raised
      className={clsx(className, {
        [card]: !withoutTopMargin,
        [lowMargin]: withoutTopMargin
      })}
  >
    {children}
  </Card>
));

ContentCard.displayName = 'ContentCard';

ContentCard.propTypes = ContentCardPropTypes;

export default memo(ContentCard);
