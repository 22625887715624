import { gql } from '@apollo/client';
// local imports
import { API_SUPV_OPPORTUNITY } from '../config/api';

export const OPPORTUNITY_QUERY = gql`
  query Opportunity(
    $opportunity_id: Int!
  ) {
    opportunity(
      opportunity_id: $opportunity_id
    )
    @rest(
      type: "Opportunity",
      path: "${API_SUPV_OPPORTUNITY}"
    ) {
      id
      title
      duration_unit
      duration_value
      description
      start_date
      status
      scope_manager_id
      onsite
      sidegig
      timezone_minutes
      # created_at
      published_at
      updated_at
      started_at
      archived_at
      timestamp @client
      total_matches
      shortlisted
      bookings {
        status
        count
      }
      location {
        id
        city
        state
        country
        state_id
        country_id
        title @client
      }
      skills {
        id
        title
        abbr
        skill_proficiency_level
        status
        # type
        # description
      }
      matches {
        employee_status
        count
      }
      bookings {
        status
        count
      }
    }
  }
`;
