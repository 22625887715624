import head from 'lodash/head';
import { matchRoutes, useLocation, type RouteObject } from 'react-router-dom';

function useCurrentPath(routes: RouteObject[]) {
  return head(
    matchRoutes(routes, useLocation())
  )?.route.path;
}

export default useCurrentPath;
