import { forwardRef, memo, useMemo, type ElementType, type MouseEventHandler, type ForwardedRef } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import size from 'lodash/size';
import clsx from 'clsx';
import { useIntl, FormattedMessage } from 'react-intl';
// Material UI imports
import { type TooltipProps } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
// Material Icon imports
import Bullseye from 'mdi-material-ui/Bullseye';
// local imports
import SimpleTooltip from './SimpleTooltip';
// SCSS imports
import { avatar, avatarSmall, enabled, muted, inProgress } from './TargetIcon.module.scss';

type TargetIconProps = {
  active?: boolean | null;
  pending?: boolean | null;
  disabled?: boolean | null;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  small?: boolean;
  tooltip?: string;
  tooltipPlacement?: TooltipProps['placement'];
  label?: string;
  activeLabel?: string;
  Icon?: ElementType;
  ActiveIcon?: ElementType;
}

const TargetIconPropTypes = {
  active: PropTypes.bool,
  pending: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  small: PropTypes.bool,
  tooltip: PropTypes.string,
  tooltipPlacement: PropTypes.string as Validator<TooltipProps['placement']>,
  label: PropTypes.string,
  activeLabel: PropTypes.string,
  Icon: PropTypes.elementType as Validator<ElementType>,
  ActiveIcon: PropTypes.elementType as Validator<ElementType>
};

// eslint-disable-next-line complexity
const TargetIcon = forwardRef<HTMLButtonElement, TargetIconProps>(({
  active = false,
  pending = false,
  onClick,
  disabled,
  className,
  small = false,
  tooltip,
  tooltipPlacement,
  label,
  activeLabel,
  Icon,
  ActiveIcon
}, ref) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();

  const AltIcon = (active && ActiveIcon) || Icon;
  const icon = AltIcon ? (pending && <CircularProgress ref={onClick ? undefined : ref} size={24} color="primary"/>) || (
    <AltIcon ref={onClick ? undefined : ref} color={disabled ? 'disabled' : 'primary'}/>
  ) : (
    <Avatar
        ref={onClick ? undefined : ref as ForwardedRef<HTMLDivElement>}
        variant="circular"
        className={clsx(className, {
          [avatar]: !small,
          [avatarSmall]: small,
          [enabled]: active,
          [muted]: !active && !pending,
          [inProgress]: pending
        })}
    >
      {pending
      ? <CircularProgress size={small ? 20 : 28} color="primary"/>
      : <Bullseye fontSize="inherit"/>}
    </Avatar>
  );

  const lbl = active ? activeLabel || 'common.button.targeted' : label || (onClick ? 'common.button.not_targeted' : undefined);
  const srLabel = lbl ? (
    <Box sx={visuallyHidden}>
      <FormattedMessage id={lbl} defaultMessage={lbl}/>
    </Box>
  ) : undefined;

  const tooltipText = useMemo(
    () => tooltip && size(tooltip) >= 1 ? formatMessage({ id: tooltip, defaultMessage: tooltip }) : undefined,
    [tooltip, formatMessage]);

  const content = onClick ? (
    <IconButton
        ref={ref}
        onClick={onClick}
        disabled={disabled || pending ? true : undefined}
        className={AltIcon ? className : undefined}
    >
      {icon}
      {srLabel}
    </IconButton>
  ) : undefined;

  return tooltipText ? (
    <>
      <SimpleTooltip title={tooltipText} placement={tooltipPlacement} disableInteractive>
        {content || icon}
      </SimpleTooltip>
      {content ? undefined : srLabel}
    </>
  ) : content || (
    <>
      {icon}
      {srLabel}
    </>
  );
});

TargetIcon.displayName = 'TargetIcon';

TargetIcon.propTypes = TargetIconPropTypes;

export default memo(TargetIcon);
