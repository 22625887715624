import { memo, useCallback, useEffect, useMemo, type FunctionComponent, type ReactNode } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
// EmPath UI Components
import { mapChunks } from '@empathco/ui-components/src/helpers/intl';
import useMutationMethod from '@empathco/ui-components/src/hooks/useMutationMethod';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
// local imports
import { PROFILE_BUILDER_COMPLETE } from '../graphql/ProfileBuilderComplete';
import { ProfileBuilderCompleteDocument } from '../graphql/types';
import { PATH_MY } from '../config/paths';
// SCSS imports
import { root, button } from './BuilderCompleteStep.module.scss';

type BuilderCompleteStepProps = {
  testComplete?: boolean | null;
}

const BuilderCompleteStepPropTypes = {
  testComplete: PropTypes.bool
};

const BuilderCompleteStep: FunctionComponent<BuilderCompleteStepProps> = ({
  testComplete = false
}) => {
  const navigate = useNavigate();

  const { mutate: builderComplete, loading: completePending, failed: completeFailed } = useMutationMethod({
    key: 'profileBuilderComplete',
    mutation: useMutation(PROFILE_BUILDER_COMPLETE as typeof ProfileBuilderCompleteDocument)
  });

  const infoValues = useMemo(() => ({
    // eslint-disable-next-line react/no-unstable-nested-components
    line: (chunks?: ReactNode | ReactNode[] | null): ReactNode => (
      <Typography variant="h3" paragraph>
        {mapChunks(chunks)}
      </Typography>
    )
  }), []);

  const handleComplete = useCallback(() => {
    builderComplete({
      variables: { input: {} },
      onCompleted: (data) => {
        if (data?.profileBuilderComplete?.success) navigate(PATH_MY);
      }
    });
  }, [builderComplete, navigate]);

  // for Storybook & Jest-snapshots testing only
  useEffect(() => {
    if (testComplete) builderComplete({ variables: { input: {} } });
  }, [testComplete, builderComplete]);

  return (
    <>
      <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          className={root}
      >
        <Grid item>
          <Box p={6} textAlign="center">
            <FormattedMessage id="builder.complete.info" values={infoValues}/>
          </Box>
          <Grid container justifyContent="center">
            <Box pb={6}>
              <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  disabled={completePending}
                  startIcon={completePending ? <CircularProgress size={18} color="inherit"/> : undefined}
                  className={button}
                  onClick={handleComplete}
              >
                <FormattedMessage id="builder.start_exploring"/>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <ActionFailedAlert
          message="builder.complete.error"
          open={completeFailed}
      />
    </>
  );
};

BuilderCompleteStep.propTypes = BuilderCompleteStepPropTypes;

export default memo(BuilderCompleteStep);
