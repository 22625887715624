import { gql } from '@apollo/client';
// local imports
import { API_LOCATIONS } from '../config/api';

export const LOCATIONS_QUERY = gql`
  query Locations(
    $search: String!
    $country_id: Int!
    $limit: Int
  ) {
    locations(
      search: $search,
      country_id: $country_id,
      limit: $limit
    )
    @rest(
      type: "LocationsPayload",
      path: "${API_LOCATIONS}?{args}"
    ) {
      count
      results {
        id
        city
        state
        country
        state_id
        country_id
        title @client
      }
    }
  }
`;
