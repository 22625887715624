import { forwardRef, memo, type Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import { makeStyles } from 'tss-react/mui';
import { type Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Tooltip, { type TooltipProps } from '@mui/material/Tooltip';
// EmPath UI Components
import { shape } from '@empathco/ui-components/src/styles/themeOptions';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';

const useStyles = makeStyles()((theme: Theme) => ({
  tooltip: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.background.tourHeader,
    boxShadow: theme.shadows[3],
    padding: 0,
    maxWidth: theme.shape.tourScreenshotWidth
  },
  arrow: {
    color: theme.palette.background.tourHeader,
    '&::before': {
      backgroundColor: theme.palette.background.tourHeader
    }
  }
}));

const PLACEMENTS = ['bottom', 'left', 'right', 'top'] as const;
const MUI_PLACEMENTS = ['bottom-start', 'left-start', 'right-start', 'top-end'] as const;

export type QuickTourTooltipPlacement = typeof PLACEMENTS[number];

const PLACEMENT: Record<QuickTourTooltipPlacement, typeof MUI_PLACEMENTS[number]> = {
  bottom: MUI_PLACEMENTS[0],
  left: MUI_PLACEMENTS[1],
  right: MUI_PLACEMENTS[2],
  top: MUI_PLACEMENTS[3]
};

type QuickTourTooltipProps = {
  open?: boolean;
  id: string;
  placement: QuickTourTooltipPlacement;
  x0: number;
  y0: number;
  width: number;
};

const QuickTourTooltipPropTypes = {
  // attributes
  open: PropTypes.bool,
  id: PropTypes.string.isRequired,
  placement: PropTypes.oneOf(PLACEMENTS).isRequired,
  x0: PropTypes.number.isRequired,
  y0: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired
};

const QuickTourTooltip = forwardRef<Component<TooltipProps>, QuickTourTooltipProps>(({
  open = false,
  id,
  placement,
  x0,
  y0,
  width
}, ref) => {
  const { classes } = useStyles();
  const { shape: { tourScreenshotHeight }} = useTheme();
  const compact = y0 > 0.85 * tourScreenshotHeight;
  const compactBottom = y0 > 0.70 * tourScreenshotHeight;
  return (
    <Tooltip
        ref={ref}
        open={open}
        arrow
        disableFocusListener
        disableHoverListener
        disableTouchListener
        disableInteractive
        enterDelay={0}
        enterTouchDelay={0}
        placement={PLACEMENT[placement]}
        title={(
          <Box
              display="flex"
              flexDirection="column"
              width={`${width}px`}
          >
            <BoxTypography
                px={2.25}
                py={compact ? 1 : 1.5}
                variant="h6"
            >
              <FormattedMessage id={`tour.${id}.title`}/>
            </BoxTypography>
            <BoxTypography
                flexGrow={1}
                px={3.5}
                pt={compact ? 1 : 2.5}
                pb={(!compact && compactBottom && 2.25) || (compact ? 1.5 : 5)}
                bgcolor="background.tourBody"
                borderRadius={shape.tinyBorderRadius}
                variant="body2"
            >
              <FormattedMessage
                  id={`tour.${id}.text`}
                  values={{ customer: <FormattedMessage id="header.brand"/> }}
              />
            </BoxTypography>
          </Box>
        )}
        PopperProps={{
          disablePortal: true,
          keepMounted: open,
          modifiers: [
            { name: 'hide', enabled: false },
            { name: 'preventOverflow', enabled: false },
            { name: 'flip', enabled: false },
            {
              name: 'offset',
              options: {
                offset: [placement === 'top' ? 24 : -24, 0]
              }
            }
          ]
        }}
        classes={classes}
    >
      <Box
          sx={{
            position: 'absolute',
            left: `${x0}px`,
            top: `${y0}px`,
            width: 0,
            height: 0
          }}
      />
    </Tooltip>
  );
});

QuickTourTooltip.displayName = 'QuickTourTooltip';

QuickTourTooltip.propTypes = QuickTourTooltipPropTypes;

export default memo(QuickTourTooltip);
