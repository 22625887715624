import { memo } from 'react';
// Material UI imports
import { withStyles } from 'tss-react/mui';
import Tooltip from '@mui/material/Tooltip';

const SimpleTooltip = withStyles(Tooltip, (theme) => ({
  tooltip: {
    padding: '0.9rem 1.35rem 0.8rem 1.35rem',
    border: `${theme.shape.thinBorderWidth}px solid ${theme.palette.misc.tooltipBorder}`,
    boxShadow: theme.shadows[5],
    fontWeight: theme.typography.subtitle2.fontWeight,
    fontSize: theme.typography.subtitle2.fontSize,
    lineHeight: theme.typography.subtitle2.lineHeight,
    letterSpacing: theme.typography.subtitle2.letterSpacing
  }
}));

export default memo(SimpleTooltip);
