import { memo, useMemo, createContext, type FunctionComponent, type ReactNode } from 'react';
import PropTypes from 'prop-types';
import { RawIntlProvider, type IntlShape } from 'react-intl';
// local imports
import useCustomerSettings from './customer';
import { newIntl } from './intl';

type LocalizationProviderProps = {
  children: ReactNode | ReactNode[];
}

const LocalizationProviderPropTypes = {
  // React built-in
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

// eslint-disable-next-line no-underscore-dangle
window.__REACT_INTL_CONTEXT__ = createContext<IntlShape>(null as unknown as IntlShape);

const LocalizationProvider: FunctionComponent<LocalizationProviderProps> = ({
  children
}) => {
  const { CUSTOM_MESSAGES } = useCustomerSettings();
  const intl = useMemo(() => newIntl(CUSTOM_MESSAGES.en), [CUSTOM_MESSAGES.en]);
  return (
    <RawIntlProvider value={intl}>
      {children}
    </RawIntlProvider>
  );
};

LocalizationProvider.propTypes = LocalizationProviderPropTypes;

export default memo(LocalizationProvider);
