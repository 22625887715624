import { gql } from '@apollo/client';
// local imports
import { API_TALENT_FINDER_COUNTS } from '../config/api';

export const TALENT_COUNTS_QUERY = gql`
  query TalentCounts(
    $input: TalentFinderInput!
    $pathBuilder: String!
  ) {
    talentCounts(
      input: $input
    )
    @rest(
      type: "TalentCountsPayload",
      path: "${API_TALENT_FINDER_COUNTS}",
      pathBuilder: $pathBuilder
    ) {
      results {
        by_desired_skills_count
      }
    }
  }
`;
