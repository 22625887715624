import { memo, useState, useEffect, useContext, type FunctionComponent, type MouseEventHandler } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';
// Material UI imports
import DialogContent from '@mui/material/DialogContent';
// EmPath UI Components
import { isEmptyString } from '@empathco/ui-components/src/helpers/strings';
import SkillLevelDialogTitle from '@empathco/ui-components/src/elements/SkillLevelDialogTitle';
import SkillLevelDialogContent from '@empathco/ui-components/src/elements/SkillLevelDialogContent';
import SkillLevelDialogActions from '@empathco/ui-components/src/elements/SkillLevelDialogActions';
// local imports
import { Skill, SkillLevel, SKILL_LEVEL_TO_MENTOR } from '../models/skill';
import { PATH_SKILL } from '../config/paths';
import useCustomerSettings from '../config/customer';
import { getSkillCurrentLevel, getSkillCurrentLevelOnly } from '../helpers/models';
import { DataContext } from '../context';

const contentSx = { pt: 1, pb: 0.75 };

type EditSkillLevelProps = {
  skill: Skill;
  plain?: boolean;
  readOnly?: boolean;
  defaultLevel?: SkillLevel;
  depersonalized?: boolean;
  reducedUI?: boolean | null;
  onUpdate: (skill: Skill, level: SkillLevel, mentoring: boolean, targeting?: boolean) => void;
  onBack?: MouseEventHandler<HTMLButtonElement>;
  onConfirm: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean | null;
  confirmPending?: boolean | null;
  confirmDisabled?: boolean | null;
  noTargeting?: boolean | null;
  noMentoring?: boolean | null;
  // for Storybook only
  testLevel?: SkillLevel;
}

const EditSkillLevelPropTypes = {
  // attributes
  skill: PropTypes.object.isRequired as Validator<Skill>,
  plain: PropTypes.bool,
  readOnly: PropTypes.bool,
  defaultLevel: PropTypes.number as Validator<SkillLevel>,
  depersonalized: PropTypes.bool,
  reducedUI: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  onBack: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  confirmPending: PropTypes.bool,
  confirmDisabled: PropTypes.bool,
  noTargeting: PropTypes.bool,
  noMentoring: PropTypes.bool,
  // for Storybook only
  testLevel: PropTypes.number as Validator<SkillLevel>
};

// eslint-disable-next-line complexity
const EditSkillLevel: FunctionComponent<EditSkillLevelProps> = ({
  skill,
  plain = false,
  readOnly = false,
  defaultLevel,
  depersonalized = false,
  reducedUI = false,
  onUpdate,
  onBack,
  onConfirm,
  disabled: parentDisabled = false,
  confirmPending = false,
  confirmDisabled = false,
  noTargeting = false,
  noMentoring = false,
  testLevel = 0
}) => {
  const { HAS_MENTORING } = useCustomerSettings();
  const {
    skl: { data: skl, pending: sklPending, failed: sklFailed }, requireSkl,
    preferences: { data: preferences, pending: preferencesPending, failed: preferencesFailed }
  } = useContext(DataContext);
  const { id, abbr, title, is_mentoring, is_target, is_in_demand, inferred_level } = skill || {};
  const currentLevel = isNil(defaultLevel) ? getSkillCurrentLevel(skill) : defaultLevel;
  const isMentoring = HAS_MENTORING && !noMentoring && !depersonalized && Boolean(is_mentoring);
  const isTargeting = !noTargeting && !depersonalized && Boolean(is_target);
  const needsSkl = skill && (isEmptyString(skill.description) || !isArray(skill.level_description));

  const [level, setLevel] = useState<SkillLevel>(testLevel);
  useEffect(() => {
    if (!testLevel) setLevel(currentLevel);
  }, [currentLevel, testLevel]);

  useEffect(() => {
    if (needsSkl && skill && (!skl || skl.id !== skill.id) && skill.abbr) requireSkl?.({ skill_id: skill.abbr });
  }, [needsSkl, requireSkl, skill, skl]);

  const [mentoring, setMentoring] = useState<boolean>(isMentoring);
  useEffect(() => {
    setMentoring(isMentoring);
  }, [id, isMentoring]);

  const [isTarget, setIsTarget] = useState<boolean>(isTargeting);
  useEffect(() => {
    setIsTarget(isTargeting);
  }, [id, isTargeting]);

  const canMentorThis = HAS_MENTORING && !noMentoring && !depersonalized && !reducedUI && level >= SKILL_LEVEL_TO_MENTOR;
  const canMentor = HAS_MENTORING && !noMentoring && !depersonalized && !preferencesPending && (
    !preferences || preferencesFailed || Boolean(preferences.mentor_others)
  ) && canMentorThis;

  const sk = needsSkl ? skl : skill;
  useEffect(() => {
    if (sk) onUpdate(sk, level, canMentor ? mentoring : false, noTargeting ? undefined : isTarget);
  }, [canMentor, isTarget, level, mentoring, noTargeting, onUpdate, sk]);

  const pending = needsSkl && (sklPending || !skl);
  const disabled = parentDisabled || pending;

  return (
    <>
      <SkillLevelDialogTitle
          route={PATH_SKILL}
          reducedUI={reducedUI}
          abbr={abbr}
          title={title}
          isTarget={is_target}
          isInDemand={is_in_demand}
          disabled={disabled}
          plain={Boolean((plain || depersonalized) && !readOnly)}
      />
      <DialogContent sx={contentSx}>
        <SkillLevelDialogContent
            readOnly={readOnly}
            noTargeting={noTargeting}
            depersonalized={depersonalized}
            currentLevel={depersonalized ? undefined : getSkillCurrentLevelOnly(skill)}
            inferredLevel={depersonalized ? undefined : inferred_level}
            description={(needsSkl ? skl : skill)?.description}
            levelDescription={(needsSkl ? skl : skill)?.level_description}
            disabled={disabled}
            failed={needsSkl && sklFailed ? true : undefined}
            pending={pending}
            loading={!noMentoring && !depersonalized && preferencesPending ? true : undefined}
            canMentor={canMentor}
            level={level}
            setLevel={setLevel}
            mentoring={mentoring}
            setMentoring={setMentoring}
            isTarget={isTarget}
            setIsTarget={setIsTarget}
        />
      </DialogContent>
      <SkillLevelDialogActions
          readOnly={readOnly}
          onConfirm={onConfirm}
          onBack={onBack}
          visible={!pending && (!needsSkl || !sklFailed)}
          pending={confirmPending}
          disabled={disabled || confirmDisabled}
          withBorder
      />
    </>
  );
};

EditSkillLevel.propTypes = EditSkillLevelPropTypes;

export default memo(EditSkillLevel);
