import { memo, useMemo, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import isNil from 'lodash/isNil';
import toSafeInteger from 'lodash/toSafeInteger';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import TableSortLabel from '@mui/material/TableSortLabel';
import LinearProgress from '@mui/material/LinearProgress';
// EmPath UI Components
import SortArrow from '@empathco/ui-components/src/icons/SortArrow';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import TargetIcon from '@empathco/ui-components/src/elements/TargetIcon';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import DataTable from '@empathco/ui-components/src/elements/DataTable';
import SkillLevelGauge from '@empathco/ui-components/src/elements/SkillLevelGauge';
import SkillTrendIcon from '@empathco/ui-components/src/elements/SkillTrendIcon';
import SkillNewTag from '@empathco/ui-components/src/elements/SkillNewTag';
import SkillName from '@empathco/ui-components/src/elements/SkillName';
// local imports
import {
  SkillSort, DEFAULT_SORT_DIRECTION, SKILL_SORT_ASC, SKILL_SORT_DESC, SKILL_SORT_LEVEL, SKILL_SORT_NAME
} from '../constants/skillsSort';
import { Skill } from '../models/skill';
import { PATH_SKILL } from '../config/paths';
import { getSkillCurrentLevelOnly } from '../helpers/models';
import SuggestedSkillIcon from '../v3/SuggestedSkillIcon';
// SCSS imports
import { overlayDefault } from '@empathco/ui-components/src/styles/modules/Overlay.module.scss';
import { nextIcon, icons0, icons1, icons2, icons3, newTag } from './SkillsTable.module.scss';

type SkillsTableProps = {
  isEmployee?: boolean;
  supervisor?: boolean;
  depersonalised?: boolean;
  title?: string | null;
  skills?: Skill[] | null;
  pending?: boolean | null;
  failed?: boolean | null;
  sortBy?: SkillSort | null;
  direction?: boolean | null;
  changeSort?: (sort: SkillSort, direction: boolean) => void;
  disabled?: boolean | null;
  pendingTarget?: boolean;
  updatingLevelId?: number | null;
  onLevelClick?: (skill: Skill) => void;
  plain?: boolean;
  withExpectedLevel?: boolean;
  withReloading?: boolean;
}

const SkillsTablePropTypes = {
  // attributes
  isEmployee: PropTypes.bool,
  supervisor: PropTypes.bool,
  depersonalised: PropTypes.bool,
  title: PropTypes.string,
  skills: PropTypes.arrayOf(PropTypes.object.isRequired as Validator<Skill>),
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  sortBy: PropTypes.string as Validator<SkillSort>,
  direction: PropTypes.bool,
  changeSort: PropTypes.func,
  disabled: PropTypes.bool,
  pendingTarget: PropTypes.bool,
  updatingLevelId: PropTypes.number,
  onLevelClick: PropTypes.func,
  plain: PropTypes.bool,
  withExpectedLevel: PropTypes.bool,
  withReloading: PropTypes.bool
};

// eslint-disable-next-line complexity
const SkillsTable: FunctionComponent<SkillsTableProps> = ({
  isEmployee = false,
  supervisor = false,
  depersonalised = false,
  title: tableTitle,
  skills,
  pending = false,
  failed = false,
  sortBy,
  direction = false,
  changeSort,
  disabled = false,
  pendingTarget = false,
  updatingLevelId = null,
  onLevelClick,
  plain = false,
  withExpectedLevel = false,
  withReloading = false
}) => {
  const loading = withReloading ? pending && !skills : pending || !skills;
  const reloading = Boolean(withReloading && pending && skills);

  const sortByName = useMemo(() => changeSort ? () => changeSort(
    SKILL_SORT_NAME,
    sortBy === SKILL_SORT_NAME && !isNil(direction) ? !direction : DEFAULT_SORT_DIRECTION[SKILL_SORT_NAME]
  ) : undefined, [direction, changeSort, sortBy]);

  const sortByLevel = useMemo(() => changeSort ? () => changeSort(
    SKILL_SORT_LEVEL,
    sortBy === SKILL_SORT_LEVEL && !isNil(direction) ? !direction : DEFAULT_SORT_DIRECTION[SKILL_SORT_LEVEL]
  ) : undefined, [direction, changeSort, sortBy]);

  const sortDisabled = disabled || loading || failed || !skills || size(skills) < 1;
  const icons = (plain && icons0) || (depersonalised && icons1) || (supervisor && !isEmployee && icons2) || icons3;

  /* eslint-disable react/jsx-indent */
  const titles = useMemo(() => map([
    ...plain ? [] : [{ label: '', id: 'icons' }],
    { label: 'edit_skills.list.name', id: SKILL_SORT_NAME, handler: sortByName },
    { label: 'edit_skills.list.level', id: SKILL_SORT_LEVEL, handler: sortByLevel }
  ], ({ id, label, handler }) => size(label)
    ? <TableSortLabel key={id}
            direction={(sortBy === id ? direction : DEFAULT_SORT_DIRECTION[id]) ? SKILL_SORT_ASC : SKILL_SORT_DESC}
            active={sortBy === id}
            onClick={handler}
            disabled={sortDisabled}
            IconComponent={SortArrow}
      >
        <FormattedMessage id={label}/>
      </TableSortLabel>
    : label
  ), [sortDisabled, sortBy, direction, sortByLevel, sortByName, plain]);
  /* eslint-enable react/jsx-indent */

  const rows = useMemo(() => map(skills, (skill) => {
    const {
      id, inferred_level, expected_level, is_target, is_inference_newer, is_in_demand, is_suggested_by_manager, is_new
    } = skill || {};
    const currentLevel = depersonalised ? undefined : getSkillCurrentLevelOnly(skill);
    const selected = isEmployee && !depersonalised && Boolean(is_target);
    return {
      selected,
      values: [
        /* eslint-disable react/jsx-key */
        ...plain ? [] : [
          <Box display="flex" justifyContent="space-between" alignItems="center">
            {isEmployee && !depersonalised ? (
              <TargetIcon
                  small
                  active={is_target}
                  tooltip={is_target && !pendingTarget ? 'skill.target_this' : undefined}
                  tooltipPlacement="top"
                  pending={is_target && pendingTarget ? true : undefined}
                  className={nextIcon}
              />
            ) : undefined}
            {!depersonalised &&
              <SuggestedSkillIcon
                  active={is_suggested_by_manager}
                  className={nextIcon}
              />}
            <SkillTrendIcon small active={is_in_demand} placement="top" className={nextIcon}/>
          </Box>
        ],
        <>
          <SkillName
              skill={skill}
              skillPath={onLevelClick ? undefined : PATH_SKILL}
              onClick={onLevelClick}
              disabled={disabled}
          />
          {isEmployee && !depersonalised ? <SkillNewTag active={is_new} className={newTag}/> : undefined}
        </>,
        <SkillLevelGauge
            level={currentLevel}
            selected={selected}
            depersonalised={depersonalised}
            inferredLevel={inferred_level}
            isInferenceNewer={is_inference_newer}
            requiredLevel={withExpectedLevel ? toSafeInteger(expected_level) : undefined}
            pending={updatingLevelId === id}
            withoutText
        />
        /* eslint-enable react/jsx-key */
      ]
    };
  }), [skills, isEmployee, depersonalised, pendingTarget, updatingLevelId, disabled, onLevelClick, plain, withExpectedLevel]);

  const content = (
    <CardSection>
      <DataTable
          titles={titles}
          empty="skills.no_skills_found"
          data={rows}
          pending={loading}
          failed={failed}
          lastLeftAlignedTitle={size(titles)}
          className={loading || failed ? undefined : icons}
      />
    </CardSection>
  );

  return (
    <>
      {tableTitle ? (
        <CardSection dark compact>
          <BoxTypography
              py={2}
              variant="body1"
              fontStyle="italic"
              color="text.label"
          >
            <FormattedMessage id={tableTitle} defaultMessage={tableTitle}/>
          </BoxTypography>
        </CardSection>
      ) : undefined}
      {reloading ? (
        <Box
            flexGrow={1}
            display="flex"
            flexDirection="column"
            position="relative"
        >
          {content}
          <Box className={overlayDefault}>
            <LinearProgress/>
          </Box>
        </Box>
      ) : content}
    </>
  );
};

SkillsTable.propTypes = SkillsTablePropTypes;

export default memo(SkillsTable);
