import { useCallback, useEffect, useRef, useState, type KeyboardEvent, type ChangeEvent, useMemo } from 'react';
import toString from 'lodash/toString';

function useEditTitle({
  parentTitle,
  renameFailed,
  updateTitle
}: {
  parentTitle?: string;
  renameFailed?: boolean | null;
  updateTitle: (title?: string) => void;
}) {
  const inputRef = useRef<HTMLInputElement>();
  const handleRename = useCallback(() => inputRef.current?.focus?.(), [inputRef]);

  const [title, setTitle] = useState(parentTitle);
  useEffect(() => {
    setTitle(parentTitle);
  }, [parentTitle]);
  useEffect(() => {
    if (renameFailed === true) setTitle(parentTitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renameFailed]); // ignoring changes of `parentTitle` here

  const handleTitleChange = useCallback((event: ChangeEvent<{ name?: string; value: unknown; }>) => {
    event?.preventDefault();
    setTitle(toString(event?.target?.value));
  }, []);

  const [editing, setEditing] = useState(false);
  const handleStartEditing = useCallback(() => setEditing(true), []);

  const [key, setKey] = useState<'Escape' | 'Enter'>();
  useEffect(() => {
    if (key) inputRef.current?.blur?.();
  }, [key]);

  const handleKeys = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    const keyName = event?.key;
    if (keyName === 'Escape' || keyName === 'Enter') setKey(keyName);
  }, []);

  const handleSaveTitle = useCallback(() => {
    if (key === 'Escape') {
      setTitle(parentTitle);
    } else if (parentTitle !== title) updateTitle(title);
    setKey(undefined);
    setEditing(false);
  }, [parentTitle, title, key, updateTitle]);

  return useMemo(() => ({
    inputRef,
    title,
    editing,
    handleRename,
    handleTitleChange,
    handleStartEditing,
    handleSaveTitle,
    handleKeys
  }), [editing, handleKeys, handleRename, handleSaveTitle, handleStartEditing, handleTitleChange, title]);
}

export default useEditTitle;
