import { gql } from '@apollo/client';
// local imports
import { API_ADMIN_USERS } from '../config/api';

export const NEW_ADMIN_USER = gql`
  mutation NewAdminUser(
    $input: AdminUserInput!
  ) {
    newAdminUser(
      input: $input
    )
    @rest(
      type: "MutationResponseWithId",
      path: "${API_ADMIN_USERS}",
      method: "POST"
    )
    # status 201
    {
      id
      success
    }
  }
`;
