import { memo, useCallback, useContext, useEffect, useMemo, useState, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import size from 'lodash/size';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import isString from 'lodash/isString';
import isEqual from 'lodash/isEqual';
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import { type SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
// EmPath UI Components
import { pathBuilder } from '@empathco/ui-components/src/helpers/graphql';
import useQueryObject from '@empathco/ui-components/src/hooks/useQueryObject';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import GridBox from '@empathco/ui-components/src/mixins/GridBox';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
// local imports
import { MD_IN_DEMAND_SKILLS_QUERY } from '../graphql/MDInDemandSkills';
import { MD_TARGETED_SKILLS_QUERY } from '../graphql/MDTargetedSkills';
import { MD_TARGETED_JOBS_QUERY } from '../graphql/MDTargetedJobs';
import { MD_JOBS_SKILLS_GAP_QUERY } from '../graphql/MDJobsSkillsGap';
import { DA_CHARTS_AVAILABILITY_QUERY } from '../graphql/DAChartsAvailability';
import { DA_EMPLOYEE_SKILLS_QUERY } from '../graphql/DAEmployeeSkills';
import {
  ChartsAvailability, DAChartsAvailabilityDocument,
  MDInDemandSkillsDocument, MDInDemandSkillsQuery,
  MDTargetedSkillsDocument, MDTargetedSkillsQuery,
  DAEmployeeSkillsDocument, DAEmployeeSkillsPayload,
  MDTargetedJobsDocument, MDTargetedJobsQuery,
  MDJobsSkillsGapDocument, MDJobsSkillsGapQuery
} from '../graphql/types';
import { CONST_MIN_SKILLS, CONST_YEARS, toValidConst } from '../constants/constValues';
import { hasDelegationOnly, getDefaultLeaderId, getRootLeaderId, hasLeadershipOnly, getSelectedLeaderId } from '../models/user';
import { MAX_DASHBOARD_SKILLS_GAP_JOBS } from '../config/params';
import useCustomerSettings from '../config/customer';
import { API_MANAGER_DASHBOARD_EXPORT } from '../config/api';
import { PATH_JOB, PATH_SKILL } from '../config/paths';
import { sanitizeLookupString } from '../helpers/models';
import { GlobalContext } from '../context/global';
import { DashboardExportParams, DashboardFilterValues, managerDashboardParams } from '../context/supervisor';
import { DataContext } from '../context';
import useStatusPoller from '../hooks/useStatusPoller';
import ConstSelector from '../elements/ConstSelector';
import ChainOfCommandSelector from '../v3/ChainOfCommandSelector';
import DashboardFilters from '../v3/DashboardFilters';
import TopChart from '../widgets/TopChart';
// SCSS imports
import { filtersPadding, minSkillsSelector } from './ManagerDashboard.module.scss';

type ManagerDashboardProps = {
  hrbp?: boolean;
}

const ManagerDashboardPropTypes = {
  hrbp: PropTypes.bool
};

// eslint-disable-next-line complexity, max-statements, max-lines-per-function
const ManagerDashboard: FunctionComponent<ManagerDashboardProps> = ({
  hrbp = false
}) => {
  const { cache: apolloCache } = useApolloClient();
  const { HAS_INDEMAND_SKILLS, HAS_JOBS_SKILLS_GAP_CHART } = useCustomerSettings();
  const { fontsLoaded, token, user: { data: user } } = useContext(GlobalContext);

  // load Charts Availability
  const { pending, failed, results: charts } = useQueryObject({
    data: undefined as unknown as ChartsAvailability,
    key: 'daChartsAvailability',
    query: useQuery(DA_CHARTS_AVAILABILITY_QUERY as typeof DAChartsAvailabilityDocument)
  });
  const hasInDemandChart = Boolean(HAS_INDEMAND_SKILLS && charts?.in_demand_skills);
  const hasSkillsChart = Boolean(charts?.targeted_skills);
  const hasJobsChart = Boolean(charts?.targeted_jobs);
  const hasEmployeeSkillsChart = Boolean(hrbp && charts?.employeeconfirmed);
  const hasSkillsGapChart = Boolean(HAS_JOBS_SKILLS_GAP_CHART && hrbp && charts?.most_frequent_jobs);

  // lazy load Employee Skills data
  const {
    query: getEmployeeSkills, pending: pendingEmployeeSkills, failed: failedEmployeeSkills, results: employeeSkills
  } = useQueryObject({
    data: undefined as unknown as DAEmployeeSkillsPayload,
    key: 'daEmployeeSkills',
    flatResults: true,
    lazyQuery: useLazyQuery(DA_EMPLOYEE_SKILLS_QUERY as typeof DAEmployeeSkillsDocument)
  });

  const refreshManagerDashboard = useCallback(() => {
    apolloCache.evict({ fieldName: 'mdInDemandSkills' });
    apolloCache.evict({ fieldName: 'mdTargetedSkills' });
    apolloCache.evict({ fieldName: 'mdTargetedJobs' });
    apolloCache.evict({ fieldName: 'mdJobsSkillsGap' });
  }, [apolloCache]);

  const {
    coc: { data: cocData, pending: cocPending }, requireCoC,
    settingsUpdate: { pending: pendingSettingsUpdate }
  } = useContext(DataContext);

  // lazy load In-Demand Skills data
  const {
    query: getInDemandSkls, pending: pendingSkills, failed: failedSkills, results: skillsData
  } = useQueryObject({
    data: undefined as unknown as MDInDemandSkillsQuery['mdInDemandSkills'],
    key: 'mdInDemandSkills',
    flatResults: true,
    lazyQuery: useLazyQuery(MD_IN_DEMAND_SKILLS_QUERY as typeof MDInDemandSkillsDocument)
  });
  const { results: skills, count: skillsCount, employees_count: skillsEmployees, job_id: skillsJob } = skillsData || {};

  // lazy load Targeted Skills data
  const {
    query: getTargetedSkills, pending: pendingTargeted, failed: failedTargeted, results: targetedData
  } = useQueryObject({
    data: undefined as unknown as MDTargetedSkillsQuery['mdTargetedSkills'],
    key: 'mdTargetedSkills',
    flatResults: true,
    lazyQuery: useLazyQuery(MD_TARGETED_SKILLS_QUERY as typeof MDTargetedSkillsDocument)
  });
  const {
    results: targeted, count: targetedCount, employees_count: targetedEmployees, job_id: targetedJob
  } = targetedData || {};

  // lazy load Targeted Jobs data
  const {
    query: getTargetedJobs, pending: pendingJobs, failed: failedJobs, results: jobsData
  } = useQueryObject({
    data: undefined as unknown as MDTargetedJobsQuery['mdTargetedJobs'],
    key: 'mdTargetedJobs',
    flatResults: true,
    lazyQuery: useLazyQuery(MD_TARGETED_JOBS_QUERY as typeof MDTargetedJobsDocument)
  });
  const {
    results: jobs, count: jobsCount, employees_count: jobsEmployees, job_id: jobsJob
  } = jobsData || {};

  // lazy load Jobs Skills Gap data
  const {
    query: getJobsSkillsGap, pending: pendingGap, failed: failedGap, results: gapData
  } = useQueryObject({
    data: undefined as unknown as MDJobsSkillsGapQuery['mdJobsSkillsGap'],
    key: 'mdJobsSkillsGap',
    flatResults: true,
    lazyQuery: useLazyQuery(MD_JOBS_SKILLS_GAP_QUERY as typeof MDJobsSkillsGapDocument)
  });
  const { results: gap, employees_count: gapEmployees, job_id: gapJob } = gapData || {};

  const { computed: skillsComputed, failed: skillsJobFailed } = useStatusPoller((hasInDemandChart && skillsJob) || undefined);
  const { computed: targetedComputed, failed: targetedJobFailed } = useStatusPoller(
    (hasSkillsChart && targetedJob) || undefined);
  const { computed: jobsComputed, failed: jobsJobFailed } = useStatusPoller((hasJobsChart && jobsJob) || undefined);
  const { computed: gapComputed, failed: gapJobFailed } = useStatusPoller((hasSkillsGapChart && gapJob) || undefined);

  const skillsWaiting = hasInDemandChart && (pendingSkills || (Boolean(skillsJob) && !skillsJobFailed));
  const targetedWaiting = hasSkillsChart && (pendingTargeted || (Boolean(targetedJob) && !targetedJobFailed));
  const jobsWaiting = hasJobsChart && (pendingJobs || (Boolean(jobsJob) && !jobsJobFailed));
  const gapWaiting = hasSkillsGapChart && (pendingGap || (Boolean(gapJob) && !gapJobFailed));

  const jobFailed = (hasInDemandChart && !skillsWaiting && skillsJobFailed) ||
    (hasSkillsChart && !targetedWaiting && targetedJobFailed) ||
    (hasJobsChart && !jobsWaiting && jobsJobFailed) ||
    (hasSkillsGapChart && !gapWaiting && gapJobFailed);

  const skillsReq = hasInDemandChart && ((skillsComputed && 2) || (!skillsJob && 1)) || 0;
  const targetedReq = hasSkillsChart && ((targetedComputed && 2) || (!targetedJob && 1)) || 0;
  const jobsReq = hasJobsChart && ((jobsComputed && 2) || (!jobsJob && 1)) || 0;
  const gapReq = hasSkillsGapChart && ((gapComputed && 2) || (!gapJob && 1)) || 0;

  const managers = (!hrbp && !cocPending && cocData) || null;
  const [manager, setManager] = useState('0');
  const [years, setYears] = useState<number>(CONST_YEARS[1]);
  const [minSkills, setMinSkills] = useState<number>(CONST_MIN_SKILLS[1]);

  const [filters, setFilters] = useState<DashboardFilterValues>();

  const optionalParams = useMemo(() => hrbp ? { years } : undefined, [years, hrbp]);

  const [rootUid, uid, leaderUid, delegate, withDirectReports] = useMemo(() => hrbp ? [
    getRootLeaderId(user),
    getDefaultLeaderId(user),
    getSelectedLeaderId(user),
    hasDelegationOnly(user),
    hasLeadershipOnly(user)
  ] : [], [hrbp, user]);

  useEffect(() => {
    if (!hrbp) requireCoC?.();
  }, [hrbp, requireCoC]);

  const managerDashboardParamsFunc = useMemo(managerDashboardParams, []);
  const managerDashboardParamsGapFunc = useMemo(() => managerDashboardParams(MAX_DASHBOARD_SKILLS_GAP_JOBS), []);

  useEffect(() => {
    if (skillsReq && (!hrbp || filters)) {
      if (skillsReq === 2) apolloCache.evict({ fieldName: 'mdInDemandSkills' });
      getInDemandSkls?.({ variables: {
        input: hrbp && filters ? managerDashboardParamsFunc(filters) as DashboardFilterValues : {},
        pathBuilder: pathBuilder as unknown as string
      } });
    }
  }, [filters, hrbp, skillsReq, managerDashboardParamsFunc, getInDemandSkls, apolloCache]);

  useEffect(() => {
    if (targetedReq && (!hrbp || filters)) {
      if (targetedReq === 2) apolloCache.evict({ fieldName: 'mdTargetedSkills' });
      getTargetedSkills?.({ variables: {
        input: hrbp && filters ? omit(managerDashboardParamsFunc(filters), 'org_id') : {},
        pathBuilder: pathBuilder as unknown as string
      } });
    }
  }, [filters, hrbp, targetedReq, managerDashboardParamsFunc, getTargetedSkills, apolloCache]);

  useEffect(() => {
    if (jobsReq && (!hrbp || filters)) {
      if (jobsReq === 2) apolloCache.evict({ fieldName: 'mdTargetedJobs' });
      getTargetedJobs?.({ variables: {
        input: hrbp && filters
          ? omit(managerDashboardParamsFunc(filters), ['org_id', 'employee_rated_only'])
          : { manager_id: manager },
        pathBuilder: pathBuilder as unknown as string
      } });
    }
  }, [filters, manager, hrbp, jobsReq, managerDashboardParamsFunc, getTargetedJobs, apolloCache]);

  useEffect(() => {
    if (gapReq && (!hrbp || filters)) {
      if (gapReq === 2) apolloCache.evict({ fieldName: 'mdJobsSkillsGap' });
      getJobsSkillsGap?.({ variables: {
        input: hrbp && filters
          ? omit(managerDashboardParamsGapFunc(filters), ['org_id', 'employee_rated_only'])
          : { manager_id: manager },
        pathBuilder: pathBuilder as unknown as string
      } });
    }
  }, [filters, manager, hrbp, gapReq, managerDashboardParamsGapFunc, getJobsSkillsGap, apolloCache]);

  useEffect(() => {
    if (hasEmployeeSkillsChart && filters && minSkills) getEmployeeSkills?.({ variables: {
      input: {
        ...pick(
          managerDashboardParamsFunc(filters),
          ['country_id', 'state_id', 'manager_id', 'org_id', 'job_levels']
        ),
        ...leaderUid ? { selected_leader_id: leaderUid } : {},
        skill_threshold: minSkills
      },
      pathBuilder: pathBuilder as unknown as string
    } });
  }, [filters, minSkills, leaderUid, hasEmployeeSkillsChart, managerDashboardParamsFunc, getEmployeeSkills]);

  const handleFilters = useCallback((newFilters: DashboardFilterValues) => setFilters((prevFilters) => {
    if (isEqual(prevFilters, newFilters)) return prevFilters;
    if (jobFailed) refreshManagerDashboard();
    return newFilters;
  }), [jobFailed, refreshManagerDashboard]);

  const handleManager = useCallback((value: string) => {
    const val = sanitizeLookupString(value, managers);
    setManager((prev) => {
      if (prev !== val && jobFailed) refreshManagerDashboard();
      return val;
    });
  }, [managers, jobFailed, refreshManagerDashboard]);

  const handleMinSkillsChange = useCallback((event: SelectChangeEvent<number>) =>
    setMinSkills(toValidConst(event?.target?.value, CONST_MIN_SKILLS[1], CONST_MIN_SKILLS)), []);
  const handleYearsChange = useCallback((event: SelectChangeEvent<number>) =>
    setYears(toValidConst(event?.target?.value, CONST_YEARS[1], CONST_YEARS)), []);

  const [
    inDemandExportParams,
    skillsExportParams,
    jobsExportParams
    // eslint-disable-next-line array-bracket-newline
  ]: (DashboardExportParams | null)[] = useMemo(() => {
    if ((hrbp && !filters) || !token || !isString(token)) return [null, null, null];
    const params: Omit<DashboardExportParams, 'report_type'> = hrbp
      ? {
          ...managerDashboardParamsFunc({ ...filters as DashboardFilterValues, export_mode: true }),
          selected_leader_id: leaderUid,
          token
        }
      : { token };
    return [
      { ...params, report_type: 'dashboard_indemand' },
      { ...hrbp ? omit(params, 'org_id') : params, report_type: 'dashboard_targetedskills' },
      { ...hrbp ? omit(params, ['org_id', 'employee_rated_only']) : params, report_type: 'dashboard_targetedjobs' }
    ];
  }, [filters, hrbp, leaderUid, token, managerDashboardParamsFunc]);

  const filtersReady = !hrbp || Boolean(filters);
  const exportDisabled = !filtersReady;
  const disabled = exportDisabled || skillsWaiting || targetedWaiting || jobsWaiting ||
    (hasEmployeeSkillsChart && pendingEmployeeSkills) || pendingSettingsUpdate;

  const inDemandPending = skillsWaiting || !filtersReady || !fontsLoaded;
  const inDemandFailed = failedSkills || skillsJobFailed;

  return (
    <>
      <CardTitle title="hr.dashboard.skills_insights" withDivider={hrbp}/>
      {(failed && <FetchFailedAlert flat/>) || (pending && <LoadingPlaceholder flat/>) || (
        <>
          {hrbp && !hasInDemandChart ? (
            <CardSection className={filtersPadding}>
              <DashboardFilters
                  settingsId="hr_dashboard"
                  compact
                  withLevels
                  withReset
                  withEmployeeRated
                  onChange={handleFilters}
                  uid={uid}
                  rootUid={rootUid}
                  // disableTopLevel={!rootUid}
                  delegate={delegate}
                  withDirectReports={withDirectReports}
                  disabled={disabled}
              />
            </CardSection>
          ) : undefined}
          <CardSection shady bottom>
            {!hrbp && (
              <BoxTypography
                  pt={3}
                  pb={0.5}
                  flexGrow={1}
                  px={0.5}
                  variant="body1"
                  fontStyle="italic"
                  color="text.label"
              >
                <FormattedMessage id="supervisor.dashboard.info"/>
              </BoxTypography>
            )}
            <GridBox container spacing={3} pt={hrbp ? (hasInDemandChart && 5) || 3 : 1}>
              {hasInDemandChart ? (
                <TopChart
                    variant="in_demand"
                    layout="wide"
                    info={hrbp ? 'hr.dashboard.info' : undefined}
                    hrbp={hrbp}
                    data={skills}
                    totalEmployees={skillsEmployees}
                    path={PATH_SKILL}
                    pending={inDemandPending}
                    failed={inDemandFailed}
                    exportEndpoint={API_MANAGER_DASHBOARD_EXPORT}
                    exportParams={inDemandExportParams}
                    exportDisabled={skillsWaiting || !skills || !skillsCount || size(skills) < 1 || failedSkills ||
                      skillsJobFailed || !skillsEmployees || exportDisabled}
                    filters={hrbp ? (
                      <DashboardFilters
                          settingsId="hr_dashboard"
                          withOrg
                          withLevels
                          withReset
                          withEmployeeRated
                          onChange={handleFilters}
                          uid={uid}
                          rootUid={rootUid}
                          userOrgId={user?.org?.id}
                          // disableTopLevel={!rootUid}
                          delegate={delegate}
                          withDirectReports={withDirectReports}
                          disabled={disabled}
                          orgFilterPlacement={inDemandPending || inDemandFailed || !skills || size(skills) >= 1
                            ? 'bottom' : 'overlap'}
                      />
                    ) : undefined}
                />
              ) : undefined}
              {hasSkillsChart ? (
                <TopChart
                    variant="skills"
                    hrbp={hrbp}
                    data={targeted}
                    totalEmployees={targetedEmployees}
                    path={PATH_SKILL}
                    pending={targetedWaiting || !filtersReady || !fontsLoaded}
                    failed={failedTargeted || targetedJobFailed}
                    exportEndpoint={API_MANAGER_DASHBOARD_EXPORT}
                    exportParams={skillsExportParams}
                    exportDisabled={targetedWaiting || !targeted || !targetedCount || size(targeted) < 1 || failedTargeted ||
                      targetedJobFailed || !targetedEmployees || exportDisabled}
                />
              ) : undefined}
              {hasJobsChart ? (
                <TopChart
                    variant="jobs"
                    hrbp={hrbp}
                    data={jobs}
                    totalEmployees={jobsEmployees}
                    path={PATH_JOB}
                    pending={jobsWaiting || !filtersReady || !fontsLoaded}
                    failed={failedJobs || jobsJobFailed}
                    action={hrbp ? undefined : (
                      <ChainOfCommandSelector
                          allowEmpty
                          managers={managers}
                          value={manager}
                          onChange={handleManager}
                          disabled={jobsWaiting || exportDisabled}
                      />
                    )}
                    exportEndpoint={API_MANAGER_DASHBOARD_EXPORT}
                    exportParams={jobsExportParams}
                    exportDisabled={jobsWaiting || !jobs || !jobsCount || size(jobs) < 1 || failedJobs || jobsJobFailed ||
                      !jobsEmployees || exportDisabled || (!hrbp && manager !== '0')}
                />
              ) : undefined}
              {hasEmployeeSkillsChart ? (
                <TopChart
                    variant="employee_skills"
                    layout="wide"
                    info="hr.dashboard.employees_skills.info"
                    withInfoButton
                    hrbp={hrbp}
                    data={employeeSkills?.results}
                    totalEmployees={employeeSkills?.total_employees}
                    pending={pendingEmployeeSkills || !filtersReady || !fontsLoaded}
                    failed={failedEmployeeSkills}
                    optionalParams={optionalParams}
                    action={(
                      <>
                        <Box py={1}>
                          <ConstSelector
                              variant="skills"
                              value={minSkills}
                              onChange={handleMinSkillsChange}
                              disabled={disabled ? true : undefined}
                              className={minSkillsSelector}
                          />
                        </Box>
                        <Box py={1}>
                          <ConstSelector
                              variant="years"
                              value={years}
                              onChange={handleYearsChange}
                              disabled={disabled ? true : undefined}
                          />
                        </Box>
                      </>
                    )}
                    // exportEndpoint={...} // TODO: Employees' Skills Export!
                    // exportParams={...}
                    // exportDisabled={...}
                />
              ) : undefined}
              {hasSkillsGapChart ? (
                <TopChart
                    variant="skills_gap"
                    layout="wide"
                    hrbp={hrbp}
                    data={gap}
                    totalEmployees={gapEmployees}
                    path={PATH_SKILL}
                    pending={gapWaiting || !filtersReady || !fontsLoaded}
                    failed={failedGap || gapJobFailed}
                />
              ) : undefined}
            </GridBox>
          </CardSection>
        </>
      )}
    </>
  );
};

ManagerDashboard.propTypes = ManagerDashboardPropTypes;

export default memo(ManagerDashboard);
