import { memo, forwardRef, useCallback } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import { useIntl } from 'react-intl';
// Material UI imports
import IconButton from '@mui/material/IconButton';
// Material Icon imports
import PlusOne from '@mui/icons-material/PlusOne';
import AddRounded from '@mui/icons-material/AddRounded';
import Numeric1 from 'mdi-material-ui/Numeric1';
import Numeric2 from 'mdi-material-ui/Numeric2';
import Numeric3 from 'mdi-material-ui/Numeric3';
import Numeric4 from 'mdi-material-ui/Numeric4';
// local imports
import { Skill, SkillLevel } from '../models/skill';

const iconSx = { ml: -1, mr: 1 };
const plusIconSx = { mr: -1.25, fontSize: '1rem' };

type AddGrowthButtonProps = {
  skill: Skill;
  level?: SkillLevel;
  onClick: (skill: Skill, level?: SkillLevel) => void;
  disabled?: boolean;
}

const AddGrowthButtonPropTypes = {
  // attributes
  skill: PropTypes.object.isRequired as Validator<Skill>,
  level: PropTypes.number as Validator<SkillLevel>,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

const AddGrowthButton = forwardRef<HTMLButtonElement, AddGrowthButtonProps>(({
  skill,
  level,
  onClick,
  disabled = false
}, ref) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();

  const handleClick = useCallback(() => onClick(skill, level && level >= 1 && level <= 4 ? level : undefined),
    [skill, level, onClick]);

  const Icon = (level === 1 && Numeric1) ||
    (level === 2 && Numeric2) ||
    (level === 3 && Numeric3) ||
    (level === 4 && Numeric4) ||
    PlusOne;

  return (
    <IconButton
        ref={ref}
        aria-label={formatMessage({ id: 'opportunities.button.add_growth' })}
        color="primary"
        size="small"
        disabled={disabled}
        onClick={handleClick}
        sx={iconSx}
    >
      {level ? <AddRounded color="inherit" fontSize="inherit" sx={plusIconSx}/> : undefined}
      <Icon color="inherit" fontSize="medium"/>
    </IconButton>
  );
});

AddGrowthButton.displayName = 'AddGrowthButton';

AddGrowthButton.propTypes = AddGrowthButtonPropTypes;

export default memo(AddGrowthButton);
