import { gql } from '@apollo/client';
// local imports
import { API_SUPV_OPPORTUNITIES } from '../config/api';

export const NEW_OPPORTUNITY = gql`
  mutation NewOpportunity(
    $input: OpportunityInput!
  ) {
    newOpportunity(
      input: $input
    )
    @rest(
      type: "MutationResponseWithId",
      path: "${API_SUPV_OPPORTUNITIES}",
      method: "POST"
    )
    # status 201
    {
      id
      success
    }
  }
`;
