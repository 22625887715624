import { gql } from '@apollo/client';
// local imports
import { API_ADMIN_REPORT } from '../config/api';

export const ADMIN_TARGET_JOBS_BY_EMPLOYEE_QUERY = gql`
  query ARTargetJobsByEmployee(
    $report_id: Int!
    $offset: Int
    $limit: Int
  ) {
    arTargetJobsByEmployee(
      report_id: $report_id,
      offset: $offset,
      limit: $limit
    )
    @rest(
      type: "ARTargetJobsByEmployeePayload",
      path: "${API_ADMIN_REPORT}?limit={args.limit}&offset={args.offset}"
    ) {
      count
      results {
        id
        code
        email
        first_name
        last_name
        is_contractor
        count
      }
    }
  }
`;
