import { gql } from '@apollo/client';
// local imports
import { API_DEV_PLAN } from '../config/api';

export const DEV_PLAN_COURSES_QUERY = gql`
  query DevPlanCourses(
    $devplan_id: Int!
    $type: DevPlanCourseType!
    $exclude_ids: String!
    $target_skill_ids: String!
  ) {
    devplanCourses(
      devplan_id: $devplan_id,
      type: $type,
      exclude_ids: $exclude_ids,
      target_skill_ids: $target_skill_ids
    )
    @rest(
      type: "DevPlanCoursesPayload",
      path: "${API_DEV_PLAN}{args.type}/?target_skill_ids={args.target_skill_ids}&exclude_ids={args.exclude_ids}"
    ) {
      count
      results {
        id
        title
        code
        abbr
        kind
        level
        rating
        link
        level_growth_in_quarters
        external_url
        duration_value
        duration_unit
        certification_program
        video_url
        credit_hours
        is_self_placed
        description
        preferred_rating
        is_selected # local field used in DevPlanEditor
        provider {
          id
          title
          code
        }
        covered_skills {
          id
          title
          abbr
          current_level
        }
      }
    }
  }
`;
