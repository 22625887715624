import map from 'lodash/map';
import head from 'lodash/head';
import last from 'lodash/last';
import some from 'lodash/some';
import times from 'lodash/times';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import floor from 'lodash/floor';
import constant from 'lodash/constant';
import forEach from 'lodash/forEach';
import findLast from 'lodash/findLast';
import transform from 'lodash/transform';
import rangeRight from 'lodash/rangeRight';
import { type DateTime } from 'luxon';
// EmPath UI Components
import { svgPath as RadioButtonFilled } from '@empathco/ui-components/src/icons/RadioButtonFilled';
import { getCurrentUtc, getFormattedQuarter, getUtcFromISO } from '@empathco/ui-components/src/helpers/datetime';
// local imports
import { Maybe } from '../graphql/types';
import { CONST_YEARS } from '../constants/constValues';

export type NormalizedTimelineValue = number | null | Maybe<number> | undefined;
export type NormalizedTimelineExtra<T> = (T[] | null | undefined)[];

export type NormalizedTimelineData<T> = {
  date: DateTime;
  values: NormalizedTimelineValue[];
  extraData?: NormalizedTimelineExtra<T>;
};

const getBarDate = (date: DateTime, quarters: boolean): DateTime => quarters ? date.startOf('quarter')
  : getUtcFromISO(`${date.year}-${date.month > 6 ? '07' : '01'}-01T00:00:00.000Z`);

export const pushTimelineData = <T>(
  result: NormalizedTimelineData<T>[],
  date: string,
  values: NormalizedTimelineValue[],
  extraData?: NormalizedTimelineExtra<T>,
  index?: number,
  seriesCount?: number
) => {
  const dt = getUtcFromISO(`${date}T00:00:00.000Z`).startOf('month');
  const lst = last(result);
  if (lst?.date.equals(dt)) {
    // values
    if (isNil(index)) lst.values = values;
    else [lst.values[index]] = values;
    // extraData
    lst.extraData = extraData;
  } else if (isNil(index)) {
    result.push({ date: dt, values, extraData });
  } else {
    const vals: NormalizedTimelineValue[] = times(seriesCount || 0, constant(null));
    [vals[index]] = values;
    result.push({ date: dt, values: vals, extraData });
  }
};

export const getPreparedTimelineData = <T>({
  normalized,
  wide,
  years
}: {
  normalized: NormalizedTimelineData<T>[];
  wide: boolean;
  years?: number | null;
}) => {
  // Dropping months outside the selected number of years and available (covered by data) number of months:
  const maxMonths = 12 * (years || CONST_YEARS[CONST_YEARS.length - 1]);
  const currentNormDate = getBarDate(getCurrentUtc(), true);
  const firstNormDate = head(normalized)?.date;
  const lastNormDateDate = last(normalized)?.date;

  let takeMonths = maxMonths;
  let stepMonths = 3;
  let byQuarters = true;
  do {
    byQuarters = stepMonths === 3;
    const lastNormDate = lastNormDateDate && lastNormDateDate > currentNormDate ? lastNormDateDate : currentNormDate;
    const dataMonths = firstNormDate && lastNormDate
      ? (getBarDate(lastNormDate, byQuarters).diff(getBarDate(firstNormDate, byQuarters), 'months')
          .toObject().months ?? 0) + (byQuarters ? 3 : 6)
      : 0;
    takeMonths = dataMonths < 1 || dataMonths > maxMonths ? maxMonths : dataMonths;
    stepMonths = takeMonths <= (wide ? 24 : 12) ? 3 : 6;
    if (stepMonths === 3) break;
  } while (byQuarters);

  // Reduce data resolution to quarters or semesters:
  const prepared = transform(normalized, (result, { date, values, extraData }) => {
    const dt = getBarDate(date, stepMonths === 3);
    const lst = last(result);
    if (lst?.date.equals(dt)) {
      lst.values = map(values, (val, idx) => isNull(val) ? lst.values[idx] : val);
      lst.extraData = extraData || lst.extraData;
    } else {
      result.push({ date: dt, values, extraData });
    }
  }, [] as NormalizedTimelineData<T>[]);

  const currentDate = stepMonths === 3 ? currentNormDate : getBarDate(getCurrentUtc(), false);
  const lastDataDate = last(prepared)?.date;
  const lastDate = lastDataDate && lastDataDate > currentDate ? lastDataDate : currentDate;
  const maxIdx = floor(takeMonths / stepMonths) - 1;

  return { takeMonths, stepMonths, lastDate, maxIdx, prepared };
};

export const generateTimelineChartData = <T>({
  seriesCount,
  takeMonths,
  stepMonths,
  lastDate,
  prepared,
  callback
} : {
  seriesCount: number;
  takeMonths: number;
  stepMonths: number;
  lastDate: DateTime;
  prepared: NormalizedTimelineData<T>[];
  callback: (
    index: number,
    category: string,
    values: NormalizedTimelineValue[],
    extraData?: NormalizedTimelineExtra<T>
  ) => void;
}) => {
  // Generating chart data:
  let dataIndex = 0;
  forEach(rangeRight(0, takeMonths, stepMonths), (months, valueIndex) => {
    // get bar date:
    const date = lastDate.minus({ months });
    // bar date to category
    const category = stepMonths === 3 ? getFormattedQuarter(date) : `${floor((date.quarter - 1) / 2) + 1}H${date.year}`;

    // forward cursor to current bar date:
    while (dataIndex < prepared.length && prepared[dataIndex].date < date) dataIndex += 1;
    const endOfData = dataIndex >= prepared.length;

    // init values with nulls if there is no data for current bar date
    // (and get `extraData` from previous bar with at least one non-null value)
    const { values, extraData } = !endOfData && date.equals(prepared[dataIndex].date) ? prepared[dataIndex]
      : {
          values: times(seriesCount, constant(null)),
          extraData: findLast(
            prepared,
            ({ values: prevValues }) => some(prevValues, (val) => !isNull(val)),
            endOfData ? dataIndex : dataIndex - 1
          )?.extraData || undefined
        };
    // get missing values from previous bars:
    const nonNullValues = map(values, (val, idx) => isNull(val) && (endOfData || dataIndex > 0)
      ? findLast(
          prepared,
          ({ values: prevValues }) => !isNull(prevValues[idx]),
          endOfData ? dataIndex : dataIndex - 1
        )?.values[idx] || null
      : val);

    callback(valueIndex, category, nonNullValues, extraData);
  });
};

export const addPointsFactory = ({
  preview,
  highlighted,
  pointSize,
  colors,
  altColors,
  paperColor,
  dots
}: {
  preview: boolean;
  dots: boolean | null; // true=filled, false=unfilled, null=none
  highlighted: boolean; // last point
  pointSize: number;
  colors: string[];
  altColors: string[];
  paperColor: string;
}) =>
  // eslint-disable-next-line complexity
  (points: object[], valueIndex: number, idx: number, val: number | null, maxIdx: number) => {
    if (!isNull(dots)) points.push({
      coord: [valueIndex, val],
      symbol: highlighted && valueIndex === maxIdx ? RadioButtonFilled : 'circle',
      symbolSize: (preview ? 0.6 : 1) * (
        (highlighted && valueIndex === maxIdx && 24) ||
        pointSize
      ),
      symbolKeepAspect: true,
      itemStyle: (highlighted && valueIndex === maxIdx && { color: altColors[idx] }) ||
        (dots && { color: colors[idx] }) ||
        {
          color: paperColor,
          borderColor: colors[idx]
        }
    });
    if (highlighted && valueIndex === maxIdx) points.push({
      coord: [valueIndex, val],
      symbol: 'circle',
      symbolSize: preview ? 5.5 : 8
    });
  };
