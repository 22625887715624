import isSafeInteger from 'lodash/isSafeInteger';
// EmPath UI Components
import { isEmptyString } from '@empathco/ui-components/src/helpers/strings';
// local imports
import {
  JOBS_INDEX_FETCH, RECOMMENDED_SKILLS_FETCH, SKILLS_GAP_FETCH,
  TARGETED_SKILLS_FETCH, LEARNING_PROGRESS_FETCH, MENTORSHIP_FETCH,
  EMPLOYEE_BOARD_SKILLS_FETCH, EMPLOYEE_BOARD_SUGGESTED_FETCH, EMPLOYEE_BOARD_JOBS_FETCH, IN_DEMAND_SKILLS_FETCH
} from './dataContext';
import { isValidSortBy, isValidSortDirection } from '../constants/jobSort';
import {
  API_MY_CURRENT_JOB, API_EMPLOYEE_SKILLS_TARGETED, API_EMPLOYEE_LEARNING_PROGRESS, API_EMPLOYEE_MENTORING,
  API_EMPLOYEE_JOBS_BASE, API_EMPLOYEE_SKILLS_RECOMMENDATIONS, getApiSkillsGap,
  API_MY_DASHBOARD_SKILLS, API_MY_DASHBOARD_SUGGESTED, API_MY_DASHBOARD_JOBS, API_EMPLOYEE_SKILLS_IN_DEMAND
} from '../config/api';
import { MAX_MATCH_RATE, MIN_MATCH_RATE } from '../config/params';
import { fetchFactory } from '../helpers/actions';
import { sanitizeRoleScope, locationParams } from '../helpers/context';
import { transformBoardJobs, transformBoardSuggested, transformRoles, transformSkills } from './dataTranformations';

export const requireBoardSkills = (token, online, unauthenticate, dispatch, boardSkills) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  type: EMPLOYEE_BOARD_SKILLS_FETCH,
  entity: boardSkills,
  api: API_MY_DASHBOARD_SKILLS,
  results: 'my_skills_counts'
});

export const requireBoardSuggested = (token, online, unauthenticate, dispatch, boardSuggested) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  type: EMPLOYEE_BOARD_SUGGESTED_FETCH,
  entity: boardSuggested,
  api: API_MY_DASHBOARD_SUGGESTED,
  results: 'suggested_skills',
  transformation: transformBoardSuggested
});

export const requireBoardJobs = (token, online, unauthenticate, dispatch, boardJobs) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  type: EMPLOYEE_BOARD_JOBS_FETCH,
  entity: boardJobs,
  api: API_MY_DASHBOARD_JOBS,
  results: 'close_match_jobs',
  transformation: transformBoardJobs
});

export const requireInDemandSkills = (token, online, unauthenticate, dispatch, skillsInDemand, routeParams) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  routeParams,
  type: IN_DEMAND_SKILLS_FETCH,
  entity: skillsInDemand,
  withCount: true,
  params: ({ org_id, limit, offset }) => ({
    org_id: org_id && org_id >= 1 && isSafeInteger(org_id) ? org_id : null,
    limit: limit && limit >= 1 && isSafeInteger(limit) ? limit : null,
    offset: offset && offset >= 1 && isSafeInteger(offset) ? offset : null
  }),
  api: API_EMPLOYEE_SKILLS_IN_DEMAND,
  transformation: transformSkills
});

export const requireRecommendedSkills = (
  token, online, unauthenticate, dispatch, recommendedSkills, routeParams
) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  routeParams,
  params: ({ in_demand_only }) => ({
    in_demand_only: in_demand_only === true ? in_demand_only : null
  }),
  type: RECOMMENDED_SKILLS_FETCH,
  entity: recommendedSkills,
  api: API_EMPLOYEE_SKILLS_RECOMMENDATIONS
  // transformation: ...
});

export const requireTargetSkills = (token, online, unauthenticate, dispatch, targetSkills, routeParams) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  routeParams,
  type: TARGETED_SKILLS_FETCH,
  entity: targetSkills,
  api: API_EMPLOYEE_SKILLS_TARGETED
  // transformation: ...
});

export const requireLearningProgress = (
  token, online, unauthenticate, dispatch, learningProgress, routeParams
) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  routeParams,
  params: ({ duration }) => ({
    duration: duration && duration >= 1 && isSafeInteger(duration) ? duration : null
  }),
  type: LEARNING_PROGRESS_FETCH,
  entity: learningProgress,
  api: API_EMPLOYEE_LEARNING_PROGRESS
});

export const requireSkillsGap = (token, online, unauthenticate, dispatch, skillsGap) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  // routeParams,
  type: SKILLS_GAP_FETCH,
  entity: skillsGap,
  params: ({ role_id }) => ({
    role_id: isEmptyString(role_id) ? null : role_id
  }),
  api: ({ role_id }) => role_id ? getApiSkillsGap(role_id) : API_MY_CURRENT_JOB,
  dropParams: ['role_id'],
  results: ''
});

export const requireJobsIndex = (token, online, unauthenticate, dispatch, jobsIndex, routeParams) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  routeParams,
  type: JOBS_INDEX_FETCH,
  entity: jobsIndex,
  withCount: true,
  // eslint-disable-next-line complexity
  params: ({
    scope, open_reqs_only, supervisory_jobs_only, min_match_rate, country_id, state_id, location_id,
    management_level, management_level_min, management_level_max,
    job_category_id, career_ladder_id, org_id, sort_by, direction, limit, offset
  }) => sanitizeRoleScope({
    ...locationParams(country_id, state_id, location_id),
    scope,
    job_category_id: job_category_id && job_category_id >= 1 && isSafeInteger(job_category_id) ? job_category_id : null,
    career_ladder_id: career_ladder_id && career_ladder_id >= 1 && isSafeInteger(career_ladder_id)
      ? career_ladder_id : null,
    org_id: org_id && org_id >= 1 && isSafeInteger(org_id) ? org_id : null,
    open_reqs_only: open_reqs_only === true ? true : null,
    supervisory_jobs_only: supervisory_jobs_only === true ? true : null,
    min_match_rate: min_match_rate >= MIN_MATCH_RATE && min_match_rate <= MAX_MATCH_RATE && isSafeInteger(min_match_rate)
      ? min_match_rate : null,
    management_level: isSafeInteger(management_level) ? management_level : null,
    management_level_min: isSafeInteger(management_level_min) ? management_level_min : null,
    management_level_max: isSafeInteger(management_level_max) ? management_level_max : null,
    sort_by: isValidSortBy(sort_by) ? sort_by : null,
    direction: isValidSortDirection(direction) ? direction : null,
    limit: limit && limit >= 1 && isSafeInteger(limit) ? limit : null,
    offset: offset && offset >= 1 && isSafeInteger(offset) ? offset : null
  }),
  api: API_EMPLOYEE_JOBS_BASE,
  transformation: transformRoles
});

export const requireMentorship = (token, online, unauthenticate, dispatch, mentorship, routeParams) => fetchFactory({
  token,
  online,
  unauthenticate,
  dispatch,
  routeParams,
  type: MENTORSHIP_FETCH,
  entity: mentorship,
  api: API_EMPLOYEE_MENTORING
});
