import { type Dispatch } from 'react';
import get from 'lodash/get';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import toSafeInteger from 'lodash/toSafeInteger';
import { type AxiosResponse, type AxiosError } from 'axios';
// local imports
import { APIResponse } from '../models/apiResponse';
import { DELAYED, FETCHED, FETCHING, PARAMS } from '../constants/actionTypes';
import { AuthErrorCode } from '../constants/authErrorCodes';
import { axiosInstance as axios } from '../config/api';
import { IContextObject, ContextParams, IContextEntity, ICountedData, IContextEntityWithCount } from '../models/contextEntity';
import { alreadyDone, getRequestHeaders, keepDataOffline, optimizeParams } from './context';
import { RouteParams } from '../context/commonParams';

export type APIResults<R, D> = string | ((data: APIResponse<R> | Partial<R>) => D | null);

export interface APICallbacks<D> {
  onSuccess?: (data: D) => void;
  onError?: (error?: unknown) => void;
}

const getResults = <R, D>(data?: APIResponse<R>, results?: APIResults<R, D>): D | null => data && results
  ? ((
      (isFunction(results) && results(data)) ||
      (isString(results) && get(data, results))
    ) as D) || null
  : (data as D) || null;

function getData<T, R, D, P>(
  response?: APIResponse<R>,
  results?: APIResults<R, D>,
  transformation?: (data: D, params?: P) => T | T[],
  params?: P,
  extraResults?: string[],
  withCount = false
): D | ICountedData<T> | T | T[] | null {
  if (withCount) {
    if (!response || isNil(response.count)) return null;
    const data = getResults(response, results);
    return data ? {
      ...extraResults ? pick(response, extraResults) : {},
      data: transformation ? transformation(data, params) : data,
      count: toSafeInteger(response.count)
    } as ICountedData<T> : null;
  }
  const data = getResults(response, results);
  return data && transformation ? transformation(data, params) : data;
}

export const fetchFactory = <T, A, P = {}, D = T, R = D>({
  token, // auth token (string) [REQUIRED]; in global context to receive token during authentication set this value to `true`
  online, // online global state [REQUIRED]
  unauthenticate, // `unauthenticate` action from Global context [REQUIRED, expect GlobalContext]
  dispatch, // reducer's dispatch [REQUIRED]
  type, // type of action (string) [REQUIRED]
  transformation, // fetched data transformation function [optional]
  api, // API URI (string or function - called with params hash) [REQUIRED]
  method = 'GET',
  routeParams = {} as RouteParams, // context routeParams [optional]
  entity: { data: entity, pending, failed, params: entityParams }, // data entity
  withCount = false,
  withDelayed = false,
  params: paramNames, // accepted action parameters (array of strings (names) or function) [optional]
  validator, // action parameters validator [optional]
  dropParams, // action parameters to be excluded from API call (array of strings) or function [optional]
  results = 'results', // key in data object which contains fetched records (specify '' to access whole data object) or function
  extraResults, // array of keys in data object (siblings of `results` and `count`) to include into payload
  onSuccess, // success callback function
  onError // error callback function
}: {
  token: string | boolean | null;
  online: boolean | 'yes';
  unauthenticate?: () => void;
  dispatch: Dispatch<A>;
  type: string;
  transformation?: (data: D, params?: P) => T | T[];
  api: string | ((params: P) => string);
  method?: 'GET' | 'POST',
  routeParams?: RouteParams;
  entity: IContextEntityWithCount<T, P> | IContextEntity<T, P> | IContextObject<T, P>;
  withCount?: boolean;
  withDelayed?: boolean;
  params?: string[] | ((params: P) => P);
  validator?: (params: P) => boolean;
  dropParams?: string[] | ((params: P) => P | ContextParams);
  results?: APIResults<R, D>;
  extraResults?: string[];
  // eslint-disable-next-line complexity, max-statements
} & APICallbacks<D>) => async (callParams: P = {} as P) => {
  if (!token || pending) return;
  const params = optimizeParams<P>({
    ...routeParams,
    ...isArray(paramNames) ? pick(callParams, paramNames) : {},
    ...isFunction(paramNames) ? paramNames(callParams || {} as P) : {}
  } as P & RouteParams, online);
  if ((validator && !validator(params)) || alreadyDone(entity, pending, failed, entityParams, params)) return;
  try {
    if (keepDataOffline(online, entity, pending, failed)) {
      dispatch({
        type: `${type}${PARAMS}`,
        params
      } as unknown as A);
      return;
    }
    dispatch({
      type: `${type}${FETCHING}`,
      params
    } as unknown as A);
    const { status, data } = await axios.request<ContextParams, AxiosResponse<APIResponse<R>>>({
      method,
      url: isFunction(api) ? api(params) : api,
      [method === 'GET' ? 'params' : 'data']:
        (isArray(dropParams) && omit(params as ContextParams, dropParams)) ||
        (isFunction(dropParams) && dropParams(params)) ||
        params,
      headers: getRequestHeaders(token)
    }) || {};
    // Authentication token expired:
    if (status === 401 && isFunction(unauthenticate)) {
      unauthenticate();
      return;
    }
    // the response is delayed and must be accessed later, when job_id is finished:
    if (withDelayed && status === 200 && data?.job_id) {
      dispatch({
        type: `${type}${DELAYED}`,
        payload: data.job_id,
        params
      } as unknown as A);
    } else {
      const fetched = getData(data, results, transformation, params, extraResults, withCount);
      if ((method === 'GET' ? status !== 200 : status < 200 || status > 201) || !data || !fetched) throw new Error();
      if (onSuccess) onSuccess(fetched as D);
      (params as APICallbacks<D>)?.onSuccess?.(fetched as D);
      dispatch({
        type: `${type}${FETCHED}`,
        payload: fetched,
        params
      } as unknown as A);
    }
  } catch (error) {
    // TODO: handle 404 Not Found (somehow)
    // Authentication token expired:
    if ((error as AxiosError)?.response?.status === 401) {
      if (isFunction(unauthenticate)) {
        unauthenticate();
        return;
      }
    }
    onError?.(error);
    (params as APICallbacks<D>)?.onError?.(error);
    const { error_code } = (error as AxiosError<{ error_code: AuthErrorCode }>)?.response?.data || {};
    dispatch({
      type: `${type}${FETCHED}`,
      payload: null,
      params,
      ...error_code ? { error_code } : {}
    } as unknown as A);
  }
};
