import { gql } from '@apollo/client';
// local imports
import { API_ADMIN_USER } from '../config/api';

export const DELETE_ADMIN_USER = gql`
  mutation DeleteAdminUser(
    $user_id: Int!
  ) {
    deleteAdminUser(
      user_id: $user_id
    )
    @rest(
      type: "MutationResponse",
      path: "${API_ADMIN_USER}",
      method: "DELETE"
    ) {
      success # just a placeholder; this request has empty response (status 204 only)
    }
  }
`;
