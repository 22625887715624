import { forwardRef, memo, useMemo } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import isNil from 'lodash/isNil';
import { FormattedDate, FormattedMessage } from 'react-intl';
// Material UI imports
import { type TableProps } from '@mui/material/Table';
import TableSortLabel from '@mui/material/TableSortLabel';
// Material Icon imports
import CheckIcon from '@mui/icons-material/Check';
// EmPath UI Components
import { shortDateOptions } from '@empathco/ui-components/src/common/intl';
import { getJsDateFromISO } from '@empathco/ui-components/src/helpers/datetime';
import { getName } from '@empathco/ui-components/src/helpers/strings';
import SortArrow from '@empathco/ui-components/src/icons/SortArrow';
import DataTable from '@empathco/ui-components/src/elements/DataTable';
// local imports
import { AdminUser, AdminUsersSort, SortDirection } from '../graphql/types';
import { ADMIN_USERS_SORT, DEFAULT_ADMIN_USERS_DIRECTION } from '../graphql/customTypes';
import EmployeeName from '../elements/EmployeeName';

export type EmployeeSelection = Record<number, boolean>;

type UsersTableProps = {
  data?: AdminUser[] | null;
  pending?: boolean | null;
  failed?: boolean | null;
  sortBy?: AdminUsersSort | null;
  direction?: SortDirection | null;
  changeSort: (sort: AdminUsersSort, direction: SortDirection) => void;
  onClick: (user: AdminUser) => void;
  disabled?: boolean | null;
  withReloading?: boolean;
  tableSize?: TableProps['size'];
}

const UsersTablePropTypes = {
  // attributes
  data: PropTypes.arrayOf(PropTypes.object.isRequired) as Validator<AdminUser[]>,
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  sortBy: PropTypes.string as Validator<AdminUsersSort>,
  direction: PropTypes.string as Validator<SortDirection>,
  changeSort: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  withReloading: PropTypes.bool,
  tableSize: PropTypes.string as Validator<TableProps['size']>
};

const UsersTable = forwardRef<HTMLDivElement, UsersTableProps>(({
  data,
  pending = false,
  failed = false,
  sortBy,
  direction,
  changeSort,
  onClick,
  disabled = false,
  withReloading = false,
  tableSize
}, ref) => {

  const sortHandlers = useMemo(() => ADMIN_USERS_SORT.map((auSort) => () => changeSort(
      auSort,
      sortBy === auSort && !isNil(direction)
        ? (direction === SortDirection.ascending && SortDirection.descending) || SortDirection.ascending
        : DEFAULT_ADMIN_USERS_DIRECTION[auSort]
    )
  ), [direction, changeSort, sortBy]);

  const sortDisabled = disabled || pending || failed || !data || size(data) < 1;

  const titles = useMemo(() => map([
    { label: 'admin.users.column.id', id: AdminUsersSort.id, handler: sortHandlers[0] },
    { label: 'admin.users.column.username', id: AdminUsersSort.username, handler: sortHandlers[1] },
    { label: 'admin.users.column.first_name' },
    { label: 'admin.users.column.last_name', id: AdminUsersSort.last_name, handler: sortHandlers[2] },
    { label: 'admin.users.column.email', id: AdminUsersSort.email, handler: sortHandlers[3] },
    { label: 'admin.users.column.active', id: AdminUsersSort.is_active, handler: sortHandlers[4] },
    { label: 'admin.users.column.joined', id: AdminUsersSort.date_joined, handler: sortHandlers[5] }
  ], ({ id, label, handler }) => id
    ? (
      <TableSortLabel key={id}
          direction={(sortBy === id && direction ? direction : DEFAULT_ADMIN_USERS_DIRECTION[id]) ===
            SortDirection.ascending ? 'asc' : 'desc'}
          active={sortBy === id}
          onClick={handler}
          disabled={sortDisabled}
          IconComponent={SortArrow}
      >
        <FormattedMessage id={label}/>
      </TableSortLabel>
    ) : <FormattedMessage key={label} id={label}/>
  ), [direction, sortBy, sortDisabled, sortHandlers]);

  // eslint-disable-next-line complexity
  const rows = useMemo(() => map(data, (user) => {
    const { id, username, first_name, last_name, email, is_active, date_joined } = user;
    return {
      selected: false,
      values: [
        /* eslint-disable react/jsx-key */
        id || '—',
        username ? (
          <EmployeeName
              variant="subtitle2"
              admin
              employee={user}
              fullName={username}
              onClick={onClick}
              disabled={disabled || pending ? true : undefined}
          />
        ) : '—',
        getName(first_name || '—'),
        getName(last_name || '—'),
        email || '—',
        is_active ? <CheckIcon/> : '—',
        // eslint-disable-next-line react/jsx-props-no-spreading
        date_joined ? <FormattedDate value={getJsDateFromISO(date_joined)} {...shortDateOptions}/> : '—'
        /* eslint-enable react/jsx-key */
      ]
    };
  }), [data, disabled, pending, onClick]);

  return (
    <DataTable
        ref={ref}
        tableSize={tableSize}
        titles={titles}
        empty="admin.users.empty"
        lastLeftAlignedTitle={4}
        data={rows}
        pending={withReloading ? pending && !data : pending || !data}
        failed={failed}
    />
  );
});

UsersTable.displayName = 'UsersTable';

UsersTable.propTypes = UsersTablePropTypes;

export default memo(UsersTable);
