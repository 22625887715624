import { gql } from '@apollo/client';
// local imports
import { API_COHORT } from '../config/api';

export const UPDATE_COHORT = gql`
  mutation UpdateCohort(
    $cohort_id: Int!
    $input: UpdateCohortInput!
  ) {
    updateCohort(
      cohort_id: $cohort_id,
      input: $input
    )
    @rest(
      type: "Cohort",
      path: "${API_COHORT}",
      method: "PATCH"
    ) {
      success
    }
  }
`;
