import { memo, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import range from 'lodash/range';
import round from 'lodash/round';
import toSafeInteger from 'lodash/toSafeInteger';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import { visuallyHidden } from '@mui/utils';
import Box from '@mui/material/Box';
import Grid, { type GridSize } from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
// local imports
import { SKILL_LEVEL_MIN, SKILL_LEVEL_MAX, SKILL_LEVEL_FIRST, SkillLevel } from '../models/skillLevel';
import BoxTypography from '../mixins/BoxTypography';
// SCSS imports
import {
  root, smallRoot, requirement, smallRequirement, current, smallCurrent, rect, smallRect, rectSelected, normal, normalSelected,
  inferred, empty, smallEmpty, line, mark, unmet, satisfied, required, growth
} from './SkillLevelGauge.module.scss';

const rootSx = { forcedColorAdjust: 'none' };

type SkillLevelGaugeProps = {
  level?: SkillLevel | null;
  requiredLevel?: number | null;
  inferredLevel?: number | null;
  isInferenceNewer?: boolean | null;
  pending?: boolean | null;
  depersonalised?: boolean;
  withoutText?: boolean | null;
  withGrowthLevel?: boolean | null;
  selected?: boolean | null;
  small?: boolean | null;
};

const SkillLevelGaugePropTypes = {
  // attributes
  level: PropTypes.number as Validator<SkillLevel>,
  requiredLevel: PropTypes.number,
  inferredLevel: PropTypes.number,
  isInferenceNewer: PropTypes.bool,
  pending: PropTypes.bool,
  depersonalised: PropTypes.bool,
  withoutText: PropTypes.bool,
  withGrowthLevel: PropTypes.bool,
  selected: PropTypes.bool,
  small: PropTypes.bool
};

const LEVEL_COLS = round(12 / SKILL_LEVEL_MAX);

// eslint-disable-next-line complexity
const SkillLevelGauge: FunctionComponent<SkillLevelGaugeProps> = ({
  level,
  requiredLevel,
  inferredLevel,
  isInferenceNewer = false,
  pending = false,
  depersonalised = false,
  withoutText = false,
  withGrowthLevel = false,
  selected = false,
  small = false
}) => {
  const inferredLvl = depersonalised ? 0 : toSafeInteger(inferredLevel);
  const displayInferrence = (isNil(level) || isInferenceNewer) && inferredLvl >= SKILL_LEVEL_FIRST;
  const currentLevel = displayInferrence || depersonalised ? 0 : toSafeInteger(level);
  const effectiveLevel = displayInferrence ? inferredLvl : currentLevel;
  const showRequired = isNil(requiredLevel) ? null
    : (depersonalised && required) || (withGrowthLevel && growth) || (effectiveLevel >= requiredLevel && satisfied) || unmet;
  const textLabelLevel = depersonalised && showRequired ? requiredLevel : effectiveLevel;
  const nonZeroReq = Boolean(showRequired) && (requiredLevel ?? 0) >= SKILL_LEVEL_FIRST;
  const normalBarCols = pending || currentLevel < SKILL_LEVEL_FIRST ? 0 : round(12 / currentLevel);
  const emptyBarCols = pending || currentLevel >= SKILL_LEVEL_MAX ? 0 : round(12 / (SKILL_LEVEL_MAX - currentLevel));
  return (
    <>
      <Box
          display="flex"
          alignItems="center"
          flexWrap="nowrap"
          sx={rootSx}
      >
        <Grid
            container
            wrap="nowrap"
            className={small ? smallRoot : root}
        >
          {pending ? (
            <Grid item container xs={12}>
              <Skeleton variant="text" width="100%"/>
            </Grid>
          ) : undefined}
          {normalBarCols >= 1 && (
            <Grid
                item
                container
                xs={(currentLevel * LEVEL_COLS) as GridSize}
                className={small ? smallCurrent : current}
            >
              {map(range(SKILL_LEVEL_MIN, currentLevel), (lvl) => (
                <Grid
                    key={lvl}
                    item
                    container
                    xs={normalBarCols as GridSize}
                    className={(selected && rectSelected) || (small && smallRect) || rect}
                >
                  <Box flexGrow={1} className={selected ? normalSelected : normal}/>
                </Grid>
              ))}
            </Grid>
          )}
          {emptyBarCols >= 1 && (
            <Grid
                item
                container
                wrap="nowrap"
                xs={((SKILL_LEVEL_MAX - currentLevel) * LEVEL_COLS) as GridSize}
            >
              {map(range(currentLevel, SKILL_LEVEL_MAX), (lvl) => (
                <Grid
                    key={lvl}
                    item
                    container
                    xs={emptyBarCols as GridSize}
                    className={(selected && rectSelected) || (small && smallRect) || rect}
                >
                  <Box
                      flexGrow={1}
                      className={(displayInferrence && lvl < inferredLvl && inferred) || (small && smallEmpty) || empty}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
        {!pending && !withoutText ? (
          <BoxTypography
              aria-hidden
              pl={1}
              flexGrow={1}
              color="text.label"
              fontStyle="italic"
              variant="caption"
              noWrap
          >
            <FormattedMessage
                id="common.skill_level.text"
                values={{ level: toSafeInteger(textLabelLevel) }}
            />
          </BoxTypography>
        ) : undefined}
        {depersonalised ? undefined : (
          <Box sx={visuallyHidden}>
            <FormattedMessage
                id="common.skill_level.title"
                values={{ level: toSafeInteger(textLabelLevel), inferred: displayInferrence }}
            />
          </Box>
        )}
      </Box>
      {showRequired ? (
        <>
          <Grid
              container
              className={small ? smallRequirement : requirement}
          >
            <Grid
                item
                container
                alignItems="flex-end"
                justifyContent={nonZeroReq ? 'flex-end' : 'flex-start'}
                xs={((requiredLevel ?? 0) * LEVEL_COLS || 1) as GridSize}
                className={clsx(line, { [showRequired]: nonZeroReq })}
            >
              <Box className={`${mark} ${showRequired}`}/>
            </Grid>
          </Grid>
          {requiredLevel ? (
            <Box sx={visuallyHidden}>
              <FormattedMessage
                  id="common.skill_level.required"
                  values={{
                    level: toSafeInteger(requiredLevel),
                    satisfied: (showRequired === satisfied) || (showRequired === unmet ? false : null),
                    growth: showRequired === growth
                  }}
              />
            </Box>
          ) : undefined}
        </>
      ) : undefined}
    </>
  );
};

SkillLevelGauge.propTypes = SkillLevelGaugePropTypes;

export default memo(SkillLevelGauge);
