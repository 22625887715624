import { memo, useContext, useEffect, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
// local imports
import { DataContext } from '../context';
import SkillsGrid from '../v3/SkillsGrid';

type TargetSkillsProps = {
  supervisor?: boolean;
  uid?: string | null;
}

const TargetSkillsPropTypes = {
  supervisor: PropTypes.bool,
  uid: PropTypes.string
};

const TargetSkills: FunctionComponent<TargetSkillsProps> = ({
  supervisor = false,
  uid
}) => {
  const {
    targetSkills: { data: skills, pending, failed },
    requireTargetSkills
  } = useContext(DataContext);

  useEffect(() => {
    requireTargetSkills?.();
  }, [uid, requireTargetSkills]);

  return (
    <SkillsGrid
        source="target"
        isEmployee={!supervisor}
        readOnly={supervisor}
        supervisor={supervisor}
        skills={skills}
        pending={pending}
        failed={failed}
    />
  );
};

TargetSkills.propTypes = TargetSkillsPropTypes;

export default memo(TargetSkills);
