import { gql } from '@apollo/client';
// local imports
import { API_HR_REDEPLOYMENT } from '../config/api';

export const REDEPLOYMENT_PLAN_QUERY = gql`
  query RedeploymentPlan(
    $plan_id: Int!
  ) {
    redeploymentPlan(
      plan_id: $plan_id
    )
    @rest(
      type: "RedeploymentPlan",
      path: "${API_HR_REDEPLOYMENT}"
    ) {
      id
      title
      created_at
      updated_at
      search_criteria {
        manager_id
        country_id
        state_id
        # job_levels
      }
      job {
        id
        title
        code
      }
      # owner { ... } - avaliable, but not needed
    }
  }
`;
