import { AdminJobsSort } from '../graphql/types';

// Job Index Sorting

export const JOB_SORT_TITLE = 'title' as const;
export const JOB_SORT_MATCH_RATE = 'match_rate' as const;
export const JOB_SORT_OPEN_REQS = 'open_reqs' as const;
export const JOB_SORT_LEVEL = 'level' as const;
export const JOB_SORT_LOCATION = 'location' as const;

const JOB_SORTS = [JOB_SORT_TITLE, JOB_SORT_MATCH_RATE, JOB_SORT_OPEN_REQS, JOB_SORT_LEVEL, JOB_SORT_LOCATION] as const;

export type JobSort = typeof JOB_SORTS[number];
export type JobSortExt = JobSort | keyof typeof AdminJobsSort;

export const JOB_SORT_ASC = 'asc' as const;
export const JOB_SORT_DESC = 'desc' as const;
export const JOB_SORT_ASCENDING = 'ascending' as const;
export const JOB_SORT_DESCENDING = 'descending' as const;

const JOB_SORT_DIRS = [JOB_SORT_ASC, JOB_SORT_DESC] as const;
const JOB_SORT_DIRECTIONS = [JOB_SORT_ASCENDING, JOB_SORT_DESCENDING] as const;

export type JobSortDir = typeof JOB_SORT_DIRS[number];
export type JobSortDirections = typeof JOB_SORT_DIRECTIONS[number];

export const DEFAULT_SORT_DIRECTION: Record<string, boolean> = {
  [JOB_SORT_TITLE]: true,
  [JOB_SORT_MATCH_RATE]: false,
  [JOB_SORT_OPEN_REQS]: false,
  [JOB_SORT_LEVEL]: false,
  [JOB_SORT_LOCATION]: true
};

const VALID_SORT: Record<string, boolean> = {
  [JOB_SORT_TITLE]: true,
  [JOB_SORT_MATCH_RATE]: true,
  [JOB_SORT_OPEN_REQS]: true,
  [JOB_SORT_LEVEL]: true
};

const VALID_DIRECTIONS: Record<string, boolean> = {
  [JOB_SORT_ASCENDING]: true,
  [JOB_SORT_DESCENDING]: true
};

export const isValidSortBy = (sort?: string) => Boolean(sort && VALID_SORT[sort]);
export const isValidSortDirection = (direction: string) => Boolean(VALID_DIRECTIONS[direction]);
