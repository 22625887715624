import { type TruncateOptions } from 'lodash';
import { type SnackbarOrigin } from '@mui/material/Snackbar';

// When user types in lookup-select input, only call API at most once per every specified number of milliseconds
export const API_CALL_LOOKUP_WAIT = 500 as const; // milliseconds

export const SNACKBAR_AUTOHIDE_DURATION = 6000 as const; // milliseconds
export const SNACKBAR_ELEVATION = 5 as const; // See: https://material.io/design/environment/elevation.html#default-elevations

export const DEFAULT_QUARTER_FORMAT = 'qQyyyy';

// Pagination
export const PAGE_SIZES = [12, 24, 36] as const;
export const MIN_PAGE_SIZE = 12 as const;
export const MAX_PAGE_SIZE = 36 as const;
export const MAX_JUMP_PAGE_COUNT = 256 as const;

// Titles Truncation
export const MAX_DISPLAYED_TITLE_LENGTH = 36 as const;
export const TITLE_TRUNCATE_OPTIONS: TruncateOptions = {
  length: MAX_DISPLAYED_TITLE_LENGTH,
  separator: /\s+/u,
  omission: '…'
} as const;

// Snackbar
export const anchorOrigin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'center' };

// ECharts
export const GlobalEChartsStyles = {
  textStyle: {
    fontFamily: 'Roboto'
  }
} as const;
