import { Skill } from './skill';
import { Job } from './job';

export const MY_SKILLS_COUNT_KEYS = ['new', 'level1', 'level2', 'level3', 'level4'] as const;
type MySkillsCountKey = typeof MY_SKILLS_COUNT_KEYS[number];

export interface MySkillsCounts extends Record<MySkillsCountKey, number> {
  new: number;
  level1: number;
  level2: number;
  level3: number;
  level4: number;
  total: number;
}

export type BoardSkills = MySkillsCounts;

export interface BoardSuggested {
  total: number;
  new: number;
  top: Skill[];
}
export interface BoardJobs {
  total: number;
  new_open_reqs: number;
  new_close_match: number;
  top: Job[];
}
