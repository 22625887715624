import { forwardRef, memo, type Ref, type MouseEventHandler } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
// Material Icon imports
import AddBoxIcon from '@mui/icons-material/AddBox';
// EmPath UI Components
import { type Values } from '@empathco/ui-components/src/helpers/intl';
// local imports
import Groups from '../icons/Groups';
import GroupsTrendUp from '../icons/GroupsTrendUp';
import { RouterLinkState } from '../helpers/routerParams';
// SCSS imports
import { button, textButton } from './LargeButton.module.scss';

type LargeButtonVariant = 'cohort' | 'devplan' | 'plus';

type LargeButtonProps = {
  title: string;
  values?: Values;
  variant?: LargeButtonVariant;
  link?: string;
  linkState?: RouterLinkState;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean | null;
  pending?: boolean | null;
};

const LargeButtonPropTypes = {
  title: PropTypes.string.isRequired,
  values: PropTypes.object as Validator<Values>,
  variant: PropTypes.oneOf(['cohort', 'devplan', 'plus']) as Validator<LargeButtonVariant>,
  link: PropTypes.string,
  linkState: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  pending: PropTypes.bool
};

const LargeButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, LargeButtonProps>(({
  title,
  values,
  variant,
  link,
  linkState,
  onClick,
  disabled = false,
  pending = false
}, ref) => {
  const startIcon = pending ? <CircularProgress size="inherit" color="inherit"/>
    : (variant && (
      (variant === 'cohort' && <Groups color={disabled ? 'inherit' : 'primary'}/>) ||
      (variant === 'plus' && <AddBoxIcon color={disabled ? 'inherit' : 'primary'}/>) || (
        <GroupsTrendUp color={disabled ? 'inherit' : 'primary'}/>
      ))) || undefined;

  return link ? (
    <Button
        ref={ref as Ref<HTMLAnchorElement>}
        color="info"
        variant="outlined"
        component={RouterLink}
        to={link}
        state={linkState}
        startIcon={startIcon}
        disabled={disabled || pending ? true : undefined}
        className={variant || pending ? button : textButton}
    >
      <FormattedMessage id={title} values={values} defaultMessage={title}/>
    </Button>
  ) : (
    <Button
        ref={ref as Ref<HTMLButtonElement>}
        color="info"
        variant="outlined"
        onClick={onClick}
        startIcon={startIcon}
        disabled={disabled || pending ? true : undefined}
        className={variant || pending ? button : textButton}
    >
      <FormattedMessage id={title} values={values} defaultMessage={title}/>
    </Button>
  );
});

LargeButton.displayName = 'LargeButton';

LargeButton.propTypes = LargeButtonPropTypes;

export default memo(LargeButton);
