import { gql } from '@apollo/client';
// local imports
import { API_MY_SEARCH } from '../config/api';

export const MY_SEARCH_QUERY = gql`
  query MySearch(
    $search: String
    $offset: Int
    $limit: Int
  ) {
    mySearch(
      search: $search,
      offset: $offset,
      limit: $limit
    )
    @rest(
      type: "MySearchPayload",
      path: "${API_MY_SEARCH}?{args}"
    ) {
      count
      results {
        id @client
        title
        item_type
        item_id
        item_code
      }
    }
  }
`;
