import { forwardRef, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Button from '@mui/material/Button';
// local imports
import { injectParams } from '../helpers/path';
import CardTitle from './CardTitle';
import TargetIcon from './TargetIcon';
import SkillTrendIcon from './SkillTrendIcon';
// SCSS imports
import { targetIcon, detailsButton } from './SkillLevelDialogTitle.module.scss';

type SkillLevelDialogTitleProps = {
  route?: string | null;
  abbr?: string | null;
  title?: string | null;
  isTarget?: boolean | null;
  isInDemand?: boolean | null;
  reducedUI?: boolean | null;
  disabled?: boolean | null;
  plain?: boolean | null;
}

const SkillLevelDialogTitlePropTypes = {
  // attributes
  route: PropTypes.string,
  abbr: PropTypes.string,
  title: PropTypes.string,
  isTarget: PropTypes.bool,
  isInDemand: PropTypes.bool,
  reducedUI: PropTypes.bool,
  disabled: PropTypes.bool,
  plain: PropTypes.bool
};

const SkillLevelDialogTitle = forwardRef<HTMLElement, SkillLevelDialogTitleProps>(({
  route,
  abbr,
  title,
  isTarget = false,
  isInDemand = false,
  reducedUI = false,
  disabled = false,
  plain = false
}, ref) => {
  const link = useMemo(() => route && abbr ? injectParams(route, { skill_id: abbr }) : '', [route, abbr]);
  return (
    <CardTitle
        ref={ref}
        wrap
        title={title || 'skill.default_skill_title'}
        subheader={!reducedUI && (
          <>
            <TargetIcon
                active={isTarget}
                tooltip={isTarget && !disabled ? 'skill.target_this' : undefined}
                tooltipPlacement="top"
                className={targetIcon}
            />
            <SkillTrendIcon active={isInDemand}/>
          </>
        )}
        action={!plain && (
          <Button
              color="primary"
              variant="outlined"
              component={RouterLink}
              to={link}
              disabled={disabled || !link}
              className={detailsButton}
          >
            <FormattedMessage id="skill.view_details"/>
          </Button>
        )}
        compact={!plain}
    />
  );
});

SkillLevelDialogTitle.displayName = 'SkillLevelDialogTitle';

SkillLevelDialogTitle.propTypes = SkillLevelDialogTitlePropTypes;

export default memo(SkillLevelDialogTitle);
