import {
  TourScreen, TourScreenshot,
  TOUR_TAB_DASHBOARD, TOUR_TAB_SKILLS, TOUR_TAB_JOBS
} from '../constants/tour';

const customerFolder = window.CUSTOMER ? `/customers/${window.CUSTOMER}/` : '/customers/';

export const TOUR_SCREENSHOTS: Record<TourScreenshot, string | undefined> = {
  1: `${customerFolder}_tour/tour01.png`,
  2: `${customerFolder}_tour/tour02.png`,
  3: `${customerFolder}_tour/tour03.png`,
  4: `${customerFolder}_tour/tour04.png`,
  5: `${customerFolder}_tour/tour05.png`,
  6: `${customerFolder}_tour/tour06.png`,
  7: `${customerFolder}_tour/tour07.png`,
  8: `${customerFolder}_tour/tour08.png`,
  9: `${customerFolder}_tour/tour09.png`,
  10: `${customerFolder}_tour/tour10.png`,
  11: `${customerFolder}_tour/tour11.png`,
  12: `${customerFolder}_tour/tour12.png`,
  13: `${customerFolder}_tour/tour13.png`,
  14: `${customerFolder}_tour/tour14.png`,
  15: `${customerFolder}_tour/tour15.png`,
  16: `${customerFolder}_tour/tour16.png`,
  17: `${customerFolder}_tour/tour17.png`
};

export const TOUR_SCREENS: TourScreen[] = [
  {
    // 01
    screenshot: 1,
    tab: TOUR_TAB_DASHBOARD,
    placement: 'right',
    x0: 270,
    y0: 28,
    width: 336
  },
  {
    // 02
    screenshot: 1,
    tab: TOUR_TAB_DASHBOARD,
    placement: 'right',
    x0: 395,
    y0: 200,
    width: 332
  },
  {
    // 03
    screenshot: 1,
    tab: TOUR_TAB_DASHBOARD,
    placement: 'right',
    x0: 775,
    y0: 198,
    width: 336
  },
  {
    // 04
    screenshot: 1,
    tab: TOUR_TAB_DASHBOARD,
    placement: 'left',
    x0: 820,
    y0: 190,
    width: 336
  },
  {
    // 05
    screenshot: 1,
    tab: TOUR_TAB_DASHBOARD,
    placement: 'left',
    x0: 896,
    y0: 28,
    width: 340
  },
  {
    // 06
    screenshot: 1,
    tab: TOUR_TAB_DASHBOARD,
    placement: 'left',
    x0: 1104,
    y0: 28,
    width: 342
  },
  {
    // 07
    screenshot: 1,
    tab: TOUR_TAB_DASHBOARD,
    placement: 'left',
    x0: 1158,
    y0: 28,
    width: 342
  },
  {
    // 08
    screenshot: 2,
    tab: TOUR_TAB_DASHBOARD,
    placement: 'right',
    x0: 96,
    y0: 26,
    width: 336
  },
  {
    // 09
    screenshot: 3,
    tab: TOUR_TAB_SKILLS,
    placement: 'right',
    x0: 336,
    y0: 116,
    width: 336
  },
  {
    // 10
    screenshot: 3,
    tab: TOUR_TAB_SKILLS,
    placement: 'right',
    x0: 286,
    y0: 186,
    width: 336
  },
  {
    // 11
    screenshot: 3,
    tab: TOUR_TAB_SKILLS,
    placement: 'right',
    x0: 588,
    y0: 186,
    width: 336
  },
  {
    // 12
    screenshot: 4,
    tab: TOUR_TAB_SKILLS,
    placement: 'right',
    x0: 512,
    y0: 116,
    width: 336
  },
  {
    // 13
    screenshot: 5,
    tab: TOUR_TAB_SKILLS,
    placement: 'right',
    x0: 404,
    y0: 46,
    width: 336
  },
  {
    // 14
    screenshot: 5,
    tab: TOUR_TAB_SKILLS,
    placement: 'left',
    x0: 1080,
    y0: 46,
    width: 340
  },
  {
    // 15
    screenshot: 5,
    tab: TOUR_TAB_SKILLS,
    placement: 'right',
    x0: 496,
    y0: 248,
    width: 554
  },
  {
    // 16
    screenshot: 6,
    tab: TOUR_TAB_SKILLS,
    placement: 'right',
    x0: 432,
    y0: 252,
    width: 342
  },
  {
    // 17
    screenshot: 6,
    tab: TOUR_TAB_SKILLS,
    placement: 'left',
    x0: 816,
    y0: 90,
    width: 342
  },
  {
    // 18
    screenshot: 7,
    tab: TOUR_TAB_SKILLS,
    placement: 'left',
    x0: 980,
    y0: 123,
    width: 340
  },
  {
    // 19
    screenshot: 8,
    tab: TOUR_TAB_SKILLS,
    placement: 'right',
    x0: 920,
    y0: 292,
    width: 248
  },
  {
    // 20
    screenshot: 8,
    tab: TOUR_TAB_SKILLS,
    placement: 'top',
    x0: 1080,
    y0: 464,
    width: 512
  },
  {
    // 21
    screenshot: 9,
    tab: TOUR_TAB_SKILLS,
    placement: 'right',
    x0: 196,
    y0: 62,
    width: 336
  },
  {
    // 22
    screenshot: 10,
    tab: TOUR_TAB_SKILLS,
    placement: 'right',
    x0: 130,
    y0: 50,
    width: 336
  },
  {
    // 23
    screenshot: 11,
    tab: TOUR_TAB_JOBS,
    placement: 'bottom',
    x0: 606,
    y0: 184,
    width: 336
  },
  {
    // 24
    screenshot: 11,
    tab: TOUR_TAB_JOBS,
    placement: 'right',
    x0: 308,
    y0: 276,
    width: 336
  },
  {
    // 25
    screenshot: 12,
    tab: TOUR_TAB_JOBS,
    placement: 'left',
    x0: 1142,
    y0: 123,
    width: 336
  },
  {
    // 26
    screenshot: 12,
    tab: TOUR_TAB_JOBS,
    placement: 'right',
    x0: 318,
    y0: 324,
    width: 336
  },
  {
    // 27
    screenshot: 13,
    tab: TOUR_TAB_JOBS,
    placement: 'right',
    x0: 344,
    y0: 124,
    width: 336
  },
  {
    // 28
    screenshot: 13,
    tab: TOUR_TAB_JOBS,
    placement: 'left',
    x0: 984,
    y0: 123,
    width: 336
  },
  {
    // 29
    screenshot: 14,
    tab: TOUR_TAB_JOBS,
    placement: 'right',
    x0: 256,
    y0: 238,
    width: 336
  },
  {
    // 30
    screenshot: 15,
    tab: TOUR_TAB_JOBS,
    placement: 'right',
    x0: 264,
    y0: 192,
    width: 482
  },
  {
    // 31
    screenshot: 16,
    tab: TOUR_TAB_JOBS,
    placement: 'right',
    x0: 520,
    y0: 204,
    width: 482
  },
  {
    // 32
    screenshot: 17,
    placement: 'right',
    x0: 496,
    y0: 118,
    width: 482
  }
];
