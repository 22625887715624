import { gql } from '@apollo/client';
// local imports
import { API_MY_OPPORTUNITY } from '../config/api';

export const UPDATE_MY_OPPORTUNITY = gql`
  mutation UpdateMyOpportunity(
    $opportunity_id: Int!
    $input: UpdateMyOpportunityInput!
  ) {
    updateMyOpportunity(
      opportunity_id: $opportunity_id,
      input: $input
    )
    @rest(
      type: "MutationResponse",
      path: "${API_MY_OPPORTUNITY}",
      method: "PATCH"
    ) {
      success
      optimistic
    }
  }
`;
