import { gql } from '@apollo/client';
// local imports
import { API_DEV_PLAN } from '../config/api';

export const DELETE_DEV_PLAN = gql`
  mutation DeleteDevPlan(
    $devplan_id: Int!
  ) {
    deleteDevPlan(
      devplan_id: $devplan_id
    )
    @rest(
      type: "MutationResponse",
      path: "${API_DEV_PLAN}",
      method: "DELETE"
    ) {
      success # just a placeholder; this request has empty response (status 204 only)
    }
  }
`;
