import { useMemo, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

export type MoveItemFunction = (id: number, index: number | null) => number[];

function useDragAndDropItem<T extends string>(
  id: number,
  accept: T,
  moveItem?: MoveItemFunction,
  disabled?: boolean | null
) {
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept,
    canDrop: () => false
  });

  const [{ isDragging }, drag] = useDrag({
    item: () => ({ id }),
    type: accept,
    canDrag: () => !disabled,
    collect: (monitor) => ({
      isDragging: Boolean(monitor.isDragging())
    }),
    end: ({ id: droppedId }, monitor) => {
      // console.log(`Drag.end: id=${droppedId} didDrop=${monitor.didDrop()}`);
      if (!monitor.didDrop()) moveItem?.(droppedId, -1);
    }
  });

  drag(drop(ref));

  return useMemo(() => ({ ref, isDragging }), [isDragging]);
}

export default useDragAndDropItem;
