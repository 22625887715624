import { forwardRef, memo, useCallback } from 'react';
import PropTypes, { type Validator } from 'prop-types';
// Material UI imports
import Box from '@mui/material/Box';
// EmPath UI Components
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import TruncatedTextLink from '@empathco/ui-components/src/elements/TruncatedTextLink';
import ItemCard from '@empathco/ui-components/src/elements/ItemCard';
import CheckboxButton from '@empathco/ui-components/src/elements/CheckboxButton';
// local imports
import { Skill } from '../models/skill';
import { PATH_SKILL } from '../config/paths';

type CheckedSkillCardProps = {
  checked?: boolean;
  skill: Skill;
  onChange?: (id: number) => void;
  disabled?: boolean;
}

const CheckedSkillCardPropTypes = {
  // attributes
  checked: PropTypes.bool,
  skill: PropTypes.object.isRequired as Validator<Skill>,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

const CheckedSkillCard = forwardRef<HTMLDivElement, CheckedSkillCardProps>(({
  checked = false,
  skill,
  onChange,
  disabled = false
}, ref) => {
  const { id, abbr, title } = skill || {};

  const handleChange = useCallback(() => {
    if (onChange) onChange(id);
  }, [id, onChange]);

  return (
    <ItemCard
        ref={ref}
        flat
        bottomPadding
        display="flex"
        alignItems="center"
    >
      <CheckboxButton
          label={title}
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
      />
      <Box
          pl={1.5}
          flexGrow={1}
          flexBasis={0}
          display="flex"
          alignItems="center"
      >
        <TruncatedTextLink variant="subtitle2" to={injectParams(PATH_SKILL, { skill_id: abbr })} text={title}/>
      </Box>
    </ItemCard>
  );
});

CheckedSkillCard.displayName = 'CheckedSkillCard';

CheckedSkillCard.propTypes = CheckedSkillCardPropTypes;

export default memo(CheckedSkillCard);
