import findIndex from 'lodash/findIndex';
import uniq from 'lodash/uniq';
import keys from 'lodash/keys';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import isSafeInteger from 'lodash/isSafeInteger';
import toLower from 'lodash/toLower';
import toString from 'lodash/toString';

export type QueryVariables = Record<string, unknown>;

export const paramsDiffer = <P = QueryVariables>(
  params: P | null,
  newParams: P
): boolean => !params || findIndex(uniq([...keys(params), ...keys(newParams)]),
  // eslint-disable-next-line complexity
  (id) => !(
    id === 'offset' ||
    (params as QueryVariables)[id] === (newParams as QueryVariables)[id] ||
    (isNil((params as QueryVariables)[id]) && isNil((newParams as QueryVariables)[id])) ||
    ((params as QueryVariables)[id] === false && Boolean((newParams as QueryVariables)[id]) === false) ||
    (Boolean((params as QueryVariables)[id]) === false && (newParams as QueryVariables)[id] === false) ||
    (isSafeInteger((params as QueryVariables)[id]) && (params as QueryVariables)[id] as number < 1 && (
      !isSafeInteger((newParams as QueryVariables)[id]) ||
      (isSafeInteger((newParams as QueryVariables)[id]) && (newParams as QueryVariables)[id] as number < 1))
    ) ||
    (isSafeInteger((newParams as QueryVariables)[id]) && (newParams as QueryVariables)[id] as number < 1 && (
      !isSafeInteger((params as QueryVariables)[id]) ||
      (isSafeInteger((params as QueryVariables)[id]) && (params as QueryVariables)[id] as number < 1))
    ) ||
    (isString((newParams as QueryVariables)[id]) && isString((params as QueryVariables)[id]) &&
      toLower(toString((newParams as QueryVariables)[id])) === toLower(toString((params as QueryVariables)[id]))
    )
  )
) >= 0;
