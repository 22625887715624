import { gql } from '@apollo/client';
// local imports
import { API_COURSE_PROVIDERS } from '../config/api';

export const COURSE_PROVIDERS_QUERY = gql`
  query CourseProviders(
    $limit: Int
  ) {
    courseProviders(
      limit: $limit
    )
    @rest(
      type: "CourseProvidersPayload",
      path: "${API_COURSE_PROVIDERS}?{args}"
    ) {
      count
      results {
        id
        title
        code
      }
    }
  }
`;
