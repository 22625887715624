import { gql } from '@apollo/client';
// local imports
import { API_SUCCESS_STORIES } from '../config/api';

export const SUCCESS_STORIES_QUERY = gql`
  query SuccessStories(
    $limit: Int
  ) {
    successStories(
      limit: $limit
    )
    @rest(
      type: "SuccessStoriesPayload",
      path: "${API_SUCCESS_STORIES}?{args}"
    ) {
      count
      results {
        id
        photo
        name
        title
        story
        link
      }
    }
  }
`;
