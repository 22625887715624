import { type MouseEventHandler, type ReactNode } from 'react';
// Material UI imports
import { type Variant } from '@mui/material/styles/createTypography';
import Typography from '@mui/material/Typography';
// local imports
import { mapChunks } from './intl';
import StandardLink from '../elements/StandardLink';

export const getParagraph = () => {
  const Paragraph = (chunks?: ReactNode | ReactNode[] | null): ReactNode => (
    <Typography variant="inherit" paragraph>
      {mapChunks(chunks)}
    </Typography>
  );
  return Paragraph;
};

export const getStandardLink = ({
  variant,
  href,
  onClick
}: {
  variant?: Variant | 'inherit';
  href?: string | null;
  onClick?: MouseEventHandler<HTMLAnchorElement> & MouseEventHandler<HTMLSpanElement>;
}) => {
  const StandardLinkTag = (chunks?: ReactNode | ReactNode[] | null): ReactNode => (
    <StandardLink onClick={onClick} href={href} variant={variant}>
      {mapChunks(chunks)}
    </StandardLink>
  );
  return StandardLinkTag;
};
