import { gql } from '@apollo/client';
// local imports
import { API_MY_ACTIVITIES } from '../config/api';

export const SELECT_ACTIVITY = gql`
  mutation SelectActivity(
    $input: SelectActivityInput!
  ) {
    selectActivity(
      input: $input
    )
    @rest(
      type: "EmployeeActivity",
      path: "${API_MY_ACTIVITIES}",
      method: "POST"
    )
    {
      id
    }
  }
`;
