import { gql } from '@apollo/client';
// local imports
import { API_DEV_PLAN_COURSE } from '../config/api';

export const DEV_PLAN_COURSE_QUERY = gql`
  query DevPlanCourseQuery(
    $devplan_id: Int!
    $course_id: Int!
  ) {
    devplanCourse(
      devplan_id: $devplan_id,
      course_id: $course_id
    )
    @rest(
      type: "Course",
      path: "${API_DEV_PLAN_COURSE}"
    ) {
      id
      title
      code
      abbr
      kind
      level
      rating
      link
      level_growth_in_quarters
      external_url
      duration_value
      duration_unit
      certification_program
      video_url
      credit_hours
      is_self_placed
      description
      preferred_rating
      is_selected # local field used in DevPlanEditor
      provider {
        id
        title
        code
      }
      covered_skills {
        id
        title
        abbr
        current_level
      }
    }
  }
`;
