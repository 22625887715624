import { memo, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
// EmPath UI Components
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
// local imports
import { Skill } from '../models/skill';
import SelectableSkillCard from '../v3/SelectableSkillCard';
// SCSS imports
import { root } from './SelectableSkills.module.scss';

type SelectableSkillsProps = {
  skills?: Skill[] | null;
  pending?: boolean | null;
  failed?: boolean | null;
  onClick?: (skill: Skill) => void;
  disabled?: boolean | null;
  notFoundMessage?: string | null;
}

const SelectableSkillsPropTypes = {
  // attributes
  skills: PropTypes.array,
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  notFoundMessage: PropTypes.string
};

const SelectableSkills: FunctionComponent<SelectableSkillsProps> = ({
  skills,
  pending = false,
  failed = false,
  disabled = false,
  onClick,
  notFoundMessage
}) =>
  ((failed || pending || size(skills) < 1) && (
    <Box pb={4}>
      {(failed && <FetchFailedAlert flat/>) ||
      (pending && <LoadingPlaceholder flat/>) || (
        <Alert severity="info" variant="standard">
          <FormattedMessage id={notFoundMessage || 'skills.no_skills_found'}/>
        </Alert>
      )}
    </Box>
  )) || (
    <Box
        px={3}
        py={4}
        display="flex"
        flexWrap="wrap"
        alignContent="flex-start"
        className={root}
    >
      {map(skills, (skill) => (
        <Box
            key={skill.id}
            pr={3.5}
            pb={4}
        >
          <SelectableSkillCard
              skill={skill}
              disabled={disabled}
              onClick={onClick}
          />
        </Box>
      ))}
    </Box>
  );

SelectableSkills.propTypes = SelectableSkillsPropTypes;

export default memo(SelectableSkills);
