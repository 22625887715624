import { forwardRef, memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import toFinite from 'lodash/toFinite';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// EmPath UI Components
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import SimpleTooltip from '@empathco/ui-components/src/elements/SimpleTooltip';

type CourseHoursProps = {
  hours?: number | null;
  courses?: string[] | null;
  isOpen?: boolean | null;
};

const CourseHoursPropTypes = {
  hours: PropTypes.number,
  courses: PropTypes.arrayOf(PropTypes.string.isRequired),
  isOpen: PropTypes.bool
};

const CourseHours = forwardRef<HTMLElement, CourseHoursProps>(({
  hours,
  courses,
  isOpen = false
}, ref) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isOpen) setOpen(isOpen);
  }, [isOpen]);

  const handleTooltipClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleTooltipOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const text = (
    <BoxTypography ref={ref} textAlign="right" color="text.primary" variant="body1">
      <FormattedMessage id="common.course_hours" values={{ hours: toFinite(hours) }}/>
    </BoxTypography>
  );

  const renderCourse = useCallback((course: string, index: number) => (
    <Box key={index} component="li" py={0.5}>
      {course}
    </Box>
  ), []);

  const count = size(courses);

  return count >= 1 ? (
    <SimpleTooltip
        open={open}
        onOpen={handleTooltipOpen}
        onClose={handleTooltipClose}
        disableInteractive
        disableFocusListener
        placement="left"
        title={(
          <>
            <Typography variant="h5">
              <FormattedMessage id="skill.course_titles" values={{ count }}/>
            </Typography>
            <Box component="ul" pl={2} m={0}>
              {map(courses, renderCourse)}
            </Box>
          </>
        )}
    >
      {text}
    </SimpleTooltip>
  ) : text;
});

CourseHours.displayName = 'CourseHours';

CourseHours.propTypes = CourseHoursPropTypes;

export default memo(CourseHours);
