import { forwardRef, memo, useMemo, type ReactNode } from 'react';
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import { useIntl } from 'react-intl';
// Material UI imports
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// Material Icon imports
import TextIcon from 'mdi-material-ui/Text';
// local imports
import { BreadcrumbItem } from '../types';
import AppBarBrand from '../elements/AppBarBrand';
import Breadcrumbs from '../elements/Breadcrumbs';
// SCSS imports
import { toolbar, emptyToolbar } from './AppBar.module.scss';

export const APPBAR_NONE = 'none' as const;
export const APPBAR_EMPTY = 'empty' as const;
export const APPBAR_FULL = 'full' as const;

const APPBAR_VARIANTS = [APPBAR_NONE, APPBAR_EMPTY, APPBAR_FULL] as const;
export type AppBarVariant = typeof APPBAR_VARIANTS[number];
export const AppBarVariantProp = PropTypes.oneOf(APPBAR_VARIANTS);

type AppBarProps = {
  children?: ReactNode | ReactNode[];
  tickers?: ReactNode | ReactNode[];
  path?: string;
  breadcrumbs?: BreadcrumbItem[];
  drawerOpen?: boolean;
  toggleDrawer: () => void;
  variant?: AppBarVariant;
  logo: ReactNode;
};

const AppBarPropTypes = {
  // React built-in
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  // attributes
  tickers: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  path: PropTypes.string,
  breadcrumbs: PropTypes.array,
  drawerOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func.isRequired,
  variant: AppBarVariantProp,
  logo: PropTypes.node.isRequired
};

const AppBar = forwardRef<HTMLDivElement, AppBarProps>(({
  children,
  tickers,
  path,
  breadcrumbs,
  drawerOpen = false,
  toggleDrawer,
  variant = 'full',
  logo
}, ref) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();
  const label = useMemo(
    () => formatMessage({ id: drawerOpen ? 'header.close_drawer' : 'header.open_drawer' }),
    [formatMessage, drawerOpen]
  );

  return (
    <MuiAppBar
        ref={ref}
        color="inherit"
        elevation={3}
        position="fixed"
    >
      <Toolbar className={variant === APPBAR_EMPTY ? emptyToolbar : toolbar}>
        {(variant === APPBAR_EMPTY && (
          <>
            <AppBarBrand logo={logo}/>
            <Breadcrumbs breadcrumbs={breadcrumbs}/>
            {children}
          </>
        )) || (
          <>
            <IconButton aria-label={label} onClick={toggleDrawer} color="primary" size="large">
              <TextIcon fontSize="large"/>
            </IconButton>
            <AppBarBrand link={path} logo={logo}/>
            <Breadcrumbs breadcrumbs={breadcrumbs}/>
            {children}
          </>
        )}
      </Toolbar>
      {tickers}
    </MuiAppBar>
  );
});

AppBar.displayName = 'AppBar';

AppBar.propTypes = AppBarPropTypes;

export default memo(AppBar);
