import { forwardRef, memo } from 'react';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
// local imports
import BoxTypography from '../mixins/BoxTypography';
// SCSS imports
import { current, inferred } from './SkillLevelGauge.module.scss';
import { box } from './SkillsLegend.module.scss';

const currentBox = `${current} ${box}`;
const inferredBox = `${inferred} ${box}`;

const SkillsLegend = forwardRef<HTMLElement, {}>((_props, ref) => (
  <BoxTypography
      ref={ref}
      flexGrow={1}
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      flexWrap="wrap"
      variant="body1"
  >
    <Box pl={2} display="flex" alignItems="center" color="text.label" fontStyle="italic" component="span">
      <Box mr={1.5} className={inferredBox} component="span"/>
      <FormattedMessage id="common.skills.inferred"/>
    </Box>
    <Box pl={4} display="flex" alignItems="center" color="text.label" fontStyle="italic" component="span">
      <Box mr={1.5} className={currentBox} component="span"/>
      <FormattedMessage id="common.skills.other"/>
    </Box>
  </BoxTypography>
));

SkillsLegend.displayName = 'SkillsLegend';

export default memo(SkillsLegend);
