import { forwardRef } from 'react';
import { type BoxProps, type SystemProps } from '@mui/system/Box';
import Box from '@mui/material/Box';
import Typography, { type TypographyProps } from '@mui/material/Typography';

const BoxTypography = forwardRef<HTMLElement,
  Omit<TypographyProps, 'classes' | 'component' | 'display' | 'displayPrint'> &
  Omit<SystemProps, 'component'> &
  Omit<BoxProps, 'component'>
>((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Box ref={ref} component={Typography} {...props}/>
));

BoxTypography.displayName = 'BoxTypography';

export default BoxTypography;
