// used for `skill_development_resources` (see `src/customers/*/settings.json`)
export const CUSTOMER_IMAGES: string[] = [];

export const devplanIntro = 0;
export const devplanNote = 1;
export const devplanInDemandIntro = 2;
export const devplanInDemandNote = 3;
export const devplanConclusion = 4;
export const devplanJobSkills = 5;
export const devplanInDemandSkills = 6;

const customerFolder = window.CUSTOMER ? `/customers/${window.CUSTOMER}/` : '/customers/';

export const CUSTOMER_FILES: (string | undefined)[] = [
  // devplanIntro = 0
  `${customerFolder}files/devplan_1_intro.html`,
  // devplanNote = 1
  `${customerFolder}files/devplan_1_note.html`,
  // devplanInDemandIntro = 2
  `${customerFolder}files/devplan_2_indemand.html`,
  // devplanInDemandNote = 3
  `${customerFolder}files/devplan_2_indemand_note.html`,
  // devplanConclusion
  `${customerFolder}files/devplan_3_conclusion.html`,
  // devplanJobSkills
  `${customerFolder}files/devplan_4_job_skills.html`,
  // devplanInDemandSkills
  `${customerFolder}files/devplan_5_indemand_skills.html`
];
