import { memo, useContext, useEffect, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
// local imports
import { DataContext } from '../context';
import SkillsGrid from '../v3/SkillsGrid';

type LearningProgressProps = {
  supervisor?: boolean;
  uid?: string | null;
  duration?: number;
}

const LearningProgressPropTypes = {
  supervisor: PropTypes.bool,
  uid: PropTypes.string,
  duration: PropTypes.number
};

const LearningProgress: FunctionComponent<LearningProgressProps> = ({
  supervisor = false,
  uid,
  duration
}) => {
  const {
    learningProgress: { data: skills, pending, failed }, requireLearningProgress
  } = useContext(DataContext);

  useEffect(() => {
    requireLearningProgress?.({ duration });
  }, [uid, requireLearningProgress, duration]);

  return (
    <SkillsGrid
        source="learning"
        isEmployee={!supervisor}
        readOnly={supervisor}
        supervisor={supervisor}
        notFoundMessage="skills.no_learning_progress"
        withCourses
        skills={skills}
        pending={pending}
        failed={failed}
    />
  );
};

LearningProgress.propTypes = LearningProgressPropTypes;

export default memo(LearningProgress);
