import { forwardRef, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
// Material UI imports
import Button from '@mui/material/Button';
// SCSS imports
import { button, outlined } from './ScopeSelectorButton.module.scss';

const COLORS = ['inherit', 'primary', 'secondary', 'info'] as const;

type ScopeSelectorButtonProps = {
  className?: string;
  color?: typeof COLORS[number];
  simple?: boolean;
  item: string;
  selected: boolean;
  disabled?: boolean;
  onChange: (item: string) => void;
};

const ScopeSelectorButtonPropTypes = {
  // attributes
  className: PropTypes.string,
  color: PropTypes.oneOf(COLORS),
  simple: PropTypes.bool,
  item: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

const ScopeSelectorButton = forwardRef<HTMLButtonElement, ScopeSelectorButtonProps>(({
  simple = false,
  item,
  selected,
  disabled,
  onChange,
  className,
  color,
  ...props
}, ref) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();
  const label = formatMessage({ id: `scopeselector.${item}` });

  const handleClick = useCallback(() => onChange(item), [item, onChange]);

  return (
    <Button
        ref={ref}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        aria-label={label}
        aria-pressed={selected}
        color={selected ? (simple && 'secondary') || color || 'primary' : 'info'}
        variant={selected ? 'contained' : 'outlined'}
        disabled={disabled}
        disableElevation
        onClick={handleClick}
        className={clsx(className, button, {
          [outlined]: !selected
        })}
    >
      {label}
    </Button>
  );
});

ScopeSelectorButton.displayName = 'ScopeSelectorButton';

ScopeSelectorButton.propTypes = ScopeSelectorButtonPropTypes;

export default memo(ScopeSelectorButton);
