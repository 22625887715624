import { forwardRef, memo, useCallback, type ReactNode, type Component } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import { visuallyHidden } from '@mui/utils';
import Box, { type BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress, { type CircularProgressProps } from '@mui/material/CircularProgress';
// local imports
import { mapChunks } from '../helpers/intl';
// SCSS imports
import { outer, flipped, plannedFlipped, mirrored, planned, reversed, blank, percentage } from './MatchIndicator.module.scss';

const THICKNESS = 4.5;
const SIZE = '4.1rem';

const rootSx = { forcedColorAdjust: 'none' };

export type MatchIndicatorVariant = 'normal' | 'simple' | 'reversed' | 'planned';

type MatchIndicatorProps = {
  value: number;
  label?: string;
  pending?: boolean | null;
  pendingColor?: CircularProgressProps['color'];
  variant?: MatchIndicatorVariant;
  size?: string;
}

const MatchIndicatorPropTypes = {
  // attributes
  value: PropTypes.number.isRequired,
  label: PropTypes.string,
  pending: PropTypes.bool,
  pendingColor: PropTypes.string as Validator<CircularProgressProps['color']>,
  variant: PropTypes.string as Validator<MatchIndicatorVariant>,
  size: PropTypes.string
};

// eslint-disable-next-line complexity
const MatchIndicator = forwardRef<Component<BoxProps>, MatchIndicatorProps>(({
  value,
  label,
  pending = false,
  pendingColor = 'primary',
  variant = 'normal',
  size = SIZE
}, ref) => {

  const bold = useCallback((chunks?: ReactNode | ReactNode[]): ReactNode => (
    <Typography variant={variant === 'reversed' ? 'h4' : 'h5'} component="div" className={percentage}>
      {mapChunks(chunks)}
    </Typography>
  ), [variant]);

  const text = useCallback((chunks?: ReactNode | ReactNode[]): ReactNode => (
    <Typography variant="body2" component="div">
      {mapChunks(chunks)}
    </Typography>
  ), []);

  return (
    <Box
        ref={ref}
        position="relative"
        display="inline-flex"
        color={pending ? undefined : 'text.label'}
        sx={rootSx}
    >
      <CircularProgress
          aria-valuenow={null as unknown as undefined}
          color={pending ? pendingColor : 'inherit'}
          variant={pending ? undefined : 'determinate'}
          size={size}
          thickness={THICKNESS}
          value={pending ? undefined : (variant === 'simple' && 100) || value}
          className={clsx({
            [blank]: variant === 'simple' && !pending,
            [outer]: variant === 'normal' && !pending,
            [mirrored]: variant === 'reversed' && !pending,
            [planned]: variant === 'planned' && !pending
          })}
      />
      {!pending && variant !== 'simple' && value < 100 ? (
        <Box
            data-html2canvas-ignore // TODO: workaround while html2canvas doesn't fully support `transform`
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
          <CircularProgress
              aria-valuenow={null as unknown as undefined}
              color="inherit"
              variant="determinate"
              size={size}
              thickness={THICKNESS}
              value={100 - value}
              className={(variant === 'reversed' && reversed) || (variant === 'planned' && plannedFlipped) || flipped}
          />
        </Box>
      ) : undefined}
      {pending ? (
        <Box sx={visuallyHidden}>
          <FormattedMessage id="common.match_rate_updating"/>
        </Box>
      ) : (
        <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
        >
          <FormattedMessage
              id={label || (variant === 'reversed' ? 'common.match_rate_reversed' : 'common.match_rate_value')}
              values={{
                bold,
                text,
                value: value / 100
            }}
          />
        </Box>
      )}
    </Box>
  );
});

MatchIndicator.displayName = 'MatchIndicator';

MatchIndicator.propTypes = MatchIndicatorPropTypes;

export default memo(MatchIndicator);
