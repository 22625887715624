import { forwardRef, memo, type Component } from 'react';
import PropTypes from 'prop-types';
// Material UI imports
import { makeStyles } from 'tss-react/mui';
import { type SliderValueLabelProps } from '@mui/material/Slider';
import { type Theme } from '@mui/material/styles';
import Tooltip, { type TooltipProps } from '@mui/material/Tooltip';

const popperProps = {
  disablePortal: true,
  keepMounted: true,
  modifiers: [
    { name: 'hide', enabled: false },
    { name: 'preventOverflow', enabled: false },
    { name: 'flip', enabled: false }
  ]
};

const useStyles = makeStyles()((theme: Theme) => ({
  popper: {
    zIndex: 0
  },
  tooltip: {
    marginTop: '2px !important',
    padding: '1px 8px 1px 4px',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    border: 'none',
    color: theme.palette.text.label,
    boxShadow: theme.shadows[0],
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
    lineHeight: theme.typography.body2.lineHeight,
    letterSpacing: theme.typography.body2.letterSpacing
  },
  tooltipPlacementBottom: {
    margin: 0
  }
}));

type PlainValueLabelProps = Omit<TooltipProps & SliderValueLabelProps, 'value'> & {
  value: string | number;
};

const PlainValueLabelPropTypes = {
  // attributes
  open: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ]).isRequired
};

const PlainValueLabel = forwardRef<Component<TooltipProps>, PlainValueLabelProps>(({
  open = false,
  children,
  value
}, ref) => {
  const { classes } = useStyles();
  return (
    <Tooltip
        ref={ref}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        enterDelay={0}
        enterTouchDelay={0}
        placement="bottom"
        title={value}
        PopperProps={popperProps}
        classes={classes}
    >
      {children}
    </Tooltip>
  );
});

PlainValueLabel.displayName = 'PlainValueLabel';

PlainValueLabel.propTypes = PlainValueLabelPropTypes;

export default memo(PlainValueLabel);
