import {
  useState, useCallback, useEffect, useMemo,
  type ReactNode, type ChangeEvent, type ReactElement, type ForwardedRef
} from 'react';
// import PropTypes from 'prop-types';
import trim from 'lodash/trim';
import size from 'lodash/size';
import pick from 'lodash/pick';
import keys from 'lodash/keys';
import filter from 'lodash/filter';
import indexOf from 'lodash/indexOf';
import toLower from 'lodash/toLower';
import isEqual from 'lodash/isEqual';
import isString from 'lodash/isString';
import throttle from 'lodash/throttle';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
// Material UI imports
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Autocomplete, {
  type AutocompleteInputChangeReason, type AutocompleteRenderInputParams, type AutocompleteRenderOptionState
  // type AutocompleteGetTagProps
} from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
// local imports
import { ILookupItem } from '../models/lookupItem';
import { API_CALL_LOOKUP_WAIT } from '../config/params';
import { simpleForwardRef } from '../helpers/react';
import ActionFailedAlert from '../elements/ActionFailedAlert';
// SCSS imports
import { root, rootFullWidth, searching } from '../styles/modules/Lookup.module.scss';

const EMPTY: unknown = [];

const sx = {
  '.MuiAutocomplete-popupIndicatorOpen': {
    transform: 'none'
  }
};

type BaseItemType = Partial<ILookupItem>;

export interface FetchParams {
  search?: string | null;
}

type LookupProps<T, P extends FetchParams> = {
  readonly type: string;
  readonly className?: string;
  readonly fetched?: T[] | null;
  readonly pending?: boolean | null;
  readonly failed?: boolean | null;
  readonly params?: P | null;
  readonly fetch: (params: P) => void;
  readonly fetchParams?: Partial<P>;
  readonly exclude?: number[] | null;
  readonly value?: T | null;
  readonly onChange?: (value: T | null) => void;
  readonly onFreeSoloChange?: (value: string | T | null) => void;
  readonly disabled?: boolean | null;
  readonly fullWidth?: boolean;
  readonly nativeFullWidth?: boolean;
  readonly error?: boolean;
  readonly helperText?: ReactNode;
  readonly getOptionLabel?: (option: T) => string;
  readonly renderOption?: (option: T, state: AutocompleteRenderOptionState) => ReactNode;
  readonly filterOptions?: (opts: T[], state: object) => T[];
  // readonly renderTags?: ((value: T[], getTagProps: AutocompleteGetTagProps) => ReactNode)
  //   & ((value: unknown[], getTagProps: AutocompleteGetTagProps) => ReactNode);
  // readonly multiple?: boolean;
  readonly popupIcon?: ReactNode;
  readonly withFiltering?: boolean;
  readonly withoutLabel?: boolean;
  readonly withCodeOrAbbr?: boolean;
  readonly requireInput?: boolean;
  // for Storybook only
  readonly testOpen?: boolean;
  readonly testValue?: string;
};

// const LookupPropTypes = {
//   // attributes
//   type: PropTypes.string.isRequired,
//   className: PropTypes.string,
//   fetched: PropTypes.array,
//   pending: PropTypes.bool,
//   failed: PropTypes.bool,
//   params: PropTypes.object,
//   fetch: PropTypes.func.isRequired,
//   fetchParams: PropTypes.object,
//   exclude: PropTypes.arrayOf(PropTypes.number.isRequired),
//   value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
//   onChange: PropTypes.func,
//   onFreeSoloChange: PropTypes.func,
//   disabled: PropTypes.bool,
//   fullWidth: PropTypes.bool,
//   nativeFullWidth: PropTypes.bool,
//   error: PropTypes.bool,
//   helperText: PropTypes.node,
//   getOptionLabel: PropTypes.func,
//   renderOption: PropTypes.func,
//   filterOptions: PropTypes.func,
//   // renderTags: PropTypes.func,
//   // multiple: PropTypes.bool,
//   popupIcon: PropTypes.node,
//   withFiltering: PropTypes.bool,
//   withoutLabel: PropTypes.bool,
//   withCodeOrAbbr: PropTypes.bool,
//   requireInput: PropTypes.bool,
//   // for Storybook only
//   testOpen: PropTypes.bool,
//   testValue: PropTypes.string
// };

// eslint-disable-next-line max-statements, complexity
function LookupRender<T, P extends FetchParams>({
  type,
  fetched,
  pending,
  failed,
  params: prevParams,
  fetch,
  fetchParams,
  exclude,
  value,
  onChange,
  onFreeSoloChange,
  disabled = false,
  className,
  fullWidth = false,
  nativeFullWidth,
  error,
  helperText,
  getOptionLabel: parentGetOptionLabel,
  renderOption,
  filterOptions,
  // multiple = false,
  // renderTags,
  popupIcon,
  withFiltering = false,
  withoutLabel = false,
  withCodeOrAbbr = false,
  requireInput = false,
  testOpen = false,
  testValue
}: LookupProps<T, P>, ref: ForwardedRef<HTMLDivElement>): ReactElement | null {
  const { search: lastSearch } = prevParams || {};

  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();

  const getLabel = useCallback((item?: T | null): string => {
    if (!item) return '';
    const label = (item as BaseItemType).name || (item as BaseItemType).title || '';
    if (!withCodeOrAbbr) return label;
    const code = (item as BaseItemType).code || (item as BaseItemType).abbr;
    return code ? `${label} (${code})` : label;
  }, [withCodeOrAbbr]);

  const [inputValue, setInputValue] = useState<string>(testValue || (onFreeSoloChange ? getLabel(value) : ''));
  const [open, setOpen] = useState<boolean>(testOpen);

  const search = toLower(trim(inputValue));
  const isSearching = !requireInput || size(search) >= 1;
  const loading = open && isSearching ? Boolean(pending) : false;
  const noInputPlaceholder = requireInput && open;

  const handleChange = useCallback((
    _event: ChangeEvent<{}>,
    newValue: string | T | null
  ) => (onFreeSoloChange || onChange)?.(newValue as (T | null)), [onFreeSoloChange, onChange]);

  const fetchOptions = useMemo(
    () => fetch ? throttle(fetch, API_CALL_LOOKUP_WAIT, { leading: true, trailing: true }) : null,
    [fetch]
  );

  const onInputChange = useCallback((
    _event: ChangeEvent<{}>,
    newInputValue: string,
    _reason: AutocompleteInputChangeReason
  ) => {
    onFreeSoloChange?.(newInputValue);
    setInputValue(testValue || newInputValue);
  }, [testValue, onFreeSoloChange]);

  useEffect(() => {
    // do nothing if search string and fetchParams are unchanged
    if (
      (requireInput && size(search) < 1) ||
      (isEqual(search, lastSearch) && (!fetchParams || isEqual(fetchParams, pick(prevParams, keys(fetchParams)))))
    ) return;
    // try to fetch options
    if (fetchOptions) {
      fetchOptions.cancel();
      if (!requireInput || search) fetchOptions((fetchParams ? { ...fetchParams, search } : { search }) as P);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, fetched, fetchParams, lastSearch, fetchOptions, requireInput]); // ignore `prevParams` changes

  const options = useMemo(
    () => (isSearching && (size(exclude) >= 1
      ? filter(fetched || [] as T[], (item) => indexOf(exclude, (item as BaseItemType)?.id) < 0)
      : fetched
    )) || EMPTY as T[],
    [exclude, fetched, isSearching]
  );

  const onOpen = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => setOpen(false), []);

  const isOptionEqualToValue = useCallback(
    (option: T, val: T): boolean => (option as BaseItemType).id === (val as BaseItemType).id, []
  );
  const getOptionLabel = useCallback((option: string | T): string => isString(option) ? option
    : parentGetOptionLabel?.(option) || getLabel(option),
    [parentGetOptionLabel, getLabel]
  );
  // const groupBy = useCallback((option: T): string => option.group, []);

  const [clearText, openText, closeText] = useMemo(() => [
    formatMessage({ id: 'lookup.text.clear' }),
    formatMessage({ id: 'lookup.text.open' }),
    formatMessage({ id: 'lookup.text.close' })
  ], [formatMessage]);
  const [label, placeholderText] = useMemo(
    () => withoutLabel ? [undefined, undefined] : [
      formatMessage({ id: `lookup.${type}.select` }),
      trim(formatMessage({ id: `lookup.${type}.placeholder`, defaultMessage: ' ' })) || undefined
    ],
    [type, withoutLabel, formatMessage]);
  const [loadingText, noOptionsText] = useMemo(() => [
    formatMessage({ id: `lookup.${type}.loading` }),
    formatMessage({ id: `lookup.${type}.nothing` })
  ], [formatMessage, type]);

  const handleRenderOption = useCallback((props: object, option: T, state: AutocompleteRenderOptionState): ReactNode => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <li {...props} key={(option as BaseItemType).id}>
      {renderOption ? renderOption(option, state) : getOptionLabel(option)}
    </li>
  ), [renderOption, getOptionLabel]);

  const renderInput = useCallback(({ InputProps, ...params }: AutocompleteRenderInputParams): ReactNode => (
    <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...params}
        label={label}
        placeholder={noInputPlaceholder ? undefined : placeholderText}
        variant="outlined"
        size="small"
        InputProps={{
          ...InputProps,
          endAdornment: (
            <>
              {loading ? <CircularProgress size={20} className={searching}/> : null}
              {InputProps.endAdornment}
            </>
          )
        }}
    />
  ), [loading, noInputPlaceholder, label, placeholderText]);

  const handleFilterOptions = useMemo(() => filterOptions || (
    withFiltering ? undefined : (opts: T[]) => opts
  ), [filterOptions, withFiltering]);

  return (
    <>
      <FormControl
          ref={ref}
          variant="outlined"
          size="small"
          disabled={disabled ? true : undefined}
          fullWidth={nativeFullWidth}
          error={error}
          className={clsx({
            [root]: !nativeFullWidth && !fullWidth,
            [rootFullWidth]: fullWidth,
            ...className ? { [className]: true } : {}
          })}
      >
        <Autocomplete
            id={`${type}-lookup-select`}
            sx={popupIcon ? sx : undefined}
            autoComplete
            // multiple={multiple}
            // disablePortal // misplaces Lookup options box
            clearText={clearText}
            openText={openText}
            closeText={closeText}
            loadingText={loadingText}
            noOptionsText={loading ? loadingText : (isSearching && noOptionsText) || placeholderText}
            open={open && isSearching ? true : undefined}
            onOpen={onOpen}
            disabled={disabled ? true : undefined}
            loading={loading}
            onClose={onClose}
            isOptionEqualToValue={isOptionEqualToValue}
            getOptionLabel={getOptionLabel}
            // groupBy={groupBy}
            options={loading ? EMPTY as T[] : options}
            renderInput={renderInput}
            renderOption={handleRenderOption}
            filterOptions={handleFilterOptions}
            freeSolo={onFreeSoloChange ? true : undefined}
            value={value}
            onChange={handleChange}
            inputValue={inputValue}
            onInputChange={onInputChange}
            // renderTags={renderTags}
            popupIcon={popupIcon}
        />
        {helperText ? (
          <FormHelperText>
            {helperText}
          </FormHelperText>
        ) : undefined}
      </FormControl>
      <ActionFailedAlert
          message={`lookup.${type}.error`}
          open={failed}
      />
    </>
  );
}

const Lookup = simpleForwardRef(LookupRender);

// Lookup.displayName = 'Lookup';

// Lookup.propTypes = LookupPropTypes;

export default Lookup;
