import { type ApolloCache } from '@apollo/client';

export function invalidateApolloCacheSkills(cache: ApolloCache<unknown>) {
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeRole' });
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeSkill' });
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'employeeSkills' });
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'myOpportunity' });
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'myOpportunities' });
  cache.evict({ id: 'ROOT_QUERY', fieldName: 'myOpportunitiesBrowse' });
}
