import { gql } from '@apollo/client';
// local imports
import { API_ADMIN_JOBS } from '../config/api';

export const ADMIN_JOBS_QUERY = gql`
  query AdminJobs(
    $location_id: Int
    $state_id: Int
    $country_id: Int
    $org_id: Int
    $career_ladder_id: Int
    $management_level: Int
    $management_level_min: Int
    $management_level_max: Int
    $supervisory_jobs_only: Boolean
    $open_reqs_only: Boolean
    $job_category: JobCategory
    $sort_by: AdminJobsSort
    $direction: SortDirection
    $limit: Int
    $offset: Int
  ) {
    adminJobs(
      location_id: $location_id,
      state_id: $state_id,
      country_id: $country_id,
      org_id: $org_id,
      career_ladder_id: $career_ladder_id,
      management_level: $management_level,
      management_level_min: $management_level_min,
      management_level_max: $management_level_max,
      supervisory_jobs_only: $supervisory_jobs_only,
      open_reqs_only: $open_reqs_only,
      job_category: $job_category,
      sort_by: $sort_by,
      direction: $direction,
      limit: $limit,
      offset: $offset
    )
    @rest(
      type: "AdminJobsPayload",
      path: "${API_ADMIN_JOBS}?{args}"
    ) {
      count
      results {
        id
        title
        code
        location {
          id
          title
          city
          state
          country
          country_id
          state_id
        }
        career_ladder {
          id
          title
        }
        category {
          id
          title
        }
        discipline {
          id
          title
          name
          sub_family {
            id
            title
          }
        }

        # internal data
        is_pilot
        management_level
        management_sublevel
        is_contractor
        salary_min
        salary_max
        salary_currency
        family
        job_type
        career_step
        sti_target_pct
        is_supervisory
        is_index_display
        source
        display_management_level
        unique_job {
          id
          title
        }
        sub_family {
          id
          title
        }

        # counts
        open_req_count
        new_req_count
        skill_count
        employee_count
      }
    }
  }
`;
