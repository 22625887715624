import { memo, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
// SCSS imports
import { square } from './FetchFailedAlert.module.scss';

type FetchFailedAlertProps = {
  flat?: boolean;
};

const FetchFailedAlertPropTypes = {
  // attributes
  flat: PropTypes.bool
};

const FetchFailedAlert: FunctionComponent<FetchFailedAlertProps> = ({
  flat = false
}) => (
  <Alert
      severity="error"
      elevation={flat ? 0 : undefined}
      square={flat}
      className={flat ? square : undefined}
  >
    <Typography variant="body1">
      <FormattedMessage id="error.fetch_failed"/>
    </Typography>
  </Alert>
);

FetchFailedAlert.propTypes = FetchFailedAlertPropTypes;

export default memo(FetchFailedAlert);
