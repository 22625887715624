import { forwardRef, memo, type Component } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box, { type BoxProps } from '@mui/material/Box';
import Alert from '@mui/material/Alert';
// EmPath UI Components
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
// local imports
import { Skill } from '../models/skill';
import TbItemChip from '../elements/TbItemChip';

type TbSkillsListProps = {
  skills?: Skill[] | null;
}

const TbSkillsListPropTypes = {
  // attributes
  skills: PropTypes.array as Validator<Skill[]>
};

const TbSkillsList = forwardRef<Component<BoxProps>, TbSkillsListProps>(({
  skills
}, ref) => {
  const hasSkills = size(skills) >= 1;
  return (
    <CardSection ref={ref}>
      <BoxTypography pt={2} pb={1} pr={4} variant="subtitle1">
        <FormattedMessage
            id="hr.teambuilder.review.skills_title"
        />
      </BoxTypography>
      <Box
          flexGrow={1}
          display="flex"
          justifyContent={hasSkills ? 'flex-start' : 'center'}
          alignContent={hasSkills ? 'flex-start' : 'center'}
          flexWrap="wrap"
      >
        {hasSkills ? map(skills, (skill, index) => (
          <Box key={index} pb={2} pr={2}>
            <TbItemChip item={skill} satisfied={Boolean(skill?.satisfied)} withLevel/>
          </Box>
        )) : (
          <Alert severity="info" variant="standard">
            <FormattedMessage id="skills.no_skills_found"/>
          </Alert>
        )}
      </Box>
    </CardSection>
  );
});

TbSkillsList.displayName = 'TbSkillsList';

TbSkillsList.propTypes = TbSkillsListPropTypes;

export default memo(TbSkillsList);
