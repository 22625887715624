import { gql } from '@apollo/client';
// local imports
import { API_DA_USAGE_BY_ORG } from '../config/api';

export const DA_USAGE_BY_ORG_QUERY = gql`
  query DAOrgUsage(
    $input: DAOrgUsageInput!
    $pathBuilder: String!
  ) {
    daOrgUsage(
      input: $input
    )
    @rest(
      type: "DAOrgUsagePayload",
      path: "${API_DA_USAGE_BY_ORG}",
      pathBuilder: $pathBuilder
    ) {
      total_employees
      results {
        id
        title
        employee_count
        usage_count
      }
    }
  }
`;
