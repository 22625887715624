import { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import indexOf from 'lodash/indexOf';
// Material UI imports
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
// local imports
import { DrawerItem } from '../types';
import AppDrawerItem from './AppDrawerItem';
// SCSS imports
import { list } from './AppDrawer.module.scss';

type AppDrawerProps = {
  items: DrawerItem[];
  path?: string;
  defaultLink?: string;
  open?: boolean;
  onOpen: () => void;
  onClose: () => void;
  isItemVisible?: (item: DrawerItem) => boolean;
};

const AppDrawerPropTypes = {
  // attributes
  items: PropTypes.array.isRequired,
  path: PropTypes.string,
  defaultLink: PropTypes.string,
  open: PropTypes.bool,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isItemVisible: PropTypes.func
};

const AppDrawer = forwardRef<HTMLDivElement, AppDrawerProps>(({
  items,
  path = '',
  defaultLink,
  open,
  onOpen,
  onClose,
  isItemVisible
}, ref) => (
  <SwipeableDrawer
      ref={ref}
      open={Boolean(open)}
      onOpen={onOpen}
      onClose={onClose}
  >
    <List className={list}>
      {items.map((item) => (!isItemVisible || isItemVisible(item)) && (
        <AppDrawerItem
            key={item.text || item.link || item.href || defaultLink}
            text={item.text}
            icon={item.icon}
            link={item.link || (isNil(item.href) && isNil(item.link) ? defaultLink : undefined)}
            href={item.href}
            onClick={onClose}
            active={item.link === path || indexOf(item.paths, path) >= 0}
        />
      ))}
    </List>
  </SwipeableDrawer>
));

AppDrawer.displayName = 'AppDrawer';

AppDrawer.propTypes = AppDrawerPropTypes;

export default memo(AppDrawer);
