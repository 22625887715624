import { gql } from '@apollo/client';
// local imports
import { API_ADMIN_USERS } from '../config/api';

export const ADMIN_USERS_QUERY = gql`
  query AdminUsers(
    $sort_by: AdminUsersSort
    $direction: SortDirection
    $limit: Int
    $offset: Int
  ) {
    adminUsers(
      sort_by: $sort_by,
      direction: $direction,
      limit: $limit,
      offset: $offset
    )
    @rest(
      type: "AdminUsersPayload",
      path: "${API_ADMIN_USERS}?{args}"
    ) {
      count
      results {
        id
        username
        first_name
        last_name
        email
        is_active
        is_staff
        is_superuser
        date_joined
      }
    }
  }
`;
