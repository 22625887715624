/* eslint-disable max-lines, complexity, max-lines-per-function */
import { useCallback, useMemo } from 'react';
// local imports
import { SKILL_LEVEL_FIRST } from '../models/skill';
import { SET_ACTIONS, NAVIGATION, APP_ONLINE, UNAUTHENTICATED } from '../constants/actionTypes';
import {
  MY_SKILLS_FETCHING, MY_SKILLS_FETCHED, MY_SKILLS_PARAMS,
  MY_SKILLS_UPDATED, MY_SKILLS_UPDATING,
  MY_TARGET_SKILLS_UPDATED, MY_TARGET_SKILLS_UPDATING,
  SKL_FETCHING, SKL_FETCHED, SKL_PARAMS,
  EMPLOYEE_ROLE_FETCHING, EMPLOYEE_ROLE_FETCHED, EMPLOYEE_ROLE_PARAMS,
  TECHNICAL_CAREER_PATH_FETCHING, TECHNICAL_CAREER_PATH_FETCHED, TECHNICAL_CAREER_PATH_PARAMS,
  JOB_MOVES_FETCHING, JOB_MOVES_FETCHED, JOB_MOVES_PARAMS,
  TARGET_ROLE_FETCHING, TARGET_ROLE_FETCHED, TARGET_ROLE_PARAMS,
  TARGET_ROLE_UPDATING, TARGET_ROLE_UPDATED,
  EMPLOYEES_CONNECT_FETCHING, EMPLOYEES_CONNECT_FETCHED, EMPLOYEES_CONNECT_PARAMS,
  PREFERENCES_FETCHING, PREFERENCES_FETCHED, PREFERENCES_PARAMS,
  SETTINGS_FETCHING, SETTINGS_FETCHED, SETTINGS_PARAMS,
  NOTIFICATIONS_FETCHING, NOTIFICATIONS_FETCHED, NOTIFICATIONS_PARAMS,
  PREFERENCES_UPDATING, PREFERENCES_UPDATED,
  SETTINGS_UPDATING, SETTINGS_UPDATED,
  EMPLOYEE_SKILL_FETCHING, EMPLOYEE_SKILL_FETCHED, EMPLOYEE_SKILL_PARAMS,
  DEV_PLAN_FETCHING, DEV_PLAN_FETCHED, DEV_PLAN_PARAMS,
  SKILL_EMPLOYEES_FETCHING, SKILL_EMPLOYEES_FETCHED, SKILL_EMPLOYEES_PARAMS,
  SKILL_UPDATING, SKILL_UPDATED,
  TARGET_SKILL_UPDATING, TARGET_SKILL_UPDATED,
  COURSE_UPDATING, COURSE_UPDATED,
  IN_DEMAND_SKILLS_FETCHING, IN_DEMAND_SKILLS_FETCHED, IN_DEMAND_SKILLS_PARAMS,
  RECOMMENDED_SKILLS_FETCHING, RECOMMENDED_SKILLS_FETCHED, RECOMMENDED_SKILLS_PARAMS,
  TARGETED_SKILLS_FETCHING, TARGETED_SKILLS_FETCHED, TARGETED_SKILLS_PARAMS,
  SKILLS_GAP_FETCHING, SKILLS_GAP_FETCHED, SKILLS_GAP_PARAMS,
  JOBS_INDEX_FETCHING, JOBS_INDEX_FETCHED, JOBS_INDEX_PARAMS,
  SKILL_ADDING, SKILL_ADDED,
  JOB_STATUS_FETCHING, JOB_STATUS_FETCHED, JOB_STATUS_PARAMS,
  DATA_STATUS_FETCHING, DATA_STATUS_FETCHED, DATA_STATUS_PARAMS,
  DATA_STATUS_UPDATING, DATA_STATUS_UPDATED,
  EMPLOYEE_MATCH_RATE_FETCHING, EMPLOYEE_MATCH_RATE_FETCHED, EMPLOYEE_MATCH_RATE_PARAMS,
  LEARNING_PROGRESS_FETCHING, LEARNING_PROGRESS_FETCHED, LEARNING_PROGRESS_PARAMS,
  MENTORSHIP_FETCHING, MENTORSHIP_FETCHED, MENTORSHIP_PARAMS,
  SUGGESTED_SKILL_ADDING, SUGGESTED_SKILL_ADDED, SUGGESTED_SKILL_REMOVING, SUGGESTED_SKILL_REMOVED,
  COC_FETCHING, COC_FETCHED, COC_PARAMS,
  UNREAD_COUNT_FETCHING, UNREAD_COUNT_FETCHED, UNREAD_COUNT_PARAMS,
  TALENT_DATA_FETCHING, TALENT_DATA_FETCHED, TALENT_DATA_PARAMS,
  EMPLOYEE_BOARD_SKILLS_FETCHING, EMPLOYEE_BOARD_SKILLS_FETCHED, EMPLOYEE_BOARD_SKILLS_PARAMS,
  EMPLOYEE_BOARD_SUGGESTED_FETCHING, EMPLOYEE_BOARD_SUGGESTED_FETCHED, EMPLOYEE_BOARD_SUGGESTED_PARAMS,
  EMPLOYEE_BOARD_JOBS_FETCHING, EMPLOYEE_BOARD_JOBS_FETCHED, EMPLOYEE_BOARD_JOBS_PARAMS,
  OPEN_REQS_FETCHING, OPEN_REQS_FETCHED, OPEN_REQS_PARAMS
} from './dataContext';
import { DATA_COMPUTING, forceReloadData, getDataStatus, isComputing } from '../constants/dataStatuses';
import useCustomerSettings from '../config/customer';
import {
  getInitialObjectState, getInitialState, getInitialStateWithCount,
  getPendingObjectState, getPendingState, getPendingStateWithCount,
  getFetchedObjectState, getFetchedState, getFetchedStateWithCount,
  updateStateParams, updateStateCountParams,
  getInitialActionState, getActionPendingState, getActionFinishedState,
  clearActionStates
} from '../helpers/context';
import useModels, {
  updateCachedRoleIsTarget, updateCachedRolesIsTarget, updateSkillCourses,
  updateCachedSkillIsTarget, updateCachedSkillsIsTarget,
  updateCachedTargetSkills, updateCachedSkillIsMentoring, updateCachedSettings
} from '../helpers/models';
import { updateEntityAttr, updateEntity } from '../helpers/reducers';

export const initialDataState = {
  // USER
  preferences: getInitialState(),
  settings: getInitialState(),
  notifications: getInitialStateWithCount(),
  coc: getInitialState(),
  unreadCount: getInitialState(),
  talentData: getInitialObjectState(),
  // action results
  preferencesUpdate: getInitialActionState(),
  settingsUpdate: getInitialActionState(),

  // SKILLS
  boardSkills: getInitialState(),
  boardSuggested: getInitialState(),
  boardJobs: getInitialState(),
  skills: getInitialStateWithCount(),
  skillsInDemand: getInitialStateWithCount(),
  recommendedSkills: getInitialState(),
  targetSkills: getInitialState(),
  learningProgress: getInitialState(),
  skillsGap: getInitialState(),
  jobsIndex: getInitialStateWithCount(),
  mentorship: getInitialState(),
  suggestedSkillAdd: getInitialActionState(),
  suggestedSkillRemove: getInitialActionState(),

  // SKILL
  skill: getInitialObjectState(),
  devPlan: getInitialObjectState(),
  skillEmployees: getInitialState(),
  // action results
  skillAdd: getInitialActionState(),
  skillUpdate: getInitialActionState(),
  targetSkillUpdate: getInitialActionState(),
  courseUpdate: getInitialActionState(),

  // ROLE
  role: getInitialState(),
  matchRate: getInitialState(),
  employeesToConnect: getInitialStateWithCount(),
  jobMoves: getInitialState(),
  targetRole: getInitialState(),
  careerPath: getInitialState(),
  // action results
  targetRoleUpdate: getInitialActionState(),
  openReqs: getInitialStateWithCount(),

  // EDITABLE SKILLS
  skl: getInitialState(),
  editableSks: getInitialStateWithCount(),
  // action results
  skillsUpdate: getInitialActionState(),
  targetSkillsUpdate: getInitialActionState(),

  // DATA STATUS
  jobStatus: getInitialState(),
  dataStatus: getInitialState(),
  // action results
  dataStatusUpdate: getInitialActionState()
};

function useDataReducer() {
  const { HAS_MENTORING } = useCustomerSettings();
  const {
    updateCachedPreferences, updateCachedPreferencesSkills, updateCachedSkillLevels,
    updateCachedRoleSkills, updateCachedSkillGaps, updateCachedSkillsLevels
  } = useModels();

  const dataReducer = useCallback((state, { type, payload, params }) => {
    switch (type) {
      case SET_ACTIONS: return { ...state, ...payload };


      // USER
      case PREFERENCES_FETCHING: return { ...state, preferences: getPendingState(params) };
      case PREFERENCES_FETCHED: return { ...state, preferences: getFetchedState(payload, params) };
      case PREFERENCES_PARAMS: return { ...state, preferences: updateStateParams(state.preferences, params) };

      case SETTINGS_FETCHING: return { ...state, settings: getPendingState(params) };
      case SETTINGS_FETCHED: return { ...state, settings: getFetchedState(payload, params) };
      case SETTINGS_PARAMS: return { ...state, settings: updateStateParams(state.settings, params) };

      case NOTIFICATIONS_FETCHING: return { ...state, notifications: getPendingStateWithCount(params) };
      case NOTIFICATIONS_FETCHED: return {
        ...state,
        ...payload ? {
          // when we request notifications from backend, it marks them as 'read'
          unreadCount: getInitialState()
        } : {},
        notifications: getFetchedStateWithCount(payload, params)
      };
      case NOTIFICATIONS_PARAMS: return { ...state, notifications: updateStateCountParams(state.notifications, params) };

      case COC_FETCHING: return { ...state, coc: getPendingState(params) };
      case COC_FETCHED: return { ...state, coc: getFetchedState(payload, params) };
      case COC_PARAMS: return { ...state, coc: updateStateParams(state.coc, params) };

      case UNREAD_COUNT_FETCHING: return { ...state, unreadCount: getPendingState(params) };
      case UNREAD_COUNT_FETCHED: return { ...state, unreadCount: getFetchedState(payload, params) };
      case UNREAD_COUNT_PARAMS: return { ...state, unreadCount: updateStateParams(state.unreadCount, params) };

      case TALENT_DATA_FETCHING: return { ...state, talentData: getPendingState(params) };
      case TALENT_DATA_FETCHED: return { ...state, talentData: getFetchedState(payload, params) };
      case TALENT_DATA_PARAMS: return { ...state, talentData: updateStateParams(state.talentData, params) };

      // SKILLS

      case EMPLOYEE_BOARD_SKILLS_FETCHING: return { ...state, boardSkills: getPendingState(params) };
      case EMPLOYEE_BOARD_SKILLS_FETCHED: return { ...state, boardSkills: getFetchedState(payload, params) };
      case EMPLOYEE_BOARD_SKILLS_PARAMS: return { ...state, boardSkills: updateStateParams(state.boardSkills, params) };

      case EMPLOYEE_BOARD_SUGGESTED_FETCHING: return { ...state, boardSuggested: getPendingState(params) };
      case EMPLOYEE_BOARD_SUGGESTED_FETCHED: return { ...state, boardSuggested: getFetchedState(payload, params) };
      case EMPLOYEE_BOARD_SUGGESTED_PARAMS:
        return { ...state, boardSuggested: updateStateParams(state.boardSuggested, params) };

      case EMPLOYEE_BOARD_JOBS_FETCHING: return { ...state, boardJobs: getPendingState(params) };
      case EMPLOYEE_BOARD_JOBS_FETCHED: return { ...state, boardJobs: getFetchedState(payload, params) };
      case EMPLOYEE_BOARD_JOBS_PARAMS: return { ...state, boardJobs: updateStateParams(state.boardJobs, params) };

      case IN_DEMAND_SKILLS_FETCHING: return { ...state, skillsInDemand: getPendingStateWithCount(params) };
      case IN_DEMAND_SKILLS_FETCHED: return { ...state, skillsInDemand: getFetchedStateWithCount(payload, params) };
      case IN_DEMAND_SKILLS_PARAMS: return { ...state, skillsInDemand: updateStateCountParams(state.skillsInDemand, params) };

      case RECOMMENDED_SKILLS_FETCHING: return { ...state, recommendedSkills: getPendingState(params) };
      case RECOMMENDED_SKILLS_FETCHED: return { ...state, recommendedSkills: getFetchedState(payload, params) };
      case RECOMMENDED_SKILLS_PARAMS:
        return { ...state, recommendedSkills: updateStateParams(state.recommendedSkills, params) };

      case TARGETED_SKILLS_FETCHING: return { ...state, targetSkills: getPendingState(params) };
      case TARGETED_SKILLS_FETCHED: return { ...state, targetSkills: getFetchedState(payload, params) };
      case TARGETED_SKILLS_PARAMS: return { ...state, targetSkills: updateStateParams(state.targetSkills, params) };

      case LEARNING_PROGRESS_FETCHING: return { ...state, learningProgress: getPendingState(params) };
      case LEARNING_PROGRESS_FETCHED: return { ...state, learningProgress: getFetchedState(payload, params) };
      case LEARNING_PROGRESS_PARAMS: return { ...state, learningProgress: updateStateParams(state.learningProgress, params) };

      case SKILLS_GAP_FETCHING: return { ...state, skillsGap: getPendingState(params) };
      case SKILLS_GAP_FETCHED: return { ...state, skillsGap: getFetchedState(payload, params) };
      case SKILLS_GAP_PARAMS: return { ...state, skillsGap: updateStateParams(state.skillsGap, params) };

      case JOBS_INDEX_FETCHING: return { ...state, jobsIndex: getPendingStateWithCount(params) };
      case JOBS_INDEX_FETCHED: return { ...state, jobsIndex: getFetchedStateWithCount(payload, params) };
      case JOBS_INDEX_PARAMS: return { ...state, jobsIndex: updateStateCountParams(state.jobsIndex, params) };

      case MENTORSHIP_FETCHING: return HAS_MENTORING ? { ...state, mentorship: getPendingState(params) } : state;
      case MENTORSHIP_FETCHED: return HAS_MENTORING ? { ...state, mentorship: getFetchedState(payload, params) } : state;
      case MENTORSHIP_PARAMS:
        return HAS_MENTORING ? { ...state, mentorship: updateStateParams(state.mentorship, params) } : state;

      // SKILL

      case EMPLOYEE_SKILL_FETCHING: return { ...state, skill: getPendingObjectState(params) };
      case EMPLOYEE_SKILL_FETCHED: return { ...state, skill: getFetchedObjectState(payload, params) };
      case EMPLOYEE_SKILL_PARAMS: return { ...state, skill: updateStateParams(state.skill, params) };

      case DEV_PLAN_FETCHING: return { ...state, devPlan: getPendingObjectState(params) };
      case DEV_PLAN_FETCHED: return { ...state, devPlan: getFetchedObjectState(payload, params) };
      case DEV_PLAN_PARAMS: return { ...state, devPlan: updateStateParams(state.devPlan, params) };

      case SKILL_EMPLOYEES_FETCHING: return { ...state, skillEmployees: getPendingState(params) };
      case SKILL_EMPLOYEES_FETCHED: return { ...state, skillEmployees: getFetchedState(payload, params) };
      case SKILL_EMPLOYEES_PARAMS: return { ...state, skillEmployees: updateStateParams(state.skillEmployees, params) };

      // ROLE

      case EMPLOYEE_ROLE_FETCHING: return { ...state, role: getPendingState(params) };
      case EMPLOYEE_ROLE_FETCHED: return { ...state, role: getFetchedState(payload, params) };
      case EMPLOYEE_ROLE_PARAMS: return { ...state, role: updateStateParams(state.role, params) };

      case EMPLOYEE_MATCH_RATE_FETCHING: return { ...state, matchRate: getPendingState(params) };
      case EMPLOYEE_MATCH_RATE_FETCHED: return { ...state, matchRate: getFetchedState(payload, params) };
      case EMPLOYEE_MATCH_RATE_PARAMS: return { ...state, matchRate: updateStateParams(state.role, params) };

      case EMPLOYEES_CONNECT_FETCHING: return { ...state, employeesToConnect: getPendingStateWithCount(params) };
      case EMPLOYEES_CONNECT_FETCHED: return { ...state, employeesToConnect: getFetchedStateWithCount(payload, params) };
      case EMPLOYEES_CONNECT_PARAMS: return {
        ...state, employeesToConnect: updateStateCountParams(state.employeesToConnect, params)
      };

      case TECHNICAL_CAREER_PATH_FETCHING: return { ...state, careerPath: getPendingState(params) };
      case TECHNICAL_CAREER_PATH_FETCHED: return { ...state, careerPath: getFetchedState(payload, params) };
      case TECHNICAL_CAREER_PATH_PARAMS: return { ...state, careerPath: updateStateParams(state.careerPath, params) };

      case JOB_MOVES_FETCHING: return { ...state, jobMoves: getPendingState(params) };
      case JOB_MOVES_FETCHED: return { ...state, jobMoves: getFetchedState(payload, params) };
      case JOB_MOVES_PARAMS: return { ...state, jobMoves: updateStateParams(state.jobMoves, params) };

      case TARGET_ROLE_FETCHING: return { ...state, targetRole: getPendingState(params) };
      case TARGET_ROLE_FETCHED: return { ...state, targetRole: getFetchedState(payload, params) };
      case TARGET_ROLE_PARAMS: return { ...state, targetRole: updateStateParams(state.targetRole, params) };

      case OPEN_REQS_FETCHING: return { ...state, openReqs: getPendingStateWithCount(params) };
      case OPEN_REQS_FETCHED: return { ...state, openReqs: getFetchedStateWithCount(payload, params) };
      case OPEN_REQS_PARAMS: return { ...state, openReqs: updateStateCountParams(state.openReqs, params) };

      // EDITABLE SKILLS

      case SKL_FETCHING: return { ...state, skl: getPendingState(params) };
      case SKL_FETCHED: return { ...state, skl: getFetchedState(payload, params) };
      case SKL_PARAMS: return { ...state, skl: updateStateParams(state.skl, params) };

      case MY_SKILLS_FETCHING: return { ...state, editableSks: getPendingStateWithCount(params) };
      case MY_SKILLS_FETCHED: return { ...state, editableSks: getFetchedStateWithCount(payload, params) };
      case MY_SKILLS_PARAMS: return { ...state, editableSks: updateStateCountParams(state.editableSks, params) };

      // DATA STATUS
      // don't drop previous statuses!
      case JOB_STATUS_FETCHING: return { ...state, jobStatus: getPendingState(params, state.jobStatus.data) };
      case JOB_STATUS_FETCHED: return { ...state, jobStatus: getFetchedState(payload, params) };
      case JOB_STATUS_PARAMS: return { ...state, jobStatus: updateStateParams(state.jobStatus, params) };
      // don't drop previous statuses!
      case DATA_STATUS_FETCHING: return { ...state, dataStatus: getPendingState(params, state.dataStatus.data) };
      case DATA_STATUS_FETCHED: return {
        ...state,
        ...forceReloadData(state.dataStatus.data, payload) ? {
          boardSkills: getInitialState(),
          boardSuggested: getInitialState(),
          boardJobs: getInitialState(),
          skillsGap: getInitialState(),
          recommendedSkills: getInitialState(),
          skill: getInitialObjectState(),
          devPlan: getInitialObjectState(),
          jobsIndex: getInitialStateWithCount(),
          talentData: getInitialObjectState(),
          role: getInitialState(), // to update match rate
          targetRole: getInitialState(), // to update match rate
          jobMoves: getInitialState(), // to update match rates
          matchRate: getInitialState()
        } : {},
        dataStatus: getFetchedState(payload, params)
      };
      case DATA_STATUS_PARAMS: return { ...state, dataStatus: updateStateParams(state.dataStatus, params) };

      // UPDATES

      case DATA_STATUS_UPDATING: return {
        ...state,
        ...clearActionStates(state),
        dataStatusUpdate: getActionPendingState(params)
      };
      case DATA_STATUS_UPDATED: return {
        ...state,
        ...payload ? updateEntity(state, 'dataStatus', (dataStatus) => isComputing(getDataStatus(dataStatus))
          ? dataStatus
          : { ...dataStatus, cached_data_status: DATA_COMPUTING }
        ) : {},
        dataStatusUpdate: getActionFinishedState(payload, params)
      };

      case MY_SKILLS_UPDATING: return {
        ...state,
        ...clearActionStates(state),
        skillsUpdate: getActionPendingState(params)
      };
      case MY_TARGET_SKILLS_UPDATING: return {
        ...state,
        ...clearActionStates(state),
        targetSkillsUpdate: getActionPendingState(params)
      };
      case MY_SKILLS_UPDATED:
      case MY_TARGET_SKILLS_UPDATED: return {
        ...state,
        ...payload ? {
          // RESET ALL SKILLS AND ROLES/JOBS THOSE MAY CONTAIN SKILL LEVELS, IS_MENTORING and/or IS_TARGET
          // USER
          ...HAS_MENTORING ?
            { preferences: getInitialState() } : {}, // skills_i_can_mentor and skills_i_mentor must be refetched
          // SKILLS
          boardSkills: getInitialState(),
          boardSuggested: getInitialState(),
          skills: getInitialStateWithCount(),
          skillsInDemand: getInitialStateWithCount(),
          recommendedSkills: getInitialState(),
          targetSkills: getInitialState(),
          learningProgress: getInitialState(),
          skillsGap: getInitialState(),
          ...HAS_MENTORING ? { mentorship: getInitialState() } : {},
          // SKILL
          skill: getInitialObjectState(),
          // ROLE
          role: getInitialState(),
          // `updateSkills` and `updateTargetSkills` actions are used on Inferred Skills/Profile Builder screens only,
          // which redirects to Dashboard right after this action is succeeded;
          // so it's absolutely safe to drop all cached `editableSks` here.
          editableSks: getInitialStateWithCount()
        } : {},
        [type === MY_TARGET_SKILLS_UPDATED ? 'targetSkillsUpdate' : 'skillsUpdate']: getActionFinishedState(payload, params)
      };

      case PREFERENCES_UPDATING: return {
        ...state,
        ...clearActionStates(state),
        preferencesUpdate: getActionPendingState(params)
      };
      case PREFERENCES_UPDATED: return {
        ...state,
        // PREFERENCES => SKILLS: IS_MENTORING
        ...HAS_MENTORING && payload ? {
          mentorship: getInitialState()
        } : {},
        ...payload && params ? updateEntity(state, 'preferences', (preferences) =>
            updateCachedPreferences(preferences, params)) : {},
        ...HAS_MENTORING && payload && params
          ? updateEntity(state, 'skill', (skill) => updateCachedSkillIsMentoring(skill, params)) : {},
        preferencesUpdate: getActionFinishedState(payload, params)
      };

      case SETTINGS_UPDATING: return {
        ...state,
        ...clearActionStates(state),
        settingsUpdate: getActionPendingState(params)
      };
      case SETTINGS_UPDATED: return {
        ...state,
        ...payload && params ? updateEntity(state, 'settings', (settings) => updateCachedSettings(settings, params)) : {},
        settingsUpdate: getActionFinishedState(payload, params)
      };

      case SKILL_ADDING: return {
        ...state,
        ...clearActionStates(state),
        skillAdd: getActionPendingState(params)
      };
      case SKILL_UPDATING: return {
        ...state,
        ...clearActionStates(state),
        skillUpdate: getActionPendingState(params)
      };
      case SKILL_ADDED:
      case SKILL_UPDATED:
        return {
        ...state,
        // SKILLS ADD / SKILL UPDATE: LEVEL + IS_MENTORING + IS_TARGET
        ...payload ? {
          boardSkills: getInitialState(),
          boardSuggested: getInitialState(),
          matchRate: getInitialState(),
          ...HAS_MENTORING ? { mentorship: getInitialState() } : {},
          devPlan: getInitialObjectState(),
          dataStatus: {
            ...getInitialState(),
            data: state.dataStatus.data // don't drop previous status!
          }
        } : {},
        // My Skills: new skill added / existing skill deleted
        ...payload && params && (!params.source || params.source !== 'profile_builder') &&
          (type === SKILL_ADDED || (type === SKILL_UPDATED && params.level < SKILL_LEVEL_FIRST)) ? {
            editableSks: getInitialStateWithCount()
          } : {},
        // Target Skills: existing skill updated / new target skill added
        ...payload && params && (type === SKILL_UPDATED || (type === SKILL_ADDED && params.is_target)) ? {
          targetSkills: getInitialState()
        } : {},
        // My Skills: existing skill updated
        ...payload && params && type === SKILL_UPDATED && (
          params.level >= SKILL_LEVEL_FIRST || params.source === 'profile_builder'
        ) ? updateEntity(state, 'editableSks', (skills) => updateCachedSkillsLevels(skills, params)) : {},
        // other skills
        ...payload && params ? updateEntity(state, 'skill', (skill) => updateCachedSkillLevels(skill, params)) : {},
        ...payload && params ? updateEntity(state, 'skills', (skills) => updateCachedSkillsLevels(skills, params)) : {},
        ...payload && params ? updateEntity(state, 'skillsInDemand', (skills) => updateCachedSkillsLevels(skills, params)) : {},
        ...payload && params ? updateEntity(state, 'recommendedSkills', (skills) =>
            updateCachedSkillsLevels(skills, params)) : {},
        ...payload && params ? updateEntity(state, 'learningProgress', (learningProgress) =>
            updateCachedSkillsLevels(learningProgress, params)) : {},
        ...payload && params ? updateEntity(state, 'role', (role) => updateCachedRoleSkills(role, params)) : {},
        ...payload && params
          ? (params.updateSkillsGap && updateEntityAttr(state, 'skillsGap', 'skills_with_gap', (skills_with_gap) =>
              updateCachedSkillGaps(skills_with_gap, params))
            ) || {
              skillsGap: getInitialState()
            }
          : {},
        ...payload && params ? updateEntity(state, 'preferences', (preferences) =>
            updateCachedPreferencesSkills(preferences, params, payload)) : {}, // payload contains updated skill object
        [type === SKILL_ADDED ? 'skillAdd' : 'skillUpdate']: getActionFinishedState(payload, params)
      };

      case SUGGESTED_SKILL_ADDING: return { ...state, suggestedSkillAdd: getActionPendingState(params) };
      case SUGGESTED_SKILL_ADDED: return {
        ...state,
        ...clearActionStates(state),
        ...payload ? {
          recommendedSkills: getInitialState()
        } : {},
        suggestedSkillAdd: getActionFinishedState(payload, params)
      };
      case SUGGESTED_SKILL_REMOVING: return { ...state, suggestedSkillRemove: getActionPendingState(params) };
      case SUGGESTED_SKILL_REMOVED: return {
        ...state,
        ...clearActionStates(state),
        ...payload ? {
          recommendedSkills: getInitialState()
        } : {},
        suggestedSkillRemove: getActionFinishedState(payload, params)
      };

      case TARGET_SKILL_UPDATING: return {
        ...state,
        ...clearActionStates(state),
        targetSkillUpdate: getActionPendingState(params)
      };
      case TARGET_SKILL_UPDATED: return {
        ...state,
        // SKILLS UPDATE: IS_TARGET
        ...payload ? {
          boardSkills: getInitialState(),
          boardSuggested: getInitialState()
        } : {},
        ...payload && params ? updateEntity(state, 'skill', (skill) => updateCachedSkillIsTarget(skill, params)) : {},
        ...payload && params ? updateEntity(state, 'skills', (skills) => updateCachedSkillsIsTarget(skills, params)) : {},
        ...payload && params ? updateEntity(state, 'skillsInDemand', (skills) =>
          updateCachedSkillsIsTarget(skills, params)) : {},
        ...payload && params ? updateEntity(state, 'editableSks', (skills) =>
          updateCachedSkillsIsTarget(skills, params)) : {},
        ...payload && params ? updateEntity(state, 'recommendedSkills', (skills) =>
          updateCachedSkillsIsTarget(skills, params)) : {},
        ...payload && params ? updateEntityAttr(state, 'skillsGap', 'skills_with_gap', (skills_with_gap) =>
          updateCachedSkillsIsTarget(skills_with_gap, params)) : {},
        ...payload && params ? updateEntity(state, 'targetSkills', (targetSkills) =>
          updateCachedTargetSkills(targetSkills, params, payload)) : {},
        ...payload && params ? updateEntity(state, 'learningProgress', (skills) =>
          updateCachedSkillsIsTarget(skills, params)) : {},
        ...payload && params ? updateEntityAttr(state, 'role', ['skills', 'skills_with_gap'], (skills) =>
          updateCachedSkillsIsTarget(skills, params)) : {},
        targetSkillUpdate: getActionFinishedState(payload, params)
      };

      case TARGET_ROLE_UPDATING: return {
        ...state,
        ...clearActionStates(state),
        targetRoleUpdate: getActionPendingState(params)
      };
      case TARGET_ROLE_UPDATED: return {
        ...state,
        // ROLES/JOBS UPDATE: IS_TARGET
        ...payload ? {
          boardJobs: getInitialState(),
          targetRole: getInitialState(), // targetRole must be refetched
          jobsIndex: getInitialStateWithCount() // jobsIndex must be refetched
        } : {},
        ...payload && params ? updateEntity(state, 'role', (role) => updateCachedRoleIsTarget(role, params)) : {},
        ...payload && params ? updateEntity(state, 'skillsGap', (skillsGap) =>
            updateCachedRoleIsTarget(skillsGap, params)) : {},
        ...payload && params ? updateEntityAttr(state, 'skill', 'jobs', (jobs) =>
            updateCachedRolesIsTarget(jobs, params)) : {},
        targetRoleUpdate: getActionFinishedState(payload, params)
      };

      case COURSE_UPDATING: return {
        ...state,
        ...clearActionStates(state),
        courseUpdate: getActionPendingState(params)
      };
      case COURSE_UPDATED: return {
        ...state,
        ...payload && state.devPlan.data && state.devPlan.data.id === params.skill_id ? { devPlan: {
          ...state.devPlan, data: updateSkillCourses(state.devPlan.data, params)
        }} : {},
        courseUpdate: getActionFinishedState(payload, params)
      };


      // COMMON ACTIONS

      case NAVIGATION: return {
        ...clearActionStates(state),
        // YES, we just drop these fetched data on navigation (browser URL change):
        dataStatus: {
          ...getInitialState(),
          data: state.dataStatus.data // don't drop previous status!
        },
        skl: getInitialState()
      };

      case APP_ONLINE: return clearActionStates(state);

      case UNAUTHENTICATED:
        return { ...state, ...initialDataState };


      default: return state;
    }
  }, [
    HAS_MENTORING, updateCachedPreferences, updateCachedPreferencesSkills, updateCachedRoleSkills,
    updateCachedSkillGaps, updateCachedSkillLevels, updateCachedSkillsLevels
  ]);

  return useMemo(() => ({ dataReducer }), [dataReducer]);
}

export default useDataReducer;
