import { forwardRef, memo, type ReactNode } from 'react';
import PropTypes from 'prop-types';
import size from 'lodash/size';
import isString from 'lodash/isString';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
// local imports
import { Values, ValuesPropType } from '../helpers/intl';
import BoxTypography from '../mixins/BoxTypography';
import CardSubheader from './CardSubheader';
// SCSS imports
import { wrapped, noTopPadding, noBottomPadding, vDivider, hDivider, displayFlex } from './CardTitle.module.scss';

type CardTitleProps = {
  title: ReactNode | ReactNode[] | string;
  values?: Values;
  withVerticalDivider?: boolean;
  subheader?: ReactNode | ReactNode[] | string;
  avatar?: ReactNode;
  action?: ReactNode;
  withDivider?: boolean;
  compact?: boolean;
  following?: boolean;
  centered?: boolean;
  wrap?: boolean;
  flex?: boolean;
  disabled?: boolean;
  className?: string;
};

const CardTitlePropTypes = {
  title: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
    PropTypes.node.isRequired,
    PropTypes.string.isRequired
  ]).isRequired,
  values: ValuesPropType,
  withVerticalDivider: PropTypes.bool,
  subheader: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string
  ]),
  avatar: PropTypes.node,
  action: PropTypes.node,
  withDivider: PropTypes.bool,
  compact: PropTypes.bool,
  following: PropTypes.bool,
  centered: PropTypes.bool,
  wrap: PropTypes.bool,
  flex: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

const CardTitle = forwardRef<HTMLElement, CardTitleProps>(({
  title,
  values,
  flex = false,
  withVerticalDivider = false,
  subheader,
  avatar,
  action,
  withDivider = false,
  compact = false,
  following = false,
  centered = false,
  wrap = false,
  disabled = false,
  className
}, ref) => (
  <>
    <CardHeader
        ref={ref}
        disableTypography
        title={
          <BoxTypography
              pr={1.5}
              flexGrow={centered ? 1 : undefined}
              align={centered ? 'center' : undefined}
              variant="h3"
              color={disabled ? 'text.disabled' : undefined}
              className={clsx({
                [vDivider]: withVerticalDivider,
                [displayFlex]: flex
              })}
          >
            {isString(title) && size(title) >= 1 ? (
              <FormattedMessage id={title} values={values} defaultMessage={title}/>
            ) : title}
          </BoxTypography>
        }
        subheader={isString(subheader) && size(subheader) >= 1
          ? (
            <CardSubheader
                text={subheader}
                values={values}
                alignSelf="flex-end"
                pl={3}
                pb={0.125}
            />
          ) : subheader}
        avatar={avatar}
        action={action}
        className={clsx(className, {
          [wrapped]: wrap,
          [noTopPadding]: following,
          [noBottomPadding]: compact
        })}
    />
    {withDivider ? <Divider light className={hDivider}/> : undefined}
  </>
));

CardTitle.displayName = 'CardTitle';

CardTitle.propTypes = CardTitlePropTypes;

export default memo(CardTitle);
