import { gql } from '@apollo/client';
// local imports
import { API_HR_REDEPLOYMENT } from '../config/api';

export const DELETE_REDEPLOYMENT_PLAN = gql`
  mutation DeleteRedeploymentPlan(
    $plan_id: Int!
    $selected_leader_id: String
  ) {
    deleteRedeploymentPlan(
      plan_id: $plan_id,
      selected_leader_id: $selected_leader_id
    )
    @rest(
      type: "MutationResponse",
      path: "${API_HR_REDEPLOYMENT}?{args}",
      method: "DELETE"
    ) {
      success # just a placeholder; this request has empty response (status 204 only)
    }
  }
`;
