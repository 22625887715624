import { gql } from '@apollo/client';
// local imports
import { API_SUPV_OPPORTUNITY } from '../config/api';

export const UPDATE_OPPORTUNITY = gql`
  mutation UpdateOpportunity(
    $opportunity_id: Int!
    $input: OpportunityInput!
  ) {
    updateOpportunity(
      opportunity_id: $opportunity_id,
      input: $input
    )
    @rest(
      type: "MutationResponse",
      path: "${API_SUPV_OPPORTUNITY}",
      method: "PATCH"
    ) {
      success
      optimistic
    }
  }
`;
