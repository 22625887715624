import round from 'lodash/round';
import { DateTime, type DateTimeOptions } from 'luxon';
// local imports
import { DEFAULT_QUARTER_FORMAT } from '../config/params';

const options: DateTimeOptions = { zone: 'utc' };

export const getCurrentUtc = () => DateTime.utc();

// export const getUtcFromDate = (date: Date) => DateTime.fromJSDate(date);

export const getUtcFromISO = (str: string) => {
  const dt = DateTime.fromISO(str, options);
  if (dt.isValid) return dt;
  return DateTime.fromSQL(str, options);
};

// export const getUtcFromString = (str: string) => {
//   const dt = DateTime.fromSQL(str, options);
//   if (dt.isValid) return dt;
//   return DateTime.fromISO(str, options);
// };

export const getCurrentSeconds = () => round(getCurrentUtc().toSeconds());
export const getSecondsFromISO = (str: string) => round(getUtcFromISO(str).toSeconds());

export const getJsDateFromISO = (str: string) => {
  const dt = getUtcFromISO(str);
  return (dt.isValid ? dt : getCurrentUtc()).toJSDate();
};

export const getCurrentQuarter = () => getCurrentUtc().startOf('quarter');

export const getFormattedQuarter = (
  date: DateTime,
  format?: string
) => date.toFormat(format || DEFAULT_QUARTER_FORMAT);

export const getFormattedQuarterFrom = (
  date: DateTime,
  quarters: number,
  format?: string
) => getFormattedQuarter(date.plus({ quarters }), format || DEFAULT_QUARTER_FORMAT);
