// Propogate action function(s) to context
export const SET_ACTIONS = 'SET_ACTIONS' as const;

// App Online/Offline
export const APP_ONLINE = 'APP_ONLINE' as const;

// Navigation between screens/pages
export const NAVIGATION = 'NAVIGATION' as const;

export const FETCHING = '_FETCHING' as const;
export const FETCHED = '_FETCHED' as const;
export const PARAMS = '_PARAMS' as const;
export const DELAYED = '_DELAYED' as const;

// User Authentication
export const AUTH = 'AUTH' as const;
export const AUTHENTICATING = `${AUTH}${FETCHING}` as const;
export const AUTHENTICATED = `${AUTH}${FETCHED}` as const;
// User Re-Authentication with saved token
export const REAUTH = 'REAUTH' as const;
export const REAUTHENTICATING = `${REAUTH}${FETCHING}` as const;
export const REAUTHENTICATED = `${REAUTH}${FETCHED}` as const;
// User Unauthentication
export const UNAUTHENTICATED = 'UNAUTHENTICATED' as const;

export type CommonActions =
  | { type: typeof SET_ACTIONS; payload: Record<string, Function>; }
  | { type: typeof UNAUTHENTICATED; payload?: null; }
  | { type: typeof APP_ONLINE; payload: boolean; }
  | { type: typeof NAVIGATION; payload?: null; };
