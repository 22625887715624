import { useCallback, useMemo } from 'react';
// local imports
import useBuilderSteps from '../constants/builder';
import { HASH_SKIP_PROFILE_BUILDER } from '../config/params';
import useCustomerSettings from '../config/customer';
import {
  PATH_MY, PATH_MY_SPLASH, PATH_MY_WELCOME, PATH_SUPERVISOR, PATH_HR, PATH_ADMIN_INDEMAND_SKILLS, PATH_ADMIN
} from '../config/paths';
import useUserModel, { isNotContractor, isEmployee, isManager, isLeader, hasHRBP, isAdmin, User } from '../models/user';
import { getPath } from './storage';

function useRedirects() {
  const { HAS_ADMIN_UI, HAS_PROFILE_BUILDER } = useCustomerSettings();
  const { noStepCompleted } = useBuilderSteps();
  const { hasNoAccess } = useUserModel();

  const getEmployeePath = useCallback((user: User, noDefaultPath = false, noProfileBuilder = false): string =>
    // Redirect new Employee users (except contractors) to Welcome screen
    (HAS_PROFILE_BUILDER && !noProfileBuilder && isNotContractor(user) && noStepCompleted(user) && PATH_MY_WELCOME) ||
    // Redirect others to Employee Dashboard (unless `noDefaultPath` is `true`)
    (noDefaultPath ? '' : PATH_MY),
    [HAS_PROFILE_BUILDER, noStepCompleted]);

  /**
   * User home path – for Company Logo link
   */
  const getHomePath = useCallback((user?: User | null): string | undefined =>
    (isEmployee(user) && PATH_MY) ||
    (hasHRBP(user) && PATH_HR) ||
    (isManager(user) && PATH_SUPERVISOR) ||
    (isAdmin(user) && (HAS_ADMIN_UI ? PATH_ADMIN : PATH_ADMIN_INDEMAND_SKILLS)) ||
    undefined,
    [HAS_ADMIN_UI]);

  /**
   * Default redirection path – use only if `hasNoAccess` returned `false`.
   * @param noDefaultPath If `true`, returns non-empty string only if the user must be forcibly redirected to some screen,
   * e.g. Profile Builder or Splash screen.
   */
  const getDefaultPath = useCallback((user: User, noDefaultPath = false): string =>
    (isEmployee(user) && getEmployeePath(user, noDefaultPath)) || (!noDefaultPath && (
      // Redirect non-Employee HR Personas to HRBP UI
      (hasHRBP(user) && PATH_HR) ||
      // Redirect non-Employee non-HRBP Managers to Manager UI
      (isManager(user) && PATH_SUPERVISOR) ||
      // Redirect non-Employee non-HRBP non-Manager Admins to In-Demand Skills Editor
      (isAdmin(user) && (HAS_ADMIN_UI ? PATH_ADMIN : PATH_ADMIN_INDEMAND_SKILLS))
    )) || '', [HAS_ADMIN_UI, getEmployeePath]);

  /**
   * Redirection path for logged-in user.
   * @param noDefaultPath If `true`, returns non-empty string only if the user must be forcibly redirected to some screen,
   * e.g. last visited screen (before SSO re-authentication), Profile Builder or Splash screen.
   */
  const getRedirectionPath = useCallback((user: User, noDefaultPath = false): string => {
    // Redirect users with no access permissions to the Splash screen
    if (hasNoAccess(user)) return PATH_MY_SPLASH;
    const savedPath = getPath();
    const noProfileBuilder = Boolean(savedPath?.includes(HASH_SKIP_PROFILE_BUILDER));
    // Redirect new Employee users (except contractors) to Welcome screen
    return (isEmployee(user) && getEmployeePath(user, true, noProfileBuilder)) ||
      // Redirect users to the last visited screen
      (savedPath || '') || // TODO: match `savedPath` to `ROUTES`
      // Redirect to default user's path otherwise
      getDefaultPath(user, noDefaultPath);
  }, [getDefaultPath, getEmployeePath, hasNoAccess]);

  /**
   * Redirection path for non-Employee trying to open Employee UI screen
   */
  const getNonEmployeePath = useCallback((user: User): string =>
    // Redirect users with no access permissions to the Splash screen
    (hasNoAccess(user) && PATH_MY_SPLASH) ||
    (!isEmployee(user) && (
      // Redirect non-Employee HR Personas to HRBP UI
      (hasHRBP(user) && PATH_HR) ||
      // Redirect non-Employee non-HRBP Managers to Manager UI
      (isManager(user) && PATH_SUPERVISOR) ||
      // Redirect non-Employee non-HRBP non-Manager Admins to In-Demand Skills Editor
      (isAdmin(user) && (HAS_ADMIN_UI ? PATH_ADMIN : PATH_ADMIN_INDEMAND_SKILLS)) ||
      PATH_MY_SPLASH
    )) || '', [HAS_ADMIN_UI, hasNoAccess]);

  /**
   * Redirection path for non-Employee & non-Leader trying to open Employee Skills UI screen
   */
  const getNonEmployeeNonLeaderPath = useCallback((user: User): string =>
    // Redirect users with no access permissions to the Splash screen
    (hasNoAccess(user) && PATH_MY_SPLASH) ||
    (!isEmployee(user) && !isLeader(user) && (
      // Redirect non-Employee HR Personas to HRBP UI
      (hasHRBP(user) && PATH_HR) ||
      // Redirect non-Employee non-HRBP Managers to Manager UI
      (isManager(user) && PATH_SUPERVISOR) ||
      // Redirect non-Employee non-HRBP non-Manager Admins to In-Demand Skills Editor
      (isAdmin(user) && (HAS_ADMIN_UI ? PATH_ADMIN : PATH_ADMIN_INDEMAND_SKILLS)) ||
      PATH_MY_SPLASH
    )) || '', [HAS_ADMIN_UI, hasNoAccess]);

  /**
   * Redirection path for non-Employee & non-Leader trying to open Employee Skills UI screen
   */
  const getNonEmployeeNonManagerPath = useCallback((user: User): string =>
    // Redirect users with no access permissions to the Splash screen
    (hasNoAccess(user) && PATH_MY_SPLASH) ||
    (!isEmployee(user) && !isManager(user) && (
      // Redirect non-Employee HR Personas to HRBP UI
      (hasHRBP(user) && PATH_HR) ||
      // Redirect non-Employee non-HRBP non-Manager Admins to In-Demand Skills Editor
      (isAdmin(user) && (HAS_ADMIN_UI ? PATH_ADMIN : PATH_ADMIN_INDEMAND_SKILLS)) ||
      PATH_MY_SPLASH
    )) || '', [HAS_ADMIN_UI, hasNoAccess]);

  /**
   * Redirection path for non-Manager trying to open Manager UI screen
   */
  const getNonSupervisorPath = useCallback((user: User): string =>
    // Redirect users with no access permissions to the Splash screen
    (hasNoAccess(user) && PATH_MY_SPLASH) ||
    // Redirect non-Managers to their default path
    (!isManager(user) && (
      (isEmployee(user) && getEmployeePath(user)) ||
      (hasHRBP(user) && PATH_HR) ||
      (isAdmin(user) && (HAS_ADMIN_UI ? PATH_ADMIN : PATH_ADMIN_INDEMAND_SKILLS)) ||
      PATH_MY_SPLASH
    )) || '', [HAS_ADMIN_UI, getEmployeePath, hasNoAccess]);

  /**
   * Redirection path for non-HR trying to open HRBP UI screen
   */
  const getNonHRPath = useCallback((user: User): string =>
    // Redirect users with no access permissions to the Splash screen
    (hasNoAccess(user) && PATH_MY_SPLASH) ||
    // Redirect non-HRBPs to their default path
    (!hasHRBP(user) && (
      (isEmployee(user) && getEmployeePath(user)) ||
      (isManager(user) && PATH_SUPERVISOR) ||
      (isAdmin(user) && (HAS_ADMIN_UI ? PATH_ADMIN : PATH_ADMIN_INDEMAND_SKILLS)) ||
      PATH_MY_SPLASH
    )) || '', [HAS_ADMIN_UI, getEmployeePath, hasNoAccess]);

  /**
   * Redirection path for non-Admin trying to open Admin UI screen
   */
  const getNonAdminPath = useCallback((user: User): string =>
    // Redirect users with no access permissions to the Splash screen
    (hasNoAccess(user) && PATH_MY_SPLASH) ||
    // Redirect non-Managers to their default path
    (!isAdmin(user) && (
      (isEmployee(user) && getEmployeePath(user)) ||
      (hasHRBP(user) && PATH_HR) ||
      (isManager(user) && PATH_SUPERVISOR) ||
      PATH_MY_SPLASH
    )) || '', [getEmployeePath, hasNoAccess]);

  /**
   * Redirection path for non-Manager non-HR trying to open shared HR/Manager UI screen
   */
  const getNonSupervisorNonHRPath = useCallback((user: User): string =>
    // Redirect users with no access permissions to the Splash screen
    (hasNoAccess(user) && PATH_MY_SPLASH) ||
    // Redirect non-Manager non-HRBPs to their default path
    (!hasHRBP(user) && !isManager(user) && (
      (isEmployee(user) && getEmployeePath(user)) ||
      (isAdmin(user) && (HAS_ADMIN_UI ? PATH_ADMIN : PATH_ADMIN_INDEMAND_SKILLS)) ||
      PATH_MY_SPLASH
    )) || '', [HAS_ADMIN_UI, getEmployeePath, hasNoAccess]);

  return useMemo(() => ({
    getHomePath,
    getDefaultPath,
    getRedirectionPath,
    getNonEmployeePath,
    getNonEmployeeNonLeaderPath,
    getNonEmployeeNonManagerPath,
    getNonSupervisorPath,
    getNonHRPath,
    getNonAdminPath,
    getNonSupervisorNonHRPath
  }), [
    getDefaultPath, getHomePath, getNonAdminPath, getNonEmployeeNonLeaderPath, getNonEmployeeNonManagerPath,
    getNonEmployeePath, getNonHRPath, getNonSupervisorNonHRPath, getNonSupervisorPath, getRedirectionPath
  ]);
}

export default useRedirects;
