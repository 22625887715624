// TruncatedTextLink
import {
  forwardRef, memo, useState, useCallback, useMemo,
  type ForwardedRef, type MouseEventHandler, type ReactNode
} from 'react';
import PropTypes, { type Validator } from 'prop-types';
import { type TruncateOptions } from 'lodash';
import round from 'lodash/round';
import truncate from 'lodash/truncate';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
// Material UI imports
import { type Variant } from '@mui/material/styles/createTypography';
import Box from '@mui/material/Box';
// local imports
import { fontWeightBold, fontWeightMedium, fontWeightRegular } from '../styles/themeOptions';
import { MAX_DISPLAYED_TITLE_LENGTH } from '../config/params';
import { FormatMessageFuncValues, Values, ValuesPropType } from '../helpers/intl';
import BoxTypography from '../mixins/BoxTypography';
import StandardLink from '../elements/StandardLink';
import SimpleTooltip from '../elements/SimpleTooltip';
// SCSS imports
import {
  row, subtitle1Container, subtitle1Row, subtitle2Container, subtitle2Row,
  h5Container, h5Row, h6Container, h6Row, body1Container, body1Row, disabledText
} from './TruncatedTextLink.module.scss';

const TEXT_TRUNCATE_OPTIONS: TruncateOptions = {
  length: 3 * MAX_DISPLAYED_TITLE_LENGTH,
  separator: /\s+/u,
  omission: '…'
} as const;

export function getTruncatedText(title: string, length?: number): string {
  return truncate(title, length ? { ...TEXT_TRUNCATE_OPTIONS, length } : TEXT_TRUNCATE_OPTIONS);
}

export type TruncatedTextVariant = Exclude<Variant, 'h1' | 'h2' | 'h3' | 'h4' | 'caption' | 'button' | 'overline'>;

const titleContainer: Record<TruncatedTextVariant, string> = {
  subtitle1: subtitle1Container,
  subtitle2: subtitle2Container,
  h5: h5Container,
  h6: h6Container,
  body1: body1Container,
  body2: h6Container
} as const;

const titleRow: Record<TruncatedTextVariant, string> = {
  subtitle1: subtitle1Row,
  subtitle2: subtitle2Row,
  h5: h5Row,
  h6: h6Row,
  body1: body1Row,
  body2: h6Row
} as const;

type TruncatedTextLinkProps = {
  text?: string | null;
  values?: Values;
  suffix?: ReactNode | ReactNode[];
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  length?: number;
  plain?: boolean;
  bold?: boolean;
  dark?: boolean;
  active?: boolean;
  inherit?: boolean;
  variant?: TruncatedTextVariant | 'h1' | 'h2' | 'h3' | 'h4' | 'inherit';
  maxLines?: number;
  color?: string;
  regular?: boolean;
  to?: string;
  toState?: object;
  href?: string | null;
  withoutTarget?: boolean;
  link?: string | null;
  onClick?: MouseEventHandler<HTMLAnchorElement> & MouseEventHandler<HTMLSpanElement>;
  disabled?: boolean;
  withoutTooltip?: boolean;
  className?: string;
};

const TruncatedTextLinkPropTypes = {
  // attributes
  text: PropTypes.string, // link text translated with React Intl
  values: ValuesPropType, // values for link text translation with React Intl
  suffix: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  length: PropTypes.number, // length of truncated text
  plain: PropTypes.bool,
  bold: PropTypes.bool,
  dark: PropTypes.bool,
  active: PropTypes.bool,
  inherit: PropTypes.bool,
  variant: PropTypes.string as Validator<TruncatedTextVariant | 'h1' | 'h2' | 'h3' | 'h4' | 'inherit'>,
  maxLines: PropTypes.number,
  color: PropTypes.string,
  regular: PropTypes.bool,
  to: PropTypes.string, // React Router link
  toState: PropTypes.object, // React Router link state
  href: PropTypes.string, // static href
  withoutTarget: PropTypes.bool, // do not add `target="_blank"` for static href
  link: PropTypes.string, // dynamic href translated with React Intl
  onClick: PropTypes.func, // click handler function
  disabled: PropTypes.bool,
  withoutTooltip: PropTypes.bool,
  className: PropTypes.string
};

// eslint-disable-next-line complexity
const TruncatedTextLink = forwardRef<HTMLElement | HTMLAnchorElement, TruncatedTextLinkProps>(({
  text,
  values,
  suffix,
  startIcon,
  endIcon,
  length,
  plain,
  bold,
  dark,
  active,
  inherit,
  variant,
  maxLines,
  color,
  regular,
  disabled,
  withoutTooltip,
  to,
  toState,
  href,
  withoutTarget,
  link,
  onClick,
  className,
  ...props
}, ref) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();

  const [open, setOpen] = useState(false);
  const handleTooltipOpen = useCallback(() => setOpen(true), []);
  const handleTooltipClose = useCallback(() => setOpen(false), []);

  const [translatedText, truncatedText, needsTootip] = useMemo(() => {
    const txt = text
      ? formatMessage({ id: text, defaultMessage: text }, values as FormatMessageFuncValues) as string
      : '';
    const truncated = text
      ? getTruncatedText(txt, length || (maxLines ? round(maxLines * MAX_DISPLAYED_TITLE_LENGTH) : undefined))
      : txt;
    return [
      suffix ? (
        <>
          {txt}
          {suffix}
        </>
      ) : txt,
      truncated,
      !withoutTooltip && !disabled && txt !== truncated
    ];
  }, [text, values, suffix, disabled, length, maxLines, withoutTooltip, formatMessage]);

  const naked = Boolean(maxLines) ||
    variant === 'inherit' || variant === 'h1' || variant === 'h2' || variant === 'h3' || variant === 'h4';

  const textLink = plain ? (
    <BoxTypography
        ref={naked ? ref : undefined}
        variant={variant || 'subtitle2'}
        color={color || (dark ? 'secondary.text' : 'secondary.main')}
        fontWeight={(bold && fontWeightBold) || (regular && fontWeightRegular) || fontWeightMedium}
        className={clsx(
          naked ? row : titleRow[variant || 'subtitle2'],
          disabled ? disabledText : undefined,
          className
        )}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...naked ? props : {}}
    >
      {startIcon}
      {truncatedText}
      {suffix}
      {endIcon}
    </BoxTypography>
  ) : (
    <StandardLink
        ref={naked ? ref as ForwardedRef<HTMLAnchorElement> : undefined}
        variant={variant}
        bold={bold}
        dark={dark}
        active={active}
        inherit={inherit}
        to={disabled ? undefined : to}
        toState={disabled ? undefined : toState}
        href={disabled ? undefined : href}
        withoutTarget={withoutTarget}
        link={disabled ? undefined : link}
        onClick={disabled ? undefined : onClick}
        className={clsx(
          naked ? row : titleRow[variant || 'subtitle2'],
          className
        )}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...naked ? props : {}}
    >
      {startIcon}
      {truncatedText}
      {suffix}
      {endIcon}
    </StandardLink>
  );

  const content = naked ? textLink : (
    <Box
        ref={ref}
        display="flex"
        alignItems="center"
        className={titleContainer[variant || 'subtitle2']}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
    >
      {textLink}
    </Box>
  );

  return needsTootip ? (
    <SimpleTooltip
        open={open}
        onOpen={handleTooltipOpen}
        onClose={handleTooltipClose}
        disableInteractive
        disableFocusListener
        placement="top"
        title={translatedText}
    >
      {content}
    </SimpleTooltip>
  ) : content;
});

TruncatedTextLink.displayName = 'TruncatedTextLink';

TruncatedTextLink.propTypes = TruncatedTextLinkPropTypes;

export default memo(TruncatedTextLink);
