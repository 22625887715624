import { gql } from '@apollo/client';
// local imports
import { API_ADMIN_REPORT } from '../config/api';

export const ADMIN_JOB_TRENDS_QUERY = gql`
  query ARJobTrends(
    $report_id: Int!
    $offset: Int
    $limit: Int
  ) {
    arJobTrends(
      report_id: $report_id,
      offset: $offset,
      limit: $limit
    )
    @rest(
      type: "ARJobTrendsPayload",
      path: "${API_ADMIN_REPORT}?limit={args.limit}&offset={args.offset}"
    ) {
      count
      results {
        job_code
        job_title
        close_match_employees
        employees
        hires
      }
    }
  }
`;
