import map from 'lodash/map';
import join from 'lodash/join';
import isArray from 'lodash/isArray';
import replace from 'lodash/replace';
import { type RestLink } from 'apollo-link-rest';

export const pathBuilder = ({ '@rest': { path }, args }: RestLink.PathBuilderProps) => {
  const { input } = args;
  const patchedPath = replace(path, /\{args\.(\w+)\}/gu, (_match, p1) => encodeURIComponent(args[p1] || '0'));
  return input
    ? `${patchedPath}?${join(map(input, (value, key) => `${key}=${
        isArray(value) ? join(value, ',') : encodeURIComponent(value)
      }`), '&')}`
    : patchedPath;
};
