import { forwardRef, memo, type Ref, type MouseEventHandler } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
// Material Icon imports
import AddBoxIcon from '@mui/icons-material/AddBox';
// SCSS imports
import { button, icon } from './PlusButton.module.scss';

type PlusButtonProps = {
  label: string;
  disabled?: boolean;
  pending?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  link?: string;
};

const PlusButtonPropTypes = {
  // attributes
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  pending: PropTypes.bool,
  onClick: PropTypes.func as Validator<MouseEventHandler<HTMLButtonElement>>,
  link: PropTypes.string
};

const PlusButton = forwardRef<HTMLAnchorElement | HTMLButtonElement, PlusButtonProps>(({
  label,
  disabled = false,
  pending = false,
  onClick,
  link
}, ref) => link ? (
  <Button
      ref={ref as Ref<HTMLAnchorElement>}
      color="secondary"
      disabled={disabled || pending}
      className={button}
      startIcon={pending ? (
        <CircularProgress color="inherit" size="2.4rem"/>
      ) : (
        <AddBoxIcon
            className={icon}
            color={disabled ? undefined : 'primary'}
        />
      )}
      component={RouterLink}
      to={link}
  >
    <FormattedMessage id={label} defaultMessage={label}/>
  </Button>
) : (
  <Button
      ref={ref as Ref<HTMLButtonElement>}
      color="secondary"
      disabled={disabled || pending}
      className={button}
      startIcon={pending ? (
        <CircularProgress color="inherit" size="2.4rem"/>
      ) : (
        <AddBoxIcon
            className={icon}
            color={disabled ? undefined : 'primary'}
        />
      )}
      onClick={onClick}
  >
    <FormattedMessage id={label} defaultMessage={label}/>
  </Button>
));

PlusButton.displayName = 'PlusButton';

PlusButton.propTypes = PlusButtonPropTypes;

export default memo(PlusButton);
