// Team Builder Best Matches Sorting

export const BEST_MATCHES_SORT_NAME = 'last_name' as const;
export const BEST_MATCHES_SORT_LEVEL = 'job_level' as const;
export const BEST_MATCHES_SORT_LOCATION = 'location' as const;
export const BEST_MATCHES_SORT_ORG = 'org' as const;
export const BEST_MATCHES_SORT_UNDEFINED = 'undefined' as const;

export const BEST_MATCHES_SORTS = [
  BEST_MATCHES_SORT_UNDEFINED, BEST_MATCHES_SORT_NAME,
  BEST_MATCHES_SORT_LEVEL, BEST_MATCHES_SORT_LOCATION, BEST_MATCHES_SORT_ORG
] as const;

export type BestMatchesSort = typeof BEST_MATCHES_SORTS[number];

const VALID_SORT: Record<string, boolean> = {
  [BEST_MATCHES_SORT_NAME]: true,
  [BEST_MATCHES_SORT_LEVEL]: true,
  [BEST_MATCHES_SORT_LOCATION]: true,
  [BEST_MATCHES_SORT_ORG]: true,
  [BEST_MATCHES_SORT_UNDEFINED]: true
};

const VALID_SORT_PARAMS: Record<string, boolean> = {
  [BEST_MATCHES_SORT_NAME]: true,
  [BEST_MATCHES_SORT_LEVEL]: true,
  [BEST_MATCHES_SORT_LOCATION]: true,
  [BEST_MATCHES_SORT_ORG]: true
};

export const isValidSortBy = (sort?: string) => Boolean(sort && VALID_SORT[sort]);
export const isValidSortParam = (sort?: string) => Boolean(sort && VALID_SORT_PARAMS[sort]);
