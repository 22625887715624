import { gql } from '@apollo/client';
// local imports
import { API_COUNTRIES } from '../config/api';

export const COUNTRIES_QUERY = gql`
  query Countries(
    $local: Boolean
    $limit: Int
  ) {
    countries(
      local: $local,
      limit: $limit
    )
    @rest(
      type: "LookupPayload",
      path: "${API_COUNTRIES}?{args}"
    ) {
      count
      results {
        id
        title
      }
    }
  }
`;
