import { memo, useState, useEffect, useCallback, type FunctionComponent, type SyntheticEvent } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Typography from '@mui/material/Typography';
import Snackbar, { type SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
// local imports
import { SNACKBAR_AUTOHIDE_DURATION, SNACKBAR_ELEVATION, anchorOrigin } from '../config/params';
import { type Values, ValuesPropType } from '../helpers/intl';

type ActionFailedAlertProps = {
  message: string;
  values?: Values;
  long?: boolean;
  open?: boolean | null;
  onDismiss?: () => void;
};

const ActionFailedAlertPropTypes = {
  // attributes
  message: PropTypes.string.isRequired,
  values: ValuesPropType,
  long: PropTypes.bool,
  open: PropTypes.bool,
  onDismiss: PropTypes.func
};

const ActionFailedAlert: FunctionComponent<ActionFailedAlertProps> = ({
  message,
  values,
  long = false,
  open = false,
  onDismiss
}) => {

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    if (open) setSnackbarOpen(true);
  }, [open]);

  const handleSnackbarClose = useCallback((_event: Event | SyntheticEvent, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
    onDismiss?.();
  }, [onDismiss]);

  const alert = (
    <Alert onClose={handleSnackbarClose} severity="error" variant="filled" elevation={SNACKBAR_ELEVATION}>
      <Typography variant="body1">
        <FormattedMessage id={message} values={values} defaultMessage={message}/>
      </Typography>
    </Alert>
  );

  return (typeof jest === 'undefined' && (
    <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        autoHideDuration={long ? 2 * SNACKBAR_AUTOHIDE_DURATION : SNACKBAR_AUTOHIDE_DURATION}
        anchorOrigin={anchorOrigin}
    >
      {alert}
    </Snackbar>
  )) || (snackbarOpen ? alert : null);
};

ActionFailedAlert.propTypes = ActionFailedAlertPropTypes;

export default memo(ActionFailedAlert);
