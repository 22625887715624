export enum NotificationKind {
  job_targeted = 'job_targeted',
  skill_targeted = 'skill_targeted',
  new_inferred_skills = 'new_inferred_skills',
  new_suggested_skills = 'new_suggested_skills',
  new_suggested_skill_by_manager = 'new_suggested_skill_by_manager',
  new_job_matches = 'new_job_matches',
  new_open_reqs_for_target_job = 'new_open_reqs_for_target_job',
  delegation_created = 'delegation_created', // created by Leader himself
  delegation_updated = 'delegation_updated', // created by HRBP
  accept_mentorship = 'accept_mentorship',
  new_advisee = 'new_advisee'
}

export enum NotificationUIPart {
  employees = 'employees',
  supervisor = 'supervisor'
}

export enum NotificationObjectType {
  job = 'job',
  skill = 'skill',
  leader = 'leader'
}

export enum NotificationTarget {
  targeted = 'targeted',
  untargeted = 'untargeted',
  skill = 'skill',
  job = 'job',
  req = 'req',
  delegation = 'delegation',
  mentorship = 'mentorship'
}

export interface INotificationObject {
  id: number;
  code: string;
  title: string;
  email?: string;
}

export interface INotificationDetails {
  part_of_ui: NotificationUIPart;
  object_type: NotificationObjectType;
  target: NotificationTarget;
}

export interface NotificationJobTargeted extends INotificationDetails {
  part_of_ui: NotificationUIPart.employees;
  object_type: NotificationObjectType.job;
  target: NotificationTarget.targeted | NotificationTarget.untargeted;
  // job_targeted
  job_code: string;
  object: INotificationObject;
}

export interface NotificationSkillTargeted extends INotificationDetails {
  part_of_ui: NotificationUIPart.employees;
  object_type: NotificationObjectType.skill;
  target: NotificationTarget.targeted | NotificationTarget.untargeted;
  // skill_targeted
  abbr: string;
  title: string;
  object: INotificationObject;
}

export interface NotificationNewSkills extends INotificationDetails {
  part_of_ui: NotificationUIPart.employees;
  object_type: NotificationObjectType.skill;
  target: NotificationTarget.skill;
  // new_inferred_skills, new_suggested_skills
  skill_ids: number[];
  objects: INotificationObject[];
}

export interface NotificationNewSkillsByManager extends INotificationDetails {
  part_of_ui: NotificationUIPart.employees;
  object_type: NotificationObjectType.skill;
  target: NotificationTarget.skill;
  // new_suggested_skill_by_manager
  skill_ids: number[];
  object: INotificationObject;
}

export interface NotificationNewJobMatches extends INotificationDetails {
  part_of_ui: NotificationUIPart.employees;
  object_type: NotificationObjectType.job;
  target: NotificationTarget.job;
  object: {};
}

export interface NotificationNewOpenReqs extends INotificationDetails {
  part_of_ui: NotificationUIPart.employees;
  object_type: NotificationObjectType.job;
  target: NotificationTarget.req;
  // new_open_reqs_for_target_job
  job_id: number;
  object: INotificationObject;
}

export interface NotificationNewDelegation extends INotificationDetails {
  part_of_ui: NotificationUIPart.supervisor;
  object_type: NotificationObjectType.leader;
  target: NotificationTarget.delegation;
  // delegation_created
  leader_id: string;
  object: INotificationObject;
}

export interface NotificationDelegationUpdate extends INotificationDetails {
  part_of_ui: NotificationUIPart.supervisor;
  object_type: NotificationObjectType.leader;
  target: NotificationTarget.delegation;
  // delegation_updated
  leader_id: string;
  hrbp_id: string;
  hrbp_title: string;
  hrbp_email?: string;
  object: INotificationObject;
}

export interface NotoficationAcceptMentorship extends INotificationDetails {
  target: NotificationTarget;
  mentor_id: string;
  mentor_title: string;
  mentor_email?: string;
  object: INotificationObject;
}

export interface NotoficationNewAdvisee extends INotificationDetails {
  target: NotificationTarget;
  advisee_id: string;
  advisee_title: string;
  advisee_email?: string;
  object: INotificationObject;
}

export type NotificationDetails = NotificationJobTargeted | NotificationSkillTargeted | NotificationNewSkills |
  NotificationNewJobMatches | NotificationNewSkillsByManager | NotificationNewOpenReqs |
  NotificationNewDelegation | NotificationDelegationUpdate | NotoficationAcceptMentorship | NotoficationNewAdvisee;

export interface INotification<T extends NotificationKind = NotificationKind> {
  id: number;
  kind: T;
  description: string;
  created_at: string;
  details: (
    T extends NotificationKind.job_targeted ? NotificationJobTargeted
    : T extends NotificationKind.skill_targeted ? NotificationSkillTargeted
    : T extends NotificationKind.new_inferred_skills | NotificationKind.new_suggested_skills ? NotificationNewSkills
    : T extends NotificationKind.new_suggested_skill_by_manager ? NotificationNewSkillsByManager
    : T extends NotificationKind.new_job_matches ? NotificationNewJobMatches
    : T extends NotificationKind.new_open_reqs_for_target_job ? NotificationNewOpenReqs
    : T extends NotificationKind.delegation_created ? NotificationNewDelegation
    : T extends NotificationKind.delegation_updated ? NotificationDelegationUpdate
    : T extends NotificationKind.accept_mentorship ? NotoficationAcceptMentorship
    : T extends NotificationKind.new_advisee ? NotoficationNewAdvisee
    : NotificationDetails
  );
}
