import { forwardRef, memo, useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { useIntl } from 'react-intl';
// Material Icon imports
import HomeIcon from '@mui/icons-material/HomeRounded';
// EmPath UI Components
import AppDrawer from '@empathco/ui-components/src/elements/AppDrawer';
// local imports
import { isContractor, isEmployee, hasHRBP, isManager, isLeader, isAdmin } from '../models/user';
import usePathConfig, { CustomizedDrawerItem, HOME_ICON_ID } from '../config/paths';
import useRedirects from '../helpers/redirect';
import { GlobalContext } from '../context/global';

const homeIconSx = { ml: -0.375 };

type CustomizedDrawerProps = {
  items?: CustomizedDrawerItem[];
  path?: string;
  open?: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const CustomizedDrawerPropTypes = {
  // attributes
  items: PropTypes.array,
  path: PropTypes.string,
  open: PropTypes.bool,
  onOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

const CustomizedDrawer = forwardRef<HTMLDivElement, CustomizedDrawerProps>(({
  items: parentItems,
  path = '',
  open,
  onOpen,
  onClose
}, ref) => {
  const { getHomePath } = useRedirects();
  const { DRAWER } = usePathConfig();
  const items = parentItems || DRAWER;

  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();

  const { user: { data: user } } = useContext(GlobalContext);
  const homePath = getHomePath(user);
  const isContractr = isContractor(user);
  const isEmpl = isEmployee(user);
  const isSupervisor = isManager(user);
  const isLead = isLeader(user);
  const isAdm = isAdmin(user);
  const hasHR = hasHRBP(user);

  // eslint-disable-next-line complexity
  const isItemVisible = useCallback(({ visibleTo, invisibleTo }: CustomizedDrawerItem) => Boolean(
    (
      !visibleTo ||
      (visibleTo?.contractor ? isContractr : false) ||
      (visibleTo?.employee ? isEmpl : false) ||
      (visibleTo?.leader ? isLead : false) ||
      (visibleTo?.supervisor ? isSupervisor : false) ||
      (visibleTo?.hrbp ? hasHR : false) ||
      (visibleTo?.admin ? isAdm : false)
    ) &&
    (
      !invisibleTo ||
      (invisibleTo?.contractor ? !isContractr : false) ||
      (invisibleTo?.employee ? !isEmpl : false) ||
      (invisibleTo?.leader ? !isLead : false) ||
      (invisibleTo?.supervisor ? !isSupervisor : false) ||
      (invisibleTo?.hrbp ? !hasHR : false) ||
      (invisibleTo?.admin ? !isAdm : false)
    )
  ), [hasHR, isContractr, isEmpl, isSupervisor, isLead, isAdm]);

  const itemsWithHome = useMemo(() => map(items, (item) => item.icon === HOME_ICON_ID ? {
    ...item,
    icon: (
      <HomeIcon
          color="inherit"
          sx={homeIconSx}
          aria-label={formatMessage({ id: 'drawer.home' })}
      />
    )
  } : item), [items, formatMessage]);

  return (
    <AppDrawer
        ref={ref}
        path={path}
        defaultLink={homePath}
        open={Boolean(open)}
        onOpen={onOpen}
        onClose={onClose}
        items={itemsWithHome}
        isItemVisible={isItemVisible}
    />
  );
});

CustomizedDrawer.displayName = 'CustomizedDrawer';

CustomizedDrawer.propTypes = CustomizedDrawerPropTypes;

export default memo(CustomizedDrawer);
