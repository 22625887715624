import size from 'lodash/size';

export function checkPasswordStrength(password?: string) {
  // Initialize variables
  const result = {
    strength: 0,
    tips: [] as string[]
  }

  if (!password) return result;

  // Check password length
  if (size(password) < 8) {
    result.tips.push('splash.set_password.length');
  } else if (password) {
    result.strength += 1;
  }

  // Check for mixed case
  if (password && password.match(/[a-z]/u) && password.match(/[A-Z]/u)) {
    result.strength += 1;
  } else {
    result.tips.push('splash.set_password.mixcase');
  }

  // Check for numbers
  if (password.match(/\d/u)) {
    result.strength += 1;
  } else {
    result.tips.push('splash.set_password.number');
  }

  // Check for special characters
  if (password.match(/[^a-zA-Z\d]/u)) {
    result.strength += 1;
  } else {
    result.tips.push('splash.set_password.special');
  }

  return result;
}
