import { type Dispatch } from 'react';
import isSafeInteger from 'lodash/isSafeInteger';
import { type AxiosResponse } from 'axios';
// local imports
import { APIActionResponse } from '../models/apiResponse';
import { axiosInstance as axios, API_DELEGATES_ADD, API_DELEGATES_REMOVE } from '../config/api';
import { getRequestHeaders, optimizeParams } from '../helpers/context';
import { Token, Online } from './global';
import { SupervisorActions, DelegatesActionParams } from './supervisor';

export const delegationAction = (
  token: Token,
  operation: 'ADD' | 'REMOV',
  online: Online,
  dispatch: Dispatch<SupervisorActions>,
  actionPending: boolean | null
) => async ({
  from_id,
  to_id,
  onSuccess
}: DelegatesActionParams = {} as DelegatesActionParams) => {
  if (
    !token || actionPending ||
    !from_id || !isSafeInteger(from_id) || from_id < 1 ||
    !to_id || !isSafeInteger(to_id) || to_id < 1
  ) return;
  const params = optimizeParams({ from_id, to_id }, online);
  try {
    if (!online) throw new Error();
    dispatch({
      type: `DELEGATE_${operation}ING` as const,
      params
    });
    const { status, data } = await axios.request<DelegatesActionParams, AxiosResponse<APIActionResponse>>({
      method: 'POST',
      url: operation === 'ADD' ? API_DELEGATES_ADD : API_DELEGATES_REMOVE,
      data: { from_id, to_id },
      headers: getRequestHeaders(token)
    }) || {};
    if (status < 200 || status > 201 || !data || !data.success) throw new Error();
    onSuccess?.();
    dispatch({
      type: `DELEGATE_${operation}ED` as const,
      payload: true,
      params
    });
  } catch (error) {
    dispatch({
      type: `DELEGATE_${operation}ED` as const,
      payload: false,
      params
    });
  }
};
