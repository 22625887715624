import { gql } from '@apollo/client';
// local imports
import { API_STATES } from '../config/api';

export const STATES_QUERY = gql`
  query States(
    $country_id: Int!
    $limit: Int
  ) {
    states(
      country_id: $country_id,
      limit: $limit
    )
    @rest(
      type: "StatesPayload",
      path: "${API_STATES}?{args}"
    ) {
      count
      results {
        id
        title
        country {
          id
          title
        }
      }
    }
  }
`;
