import { gql } from '@apollo/client';
// local imports
import { API_TALENT_FINDER_SEARCH } from '../config/api';

export const EMPLOYEES_SEARCH_QUERY = gql`
  query EmployeesSearch(
    $search: String!
    $limit: Int
  ) {
    employeesSearch(
      search: $search,
      limit: $limit
    )
    @rest(
      type: "EmployeesSearchPayload",
      path: "${API_TALENT_FINDER_SEARCH}?{args}"
    ) {
      results {
        id
        code
        first_name
        last_name
        email
        title @client
      }
    }
  }
`;
