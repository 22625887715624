import { gql } from '@apollo/client';
// local imports
import { API_COHORTS } from '../config/api';

export const COHORTS_QUERY = gql`
  query Cohorts(
    $limit: Int
    $offset: Int
  ) {
    cohorts(
      limit: $limit,
      offset: $offset
    )
    @rest(
      type: "CohortsPayload",
      path: "${API_COHORTS}?{args}"
    ) {
      count
      results {
        id
        title
        created_at
        updated_at
        employees_count
        owner {
          id
          code
          first_name
          last_name
          email
        }
        dev_plan {
          id
          title
        }
      }
    }
  }
`;
