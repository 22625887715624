import { gql } from '@apollo/client';
// local imports
import { API_MY_OPPORTUNITY_OWNERS } from '../config/api';

export const OPPORTUNITY_OWNERS_QUERY = gql`
  query OpportunityOwners(
    $offset: Int
    $limit: Int
  ) {
    opportunityOwners(
      offset: $offset,
      limit: $limit
    )
    @rest(
      type: "OpportunityOwnersPayload",
      path: "${API_MY_OPPORTUNITY_OWNERS}?{args}"
    ) {
      results {
        id
        code
        first_name
        last_name
        email
        title @client
      }
    }
  }
`;
