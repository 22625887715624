import { gql } from '@apollo/client';
// local imports
import { API_EMPLOYEE_DEV_PLAN } from '../config/api';

export const EMPLOYEE_DEV_PLAN_ACTIVITIES_QUERY = gql`
  query EmployeeDevPlanActivities(
    $devplan_id: Int!
    $selected_employee_id: String
  ) {
    employeeDevplanActivities(
      devplan_id: $devplan_id,
      selected_employee_id: $selected_employee_id
    )
    @rest(
      type: "SkillActivitiesPayload",
      path: "${API_EMPLOYEE_DEV_PLAN}activities/?{args}"
    ) {
      count
      results {
        id
        employee_activity_id
        code
        name
        activity_type
        description
        activity_link
        development_area
        field_support
        store
        education_style
        is_selected
        is_complete
        completed_at
        owner {
          id
          code
          first_name
          last_name
          email
        }
        skills {
          id
          abbr
          title
          skill_proficiency_level
        }
      }
    }
  }
`;
