import PropTypes from 'prop-types';

const AUTH_ERROR_CODES = [
  // 'user_bad_cookie', // this just means that SSO login failed, so the default error message is fine
  'user_not_in_employee_list',
  'user_sso_forbidden',
  'user_organization_forbidden'
] as const;

export type AuthErrorCode = typeof AUTH_ERROR_CODES[number];

export const AuthErrorCodeProp = PropTypes.oneOf(AUTH_ERROR_CODES);

export const isValidAuthErrorCode = (errorCode?: string | null): boolean => Boolean(errorCode) &&
  AUTH_ERROR_CODES.indexOf(errorCode as AuthErrorCode) >= 0;
