import { memo, useContext, useEffect, useState, useCallback, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import size from 'lodash/size';
import trim from 'lodash/trim';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import { useLazyQuery } from '@apollo/client';
// Material UI imports
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
// Material Icon imports
import RemoveIcon from '@mui/icons-material/Remove';
// EmPath UI Components
import { paramsDiffer } from '@empathco/ui-components/src/helpers/pagination';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import LevelSelector from '@empathco/ui-components/src/elements/LevelSelector';
import FilterSearch from '@empathco/ui-components/src/elements/FilterSearch';
// local imports
import { ADMIN_EMPLOYEES_QUERY } from '../graphql/AdminEmployees';
import {
  AdminEmployee, AdminEmployeesDocument, AdminEmployeesQueryVariables, AdminEmployeesSort, AdminJob
} from '../graphql/types';
import { TalentEmployeeObject, ADMIN_EMPLOYEES_SORT, DEFAULT_ADMIN_EMPLOYEES_DIRECTION } from '../graphql/customTypes';
import { Job } from '../models/job';
// import { EmployeeSortExt } from '../constants/employeeSort';
import useCustomerSettings from '../config/customer';
import useFilters, { FilterValues } from '../hooks/useFilters';
import useTableSort from '../hooks/useTableSort';
import { DataContext } from '../context';
import PaginationControls, { hasPagination, PaginationControlsComponent } from '../v3/PaginationControls';
import EmployeesTable from '../v3/EmployeesTable';
import DashboardFilters from '../v3/DashboardFilters';
import AdminEmployeeDialog from '../widgets/AdminEmployeeDialog';
import AdminJobDialog from '../widgets/AdminJobDialog';
// SCSS imports
import { overlayDefault } from '@empathco/ui-components/src/styles/modules/Overlay.module.scss';

type AdminEmployeesProps = {
  settingsId?: string;
  // for Storybook only
  testPending?: boolean;
}

const AdminEmployeesPropTypes = {
  settingsId: PropTypes.string,
  testPending: PropTypes.bool
};

// eslint-disable-next-line max-statements, complexity, max-lines-per-function
const AdminEmployees: FunctionComponent<AdminEmployeesProps> = ({
  settingsId,
  testPending
}) => {
  const { defaultFilters, updateSearchParams } = useFilters(ADMIN_EMPLOYEES_SORT);
  const defaultPage = defaultFilters?.current_page;

  const { HAS_INDEMAND_SKILLS, MANAGEMENT_LEVEL_FIRST, MANAGEMENT_LEVEL_LAST } = useCustomerSettings();

  const { sort, dir, canResetSort, handleSort, handleSortReset } = useTableSort({
    settingsId,
    sortValues: ADMIN_EMPLOYEES_SORT,
    defaultDirections: DEFAULT_ADMIN_EMPLOYEES_DIRECTION,
    defaultSortBy: defaultFilters?.sort_by as AdminEmployeesSort,
    defaultDirection: defaultFilters?.direction
  });

  const {
    settings: { data: settingsData, pending: pendingSettings, failed: failedSettings },
    settingsUpdate: { pending: pendingSettingsUpdate }
  } = useContext(DataContext);
  const settingsLoaded = !settingsId || (pendingSettings === false && failedSettings === false && Boolean(settingsData));

  // lazy load employees
  const {
    query: getEmployees, pending: pendingEmployees, failed, count, results: employees, variables: prevVars
  } = useQueryCounted({
    data: undefined as unknown as AdminEmployee,
    key: 'adminEmployees',
    lazyQuery: useLazyQuery(ADMIN_EMPLOYEES_QUERY as typeof AdminEmployeesDocument)
  });
  const pending = pendingEmployees || testPending;

  const [filters, setFilters] = useState(defaultFilters);
  const [search, setSearch] = useState(defaultFilters?.search || '');
  const [level, setLevel] = useState(isNil(defaultFilters?.management_level)
    ? MANAGEMENT_LEVEL_FIRST - 1 : defaultFilters.management_level);
  const [levelMax, setLevelMax] = useState(isNil(defaultFilters?.management_level_max)
    ? MANAGEMENT_LEVEL_FIRST - 1 : defaultFilters.management_level_max);
  const [currentPage, setCurrentPage] = useState(defaultPage || 1);
  const [pageSize, setPageSize] = useState(defaultFilters?.page_size || undefined);

  const [dialogKey, setDialogKey] = useState(0);
  const [employee, setEmployee] = useState<AdminEmployee>();
  const [jobEmployee, setJobEmployee] = useState<AdminEmployee>();

  const minLevelMax = level >= MANAGEMENT_LEVEL_LAST ? MANAGEMENT_LEVEL_LAST : level + 1;
  const levelMaxValue = level < MANAGEMENT_LEVEL_FIRST || level >= MANAGEMENT_LEVEL_LAST || levelMax < level
    ? minLevelMax - 1 : levelMax;

  const handleLevel = useCallback((val: number) => {
    setLevel(val);
    if (val < MANAGEMENT_LEVEL_FIRST || val >= MANAGEMENT_LEVEL_LAST || levelMax <= val) {
      setLevelMax(MANAGEMENT_LEVEL_FIRST - 1);
    }
  }, [levelMax, MANAGEMENT_LEVEL_FIRST, MANAGEMENT_LEVEL_LAST]);

  const handleFiltersReset = useCallback(() => {
    setSearch('');
    setLevel(MANAGEMENT_LEVEL_FIRST - 1);
    setLevelMax(MANAGEMENT_LEVEL_FIRST - 1);
    handleSortReset();
  }, [handleSortReset, MANAGEMENT_LEVEL_FIRST]);

  const handleClick = useCallback((empl?: TalentEmployeeObject | AdminEmployee) => {
    setEmployee(empl as AdminEmployee);
  }, []);

  const handleClose = useCallback(() => {
    setDialogKey((prev) => prev + 1);
    setEmployee(undefined);
  }, []);

  const handleJobClick = useCallback((_code: string, _job?: Job | AdminJob, empl?: TalentEmployeeObject | AdminEmployee) => {
    if (empl?.current_job?.code) setJobEmployee(empl as AdminEmployee);
  }, []);

  const handleJobClose = useCallback(() => {
    setJobEmployee(undefined);
  }, []);
  
  useEffect(() => {
    if (settingsLoaded && filters && getEmployees && !isNil(pageSize)) {
      const minLevel = level < MANAGEMENT_LEVEL_FIRST ? null : level;
      const maxLevel = levelMax <= level ? null : levelMax;
      const filterValues: FilterValues = {
        search: trim(search) || null,
        manager_id: filters.manager_id || null,
        org_id: filters.org_id || null,
        country_id: filters.country_id || null,
        state_id: filters.state_id || null,
        ...isNil(minLevel) || isNil(maxLevel) ? { management_level: minLevel } : {},
        ...!isNil(minLevel) && !isNil(maxLevel) ? {
          management_level_min: minLevel,
          management_level_max: maxLevel
        } : {},
        direction: dir
      };
      const variables: AdminEmployeesQueryVariables = {
        ...omitBy(filterValues, isNull),
        sort_by: sort, // typed as AdminEmployeesSort
        limit: pageSize
      };
      let curPage = currentPage;
      if (paramsDiffer(prevVars, variables) && (size(prevVars) >= 1 || !defaultPage)) {
        curPage = 1;
        setCurrentPage(1);
      }
      variables.offset = pageSize * (curPage - 1);
      getEmployees({ variables });
      updateSearchParams({
        ...filterValues,
        sort_by: sort, // typed as string
        current_page: curPage,
        page_size: pageSize
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // only filter values are monitored here (i.e. ignoring `prevVars` changes):
    filters, search, level, levelMax, sort, dir, pageSize, currentPage, getEmployees, settingsLoaded, defaultPage,
    updateSearchParams, MANAGEMENT_LEVEL_FIRST
  ]);

  const pendingAll = !settingsLoaded || !filters || pending;
  const loading = pendingAll || !employees;
  const failedAny = failed || (settingsId ? failedSettings : false);
  const disabled = loading || failedAny || (settingsId && pendingSettingsUpdate) ? true : undefined;

  const reloading = Boolean(pending && employees);

  const pagination = (
    <PaginationControls
        init={defaultFilters}
        settingsId={settingsId}
        loaded={Boolean(employees)}
        pending={pending}
        loading={pendingAll}
        total={count}
        currentPage={currentPage}
        onPageSelected={setCurrentPage}
        onPageSize={setPageSize}
        disabled={disabled || (settingsId ? pendingSettingsUpdate : undefined)}
    />
  );
  const withPagination = hasPagination(pagination as PaginationControlsComponent);

  const content = (
    <CardSection top={withPagination}>
      <EmployeesTable
          admin
          data={employees}
          pending={pending}
          failed={failedAny}
          sortBy={sort}
          direction={dir}
          changeSort={handleSort}
          onClick={handleClick}
          onJobClick={handleJobClick}
          disabled={disabled}
          withReloading
      />
    </CardSection>
  );

  return (
    <>
      <CardSection top>
        <DashboardFilters
            init={defaultFilters}
            settingsId={settingsId}
            withOrg={HAS_INDEMAND_SKILLS}
            requireOrg={false}
            orgFilterPlacement="simple"
            withReset
            onChange={setFilters}
            onReset={search || level >= MANAGEMENT_LEVEL_FIRST || canResetSort ? handleFiltersReset : undefined}
            uid="0"
            disabled={disabled}
            extraFilters={(
              <>
                <Box
                    pb={1}
                    pr={1.25}
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    flexWrap="nowrap"
                    color="text.secondary"
                >
                  <Box display="flex" justifyContent="flex-end">
                    <LevelSelector
                        variant="job"
                        search
                        minValue={MANAGEMENT_LEVEL_FIRST}
                        maxValue={MANAGEMENT_LEVEL_LAST}
                        value={level}
                        onChange={handleLevel}
                        disabled={disabled}
                    />
                  </Box>
                  <RemoveIcon color="inherit"/>
                  <Box display="flex" justifyContent="flex-end">
                    <LevelSelector
                        variant="job_max"
                        search
                        minValue={minLevelMax}
                        maxValue={MANAGEMENT_LEVEL_LAST}
                        value={levelMaxValue}
                        onChange={setLevelMax}
                        disabled={disabled || level < MANAGEMENT_LEVEL_FIRST || level >= MANAGEMENT_LEVEL_LAST}
                    />
                  </Box>
                </Box>
                <Box pb={1} display="flex" justifyContent="flex-start">
                  <FilterSearch
                      type="employee"
                      value={search}
                      onChange={setSearch}
                      disabled={disabled}
                  />
                </Box>
              </>
            )}
        />
      </CardSection>
      {reloading ? (
        <Box
            flexGrow={1}
            display="flex"
            flexDirection="column"
            position="relative"
        >
          {content}
          <Box className={overlayDefault}>
            <LinearProgress/>
          </Box>
        </Box>
      ) : content}
      {withPagination ? (
        <CardSection flex>
          {pagination}
        </CardSection>
      ) : pagination}
      <AdminEmployeeDialog
          key={dialogKey}
          employee={employee}
          onJobClick={handleJobClick}
          onClose={handleClose}
      />
      <AdminJobDialog
          job={jobEmployee?.current_job as AdminJob}
          employee={jobEmployee}
          // TODO: onClick={???}
          onClose={handleJobClose}
      />
    </>
  );
};

AdminEmployees.propTypes = AdminEmployeesPropTypes;

export default memo(AdminEmployees);
