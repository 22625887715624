import { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
// EmPath UI Components
import type { BreadcrumbItem } from '@empathco/ui-components/src/types';
import AppBar, { type AppBarVariant, AppBarVariantProp } from '@empathco/ui-components/src/elements/AppBar';
import AppDrawer from '@empathco/ui-components/src/elements/AppDrawer';
import AppHeader from '@empathco/ui-components/src/elements/AppHeader';
// local imports
import CustomerLogo from '../customers/CustomerLogo';
import usePathConfig from '../config/paths';
import CustomizedAppBar from './CustomizedAppBar';
import CustomizedDrawer from './CustomizedDrawer';

type HeaderProps = {
  admin?: boolean;
  breadcrumbs?: BreadcrumbItem[] | null;
  variant?: AppBarVariant;
  // for Storybook only:
  testOpen?: boolean;
};

const HeaderPropTypes = {
  // attributes
  admin: PropTypes.bool,
  breadcrumbs: PropTypes.array,
  variant: AppBarVariantProp,
  // for Storybook only:
  testOpen: PropTypes.bool
};

const Header = forwardRef<HTMLDivElement, HeaderProps>(({
  breadcrumbs,
  variant = 'full',
  testOpen = false,
  ...props
}, ref) => {
  const { DRAWER, ROUTES } = usePathConfig();
  return (
    <AppHeader
        ref={ref}
        logo={<CustomerLogo/>}
        appbar={CustomizedAppBar as typeof AppBar}
        drawer={CustomizedDrawer as typeof AppDrawer}
        items={DRAWER}
        routes={ROUTES}
        breadcrumbs={breadcrumbs || undefined}
        variant={variant}
        testOpen={testOpen}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
    />
  );
});

Header.displayName = 'Header';

Header.propTypes = HeaderPropTypes;

export default memo(Header);
