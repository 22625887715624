export interface EChartsMouseEvent {
  componentType: string;
  value: string;
}

export const getSvgShadingImage = (fg: string, bg: string, width = 3) => {
  const image = new Image();
  image.src = `data:image/svg+xml;base64,${btoa(
    '<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">' +
    `<rect width="10" height="10" fill="${bg}"/>` +
    `<path d="M-1,1 l2,-2 M0,10 l10,-10 M9,11 l2,-2" stroke="${fg}" stroke-width="${width}"/>` +
    '</svg>'
  )}`;
  return image;
};
