import { memo, useContext, useEffect, useMemo, useState, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
// EmPath UI Components
import { getFullName } from '@empathco/ui-components/src/helpers/strings';
import ContentCard from '@empathco/ui-components/src/elements/ContentCard';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
// local imports
import { Leader } from '../graphql/types';
import { isHRBP } from '../models/user';
import { MAX_DELEGATES } from '../config/params';
import { GlobalContext } from '../context/global';
import { SupervisorContext } from '../context/supervisor';
import SelectDelegate from '../v3/SelectDelegate';
import DelegatesList from '../v3/DelegatesList';
// SCSS imports
import { padding } from './Delegation.module.scss';

type DelegationProps = {
  testLeader?: Leader;
}

const DelegationPropTypes = {
  // for Storybook only
  testLeader: PropTypes.object as Validator<Leader>
};

const Delegation: FunctionComponent<DelegationProps> = ({
  testLeader = null
}) => {
  const { user: { data: user } } = useContext(GlobalContext);
  const {
    delegatesList: { data: delegates, pending, failed }, requireDelegatesList
  } = useContext(SupervisorContext);

  const isHR = isHRBP(user);

  const defaultLeader = useMemo(() => isHR || !user ? testLeader : {
    id: user.id,
    code: user.code,
    title: getFullName(user.first_name, user.last_name, user.code),
    email: user.email
  } as Leader, [isHR, testLeader, user]);

  const [leader, setLeader] = useState<Leader | null>(defaultLeader);
  const { id: from_id } = leader || {};

  const exclude = useMemo(() => map(delegates, 'id'), [delegates]);

  useEffect(() => {
    if (from_id) requireDelegatesList?.({ from_id });
  }, [from_id, requireDelegatesList]);

  const disabled = Boolean(leader) && (pending || failed || !delegates);
  const count = size(delegates);

  return (
    <ContentCard className={disabled ? undefined : padding}>
      <CardTitle
          title="delegation.title"
          withDivider
      />
      <SelectDelegate
          hrbp={isHR}
          leader={leader}
          onChange={setLeader}
          exclude={exclude}
          disabled={disabled}
          delegationDisabled={count >= MAX_DELEGATES}
          withDivider={!pending && !failed && count >= 1 ? true : undefined}
      />
      {leader ? (
        <DelegatesList
            hrbp={isHR}
            leader={leader}
        />
      ) : undefined}
    </ContentCard>
  );
};

Delegation.propTypes = DelegationPropTypes;

export default memo(Delegation);
