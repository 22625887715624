import { memo, useMemo, useReducer, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import size from 'lodash/size';
import filter from 'lodash/filter';
import toSafeInteger from 'lodash/toSafeInteger';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
// EmPath UI Components
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import GridBox from '@empathco/ui-components/src/mixins/GridBox';
import MatchIndicator, { type MatchIndicatorVariant } from '@empathco/ui-components/src/elements/MatchIndicator';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
// local imports
import { AdminEmployee, AdminJob } from '../graphql/types';
import { TalentEmployeeObject, DevPlanEmployee, TalentEmployeeDetails } from '../graphql/customTypes';
import { Job } from '../models/job';
import { Employee } from '../models/employee';
import { toggleReducer } from '../helpers/reducers';
import EmployeeSimpleBand from '../elements/EmployeeSimpleBand';
import EmployeeDetailsPopup from '../v3/EmployeeDetailsPopup';
// SCSS imports
import { overlayDefault } from '@empathco/ui-components/src/styles/modules/Overlay.module.scss';
import { label, alone } from './EmployeeTalentBand.module.scss';

type TalentBandVariant = 'standalone';

type EmployeeTalentBandProps = {
  employee?: TalentEmployeeObject | DevPlanEmployee | TalentEmployeeDetails | AdminEmployee;
  variant?: TalentBandVariant;
  matchIndicatorVariant?: MatchIndicatorVariant;
  route?: string | null;
  onJobClick?: (code: string, job?: Job | AdminJob, context?: TalentEmployeeObject | AdminEmployee) => void;
  disabled?: boolean | null;
  pending?: boolean | null;
  failed?: boolean | null;
  isMyPlan?: boolean;
  withReloading?: boolean;
  withoutDivider?: boolean;
}

const EmployeeTalentBandPropTypes = {
  // attributes
  employee: PropTypes.object as Validator<TalentEmployeeObject | DevPlanEmployee | TalentEmployeeDetails | AdminEmployee>,
  variant: PropTypes.string as Validator<TalentBandVariant>,
  matchIndicatorVariant: PropTypes.string as Validator<MatchIndicatorVariant>,
  route: PropTypes.string,
  onJobClick: PropTypes.func,
  disabled: PropTypes.bool,
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  isMyPlan: PropTypes.bool,
  withReloading: PropTypes.bool,
  withoutDivider: PropTypes.bool
};

// eslint-disable-next-line complexity
const EmployeeTalentBand: FunctionComponent<EmployeeTalentBandProps> = ({
  employee,
  variant,
  matchIndicatorVariant,
  route,
  onJobClick,
  disabled = false,
  pending = false,
  failed = false,
  isMyPlan = false,
  withReloading = false,
  withoutDivider = false
}) => {
  const { match_rate } = employee as Employee || {};
  const { current_match_rate, skills } = employee as DevPlanEmployee || {};
  const isStandalone = variant === 'standalone';
  const withMatchIndicator = Boolean(matchIndicatorVariant);

  const [popupOpen, togglePopupOpen] = useReducer(toggleReducer, false);

  const matchValues = useMemo(() => withMatchIndicator ? {
    satisfied: size(filter(skills, 'is_satisfied')),
    total: size(skills)
  } : undefined, [skills, withMatchIndicator]);

  const reloading = Boolean(withReloading && pending && employee);

  const content = failed || (pending && !reloading) ? undefined : (
    <GridBox
        container
        spacing={2}
        pt={isStandalone ? 2 : 3}
        pb={isStandalone ? (withMatchIndicator && 1) || 4 : 2}
        px={isStandalone ? (withMatchIndicator && 3) || 6 : undefined}
        className={isStandalone ? alone : undefined}
    >
      {isMyPlan || !employee ? (
        <GridBox
            item
            container
            xs={withMatchIndicator ? 8 : undefined}
            sm={withMatchIndicator ? 9 : undefined}
            md={withMatchIndicator ? 10 : undefined}
            alignItems="center"
        >
          <BoxTypography pl={2.5} variant="h3">
            <FormattedMessage id="dev_plans.match_rate"/>
          </BoxTypography>
        </GridBox>
      ) : (
        <EmployeeSimpleBand
            employee={employee}
            route={route}
            onJobClick={onJobClick}
            disabled={disabled}
            isStandalone={isStandalone}
            withMatchIndicator={withMatchIndicator}
        />
      )}
      {withMatchIndicator ? (
        <GridBox
            item
            xs={4}
            sm={3}
            md={2}
            container
            flexDirection="column"
            alignItems="center"
        >
          <BoxTypography variant="caption" color="text.label" pt={1.5} className={label}>
            <FormattedMessage id="hr.talentfinder.column.match_skills" values={matchValues}/>
          </BoxTypography>
          <IconButton
              size="small"
              disabled={disabled ? true : undefined}
              onClick={togglePopupOpen}
          >
            <MatchIndicator
                value={toSafeInteger(match_rate || current_match_rate)}
                variant={matchIndicatorVariant}
            />
          </IconButton>
        </GridBox>
      ) : undefined}
    </GridBox>
  );

  return (failed && <FetchFailedAlert flat={isStandalone}/>) || (
    pending && !reloading && <LoadingPlaceholder flat={isStandalone}/>
  ) || (
    <>
      {reloading ? (
        <Box
            flexGrow={1}
            display="flex"
            flexDirection="column"
            position="relative"
        >
          {content}
          <Box className={overlayDefault}>
            <LinearProgress/>
          </Box>
        </Box>
      ) : content}
      {withoutDivider ? undefined : <Divider/>}
      {withMatchIndicator ? (
        <EmployeeDetailsPopup
            isMyPlan={isMyPlan}
            employee={employee as TalentEmployeeObject}
            isOpen={popupOpen}
            onClose={togglePopupOpen}
            route={route}
        />
      ) : undefined}
    </>
  );
};

EmployeeTalentBand.propTypes = EmployeeTalentBandPropTypes;

export default memo(EmployeeTalentBand);
