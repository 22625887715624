import map from 'lodash/map';
import { type AxiosResponse } from 'axios';
import type { RowInput } from 'jspdf-autotable';
// local imports
import { Employee } from '../models/employee';
import { APIResponse } from '../models/apiResponse';
import { ExportParams } from '../context/commonParams';
import { MAX_JOB_MATCH_EXPORT } from '../config/params';
import {
  axiosInstance as axios,
  getApiMatchingJobEmpls
} from '../config/api';
import { getRequestHeaders } from '../helpers/context';
import {
  IAutoTable, IJsPDF, Rows, Headers,
  CENTERED_BOLD_COLUMN,
  downloadAndSave, saveToCSV, saveToPDF, getCodeNameFilename
} from '../helpers/export';

// Best matching employees for a job export

const JOB_CANDIDATES_CSV_COLUMNS = [
  'supervisor.export.match_rate',
  'supervisor.export.code',
  'supervisor.export.first_name',
  'supervisor.export.last_name',
  'supervisor.export.email',
  'supervisor.export.role_code',
  'supervisor.export.role_title',
  'supervisor.export.city',
  'supervisor.export.state',
  'supervisor.export.country'
];

// eslint-disable-next-line max-params
function saveJobCandidates (
  jobCode: string,
  jobName: string,
  title: string,
  footer: string | string[],
  headers: Headers,
  employees: Employee[],
  jsPDF?: IJsPDF,
  autoTable?: IAutoTable
) {
  const rows = map(employees, ({ match_rate, code, first_name, last_name, email, current_job, location }) => [
    match_rate,
    code,
    first_name,
    last_name,
    email,
    current_job?.code,
    current_job?.title,
    location?.city,
    location?.state,
    location?.country
  ] as RowInput) as Rows;
  const filename = `job_candidates_${getCodeNameFilename(jobCode, jobName)}`;
  if (jsPDF && autoTable) {
    saveToPDF(jsPDF, autoTable, filename, headers, rows, title, footer, { 0: CENTERED_BOLD_COLUMN });
  } else {
    saveToCSV(filename, headers, rows);
  }
}

// eslint-disable-next-line max-params
function downloadAndSaveJobCandidates(
  format: string,
  token: string,
  jobCode: string,
  jobName: string,
  title: string,
  footer: string | string[],
  headers: Headers,
  employees?: Employee[] | null
): Promise<boolean | number> {
  return downloadAndSave<Employee>(
    format,
    employees
    ? { status: 200, data: { results: employees } as APIResponse<Employee> } as AxiosResponse<APIResponse<Employee>>
    : axios.request<ExportParams, AxiosResponse<APIResponse<Employee>>>({
        method: 'GET',
        url: getApiMatchingJobEmpls(jobCode),
        params: {
          export_mode: true,
          limit: MAX_JOB_MATCH_EXPORT
        },
        headers: getRequestHeaders(token)
      }),
    (data, jsPDF, autoTable) => saveJobCandidates(jobCode, jobName, title, footer, headers, data, jsPDF, autoTable)
  );
}

const exportJobCandidatesHook = {
  JOB_CANDIDATES_CSV_COLUMNS,
  downloadAndSaveJobCandidates
};

function useExportJobCandidates() {
  return exportJobCandidatesHook;
}

export default useExportJobCandidates;
