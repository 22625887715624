import { PureComponent, type ReactNode } from 'react';
import PropTypes from 'prop-types';
import { type ErrorBoundaryProps } from '@sentry/react/build/types/errorboundary';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';

export class OurErrorBoundary extends PureComponent<ErrorBoundaryProps> {
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  static propTypes = {
    // attributes
    children: PropTypes.element.isRequired,
    fallback: PropTypes.node
  };

  state = {
    hasError: false
  };

  // componentDidCatch(/* error, info */) {
  //   ...additional error handling...
  // }

  render() {
    const { children, fallback } = this.props;
    const { hasError } = this.state;
    return (hasError ? fallback || <div></div> : children) as ReactNode;
  }
}

const ErrorBoundary = window.SENTRY_DSN ? SentryErrorBoundary : OurErrorBoundary;

export default ErrorBoundary;
