import { forwardRef, memo, useCallback } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import isString from 'lodash/isString';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
// Material Icon imports
import PushPinRounded from '@mui/icons-material/PushPinRounded';
// EmPath UI Components
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import ItemCard from '@empathco/ui-components/src/elements/ItemCard';
// local imports
import { CategoryItem } from '../models/categoriesGroup';
import {
  DA_SKILL_SUPPLY, DA_EMPLOYEE_SKILLS, DA_SKILLS_GROWTH, DA_SKILLS_DEVELOPMENT, DA_EFFECTIVE_COURSES, DA_EFFECTIVE_ADVISORS,
  DA_SKILL_COURSE_MAPPING, DA_FREQ_INDEMAND, DA_FREQ_SKILL_GAP, DA_HIRING_TRENDS, DA_JOBS_MOVEMENT,
  DA_JOB_RELATED_SKILLS, DA_JOB_MOVEMENT_SKILLS, DA_CLOSE_MATCH_JOBS, DA_AVERAGE_SKILLS,
  DA_EMPLOYEE_VELOCITY, DA_CURRENT_ROLE_MATCH, DA_UPSKILLING_VELOCITY, DA_COURSE_COMPLETION, DA_USAGE_BY_ORG
} from '../constants/dashboardAnalytics';
import useCustomerSettings from '../config/customer';
import TopChartSimple from '../widgets/TopChartSimple';
import TopChartRadar from '../widgets/TopChartRadar';
import TopChartVBars from '../widgets/TopChartVBars';
import TopChartTimeline from '../widgets/TopChartTimeline';
import TopChartStacked from '../widgets/TopChartStacked';
// SCSS imports
import { card } from './CategoryCard.module.scss';

const pinSx = { position: 'absolute', right: '0.38rem', top: '0.62rem' };

type CategoryCardProps = {
  category: CategoryItem;
  onClick: (value: number) => void;
  pinned?: boolean;
};

const CategoryCardPropTypes = {
  // attributes
  category: PropTypes.object.isRequired as Validator<CategoryItem>,
  onClick: PropTypes.func.isRequired,
  pinned: PropTypes.bool
};

// eslint-disable-next-line complexity
const CategoryCard = forwardRef<HTMLDivElement, CategoryCardProps>(({
  category,
  onClick,
  pinned
}, ref) => {
  const { HAS_COURSES_READONLY, HAS_MENTORING } = useCustomerSettings();
  const { id, title, values } = category;
  const handleClick = useCallback(() => onClick(id), [id, onClick]);
  return (
    <ItemCard
        ref={ref}
        smallItem
        light
        onClick={handleClick}
        className={card}
    >
      {/* TODO: info button */}
      {pinned ? (
        <PushPinRounded
            color="secondary"
            fontSize="small"
            sx={pinSx}
        />
      ) : undefined}
      <BoxTypography variant="h5" color="secondary.text" pb={1.25}>
        {isString(title) ? <FormattedMessage id={title} values={values}/> : title}
      </BoxTypography>
      <Box flex="1 1 0" display="flex" alignItems="center">
        {((id === DA_EFFECTIVE_COURSES || id === DA_COURSE_COMPLETION || id === DA_EFFECTIVE_ADVISORS) && (
          <TopChartSimple
              preview
              hrbp
              variant={
                (id === DA_EFFECTIVE_COURSES && 'courses') ||
                (id === DA_COURSE_COMPLETION && 'completion') ||
                (id === DA_EFFECTIVE_ADVISORS && 'advisors') ||
                undefined
              }
          />
        )) || ((id === DA_FREQ_INDEMAND || id === DA_FREQ_SKILL_GAP || id === DA_USAGE_BY_ORG) && (
          <TopChartRadar
              preview
              variant={(id === DA_USAGE_BY_ORG && 'orgs') || (id === DA_FREQ_INDEMAND && 'in_demand') || 'gaps'}
          />
        )) || ((id === DA_JOBS_MOVEMENT || id === DA_JOB_RELATED_SKILLS || id === DA_HIRING_TRENDS) && (
          <TopChartVBars
              preview
              variant={
                (id === DA_JOBS_MOVEMENT && 'jobs_movement') ||
                (id === DA_JOB_RELATED_SKILLS && 'job_skills') ||
                'hiring_trends'
              }
          />
        )) || ((
          id === DA_EMPLOYEE_SKILLS || id === DA_CLOSE_MATCH_JOBS || id === DA_AVERAGE_SKILLS ||
          id === DA_EMPLOYEE_VELOCITY || id === DA_CURRENT_ROLE_MATCH || id === DA_SKILL_COURSE_MAPPING ||
          id === DA_UPSKILLING_VELOCITY
        ) && (
          <TopChartTimeline
              preview
              variant={
                (id === DA_EMPLOYEE_SKILLS && 'employees') ||
                (id === DA_CLOSE_MATCH_JOBS && 'jobs') ||
                (id === DA_AVERAGE_SKILLS && 'skills') ||
                (id === DA_EMPLOYEE_VELOCITY && 'velocity') ||
                (id === DA_CURRENT_ROLE_MATCH && 'match') ||
                (id === DA_UPSKILLING_VELOCITY && 'upskilling') ||
                'skill_course'
              }
          />
        )) || ((
          id === DA_SKILL_SUPPLY || id === DA_SKILLS_GROWTH || id === DA_SKILLS_DEVELOPMENT || id === DA_JOB_MOVEMENT_SKILLS
        ) && (
          <TopChartStacked
              preview
              hrbp
              variant={
                (id === DA_SKILL_SUPPLY && 'supply_demand') ||
                (id === DA_SKILLS_GROWTH && 'skills_growth') ||
                (id === DA_SKILLS_DEVELOPMENT && (
                  (HAS_MENTORING && 'skill_advisors') ||
                  (HAS_COURSES_READONLY && 'skill_courses') ||
                  'skills_development'
                )) || 'upward_job_movement'
              }
          />
        )) || undefined}
      </Box>
    </ItemCard>
  );
});

CategoryCard.displayName = 'CategoryCard';

CategoryCard.propTypes = CategoryCardPropTypes;

export default memo(CategoryCard);
