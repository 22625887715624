import { forwardRef, memo, useEffect, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
// Material UI imports
import Box from '@mui/material/Box';
// EmPath UI Components
import { type GetComponentProps } from '@empathco/ui-components/src/helpers/types';
import { paramsDiffer } from '@empathco/ui-components/src/helpers/pagination';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
import CardFooter from '@empathco/ui-components/src/elements/CardFooter';
// local imports
import { COHORTS_QUERY } from '../graphql/Cohorts';
import { Cohort, CohortsDocument, CohortsQueryVariables } from '../graphql/types';
import { PATH_HR_TALENT_FINDER, PATH_HR_DEV_PLANS } from '../config/paths';
import { PersistentContext } from '../context/persistent';
import LargeButton from '../elements/LargeButton';
import TbTeamCard from '../v3/TbTeamCard';
import CardsGrid from '../v3/CardsGrid';
import PaginationControls from '../v3/PaginationControls';
// SCSS imports
import { footer } from './SavedCohorts.module.scss';

const componentProps: Partial<GetComponentProps<typeof TbTeamCard>> = {
  hrbp: true,
  variant: 'cohort'
};

type SavedCohortsProps = {
  // for Storybook only
  testPending?: boolean;
}

const SavedCohortsPropTypes = {
  testPending: PropTypes.bool
};

const SavedCohorts = forwardRef<HTMLDivElement, SavedCohortsProps>(({
  testPending
}, ref) => {
  const navigate = useNavigate();
  const { setSkills, setSkillGroups, setDesiredSkillIds } = useContext(PersistentContext);

  // lazy load cohorts
  const { query: getCohorts, pending: pendingCohorts, failed, count, results: cohorts, variables: prevVars } = useQueryCounted({
    data: undefined as unknown as Cohort,
    key: 'cohorts',
    lazyQuery: useLazyQuery(COHORTS_QUERY as typeof CohortsDocument)
  });
  const pending = pendingCohorts || testPending;

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>();

  useEffect(() => {
    if (pageSize && getCohorts) {
      const variables: CohortsQueryVariables = {
        limit: pageSize
      };
      let curPage = currentPage;
      if (paramsDiffer(prevVars, variables)) {
        curPage = 1;
        setCurrentPage(1);
      }
      variables.offset = pageSize * (curPage - 1);
      getCohorts({ variables });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, getCohorts]); // ignoring `prevVars` changes

  const handleNewCohort = useCallback(() => {
    setSkills([]);
    setSkillGroups([]);
    setDesiredSkillIds([]);
    navigate(PATH_HR_TALENT_FINDER);
  }, [navigate, setDesiredSkillIds, setSkillGroups, setSkills]);

  return (
    <>
      <CardsGrid
          ref={ref}
          items={cohorts}
          variant="shady"
          withReloading
          pending={pending}
          failed={failed}
          // disabled={...}
          blendNotFound
          blendPagination
          component={TbTeamCard}
          ComponentProps={componentProps}
          notFoundMessage="hr.cohorts.empty"
          pagination={(
            <PaginationControls
                settingsId="tb_cohorts"
                loaded={Boolean(cohorts)}
                pending={pending}
                loading={pending}
                total={count}
                currentPage={currentPage}
                onPageSelected={setCurrentPage}
                onPageSize={setPageSize}
                disabled={pending || failed}
                totalMessage="hr.cohorts.pagination"
            />
          )}
      />
      <CardFooter className={footer}>
        <LargeButton
            title="hr.cohorts.build_new"
            variant="cohort"
            onClick={handleNewCohort}
            disabled={pending}
        />
        <Box width="10%"/>
        <LargeButton
            title="hr.cohorts.view_dev_plans"
            variant="devplan"
            link={PATH_HR_DEV_PLANS}
            disabled={pending}
        />
      </CardFooter>
    </>
  );
});

SavedCohorts.displayName = 'SavedCohorts';

SavedCohorts.propTypes = SavedCohortsPropTypes;

export default memo(SavedCohorts);
